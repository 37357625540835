import { MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";
import {
    IRecommendation
} from "../../../../services/Api/executor/IApiServiceExecutor";
import Checkbox from "../../Checkbox/Checkbox";
import styles from "../IWantForm.module.scss";
import { useTranslation } from "react-i18next";

interface IRecommendationsControlProps {
    recommendationsRequired?:boolean;
    recommendations?: IRecommendation[];
    setRecommendationState: (state: string, recommendationId: string, value: boolean) => void;
    inProgress: boolean;
}

interface IRecommendationItemControlProps {
    indexItem: number;
    recommendation: IRecommendation;
    setRecommendState: (state: string, recommendationId: string, value: boolean) => void;
    inProgress: boolean;
}

function RecommendationItemControl(props: IRecommendationItemControlProps): JSX.Element {
    const { t } = useTranslation();
    const { indexItem, recommendation, setRecommendState, inProgress } = props;
    const recommendationStatesNames: string[] = [
        props.recommendation.type,
        "Not " + (props.recommendation.type || "").toLowerCase(),
        t("pleaseDiscussLabel")
    ];
    const checkRecommendationStateLock: (
        recommendation: IRecommendation,
        stateName: string
    ) => boolean = React.useCallback(
        (recommendation: IRecommendation, stateName: string): boolean => {
            return recommendation.state ? !(recommendation.state === stateName) : false;
        },
        []
    );
    const checkboxVisible : boolean = recommendationStatesNames[1].toLowerCase() == "not noted" ? false: true;

    return (
        <div className={styles.recommendationPreviewItem}>
            <div className={styles.info}>
                <div className={styles.number}>{indexItem + 1}.</div>
                <span className={styles.type}>{props.recommendation.type}</span>
                <span className={styles.descr}>{recommendation.description}</span>
            </div>
            <div className={styles.checkboxes}>
                <div className = {styles.OptionsContainer}>
                    <Checkbox
                        name={recommendationStatesNames[0]}
                        defaultChecked={recommendation.state === recommendationStatesNames[0]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[0],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={
                            inProgress ||
                        checkRecommendationStateLock(recommendation, recommendationStatesNames[0])
                        }
                    />
                </div>
                {checkboxVisible && <div className = {styles.OptionsContainer}>
                    <Checkbox
                        name={recommendationStatesNames[1]}
                        defaultChecked={recommendation.state === recommendationStatesNames[1]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[1],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={
                            inProgress ||
                        checkRecommendationStateLock(recommendation, recommendationStatesNames[1])
                        }
                    />
                </div>}
                <div className = {styles.OptionsContainer}>
                    <Checkbox
                        name={recommendationStatesNames[2]}
                        defaultChecked={recommendation.state === recommendationStatesNames[2]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[2],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={
                            inProgress ||
                        checkRecommendationStateLock(recommendation, recommendationStatesNames[2])
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default function RecommendationsControl({
    recommendations,
    recommendationsRequired,
    inProgress,
    setRecommendationState
}: IRecommendationsControlProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className={styles.recommendationsSection}>
            <h2 className={styles.sybjectHeader}>{t("recommendationsHeader")}</h2>
            <div>
                {!!recommendations?.length && (recommendations || []).map((recommendation, index) => {
                    return (
                        <RecommendationItemControl
                            key={recommendation.id}
                            indexItem={index}
                            recommendation={recommendation}
                            setRecommendState={setRecommendationState}
                            inProgress={inProgress}
                        />
                    );
                })}
                {!recommendations?.length && recommendationsRequired && <MessageBar messageBarType={MessageBarType.warning}>{t("recommendationsWarning")}</MessageBar>}
            </div>
        </div>
    );
}
