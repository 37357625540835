import React from "react";
import { useSelector } from "react-redux";
import { IUser, IProxyEmailTemplates } from "../../../services/Api/executor/IApiServiceExecutor";
import styles from "./ProxiesList.module.scss";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode
} from "@fluentui/react";
import {
    DefaultButton, 
} from "@fluentui/react";
import {
    ProxyEmailUserToken,
    ProxyEmailRemoveText,
    ProxyEmailEditExpiry,
    ProxyEmailExpiryToken,
    ProxyEmailCurrentTimeToken,
    ProxyEmailCurrentUserToken
} from "../../../providers/Constants/SettingsConstants";
import {
    Icon
} from "@fluentui/react";
import { selectCurrentUser } from "../../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";
"../../app/globalSlices/contextSlice";

interface IProxiesProps {
    users: IUser[];
    emailTemplates?: IProxyEmailTemplates | null;
    isMobile?: boolean;
    // readOnly?: boolean;
}

export default function ProxiesList({ users, emailTemplates, isMobile }: IProxiesProps): JSX.Element {
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const {t} = useTranslation();

    const getEmailTemplate = (user: IUser, fallBackTemplate: string, emailTemplate?: string): string => {
        let emailString: string = emailTemplate || fallBackTemplate;
        const userString: string = `${user.displayName || ""} (${user.mail || ""})`;
        const currentUserString: string = currentUser ? `${currentUser.displayName || ""} (${currentUser.mail}) `: "";

        emailString = emailString.replaceAll(ProxyEmailUserToken, userString);
        emailString = emailString.replaceAll(ProxyEmailExpiryToken, user && user.proxyExpiry || "");
        emailString = emailString.replaceAll(ProxyEmailCurrentTimeToken, new Date().toLocaleString());
        emailString = emailString.replaceAll(ProxyEmailCurrentUserToken, currentUserString);

        return emailString;
    };

    const columns: IColumn[] = [
        {
            key: "displayNameColumn",
            name: "Name",
            fieldName: "displayName",
            isResizable: true,
            minWidth: 60,
            maxWidth: 200,
            onRender: (user: IUser) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.primaryText}>{user.displayName}</div>
                    </div>
                );
            }
        },
        {
            key: "ExpiryDateColumn",
            name: "End Date",
            fieldName: "proxyExpiry",
            isResizable: true,
            minWidth: 50,
            maxWidth: 200,
            onRender: (user: IUser) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.secondaryText}>{user.proxyExpiry}</div>
                    </div>
                );
            }
        },
        {
            key: "editEndDateColumn",
            name: "",
            fieldName: "mail",
            isResizable: true,
            minWidth: 50,
            maxWidth: 150,
            onRender: (user: IUser) => {
                return (
                    <div className={styles.bodyColumn}>
                        <a className={styles.linkContainer} href={getEmailTemplate(user, t("proxyEditMailToLink"), emailTemplates?.requestEditProxyEndDate)}>
                            <div className={styles.linkIcon}><Icon className={styles.icon} iconName={"PageEdit"} /></div>
                            <div className={styles.linkText}>{ProxyEmailEditExpiry}</div>
                        </a>
                    </div>
                );
            }
        },
        {
            key: "removeProxyColumn",
            name: "",
            fieldName: "mail",
            isResizable: true,
            minWidth: 50,
            maxWidth: 150,
            onRender: (user: IUser) => {
                return (
                    <div className={styles.bodyColumn}>
                        <a className={styles.linkContainer} href={getEmailTemplate(user, t("proxyRemoveMailToLink"), emailTemplates?.requestDeleteProxy)}>
                            <div className={styles.linkIcon}><Icon className={styles.icon} iconName={"CalculatorSubtract"} /></div>
                            <div className={styles.linkText}>{ProxyEmailRemoveText}</div>
                        </a>
                    </div>
                );
            }
        }
    ];

    return <div className={styles.proxiesTableContainer}>
        {isMobile ? 
            <>
                {users.map(u => <div className={styles.card} key={u.displayName}>
                    <div className={styles.primaryText}>{u.displayName}</div>
                    <div className={styles.quaternaryText}><span className={styles.quinaryText}>Expiry: </span>{u.proxyExpiry}</div>
                    <a className={styles.linkContainer} href={getEmailTemplate(u, t("proxyEditMailToLink"), emailTemplates?.requestEditProxyEndDate)}>
                        <DefaultButton 
                            className={styles.mobileButton}
                            iconProps={{ iconName: "Edit", className: styles.icon }}
                            ariaLabel={`${ProxyEmailEditExpiry} button`}> 
                            {ProxyEmailEditExpiry}
                        </DefaultButton>
                    </a>
                    <a className={styles.linkContainer} href={getEmailTemplate(u, t("proxyRemoveMailToLink"), emailTemplates?.requestDeleteProxy)}>
                        <DefaultButton 
                            className={styles.mobileButton}
                            iconProps={{ iconName: "CalculatorSubtract", className: styles.icon }}
                            ariaLabel={`${ProxyEmailRemoveText} button`}> 
                            {ProxyEmailRemoveText}
                        </DefaultButton>
                    </a>

                </div>)}
            </>
            : 
        
            <DetailsList
                items={users.map(u => {
                    return {
                        displayName: u.displayName,
                        mail: u.userPrincipalName,
                        proxyExpiry: u.proxyExpiry
                    };
                })}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                isHeaderVisible={true}
            />
        }
    </div>;
}