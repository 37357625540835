import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { DefaultButton, IContextualMenuItem, IContextualMenuProps, Spinner, SpinnerSize } from "@fluentui/react";
import styles from "./IWantButton.module.scss";
import IWantForm, { IFormOutputData } from "../IWantForm/IWantForm";
import IApiService from "../../../services/Api/IApiService";
import {
    IContentTypeField,
    IGenerateOneNoteHtmlRequst,
    IIWantToDialog,
    IIWantToOption,
    IIWantToOptions,
    IRecord,
} from "../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import {
    selectIWantToOptions
} from "../../../features/manageRecordWrapper/manageRecordWrapperSlice";
import { ScrollHelper, SCROLL_KEYS } from "../../../helpers/ScrollHelper";
import { useTranslation } from "react-i18next";

interface IFormProps {
    dialog: IIWantToDialog;    
    skipRecordUpdate: boolean;
    onSubmit: (updatedData: IFormOutputData) => Promise<IRecord | null>;
    buttonOptionLabel?: string;
    overrideComponent?: JSX.Element;
}

interface IIWantButtonProps {
    title: string;
    record: IRecord;
    fields: IContentTypeField[];
    className?: string;
    isFetchOptions?: boolean;
    positionNextToBreadCrumbs?: boolean;
}

export default function IWantButton(props: IIWantButtonProps): JSX.Element {
    const { title, className, record, positionNextToBreadCrumbs, fields  } = props;
    const { t } = useTranslation();

    const [formProps, setFormProps] = React.useState<IFormProps | undefined>(undefined);
    const [menuButton, setMenuButton] = React.useState<IContextualMenuProps | undefined>(undefined);
    const [customActionInProgress, setCustomActionInProgress] = React.useState<boolean>(false);

    const iWantToOptions: IIWantToOptions | null = useSelector(selectIWantToOptions);
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const createMarkElForHtml: (html:string) => HTMLDivElement = (html:string) => {
        const mark: HTMLDivElement = document.createElement("div");
        mark.style.fontSize = "12pt";
        mark.style.border = "0";
        mark.style.padding = "0";
        mark.style.margin = "0";
        mark.style.position = "fixed";
        mark.style["right"] = "-9999px";
        mark.style.top = (window.pageYOffset || document.documentElement.scrollTop) + "px";
        mark.setAttribute("readonly", "");
        mark.style.opacity = "0";
        mark.style.pointerEvents = "none";
        mark.style.zIndex = "-1";
        mark.setAttribute("tabindex", "0");
        mark.innerHTML = html;
        return mark;
    };

    const copyToClipboardRecordDetails: () => Promise<void> = React.useCallback(async () => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const generateOneNoteHtmlRequst: IGenerateOneNoteHtmlRequst = {
            recordId: record.recordId || "",
            title: record.title
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const oneNoteHtml: string | null = await apiService.GenerateOneNoteHtml(generateOneNoteHtmlRequst);

        const range: Range = document.createRange();
        const selection: Selection | null = document.getSelection();
        const mark: HTMLDivElement = createMarkElForHtml(oneNoteHtml || "");

        document.body.appendChild(mark);
        range.selectNode(mark);
        selection?.removeAllRanges();
        selection?.addRange(range);
        document.execCommand("copy");
        if (selection) {
            if (typeof selection.removeRange == "function") {
                selection.removeRange(range);
            } else {
                selection.removeAllRanges();
            }
        }
      
        if (mark) {
            document.body.removeChild(mark);
        }
    }, [record.recordId, record.title]);

    React.useEffect(() => {
        (async () => {
            const buttonOptions: IContextualMenuItem[] = [];
           
            const grouped: { [key: string]: IIWantToOption[] } = iWantToOptions ? iWantToOptions.options.reduce((acc, option) => {
                const categoryName: string = option?.category ? t(option.category) : t("iWantToNoCategory");
                if (acc[categoryName]) {
                    acc[categoryName].push(option);
                } else {
                    acc[categoryName] =  [option];
                }
                return acc;
            }, {} as { [key: string]: IIWantToOption[] }) : {};

            //sort groups by custom order
            const customOrder: string[] = [t("iWantToCategoryActions"), t("iWantToCategoryFlowControl"), t("iWantToCategoryTools")];
            const sortedGroups: { [key: string]: IIWantToOption[] } = {};
            customOrder.forEach((key: string) => {
                if(grouped[key]){
                    sortedGroups[key] = grouped[key];
                    delete grouped[key];
                }
            });

            //append the rest of the groups
            Object.keys(grouped).forEach((key: string) => {
                sortedGroups[key] = grouped[key];
            }
            );

            let skipRedraw: boolean = false;

            sortedGroups && Object.keys(sortedGroups).forEach((key: string) => {
                buttonOptions.push({
                    key: key,
                    text: key,
                    disabled: true,
                    className: styles.categoryHeader,
                });

                sortedGroups[key].forEach((optionItem: IIWantToOption, index: number) => {
                    const isValid: boolean = optionItem.lastModifiedRecordDateTime == undefined 
                                    || optionItem.lastModifiedRecordDateTime == null 
                                    || (optionItem.lastModifiedRecordDateTime === record.modified);

                    if(optionItem.lastModifiedRecordDateTime && record.modified){
                        const optionDate: Date = new Date(optionItem.lastModifiedRecordDateTime);
                        const recordDate: Date = new Date(record.modified);
                        if(optionDate < recordDate){
                            skipRedraw = true;
                        }
                    }

                    buttonOptions.push({
                        key: (optionItem.tabKey ? optionItem.tabKey + index : String(index)) + key + "Button",
                        text: isValid ? t(optionItem.label || "") : t("invalidIWantToOption"),
                        disabled: !isValid || (!optionItem.dialog && !optionItem.tabKey),
                        href: optionItem.tabKey ? UrlHelper.castUrlWithQueryStringParam(
                            optionItem.tabKey,
                            URL_QUERY_KEYS.TAB
                        ) : undefined,
                        className: styles.actionItem,
                        onClick: () => {
                            if(optionItem.customAction == t("customActionCopyToClipboardRecordDetails")) {
                                (async () => {
                                    setCustomActionInProgress(true);
                                    await copyToClipboardRecordDetails();
                                    if(optionItem.dialog){
                                        setFormProps({
                                            dialog: optionItem.dialog,
                                            skipRecordUpdate: false,
                                            onSubmit: () => {
                                                return Promise.resolve(null);
                                            },
                                            buttonOptionLabel: optionItem.label
                                        });
                                    }
                                    setCustomActionInProgress(false);
                                })();
                            } else if (optionItem.dialog) {
                                setFormProps({
                                    dialog: optionItem.dialog,
                                    skipRecordUpdate: false,
                                    onSubmit: async (updatedData: IFormOutputData) => {
                                        const resolver: DependencyResolver = new DependencyResolver();
                                        const apiService: IApiService = resolver.ResolveIApiService();
                                        return await apiService.UpdateRecordIWantToAction({
                                            recordId: updatedData.recordId,
                                            action: updatedData.action,
                                            comments: updatedData.comments,
                                            newTasks: updatedData.newTasks,
                                            recommendations: updatedData.recommendations,
                                            transitionId: updatedData.transitionId,
                                        });
                                    },
                                    buttonOptionLabel: optionItem.label
                                });
                            }
                            if(optionItem.tabKey == UrlHelper.getParameterByName(URL_QUERY_KEYS.TAB))
                            {
                            // need to scroll after collapse event will be finished
                                setTimeout(() => { ScrollHelper.scrollTo(SCROLL_KEYS.TABS);}, 50);
                            }
                        }
                    });
                });
                
            });

            if(!skipRedraw){
                setMenuButton({ items: buttonOptions } as IContextualMenuProps);
            }
        })();
    }, [copyToClipboardRecordDetails, dispatch, iWantToOptions, record, t]);

    const onRenderButtonIcon = (): JSX.Element=>{
        return <Spinner
            size={SpinnerSize.medium}
            className={styles.spinnerStyling}
            ariaLabel={"Loading spinner"}
        />;
    };

    return (
        <div className={positionNextToBreadCrumbs ? styles.positionNextToBreadCrumbs : ""}>
            <div className={[styles.buttonWrapper, className ? className : ""].join(" ")}>
                <DefaultButton
                    text={customActionInProgress ? t("customActionInProgressText") : title}
                    primary
                    menuProps={menuButton}
                    disabled={!menuButton || !menuButton.items || menuButton.items.length < 1 || customActionInProgress}
                    ariaLabel={`${title} button`}
                    onRenderIcon={customActionInProgress ? onRenderButtonIcon : undefined}
                />
            </div>
            {formProps && (formProps.overrideComponent ? (
                <>
                    {formProps.overrideComponent }
                </>                
            ) : (
                <IWantForm
                    record={record}
                    fields={fields}
                    skipRecordUpdate={formProps.skipRecordUpdate}
                    dialogInfo={formProps.dialog}
                    onSubmit={formProps.onSubmit}
                    onCancel={() => setFormProps(undefined)}
                    buttonOptionLabel={formProps.buttonOptionLabel}
                />
            ))}
        </div>
    );
}
