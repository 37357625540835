// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import { RootState, AppThunk } from "../../app/store";
import { ISimpleUser, IUserSettings, NotificationsChannel, NotificationsFrequencyType, NotificationsOption } from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";


// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export interface INotificationSettingsState {
    initialSettings: IUserSettings | undefined;
    isGlobalSettingsOverriden?: boolean;
    preferredChannel: NotificationsChannel;
    preferredFrequency: NotificationsFrequencyType;
    proxyPreferredChannel: NotificationsChannel;
    proxyPreferredFrequency: NotificationsFrequencyType;
    excludedNotifications: NotificationsOption[];
    proxyExcludedNotifications: NotificationsOption[];
    usersForRecommendation: ISimpleUser[];     
    usersForDecision: ISimpleUser[];
    userSettingsInProgress: boolean;
}

export const initialState: INotificationSettingsState = {
    initialSettings: undefined,
    isGlobalSettingsOverriden: false,
    preferredChannel: NotificationsChannel.Email,
    preferredFrequency: NotificationsFrequencyType.All,
    proxyPreferredChannel: NotificationsChannel.Email,
    proxyPreferredFrequency: NotificationsFrequencyType.All,
    proxyExcludedNotifications:[],
    excludedNotifications:[],
    usersForRecommendation: [],
    usersForDecision: [],
    userSettingsInProgress: false
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const notificationSettingsSlice = createSlice({
    name: "notificationSettings",
    initialState,
    reducers: {
        setPreferredChannel: (
            state: INotificationSettingsState,
            action: PayloadAction<NotificationsChannel>
        ) => {
            state.preferredChannel = action.payload;
        },
        setPreferredFrequency: (
            state: INotificationSettingsState,
            action: PayloadAction<NotificationsFrequencyType>
        ) => {
            state.preferredFrequency = action.payload;            
        },
        setExcludedNotifications:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption[]>) => {
            state.excludedNotifications = action.payload;
        },
        setProxyPreferredChannel: (
            state: INotificationSettingsState,
            action: PayloadAction<NotificationsChannel>
        ) => {
            state.proxyPreferredChannel = action.payload;
        },
        setProxyPreferredFrequency: (
            state: INotificationSettingsState,
            action: PayloadAction<NotificationsFrequencyType>
        ) => {
            state.proxyPreferredFrequency = action.payload;            
        },
        setProxyExcludedNotifications:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption[]>) => {
            state.proxyExcludedNotifications = action.payload;
        },
        addExcludedNotificationOption:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption>) => {
            const excluded:NotificationsOption[]  = (state.excludedNotifications?.slice() || []);
            if(excluded.indexOf(action.payload)<0){
                excluded.push(action.payload);
            }
            state.excludedNotifications = excluded;
        },
        removeExcludedNotificationOption:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption>) => {
            const excluded:NotificationsOption[]  = (state.excludedNotifications?.slice() || []);
            if(excluded.indexOf(action.payload)>=0){
                excluded.splice(excluded.indexOf(action.payload), 1);
            }
            state.excludedNotifications = excluded;
        },
        addProxyExcludedNotificationOption:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption>) => {
            const excluded:NotificationsOption[]  = (state.proxyExcludedNotifications?.slice() || []);
            if(excluded.indexOf(action.payload)<0){
                excluded.push(action.payload);
            }
            state.proxyExcludedNotifications = excluded;
        },
        removeProxyExcludedNotificationOption:(state: INotificationSettingsState,
            action: PayloadAction<NotificationsOption>) => {
            const excluded:NotificationsOption[]  = (state.proxyExcludedNotifications?.slice() || []);
            if(excluded.indexOf(action.payload)>=0){
                excluded.splice(excluded.indexOf(action.payload), 1);
            }
            state.proxyExcludedNotifications = excluded;
        },
        setIsGlobalSettingsOverriden: (
            state: INotificationSettingsState,
            action: PayloadAction<boolean>
        ) => {
            state.isGlobalSettingsOverriden = action.payload;
        },
        setUserSettings: (
            state: INotificationSettingsState,
            action: PayloadAction<IUserSettings | null>
        ) => {
            if(!action.payload){
                for (const key in initialState) {
                    const keyAs: keyof INotificationSettingsState = key as keyof INotificationSettingsState;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (state as any)[keyAs] = initialState[keyAs] as any;
                }
            } else {
                state.initialSettings = action.payload;
                state.initialSettings.isOverridenOnSubScope = !!action.payload.isOverridenOnSubScope;

                state.preferredChannel = action.payload.notificationsChannel;
                state.preferredFrequency = action.payload.notificationsFrequencyType;
                state.excludedNotifications = action.payload.excludedNotifications || [];
                state.isGlobalSettingsOverriden = !!action.payload.isOverridenOnSubScope;
                state.usersForRecommendation = action.payload.notifyUsersOfRecommendation || [];
                state.usersForDecision = action.payload.notifyUsersOfDecision || [];

                state.proxyPreferredChannel = action.payload.proxyNotificationsChannel;
                state.proxyPreferredFrequency = action.payload.proxyNotificationsFrequencyType;
                state.proxyExcludedNotifications = action.payload.proxyExcludedNotifications || [];
            }
        },
        returnInitialSettings: (
            state: INotificationSettingsState
        ) => {
            if(!state.initialSettings){
                for (const key in initialState) {
                    const keyAs: keyof INotificationSettingsState = key as keyof INotificationSettingsState;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (state as any)[keyAs] = initialState[keyAs] as any;
                }
            } else {
                state.preferredChannel = state.initialSettings.notificationsChannel;
                state.preferredFrequency = state.initialSettings.notificationsFrequencyType;
                state.excludedNotifications = state.initialSettings.excludedNotifications || [];
                state.isGlobalSettingsOverriden = !!state.initialSettings.isOverridenOnSubScope;
                state.usersForRecommendation = state.initialSettings.notifyUsersOfRecommendation || [];
                state.usersForDecision = state.initialSettings.notifyUsersOfDecision || [];

                state.proxyPreferredChannel = state.initialSettings.proxyNotificationsChannel;
                state.proxyPreferredFrequency = state.initialSettings.proxyNotificationsFrequencyType;
                state.proxyExcludedNotifications = state.initialSettings.proxyExcludedNotifications || [];
            } 
        },
        addUserForRecommendations:(
            state: INotificationSettingsState,
            action: PayloadAction<ISimpleUser>
        ) => {
            state.usersForRecommendation?.push(action.payload);
                
        },
        removeUserForRecommendations:(
            state: INotificationSettingsState,
            action: PayloadAction<ISimpleUser>
        ) => {
            const userIndex: number = state.usersForRecommendation?.findIndex(
                t => t.siteUserId == action.payload.siteUserId
            );
            if (userIndex > -1) {
                state.usersForRecommendation?.splice(userIndex, 1);
            }
                
        }   ,
        addUserForDecision:(
            state: INotificationSettingsState,
            action: PayloadAction<ISimpleUser>
        ) => {
            state.usersForDecision.push(action.payload);
                
        },
        removeUserForDecision:(
            state: INotificationSettingsState,
            action: PayloadAction<ISimpleUser>
        ) => {
            const userIndex: number = state.usersForDecision.findIndex(
                t => t.siteUserId == action.payload.siteUserId
            );
            if (userIndex > -1) {
                state.usersForDecision.splice(userIndex, 1);
            }
                
        } 
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    returnInitialSettings,
    setPreferredChannel,
    setPreferredFrequency,
    setProxyExcludedNotifications,
    setProxyPreferredChannel,
    setProxyPreferredFrequency,
    addExcludedNotificationOption,
    removeExcludedNotificationOption,
    addProxyExcludedNotificationOption,
    removeProxyExcludedNotificationOption,
    setUserSettings,
    addUserForRecommendations,
    addUserForDecision,
    removeUserForDecision,
    removeUserForRecommendations,
    setIsGlobalSettingsOverriden
} = notificationSettingsSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


export const getUserSettings = (subScopeId?: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const optionsResponse: IUserSettings | null = await apiService.GetUserSettings(subScopeId);
    dispatch(setUserSettings(optionsResponse));
};


export const userPreferredChannel = (state: RootState): NotificationsChannel =>
    state.notificationSettings.preferredChannel;

export const userPreferredFrequency = (state: RootState): NotificationsFrequencyType =>
    state.notificationSettings.preferredFrequency;

export const proxyPreferredChannel = (state: RootState): NotificationsChannel =>
    state.notificationSettings.proxyPreferredChannel;

export const proxyPreferredFrequency = (state: RootState): NotificationsFrequencyType =>
    state.notificationSettings.proxyPreferredFrequency;

export const usersForRecommendation = (state: RootState): ISimpleUser[] =>
    state.notificationSettings.usersForRecommendation;

export const usersForDecision = (state: RootState): ISimpleUser[] =>
    state.notificationSettings.usersForDecision;

export const excludedNotifications = (state: RootState): NotificationsOption[] =>
    state.notificationSettings.excludedNotifications;

export const excludedProxyNotifications = (state: RootState): NotificationsOption[] =>
    state.notificationSettings.proxyExcludedNotifications;

export const isGlobalSettingsOverriden = (state: RootState): boolean =>
    !!state.notificationSettings.isGlobalSettingsOverriden;

export const userInitialSettings = (state: RootState): IUserSettings | undefined =>
    state.notificationSettings.initialSettings;

// 6. EXPORT REDUCER
export default notificationSettingsSlice.reducer;
