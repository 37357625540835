// 1. Imports
import React from "react";
import styles from "./AppSettingsPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

import {
    selectBoardConnectSiteURL,
    getAppSettings,
    updateAppSettingsAsync,
    appInitialSettings,
    selectFavicon,
    selectPrimaryIcon,
    setSelectedFavicon,
    setSelectedPrimaryIcon,
    selectWatermarkPDFPacks,
    setSelectedNavbarIcon,
    selectNavbarIcon
} from "./AppSettingsPageSlice";

import {
    BreadcrumbAppSettings,
    TxtBtnSave,
    TxtBtnCancel,
    SectionHeaderIcons,
    LabelFavicon,
    LabelPrimaryIcon,
    IsLoadingText,
    ErrorMessageFileTypes,
    LabelSecondaryIcon as LabelNavbarIcon
} from "../../providers/Constants/AppSettingsConstants";
import {
    AppRoutes
} from "../../app/Constants";

import {
    PrimaryButton, DefaultButton, Text, Spinner, SpinnerSize, Stack, Image, DefaultPalette, MessageBar, MessageBarType
} from "@fluentui/react";
import {
    Link
} from "react-router-dom";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { IAppSettings, IFile } from "../../services/Api/executor/IApiServiceExecutor";
import FileUpload from "../../components/FormControls/FileUpload/FileUpload";
import { FormMode } from "../wizardKeyRoles/wizardKeyRolesSlice";
import { setAppTitle, setFavicon, setNavbarIcon, setPrimaryIcon } from "../../app/globalSlices/contextSlice";
import { FileHelper } from "../../helpers/FileHelper";
import { useTranslation } from "react-i18next";

export function AppSettingsPage(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const boardConnectSiteURL: string | undefined = useSelector(selectBoardConnectSiteURL);
    const favicon: string | undefined = useSelector(selectFavicon);
    const primaryIcon: string | undefined = useSelector(selectPrimaryIcon);
    const navBarIcon: string | undefined = useSelector(selectNavbarIcon);
    const isWatermarkPDFPacks: boolean | undefined = useSelector(selectWatermarkPDFPacks);
    const initialSettings: IAppSettings | undefined = useSelector(appInitialSettings);
    const { t } = useTranslation();

    // 3. DEFINE COMPONENT STATE
    const [updating, setUpdating] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [errorMessageFavicon, setErrorMessageFavicon] = React.useState<string>("");
    const [errorMessagePrimaryIcon, setErrorMessagePrimaryIcon] = React.useState<string>("");
    const [errorMessageNavbarIcon, setErrorMessageNavbarIcon] = React.useState<string>("");
    const [isLoadingFavicon, setIsLoadingFavicon] = React.useState<boolean>(false);
    const [isLoadingPrimaryIcon, setIsLoadingPrimaryIcon] = React.useState<boolean>(false);
    const [isLoadingNavbarIcon, setIsLoadingNavbarIcon] = React.useState<boolean>(false);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    React.useEffect(() => {
        dispatch(getAppSettings());
        dispatch(setAppTitle(t("appSettingsPageTitle")));
    }, [dispatch, t]);

    const appSettings: IAppSettings = React.useMemo(() => {
        return {
            boardConnectSiteURL: boardConnectSiteURL,
            favicon: favicon,
            primaryIcon: primaryIcon,
            navbarIcon: navBarIcon,
            watermarkPDFPacks: isWatermarkPDFPacks
        };
    }, [
        boardConnectSiteURL,
        favicon,
        primaryIcon,
        navBarIcon,
        isWatermarkPDFPacks
    ]);

    const anyChangesApplied: boolean = React.useMemo(() => {
        return !!initialSettings && (
            initialSettings.favicon != favicon
            || initialSettings.primaryIcon != primaryIcon
            || initialSettings.navbarIcon != navBarIcon);
    }, [
        initialSettings,
        favicon,
        primaryIcon,
        navBarIcon
    ]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return !initialSettings;
    }, [initialSettings]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked && !updating;
    }, [anyChangesApplied, formIsBlocked, updating]);

    const onUpdateClick: () => void = React.useCallback(() => {
        setUpdating(true);
        setErrorMessage("");
        setSuccessMessage("");
        dispatch(updateAppSettingsAsync(
            appSettings,
            {
                onSuccess: () => {
                    setUpdating(false);
                    dispatch(setSelectedFavicon(appSettings.favicon));
                    dispatch(setSelectedPrimaryIcon(appSettings.primaryIcon));
                    dispatch(setSelectedNavbarIcon(appSettings.navbarIcon));
                    dispatch(setFavicon(appSettings.favicon));
                    dispatch(setPrimaryIcon(appSettings.primaryIcon));
                    dispatch(setNavbarIcon(appSettings.navbarIcon));
                    setSuccessMessage("You have sucessfully updated the application settings.");
                },
                onError: (errorMessage: string) => {
                    setUpdating(false);
                    dispatch(setSelectedFavicon(initialSettings?.favicon));
                    dispatch(setSelectedPrimaryIcon(initialSettings?.primaryIcon));
                    dispatch(setSelectedNavbarIcon(initialSettings?.navbarIcon));
                    setErrorMessage(errorMessage || "There was an issue with the provided site. Please retry");
                }
            }));

    }, [
        dispatch,
        initialSettings,
        appSettings
    ]);

    return (
        <>
            <Breadcumbs pageName={BreadcrumbAppSettings} />
            <div className={styles.appSettingsPage}>
                <div className={styles.pageContainer}>
                    <h1 className={styles.pageHeader}>{BreadcrumbAppSettings}</h1>
                    <div className={styles.bodyContainer}>
                        <div>
                            <h2 className={styles.heading}>{SectionHeaderIcons}</h2>
                        </div>
                        <Stack tokens={{ childrenGap: "l2" }} wrap horizontal>
                            <Stack styles={{ root: { background: DefaultPalette.themeLighter } }} tokens={{ childrenGap: "m", padding: "m" }}>
                                <Text>{LabelFavicon}</Text>
                                {favicon && <Image height={100} src={favicon}></Image>}
                                <FileUpload
                                    selectedFilesList={[]}
                                    onFileSelected={async (selectedFile: IFile[]) => {
                                        if (!selectedFile[0].contentURL) {
                                            return;
                                        }

                                        setIsLoadingFavicon(true);
                                        const blob: Blob = await FileHelper.getFileBlob(selectedFile[0].contentURL);
                                        if (FileHelper.isImageBlob(blob)) {
                                            const imageBase64: string = await FileHelper.blobAsBase64Image(blob);
                                            dispatch(setSelectedFavicon(imageBase64));
                                        } else {
                                            setErrorMessageFavicon(`${ErrorMessageFileTypes} ${FileHelper.imageMimeTypes.join(", ")}`);
                                        }
                                        setIsLoadingFavicon(false);
                                    }}
                                    onFileRemove={() => undefined}
                                    onFileEdit={() => undefined}
                                    onFileMoved={() => undefined}
                                    canRemove={true}
                                    onLoading={isLoadingFavicon}
                                    onLoadingText={IsLoadingText}
                                    componentMode={FormMode.New}
                                    errorMessages={errorMessageFavicon ? [errorMessageFavicon] : undefined}
                                    canEdit={!updating && !!initialSettings}
                                    canAdd={!updating && !!initialSettings}
                                    canUploadSigned={false}
                                    disableSelectFromSP
                                ></FileUpload>
                            </Stack>
                            <Stack styles={{ root: { background: DefaultPalette.themeLighter } }} tokens={{ childrenGap: "m", padding: "m" }}>
                                <Text>{LabelPrimaryIcon}</Text>
                                {primaryIcon && <Image height={100} src={primaryIcon}></Image>}
                                <FileUpload
                                    selectedFilesList={[]}
                                    onFileSelected={async (selectedFile: IFile[]) => {
                                        if (!selectedFile[0].contentURL) {
                                            return;
                                        }

                                        setIsLoadingPrimaryIcon(true);
                                        const blob: Blob = await FileHelper.getFileBlob(selectedFile[0].contentURL);
                                        if (FileHelper.isImageBlob(blob)) {
                                            const imageBase64: string = await FileHelper.blobAsBase64Image(blob);
                                            dispatch(setSelectedPrimaryIcon(imageBase64));
                                        } else {
                                            setErrorMessagePrimaryIcon(`${ErrorMessageFileTypes} ${FileHelper.imageMimeTypes.join(", ")}`);
                                        }
                                        setIsLoadingPrimaryIcon(false);
                                    }}
                                    onFileRemove={() => undefined}
                                    onFileEdit={() => undefined}
                                    onFileMoved={() => undefined}
                                    onLoading={isLoadingPrimaryIcon}
                                    onLoadingText={IsLoadingText}
                                    componentMode={FormMode.New}
                                    canRemove={true}
                                    errorMessages={errorMessagePrimaryIcon ? [errorMessagePrimaryIcon] : undefined}
                                    canEdit={!updating && !!initialSettings}                                    
                                    canAdd={!updating && !!initialSettings}                                    
                                    canUploadSigned={false}
                                    disableSelectFromSP
                                ></FileUpload>
                            </Stack>
                            <Stack styles={{ root: { background: DefaultPalette.themeLighter } }} tokens={{ childrenGap: "m", padding: "m" }}>
                                <Text>{LabelNavbarIcon}</Text>
                                {navBarIcon && <Image height={100} src={navBarIcon}></Image>}
                                <FileUpload
                                    selectedFilesList={[]}
                                    onFileSelected={async (selectedFile: IFile[]) => {
                                        if (!selectedFile[0].contentURL) {
                                            return;
                                        }

                                        setIsLoadingNavbarIcon(true);
                                        const blob: Blob = await FileHelper.getFileBlob(selectedFile[0].contentURL);
                                        if (FileHelper.isImageBlob(blob)) {
                                            const imageBase64: string = await FileHelper.blobAsBase64Image(blob);
                                            dispatch(setSelectedNavbarIcon(imageBase64));
                                        } else {
                                            setErrorMessageNavbarIcon(`${ErrorMessageFileTypes} ${FileHelper.imageMimeTypes.join(", ")}`);
                                        }
                                        setIsLoadingNavbarIcon(false);
                                    }}
                                    onFileRemove={() => undefined}
                                    onFileEdit={() => undefined}
                                    onFileMoved={() => undefined}
                                    onLoading={isLoadingNavbarIcon}
                                    onLoadingText={IsLoadingText}
                                    componentMode={FormMode.New}
                                    canRemove={true}
                                    canAdd={!updating && !!initialSettings}  
                                    errorMessages={errorMessageNavbarIcon ? [errorMessageNavbarIcon] : undefined}
                                    canEdit={!updating && !!initialSettings}
                                    canUploadSigned={false}
                                    disableSelectFromSP
                                ></FileUpload>
                            </Stack>
                        </Stack>
                    </div>
                    {successMessage && <MessageBar messageBarType={MessageBarType.success}>{successMessage}</MessageBar>}
                    {errorMessage && <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>}
                    <div className={styles.buttonContainer}>
                        <PrimaryButton
                            onClick={onUpdateClick}
                            allowDisabledFocus
                            disabled={!updateBtnIsActive}
                            className={styles.primaryButtonStyling}
                            ariaLabel={`${TxtBtnSave} button`}
                        >
                            <Text className={styles.text}>{TxtBtnSave}</Text>
                            {updating && (
                                <Spinner className={styles.buttonSpinner} size={SpinnerSize.small} />
                            )}
                        </PrimaryButton>
                        <Link to={AppRoutes.home}>
                            <DefaultButton
                                text={TxtBtnCancel}
                                allowDisabledFocus
                                className={styles.buttonStyling}
                                ariaLabel={`${TxtBtnCancel} button`}
                            />
                        </Link>
                    </div>
                </div>

            </div>
        </>
    );
}