import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Icon } from "@fluentui/react";
//1. IMPORTS
import { Spinner, SpinnerSize } from "@fluentui/react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    documentTabMode,
    getRecordAsync,
    selectCurrentRecord,
    selectIsLoading as selectIsLoadingManageRecord,
    getIWantToOptionsAsync,
    ManageFormType,
    selectFormType,
    selectErrorMessage,
    flushManageRecordState,
    setIsLoading,
    selectForceReloadData,
    setForceReloadData,
    getFieldAsync,
    selectActiveFields,
    selectInactiveFields,
    setIWantToOptions
} from "./manageRecordWrapperSlice";
import styles from "./ManageRecordWrapper.module.scss";
import { ManageRecordNotifications } from "./ManageRecordNotifications";
import UrlHelper from "../../helpers/UrlHelper";
import { ManageRecordKeyRoles } from "./ManageRecordKeyRoles";
import { ManageRecordKeyDates } from "./ManageRecordKeyDates";
import { ManageRecordDocuments } from "./ManageRecordDocuments";
import { ManageRecordDetails } from "./ManageRecordDetails";
import { ManageRecordEditDocuments } from "../manageRecordEditDocuments/ManageRecordEditDocuments";
import { resetWizardKeyRoles } from "../wizardKeyRoles/wizardKeyRolesSlice";
import { OverviewRecommendPanel } from "../overviewRecommend/OverviewRecommend";
import AccessPermissions from "../accessPermissions/AccessPermissions";
import {
    FieldTypes,
    FormMode,
    IContentTypeField,
    IRecord,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    accessTabKey,
    accessTabName,
    activityTabKey,
    activityTabName,
    breadcrumbCopyRecord,
    breadcrumbManageRecord,
    breadcrumbSupersedeRecord,
    copyRecordTabKey,
    detailsTabKey,
    detailsTabName,
    documentsTabKey,
    documentsTabName,
    keyDatesTabKey,
    keyDatesTabName,
    notificationsTabKey,
    notificationsTabName,
    peopleAndRolesTabKey,
    peopleAndRolesTabName,
    relatedRecordsTabKey,
    relatedRecordsTabName,
    supersedeRecordTabKey
} from "../../providers/Constants/RecordWizardConstants";
import TabsController, { ITab } from "../../components/FormControls/TabsController/TabsController";
import RecordTimeline from "../../components/FormControls/RecordTimeline/RecordTimeline";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";

import { selectCurrentUser, selectIsMobile, selectMaxReloadAttemptTime, setAppTitle, setErrorText } from "../../app/globalSlices/contextSlice";
import { URL_QUERY_KEYS } from "../../helpers/UrlHelper";
import { WizardCopyRecord } from "../wizardCopyRecord/WizardCopyRecord";
import { ManageRecordRelatedRecords } from "./ManageRecordRelatedRecords";
import { ManageEventRecordDetails } from "./manageEventRecord/ManageEventRecordDetails";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { ManageParliamRecordDetails } from "./ManageParliamRecordDetails";
import { ScrollHelper, SCROLL_KEYS } from "../../helpers/ScrollHelper";
import IWantButton from "../../components/FormControls/IWantButton/IWantButton";
import ViewRecordActivity from "../../components/FormControls/ViewRecordActivity/ViewRecordActivity";
import { isFieldInActive } from "../../helpers/ModelHelper";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { useTranslation } from "react-i18next";

let pullRecordTimeoutId: NodeJS.Timeout | null = null;

export function ManageRecordWrapper(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const formType: ManageFormType = useSelector(selectFormType);

    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const currentDocumentTabMode: FormMode = useSelector(documentTabMode);
    const isLoading: boolean = useSelector(selectIsLoadingManageRecord);
    const errorMessage: string | undefined = useSelector(selectErrorMessage);
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const isMobile: boolean = useSelector(selectIsMobile);
    const forceReloadData: boolean = useSelector(selectForceReloadData);
    const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
    const reloadInterval: number | undefined = useSelector(selectMaxReloadAttemptTime);

    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const urlKey: string | null = React.useMemo((): string | null => {
        const key: string | null = new URLSearchParams(history.location.search).get(URL_QUERY_KEYS.TAB);
        return key;
    }, [history.location.search]);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    const { recordId } = useParams<{ recordId: string }>();

    // Keep a ref to be able to check if the current component has been unmounted
    const componentIsMounted: React.MutableRefObject<boolean> = React.useRef(true);
    React.useEffect(() => {        
        return () => {
            componentIsMounted.current = false;
        };
    }, []);
    const subscribeReloadRecordData: (recordId: string) => void = React.useCallback(
        (recordId: string) => {
            // clear previous poll timeout if present
            if (pullRecordTimeoutId != null) {
                clearTimeout(pullRecordTimeoutId);
            }

            const reloadRecordData = () => {
                // ensure that we don't poll for unmounted components
                if (componentIsMounted.current) {
                    const currentTabKey: string | undefined = UrlHelper.getParameterByName(URL_QUERY_KEYS.TAB);
                    // get record - only refresh on screens where updating the record won't impact in-progress form
                    if (currentTabKey == undefined || currentTabKey == activityTabKey || currentTabKey == peopleAndRolesTabKey) {
                        dispatch(getRecordAsync(recordId, true, true));
                    }
                }
            };

            const loadAndReSubscribeIfVisible = () => {
                if(document.visibilityState == "visible"){
                    document.removeEventListener("visibilitychange", loadAndReSubscribeIfVisible);
                    reloadRecordData();
                    subscribeReloadRecordData(recordId);
                }
            };

            // ensure that data is refreshed periodically
            pullRecordTimeoutId = setTimeout(() => {
                //only load data if browser tab is active
                if(document.visibilityState == "visible"){
                    loadAndReSubscribeIfVisible();
                }
                else{
                    document.addEventListener("visibilitychange", loadAndReSubscribeIfVisible);
                }
            }, reloadInterval ? reloadInterval * 1000 : 30000);
        },
        [dispatch, reloadInterval]
    );

    React.useEffect(() => {
        return () => {
            //FLUSH RECORD RELATED SOURCES
            dispatch(flushManageRecordState());
            dispatch(resetWizardKeyRoles());
        };
    }, [dispatch]);

    React.useEffect(() => {
        (async () => {
            if (currentRecord?.recordType) {

                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                try{
                    await apiService.GetWorkflowDiagram(currentRecord?.recordType); 
                }  catch (e) {
                    if (typeof e === "string") {
                        dispatch(setErrorText(e));
                    } else if (e instanceof Error) {
                        dispatch(setErrorText(e.message));
                    }
                }
            }
        })();
    }, [currentRecord, dispatch]); 

    // Current Record
    React.useEffect(() => {
        dispatch(setAppTitle(t("manageRecordPageTitlePrefix") + recordId + t("manageRecordPageTitlePostfix")));
        if (recordId) {
            // has record been fetched yet
            const isFetched: boolean = recordId === currentRecord?.recordId;
            if (!isFetched) {
                dispatch(getRecordAsync(recordId, false, true));
                subscribeReloadRecordData(recordId);
            }
            else {
                if (currentRecord?.iWantToOptionCollection){
                    dispatch(setIWantToOptions(currentRecord?.iWantToOptionCollection));
                }            
                // only get the i want to options after loading the record
                dispatch(getIWantToOptionsAsync(recordId));
                if(forceReloadData) {
                    dispatch(setIsLoading(false));
                    subscribeReloadRecordData(recordId);
                    dispatch(setForceReloadData(false));
                }
            }
        }
    }, [currentRecord?.recordId,
        currentRecord?.timelineEntries,
        currentRecord?.iWantToOptionCollection,
        dispatch,
        forceReloadData,
        recordId,
        subscribeReloadRecordData,
        t]);

    React.useEffect(() => {
        if (currentRecord && currentRecord.recordType && isFirstLoad) {
            dispatch(getFieldAsync(true, currentRecord.recordType));
            setIsFirstLoad(false);
        }
    }, [currentRecord, dispatch, isFirstLoad]);

    React.useEffect(() => {
        if (currentRecord?.canCurrentUserEditRecord && urlKey) {
            ScrollHelper.scrollTo(SCROLL_KEYS.TABS);
        }
    }, [currentRecord?.canCurrentUserEditRecord, urlKey]);

    const preparedByArray: (string | undefined)[] = [
        currentRecord?.organisationLv1,
        currentRecord?.organisationLv2,
        currentRecord?.organisationLv3,
        currentRecord?.organisationLv4
    ];
    const preparedby: string = preparedByArray.filter(v => !!v).join(", ");

    const manageRecordDetailsComponent: JSX.Element = React.useMemo(() => {
        let manageRecorodDetailsComponent: JSX.Element | undefined = undefined;
        switch (formType) {
            case ManageFormType.Event:
                manageRecorodDetailsComponent = <ManageEventRecordDetails />;
                break;
            case ManageFormType.Correspondence:
                manageRecorodDetailsComponent = <ManageRecordDetails />;
                break;
            case ManageFormType.Parliamentary:
                manageRecorodDetailsComponent = <ManageParliamRecordDetails />;
                break;
            default:
                manageRecorodDetailsComponent = <ManageRecordDetails />;
        }
        return manageRecorodDetailsComponent;
    }, [formType]);

    const isAnyKeyDates: boolean = currentFields?.some(v => (v.fieldType === FieldTypes.DateTime || v.fieldType === FieldTypes.DateOnly) && v.isActive) || false;

    // 6. RETURN RENDER TEMPLATE
    const manageRecordTabs: ITab[] = React.useMemo(() => {
        const tabs: ITab[] = [
            {
                name: activityTabName,
                key: activityTabKey,
                content: currentRecord && currentUser ? (
                    <RecordTimeline
                        user={currentUser}
                        record={currentRecord}
                        fields={currentFields || []}
                        isMobileView={isMobile}
                        timelineItems={currentRecord.timelineEntries}
                    />
                ) : (
                    <div>Loading...</div>
                )
            },
            {
                name: documentsTabName,
                key: documentsTabKey,
                content: <ManageRecordDocuments />
            }           
        ];

        if(isAnyKeyDates){
            tabs.push( {
                name: keyDatesTabName,
                key: keyDatesTabKey,
                content: <ManageRecordKeyDates />
            });
        }

        tabs.push(...[
            {
                name: peopleAndRolesTabName,
                key: peopleAndRolesTabKey,
                content: <ManageRecordKeyRoles />
            },
            {
                name: relatedRecordsTabName,
                key: relatedRecordsTabKey,
                content: <ManageRecordRelatedRecords />
            },
            {
                name: accessTabName,
                key: accessTabKey,
                content: <AccessPermissions isMobileView={isMobile} isCreateRecordWizard={false} />
            },
            {
                name: notificationsTabName,
                key: notificationsTabKey,
                content: <ManageRecordNotifications></ManageRecordNotifications>
            },
            {
                name: detailsTabName,
                key: detailsTabKey,
                content: manageRecordDetailsComponent || <></>
            }
        ]);

        return tabs;
    }, [currentRecord, currentUser, currentFields, isMobile, isAnyKeyDates, manageRecordDetailsComponent]);

    // Checking the current tab & the documents tab's form mode.
    if (urlKey == documentsTabKey && currentDocumentTabMode == FormMode.Edit) {
        // Returning the document edit form component
        return (
            <>
                <Breadcumbs
                    pageName={breadcrumbManageRecord}
                    record={currentRecord ? currentRecord : undefined}
                />
                <div className={styles.manageRecordContainer}>
                    <ManageRecordEditDocuments />
                </div>
            </>
        );
    }

    if (urlKey === copyRecordTabKey) {
        return (
            <>
                <Breadcumbs
                    pageName={breadcrumbCopyRecord}
                    record={currentRecord ? currentRecord : undefined}
                    isThirdStep={true}
                />
                <div className={styles.manageRecordContainer}>
                    <WizardCopyRecord isSupersede={false} />
                </div>
            </>
        );
    }

    if (urlKey === supersedeRecordTabKey) {
        return (
            <>
                <Breadcumbs
                    pageName={breadcrumbSupersedeRecord}
                    record={currentRecord ? currentRecord : undefined}
                    isThirdStep={true}
                />
                <div className={styles.manageRecordContainer}>
                    <WizardCopyRecord isSupersede={true} />
                </div>
            </>
        );
    }

    return (
        <>
            <Breadcumbs
                pageName={breadcrumbManageRecord}
                record={currentRecord ? currentRecord : undefined}
            />
            {!errorMessage && isLoading && (
                <Spinner size={SpinnerSize.large} label={`Loading record ${recordId}...`} ariaLabel={"Loading spinner"} />
            )}
            {errorMessage && (
                <div className={styles.noResultMessage}>
                    <MessageBar
                        isMultiline={true}
                        messageBarType={MessageBarType.error}
                    >
                        {errorMessage}
                    </MessageBar>
                </div>
            )}
            {!isLoading && currentRecord && (
                <>
                    <div className={styles.manageRecordContainer}>
                        <div className={`${styles.recordHeaderContainer} ${isMobile ? styles.recordHeaderContainerMobile : ""}`}>
                            <h1 className={styles.recordTitle}>{currentRecord.title}</h1>
                            <div className={styles.buttons}>
                                <IWantButton
                                    title={"I want to"}
                                    record={currentRecord}
                                    fields={currentFields || []}
                                    isFetchOptions={true}
                                    positionNextToBreadCrumbs={isMobile}
                                />
                                <ViewRecordActivity recordId={currentRecord.recordId} />
                            </div>
                        </div>
                        <div className={styles.recordIdContainer}>
                            <Icon iconName="TextDocument" className={styles.icon} />
                            <span className={styles.recordId}>{currentRecord.recordId}</span>
                        </div>
                        <h2 className={styles.recordType}>
                            {currentRecord.recordType}
                        </h2>
                        <div className={styles.recordResponseBy}>
                            <b>Prepared by:</b> {preparedby}
                        </div>
                        {isFieldInActive("ABCRequestFrom", inactiveFields) ? (<></>) : (
                            <div className={styles.recordRequestedBy}>
                                <b>Requested by:</b> {(currentRecord.requestFrom || []).map(r => r.name).join(", ")}
                            </div>
                        )}
                        <div className={styles.securityAccess}>
                            <b>Security access:</b> {currentRecord.overrideDefaultAccess
                                ? (currentRecord.restrictAccessTeams && currentRecord.restrictAccessTeams.length > 0
                                    ? `Restricted to ${currentRecord.restrictAccessTeams.map(r => r.name).join(", ")} and named users`
                                    : "Restricted to named users"
                                ) : "Default access"}
                        </div>
                        {currentFields?.filter(v => v.showInHeader).map((field, index) => {
                            let textValue: string | undefined = currentRecord.fieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                            if (field.fieldType === FieldTypes.MultiSelectManagedMetadata 
                                || field.fieldType === FieldTypes.SelectManagedMetadata 
                                || field.fieldType === FieldTypes.RadioManagedMetadata ) {
                                textValue = textValue ? (JSON.parse(textValue) as { Id: string, Name: string }[]).map(v => v.Name).join(", ") : textValue;
                            }
                            return (
                                <div key={index} className={styles.recordRequestedBy}>
                                    <b>{field.displayName}:</b> {textValue}
                                </div>
                            );
                        })}
                    </div>
                    <OverviewRecommendPanel />
                    <TabsController
                        tabs={manageRecordTabs}
                        mobileView={isMobile}
                        defaultTabKey={activityTabKey}
                    />
                </>
            )}
        </>
    );
}