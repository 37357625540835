import {
    useParams
} from "react-router-dom";
//1. IMPORTS
import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    getIWantToOptionsAsync,
    selectActiveFields,
    selectCurrentRecord,
    selectCurrentRecordType,
    updateRecordKeyRolesAsync
} from "./manageRecordWrapperSlice";
import styles from "./ManageRecordKeyRoles.module.scss";
import {
    FormMode,
    IWizardKeyRolesModelState,
    resetWizardKeyRoles,
    selectChangeReason,
    setChangeReason,
    setWizardKeyRolesFormMode,
    setWizardKeyRolesModel
} from "../wizardKeyRoles/wizardKeyRolesSlice";
import {
    WizardKeyRolesForm
} from "../wizardKeyRoles/WizardKeyRolesForm";
import {
    IContentType,
    IContentTypeField,
    IRecord,
    IUpdateRecordKeyRolesRequest
} from "../../services/Api/executor/IApiServiceExecutor";
import { castRecordToKeyRoles } from "../../helpers/KeyRolesHelper";
import { editKeyRolesHeader } from "../../providers/Constants/RecordWizardConstants";
import { useTranslation } from "react-i18next";

export function ManageRecordKeyRoles(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const { t } = useTranslation();

    const selectedChangeReason: string | null = useSelector(selectChangeReason);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const recordType: IContentType | null = useSelector(selectCurrentRecordType);
    // 3. DEFINE COMPONENT STATE
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // 5. DEFINE COMPONENT HOOKS
    const { recordId } = useParams<{ recordId: string }>();
    const keyRolesFromRecord: Partial<IWizardKeyRolesModelState> | null = React.useMemo(() => {
        return castRecordToKeyRoles(currentRecord);
    }, [currentRecord]);

    React.useEffect(() => {
        if (keyRolesFromRecord && Object.keys(keyRolesFromRecord).length) {
            //INIT WIZARD WITH CURRENT RECORD
            dispatch(setWizardKeyRolesModel(keyRolesFromRecord));
            dispatch(setWizardKeyRolesFormMode(FormMode.Edit));
        }
        return () => {
            //FLUSH WIZARD
            dispatch(resetWizardKeyRoles());
        };
    }, [dispatch, keyRolesFromRecord]);

    const onRoleChange: (
        comments: string | null,
        user: Partial<IUpdateRecordKeyRolesRequest>
    ) => void = React.useCallback(
        (comments: string | null, user: Partial<IUpdateRecordKeyRolesRequest>) => {
            if (recordId && !updatingInProgress) {
                setUpdatingInProgress(true);
                dispatch(
                    updateRecordKeyRolesAsync(
                        {
                            ...user,
                            recordId: recordId,
                            comments: comments || ""
                        },
                        {
                            onSuccess: () => {
                                setUpdatingInProgress(false);
                                if (!selectedChangeReason) {
                                    dispatch(setChangeReason(""));
                                }
                                // TODO: only really need to do this if the user being added/remove is the current user
                                // because otherwise it won't/can't impact the current users I Want To options.
                                dispatch(getIWantToOptionsAsync(recordId));
                            },
                            onError: err => {
                                setError(err);
                                setUpdatingInProgress(false);
                            }
                        }
                    )
                );
            }
        },
        [dispatch, recordId, selectedChangeReason, updatingInProgress]
    );

    return (
        <div className={styles.manageRecordKeyRolesContainer}>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>{editKeyRolesHeader}</h2>
            </div>
            <div>{t("rolesTabDescription")}</div>
            <div className={styles.header}>{!!error && <div>{error.message}</div>}</div>
            <div>
                <WizardKeyRolesForm
                    inProgress={updatingInProgress}
                    fields={currentFields || undefined}
                    recordType={recordType || undefined}
                    readOnly={
                        !currentRecord?.canCurrentUserEditRecord || !!currentRecord?.canCurrentUsedEditRecordAdminLimitedAccess
                    }
                    onRoleChange={onRoleChange}
                />
            </div>
            <div className={styles.footer}></div>
        </div>
    );
}
