// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../../app/store";
import { isCicSelected } from "../../../helpers/ModelHelper";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import { IContact, IOrgLevel1, IOrgLevel2, IOrgLevel3, IOrgLevel4, IOrgs, IRecord, ITerm, ITermGroup, ITermSet } from "../../../services/Api/executor/IApiServiceExecutor";
import IApiService from "../../../services/Api/IApiService";
import { selectSelectedRecordType } from "../../wizardCreateRecord/wizardCreateRecordSlice";
import { setTermGroup } from "../../wizardRecordInformation/wizardRecordInformationSlice";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// 3. FEATURE STATE
export interface IManageRecordDetailsSliceState {
    decisionCategories: ITermSet | null;
    replyFormatTermSet: ITermSet | null;
    eventNeedsTermSet: ITermSet | null;
    securityClassifications: ITermSet | null;
    mediaCoverageTermSet: ITermSet | null;
    parliamentarySourceTermSet: ITermSet | null;
    recommendationTypesTermSet: ITermSet | null;
    parliamentMemberTermSet: ITermSet | null;
    recordFlags: ITerm[] | null;

    selectedRecordType: string | null;
    orgHierarchy: IOrgs | null;
    selectedDecisionCategory: ITerm | null;
    selectedOrgLevel1: IOrgLevel1 | null;
    selectedOrgLevel2: IOrgLevel2 | null;
    selectedOrgLevel3: IOrgLevel3 | null;
    selectedOrgLevel4: IOrgLevel4 | null;

    enteredTitle?: string;
    selectedDateRequiredBy: Date | null;

    selectedRecordFlags: ITerm[] | null;
    selectedSecurityClassification: ITerm | null;
    selectedCabinetWorkingFileNumber?: string;
    cicSelected:boolean;

    selectedFromContact: IContact | null;
    selectedToContact: IContact[] | null;
    selectedDateWritten: Date | null;
    selectedDateReceived: Date | null;

    overViewLoading: boolean;    

    termGroup: ITermGroup | null;

}

// 4. FEATURE INITIAL STATE
export const initialState: IManageRecordDetailsSliceState = {
    selectedRecordType: null,
    orgHierarchy: null,
    decisionCategories: null,
    selectedDecisionCategory: null,
    selectedOrgLevel1: null,
    selectedOrgLevel2: null,
    selectedOrgLevel3: null,
    selectedOrgLevel4: null,
    replyFormatTermSet: null,
    securityClassifications: null,
    selectedCabinetWorkingFileNumber: undefined,
    eventNeedsTermSet: null,
    mediaCoverageTermSet: null,
    parliamentarySourceTermSet: null,
    enteredTitle: undefined,
    selectedDateRequiredBy: null,
    selectedSecurityClassification: null,
    cicSelected:false,
    selectedFromContact: null,
    selectedToContact: null,
    selectedDateWritten: null,
    selectedDateReceived: null,
    recommendationTypesTermSet: null,
    parliamentMemberTermSet: null,
    overViewLoading: true,
    recordFlags: null,
    selectedRecordFlags: null,
    termGroup: null,
};

// 4. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const manageRecordDetailsSlice = createSlice({
    name: "manageRecordDetailsSlice",
    initialState,
    reducers: {
        resetManageRecordDetails: (state: IManageRecordDetailsSliceState) => {
            for (const key in initialState) {
                const keyAs: keyof IManageRecordDetailsSliceState = key as keyof IManageRecordDetailsSliceState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (state[keyAs] as any) = initialState[keyAs] as any;
            }
        },
        setManageRecordDetails: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<Partial<IRecord>>
        ) => {
            const record: Partial<IRecord> = action.payload;
            state.enteredTitle = record.title;
            state.selectedDateRequiredBy = record.dueDateRequiredBy ? new Date(record.dueDateRequiredBy) : null;
            
            state.selectedDecisionCategory = (record.decisionCategory || [])[0];
            state.selectedRecordType = record.recordType ? record.recordType : null;
            state.selectedOrgLevel1 = castSelectedOrgLevel1(state, record.organisationLv1);
            state.selectedOrgLevel2 = castSelectedOrgLevel2(state, record.organisationLv2);
            state.selectedOrgLevel3 = castSelectedOrgLevel3(state, record.organisationLv3);            
            state.selectedOrgLevel4 = castSelectedOrgLevel4(state, record.organisationLv4);

            //fill it with fake values in case Org Level was removed from AAD to show it on edit form
            addFakeOrgStructureForAADUpdates(state, record);

            state.selectedSecurityClassification = record.securityClassification ? record.securityClassification[0] : null;
            state.selectedCabinetWorkingFileNumber = record.cabinetWorkingFolderNumber;
            state.cicSelected = isCicSelected(state.selectedSecurityClassification);
            state.selectedRecordFlags = (record.recordFlags || []) || null;
            state.selectedFromContact = record.from || null;
            state.selectedToContact = record.to || null;
            state.selectedDateWritten = record.dateWritten ? new Date(record.dateWritten) : null;
            state.selectedDateReceived = record.dateReceived ? new Date(record.dateReceived) : null;
        },
        setDecisionCategoriesTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.decisionCategories = action.payload;
        },
        setOrgHierarchy: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<IOrgs | null>
        ) => {
            state.orgHierarchy = action.payload;
        },
        setSelectedDecisionCategory: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedDecisionCategory = castSelectedDecisionCategory(state, action.payload);
        },
        setSecurityClassificationTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.securityClassifications = action.payload;
        },
        setSelectedOrgLevel1: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel2 = null;
            state.selectedOrgLevel3 = null;
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel1 = castSelectedOrgLevel1(state, action.payload);
        },
        setSelectedOrgLevel2: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel3 = null;
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel2 = castSelectedOrgLevel2(state, action.payload);
        },
        setSelectedOrgLevel3: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel3 = castSelectedOrgLevel3(state, action.payload);
        },
        setSelectedOrgLevel4: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel4 = castSelectedOrgLevel4(state, action.payload);
        },
        setFromContact: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<IContact | null>
        ) => {
            state.selectedFromContact = action.payload;
        },
        setToContact: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<IContact[] | null>
        ) => {
            state.selectedToContact = action.payload;
        },
        setReplyFormatTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.replyFormatTermSet = action.payload;
        },
        setEventNeedsTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.eventNeedsTermSet = action.payload;
        },
        setMediaCoverageTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.mediaCoverageTermSet = action.payload;
        },
        setParliamentarySourceTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.parliamentarySourceTermSet = action.payload;
        },
        setParliamentMemberTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {          
            state.parliamentMemberTermSet = action.payload;            
        },
        setRecommendationTypesTermSet: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.recommendationTypesTermSet = action.payload;
        },
        setTitle: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.enteredTitle = undefined;
            } else {
                state.enteredTitle = action.payload;
            }
        },
        setDateWritten: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<Date | undefined | null>
        ) => {
            if (!action || !action.payload) {
                state.selectedDateWritten = null;
            } else {
                // To Do: Parse Date to string - https://stackoverflow.com/questions/64205930/what-are-the-actual-risks-of-storing-non-serializable-data-items-in-redux-store
                state.selectedDateWritten = action.payload;
            }
        },
        setDateReceived: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<Date | undefined | null>
        ) => {
            if (!action || !action.payload) {
                state.selectedDateReceived = null;
            } else {
                // To Do: Parse Date to string - https://stackoverflow.com/questions/64205930/what-are-the-actual-risks-of-storing-non-serializable-data-items-in-redux-store
                state.selectedDateReceived = action.payload;
            }
        },
        setSelectedRecordFlags: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            const idx: number = (state.selectedRecordFlags || []).findIndex(x => x.id === action.payload);
            if (idx !== -1) {
                state.selectedRecordFlags?.splice(idx, 1) || null;
            } else if (action.payload) {
                const recordFlag: ITerm | undefined = (state.recordFlags || []).find(x=> x.id === action.payload);
                if (recordFlag) {
                    if (state.selectedRecordFlags) {
                        state.selectedRecordFlags.push(recordFlag);
                    } else {
                        state.selectedRecordFlags = [recordFlag];
                    }
                }
            }
        },
        setSelectedSecurityClassification: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.selectedSecurityClassification = null;
            } else {
                const terms: ITerm[] = state.securityClassifications?.terms || [];
                for (let i: number = 0; i < terms.length; i++) {
                    const term: ITerm = terms[i];
                    if (term.id === action.payload) {
                        state.selectedSecurityClassification = term;
                        break;
                    }
                }
            }
            state.cicSelected = isCicSelected(state.selectedSecurityClassification);
            if (!state.cicSelected) {
                state.selectedCabinetWorkingFileNumber = undefined;
            }
        },
        setCabinetWorkingFileNumber: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedCabinetWorkingFileNumber = action.payload;
        },
        setDateRequiredBy: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<Date | undefined | null>
        ) => {
            if (!action || !action.payload) {
                state.selectedDateRequiredBy = null;
            } else {
                // To Do: Parse Date to string - https://stackoverflow.com/questions/64205930/what-are-the-actual-risks-of-storing-non-serializable-data-items-in-redux-store
                state.selectedDateRequiredBy = action.payload;
            }
        },
        setTermGroup: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<ITermGroup | null>
        ) => {
            state.termGroup = action.payload;
        },
        setOverviewLoading: (
            state: IManageRecordDetailsSliceState,
            action: PayloadAction<boolean>
        ) => {
            state.overViewLoading = action.payload;
        },
    }
});

// 4. SYNCHRONOUS ACTIONS
const {
    setDecisionCategoriesTermSet,
    setOrgHierarchy,
    setMediaCoverageTermSet,
    setParliamentarySourceTermSet,
    setParliamentMemberTermSet,
    setEventNeedsTermSet,
    setReplyFormatTermSet,
    setRecommendationTypesTermSet,
    setSecurityClassificationTermSet
} = manageRecordDetailsSlice.actions;

export const {
    resetManageRecordDetails,
    setManageRecordDetails,
    setSelectedDecisionCategory,
    setSelectedOrgLevel1,
    setSelectedOrgLevel2,
    setSelectedOrgLevel3,
    setSelectedOrgLevel4,
    setTitle,
    setDateWritten,
    setDateReceived,
    setDateRequiredBy,
    setSelectedSecurityClassification,
    setCabinetWorkingFileNumber,
    setFromContact,
    setToContact,
    setOverviewLoading,
    setSelectedRecordFlags,
} = manageRecordDetailsSlice.actions;

// 5. ASYNCHRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getManageDetailsDataAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    const replyFormats: ITermSet | null = await apiService.GetReplyFormatsTermSet();
    dispatch(setReplyFormatTermSet(replyFormats));

    const itemsForEvent: ITermSet | null = await apiService.GetItemsForEventTermSet();
    dispatch(setEventNeedsTermSet(itemsForEvent));

    const mediaCoverage: ITermSet | null = await apiService.GetMediaCoverageTermSet();
    dispatch(setMediaCoverageTermSet(mediaCoverage));

    const parliamentarySource: ITermSet | null = await apiService.GetParliamentarySourceTermSet();
    dispatch(setParliamentarySourceTermSet(parliamentarySource));
    
    const parliamentMembers: ITermSet | null = await apiService.GetParliamentMembersTermSet();
    dispatch(setParliamentMemberTermSet(parliamentMembers));

    const recommendationTypes: ITermSet | null = await apiService.GetRecommendationTypesTermSet();
    dispatch(setRecommendationTypesTermSet(recommendationTypes));

    const securityClassification: ITermSet | null = await apiService.GetSecurityClassificationTermSet();
    dispatch(setSecurityClassificationTermSet(securityClassification));

    //Load Term Group from API
    const termGroup: ITermGroup | null = await apiService.GetAllTermSets();
    dispatch(setTermGroup(termGroup));

    const [
        decisionCategoryTermSet,
        org
    ] = await Promise.all([
        apiService.GetDecisionCategoryTermSet(),
        apiService.GetOrgHierarchy()
    ]);
    dispatch(setDecisionCategoriesTermSet(decisionCategoryTermSet));
    dispatch(setOrgHierarchy(org));
};

export const getRecommendationTypesAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const recommendationTypes: ITermSet | null = await apiService.GetRecommendationTypesTermSet();
    dispatch(setRecommendationTypesTermSet(recommendationTypes));
};

const getDecisionCategoriesForRecordType = (
    selectedRecordType: string | null,
    decisionCategories: ITerm[]
): ITerm[] => {
    if (selectedRecordType) {
        for (let i: number = 0; i < decisionCategories.length; i++) {
            const type: ITerm = decisionCategories[i];
            if (type.name === selectedRecordType) {
                return type.terms || [];
            }
        }
    }
    return [];
};

const castSelectedDecisionCategory = (
    state: IManageRecordDetailsSliceState,
    id: string | undefined
) => {
    let result: ITerm | null = null;
    if (id || state?.orgHierarchy?.orgLevel1) {
        const recordTypes: ITerm[] = state.decisionCategories?.terms || [];
        for (let i: number = 0; i < recordTypes.length; i++) {
            const dcsForRecordType: ITerm[] = recordTypes[i].terms || [];
            for (let j: number = 0; j < dcsForRecordType.length; j++) {
                const o: ITerm = dcsForRecordType[j];
                if (o.id === id) {
                    result = o;
                }
            }
        }
    }
    return result;
};

const castSelectedOrgLevel1 = (
    state: IManageRecordDetailsSliceState,
    orgName: string | undefined
): IOrgLevel1 | null => {
    let result: IOrgLevel1 | null = null;
    if (orgName && state?.orgHierarchy?.orgLevel1) {
        for (let i: number = 0; i < state.orgHierarchy.orgLevel1.length; i++) {
            const o: IOrgLevel1 = state.orgHierarchy.orgLevel1[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const castSelectedOrgLevel2 = (
    state: IManageRecordDetailsSliceState,
    orgName: string | undefined
): IOrgLevel2 | null => {
    let result: IOrgLevel2 | null = null;
    if (orgName && state.selectedOrgLevel1?.orgLevel2) {
        for (let i: number = 0; i < state.selectedOrgLevel1.orgLevel2.length; i++) {
            const o: IOrgLevel2 = state.selectedOrgLevel1.orgLevel2[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const castSelectedOrgLevel3 = (
    state: IManageRecordDetailsSliceState,
    orgName: string | undefined
): IOrgLevel3 | null => {
    let result: IOrgLevel3 | null = null;
    if (orgName && state.selectedOrgLevel2?.orgLevel3) {
        for (let i: number = 0; i < state.selectedOrgLevel2.orgLevel3.length; i++) {
            const o: IOrgLevel3 = state.selectedOrgLevel2.orgLevel3[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};
const castSelectedOrgLevel4 = (
    state: IManageRecordDetailsSliceState,
    orgName: string | undefined
): IOrgLevel4 | null => {
    let result: IOrgLevel4 | null = null;
    if (orgName && state.selectedOrgLevel3?.orgLevel4) {
        for (let i: number = 0; i < state.selectedOrgLevel3.orgLevel4.length; i++) {
            const o: IOrgLevel3 = state.selectedOrgLevel3.orgLevel4[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const addFakeOrgStructureForAADUpdates = (state: IManageRecordDetailsSliceState, record: Partial<IRecord>): void => {
    //fill it with fake values in case Org Level was removed from AAD.
    // Just to make current record's value make available on edit form
    // -----------  LVL 4 --------------------
    if(!!record.organisationLv4 && record.organisationLv4.length > 0 && state.selectedOrgLevel4 === null){
        state.selectedOrgLevel4 = {
            name: record.organisationLv4
        };
    }

    // -----------  LVL 3 --------------------
    if(!!record.organisationLv3 && record.organisationLv3.length > 0 && state.selectedOrgLevel3 === null){
        state.selectedOrgLevel3 = {
            name: record.organisationLv3, 
            orgLevel4: []
        };
    }
    //add level4 to level3 options if required
    if(state.selectedOrgLevel3 && state.selectedOrgLevel4?.name){
        state.selectedOrgLevel3.orgLevel4 = state.selectedOrgLevel3.orgLevel4 || [];
        const lvl4name: string = state.selectedOrgLevel4.name;
        if(state.selectedOrgLevel3.orgLevel4.every(x=>x.name !== lvl4name)){
            state.selectedOrgLevel3.orgLevel4.push(state.selectedOrgLevel4);
        }
    }

    // -----------   LVL 2 --------------------
    if(!!record.organisationLv2 && record.organisationLv2.length > 0 && state.selectedOrgLevel2 === null){
        state.selectedOrgLevel2 = {
            name: record.organisationLv2, 
            orgLevel3: []
        };

    }
    //add level3 to level2 options if required
    if(state.selectedOrgLevel2 && state.selectedOrgLevel3?.name){
        state.selectedOrgLevel2.orgLevel3 = state.selectedOrgLevel2.orgLevel3 || [];
        const lvl3name: string = state.selectedOrgLevel3.name;
        if(state.selectedOrgLevel2.orgLevel3.every(x=>x.name !== lvl3name)){
            state.selectedOrgLevel2.orgLevel3.push(state.selectedOrgLevel3);
        }
    }

    // -----------   LVL 1 --------------------
    if(!!record.organisationLv1 && record.organisationLv1.length > 0 && state.selectedOrgLevel1 === null){
        state.selectedOrgLevel1 = {
            name: record.organisationLv1, 
            orgLevel2: []
        };

    }
    //add level2 to level1 options if required
    if(state.selectedOrgLevel1 && state.selectedOrgLevel2?.name){
        state.selectedOrgLevel1.orgLevel2 = state.selectedOrgLevel1.orgLevel2 || [];
        const lvl2name: string = state.selectedOrgLevel2.name;
        if(state.selectedOrgLevel1.orgLevel2.every(x=>x.name !== lvl2name)){
            state.selectedOrgLevel1.orgLevel2.push(state.selectedOrgLevel2);
        }
    }

};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectDecisionCategories = (state: RootState): ITerm[] => {
    const recordType: string | null = state.manageRecordDetails.selectedRecordType;
    const selectedRecordType: string | null = recordType ? recordType : selectSelectedRecordType(state)?.rowKey || null;
    const decisionCategories: ITerm[] = state.manageRecordDetails.decisionCategories?.terms || [];
    return getDecisionCategoriesForRecordType(selectedRecordType, decisionCategories);
};
export const selectOrgLevel1s = (state: RootState): IOrgLevel1[] =>
    state.manageRecordDetails.orgHierarchy?.orgLevel1 || [];
export const selectOrgLevel2s = (state: RootState): IOrgLevel2[] =>
    state.manageRecordDetails.selectedOrgLevel1?.orgLevel2 || [];
export const selectOrgLevel3s = (state: RootState): IOrgLevel3[] =>
    state.manageRecordDetails.selectedOrgLevel2?.orgLevel3 || [];
export const selectOrgLevel4s = (state: RootState): IOrgLevel4[] =>
    state.manageRecordDetails.selectedOrgLevel3?.orgLevel4 || [];

export const selectSelectedDecisionCategory = (state: RootState): ITerm | null =>
    state.manageRecordDetails.selectedDecisionCategory;
export const selectSelectedOrgLevel1 = (state: RootState): IOrgLevel1 | null =>
    state.manageRecordDetails.selectedOrgLevel1;
export const selectSelectedOrgLevel2 = (state: RootState): IOrgLevel2 | null =>
    state.manageRecordDetails.selectedOrgLevel2;
export const selectSelectedOrgLevel3 = (state: RootState): IOrgLevel3 | null =>
    state.manageRecordDetails.selectedOrgLevel3;
export const selectSelectedOrgLevel4 = (state: RootState): IOrgLevel4 | null =>
    state.manageRecordDetails.selectedOrgLevel4;

export const selectEnteredTitle = (state: RootState): string | undefined =>
    state.manageRecordDetails.enteredTitle;

export const selectDateRequiredBy = (state: RootState): Date | undefined =>
    state.manageRecordDetails.selectedDateRequiredBy || undefined;
export const selectRecordFlags = (state: RootState): ITerm[] =>
    state.manageRecordDetails.recordFlags || [];

export const selectSelectedRecordFlags = (state: RootState): ITerm[] | null =>
    state.manageRecordDetails.selectedRecordFlags;

export const selectSecurityClassificationsTypes = (state: RootState): ITerm[] =>
    state.manageRecordDetails.securityClassifications?.terms || [];
export const cicSelected = (state: RootState): boolean =>
    state.manageRecordDetails.cicSelected;
export const selectCabinetWorkingFileNumber = (state: RootState): string | undefined =>
    state.manageRecordDetails.selectedCabinetWorkingFileNumber;

export const selectReplyFormats = (state: RootState): ITerm[] =>
    state.manageRecordDetails.replyFormatTermSet?.terms || [];
export const selectSelectedSecurityClassificationsType = (state: RootState): ITerm | null =>
    state.manageRecordDetails.selectedSecurityClassification;

export const selectEventNeeds = (state: RootState): ITerm[] =>
    state.manageRecordDetails.eventNeedsTermSet?.terms || [];
export const selectMediaCoverage = (state: RootState): ITerm[] =>
    state.manageRecordDetails.mediaCoverageTermSet?.terms || [];
export const selectParliamentarySource = (state: RootState): ITerm[] =>
    state.manageRecordDetails.parliamentarySourceTermSet?.terms || [];
export const selectParliamentMembers = (state: RootState): ITerm[] =>
    state.manageRecordDetails.parliamentMemberTermSet?.terms || [];

export const selectDateWritten = (state: RootState): Date | undefined =>
    state.manageRecordDetails.selectedDateWritten || undefined;
export const selectDateReceived = (state: RootState): Date | undefined =>
    state.manageRecordDetails.selectedDateReceived || undefined;
export const selectFromContact = (state: RootState): IContact | undefined =>
    state.manageRecordDetails.selectedFromContact || undefined;
export const selectToContact = (state: RootState): IContact[] | undefined =>
    state.manageRecordDetails.selectedToContact || undefined;

export const selectDecisionCategoryTermSet = (state: RootState): ITermSet | null =>
    state.manageRecordDetails.decisionCategories;
export const selectOrgHierarchy = (state: RootState): IOrgs | null =>
    state.manageRecordDetails.orgHierarchy;

export const selectRecommendationTypes = (state: RootState): ITerm[] =>
    state.manageRecordDetails.recommendationTypesTermSet?.terms || [];

export const selectOverviewLoading = (state: RootState): boolean =>
    state.manageRecordDetails.overViewLoading;

export const selectTermGroup = (state: RootState): ITermGroup | null =>
    state.manageRecordDetails.termGroup || null;

// 7. EXPORT REDUCER
export default manageRecordDetailsSlice.reducer;
