import {
    Link
} from "react-router-dom";
import {
    useSelector
} from "react-redux";
import React from "react";
import {
    Icon
} from "@fluentui/react";
import styles from "./WizardRecordConfirmation.module.scss";
import {
    selectCreatedRecords,
    selectFailedToCreateRecords,
    selectIsMultipleRecordsCreation
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import {
    ICreateRecordRequest,
    IRecord
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    HeaderStrings
} from "../../providers/Constants/RecordWizardConstants";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { AppRoutes } from "../../app/Constants";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export function WizardRecordConfirmation(): JSX.Element {
    const createdRecords: IRecord[] = useSelector(selectCreatedRecords);
    const failedToCreateRecords: ICreateRecordRequest[] = useSelector(selectFailedToCreateRecords);
    const isMultipleRecordsCreation: boolean = useSelector(selectIsMultipleRecordsCreation);
    const isCreated : boolean = createdRecords.length > 0;
    const {t} = useTranslation();
    const headerstrings: HeaderStrings = isCreated 
        ? (isMultipleRecordsCreation ? {
            Header: t("recordsCreatedHeaderStrings_Header"),
        } : {
            Header: t("recordCreatedHeaderStrings_Header"),
        })
        : (isMultipleRecordsCreation ? {
            Header:  t("recordsCreatingHeaderStrings_Header")
        } : {
            Header: t("recordCreatingHeaderStrings_Header")
        });
    return (
        <>
            <div className={styles.confirmationContainer}>
                <FormHeader
                    strings={headerstrings}
                />
                <div className={styles.recordIds}>
                    {createdRecords.map((createdRecord: IRecord) => (
                        <Link key={createdRecord.recordId} to={AppRoutes.buildUrl(AppRoutes.manageRecord, { recordId: createdRecord.recordId || "" })}>
                            <div className={styles.recordID}>
                                <Icon iconName="TextDocument" className={styles.icon} ariaLabel={"Document icon"}/>
                                {createdRecord.recordId}
                            </div>
                        </Link>))
                    }
                </div>
                <div>
                    {failedToCreateRecords.map((notCreatedRecord: ICreateRecordRequest) => (
                        <MessageBar
                            key={notCreatedRecord.title}
                            isMultiline={false}
                            messageBarType={MessageBarType.error}
                        >
                            {`Failed to create the record: ${notCreatedRecord.title}`}
                        </MessageBar>                        
                    ))}
                </div>
                {!failedToCreateRecords.length && 
                    <div className={styles.description}>
                        {!isCreated ? t("recordCreatingDescription") : t("recordCreatedDescription")}
                    </div>
                }
            </div>
        </>
    );
}
