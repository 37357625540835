import {
    AccountInfo
} from "@azure/msal-browser";
import IAuthService from "./IAuthService";
import IAuthServiceExecutor, {
    IAccessToken, IAuthApiConfig
} from "./executor/IAuthServiceExecutor";
import ILogProvider from "../../providers/Log/ILogProvider";
import ICacheProvider, {
    CacheTimeout
} from "../../providers/Cache/ICacheProvider";

export default class AuthService implements IAuthService {
    private executor: IAuthServiceExecutor;
    private logProvider: ILogProvider;
    private cacheProvider: ICacheProvider;
    private account: AccountInfo | null = null;
    private authApiConfig: IAuthApiConfig | null = null;

    constructor(
        executor: IAuthServiceExecutor,
        logProvider: ILogProvider,
        cacheProvider: ICacheProvider
    ) {
        this.executor = executor;
        this.logProvider = logProvider;
        this.cacheProvider = cacheProvider;
    }

    public async SignIn(): Promise<AccountInfo | null> {
        if (!this.account) {
            this.account = await this.executor.SignIn();
        }
        return this.account;
    }

    public async GetAccessToken(): Promise<string> {
        const account: AccountInfo | null = await this.SignIn();
        const cacheKey: string = `AuthService.GetAccessToken.${account?.username}.${account?.tenantId}`;
        let cachedToken: IAccessToken = await this.cacheProvider.Get<IAccessToken>(cacheKey);
        if (!cachedToken) {
            cachedToken = await this.executor.GetAccessToken(account);

            // NOTE: MSAL still checks validity of token too often - this is faster.
            // if token has at least 12 minutes left, then cache it for 10.
            const now: Date = new Date();
            now.setMinutes(now.getMinutes() + 12);
            if (cachedToken.expiresOn && cachedToken.expiresOn.getTime() > now.getTime()) {
                await this.cacheProvider.Set(cacheKey, cachedToken, CacheTimeout.default);
            }
        }
        return cachedToken.accessToken;
    }

    public async GetAuthApiConfig(): Promise<IAuthApiConfig> {
        if (!this.authApiConfig) {
            this.authApiConfig = await this.executor.GetAuthApiConfig();
        }
        return this.authApiConfig;
    }
}
