import {
    useDispatch,
    useSelector
} from "react-redux";
// 1. IMPORTS
import React from "react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    ActionButton,
    DefaultButton,
    Separator,
    Selection,
    SearchBox,
    SelectionMode,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import {
    IOrderBy,
    resetRecordsTable,
    selectColumns,
    selectOrderBy,
    selectRecords,
    selectRefiners,
    setColumns,
    setOrderBy,
    setRecords,
    setRefiners
} from "./recordsTableSlice";
import {
    buildRecordTableColumn,
    buildRefinableFilterString
} from "./RecordsTableCommon";
import styles from "./RecordsTable.module.scss";
import RecordsTableColumnsPanel from "../recordsTableColumns/RecordsTableColumnsPanel";
import IApiService from "../../services/Api/IApiService";
import {
    IGetRecordsRequest,
    IRecordColumn,
    IRecordResult,
    IRecordResultColumn,
    IRecordResults,
    RecordViewType,
    IGenerateExportPdfPackRequest,
    IGenerateExportCsvPackRequest,
    IBaseFile
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    CSVButtonText,
    EditColumnText,
    PDFButtonText,
    PDFButtonTextAlt,
    PDFButtonModalTitle,
    PDFButtonModalBlurb1,
    PDFButtonModalBlurb2,
    PDFButtonModalBlurb3,
    PDFButtonModalBlurb4,
    // PDFButtonModalBlurb5,#2177: 
    PDFBatchLimit,
    PDFButtonModalBlurbExceeded,
    SearchFieldPlaceholder,
    CSVButtonTextAlt
} from "../../providers/Constants/FormControlConstants";
import {
    IRefiner
} from "../../model/Refiner";
import useDebounce from "../../helpers/Debounce";
import {
    TableType
} from "../../components/FormControls/Table/TableType";
import Table from "../../components/FormControls/Table/Table";
import {
    ITableColumn
} from "../../components/FormControls/Table/ITableColumn";
import ModalContainer from "../../components/FormControls/ModalContainer/ModalContainer";
import RefinerPanel from "../../components/FormControls/Refiner/RefinerPanel";
import RefinerActiveFilters from "../../components/FormControls/Refiner/RefinerActiveFilters";
import Cards from "../../components/FormControls/Cards/Cards";
import {
    selectProxy
} from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";

// 2. DEFINE PROPS
interface IRecordsTableProps {
    displayMobile: boolean;
    tableType: TableType;
    hideRefiner: boolean;
    hideSearchBox: boolean;
    hideExportPdf: boolean;
    hideExportCsv: boolean;
    hideEditCols: boolean;
    hideSelection: boolean;
    hideHeaderControls: boolean;
    openRecordInNewTab?: boolean;
    managedProperties?: string[];
}

/*
TODO:
- wrap here Refiner, Table, SearchBox components
- provide all needed callback actions for Refiner, Table, SearchBox components
- use Redux store on that level, for managing state
- use useEffect for re-requesting data (Table and Refiner) based on Redux store changes
*/

export function RecordsTable(props: IRecordsTableProps): JSX.Element {
    const { t } = useTranslation();
    // 3. SELECT CURRENT GLOBAL STATE
    const proxy: string | undefined = useSelector(selectProxy);
    const records: IRecordResult[] | undefined = useSelector(selectRecords);
    const columns: IRecordResultColumn[] | undefined = useSelector(selectColumns);
    const refiners: IRefiner[] | undefined = useSelector(selectRefiners);
    const orderBy: IOrderBy | undefined = useSelector(selectOrderBy);
    
    // 4. DEFINE COMPONENT STATE
    const [panelIsOpen, setPanelIsOpen] = React.useState<boolean>(false);
    const [recordsInProgress, setRecordsInProgress] = React.useState<boolean>(false);
    const [refinerInProgress, setRefinerInProgress] = React.useState<boolean>(false);
    const [recordsErrorMessage, setRecordsErrorMessage] = React.useState<string | undefined>();
    const [refinerErrorMessage, setRefinerErrorMessage] = React.useState<string | undefined>();
    const [availbleRefiners, setAvailbleRefiners] = React.useState<IRefiner[] | undefined>();
    const [showColumnsPanel, setShowColumnsPanel] = React.useState<boolean>(false);    

    const [srchText, setSrchText] = React.useState<string | undefined>("");
    const debouncedSrchText: string | undefined = useDebounce(srchText, 400);

    const [itemsSelectedCount, setItemsSelectedCount] = React.useState<number>(0);
    const [itemIndexesSelected, setItemIndexesSelected] = React.useState<number[]>([]);
    const [exportPDFModalOpen, setExportPDFModalOpen] = React.useState<boolean>(false);
    const [exportPDFRequestSubmitted, setExportPDFRequestSubmitted] = React.useState<boolean>(false);
    const [exportPdfResponse, setExportPdfResponse] = React.useState<string|null>(null);

    const [showCsvColumnsPanel, setShowCsvColumnsPanel] = React.useState<boolean>(false);
    const [csvExportFile, setCsvExportFile] = React.useState<IBaseFile | undefined>();
    const [csvExportError, setCsvExportError] = React.useState<string | undefined>();

    // 5. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    function onOrderChange(fieldName: string | undefined, isDesc: boolean | undefined): void {
        dispatch(setOrderBy(fieldName ? { fieldName: fieldName, isDesc: !!isDesc } : undefined));
    }

    function onSearchChange(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newSearchText: any
    ): void {
        setSrchText(newSearchText);
    }

    function onRefinersChange(newRefiners: IRefiner[] | undefined): void {
        dispatch(setRefiners(newRefiners));
    }

    function onRefinersPanelOpen(): void {
        setPanelIsOpen(true);
    }

    function onRefinersPanelClose(): void {
        setPanelIsOpen(false);
    }

    const getRequestBody: (
        getRecords: boolean,
        getRefiners: boolean,
        managedProperties?: string[]
    ) => IGetRecordsRequest = React.useCallback(
        (
            getRecords: boolean,
            getRefiners: boolean,
            managedProperties?: string[]
        ): IGetRecordsRequest => {
            const request: IGetRecordsRequest = {
                getRefiners: getRefiners,
                getResults: getRecords,
                recordViewType: RecordViewType.My,
                orderByDescending: orderBy?.isDesc,
                orderByManagedProperty: orderBy?.fieldName,
                proxy: proxy,
                searchTerm: debouncedSrchText,
                filters: buildRefinableFilterString(refiners),
                saveConfiguration: true
            };
            switch (props.tableType) {
                case TableType.ActiveTasksTable:
                    request.recordViewType = RecordViewType.Active;
                    break;
                case TableType.MyRecordsTable:
                    request.recordViewType = RecordViewType.My;
                    break;
                case TableType.OpenRecordsTable:
                    request.recordViewType = RecordViewType.Open;
                    break;
                case TableType.ClosedRecordsTable:
                    request.recordViewType = RecordViewType.Closed;
                    break;
                case TableType.RelatedRecords:
                    request.recordViewType = RecordViewType.All;
                    break;
            }
            if (managedProperties || props.managedProperties) {
                request.managedProperties = managedProperties || props.managedProperties;
            }
            return request;
        },
        [orderBy, props.tableType, props.managedProperties, proxy, refiners, debouncedSrchText]
    );

    const onSelectedCountChange: (s: Selection) => void = React.useCallback(
        (selection: Selection) => {
            setItemsSelectedCount(selection.getSelectedCount());
            setItemIndexesSelected(selection.getSelectedIndices());
        },
        [setItemsSelectedCount]
    );

    const getRefinerData: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const requestBody: IGetRecordsRequest = getRequestBody(false, true);
        const result: IRecordResults | null = await apiService.GetRecords(requestBody);
        return result;
    }, [getRequestBody]);

    const getTableData: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const requestBody: IGetRecordsRequest = getRequestBody(true, false);
        const result: IRecordResults | null = await apiService.GetRecords(requestBody);
        return result;
    }, [getRequestBody]);

    const requestPdfPack = async (): Promise<void> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        if (records) {
            // convert records to string array of ids
            let recordResult: IRecordResult[] = records;
            if (itemIndexesSelected.length) {
                recordResult = itemIndexesSelected.map(idx => records[idx]);
            }
            const recordIds: string = recordResult.map(r => r.recordId?.value).join("?").split("?").join(",");
            const request: IGenerateExportPdfPackRequest = {
                recordIds: recordIds
            };
            const response: string | null = await apiService.GenerateExportPdfPack(request);
            setExportPDFRequestSubmitted(true);
            setItemIndexesSelected([]);
            setExportPdfResponse(response);
        } 

    }; 

    const requestCsvPack =  async (managedProperties: string[]): Promise<void> => {
        setCsvExportError(undefined);

        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        if(records){

            let recordResult: IRecordResult[] = [];
            if (itemIndexesSelected.length && itemIndexesSelected.length != records.length) {
                recordResult = itemIndexesSelected.map(idx => records[idx]);
            }

            const recordIds: string[] = recordResult.map(r => r.recordId?.value || "").filter(v => !!v);
            const request: IGenerateExportCsvPackRequest = getRequestBody(true, false) as IGenerateExportCsvPackRequest;
            request.recordIds = recordIds;
            request.managedProperties = managedProperties;

            const result: IBaseFile | null = await apiService.GenerateExportCsvPack(request);
            if(result){
                setCsvExportFile(result);
            } else {
                setCsvExportError("Unable to create CSV report ...");
            }
        }
    };

    const tableColumns: ITableColumn[] = React.useMemo(() => {
        const cols: IRecordColumn[] = (columns || [])
            .filter(c => !!c.isSelected)
            .map(c => {
                return {
                    sortColumnName: c.managedPropertyName || "",
                    isSortable: c.isSortable || false,
                    key: c.key || "",
                    name: c.displayName || "",
                    type: c.type,
                    isCustom: c.isCustom || false,
                };
            });
        const result: ITableColumn[] = cols.map(c => buildRecordTableColumn(c, !!props.openRecordInNewTab));
        return result;
    }, [columns, props.openRecordInNewTab]);

    React.useEffect(() => {
        setRecordsInProgress(true);
        setRecordsErrorMessage(undefined);
        (async () => {
            const recordsResponse: IRecordResults | null = await getTableData();
            if(recordsResponse){
                dispatch(setColumns(recordsResponse?.columns));
                dispatch(setRecords(recordsResponse?.records));
            } else {
                setRecordsErrorMessage("Unable to retrieve records ...");
            }            
            setRecordsInProgress(false);
        })();
    }, [dispatch, getTableData]);

    React.useEffect(() => {
        return () => {
            dispatch(resetRecordsTable());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (panelIsOpen) {
            setRefinerInProgress(true);
            setRefinerErrorMessage(undefined);
            (async () => {
                const recordsResponse: IRecordResults | null = await getRefinerData();
                if(recordsResponse){
                    setAvailbleRefiners(recordsResponse.refiners);
                } else {
                    setRefinerErrorMessage("Unable to retrieve filters ...");
                }                
                setRefinerInProgress(false);
            })();
        }
    }, [dispatch, getRefinerData, panelIsOpen]);

    const setRecordsColumns: (managedProperties: string[]) => Promise<void> = React.useCallback(
        async (managedProperties: string[]): Promise<void> => {
            try {
                setRecordsInProgress(true);
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const requestBody: IGetRecordsRequest = getRequestBody(
                    true,
                    false,
                    managedProperties
                );
                const result: IRecordResults | null = await apiService.GetRecords(requestBody);
                dispatch(setColumns(result?.columns));
                dispatch(setRecords(result?.records));
                return;
            } finally {
                setRecordsInProgress(false);
            }
        },
        [dispatch, getRequestBody]
    );

    const onCsvPanelCancel: () => void = React.useCallback(() => {
        setShowCsvColumnsPanel(false);
        setCsvExportFile(undefined);
        setCsvExportError(undefined);
    },[setShowCsvColumnsPanel, setCsvExportFile]);

    const hasExeededBatchLimit: boolean | undefined = React.useMemo(() => {
        return itemsSelectedCount > PDFBatchLimit;
    }, [itemsSelectedCount]);

    return (
        <div className={styles.recordsTableContainer}>
            {/* <div className={styles.testPanel}>
                <div>PROXY:{proxy}</div>
                <div>SEARCH:{searchText}</div>
                <div>REFINER:{JSON.stringify(refiners || {})}</div>
                <div>ORDERBY:{JSON.stringify(orderBy || {})}</div>
            </div> */}
            {!props.hideHeaderControls && 
                <div className={styles.header}>
                    <div className={styles.top}>
                        <div className={[styles.section, styles.left, styles.actionsButtons].join(" ")}>
                            {!props.hideRefiner && 
                                <RefinerPanel
                                    refiners={availbleRefiners}
                                    selectedRefiners={refiners}
                                    inProgress={refinerInProgress}
                                    errorMessage={refinerErrorMessage}
                                    onOpen={onRefinersPanelOpen}
                                    onClose={onRefinersPanelClose}
                                    onChange={onRefinersChange}
                                />
                            }
                            {!props.hideSearchBox &&
                                <>
                                    <SearchBox
                                        className={styles.searchBox}
                                        placeholder={SearchFieldPlaceholder}
                                        onSearch={onSearchChange}
                                        ariaLabel={SearchFieldPlaceholder}
                                    />
                                    {!props.displayMobile && 
                                        <div className={styles.resultsCount}>Results:
                                            {` ${recordsInProgress ? "..." : (records || []).length} `}
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        {!props.displayMobile && 
                            <div className={[styles.section, styles.right, styles.actionsButtons].join(" ")}>
                                {!props.hideExportPdf &&
                                    <DefaultButton
                                        text={itemsSelectedCount ? PDFButtonTextAlt : PDFButtonText}
                                        className={styles.exportBtn}
                                        iconProps={{ iconName: "Download" }}
                                        onClick={() => setExportPDFModalOpen(true)}
                                        ariaLabel={`${itemsSelectedCount ? PDFButtonTextAlt : PDFButtonText} button`}
                                    />
                                }
                                {!props.hideExportCsv &&
                                    <DefaultButton
                                        text={itemsSelectedCount ? CSVButtonTextAlt :CSVButtonText}
                                        className={styles.exportBtn}
                                        iconProps={{ iconName: "Download" }}
                                        ariaLabel={`${itemsSelectedCount ? CSVButtonTextAlt : CSVButtonText} button`}
                                        onClick={() => { setShowCsvColumnsPanel(true); }}
                                    />
                                    
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.mid}>
                        {!props.hideRefiner &&
                            <RefinerActiveFilters onChange={onRefinersChange} selectedRefiners={refiners} />
                        }
                    </div>
                    <div className={styles.bot}>
                        {(!props.hideSelection || !props.hideEditCols) &&
                        <>
                            {!props.displayMobile && <Separator className={styles.separator} />}
                            <div className={styles.section}>
                                {!props.hideSelection && !props.displayMobile &&
                                    <div className={styles.noItemsSelectedMsg}>
                                        {!itemsSelectedCount
                                            ? "No items selected"
                                            : `${itemsSelectedCount} items selected`}
                                    </div>
                                }
                                {props.displayMobile && 
                                    <div className={styles.mobileResultsCount}>Records:
                                        {` ${recordsInProgress ? "..." : (records || []).length} `}
                                    </div>
                                }
                                {!props.hideEditCols &&
                                    <ActionButton
                                        className={styles.editColumnBtn}
                                        iconProps={{ iconName: "Edit" }}
                                        onClick={() => setShowColumnsPanel(true)}
                                    >
                                        {EditColumnText}
                                    </ActionButton>
                                }
                            </div>
                        </>}
                    </div>
                </div>
            }
            <div className={styles.table}>
                {!recordsErrorMessage ? 
                    <>
                        {recordsInProgress && (
                            <div className={styles.spinnerContainer}>
                                <Spinner
                                    className={styles.spinner}
                                    size={SpinnerSize.large}
                                    label={t("docListLoadingMessage")}
                                    ariaLabel={"Loading spinner"}
                                />
                            </div>
                        )}
                        {!recordsInProgress && (<div>
                            {!props.displayMobile ? (
                                <Table
                                    items={records}
                                    columns={tableColumns}
                                    isLoading={recordsInProgress}
                                    onOrderBy={onOrderChange}
                                    onSelectionChange={onSelectedCountChange}
                                    selectionMode={props.hideSelection ? SelectionMode.none : undefined}
                                />
                            ) : (
                                <Cards items={records} columns={tableColumns} showDownloadPack={!(props.hideHeaderControls || (props.hideExportCsv && props.hideExportPdf))}/>
                            )}
                        </div>)}
                    </>
                    :
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => { setRecordsErrorMessage(undefined); }}
                        dismissButtonAriaLabel="Close"
                    >
                        {recordsErrorMessage}
                    </MessageBar>
                }
            </div>
            <>
                {showColumnsPanel && (
                    <RecordsTableColumnsPanel
                        title={"Edit columns"}
                        descr={"Please select the columns you want displayed on your dashboard view."}
                        submitBtnTitle={"Save"}
                        cancelBtnTitle={"Cancel"}
                        columns={columns}
                        onSubmit={setRecordsColumns}
                        onCancel={() => setShowColumnsPanel(false)}
                        cancelAfterSubmit={true}
                    />
                )}
                <ModalContainer
                    isOpen={exportPDFModalOpen}
                    title={PDFButtonModalTitle}
                    onSubmit={() => requestPdfPack()}
                    onCancel={() => {
                        setExportPDFModalOpen(false);
                        setExportPDFRequestSubmitted(false);
                        setExportPdfResponse(null);
                    }}
                    submitDisabled={hasExeededBatchLimit}
                    hideFooter={exportPDFRequestSubmitted}
                >
                    <div>
                        {!exportPDFRequestSubmitted &&
                            <div>
                                {itemIndexesSelected.length ? `${PDFButtonModalBlurb1} ${itemIndexesSelected.length} ${PDFButtonModalBlurb2}` : `${PDFButtonModalBlurb3} ${records?.length} ${PDFButtonModalBlurb4}`}
                            </div>
                        }
                        {
                            hasExeededBatchLimit &&
                            <div className={styles.exportPrevent}>
                                {PDFButtonModalBlurbExceeded}
                            </div>
                        }
                        {exportPdfResponse && 
                            <div className={styles.exportPrevent}>
                                {exportPdfResponse}
                            </div>
                        }
                    </div>
                    
                </ModalContainer>
                {showCsvColumnsPanel && (
                    <RecordsTableColumnsPanel
                        title={"Export to Excel"}
                        descr={"Select the columns you want to include in the Excel file."}
                        submitBtnTitle={"Generate file"}
                        cancelBtnTitle={"Cancel"}
                        columns={columns}
                        onSubmit={requestCsvPack}
                        onCancel={onCsvPanelCancel}
                        cancelAfterSubmit={false}
                        replaseSubmitBtn={csvExportFile 
                            ? <DefaultButton 
                                text={"Open file"}
                                primary
                                href={`${csvExportFile.path}?web=1`}
                                target={"_blank"}
                                rel="noreferrer"
                            /> : undefined}
                        errorMessage={csvExportError}
                    />
                )}
            </>
        </div>
    );
}
