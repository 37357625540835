import "./index.css";
import {
    Provider
} from "react-redux";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import React from "react";
import "./i18n";
import {
    initializeIcons
} from "@fluentui/react";
import * as serviceWorker from "./serviceWorker";
import {
    store
} from "./app/store";
import App from "./app/App";
import { TeamsContextProvider } from "./providers/TeamsContextProvider";
import { AppRoutes } from "./app/Constants";
import CacheHelper from "./helpers/CacheHelper";

// Initializing UI Fabric Icons
initializeIcons();

(async ()=>{
    // wait for teams context initialization
    await TeamsContextProvider.Init();
    CacheHelper.init();

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <HashRouter basename={AppRoutes.root}>
                    <App />
                </HashRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();    
})();