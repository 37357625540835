import React from "react";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import { selectCurrentUser, selectProxy, setProxy } from "../../../app/globalSlices/contextSlice";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import styles from "./ViewDashboardDropDown.module.scss";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: "100%" }
};

export function ViewDashboardDropDown(): JSX.Element {
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const proxy: string | undefined = useSelector(selectProxy);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const castedProxyOptions: IDropdownOption[] = React.useMemo((): IDropdownOption[] => {
        if (currentUser?.proxyFor) {
            const proxyOptions: IDropdownOption[] = [
                { key: "myself", text: "Myself", title: "myself" }
            ];
            if (currentUser.proxyFor.length > 1) {
                proxyOptions.push({ key: "allExecutives", text: "All my executives", title: "allExecutives" });
            }
            currentUser.proxyFor.map((proxyUserItem, index) =>
                proxyOptions.push({
                    key: proxyUserItem.siteUserId || index,
                    text: proxyUserItem.displayName || "",
                    title: proxyUserItem.graphUserId || ""
                })
            );
            return proxyOptions;
        } else {
            return [];
        }
    }, [currentUser?.proxyFor]);

    function onProxyChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined
    ) {
        if (option && option.title) {
            let proxyVal: string | undefined = undefined;

            switch (option.title) {
                case "myself":
                    // do nothing
                    break;
                case "allExecutives":
                    proxyVal = "all";
                    break;
                default:
                    proxyVal = `${option.title}`;
            }
            if (proxy !== proxyVal) {
                dispatch(setProxy(proxyVal));
            }
        }
    }

    return currentUser?.proxyFor && !!currentUser?.proxyFor?.length ? (
        <div className={styles.viewDropdownContainer}>
            <Dropdown
                styles={dropdownStyles}
                placeholder="Select view dashboard"
                label="View dashboard for"
                options={castedProxyOptions}
                defaultSelectedKey={"myself"}
                onChange={onProxyChange}
            />
        </div>
    ) : (
        <></>
    );
}
