import React from "react";
import { Link } from "react-router-dom";
import { DefaultButton } from "@fluentui/react";
import styles from "./Cards.module.scss";
import SummaryField from "../SummaryField/SummaryField";
import { IContentType, IRecordResult, ISearchFieldValue } from "../../../services/Api/executor/IApiServiceExecutor";
import DateHelper from "../../../helpers/DateHelper";
import { AppRoutes } from "../../../app/Constants";
import { getRecordColor, getTimeframeColor } from "../../../features/recordsTable/RecordsTableCommon";
import PDFPackButton from "../PdfPackButton/PdfPackButton";
import { PrimaryButton, Selection } from "@fluentui/react";
import { ITableColumn } from "../Table/ITableColumn";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";
import { getUserName } from "../Refiner/UserRefiner";
import {
    downloadPDFPackMobile
} from "../../../providers/Constants/ManageRecordConstants";
import { useTranslation } from "react-i18next";

interface ICardsProps {
    items: IRecordResult[] | undefined;
    columns?: ITableColumn[] | undefined;
    showDownloadPack?: boolean;
    showSelection?: boolean;
    onSelectionChange?: (s: Selection) => void;
}

export default function Cards({ items, columns, showDownloadPack, showSelection, onSelectionChange }: ICardsProps): JSX.Element {
    const { t } = useTranslation();
    const useLessPadding: boolean = true;
    const missingValueText: string = " - ";
    const fieldNameTitle: string = "Title";
    const fieldNameRecordId: string = "recordId";
    const fieldNameCurrentTask: string = "Tasks";
    const fieldNameStage: string = "Stage";
    const fieldNameTimeframe: string = "Timeframe";
    const fieldNameDueToDecisionMaker: string = "Due Date To Decision Maker";
    const defaultColumns:  string[] = ["Record Id", fieldNameTitle, fieldNameCurrentTask, fieldNameStage, fieldNameDueToDecisionMaker, fieldNameTimeframe];
    const optionalColumns: ITableColumn[] = columns !== undefined ? columns.filter(v => defaultColumns.indexOf(v.name) < 0) : [];
    const [selectedIdx, setSelectedIdx] = React.useState<number[]>([]);

    const onToggleSelection = (idx: number, selected: boolean) => {
        let result: number[] = [...selectedIdx];
        if(selected){
            result = [...result, idx];
        } else {                        
            result = result.filter(i => i !== idx);
        }
        setSelectedIdx(result);

        if(onSelectionChange){
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const selection: any = { getSelectedCount: () => { return result.length; }, getSelectedIndices: () => { return result; }};
            onSelectionChange(selection as Selection);
        }
    };

    const getFieldValue = (column:ITableColumn, item:IRecordResult):string => {
        const fieldValue: string | boolean | IContentType | ISearchFieldValue[] | undefined = item[column.key as keyof IRecordResult]?.value;
        let result:string = column.key === "recordType" ? (fieldValue as IContentType)?.rowKey : String(fieldValue);
        switch (column.type) {
            case ManagedPropertyViewType.Date:
                result = fieldValue ? DateHelper.ToDateOnlyString(new Date(String(fieldValue)), true) : missingValueText;
                break;
            case ManagedPropertyViewType.NumberAsTime:
                result = fieldValue ? DateHelper.ToTimeOnlyFromNumber(Number(fieldValue)) || missingValueText : missingValueText;
                break;
            case ManagedPropertyViewType.User:
                result = fieldValue ? getUserName(String(fieldValue)) : missingValueText;
                break;
            default:
                break;
        }        
        return result;
    };

    React.useEffect(() => {
        setSelectedIdx([]);
    }, [items]);

    const isSelected: (idx: number) => boolean = (idx: number) => selectedIdx.some(i => i === idx);
    const isColumnVisible: (columnName: string) => boolean = (columnName: string) => columns === undefined || columns.findIndex(v=>v.name === columnName) >= 0;
    const showSelectionMode: boolean = !!showSelection && !!onSelectionChange;
    return (
        <div className={styles.cardsContainer}>
            {(items || []).map((card: IRecordResult, idx: number) => (
                <div className={styles.card} key={card.recordId?.value}>
                    <Link
                        to={AppRoutes.buildUrl(AppRoutes.manageRecord, {
                            recordId: card.recordId?.value || ""
                        })}
                        className={styles.linkStyling}
                    >
                        <SummaryField
                            fieldName={"Record"}
                            fieldValue={card.recordId?.value}
                            fieldCss={getRecordColor(card.recordType?.value?.prefix || "", card.recordType?.value?.color)}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        // onClickField={() => dispatch(showManageRecord(card?.id))}
                        />
                    </Link>
                    {isColumnVisible(fieldNameTitle) && 
                        <SummaryField
                            fieldName={fieldNameTitle}
                            fieldValue={card.title?.value}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        />
                    }
                    {isColumnVisible(fieldNameCurrentTask) &&  
                        <SummaryField
                            fieldName="Current Task"
                            fieldValue={card.tasks?.value}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        />
                    }
                    {isColumnVisible(fieldNameStage) &&  
                        <SummaryField
                            fieldName={fieldNameStage}
                            fieldValue={card.stage?.value}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        />
                    }
                    {isColumnVisible(fieldNameDueToDecisionMaker) &&  
                        <SummaryField
                            fieldName="Due to Decision Maker"
                            fieldValue={(() => {
                                const dateFieldValue: string | undefined =
                                    card.dueDateDecisionMaker?.value ||
                                    card.dueDateCurrentTask?.value;
                                const dateString: string | undefined = dateFieldValue
                                    ? DateHelper.ToDateOnlyString(new Date(dateFieldValue), true)
                                    : undefined;
                                return dateString;
                            })()}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        />
                    }
                    {isColumnVisible(fieldNameTimeframe) &&  
                        <SummaryField
                            fieldName={fieldNameTimeframe}
                            fieldValue={card.timeframe?.value}
                            fieldCss={getTimeframeColor(card.timeframe?.value)}
                            useLessPadding={useLessPadding}
                            missingValueText={missingValueText}
                        />
                    }
                    {optionalColumns.map(column => {
                        // Record Id is showing under "Record" (first field) in mobile view.                       
                        if (column.key != fieldNameRecordId) {
                            return (
                                <SummaryField
                                    key={column.name+card.recordId?.value}
                                    fieldName={column.name}
                                    fieldValue={getFieldValue(column, card)}
                                    useLessPadding={useLessPadding}
                                    missingValueText={missingValueText}
                                />);
                        }
                    })}
                    {showDownloadPack && 
                        <PDFPackButton
                            text={downloadPDFPackMobile}
                            loadingText={t("generatingPDFPackText")}
                            buttonClass={styles.pdfButton}
                            spinnerClass={styles.spinnerStyling}
                            recordId={card.recordId?.value}
                            title={card.title?.value}
                            openText={t("openPDFPackText")}
                            onError={(msg: string | undefined) => { return msg; }}
                        />
                    }
                    {showSelectionMode && (isSelected(idx)
                        ? <DefaultButton 
                            text={"Unselect"}
                            onClick={() => {onToggleSelection(idx, false);}}
                            ariaLabel={"Unselect Button"}
                        />
                        : <PrimaryButton 
                            text={"Select"}
                            onClick={() => {onToggleSelection(idx, true);}}
                            ariaLabel={"Select Button"}
                        />)
                    }
                </div>
            ))}
        </div>
    );
}
