import {
    Link
} from "react-router-dom";
import React from "react";
import {
    Icon
} from "@fluentui/react";
import styles from "./RecordsTableCommon.module.scss";
import {
    IRecordColumn,
    IRecordResult
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    IRefiner
} from "../../model/Refiner";
import {
    ManagedPropertyViewType
} from "../../model/ManagedPropertyViewType";
import DateHelper from "../../helpers/DateHelper";
import {
    ITableColumn
} from "../../components/FormControls/Table/ITableColumn";
import {
    AppRoutes
} from "../../app/Constants";
import { getUserName } from "../../components/FormControls/Refiner/UserRefiner";

export const getRecordColor = (recordTypeAbbrev: string, predefinedColor: string | undefined): React.CSSProperties => {
    if (predefinedColor) {
        return { color: predefinedColor };
    }

    // Different colours in goes here
    let color: string = "black";

    switch (recordTypeAbbrev) {
        case "BMIN":
            color = "#2B61EB";
            break;
        case "BSEC":
            color = "#CC0000";
            break;
        case "BORG":
            color = "#767676";
            break;
        case "EMIN":
        case "ESEC":
        case "EORG":
            color = "#CD0AD1";
            break;
        case "AD":
            color = "black";
            break;
        case "CQ":
            color = "black";
            break;
        case "CMIN":
            color = "#00871E";
            break;
        case "CORG":
            color = "black";
            break;
        case "PAEC":
            color = "#6F01B2";
            break;
        case "PPQ":
            color = "#956E09";
            break;
        case "QN":
        case "QON":
        case "PQON":
            color = "#006B8D";
            break;
        case "QWN":
            color = "black";
            break;
        case "CSEC":
            color = "black";
            break;
        default:
            break;
    }
    return { color: color };
};

export const getTimeframeColor = (timeframe: string | undefined): React.CSSProperties => {
    // Different colours in goes here
    let color: string = "#333333";
    let isBold: boolean = false;
    if (timeframe) {
        const reducedTimeframe: string = timeframe.substr(0, timeframe.indexOf(" ")).toLocaleLowerCase();
        switch (reducedTimeframe) {
            case "urgent":
                color = "#D83B01";
                isBold = true;
                break;
            case "priority":
                color = "#0052C2";
                isBold = true;
                break;
            case "standard":
                color = "#605E5C";
                break;
            default:
                break;
        }
    }
    return { color: color, fontWeight: isBold ? 600 : 400 };
};

export const buildRecordTableColumn = (column: IRecordColumn, openInNewTab?: boolean): ITableColumn => {
    let result: ITableColumn | undefined = undefined;
    const noValueString: string = "";
    switch (column.type) {
        case ManagedPropertyViewType.NumberAsTime: {
            result = {
                ...column,
                minWidth: 90,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: IRecordResult) => {
                    if(column.isCustom) {
                        const customColumnValue: string | undefined = (item as IRecordResult)?.values?.value?.find(v => v.fieldName?.toLowerCase() === column.key.toLowerCase())?.value;
                        return <span className={styles.recordFieldColumn}>{customColumnValue}</span>;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const numberFieldValue: number | undefined = (item as any)[column.key]?.value;
                    return <span className={styles.recordFieldColumn}>{numberFieldValue ? DateHelper.ToTimeOnlyFromNumber(numberFieldValue) : noValueString}</span>;                     
                }
            };
            break;
        }
        case ManagedPropertyViewType.Date: {
            result = {
                ...column,
                minWidth: 90,
                maxWidth: 120,
                isResizable: true,
                onRender: (item: IRecordResult) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const dateFieldValue: string | undefined = (item as any)[column.key]?.value;

                    if(column.isCustom) {
                        const customColumnValue: string | undefined = (item as IRecordResult)?.values?.value?.find(v => v.fieldName?.toLowerCase() === column.key.toLowerCase())?.value;
                        return <span className={styles.recordFieldColumn}>{customColumnValue}</span>;
                    }

                    if(dateFieldValue){
                        if(DateHelper.GetDateISOWithoutMilliseconds(new Date(dateFieldValue))===dateFieldValue){
                            const dateString: string = dateFieldValue
                                ? DateHelper.ToDateOnlyString(new Date(dateFieldValue), true)
                                : noValueString;
                            return <span className={styles.recordFieldColumn}>{dateString}</span>;
                        }
                    } 
                    
                    return noValueString;
                }
            };
            break;
        }
        case ManagedPropertyViewType.Text: {
            switch (column.key) {
                case "recordType": {
                    result = {
                        ...column,
                        iconName: "Page",
                        iconClassName: styles.recordTypeColumnIcon,
                        isIconOnly: true,
                        minWidth: 25,
                        maxWidth: 25,
                        isResizable: false,
                        onRender: (item: IRecordResult) => {
                            const recordColor: React.CSSProperties = getRecordColor(item.recordType?.value?.prefix || "", item.recordType?.value?.color);
                            const link: string = AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                recordId: item.recordId?.value || ""
                            });
                            return (
                                <Link
                                    className={styles.recordLink}
                                    to={link}
                                    target={openInNewTab ? "_blank" : "_self"}
                                >
                                    <div style={recordColor} className={styles.iconColumnContainer}>
                                        <Icon className={styles.iconColumnIcon} iconName={"TextDocument"} ariaLabel={"Document icon"} />
                                        <div className={styles.iconColumnText}>{item.recordType?.value?.prefix}</div>
                                    </div>
                                </Link>
                            );
                        }
                    };
                    break;
                }
                case "recordId": {
                    result = {
                        ...column,
                        minWidth: 120,
                        maxWidth: 180,
                        isResizable: true,
                        onRender: (item: IRecordResult) => {
                            const recordColor: React.CSSProperties = getRecordColor(item.recordType?.value?.prefix || "", item.recordType?.value?.color);
                            const link: string = AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                recordId: item.recordId?.value || ""
                            });
                            return (
                                <Link
                                    className={[styles.recordFieldColumn, styles.recordLink].join(" ")}
                                    to={link}
                                    target={openInNewTab ? "_blank" : "_self"}
                                >
                                    <div className={styles.recordIdColumn} style={recordColor}>
                                        {item.recordId?.value || noValueString}
                                    </div>
                                </Link>
                            );
                        }
                    };
                    break;
                }
                case "timeframe": {
                    result = {
                        ...column,
                        minWidth: 100,
                        maxWidth: 150,
                        isResizable: true,
                        onRender: (item: IRecordResult) => {
                            const timeframeVal: string | undefined = item.timeframe?.value;
                            const timeframeColor: React.CSSProperties = getTimeframeColor(timeframeVal);
                            return (
                                <span style={timeframeColor} className={styles.recordFieldColumn}>
                                    {timeframeVal || noValueString}
                                </span>
                            );
                        }
                    };
                    break;
                }
                case "tasks": {
                    result = {
                        ...column,
                        minWidth: 150,
                        maxWidth: 400,
                        isResizable: true,
                        onRender: (item: IRecordResult) => {
                            const link: string = AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                recordId: item.recordId?.value || ""
                            });
                            return (
                                <Link
                                    className={[styles.recordFieldColumn, styles.recordLink].join(" ")}
                                    to={link}
                                    target={openInNewTab ? "_blank" : "_self"}
                                >
                                    <span className={styles.recordFieldValue}>
                                        {item.tasks?.value || noValueString}
                                    </span>
                                </Link>
                            );
                        }
                    };
                    break;
                }
                case "title": {
                    result = {
                        ...column,
                        minWidth: 200,
                        maxWidth: 400,
                        isResizable: true,
                        onRender: (item: IRecordResult) => {
                            const link: string = AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                recordId: item.recordId?.value || ""
                            });
                            return (
                                <Link
                                    className={[styles.recordFieldColumn, styles.recordLink].join(" ")}
                                    to={link}
                                    target={openInNewTab ? "_blank" : "_self"}
                                >
                                    <span className={styles.recordFieldValue}>
                                        {item.title?.value || noValueString}
                                    </span>
                                </Link>
                            );
                        }
                    };
                    break;
                }
                default: {
                    result = {
                        ...column,
                        minWidth: 90,
                        maxWidth: 150,
                        isResizable: true,
                        onRender: (item: IRecordResult) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const defaultTextFieldValue: string | undefined = (item as any)[column.key]?.value;
                            const customFieldValue: string | undefined = (item as IRecordResult)?.values?.value?.find(v => v.fieldName?.toLowerCase() === column.key.toLowerCase())?.value;
                            return (
                                <span className={styles.recordFieldColumn}>
                                    {defaultTextFieldValue || customFieldValue || noValueString}
                                </span>
                            );
                        }
                    };
                    break;
                }
            }
            break;
        }
        case ManagedPropertyViewType.User: {
            result = {
                ...column,
                minWidth: 90,
                maxWidth: 150,
                isResizable: true,
                onRender: (item: IRecordResult) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const userTextFieldValue: string | undefined = (item as any)[column.key]?.value;

                    if(column.isCustom) {
                        const customColumnValue: string | undefined = (item as IRecordResult)?.values?.value?.find(v => v.fieldName?.toLowerCase() === column.key.toLowerCase())?.value;
                        return <span className={styles.recordFieldColumn}>{customColumnValue || noValueString}</span>;
                    }

                    return (
                        <span className={styles.recordFieldColumn}>
                            {userTextFieldValue ? getUserName(userTextFieldValue) : noValueString}
                        </span>
                    );
                }
            };
            break;
        }
        default: {
            result = {
                ...column,
                minWidth: 90,
                maxWidth: 150,
                isResizable: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onRender: (item: any) => {
                    const columnValue: string | undefined = item[column.key]?.value;
                    const customColumnValue: string | undefined = (item as IRecordResult)?.values?.value?.find(v => v.fieldName?.toLowerCase() === column.key.toLowerCase())?.value;
                    return (
                        <span className={styles.recordFieldColumn}>
                            {columnValue || customColumnValue || noValueString}
                        </span>
                    );
                }
            };
            break;
        }
    }
    return result;
};

export const buildRefinableFilterString = (
    refiners: IRefiner[] | undefined
): string[] | undefined => {
    let result: string[] | undefined = undefined;

    if (refiners && refiners.length) {
        const filters: { or: boolean; filter: string }[] = refiners.map(r => {
            //select Token and ChildTokens for active entries.
            let selectedTokens: string[] = [];

            if (r.type === ManagedPropertyViewType.Date || r.type === ManagedPropertyViewType.NumberAsTime) {
                if (r.from && r.to) {
                    selectedTokens = [`range(${r.from}, ${r.to})`];
                }
            } else {
                selectedTokens = r.entries
                    .map(entry => {
                        const tokenParts: string[] = [];
                        if (entry.token || entry.value) {
                            tokenParts.push(entry.token || entry.value);
                        }
                        // TODO: check
                        if (entry.childTokens && entry.childTokens.length) {
                            tokenParts.push(...entry.childTokens);
                        }
                        return tokenParts;
                    })
                    .reduce((a, b) => a.concat(b));
            }

            const resultFilters: { or: boolean; filter: string } = {
                or: !!r.useOrOperator,
                filter: ""
            };
            if (selectedTokens.length !== 1) {
                resultFilters.filter = `${r.name}:or(${selectedTokens.join(",")})`;
            } else {
                resultFilters.filter = `${r.name}:${selectedTokens[0]}`;
            }
            return resultFilters;
        });
        const filtersStr: string[] = filters.filter(x => !x.or).map(x => x.filter);
        const orFilters: string[] = filters.filter(x => x.or).map(x => x.filter);
        if (orFilters.length > 1) {
            filtersStr.push(`or(${orFilters.join(",")})`);
        } else {
            filtersStr.push(...orFilters);
        }
        result = filtersStr.length === 1 ? filtersStr : [`and(${filtersStr.join(",")})`];
    }

    return result;
};
