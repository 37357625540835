// 1. IMPORTS
import {
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IContentType,
    ICreateRecordRequest,
    IRecord,
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    AppThunk,
    RootState
} from "../../app/store";

import {currentStepHashKey} from "../../providers/Constants/RecordWizardConstants";
import { AppRoutes } from "../../app/Constants";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here
export enum WizardStates {
    CreateRecord = 1,
    CorrespondenceItems,
    CorrespondenceDetails,
    RecordInformation,
    ResponseInformation,
    RecordTemplate,
    KeyRoles,
    Summary,
    RecordConfirmation
}

export enum WizardType {
    Correspondence = 1,
    Event = 2,
    Other = 4
}

// 3. FEATURE STATE
export interface RecordWizardWrapperState {
    wizardState: WizardStates;
    wizardType: WizardType;
    errorMessages?: string[];
    createdRecords: IRecord[];
    failedToCreateRecords: ICreateRecordRequest[];
    isMultipleRecordsCreation: boolean;
    preventNext: boolean;
    isFirstStep: boolean;
    isLastStep: boolean;
    stepNumber: number;
    totalSteps: number;
}

// 4. FEATURE INITIAL STATE
export const initialState: RecordWizardWrapperState = {
    wizardState: WizardStates.CreateRecord,
    wizardType: WizardType.Other,
    errorMessages: undefined,
    createdRecords: [],
    failedToCreateRecords: [],
    isMultipleRecordsCreation: false,
    preventNext: true,
    isFirstStep: true,
    isLastStep: false,
    stepNumber: 0,
    totalSteps: 6
};

const wizardSteps: Record<WizardType,WizardStates[]> = {
    [WizardType.Correspondence]:[
        WizardStates.CreateRecord,
        WizardStates.CorrespondenceItems,
        WizardStates.CorrespondenceDetails,
        WizardStates.RecordInformation,
        WizardStates.ResponseInformation,
        WizardStates.RecordTemplate,
        WizardStates.KeyRoles,
        WizardStates.Summary,
        WizardStates.RecordConfirmation
    ],
    [WizardType.Event]:[
        WizardStates.CreateRecord,
        WizardStates.RecordInformation,
        WizardStates.ResponseInformation,
        WizardStates.RecordTemplate,
        WizardStates.KeyRoles,
        WizardStates.Summary,
        WizardStates.RecordConfirmation
    ],
    [WizardType.Other]:[
        WizardStates.CreateRecord,
        WizardStates.RecordInformation,
        WizardStates.ResponseInformation,
        WizardStates.RecordTemplate,
        WizardStates.KeyRoles,
        WizardStates.Summary,
        WizardStates.RecordConfirmation
    ]
};

// 4. FEATURE SLICE

// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const recordWizardWrapperSlice = createSlice({
    name: "recordWizardWrapper",
    initialState,
    reducers: {
        resetWizardWrapper: (state: RecordWizardWrapperState) => {
            state.wizardState = initialState.wizardState;
            state.wizardType = initialState.wizardType;
            state.errorMessages = initialState.errorMessages;
            state.createdRecords = initialState.createdRecords;
            state.failedToCreateRecords = initialState.failedToCreateRecords;
            state.preventNext = initialState.preventNext;
            state.isFirstStep = initialState.isFirstStep;
            state.isLastStep = initialState.isLastStep;
            state.stepNumber = initialState.stepNumber;
            state.totalSteps = initialState.totalSteps;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        nextPage: (state: RecordWizardWrapperState, action: PayloadAction<any>) => {
            // eslint-disable-next-line @typescript-eslint/typedef
            const history = action.payload;
            const steps: WizardStates[] = wizardSteps[state.wizardType];
            const currentStepIndex: number = steps.indexOf(state.wizardState);
            const nextStepIndex: number = (steps.length - 1) === currentStepIndex ? currentStepIndex : currentStepIndex + 1;
            state.isFirstStep = nextStepIndex === 0;
            state.isLastStep = nextStepIndex === (steps.length - 1);
            state.wizardState = steps[nextStepIndex];
            state.stepNumber = nextStepIndex;
            state.totalSteps = steps.length - 1;
            state.errorMessages = undefined;
            if (currentStepIndex === 0) {
                history.replace({ pathname: AppRoutes.createRecord, hash: `${currentStepHashKey}${currentStepIndex}`, state: { skipLocationHandler: true }});
            }
            history.push({ pathname: AppRoutes.createRecord, hash: `${currentStepHashKey}${currentStepIndex + 1}`, state: { skipLocationHandler: true }});
        },
        previousPage: (state: RecordWizardWrapperState) => {
            const steps: WizardStates[] = wizardSteps[state.wizardType];
            const currentStepIndex: number = steps.indexOf(state.wizardState);
            const previousStepIndex: number = currentStepIndex === 0 ? currentStepIndex : currentStepIndex - 1;
            state.isFirstStep = previousStepIndex === 0;
            state.isLastStep = previousStepIndex === (steps.length - 1);
            state.wizardState = steps[previousStepIndex];
            state.stepNumber = previousStepIndex;
            state.totalSteps = steps.length - 1;
            state.errorMessages = undefined;
        },
        navigateToPage: (
            state: RecordWizardWrapperState,
            action: PayloadAction<number | undefined>
        ) => {
            if (action && action.payload) {
                state.wizardState = action.payload;
                const steps: WizardStates[] = wizardSteps[state.wizardType];
                const currentStepIndex: number = steps.indexOf(state.wizardState);
                state.stepNumber = (steps.length - 1) === currentStepIndex ? currentStepIndex : currentStepIndex;
                state.errorMessages = undefined;
            }
        },
        setErrorMessages: (
            state: RecordWizardWrapperState,
            action: PayloadAction<string[] | undefined>
        ) => {
            state.errorMessages = action.payload;
        },
        setCreatedRecords: (state: RecordWizardWrapperState, action: PayloadAction<IRecord[]>) => {
            state.createdRecords = action.payload;
        },
        setFailedToCreateRecords: (state: RecordWizardWrapperState, action: PayloadAction<ICreateRecordRequest[]>) => {
            state.failedToCreateRecords = action.payload;
        },
        setIsMultipleRecordsCreation: (state: RecordWizardWrapperState, action: PayloadAction<boolean>) => {
            state.isMultipleRecordsCreation = action.payload;
        },
        setPreventNext: (state: RecordWizardWrapperState, action: PayloadAction<boolean>) => {
            state.preventNext = action.payload;
        },
        setWizardType: (state: RecordWizardWrapperState, action: PayloadAction<IContentType|undefined>) =>{
            if (action.payload?.isCorrespondenceType){
                state.wizardType = WizardType.Correspondence;
            } else  {
                state.wizardType = WizardType.Other;
            }
        }   
    }
});

// 4. SYNCRONOUS ACTIONS

const { setIsMultipleRecordsCreation, setCreatedRecords, setFailedToCreateRecords } = recordWizardWrapperSlice.actions;

export const {
    resetWizardWrapper,
    nextPage,
    previousPage,
    navigateToPage,
    setErrorMessages,
    setPreventNext,
    setWizardType
} = recordWizardWrapperSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const createRecordsAsync = (
    records: ICreateRecordRequest[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    history: any,
    recordsReady?: IRecord[],
): AppThunk => async dispatch => {
    dispatch(nextPage(history));
    setIsMultipleRecordsCreation(records.length > 0);

    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    // Create the record
    const createdRecords:(IRecord | null)[] = recordsReady || [];
    const failedToCreateRecords: ICreateRecordRequest[] = [];
    let errorMesages: string[] | undefined = undefined;

    await Promise.all(records.map(async (record) => {
        try{
            const result: IRecord | null = await apiService.CreateRecord(record);
            if(result){
                createdRecords.push(result);
            } else {
                throw null;
            }
        } catch (e) {
            failedToCreateRecords.push(record);
            if(e){
                errorMesages = errorMesages || [];
                errorMesages.push(e.toString());
            }
        }
    }));

    dispatch(setCreatedRecords(createdRecords.filter(x=>!!x) as IRecord[]));
    dispatch(setFailedToCreateRecords(failedToCreateRecords));
    dispatch(setErrorMessages(errorMesages));
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectWizardStage = (state: RootState): number =>
    state.recordWizardWrapper.wizardState;
export const selectErrorMessages = (state: RootState): string[] | undefined =>
    state.recordWizardWrapper.errorMessages;
export const selectCreatedRecords = (state: RootState): IRecord[] =>
    state.recordWizardWrapper.createdRecords;
export const selectFailedToCreateRecords = (state: RootState): ICreateRecordRequest[] =>
    state.recordWizardWrapper.failedToCreateRecords;
export const selectIsMultipleRecordsCreation = (state: RootState): boolean =>
    state.recordWizardWrapper.isMultipleRecordsCreation;
export const selectPreventNext = (state: RootState): boolean =>
    state.recordWizardWrapper.preventNext;
export const selectIsFirstStep = (state: RootState): boolean =>
    state.recordWizardWrapper.isFirstStep;
export const selectIsLastStep = (state: RootState): boolean =>
    state.recordWizardWrapper.isLastStep;
export const selectStepNumber = (state: RootState): number =>
    state.recordWizardWrapper.stepNumber;
export const selectTotalSteps = (state: RootState): number =>
    state.recordWizardWrapper.totalSteps;
export const selectWizardType = (state: RootState): WizardType =>
    state.recordWizardWrapper.wizardType;

// 6. EXPORT REDUCER
export default recordWizardWrapperSlice.reducer;
