/* eslint-disable @typescript-eslint/typedef */
export const AppRoutes = {
    root: "/",
    home: "/",
    teamsAuth: "/teamsAuth",
    createRecord: "/create",
    manageRecord: "/record/:recordId",
    manageRelatedRecords: "/record/:recordId/relatedRecords",
    recordRecommendations: "/record/:recordId/overviewRecommendations",
    settings: "/settings",
    appSettings: "/appSettings",
    bulkUpdate: "/bulkUpdate",
    configWF: "/configWF",
    schemaExport: "/recordschema/export",
    watermarkPdf: "/watermarkPdf/:fileRef",
    search: "/search",
    documentGeneration: "/docgen/:recordId",
    buildUrl: (url: string, opt: { [key: string]: string }): string => {
        let result = url;
        for (const key of Object.keys(opt)) {
            result = result.replace(`:${key}`, opt[key]);
        }
        return result;
    }
};
