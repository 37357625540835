import { MessageBar, MessageBarType, Separator } from "@fluentui/react";
import React from "react";
import styles from "./SearchPage.module.scss";
import { IRecordResult } from "../../services/Api/executor/IApiServiceExecutor";
import { ControlShimmer } from "../../components/FormControls/ControlShimmer/ControlShimmer";
import PdfPackButton from "../../components/FormControls/PdfPackButton/PdfPackButton";
import { RecordItem } from "./recordItem/RecordItem";
import { useTranslation } from "react-i18next";

interface ISearchResult{
    items: IRecordResult[] | undefined,
    isLoading: boolean,
    isMobile: boolean 
}

export function SearchResult({ items, isLoading, isMobile }: ISearchResult): JSX.Element {
    const isReady: boolean = !!(items && !isLoading);
    const isEmptyResults: boolean = !!items && !items.length;
    const [errorRecordId, setErrorRecordId] = React.useState<string | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const {t} = useTranslation();

    return(<>
        {isReady && !isEmptyResults && items?.map((r, idx) => {
            return (
                <div key={idx}>
                    {!isMobile && <Separator className={styles.separator} />}
                    <div className={`${styles.searchResultItem} ${isMobile ? styles.mobileSearchResultItem : ""}`}>
                        <RecordItem 
                            record={r}
                            isMobile={isMobile}
                        />
                        <div className={styles.briefingButton}>
                            <PdfPackButton 
                                text={t("textBriefingButton")}
                                loadingText={t("loadingBriefingButton")}
                                recordId={r.recordId?.value}
                                title={r.title?.value}
                                openText={t("openBriefingButton")}
                                onError={(msg: string | undefined) => { 
                                    setErrorMessage(msg);
                                    setErrorRecordId(r.recordId?.value);
                                }}
                            />
                            {errorMessage && (r.recordId?.value == errorRecordId)
                                && <MessageBar 
                                    className={styles.errorMessage} 
                                    messageBarType={MessageBarType.blocked} 
                                    isMultiline={true} 
                                >
                                    {errorMessage}
                                </MessageBar>
                            }
                        </div>
                    </div>
                </div>
            );
        })
        }
        {!isReady && !isMobile && <ControlShimmer rows={6} cols={3} />}
    </>);
}