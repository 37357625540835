// 1. IMPORTS
import {
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import {
    IFieldValue,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    AppThunk,
    RootState
} from "../../app/store";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export enum FormMode {
    New,
    Edit
}
export interface IWizardKeyRolesFormState {
    mode: FormMode | null;
}
export interface IWizardKeyRolesModelState {
    userInitiator: IUser | null;
    changeReason: string | null;
    currentFieldValues: IFieldValue[] | null;
}
export interface IWizardKeyRolesState {
    model: IWizardKeyRolesModelState;
    form: IWizardKeyRolesFormState;
}
export const initialFormState: IWizardKeyRolesFormState = {
    mode: null
};

export const initialKeyRolesState: IWizardKeyRolesModelState = {
    userInitiator: null,
    changeReason: null,
    currentFieldValues: null,
};

export const initialState: IWizardKeyRolesState = {
    form: initialFormState,
    model: initialKeyRolesState
};

// 3. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const wizardKeyRolesSlice = createSlice({
    name: "wizardKeyRoles",
    initialState,
    reducers: {
        resetWizardKeyRoles: (state: IWizardKeyRolesState) => {
            for (const key in initialKeyRolesState) {
                const keyAs: keyof IWizardKeyRolesModelState = key as keyof IWizardKeyRolesModelState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state.model[keyAs] = initialState.model[keyAs] as any;
            }
            state.form.mode = initialState.form.mode;
        },
        setWizardKeyRolesModel: (
            state: IWizardKeyRolesState,
            action: PayloadAction<Partial<IWizardKeyRolesModelState>>
        ) => {
            for (const key in action.payload) {
                const keyAs: keyof IWizardKeyRolesModelState = key as keyof IWizardKeyRolesModelState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state.model[keyAs] = action.payload[keyAs] as any;
            }
        },
        setWizardDefaultKeyRoles: (
            state: IWizardKeyRolesState,
            action: PayloadAction<{
                initiator: IUser | null;                
            }>
        ) => {
            state.model.userInitiator = state.model.userInitiator || action.payload.initiator;
        },
        setWizardKeyRolesFormMode: (
            state: IWizardKeyRolesState,
            action: PayloadAction<FormMode>
        ) => {
            state.form.mode = action.payload;
        },
        setInitiator: (state: IWizardKeyRolesState, action: PayloadAction<IUser>) => {
            state.model.userInitiator = action.payload;
        },
        setChangeReason: (state: IWizardKeyRolesState, action: PayloadAction<string | null>) => {
            state.model.changeReason = action.payload;
        },
        setFieldValues: (
            state: IWizardKeyRolesState,
            action: PayloadAction<IFieldValue[] | null>
        ) => {
            state.model.currentFieldValues = action.payload;
        },
    }
});

// 4. SYNCHRONOUS ACTIONS
export const {
    setWizardKeyRolesFormMode,
    resetWizardKeyRoles,
    setWizardKeyRolesModel,
    setWizardDefaultKeyRoles,
    // setUsersAvailable,
    setInitiator,
    setFieldValues,
    setChangeReason
} = wizardKeyRolesSlice.actions;

// 5. ASYNCHRONOUS ACTIONS
export const fillDefaultKeyRoles = (currentUser: IUser | null | undefined): AppThunk => async dispatch => {
    dispatch(
        setWizardDefaultKeyRoles({
            initiator: currentUser || null,          
        })
    );
};

// 6. SELECTORS
export const selectSelectedInitiator = (state: RootState): IUser | null =>
    state.wizardKeyRoles.model.userInitiator;
export const selectChangeReason = (state: RootState): string | null =>
    state.wizardKeyRoles.model.changeReason;
export const selectCustomUserValues = (state: RootState): IFieldValue[] | null =>
    state.wizardKeyRoles.model.currentFieldValues;
export const selectFormMode = (state: RootState): FormMode | null => state.wizardKeyRoles.form.mode;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// 6. EXPORT REDUCER
export default wizardKeyRolesSlice.reducer;
