// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import { ICurrentClientContext, IUser } from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
export const isMobileInnerWidth = (): boolean => window.innerWidth <= 640;

// 2. TYPES AND INTERFACES

// 3. FEATURE STATE
export interface ContextState {
    currentUser: IUser | null | undefined;
    headerBannerText: string | null | undefined;
    proxy: string | undefined;
    isMobile: boolean;
    favicon?: string;
    primaryIcon?: string;
    navbarIcon?: string;
    errorText?: string;
    applicationName?: string;
    appTitle?: string;
    showValidationErrors?: boolean;
    reloadInterval?: number;
    maxReloadAttemptTime?: number;
}

// 4. FEATURE INITIAL STATE
export const initialState: ContextState = {
    currentUser: undefined,
    headerBannerText: undefined,
    proxy: undefined,
    isMobile: isMobileInnerWidth(),
    favicon: undefined,
    primaryIcon: undefined,
    errorText: undefined
};

// 4. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const contextSlice = createSlice({
    name: "context",
    initialState,
    reducers: {
        setCurrentContext: (
            state: ContextState,
            action: PayloadAction<ICurrentClientContext | null>
        ) => {
            state.currentUser = action.payload?.currentUser;
            state.headerBannerText = action.payload?.headerBannerText;
            state.favicon = action.payload?.favicon;
            state.primaryIcon = action.payload?.primaryIcon;
            state.navbarIcon = action.payload?.navbarIcon;
            state.applicationName = action.payload?.applicationName;
            state.showValidationErrors = action.payload?.showValidationErrors || false;
            state.reloadInterval = action.payload?.reloadInterval;
            state.maxReloadAttemptTime = action.payload?.maxReloadAttemptTime;
        },
        setAppTitle: (state: ContextState, action: PayloadAction<string | undefined>) => {
            state.appTitle = action.payload;
        },
        setHeaderBannerText: (state: ContextState, action: PayloadAction<IUser | null>) => {
            state.currentUser = action.payload;
        },
        setProxy: (state: ContextState, action: PayloadAction<string | undefined>) => {
            state.proxy = action.payload;
        },
        setIsMobile: (state: ContextState, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setFavicon: (state: ContextState, action: PayloadAction<string | undefined>) => {
            state.favicon = action.payload;
        },
        setPrimaryIcon: (state: ContextState, action: PayloadAction<string | undefined>) => {
            state.primaryIcon = action.payload;
        },
        setNavbarIcon: (state: ContextState, action: PayloadAction<string | undefined>) => {
            state.navbarIcon = action.payload;
        },
        setErrorText: (state: ContextState, action: PayloadAction<string | undefined>) => {
            if(action.payload){
                try{
                    const error: {failureMessages: string[]} = JSON.parse(action.payload);
                    if(error?.failureMessages){
                        action.payload = error?.failureMessages.join("\n");
                    }
                }
                catch(e){
                    // do nothing
                }
            }

            state.errorText = action.payload;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
export const { 
    setCurrentContext, 
    setProxy, 
    setIsMobile, 
    setFavicon, 
    setPrimaryIcon, 
    setNavbarIcon, 
    setErrorText,
    setAppTitle } =
    contextSlice.actions;

// 5. ASYNCRONOUS ACTIONS
export const getCurrentContextAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const currentContext: ICurrentClientContext | null = await apiService.GetCurrentContext();
    dispatch(setCurrentContext(currentContext));
};

// 6. SELECTORS
export const selectCurrentUser = (state: RootState): IUser | null | undefined =>
    state.context.currentUser;
export const selectHeaderBannerText = (state: RootState): string | undefined =>
    state.context.headerBannerText || undefined;
export const selectProxy = (state: RootState): string | undefined => state.context.proxy;
export const selectIsMobile = (state: RootState): boolean => state.context.isMobile;
export const selectFavicon = (state: RootState): string | undefined => state.context.favicon;
export const selectNavbarIcon = (state: RootState): string | undefined =>
    state.context.navbarIcon;
export const selectAppName = (state: RootState): string | undefined => state.context.applicationName;
export const selectShowValidationErrors = (state: RootState): boolean | undefined =>
    state.context.showValidationErrors;
export const selectReloadInterval = (state: RootState): number | undefined =>
    state.context.reloadInterval;
export const selectMaxReloadAttemptTime = (state: RootState): number | undefined =>
    state.context.maxReloadAttemptTime;
export const selectAppTitle = (state: RootState): string | undefined => state.context.appTitle;
export const selectErrorText = (state: RootState): string | undefined => state.context.errorText;

// 6. EXPORT REDUCER
export default contextSlice.reducer;
