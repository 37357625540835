/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme } from "@fluentui/react";

export const customTheme: any = createTheme({
    fonts: {
        xSmall: { fontSize: "10px" },
        small: { fontSize: "12px" },
        medium: { fontSize: "14px" },
        mediumPlus: { fontSize: "16px" },
        large: { fontSize: "18px" },
        xLarge: { fontSize: "20px" },
        xLargePlus: { fontSize: "28px" },
        xxLarge: { fontSize: "32px" },
        xxLargePlus: { fontSize: "42px" },
        superLarge: { fontSize: "68px" }
    },
    palette: {
        themePrimary: "#0052c2",
        themeLighterAlt: "#eff6fc",
        themeLighter: "#deecf9",
        themeLight: "#c7e0f4",
        themeTertiary: "#2b88d8",
        themeSecondary: "#1862c9",
        themeDarkAlt: "#106ebe",
        themeDark: "#005a9e",
        themeDarker: "#004578",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#a19f9d",
        neutralSecondary: "#605e5c",
        neutralPrimaryAlt: "#3b3a39",
        neutralPrimary: "#323130",
        neutralDark: "#201f1e",
        black: "#000000",
        white: "#ffffff",
    }
});