import { useDispatch } from "react-redux";
import React from "react";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn
} from "@fluentui/react";
import { Dispatch } from "@reduxjs/toolkit";
import { ActionButton, SelectionMode } from "@fluentui/react";
import styles from "./UserList.module.scss";
import { IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import {
    DateAccessColumnFieldName,
    DateAccessColumnKey,
    DateAccessColumnName,
    JobTitleColumnFieldName,
    JobTitleColumnKey,
    JobTitleColumnName,
    NameColumnFieldName,
    NameColumnKey,
    NameColumnName,
    RemoveUserColumnKey,
    RemoveuserColumnName as RemoveUserColumnName,   
} from "../../../providers/Constants/FormControlConstants";
import { removeAccessRestrictedUsers } from "../../../features/wizardRecordInformation/wizardRecordInformationSlice";

interface IUserListProps {
    users: IUser[];
    readOnly?: boolean;
}

export default function UserList({ users, readOnly }: IUserListProps): JSX.Element {
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const columns: IColumn[] = [
        {
            key: NameColumnKey,
            name: NameColumnName,
            fieldName: NameColumnFieldName,
            isResizable: true,
            minWidth: 150,
            maxWidth: 250,
            onRender: function onCustomRender(user: IUser): JSX.Element {
                return (
                    <div className={[styles.userInfo, styles.userDisplayNameInfo].join(" ")}>
                        {user.displayName}
                    </div>
                );
            }
        },
        {
            key: JobTitleColumnKey,
            name: JobTitleColumnName,
            fieldName: JobTitleColumnFieldName,
            isResizable: true,
            minWidth: 150,
            maxWidth: 250,
            onRender: function onCustomRender(user: IUser): JSX.Element {
                return <div className={styles.userInfo}>{user.jobTitle}</div>;
            }
        },
        {
            key: DateAccessColumnKey,
            name: DateAccessColumnName,
            fieldName: DateAccessColumnFieldName,
            isResizable: true,
            minWidth: 150,
            maxWidth: 250,
            onRender: function onCustomRender(): JSX.Element {
                return (
                    <div className={styles.userInfo}>{new Date().toLocaleDateString("en-AU")}</div>
                );
            }
        }
    ];

    if (!readOnly) {
        columns.push({
            key: RemoveUserColumnKey,
            name: RemoveUserColumnName,
            fieldName: "",
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            onRender: function onRenderIcon(user: IUser): JSX.Element {
                return (
                    <ActionButton
                        className={styles.deleteBtn}
                        iconProps={{ iconName: "Delete" }}
                        allowDisabledFocus
                        onClick={() => dispatch(removeAccessRestrictedUsers(user))}
                    >
                        Delete
                    </ActionButton>
                );
            }
        });
    }

    return (
        <div className={styles.headerContainer}>
            <DetailsList
                items={users.map(u => {
                    return { ...u, dateAccess: new Date().toLocaleDateString("en-AU") };
                })}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                ariaLabel={"User List"}
            />
        </div>
    );
}
