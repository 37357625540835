import React from "react";
import { IRefiner, IRefinerEntry } from "../../../model/Refiner";
import styles from "./UserRefiner.module.scss";
import { Label, SearchBox, Checkbox, Link, Persona, PersonaSize, Separator, PrimaryButton, DefaultButton } from "@fluentui/react";
import { DialogHelper } from "../../../helpers/DialogHelper";
import { PersonaComponentAltText } from "../../../providers/Constants/FormControlConstants";

interface IUserRefinerProps {
    refiner: IRefiner;
    value?: IRefiner;
    showLimit?: number;
    expanded: boolean;
    onChange: (value: IRefiner) => void;
    onExpand: () => void;
}

export function getUserName(str: string): string {
    let result: string = str;
    const parts: string[] = str.split("|");
    if (parts.length > 1) {
        result = parts[1].trim();
    }
    return result;
}

export default function UserRefiner({ refiner, value, showLimit, expanded, onChange, onExpand }: IUserRefinerProps): JSX.Element {
    const [searchValue, setSearchValue] = React.useState<string | undefined>();
    const [checkedOpts, setCheckedOpts] = React.useState<IRefinerEntry[]>(value?.entries || []);
    const itemsToShow: number = showLimit === undefined ? 5 : showLimit;
    const showSeeAllBtn: boolean = (refiner.entries || []).length > itemsToShow;

    function onChangeSearchBox(e?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) {
        setSearchValue(newValue);
    }

    function onChangeCheckbox(val: IRefinerEntry, checked?: boolean | undefined) {
        const idx: number = checkedOpts.findIndex(o => o.value === val.value);
        const newCheckedOpts: IRefinerEntry[] = [...checkedOpts];
        if (checked) {
            if (idx === -1) {
                newCheckedOpts.push(val);
            }
        } else {
            if (idx > -1) {
                newCheckedOpts.splice(idx, 1);
            }
        }
        setCheckedOpts(newCheckedOpts);

        const newValue: IRefiner = { ...refiner };
        newValue.entries = newCheckedOpts;
        onChange(newValue);
    }

    function onClearClick() {
        setCheckedOpts([]);
    }

    function isChecked(e: IRefinerEntry): boolean {
        const result: boolean = checkedOpts.some(o => o.value === e.value);
        return result;
    }

    React.useEffect(() => {
        if (!value?.entries) {
            setCheckedOpts([]);
        }
    }, [value]);

    const options: IRefinerEntry[] = React.useMemo(() => {
        let result: IRefinerEntry[] = [...(refiner.entries || [])];
        if (searchValue) {
            const regexp: RegExp = new RegExp(searchValue, "i");
            result = result.filter(o => !!(o.name || "").match(regexp));
        }
        result = !expanded ? result.slice(0, itemsToShow) : result;
        return result;
    }, [searchValue, refiner.entries, itemsToShow, expanded]);

    return (
        <>
            {expanded ?
                <>
                    <div className={styles.body}>
                        <div>
                            <div className={styles.userRefiner}>
                                <div className={styles.searchBoxContainer}>
                                    <div>
                                        <Label>{`Search ${refiner.displayName}`}</Label>
                                    </div>
                                    <SearchBox
                                        className={styles.searchBox}
                                        onChange={onChangeSearchBox}
                                    />
                                </div>
                                <div className={styles.checkboxList}>
                                    {options.length ?
                                        options.map((o, i) =>
                                            <div className={styles.listItem} key={i}>
                                                <Checkbox
                                                    className={styles.checkbox}
                                                    onChange={(ev, checked) => { onChangeCheckbox(o, checked); }}
                                                    checked={isChecked(o)}
                                                />
                                                <Persona
                                                    className={styles.persona}
                                                    imageInitials={DialogHelper.getInitials(getUserName(o.value))}
                                                    text={getUserName(o.value)}
                                                    size={PersonaSize.size40}
                                                    onClick={() => { onChangeCheckbox(o, !isChecked(o)); }}
                                                    data-alt={`${PersonaComponentAltText} ${getUserName(o.value) || ""}`}
                                                />
                                            </div>
                                        )
                                        : "No matches ..."
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Separator className={styles.separator} />
                    <div className={styles.footer}>
                        <PrimaryButton text="Apply" onClick={onExpand} />
                        <DefaultButton text="Clear" onClick={onClearClick} />
                    </div>
                </>
                :

                <div className={styles.userRefiner}>
                    <div className={styles.searchBoxContainer}>
                        <div>
                            <Label>{`Search ${refiner.displayName}`}</Label>
                        </div>
                        <SearchBox
                            className={styles.searchBox}
                            onChange={onChangeSearchBox}
                        />
                    </div>
                    <div className={styles.checkboxList}>
                        {options.length ?
                            options.map((o, i) =>
                                <div className={styles.listItem} key={i}>
                                    <Checkbox
                                        className={styles.checkbox}
                                        onChange={(ev, checked) => { onChangeCheckbox(o, checked); }}
                                        checked={isChecked(o)}
                                    />
                                    <Persona
                                        className={styles.persona}
                                        imageInitials={DialogHelper.getInitials(getUserName(o.value))}
                                        text={getUserName(o.value)}
                                        size={PersonaSize.size40}
                                        onClick={() => { onChangeCheckbox(o, !isChecked(o)); }}
                                        data-alt={`${PersonaComponentAltText} ${getUserName(o.value) || ""}`}
                                    />
                                </div>
                            )
                            : "No matches ..."
                        }
                    </div>
                    {showSeeAllBtn &&
                        <div className={styles.seeAllBtn}>
                            <Link className={styles.btn} onClick={onExpand}>See all</Link>
                        </div>
                    }
                </div>
            }
        </>
    );
}