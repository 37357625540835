/* eslint-disable @typescript-eslint/no-explicit-any */
export default class ArrayHelper {
    public static isEqualAsJSON(arr1: any[] | null | undefined, arr2: any[] | null | undefined): boolean {
        const firstArr: any[] = (arr1 || []);
        const secondArr: any[] = (arr2 || []);
        let result: boolean = (!firstArr.length && !secondArr.length);
        if(!result) {
            result = JSON.stringify(firstArr) === JSON.stringify(secondArr);
        }

        return result;
    }
}