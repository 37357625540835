import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    stateFilesLoading,
    deleteFileAsync,
    uploadFilesAsync,
    renameSelectedFile,
    moveSelectedFile,
    stateSelectedFiles,
    ImsgParsedFile,
    stateErrorMessages,
    uploadSuccess,
    removeSelectedFile,
} from "./wizardCorrespondenceFilesSlice";
import styles from "./WizardCorrespondenceFiles.module.scss";
import {
    setPreventNext
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import {
    FormMode,
    IContentType,
    IFile,
} from "../../services/Api/executor/IApiServiceExecutor";
import {

    correspondenceFilesHeaderStrings,
} from "../../providers/Constants/RecordWizardConstants";
import {
    CorrespondenceDeletingText,
    CorrespondenceUploadingText
} from "../../providers/Constants/FormControlConstants";

import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import FileUpload from "../../components/FormControls/FileUpload/FileUpload";
import { selectSelectedRecordType } from "../wizardCreateRecord/wizardCreateRecordSlice";

export function WizardCorrespondenceFiles(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const isFileLoading: boolean = useSelector(stateFilesLoading);
    const selectedFiles: ImsgParsedFile[] = useSelector(stateSelectedFiles);
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);
    const errorMessages: string[] | undefined = useSelector(stateErrorMessages);
    const isUploadSuccess: boolean = useSelector(uploadSuccess);
    
    // 3. DEFINE COMPONENT STATE
    const [fileLoadingText, setFileLoadingText] = React.useState<string>("");

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    // NOTE: No component hooks

    React.useEffect(()=>{
        const isFilesRequired: boolean = selectedRecordType?.mandatoryCorrespondenceUpload !== false;
        dispatch(setPreventNext(!selectedFiles?.length && isFilesRequired));
    },[selectedFiles, dispatch, selectedRecordType]);

    React.useEffect(()=>{
        const notRealFiles: IFile[] = selectedFiles.filter(x=> !x.path);
        for(const file of notRealFiles){
            dispatch(removeSelectedFile(file));
        }
    },[selectedFiles, dispatch]);

    function uploadFile(files: IFile[]): void {
        dispatch(uploadFilesAsync(files));
    }

    function deleteFile(file: IFile): void {
        const fileToDelete: ImsgParsedFile|undefined = selectedFiles.find(x=>x.name === file.name);
        if(fileToDelete){
            dispatch(
                deleteFileAsync(fileToDelete)
            );
        }
    }


    // Returning the new form component.
    return (
        <div className={styles.wizardCorrespondenceFilesContainer}>
            <div className={styles.headerContainer}>
                <FormHeader strings={correspondenceFilesHeaderStrings} />
            </div>
            <div className={styles.supportingDocSection}>
                <FileUpload
                    selectedFilesList={selectedFiles}
                    onFileSelected={(selectedFile: IFile[]) => {
                        setFileLoadingText(CorrespondenceUploadingText);
                        uploadFile(selectedFile);
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        setFileLoadingText(CorrespondenceDeletingText);
                        deleteFile(selectedFile);
                    }}
                    onFileEdit={(filenames: string[]) => {
                        dispatch(renameSelectedFile(filenames));
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        dispatch(moveSelectedFile([file, movement]));
                    }}
                    onLoading={isFileLoading}
                    onLoadingText={fileLoadingText}
                    componentMode={FormMode.New}
                    errorMessages={errorMessages}
                    canEdit={true}
                    canAdd={true}
                    canRemove={true}
                    canUploadSigned={false}
                    isSuccess={isUploadSuccess}
                ></FileUpload>
            </div>
        </div>
    );
}
