import React from "react";
import { ActionButton } from "@fluentui/react";
import { IRefiner, IRefinerEntry } from "../../../model/Refiner";
import styles from "./RefinerActiveFilters.module.scss";
import { castStringToDate } from "./DateRefiner";
import { getUserName } from "./UserRefiner";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";
import {
    nextMonthPipelineTitle,
    nextWeekPipelineTitle,
    nowPipelineTitle,
    overduePipelineTitle,
    thisWeekPipelineTitle
} from "../../../features/recordsStatistics/tasksPipeline/tasksPipelineConstants";
import DateHelper from "../../../helpers/DateHelper";

export interface IRefinerActiveFiltersProps {
    selectedRefiners?: IRefiner[];
    onChange(r: IRefiner[] | undefined): void;
}

export default function RefinerActiveFilters({
    selectedRefiners,
    onChange
}: IRefinerActiveFiltersProps): JSX.Element {
    function getBgClassForDate(name: string): string {
        switch (name) {
            case overduePipelineTitle:
                return styles.overdueBgr;
            case nowPipelineTitle:
                return styles.nowBgr;
            case thisWeekPipelineTitle:
                return styles.thisWeekBgr;
            case nextWeekPipelineTitle:
                return styles.nextWeekBgr;
            case nextMonthPipelineTitle:
                return styles.nextMonthBgr;
            default:
                return "";
        }
    }

    function onClearAll(): void {
        onChange(undefined);
    }
    function onClearSingle(refiner: IRefiner, entry: IRefinerEntry): void {
        const newRefiners: IRefiner[] = [...(selectedRefiners || [])];
        const newEntries: IRefinerEntry[] = [...refiner.entries];
        const eIdx: number = newEntries.findIndex(e => e.token === entry.token);
        if (eIdx > -1) {
            newEntries.splice(eIdx, 1);
        }

        const rIdx: number = newRefiners.findIndex(r => r.name === refiner.name);
        if (rIdx > -1) {
            if (!newEntries.length) {
                newRefiners.splice(rIdx, 1);
            } else {
                const newRefiner: IRefiner = { ...newRefiners[rIdx] };
                newRefiner.entries = newEntries;
                newRefiners.splice(rIdx, 1, newRefiner);
            }
        }
        onChange(newRefiners.length ? newRefiners : undefined);
    }

    function onClearDateRange(refiner: IRefiner): void {
        const newRefiners: IRefiner[] = [...(selectedRefiners || [])];
        const rIdx: number = newRefiners.findIndex(r => r.name === refiner.name);
        if (rIdx > -1) {
            newRefiners.splice(rIdx, 1);
        }
        onChange(newRefiners.length ? newRefiners : undefined);
    }

    function renderRefinerEntries(r: IRefiner): JSX.Element[] | JSX.Element | undefined {
        let result: JSX.Element[] | JSX.Element | undefined;
        switch (r.type) {
            case ManagedPropertyViewType.Date: {
                const isTaskPiplinesRefiner: boolean =
                    r.displayName === overduePipelineTitle ||
                    r.displayName === nowPipelineTitle ||
                    r.displayName === nextWeekPipelineTitle ||
                    r.displayName === nextMonthPipelineTitle ||
                    r.displayName === thisWeekPipelineTitle;
                if (isTaskPiplinesRefiner) {
                    result = (
                        <ActionButton
                            key={r.name}
                            className={`${styles.removeRefinerBtn} ${getBgClassForDate(
                                r.displayName
                            )}`}
                            iconProps={{ iconName: "Clear" }}
                            onClick={() => {
                                onClearDateRange(r);
                            }}
                        >
                            {r.displayName}
                        </ActionButton>
                    );
                } else if (r.from && r.to) {
                    const fromDate: Date | undefined = castStringToDate(r.from);
                    const toDate: Date | undefined = castStringToDate(r.to);
                    const fromText: string = fromDate?.toLocaleDateString() || "...";
                    const toText: string = toDate?.toLocaleDateString() || "...";
                    result = (
                        <ActionButton
                            key={r.name}
                            className={`${styles.removeRefinerBtn}`}
                            iconProps={{ iconName: "Clear" }}
                            onClick={() => {
                                onClearDateRange(r);
                            }}
                        >
                            {r.displayName}: {fromText} - {toText}
                        </ActionButton>
                    );
                }
                break;
            }
            case ManagedPropertyViewType.NumberAsTime: {
                if (r.from && r.to) {
                    const fromDate: string | undefined = DateHelper.ToTimeOnlyFromNumber(r.from);
                    const toDate: string | undefined = DateHelper.ToTimeOnlyFromNumber(r.to);
                    const fromText: string = fromDate || "...";
                    const toText: string = toDate || "...";
                    result = (
                        <ActionButton
                            key={r.name}
                            className={`${styles.removeRefinerBtn}`}
                            iconProps={{ iconName: "Clear" }}
                            onClick={() => {
                                onClearDateRange(r);
                            }}
                        >
                            {r.displayName}: {fromText} - {toText}
                        </ActionButton>
                    );
                }
                break;
            }
            case ManagedPropertyViewType.User: {
                result = r.entries.map(e => (
                    <ActionButton
                        key={e.token}
                        className={styles.removeRefinerBtn}
                        iconProps={{ iconName: "Clear" }}
                        onClick={() => {
                            onClearSingle(r, e);
                        }}
                    >{`${r.displayName}: ${getUserName(e.name)}`}</ActionButton>
                ));
                break;
            }
            default: {
                result = r.entries.map(e => (
                    <ActionButton
                        key={e.token}
                        className={styles.removeRefinerBtn}
                        iconProps={{ iconName: "Clear" }}
                        onClick={() => {
                            onClearSingle(r, e);
                        }}
                    >
                        {r.displayName}: {e.name}
                    </ActionButton>
                ));
            }
        }

        return result;
    }

    return (
        <div className={styles.refinerActiveFilters}>
            {!!(selectedRefiners && selectedRefiners.length) && (
                <>
                    <div className={styles.top}>
                        <ActionButton
                            className={styles.clearAllBtn}
                            iconProps={{ iconName: "ClearFilter" }}
                            onClick={onClearAll}
                        >
                            Clear all filters
                        </ActionButton>
                    </div>
                    <div className={styles.bot}>
                        <div className={styles.refinersList}>
                            {selectedRefiners.map(renderRefinerEntries)}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
