// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import { IContentTypeField, IRecord, ITerm, ITermGroup, ITermSet } from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { IChoiceOptionProps } from "../../components/FormControls/Choice/Choice";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// 3. FEATURE STATE
export interface IWizardResponseInformationState {
    requestFromTermSet: ITermSet | null;
    timeframeTermSet: ITermSet | null;
    selectedRequestFrom: ITerm | null;
    enteredSummary?: string;
    enteredTitle?: string;    
    recordFlags: ITerm[] | null;
    inActiveFields: IContentTypeField[] | null;
    allFields: IContentTypeField[] | null;
    activeFields: IContentTypeField[] | null;
    termGroup: ITermGroup | null;
    selectedDateRequested: Date | null;
    selectedTimeframe: ITerm | null;
    selectedAutoResponseTemplate: ITerm | null;
    relatedRecords?: string;
    selectedSignatureRequired?: boolean;
    selectedRecordFlags: ITerm[] | null;
}

// 4. FEATURE INITIAL STATE
export const initialState: IWizardResponseInformationState = {
    requestFromTermSet: null,
    timeframeTermSet: null,
    inActiveFields: null,
    allFields: null,
    activeFields: null,
    termGroup: null,
    selectedRequestFrom: null,
    enteredSummary: undefined,
    enteredTitle: undefined,
    recordFlags: null,
    selectedDateRequested: null,
    selectedAutoResponseTemplate: null,
    selectedTimeframe: null,
    selectedSignatureRequired: true,
    relatedRecords: undefined,
    selectedRecordFlags: null,
};

// 4. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const wizardResponseInformationSlice = createSlice({
    name: "wizardResponseInformation",
    initialState,
    reducers: {
        resetWizardResponseInformation: (state: IWizardResponseInformationState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardResponseInformationState = key as keyof IWizardResponseInformationState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (state[keyAs] as any) = initialState[keyAs] as any;
            }
        },
        setWizardResponseInformation: (
            state: IWizardResponseInformationState,
            action: PayloadAction<Partial<IRecord>>
        ) => {
            const record: Partial<IRecord> = action.payload;
            state.enteredTitle = record.title;
            const requestFromId: string | null | undefined = record.requestFrom && record.requestFrom?.length ? (record.requestFrom)[0].id : undefined;
            state.selectedRequestFrom = castSelectedRequestFrom(
                state,
                requestFromId
            );

            state.selectedRecordFlags = record.recordFlags || null;

        },
        setRequestFromTermSet: (
            state: IWizardResponseInformationState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.requestFromTermSet = action.payload;
        },
        setTimeframeTermSet: (
            state: IWizardResponseInformationState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.timeframeTermSet = action.payload;
        },
        setRecordFlagsTermSet: (
            state: IWizardResponseInformationState,
            action: PayloadAction<ITerm[] | null>
        ) => {
            state.recordFlags = action.payload;
        },
        setInactiveFields: (
            state: IWizardResponseInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.inActiveFields = action.payload;
        },
        setAllFields: (
            state: IWizardResponseInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.allFields = action.payload;
        },
        setActiveFields: (
            state: IWizardResponseInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.activeFields = action.payload;
        },
        setTermGroup: (
            state: IWizardResponseInformationState,
            action: PayloadAction<ITermGroup | null>
        ) => {
            state.termGroup = action.payload;
        },
        setSelectedRequestFrom: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedRequestFrom = castSelectedRequestFrom(state, action.payload);
        },
        setSummary: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.enteredSummary = undefined;
            } else {
                state.enteredSummary = action.payload;
            }
        },
        setTitle: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.enteredTitle = undefined;
            } else {
                state.enteredTitle = action.payload;
            }
        },
        setDateRequested: (
            state: IWizardResponseInformationState,
            action: PayloadAction<Date | undefined | null>
        ) => {
            if (!action || !action.payload) {
                state.selectedDateRequested = null;
            } else {
                // To Do: Parse Date to string - https://stackoverflow.com/questions/64205930/what-are-the-actual-risks-of-storing-non-serializable-data-items-in-redux-store
                state.selectedDateRequested = action.payload;
            }
        },
        setSelectedTimeframe: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.selectedRequestFrom = null;
            } else {
                const timeframeTerms: ITerm[] = state.timeframeTermSet?.terms || [];
                for (let i: number = 0; i < timeframeTerms.length; i++) {
                    const term: ITerm = timeframeTerms[i];
                    if (term.id === action.payload) {
                        state.selectedTimeframe = term;
                        //  To Do: Logic goes here
                        //  switch (term.name) {
                        //     case "5":
                        //         state.selectedDate
                        //         break;
                        // };
                        return;
                    }
                }
            }
        },
        setSelectedRecordFlags: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            const idx: number = (state.selectedRecordFlags || []).findIndex(x => x.id === action.payload);
            if (idx !== -1) {
                state.selectedRecordFlags?.splice(idx, 1) || null;
            } else if (action.payload) {
                const recordFlag: ITerm | undefined = (state.recordFlags || []).find(x=> x.id === action.payload);
                if (recordFlag) {
                    if (state.selectedRecordFlags) {
                        state.selectedRecordFlags.push(recordFlag);
                    } else {
                        state.selectedRecordFlags = [recordFlag];
                    }
                }
            }
        },
        setRelatedRecords: (
            state: IWizardResponseInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.relatedRecords = undefined;
            } else {
                state.relatedRecords = action.payload;
            }
        },
        setSelectedSignatureRequired: (
            state: IWizardResponseInformationState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.selectedSignatureRequired = action.payload;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
const { 
    setRequestFromTermSet, 
    setTimeframeTermSet, 
    setInactiveFields,
    setActiveFields,
    setAllFields,
    setTermGroup,
    setRecordFlagsTermSet,
} = wizardResponseInformationSlice.actions;

export const {
    resetWizardResponseInformation,
    setWizardResponseInformation,
    setSelectedRequestFrom,
    setSummary,
    setTitle,
    setDateRequested,
    setSelectedTimeframe,
    setRelatedRecords,
    setSelectedSignatureRequired,
    setSelectedRecordFlags,
} = wizardResponseInformationSlice.actions;

// 5. ASYNCHRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getWizardResponseInformationDataAsync = (recordType: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    const requestFromTermSet: ITermSet | null = await apiService.GetRequestFromTermSet();
    dispatch(setRequestFromTermSet(requestFromTermSet));

    const timeframeTermSet: ITermSet | null = await apiService.GetTimeframeTermSet();
    dispatch(setTimeframeTermSet(timeframeTermSet));

    const contentTypeFields: IContentTypeField[] | null = await apiService.GetContentTypeFields(recordType);
    dispatch(setInactiveFields((contentTypeFields || [])?.filter(v=>!v.isActive)));
    dispatch(setActiveFields((contentTypeFields as IContentTypeField[])?.filter(v=>v.isActive)));
    dispatch(setAllFields((contentTypeFields as IContentTypeField[])));
    const termGroup: ITermGroup | null = await apiService.GetAllTermSets();
    dispatch(setTermGroup(termGroup));

    if(contentTypeFields?.find(x=>x.internalName === "ABCRecordFlags" && x.isActive)) {
        const recordFlags: ITerm[] | null = await apiService.GetAvailableRecordFlags(recordType);
        dispatch(setRecordFlagsTermSet(recordFlags));
    }
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const wizardStage = (state: RootState): number => state.recordWizardWrapper.wizardState;
export const selectSelectedRequestFrom = (state: RootState): ITerm | null =>
    state.wizardResponseInformation.selectedRequestFrom;
export const selectEnteredSummary = (state: RootState): string | undefined =>
    state.wizardResponseInformation.enteredSummary;
export const selectEnteredTitle = (state: RootState): string | undefined =>
    state.wizardResponseInformation.enteredTitle;
export const selectDateRequested = (state: RootState): Date | undefined =>
    state.wizardResponseInformation.selectedDateRequested || undefined;
export const selectSelectedTimeframe = (state: RootState): ITerm | null =>
    state.wizardResponseInformation.selectedTimeframe;
export const selectSelectedAutoResponseTemplate = (state: RootState): ITerm | null =>
    state.wizardResponseInformation.selectedAutoResponseTemplate;
export const selectRelatedRecords = (state: RootState): string | undefined =>
    state.wizardResponseInformation.relatedRecords;
export const selectSignatureRequired = (state: RootState): boolean | undefined =>
    state.wizardResponseInformation.selectedSignatureRequired;
export const selectSelectedRecordFlags = (state: RootState): ITerm[] | null =>
    state.wizardResponseInformation.selectedRecordFlags;


export const selectRequestFromTypes = (state: RootState): ITerm[] =>
    state.wizardResponseInformation.requestFromTermSet?.terms || [];
export const selectTimeframeTypes = (state: RootState): ITerm[] =>
    state.wizardResponseInformation.timeframeTermSet?.terms || [];
export const selectRecordFlags = (state: RootState): ITerm[] =>
    state.wizardResponseInformation.recordFlags || [];
export const selectInactiveFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardResponseInformation.inActiveFields;
export const selectAllFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardResponseInformation.allFields || [];
export const selectActiveFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardResponseInformation.activeFields || [];
export const selectTermGroup = (state: RootState): ITermGroup | null =>
    state.wizardResponseInformation.termGroup || null;

export const signatureTypes: IChoiceOptionProps[] = [
    {
        key: "false",
        text: "No"
    },
    {
        key: "true",
        text: "Yes"
    }
];

// 6. SELECTORS
const castSelectedRequestFrom = (
    state: IWizardResponseInformationState,
    id: string | undefined | null
): ITerm | null => {
    let result: ITerm | null = null;
    if (id) {
        const terms: ITerm[] = state.requestFromTermSet?.terms || [];
        for (let i: number = 0; i < terms.length; i++) {
            const term: ITerm = terms[i];
            if (term.id === id) {
                result = term;
            }
        }
    }
    return result;
};

// 7. EXPORT REDUCER
export default wizardResponseInformationSlice.reducer;
