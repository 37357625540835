import React from "react";
import { ColumnActionsMode, ConstrainMode, ContextualMenu, DetailsList, Selection, DetailsListLayoutMode, IColumn, IContextualMenuItem, IContextualMenuProps, SelectionMode } from "@fluentui/react";
import { ControlShimmer } from "../ControlShimmer/ControlShimmer";
import NoResults from "../NoResults/NoResults";
import styles from "./Table.module.scss";
import { ITableColumn } from "./ITableColumn";
import { IRecordResult } from "../../../services/Api/executor/IApiServiceExecutor";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";

export interface ITableProps {
    columns: ITableColumn[] | undefined;
    items: IRecordResult[] | undefined;
    isLoading: boolean;
    noResultsText?: string;
    selectionMode?: SelectionMode;
    onOrderBy: (fieldName: string | undefined, isDesc: boolean) => void;
    onSelectionChange?: (itemsSelected: Selection) => void;
}

export default function Table({ columns, items, isLoading, noResultsText, selectionMode, onOrderBy, onSelectionChange }: ITableProps): JSX.Element {
    const [colContextMenu, setColContextMenu] = React.useState<IContextualMenuProps | undefined>();
    const [sortColumnName, setSortColumnName] = React.useState<string | undefined>();
    const [sortDesc, setSortDesc] = React.useState<boolean | undefined>();
    const isEmptyResults: boolean = !!columns && !!items && !items.length;
    const isReady: boolean = !!(columns && items && !isLoading);
    let selection: Selection | undefined;
    let onSelectionChanged: () => void | undefined;
    if (!selection) {
        onSelectionChanged = () => {
            if (selection && onSelectionChange) {
                onSelectionChange(selection);
            }
        };
        selection = new Selection({ onSelectionChanged });
    }


    function getColumnMenuItems(column: ITableColumn): IContextualMenuItem[] {
        const idDate: boolean = column.type === ManagedPropertyViewType.Date || column.type === ManagedPropertyViewType.NumberAsTime;
        const menuItems: IContextualMenuItem[] = [
            {
                key: "newIsortAsc",
                text: idDate ? "Older to Newer" : "A-Z",
                iconProps: {
                    iconName: "Ascending"
                },
                canCheck: true,
                checked: column.isSorted && !column.isSortedDescending,

                onClick: () => {
                    setSortColumnName(column.sortColumnName || column.fieldName);
                    setSortDesc(false);
                    onOrderBy(column.sortColumnName || column.fieldName, false);
                }
            },
            {
                key: "sortDesc",
                text: idDate ? "Newer to Older" : "Z-A",
                iconProps: {
                    iconName: "Descending"
                },
                canCheck: true,
                checked: column.isSorted && column.isSortedDescending,
                onClick: () => {
                    setSortColumnName(column.sortColumnName || column.fieldName);
                    setSortDesc(true);
                    onOrderBy(column.sortColumnName || column.fieldName, true);
                }
            }
        ];
        return menuItems;
    }

    function onColumnClick(
        ev: React.MouseEvent<HTMLElement, MouseEvent>,
        currColumn: IColumn
    ): void {
        setColContextMenu({
            items: getColumnMenuItems(currColumn as ITableColumn),
            target: ev.currentTarget.querySelector("i") || ev.currentTarget,
            onDismiss: () => { setColContextMenu(undefined); }
        });
    }

    (columns || []).filter(c => c.isSortable).forEach(c => {
        c.onColumnClick = onColumnClick;
        c.columnActionsMode = ColumnActionsMode.hasDropdown;
        c.isSorted = c.sortColumnName === sortColumnName;
        if (c.isSorted) {
            c.isSortedDescending = sortDesc;
        }
    });

    return (
        <div className={styles.table}>
            {isReady && !isEmptyResults && (
                <DetailsList
                    className={styles.list}
                    items={(items || [])}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    enterModalSelectionOnTouch={false}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    selection={selection}
                    selectionMode={selectionMode}
                    ariaLabel={"Records Table"}
                />
            )}
            {colContextMenu && (
                <ContextualMenu {...colContextMenu} />
            )}
            {isReady && isEmptyResults && (
                <NoResults text={noResultsText} />
            )}
            {!isReady && <ControlShimmer rows={3} cols={6} />}
        </div>
    );
}