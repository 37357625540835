import { useState, useEffect } from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export default function useDebounce(value: any, delay: any): any {
    const [ret, setValue] = useState(value);

    useEffect(() => {
        const handler: NodeJS.Timeout = setTimeout(() => {
            setValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return ret;
}