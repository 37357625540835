import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    addSelectedTemplates,
    editSelectedSupportingDoc,
    editSelectedPrimaryDoc,
    getWizardResponseTemplateStepDataAsync,
    moveSelectedSupportingDoc,
    moveSelectedPrimaryDoc,
    deleteFileAsync,
    uploadFilesAsync,
    removeTemplate,
    selectAvailableTemplates,
    supportingDocLoading,
    primaryDocLoading,
    selectedSupportingDocuments,
    selectedPrimaryDocuments,
    userSelectedTemplates,
    templateLoading,
    selectErrorMessages,
    userSelectedAttachments,
    editSelectedAttachments,
    moveSelectedAttachments,
    attachmentsLoading
} from "./wizardResponseTemplateStepSlice";
import styles from "./WizardResponseTemplateStep.module.scss";
import {
    selectSelectedRecordType
} from "../wizardCreateRecord/wizardCreateRecordSlice";
import {
    selectWizardType, setPreventNext, WizardType
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import {
    FormMode,
    IContentType,
    IFile,
    ITemplate,
    RecordDocumentType,
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    responseTemplateHeaderStrings
} from "../../providers/Constants/RecordWizardConstants";
import TemplateList from "../../components/FormControls/TemplateList/TemplateList";
import Select, {
    ISelectOptionProps
} from "../../components/FormControls/Select/Select";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import FileUpload from "../../components/FormControls/FileUpload/FileUpload";
import { AttachmentUploadingText, PrimaryDocumentUploadingText, SupportingDocumentUploadingText } from "../../providers/Constants/FormControlConstants";
import { useTranslation } from "react-i18next";

export function WizardResponseTemplateStep(): JSX.Element {
    const { t } = useTranslation();
    // 2. SELECT CURRENT GLOBAL STATE
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);
    const selectedTemplates: ITemplate[] = useSelector(userSelectedTemplates);
    const templateTypes: ITemplate[] = useSelector(selectAvailableTemplates);
    const isAttachmentsLoading: boolean = useSelector(attachmentsLoading);
    const isSupportingDocLoading: boolean = useSelector(supportingDocLoading);
    const isPrimaryDocLoading: boolean = useSelector(primaryDocLoading);
    const isTemplateLoading: boolean = useSelector(templateLoading);
    const selectedAttachments: IFile[] = useSelector(userSelectedAttachments);
    const selectedSupportingFiles: IFile[] = useSelector(selectedSupportingDocuments);
    const selectedPrimaryFiles: IFile[] = useSelector(selectedPrimaryDocuments);
    const errorMessages: string[] | undefined = useSelector(selectErrorMessages);
    const wizardType: WizardType = useSelector(selectWizardType);
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/typedef
    const dispatch = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    React.useEffect(() => {
        if (selectedRecordType?.rowKey) { 
            dispatch(
                getWizardResponseTemplateStepDataAsync([selectedRecordType?.rowKey])
            );
        }
    }, [dispatch, selectedRecordType]);

    React.useEffect(() => {
        dispatch(setPreventNext(isSupportingDocLoading || isTemplateLoading || isPrimaryDocLoading));
    }, [dispatch, isSupportingDocLoading, isTemplateLoading, isPrimaryDocLoading]);

    const availableTemplates: ISelectOptionProps[] = templateTypes.map(tmpl => ({
        key: String(tmpl.url),
        text: tmpl.name,
        disabled: selectedTemplates.find(e => e.name == tmpl.name) != undefined ? true : false
    }));

    // Returning the new form component.
    return (
        <div className={styles.wizardResponseTemplateContainer}>
            <div className={styles.headerContainer}>
                {(wizardType === WizardType.Other || wizardType === WizardType.Event) && <FormHeader strings={responseTemplateHeaderStrings} />}
                {wizardType === WizardType.Correspondence && <FormHeader strings={{
                    Header: t("responseTemplateHeaderStringsCorro_Header"),
                    DotPoints: [t("responseTemplateHeaderStringsCorro_DotPoints_1"), t("responseTemplateHeaderStringsCorro_DotPoints_2")]
                }} />}
            </div>
            <div className={styles.sections}>
                <h3 className={styles.header}>{t("fieldTemplateHeader")}</h3>

                {(wizardType === WizardType.Other || wizardType === WizardType.Event) && <div className={styles.descriptions}>{t("fieldTemplateDescription")}</div>}
                {wizardType === WizardType.Correspondence && <div className={styles.descriptions}>{t("fieldTemplateDescriptionCorro")}</div>}
                <div className={styles.templateContainerNewForm}>
                    <Select
                        name={t("fieldTemplateSubHeader")}
                        options={availableTemplates}
                        required={false}
                        showAsLoading={isTemplateLoading}
                        onChange={option => {
                            if (option) {
                                dispatch(addSelectedTemplates({ name: option.text, url: option.key }));
                            }
                        }}
                        className={styles.selectContainer}
                    />
                    <TemplateList
                        availableTemplates={selectedTemplates}
                        onClick={(delTemplate: ITemplate) => {
                            dispatch(removeTemplate(delTemplate));
                        }}
                    ></TemplateList>
                </div>
            </div>
            <div className={styles.supportingDocSection}>
                <h3 className={styles.header}>{t("fieldPrimaryDocumentHeader")}</h3>
                <div className={styles.descriptions}>{t("fieldPrimaryDocumentDescription")}</div>
                <FileUpload
                    selectedFilesList={selectedPrimaryFiles}
                    onFileSelected={(selectedFile: IFile[]) => {
                        dispatch(uploadFilesAsync(selectedFile, RecordDocumentType.departmentWorkProduct));
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        dispatch(deleteFileAsync(selectedFile, RecordDocumentType.departmentWorkProduct));
                    }}
                    onFileEdit={(filenames: string[]) => {
                        dispatch(editSelectedPrimaryDoc(filenames));
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        dispatch(moveSelectedPrimaryDoc([file, movement]));
                    }}
                    onLoading={isPrimaryDocLoading}
                    onLoadingText={PrimaryDocumentUploadingText}
                    componentMode={FormMode.New}
                    errorMessages={errorMessages}
                    canEdit={true}
                    canAdd={true}
                    canRemove={true}
                    canUploadSigned={false}
                ></FileUpload>
            </div>
            <div className={styles.supportingDocSection}>
                <h3 className={styles.header}>{t("fieldSupportingDocumentHeader")}</h3>
                <div className={styles.descriptions}>{t("fieldSupportingDocumentDescription")}</div>
                <FileUpload
                    selectedFilesList={selectedSupportingFiles}
                    onFileSelected={(selectedFile: IFile[]) => {
                        dispatch(uploadFilesAsync(selectedFile, RecordDocumentType.supportingDocument));
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        dispatch(deleteFileAsync(selectedFile, RecordDocumentType.supportingDocument));
                    }}
                    onFileEdit={(filenames: string[]) => {
                        dispatch(editSelectedSupportingDoc(filenames));
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        dispatch(moveSelectedSupportingDoc([file, movement]));
                    }}
                    onLoading={isSupportingDocLoading}
                    onLoadingText={SupportingDocumentUploadingText}
                    componentMode={FormMode.New}
                    errorMessages={errorMessages}
                    canEdit={true}
                    canAdd={true}
                    canRemove={true}
                    canUploadSigned={false}
                ></FileUpload>
            </div>
            <div className={styles.supportingDocSection}>
                <h3 className={styles.header}>{t("fieldAttachmentsHeader")}</h3>
                {(wizardType === WizardType.Other || wizardType === WizardType.Event) && <div className={styles.descriptions}>{t("editFieldAttachmentsDescription1")}</div>}
                {wizardType === WizardType.Correspondence && <div className={styles.descriptions}>{t("editFieldAttachmentsDescription1")}</div> }
                <FileUpload
                    selectedFilesList={selectedAttachments}
                    onFileSelected={(selectedFile: IFile[]) => {
                        dispatch(uploadFilesAsync(selectedFile, RecordDocumentType.attachment));
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        dispatch(deleteFileAsync(selectedFile, RecordDocumentType.attachment));
                    }}
                    onFileEdit={(filenames: string[]) => {
                        dispatch(editSelectedAttachments(filenames));
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        dispatch(moveSelectedAttachments([file, movement]));
                    }}
                    onLoading={isAttachmentsLoading}
                    onLoadingText={AttachmentUploadingText}
                    componentMode={FormMode.New}
                    errorMessages={errorMessages}
                    canEdit={true}
                    canAdd={true}
                    canRemove={true}
                    canUploadSigned={false}
                ></FileUpload>
            </div>
        </div>
    );
}
