import { Doughnut } from "react-chartjs-2";
import React from "react";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import {
    onTimeRecordsText,
    overdueRecordsText,
    recordsChartInfo,
    recordsOverdueInfo,
    recordsTimelinessHeader,
    recordsTimelinessDescr
} from "./recordsTimelinessConstants";
import styles from "./RecordsTimeliness.module.scss";
import { IRecordResult } from "../../../services/Api/executor/IApiServiceExecutor";
import { StageTypes } from "../../../providers/Constants/TermConstants";

interface IRecordsTimelinessProps {
    records?: IRecordResult[];
    inProgress?: boolean;
    mobileView?: boolean;
}

export function RecordsTimeliness({
    records,
    inProgress,
    mobileView
}: IRecordsTimelinessProps): JSX.Element {

    const [recordsData, setRecordsData] = React.useState<IRecordResult[] | undefined>(undefined);

    React.useEffect(() => {
        if (records) {
            setRecordsData(records);
        }
    }, [records]);

    const overdueRecordsToDecisionMaker: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        if (recordsData) {
            const overdueRecordsData: IRecordResult[] = recordsData.filter(recordItem => {
                // after the recommender stage, the record can no longer be overdue to the decision maker
                if (recordItem.stage?.value === StageTypes.Initiate || recordItem.stage?.value === StageTypes.PrepareAndReview || recordItem.stage?.value === StageTypes.Recommend) {
                    const dateString: string | undefined = recordItem.dueDateDecisionMaker?.value; //getDateString(recordItem);
                    return dateString ? new Date() > new Date(dateString) : false;
                }
                else {
                    return false;
                }
            });
            return overdueRecordsData;
        } else {
            return [];
        }
    }, [recordsData]);

    const overdueRecordsToRecommender: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        if (recordsData) {
            const overdueRecordsData: IRecordResult[] = recordsData.filter(recordItem => {
                // after the prepare and review stage, the record can no longer be overdue to the recommender
                if (recordItem.stage?.value === StageTypes.Initiate || recordItem.stage?.value === StageTypes.PrepareAndReview) {
                    const dateString: string | undefined = recordItem.dueDateRecommender?.value; //getDateString(recordItem);
                    return dateString ? new Date() > new Date(dateString) : false;
                }
                else {
                    return false;
                }
            });
            return overdueRecordsData;
        } else {
            return [];
        }
    }, [recordsData]);

    const percentageOverdueRecords: number = React.useMemo((): number => {
        if (overdueRecordsToDecisionMaker && overdueRecordsToRecommender) {
            const uniqueOverdueRecords: IRecordResult[] = [];
            uniqueOverdueRecords.push(...overdueRecordsToDecisionMaker);
            overdueRecordsToRecommender.forEach(overDueRecord => {
                if (!uniqueOverdueRecords.some(u => u.recordId === overDueRecord.recordId)) {
                    uniqueOverdueRecords.push(overDueRecord);
                }
            });
            return Math.floor(
                (uniqueOverdueRecords.length / (recordsData?.length || 1)) * 100
            );
        } else {
            return 0;
        }
    }, [overdueRecordsToDecisionMaker, overdueRecordsToRecommender, recordsData?.length]);

    const percentageOnTimeRecords: number = React.useMemo((): number => {
        return percentageOverdueRecords ? 100 - percentageOverdueRecords : 100;
    }, [percentageOverdueRecords]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const generateLegends: (chart: any) => any = React.useCallback((chart: any): any => {
        const { data } = chart;
        if (data.labels.length && data.datasets.length) {
            return data.labels.map((label: string, index: number) => {
                const labelValue: number = data.datasets[0].data[index];
                const labelColor: string = label === overdueRecordsText ? "#d83b01" : "#0052c2";
                return {
                    index: String(index) + label,
                    text: `${label} (${labelValue}%)`,
                    textAlign: "left",
                    fillStyle: labelColor,
                    hidden: !labelValue,
                    lineWidth: 0,
                    datasetIndex: 0
                };
            });
        }
    }, []);

    const maxChartsPercentage: number = React.useMemo(() => Math.max(percentageOverdueRecords, percentageOnTimeRecords), [percentageOnTimeRecords, percentageOverdueRecords]);

    return (
        <div
            className={[
                styles.recordsCardInfo,
                mobileView ? styles.mobileRecordsCardInfo : ""
            ].join(" ")}
        >
            <div className={styles.recordsTimelinessContainer}>
                <div className={styles.cardTextInfo}>
                    <div className={styles.cardHeader}>
                        <Icon iconName={"CalendarMirrored"} ariaLabel={"Calendar icon"}/>
                        <h3>{recordsTimelinessHeader}</h3>
                    </div>
                    <p>{recordsTimelinessDescr}</p>
                </div>
                {!inProgress ? (
                    <div
                        className={[
                            styles.recordsTimelinessInfoContainer,
                            mobileView ? styles.mobileRecordsTimelinessInfoContainer : ""
                        ].join(" ")}
                    >
                        <div
                            className={[
                                styles.chartTimelinessContainer,
                                mobileView ? styles.mobileChartTimelinessContainer : ""
                            ].join(" ")}
                        >
                            <Doughnut
                                options={{ cutoutPercentage: 70, maintainAspectRatio: false }}
                                data={{
                                    labels: [overdueRecordsText, onTimeRecordsText],
                                    datasets: [
                                        {
                                            data: [
                                                percentageOverdueRecords,
                                                // percentageOverdueDecisionMakerRecords,
                                                // percentageOverdueRecommenderRecords,
                                                percentageOnTimeRecords
                                            ],
                                            backgroundColor: ["rgb(216, 59, 1)", "rgb(0, 82, 194)"],
                                            borderWidth: 0,
                                            label: "Records timeliness"
                                        }
                                    ]
                                }}
                                legend={{
                                    position: !mobileView ? "right" : "bottom",
                                    align: "start",
                                    labels: {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        generateLabels: (chart: any) => generateLegends(chart),
                                        boxWidth: 14,
                                        padding: mobileView ? 25 : 20,
                                        fontColor: "#0052C2"
                                    }
                                }}
                                width={260}
                                height={150}
                            />
                            {maxChartsPercentage >= 0 && (
                                <div
                                    className={[
                                        styles.maxTimelinessPercent,
                                        mobileView ? styles.mobileMaxTimelinessPercent : "",
                                    ].join(" ")}
                                >
                                    {maxChartsPercentage}
                                    <span>%</span>
                                </div>
                            )}
                            <p>{maxChartsPercentage}% {percentageOnTimeRecords > 50 ? recordsChartInfo : recordsOverdueInfo}</p>
                        </div>
                    </div>
                ) : (
                    <Spinner size={SpinnerSize.large} ariaLabel={"Loading spinner"} />
                )}
            </div>
        </div>
    );
}
