import * as React from "react";
import {
    ChoiceGroup,
    IChoiceGroup,
    IChoiceGroupOption
} from "@fluentui/react";
import styles from "./Choice.module.scss";
import { ControlContainer } from "../ControlContainer/ControlContainer";
import SPHelper from "../../../helpers/SharePointHelper";

export interface IChoiceProps {
    name: string;
    required: boolean;
    onChange?: (newValue?: IChoiceGroupOption) => void;
    options?: IChoiceGroupOption[];
    defaultSelectedKey?: string;
    allowLargeWidth?: boolean;
    supressLabelColon?: boolean;
    disabled?: boolean;
    className?: string;
}

export interface IChoiceOptionProps {
    key: string;
    text: string;
    disabled?: boolean;
}

export default class Choice extends React.Component<IChoiceProps> {
    public componentRef: IChoiceGroup | null = null;

    public render(): React.ReactElement<IChoiceProps> {
        const key: string = `${SPHelper.GetSafeFilename(this.props.name)}Control${this.props.defaultSelectedKey}`;

        return (
            <ControlContainer allowLargeWidth={this.props.allowLargeWidth}>
                <ChoiceGroup
                    id={key}
                    key={key}
                    className={[styles.textField, this.props.className ? this.props.className : ""].join(" ")}
                    defaultSelectedKey={this.props.defaultSelectedKey}
                    disabled={this.props.disabled}
                    componentRef={r => (this.componentRef = r)}
                    label={`${this.props.name}${this.props.supressLabelColon ? "" : ":"}`}
                    options={this.props.options || []}
                    ariaLabelledBy={`${this.props.name} text input control`}
                    required={this.props.required}
                    onChange={(ev, option) => {
                        if (this.props.onChange && ev) {
                            this.props.onChange(option);
                        }
                    }}
                />
            </ControlContainer>
        );
    }
}
