import StorageCacheProvider from "../providers/Cache/StorageCacheProvider";
import DependencyResolver from "../providers/DependencyResolver/DependencyResolver";
import IApiService from "../services/Api/IApiService";

export default class CacheHelper {
    public static init(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)["clearCache"] = CacheHelper.clear;
    }

    public static clear(): void {
        for (const key in localStorage) {
            if (key.startsWith(StorageCacheProvider.cacheKeyPrefix)) {
                localStorage.removeItem(key);
            }
        }

        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        apiService.ClearCache()
            .then((result: boolean) => {
                // eslint-disable-next-line no-console
                console.log("Cache cleared. Server Result: " + result);
            });
    }
}