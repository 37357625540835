import React from "react";
import styles from "./SummaryField.module.scss";

interface ISummaryFieldProps {
    fieldName: string;
    fieldValue?: string | null | JSX.Element;
    missingValueText?: string;
    isMandatory?: boolean;
    fieldCss?: React.CSSProperties;
    useLessPadding?: boolean;
    fieldHref?: string;
}

export default function SummaryField(props: ISummaryFieldProps): JSX.Element {
    const containerClass: string = props.useLessPadding
        ? `${styles.summaryFieldContainer} ${styles.useLessPadding}`
        : styles.summaryFieldContainer;
    return (
        <div className={containerClass}>
            <div className={styles.fieldContainer}>
                <span className={styles.fieldText}>{props.fieldName}</span>
                {props.isMandatory && <span className={styles.asterisk}> *</span>}
            </div>
            <div className={styles.valueContainer}>
                {props.fieldValue ? (
                    <div className={styles.valueText} style={props.fieldCss}>
                        {!!props.fieldHref && (
                            <a href={props.fieldHref}>{props.fieldValue}</a>
                        )}
                        {!props.fieldHref && props.fieldValue}
                    </div>
                ) : (
                    <div className={styles.missingText}>{props.missingValueText}</div>
                )}
            </div>
        </div>
    );
}
