import React, { useState } from "react";
import { Icon } from "@fluentui/react";
import styles from "./CollapsibleContainer.module.scss";

interface ICollapsibleContainerProps {
    title: string;
    onEditClick?: () => void;
    children?: JSX.Element | JSX.Element[] | false;
    onExpandClick?: (expanded: boolean) => void;
    expanded?: boolean;
    expandedDefault?: boolean;
    highlightHeadingBg?: boolean;
    highlightContentBg?: boolean;
    className?: string;
    smallHeading?: boolean;
}

export default function CollapsibleContainer(props: ICollapsibleContainerProps): JSX.Element {
    const [expanded, setExpanded] = useState<boolean>(
        props.expandedDefault === undefined ? true : props.expandedDefault
    );

    const expandCollapse = () => {
        props.onExpandClick && props.onExpandClick(!expanded);
        setExpanded(!expanded);
    };

    React.useEffect(() => {
        if (props.expanded !== undefined) {
            setExpanded(props.expanded);
        }
    }, [props, props.expanded]);

    const stateClass: string = React.useMemo(() => {
        return expanded ? "open" : "";
    }, [expanded]);

    return (
        <div className={`${styles.collapsibleContainer} ${props.className || ""}`}>
            <div
                className={`${styles.collapsibleSection} head ${stateClass} ${
                    props.highlightHeadingBg && expanded ? "highlighted" : ""
                }`}
            >
                <div
                    onClick={() => {
                        expandCollapse();
                    }}
                >
                    <div className={"icon"}>
                        <Icon
                            iconName={expanded ? "ChevronDown" : "ChevronRight"}
                            className={"chevron"}
                            ariaLabel={"Expand Icon"}
                        />
                    </div>
                    <div className={`title ${stateClass} ${props.smallHeading ? "titleSmall" : "titleLarge"}`}>{props.title}</div>
                </div>

                {!!props.onEditClick && (
                    <div
                        className={"edit"}
                        onClick={() => props.onEditClick && props.onEditClick()}
                    >
                        <Icon iconName={"Edit"} className={"editIcon"} ariaLabel={"Edit Icon"} />
                        Edit
                    </div>
                )}
            </div>
            <div
                className={`body ${expanded ? "expanded" : "collapsed"} ${
                    props.highlightContentBg ? "highlighted" : ""
                }`}
            >
                {props.children}
            </div>
        </div>
    );
}
