// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setDocumentFetching } from "../manageRecordWrapper/manageRecordWrapperSlice";
import IApiService from "../../services/Api/IApiService";
import {
    IDeleteRecordDocumentRequest,
    IEditRecordDocumentRequest,
    IFile,
    IFileOrder,
    IRecordDocuments,
    IReorderRecordDocumentRequest,
    ITemplate,
    ITermSet,
    IUploadRecordDocumentRequest,
    IUploadRecordSignedDocumentRequest,
    IUploadRecordTemplateRequest,
    RecordDocumentType
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { FileHelper } from "../../helpers/FileHelper";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export interface IWizardResponseTemplateState {
    selectedTemplates: ITemplate[];
    availableTemplates: ITemplate[];
    selectedSupportingFiles: IFile[];
    selectedPrimaryFiles: IFile[];
    signedAndApprovedDocuments: IFile[];
    securityClassification: ITermSet | null;
    recordDocuments: IRecordDocuments | null;
    selectedAttachments: IFile[];
    attachmentLoading: boolean;
    supportingDocLoading: boolean;
    templateLoading: boolean;
    primaryDocLoading: boolean;

    attachmentUploadSuccess: boolean;
    supportingDocUplaodSuccess: boolean;
    primaryDocUplaodSuccess: boolean;

    attachmentDocErrorMessages: string [] | undefined;
    supportingDocErrorMessages: string [] | undefined;
    primaryDocErrorMessages: string [] | undefined;
    templateDocErrorMessages: string [] | undefined;
}

export const initialState: IWizardResponseTemplateState = {
    selectedTemplates: [],
    availableTemplates: [],
    selectedSupportingFiles: [],
    selectedPrimaryFiles: [],
    signedAndApprovedDocuments: [],
    securityClassification: null,
    recordDocuments: null,
    selectedAttachments: [],
    attachmentLoading: false,
    supportingDocLoading: false,
    templateLoading: false,
    primaryDocLoading: false,
    attachmentDocErrorMessages: undefined,
    supportingDocErrorMessages: undefined,
    templateDocErrorMessages: undefined,
    primaryDocErrorMessages: undefined,
    attachmentUploadSuccess: false,
    supportingDocUplaodSuccess: false,
    primaryDocUplaodSuccess: false
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const manageRecordEditDocumentsSlice = createSlice({
    name: "manageRecordEditDocuments",
    initialState,
    reducers: {
        setAvailableTemplates: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<ITemplate[] | null>
        ) => {
            state.availableTemplates = action.payload || [];
        },
        addSelectedTemplates: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<ITemplate>
        ) => {
            state.selectedTemplates.push(action.payload);
        },
        setSelectedTemplates: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<ITemplate[]>
        ) => {
            state.selectedTemplates = action.payload;
        },
        removeTemplate: (state: IWizardResponseTemplateState, action: PayloadAction<ITemplate>) => {
            const templateIndex: number = state.selectedTemplates.findIndex(
                t => t.name == action.payload.name
            );
            if (templateIndex > -1) {
                state.selectedTemplates.splice(templateIndex, 1);
            }
        },
        addSignedAndApprovedDocument: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            state.signedAndApprovedDocuments.push(action.payload);
        },
        updateRecordDocuments: (state: IWizardResponseTemplateState, action: PayloadAction<IFile[]>) => {
            const newDocs: IFile[] = action.payload;
            if(newDocs.length){
                let deptWorkProducts: IFile[] = [...state.recordDocuments?.deptWorkProducts || []];
                let attachments: IFile[] = [...state.recordDocuments?.attachments || []];

                deptWorkProducts = deptWorkProducts.map(doc => { 
                    const newDoc: IFile | undefined = newDocs.find(newDoc => newDoc.id == doc.id);
                    return newDoc ? newDoc : doc;
                });

                attachments = attachments.map(doc => { 
                    const newDoc: IFile | undefined = newDocs.find(newDoc => newDoc.id == doc.id);
                    return newDoc ? newDoc : doc;
                });

                state.recordDocuments = {
                    ...state.recordDocuments,
                    deptWorkProducts: deptWorkProducts,
                    attachments: attachments
                };
            }
            state.signedAndApprovedDocuments = action.payload;
        },
        editSignedAndApprovedDocument: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.signedAndApprovedDocuments.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const curr: IFile = state.signedAndApprovedDocuments[fileIndex];
                const updatedFile: IFile = {
                    ...curr,
                    name: action.payload[1] || "-",
                };
                state.signedAndApprovedDocuments.splice(fileIndex, 1, updatedFile);
            }
        },
        removeSignedAndApprovedDocument: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            const fileIndex: number = state.signedAndApprovedDocuments.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.signedAndApprovedDocuments.splice(fileIndex, 1);
            }
        },
        addSelectedPrimaryFile: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            state.selectedPrimaryFiles.push(action.payload);
        },
        setSelectedPrimaryFiles: (state: IWizardResponseTemplateState, action: PayloadAction<IFile[]>) => {
            state.selectedPrimaryFiles = action.payload;
        },
        removeSelectedPrimaryFile: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            const fileIndex: number = state.selectedPrimaryFiles.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.selectedPrimaryFiles.splice(fileIndex, 1);
            }
        },
        addSelectedFile: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            state.selectedSupportingFiles.push(action.payload);
        },
        setSelectedSupportingFiles: (state: IWizardResponseTemplateState, action: PayloadAction<IFile[]>) => {
            state.selectedSupportingFiles = action.payload;
        },
        removeSelectedFile: (state: IWizardResponseTemplateState, action: PayloadAction<IFile>) => {
            const fileIndex: number = state.selectedSupportingFiles.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.selectedSupportingFiles.splice(fileIndex, 1);
            }
        },
        setRecordDocuments: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<IRecordDocuments | null>
        ) => {
            state.recordDocuments = action.payload;

            // state.selectedTemplates = []; //.splice(0);
            state.selectedAttachments = []; //.splice(0);
            state.selectedSupportingFiles = []; //.splice(0);
            state.signedAndApprovedDocuments = []; //.splice(0);
            state.selectedPrimaryFiles = []; //.splice(0);

            if (action.payload) {
                action.payload.deptWorkProducts?.map(s => {
                    // state.selectedTemplates.push({ ...s, url: s.contentURL || ""});
                    state.selectedPrimaryFiles.push(s);
                });

                action.payload.attachments?.map(s => {
                    state.selectedAttachments.push(s);
                });

                action.payload.supportingDocuments?.map(s => {
                    state.selectedSupportingFiles.push(s);
                });

                action.payload.signedAndApprovedProducts?.map(s => {
                    state.signedAndApprovedDocuments.push(s);
                });
            }
        },
        setSecurityClassification: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<ITermSet | null>
        ) => {
            if(action.payload){
                state.securityClassification = action.payload;   
            }
        },
        editSelectedPrimaryFile: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedPrimaryFiles.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const curr: IFile = state.selectedPrimaryFiles[fileIndex];
                const updatedFile: IFile = {
                    ...curr,
                    name: action.payload[1] || "-",
                };
                state.selectedPrimaryFiles.splice(fileIndex, 1, updatedFile);
            }
        },
        editSelectedFile: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedSupportingFiles.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const curr: IFile = state.selectedSupportingFiles[fileIndex];
                const updatedFile: IFile = {
                    ...curr,
                    name: action.payload[1] || "-",
                };
                state.selectedSupportingFiles.splice(fileIndex, 1, updatedFile);
            }
        },
        addSelectedAttachment: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<IFile>
        ) => {
            state.selectedAttachments.push(action.payload);
        },
        setSelectedAttachments: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<IFile[]>
        ) => {
            state.selectedAttachments = action.payload;
        },
        removeSelectedAttachment: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<IFile>
        ) => {
            const fileIndex: number = state.selectedAttachments.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.selectedAttachments.splice(fileIndex, 1);
            }
        },
        editSelectedAttachment: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedAttachments.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const curr: IFile = state.selectedAttachments[fileIndex];
                const updatedFile: IFile = {
                    ...curr,
                    name: action.payload[1] || "-",
                };
                state.selectedAttachments.splice(fileIndex, 1, updatedFile);
            }
        },
        editSelectedTemplates: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedTemplates.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const curr: ITemplate = state.selectedTemplates[fileIndex];
                const updatedFile: ITemplate = {
                    ...curr,
                    name: action.payload[1] || "-",
                };
                state.selectedTemplates.splice(fileIndex, 1, updatedFile);
            }
        },
        setComponentLoading: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<[RecordDocumentType, boolean]>
        ) => {
            switch (action.payload[0]) {
                case RecordDocumentType.attachment:
                    state.attachmentLoading = action.payload[1];
                    break;
                case RecordDocumentType.supportingDocument:
                    state.supportingDocLoading = action.payload[1];
                    break;
                case RecordDocumentType.departmentWorkProduct:
                    state.primaryDocLoading = action.payload[1];
                    break;
            }
        },
        setIsUplaodSuccess:(
            state: IWizardResponseTemplateState,
            action: PayloadAction<[RecordDocumentType, boolean]>
        ) => {
            switch (action.payload[0]) {
                case RecordDocumentType.attachment:
                    state.attachmentUploadSuccess = action.payload[1];
                    break;
                case RecordDocumentType.supportingDocument:
                    state.supportingDocUplaodSuccess = action.payload[1];
                    break;
                case RecordDocumentType.departmentWorkProduct:
                    state.primaryDocUplaodSuccess = action.payload[1];
                    break;
            }
        },
        setErrorMessages: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<[RecordDocumentType, string[] | undefined]>
        ) => {
            switch (action.payload[0]) {
                case RecordDocumentType.attachment:
                    state.attachmentDocErrorMessages = action.payload[1];
                    break;
                case RecordDocumentType.supportingDocument:
                    state.supportingDocErrorMessages = action.payload[1];
                    break;
                case RecordDocumentType.departmentWorkProduct:
                    state.primaryDocErrorMessages = action.payload[1];
                    break;
            }
        },
        setReorderDocuments: (
            state: IWizardResponseTemplateState,
            action: PayloadAction<IFileOrder>
        ) => {
            const fileOrder: IFileOrder = action.payload;
            if (!!fileOrder.deptWorkProductsOrder && !!state.selectedTemplates) {
                state.selectedTemplates = state.selectedTemplates.sort((a, b) => {
                    return fileOrder.deptWorkProductsOrder.indexOf(a.name) - fileOrder.deptWorkProductsOrder.indexOf(b.name);
                });
            }
            if (!!fileOrder.attachmentsOrder && !!state.selectedAttachments) {
                state.selectedAttachments = state.selectedAttachments.sort((a, b) => {
                    return fileOrder.attachmentsOrder.indexOf(a.name) - fileOrder.attachmentsOrder.indexOf(b.name);
                });
            }
            if (!!fileOrder.supportingDocumentsOrder && !!state.selectedSupportingFiles) {
                state.selectedSupportingFiles = state.selectedSupportingFiles.sort((a, b) => {
                    return fileOrder.supportingDocumentsOrder.indexOf(a.name) - fileOrder.supportingDocumentsOrder.indexOf(b.name);
                });
            }
            if (!!fileOrder.deptWorkProductsOrder && !!state.selectedPrimaryFiles) {
                state.selectedPrimaryFiles = state.selectedPrimaryFiles.sort((a, b) => {
                    return fileOrder.deptWorkProductsOrder.indexOf(a.name) - fileOrder.deptWorkProductsOrder.indexOf(b.name);
                });
            }
        },
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    addSignedAndApprovedDocument,
    editSignedAndApprovedDocument,
    removeSignedAndApprovedDocument,
    addSelectedTemplates,
    removeTemplate,
    setAvailableTemplates,
    addSelectedFile,
    removeSelectedFile,
    addSelectedPrimaryFile,
    removeSelectedPrimaryFile,
    setRecordDocuments,
    setSecurityClassification,
    editSelectedFile,
    editSelectedPrimaryFile,
    addSelectedAttachment,
    removeSelectedAttachment,
    editSelectedAttachment,
    editSelectedTemplates: editSelectedDeptWorkProducts,
    setSelectedAttachments,
    setSelectedTemplates,
    setSelectedSupportingFiles,
    setSelectedPrimaryFiles,
    setComponentLoading,
    setIsUplaodSuccess,
    updateRecordDocuments,
    setErrorMessages,
    setReorderDocuments
} = manageRecordEditDocumentsSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const deleteDocumentAsync = (
    recordDocument: IDeleteRecordDocumentRequest
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([recordDocument.type, true]));
    // Delete the record document from SharePoint
    const retVal: boolean = await apiService.DeleteRecordDocument({
        ...recordDocument
    });

    // Remove file from the state
    if (retVal) {
        switch (recordDocument.type) {
            case RecordDocumentType.departmentWorkProduct:
                dispatch(removeSelectedPrimaryFile({ name: recordDocument.name, contentURL: "", path: "" }));
                break;
            case RecordDocumentType.attachment:
                dispatch(removeSelectedAttachment({ name: recordDocument.name, contentURL: "", path: "" }));
                break;
            case RecordDocumentType.supportingDocument:
                dispatch(removeSelectedFile({ name: recordDocument.name, contentURL: "", path: "" }));
                break;
            case RecordDocumentType.signedAndApprovedDocument:
                dispatch(removeSignedAndApprovedDocument({ name: recordDocument.name, contentURL: "", path: "" }));
                // need to do this as removing the signed doc needs to get other sections to reload
                dispatch(getRecordDocumentsAsync(recordDocument.recordId, recordDocument.recordName));
                break;
        }
        dispatch(setComponentLoading([recordDocument.type, false]));
    }
    return retVal;
};

export const editDocumentAsync = (
    recordDocument: IEditRecordDocumentRequest
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([recordDocument.type, true]));

    // Rename the record document from SharePoint
    const retVal: boolean = await apiService.EditRecordDocument({
        ...recordDocument
    });

    // Update the state
    if (retVal) {
        switch (recordDocument.type) {
            case RecordDocumentType.departmentWorkProduct:
                dispatch(editSelectedPrimaryFile([recordDocument.name, recordDocument.updatedName]));
                break;
            case RecordDocumentType.attachment:
                dispatch(editSelectedAttachment([recordDocument.name, recordDocument.updatedName]));
                break;
            case RecordDocumentType.supportingDocument:
                dispatch(editSelectedFile([recordDocument.name, recordDocument.updatedName]));
                break;
            case RecordDocumentType.signedAndApprovedDocument:
                dispatch(editSignedAndApprovedDocument([recordDocument.name, recordDocument.updatedName]));
                break;
        }
        dispatch(setComponentLoading([recordDocument.type, false]));
    }
    return retVal;
};

export const uploadDocumentsAsync = (
    recordDocuments: IUploadRecordDocumentRequest[]
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([recordDocuments[0].type, true]));
    dispatch(setErrorMessages([recordDocuments[0].type, undefined]));

    const promises: Promise<void>[] = [];
    let errorMessages: string[] | undefined = undefined;
    for(const doc of recordDocuments){
        if (doc.content) {
            const binary: string  = await FileHelper.fileContentAsBase64(doc.content);
            doc.content = binary;
        }

        // Rename the record document from SharePoint
        const p: Promise<void> = apiService.UploadRecordDocument({
            ...doc
        }).then((retVal: string | null) => {    
            // Update the state
            if (retVal && retVal != "") {
                switch (doc.type) {
                    case RecordDocumentType.departmentWorkProduct:
                        dispatch(
                            addSelectedPrimaryFile({
                                name: doc.name,
                                contentURL: retVal,
                                path: ""
                            })
                        );
                        break;
                    case RecordDocumentType.attachment:
                        dispatch(
                            addSelectedAttachment({
                                name: doc.name,
                                contentURL: retVal,
                                path: ""
                            })
                        );
                        break;
                    case RecordDocumentType.supportingDocument:
                        dispatch(
                            addSelectedFile({
                                name: doc.name,
                                contentURL: retVal,
                                path: ""
                            })
                        );
                        break;
                }
            } else {
                errorMessages = errorMessages || [];
                errorMessages.push(`Failed to upload file: ${doc.name}`);
            }
        });

        promises.push(p);
    }

    await Promise.all(promises);
    
    dispatch(setComponentLoading([recordDocuments[0].type, false]));
    dispatch(setErrorMessages([recordDocuments[0].type, errorMessages]));
    
    if(!errorMessages) {
        dispatch(setIsUplaodSuccess([recordDocuments[0].type, true]));
        setTimeout(() => {
            dispatch(setIsUplaodSuccess([recordDocuments[0].type, false]));
        }, 3000);
    }
};

export const uploadSignedDocumentAsync = (
    recordDocument: IUploadRecordSignedDocumentRequest,
    existingDocumentType: RecordDocumentType,
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([existingDocumentType, true]));
    dispatch(setErrorMessages([existingDocumentType, undefined]));

    if (recordDocument.content) {
        const binary: string  = await FileHelper.fileContentAsBase64(recordDocument.content);
        recordDocument.content = binary;
    }

    // Rename the record document from SharePoint
    const retVal: IRecordDocuments | null = await apiService.UploadRecordSignedDocument({
        ...recordDocument
    });

    // Update the state
    if (retVal) {
        dispatch(setRecordDocuments(retVal));
    } else {
        dispatch(setErrorMessages([existingDocumentType, [`Failed to upload signed document: ${recordDocument.name}`]]));
    }
    dispatch(setComponentLoading([existingDocumentType, false]));
};

export const uploadTemplateAsync = (
    recordDocument: IUploadRecordTemplateRequest
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([RecordDocumentType.departmentWorkProduct, true]));
    dispatch(setErrorMessages([RecordDocumentType.departmentWorkProduct, undefined]));

    // Upload the record template to SharePoint
    const retVal: boolean = await apiService.UploadRecordTemplate({
        ...recordDocument
    });

    // Update the state
    if (retVal) {
        dispatch(addSelectedTemplates({ name: recordDocument.name, url: "" }));
    } else {
        dispatch(setErrorMessages([RecordDocumentType.departmentWorkProduct, [`Failed to upload template: ${recordDocument.name}`]]));
    }

    dispatch(setComponentLoading([RecordDocumentType.departmentWorkProduct, false]));
};

export const reorderDocumentAsync = (
    request: IReorderRecordDocumentRequest,
    recordType: RecordDocumentType
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([recordType, true]));

    const retVal: boolean = await apiService.ReorderRecordDocument({
        ...request
    });

    // Update the state
    if (retVal) {
        dispatch(setReorderDocuments(request.fileOrder));
    }
    dispatch(setComponentLoading([recordType, false]));

    return retVal;
};

export const getWizardResponseTemplateDataAsync = (
    selectedRecordSubTypes: string[]
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    let recordTypeTemplates: ITemplate[] = [];
    const requestsArray: Promise<ITemplate[] | null>[] = selectedRecordSubTypes
        .map(recortType => apiService.GetAvailableTemplates(recortType)
            .then(templates => recordTypeTemplates = [...recordTypeTemplates, ...templates || []]));
    await Promise.all(requestsArray);
    recordTypeTemplates = recordTypeTemplates
        .filter((value, index, selfArray) => selfArray.findIndex(t => t.name === value.name) === index);
    dispatch(setAvailableTemplates(recordTypeTemplates));
};

export const getRecordDocumentsAsync = (
    recordId: string,
    recordName: string
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    if (recordId) {
        const recordDocuments: IRecordDocuments | null = await apiService.GetRecordDocuments(
            recordId,
            recordName
        );
        dispatch(setRecordDocuments(recordDocuments));
        dispatch(setDocumentFetching(false));
    }
};

export const getSecurityClassification = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    const securityClassification: ITermSet | null = await apiService.GetSecurityClassificationTermSet();

    dispatch(setSecurityClassification(securityClassification));
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const userSelectedTemplates = (state: RootState): ITemplate[] =>
    state.manageRecordEditDocuments.selectedTemplates;

export const selectAvailableTemplates = (state: RootState): ITemplate[] =>
    state.manageRecordEditDocuments.availableTemplates;

export const userSelectedPrimaryFiles = (state: RootState): IFile[] =>
    state.manageRecordEditDocuments.selectedPrimaryFiles;

export const userSelectedSupportingFiles = (state: RootState): IFile[] =>
    state.manageRecordEditDocuments.selectedSupportingFiles;

export const userSelectedAttachments = (state: RootState): IFile[] =>
    state.manageRecordEditDocuments.selectedAttachments;

export const recordDocuments = (state: RootState): IRecordDocuments =>
    state.manageRecordEditDocuments.recordDocuments || { attachments: [], deptWorkProducts: [], signedAndApprovedProducts: [], snapshots: [], supportingDocuments: []};

export const securityClassification = (state: RootState): ITermSet | null => 
    state.manageRecordEditDocuments.securityClassification;

export const attachmentLoading = (state: RootState): boolean =>
    state.manageRecordEditDocuments.attachmentLoading;

export const supportingDocLoading = (state: RootState): boolean =>
    state.manageRecordEditDocuments.supportingDocLoading;

export const templateLoading = (state: RootState): boolean =>
    state.manageRecordEditDocuments.templateLoading;

export const attachmentUploadSuccess = (state: RootState): boolean =>
    state.manageRecordEditDocuments.attachmentUploadSuccess;

export const supportingDocUplaodSuccess = (state: RootState): boolean =>
    state.manageRecordEditDocuments.supportingDocUplaodSuccess;

export const selectAttachmentDocErrorMessages = (state: RootState): string[] | undefined =>
    state.manageRecordEditDocuments.attachmentDocErrorMessages;

export const selectSupportingDocErrorMessages = (state: RootState): string[] | undefined =>
    state.manageRecordEditDocuments.supportingDocErrorMessages;

export const selectTemplateDocErrorMessages = (state: RootState): string[] | undefined =>
    state.manageRecordEditDocuments.templateDocErrorMessages;

export const primaryDocLoading = (state: RootState): boolean =>
    state.manageRecordEditDocuments.primaryDocLoading;

export const primaryDocUplaodSuccess = (state: RootState): boolean =>
    state.manageRecordEditDocuments.primaryDocUplaodSuccess;    

export const selectPrimaryDocErrorMessages = (state: RootState): string[] | undefined =>
    state.manageRecordEditDocuments.primaryDocErrorMessages;    

// 6. EXPORT REDUCER
export default manageRecordEditDocumentsSlice.reducer;
