// import {
//     uniq
// } from "@microsoft/sp-lodash-subset";
// import User from "../data/User/User";
// import Term from "../data/Term/Term";
// import {
//     Group
// } from "../data/Group/Group";

export default class SPHelper {
    private static maxFilenameLength: number = 128;

    // public static GetProfilePicUrl(webUrl: string, user: User): string {
    //     const loginSplit: string[] = user.loginName.split("|");
    //     const loginEmail: string = loginSplit[loginSplit.length - 1];
    //     return `${webUrl}/_layouts/15/userphoto.aspx?size=M&accountname=${loginEmail}`;
    // }

    public static GetTextToSet(text: string | undefined): string | undefined {
        const textFieldMaxLength: number = 254;
        return text === undefined ? undefined : text.substr(0, textFieldMaxLength);
    }

    // public static GetTermsToSet(terms: Term[] | null | undefined): string | null | undefined {
    //     return terms === undefined
    //         ? undefined
    //         : terms
    //         ? terms.map(t => `${t.name}|${t.id}`).join(";")
    //         : null;
    // }

    public static GetNoteToSet(
        text: string | undefined,
        unlimitedLengthInDocumentLibrary?: boolean
    ): string | undefined {
        const noteFieldMaxLength: number = unlimitedLengthInDocumentLibrary ? 64999 : 3999;
        return text === undefined ? undefined : text.substr(0, noteFieldMaxLength);
    }

    public static GetDateToSet(date: Date | null | undefined): string | null | undefined {
        return date === undefined ? undefined : date ? date.toISOString() : null;
    }

    public static GetBooleanToSet(bool: boolean | undefined): boolean | undefined {
        return bool === undefined ? undefined : bool ? true : false;
    }

    // public static GetUserOrGroupToSet(
    //     user: User | Group | null | undefined
    // ): number | null | undefined {
    //     return user === undefined ? undefined : !!user && user.id >= 0 ? user.id : null;
    // }

    // public static GetUsersOrGroupsToSet(
    //     users: Array<User | Group> | User | Group | null | undefined
    // ): { results: number[] } | undefined {
    //     if (users === undefined) {
    //         return undefined;
    //     }

    //     let ids: number[] = [];

    //     if (users !== null) {
    //         let usersArray: Array<User | Group>;
    //         if (!(users instanceof Array)) {
    //             usersArray = [users];
    //         } else {
    //             usersArray = users;
    //         }
    //         ids = usersArray.map(u => u.id);
    //         ids = uniq(ids);
    //     }

    //     return {
    //         results: ids
    //     };
    // }

    public static GetSafeFilename(
        filenameIncExt: string | null,
        maxFilenameLength?: number
    ): string {
        let safeFilename: string = (filenameIncExt || "no_filename").replace(/([^\w.])+/gi, "_");
        const maxLengthToUse: number = maxFilenameLength
            ? maxFilenameLength
            : SPHelper.maxFilenameLength;
        if (safeFilename.length > maxLengthToUse) {
            const indexOfDot: number = safeFilename.lastIndexOf(".");
            if (indexOfDot >= 0) {
                const fileExtIncDot: string = safeFilename.substr(indexOfDot);
                safeFilename =
                    safeFilename.substr(0, maxLengthToUse - fileExtIncDot.length) + fileExtIncDot;
            } else {
                safeFilename = safeFilename.substr(0, maxLengthToUse);
            }
        }
        return safeFilename;
    }

    // public static GetOfficeUrlPreferencingOpeningInDesktopApplication(
    //     absolutefileUrl: string
    // ): string {
    //     // ms-word:ofe|u|https://tenant.sharepoint.com/sites/site/library/folder/filename.docx
    //     // ms-excel:ofe|ofc|u|https://tenant.sharepoint.com/sites/site/library/folder/filename.xlsx
    //     // ms-powerpoint:ofe|u|https://tenant.sharepoint.com/sites/site/library/folder/filename.pptx

    //     const wordProtocolPrefix: string = "ms-word:ofe|u|";
    //     const excelProtocolPrefix: string = "ms-excel:ofe|ofc|u|";
    //     const powerpointProtocolPrefix: string = "ms-powerpoint:ofe|u|";

    //     let url: string = "";
    //     if (!!absolutefileUrl) {
    //         absolutefileUrl = absolutefileUrl.toLowerCase();
    //         if (absolutefileUrl.endsWith(".docx") || absolutefileUrl.endsWith(".doc")) {
    //             url = wordProtocolPrefix + encodeURI(absolutefileUrl);
    //         } else if (absolutefileUrl.endsWith(".xlsx") || absolutefileUrl.endsWith(".xls")) {
    //             url = excelProtocolPrefix + encodeURI(absolutefileUrl);
    //         } else if (absolutefileUrl.endsWith(".pptx") || absolutefileUrl.endsWith(".ppt")) {
    //             url = powerpointProtocolPrefix + encodeURI(absolutefileUrl);
    //         } else {
    //             url = this.GetOfficeOnlineUrl(absolutefileUrl);
    //         }
    //     }
    //     url = this.encodeHashes(url);
    //     return url;
    // }

    // public static GetOfficeOnlineUrl(fileUrl: string): string {
    //     fileUrl = encodeURI(fileUrl);
    //     fileUrl = this.encodeHashes(fileUrl);
    //     return fileUrl.indexOf("?") >= 0 ? `${fileUrl}&web=1` : `${fileUrl}?web=1`;
    // }

    // // public static GetDownloadUrl(fileUrl: string): string {
    // //   fileUrl = encodeURI(fileUrl);
    // //   return fileUrl.indexOf("?") >= 0 ? `${fileUrl}&web=0` : `${fileUrl}?web=0`;
    // // }

    // private static encodeHashes(url: string): string {
    //     return url.replace(/#/g, "%23");
    // }
}
