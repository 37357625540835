export const mp_RecordId: string = "RefinableABCRecordId";
export const mp_Title: string = "RefinableTitle";
export const mp_Stage: string = "RefinableABCStage";
export const mp_Modified: string = "RefinableABCModified";
export const mp_RequestFrom: string = "RefinableABCRequestFrom";
export const mp_RecordType: string = "RefinableContentType";

export const mp_DueDateCurrentTask: string = "RefinableABCDueDateCurrentTask";
export const mp_DueDateRecommender: string = "RefinableABCDueDateRecommender";
export const mp_DueDateDecisionMaker: string = "RefinableABCDueDateDecisionMaker";

