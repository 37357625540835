/* eslint-disable no-console */
import React, { useState } from "react";
import styles from "./TabsController.module.scss";
import CollapsibleContainer from "../CollapsibleContainer/CollapsibleContainer";
import { useHistory } from "react-router-dom";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import { SCROLL_KEYS } from "../../../helpers/ScrollHelper";

export interface ITab {
    key: string;
    name: string;
    content: JSX.Element
}

interface ITabsControllerProps {
    tabs: ITab[];
    onClick?: (tab: ITab | undefined) => void;
    defaultTabKey?: string;
    mobileView: boolean;
    removeTabLine?: boolean;
}

let historyListener: { remove: () => void} | undefined;
export default function TabsController({ tabs, defaultTabKey, mobileView, onClick, removeTabLine }: ITabsControllerProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const tabKey: string | null = new URLSearchParams(history.location.search).get(URL_QUERY_KEYS.TAB);
    const [currentTabKey, setCurrentTabKey] = useState<string | undefined>(tabKey || defaultTabKey || tabs[0].key);

    React.useEffect(() => {
        if(historyListener){ historyListener.remove(); }
        const remove: () => void = history.listen((location) => {
            const key: string | null = new URLSearchParams(location.search).get(URL_QUERY_KEYS.TAB);
            if (key && currentTabKey != key) { 
                setCurrentTabKey(key); 
            }
        });
        historyListener = {remove};
    },[history, currentTabKey, setCurrentTabKey]);
    
    const content: JSX.Element | undefined = tabs.find(t => t.key === currentTabKey)?.content;
    return (
        <div className={styles.tabsController} id={SCROLL_KEYS.TABS}>
            {!mobileView && (
                <>
                    <div className={styles.tabsContainer}>
                        {tabs.map(tab => (
                            <div
                                className={`${styles.tab} 
                                    ${currentTabKey === tab.key && styles.activeTab}`}
                                key={tab.key}
                                onClick={() => {
                                    setCurrentTabKey(tab?.key);
                                    
                                    history.push({ search: UrlHelper.castUrlWithQueryStringParam(tab?.key, URL_QUERY_KEYS.TAB, history.location.search) });
                                    if(onClick){ onClick(tab); }
                                }}
                            >
                                {tab.name}
                            </div>
                        ))}
                    </div>
                    <div className={styles.childrenContainer}>
                        <div className={styles.children} style={removeTabLine ? {borderTop: "none"} : {}}>{content}</div>
                    </div>
                </>
            )}
            {mobileView && (
                <>
                    {tabs.map(tab => (
                        <CollapsibleContainer
                            key={tab.key}
                            title={tab.name}
                            expanded={currentTabKey === tab.key}
                            onExpandClick={(expanded: boolean) => {
                                if(expanded){
                                    setCurrentTabKey(tab?.key);                                    
                                    history.push({ search: UrlHelper.castUrlWithQueryStringParam(tab?.key, URL_QUERY_KEYS.TAB, history.location.search) });
                                }
                                if(onClick){ onClick(expanded ? tab : undefined); }
                            }}
                            highlightContentBg={true}
                        >
                            {currentTabKey === tab.key && <>{content}</>}
                        </CollapsibleContainer>
                    ))}
                </>
            )}
        </div>
    );
}
