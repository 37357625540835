import * as React from "react";
import {
    Dropdown,
    IDropdown,
    IDropdownOption
} from "@fluentui/react";
import styles from "./Select.module.scss";
import {
    ControlContainer
} from "../ControlContainer/ControlContainer";
import {
    defaultLoadingString
} from "../../../providers/Constants/AppConstants";

export interface ISelectProps {
    name: string;
    required: boolean;
    options: ISelectOptionProps[];
    forceFullRerenderOnNewOptions?: boolean;
    defaultSelectedKey?: string | string[];
    selectedKey?: string | string[];
    suppressNameLabel?: boolean;
    overridePlaceholder?: string;
    getOptions?(): Promise<ISelectOptionProps[]>;
    onChange?(
        option: ISelectOptionProps | undefined,
        index?: number | string
    ): Promise<ISelectOptionProps | undefined> | void | false;
    isMultiSelect?: boolean;
    description?: string;
    disabled?: boolean;
    showAsLoading?: boolean;
    className?: string;
    hideDisabledOptions?: boolean;
}

export interface ISelectState {
    options: ISelectOptionProps[];
    isLoading: boolean;
}

export interface ISelectOptionProps {
    key: string;
    text: string;
    imgUrl?: string;
    initials?: string;
    isSelected?: boolean;
    disabled?: boolean;
}

export interface IReactSelectOption {
    value: string;
    label: string;
}

export default class Select extends React.Component<ISelectProps, ISelectState> {
    private ddComponentRef: IDropdown | null = null;
    // private ssComponentRef: SearchableSelect | null = null;

    constructor(props: ISelectProps) {
        super(props);
        this.state = {
            options: this.props.getOptions ? [] : this.props.options,
            isLoading: !!this.props.getOptions || !!this.props.showAsLoading
        };
    }

    public getValue(): ISelectOptionProps[] | null {
        let selectValue: ISelectOptionProps[] = [];
        if (this.ddComponentRef) {
            const values: IDropdownOption[] =
                this.ddComponentRef.selectedOptions instanceof Array
                    ? this.ddComponentRef.selectedOptions
                    : [this.ddComponentRef.selectedOptions];
            selectValue = values.map((o: IDropdownOption) => {
                return {
                    key: `${o.key}`,
                    text: o.text
                };
            });
            // } else if (!!this.ssComponentRef) {
            //     selectValue = this.ssComponentRef.getValue() || [];
        }
        return selectValue.length < 1 ? null : selectValue;
    }

    public render(): React.ReactElement<ISelectProps> {
        const placeholder: string = this.props.overridePlaceholder
            ? this.props.overridePlaceholder
            : `Select ${this.props.name?.toLowerCase()}`;
        let selectedKeyFromOptions: string[] | undefined = undefined;
        if (this.state.options) {
            const selectedOptions: ISelectOptionProps[] = this.state.options.filter(
                o => !!o.isSelected
            );
            if (selectedOptions.length > 0) {
                selectedKeyFromOptions = selectedOptions.map(o => o.key);
            }
        }
        const defaultValue: string | string[] | undefined =
            selectedKeyFromOptions || this.props.defaultSelectedKey;
        // const onChange = this.props.onChange;
        return (
            <ControlContainer className={this.props.className}>
                <>
                    <Dropdown
                        defaultSelectedKey={this.props.isMultiSelect ? undefined : defaultValue}
                        defaultSelectedKeys={
                            !this.props.isMultiSelect
                                ? undefined
                                : selectedKeyFromOptions ||
                                      (typeof this.props.defaultSelectedKey === "string"
                                          ? [this.props.defaultSelectedKey]
                                          : this.props.defaultSelectedKey)
                        }
                        selectedKey={
                            this.props.isMultiSelect
                                ? undefined
                                : (this.props.selectedKey as string)
                        }
                        selectedKeys={
                            !this.props.isMultiSelect
                                ? undefined
                                : (this.props.selectedKey as string[])
                        }
                        placeholder={this.state.isLoading ? defaultLoadingString : placeholder}
                        label={!this.props.suppressNameLabel ? `${this.props.name}:` : ""}
                        componentRef={r => (this.ddComponentRef = r)}
                        className={styles.select}
                        calloutProps={ this.props.hideDisabledOptions ? { className: styles.hideDisabledOption } : undefined }
                        ariaLabel={`${this.props.name} dropdown control`}
                        options={this.state.options}
                        required={this.props.required}
                        onChange={(e, option, index) => {
                            if (this.props.onChange) {
                                const o: ISelectOptionProps | undefined =
                                        index !== undefined ? this.state.options[index] : undefined;
                                if (this.props.selectedKey && o?.key && o?.key === this.props.selectedKey) {
                                    //skip onChange
                                } else {
                                    this.props.onChange(o, index);
                                }
                            }
                        }}
                        multiSelect={this.props.isMultiSelect}
                        disabled={this.props.disabled}
                    />
                    {!!this.props.description && (
                        <div className={styles.description}>{this.props.description}</div>
                    )}
                </>
            </ControlContainer>
        );
    }

    public async UNSAFE_componentWillReceiveProps(nextProps: ISelectProps): Promise<void> {
        if (nextProps.showAsLoading != this.state.isLoading) {
            this.setState({
                isLoading: !!nextProps.getOptions || !!nextProps.showAsLoading
            });
        }

        if (!this.props.getOptions) {
            let hasOptionsChanged: boolean = false;
            if (this.props.options.length !== nextProps.options.length) {
                hasOptionsChanged = true;
            } else {
                for (let i: number = 0; i < this.props.options.length; i++) {
                    if (this.props.options[i].key !== nextProps.options[i].key ||
                        this.props.options[i].disabled !== nextProps.options[i].disabled) {
                        hasOptionsChanged = true;
                        break;
                    }
                }
            }
            if (hasOptionsChanged) {
                this.setState({
                    options: nextProps.options
                });

                //issue with component - selectedIndices is not empty, when new options are passed
                //so errors occurs when selectedIndices are out of length of options
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((this.ddComponentRef as any).state.selectedIndices) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (this.ddComponentRef as any).setState({ selectedIndices: [] });
                }
            }
        }
    }

    public async componentDidMount(): Promise<void> {
        if (this.props.getOptions) {
            const options: ISelectOptionProps[] = await this.props.getOptions();
            this.setState({
                options: options,
                isLoading: false
            });
        }
    }
}
