import { useSelector } from "react-redux";
import React from "react";
import styles from "./WelcomeUser.module.scss";
import { IUser } from "../../services/Api/executor/IApiServiceExecutor";
import { selectCurrentUser } from "../../app/globalSlices/contextSlice";

export const userRoles: (user: IUser | null) => string = (user: IUser | null): string => {
    if (user) {
        const userRolesData: Array<string> = [];
        if (user.isDLO) {
            userRolesData.push("DLO");
        }
        if (user.isDepSec) {
            userRolesData.push("Deputy Secretary");
        }
        if (user.isSecretary) {
            userRolesData.push("Secretary");
        }
        return userRolesData.join(", ");
    } else {
        return "";
    }
};

export function WelcomeUser(): JSX.Element {
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);

    const getUserParams: (paramKeys: string[]) => string[] | undefined = React.useCallback(
        (paramsKeys: Array<string>): string[] | undefined => {
            const userParams: Array<string> = [];
            if (currentUser) {
                Object.entries(currentUser).forEach(([key, value]) => {
                    if (paramsKeys.some(levelItem => levelItem === key) && !!value) {
                        userParams.push(value);
                    }
                });
                return userParams;
            } else {
                return undefined;
            }
        },
        [currentUser]
    );

    const userOrgLevels: string = React.useMemo(() => {
        if (currentUser) {
            const userLevelsKeys: Array<string> = Object.keys(currentUser).filter(
                keyItem =>
                    keyItem === "orgLevel2" || keyItem === "orgLevel3" || keyItem === "orgLevel4"
            );
            const userLevels: string[] | undefined = getUserParams(userLevelsKeys);
            return userLevels?.length
                ? userLevels.length > 1
                    ? userLevels.join(", ")
                    : userLevels.join("")
                : "";
        } else {
            return "";
        }
    }, [currentUser, getUserParams]);

    return (
        <>
            {currentUser === undefined && <div>Loading user...</div>}
            {currentUser === null && <div>Failed to load user!.</div>}
            {!!currentUser && (
                <div className={`${styles.pageInfoBarContainer} ${styles.bgWhite}`}>
                    <div className={styles.userInfoContainer}>
                        <div className={styles.titleContainer}>
                            <h1 className={styles.title}>Dashboard</h1>
                        </div>
                        {currentUser?.displayName && (
                            <div className={styles.displayNameUserContainer}>
                                <h2 className={styles.welcomeMessage}>
                                    Welcome <span>{currentUser?.displayName}</span>
                                </h2>
                            </div>
                        )}
                        {!!userOrgLevels && (
                            <p className={styles.userLevels}>
                                {currentUser?.jobTitle ? currentUser.jobTitle + ", " : ""}
                                {userOrgLevels}
                            </p>
                        )}
                        {!!userRoles(currentUser) && (
                            <p className={styles.userRoles}>
                                <span>Open record access: </span>
                                {userRoles(currentUser)}
                            </p>
                        )}
                        {!!currentUser?.myProxies && currentUser.myProxies.length > 0 && (
                            <p className={styles.proxies}>
                                <span>My Proxies: </span>
                                {currentUser.myProxies.map(p => p.displayName).join(", ")}
                            </p>
                        )}
                        {!!currentUser?.proxyFor && currentUser.proxyFor.length > 0 && (
                            <p className={styles.proxies}>
                                <span>Proxy For: </span>
                                {currentUser.proxyFor.map(p => p.displayName).join(", ")}
                            </p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
