import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { breadcrumbDocumentGeneration } from "../../providers/Constants/RecordWizardConstants";
import styles from "./DocumentGenerationPage.module.scss";
import { selectIsMobile } from "../../app/globalSlices/contextSlice";
import { DocumentGenerationPageConstants } from "../../providers/Constants/DocumentGenerationConstants";
import React from "react";
import { DefaultButton, Label, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import {
    IDocumentSearchResponse,
    IDocumentGenerationFile,
    IOpenAIDocumentGenerationResponse,
    RecordDocumentType,
    IRecord,
    ITemplate
} from "../../services/Api/executor/IApiServiceExecutor";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import MDEditor from "@uiw/react-md-editor";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

export function DocumentGenerationPage(): JSX.Element {
    const isMobile: boolean = useSelector(selectIsMobile);

    // Setup and use Translation - required for the file upload dialog
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    React.useEffect(() => {}, [dispatch, t]);

    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();

    const [pageSteps] = React.useState<string[]>([DocumentGenerationPageConstants.pageStepTemplateSelection, DocumentGenerationPageConstants.pageStepQueryEntry, DocumentGenerationPageConstants.pageStepDocumentSelection, DocumentGenerationPageConstants.pageStepDocumentGeneration]);
    const [currentPage, setCurrentPage] = React.useState<string>(DocumentGenerationPageConstants.pageStepTemplateSelection);

    const { recordId } = useParams<{ recordId: string }>();
    const [ record, setRecord ] = React.useState<IRecord | null>(null);
    const [ recordTemplateTypes, setRecordTemplateTypes] = React.useState<ITemplate[]>([]);
    const [ selectedTemplateUrl, setSelectedTemplateUrl] = React.useState<string>("");

    const [documentSearchQuery, setDocumentSearchQuery] = React.useState<string>("");
    const [documentSearchResults, setDocumentSearchResults] = React.useState<
        IDocumentSearchResponse[]
    >([]);
    const [selectedDocuments, setSelectedDocuments] = React.useState<[string, string][]>([]);

    const [isLoadingDoc, setIsLoadingDoc] = React.useState<boolean>(false);
    const [generatedMarkdownDoc, setGeneratedMarkdownDoc] = React.useState<string>("");
    const [generatedSharepointDocumentLink, setGeneratedSharepointDocumentLink] =
        React.useState<string>("");
    const [generatedSharepointDocumentId, setGeneratedSharepointDocumentId] =
        React.useState<string>("");
    const [generatedSharepointDocumentName, setGeneratedSharepointDocumentName] =
        React.useState<string>("");

    const [selectedDocType] = React.useState<string>("wordfile");

    const [referencedFiles, setReferencedFiles] = React.useState<IDocumentGenerationFile[]>([]);

    const [documentTemplateFileName, setDocumentTemplateFileName] = React.useState<string>("");
    const [documentTemplateFileContentBase64] =
        React.useState<string>("");

        
    React.useEffect( () => { 
        async function loadRecordAndTemplates() {
            const resolver: DependencyResolver = new DependencyResolver();
            const apiService: IApiService = resolver.ResolveIApiService();

            // get record for its name and type
            const loadedRecord: IRecord | null = await apiService.GetRecord(recordId, false);
            setRecord(loadedRecord);

            let recordTypeTemplates: ITemplate[] = [];
            const requestsArray: Promise<ITemplate[] | null>[] = [loadedRecord?.recordType ?? ""]
                .map((recordType: string) => apiService.GetAvailableTemplates(recordType)
                    .then(templates => recordTypeTemplates = [...recordTypeTemplates, ...templates || []]));
            await Promise.all(requestsArray);
            recordTypeTemplates = recordTypeTemplates
                .filter((value, index, selfArray) => selfArray.findIndex(t => t.name === value.name) === index);

            setRecordTemplateTypes(recordTypeTemplates);
        }
        loadRecordAndTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchDocuments = async () => {
        setSelectedDocuments([]);
        setGeneratedMarkdownDoc("");
        setIsLoadingDoc(true);
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const docResults: IDocumentSearchResponse[] =
            (await apiService.SearchDocuments(documentSearchQuery)) ?? [];
        setIsLoadingDoc(false);
        setDocumentSearchResults(docResults ?? []);
    };

    const updateSelectedDocuments = (file: [string, string], isAdding: boolean) => {
        if (isAdding) {
            setSelectedDocuments([...selectedDocuments, file]);
        } else {
            setSelectedDocuments(selectedDocuments.filter(value => value[1] !== file[1]));
        }
    };

    const generateDocument = async () => {
        setIsLoadingDoc(true);
        setGeneratedMarkdownDoc("");
        setReferencedFiles([]);

        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const files: IDocumentGenerationFile[] = [];
        selectedDocuments.map(function (doc: [string, string]) {
            files.push({
                fileName: doc[0],
                fileSharepointWebUri: doc[1]
            });
        });

        const genResult: IOpenAIDocumentGenerationResponse | null =
            await apiService.GenerateDocumentWithAI({
                documentType: selectedDocType,
                embedFileContent: true,
                rawOutput: false,
                useInternalSearch: false,
                useAssistantApi: false,
                queryQuestion: {
                    question: documentSearchQuery
                },
                extractPdfTextContent: true,
                files: files,
                responseDocumentTemplateFileContentBase64: documentTemplateFileContentBase64,
                responseDocumentTemplateFileName: documentTemplateFileName,
                responseDocumentTemplateSharepointUrl: selectedTemplateUrl
            });

        setIsLoadingDoc(false);
        if (genResult?.generatedFileSharepointLinkingUrl || genResult?.generatedFileSharepointPath) {
            setGeneratedSharepointDocumentLink(genResult?.generatedFileSharepointLinkingUrl ?? genResult?.generatedFileSharepointPath);
            setGeneratedSharepointDocumentId(genResult?.generatedFileSharepointDocumentId);
            setGeneratedSharepointDocumentName(genResult?.generatedFileSharepointFileName);
        } else {
            setGeneratedMarkdownDoc(genResult?.answer ?? "");
        }

        setReferencedFiles(genResult?.referencedFiles ?? []);
    };

    const pageNext = async () => {

        if (currentPage == DocumentGenerationPageConstants.pageStepTemplateSelection)
        {
            if (!selectedTemplateUrl) {
                return;
            }
        }

        if (currentPage == DocumentGenerationPageConstants.pageStepQueryEntry)
        {
            if (documentSearchQuery)
            {
                searchDocuments();
            } 
            else {
                return;
            }
        }

        if (currentPage == DocumentGenerationPageConstants.pageStepDocumentSelection)
        {
            if (selectedDocuments.length > 0)
            {
                generateDocument();
            } else {
                return;
            }
        }

        const pageIndex : number = pageSteps.indexOf(currentPage);
        if (pageIndex < (pageSteps.length - 1))
        {
            setCurrentPage(pageSteps[pageIndex + 1]);
        }

    };

    const pageBack = async () => {
        const pageIndex : number = pageSteps.indexOf(currentPage);
        if (pageIndex > 0)
        {
            setCurrentPage(pageSteps[pageIndex - 1]);
        }
    };

    const attachDocumentToRecord = async () => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        setIsLoadingDoc(true);

        await apiService.UploadRecordDocument({recordId: recordId, 
            type: RecordDocumentType.departmentWorkProduct, 
            name: generatedSharepointDocumentName,
            recordName: record?.name ?? recordId, copyFromSharepointPersonalFileId: generatedSharepointDocumentId
        });

        history.push("/record/" + recordId + "?tabKey=documents");
    };

    const availableTemplates: ISelectOptionProps[] = recordTemplateTypes.map(tmpl => ({
        key: String(tmpl.url),
        text: tmpl.name
    }));
    
    const getCircleColor = (pageNames: string[]): string => {
        return pageNames.includes(currentPage) ? "rgb(0, 82, 194)" : "#ccc";
    };

    return (
        <>
            <Breadcumbs pageName={breadcrumbDocumentGeneration} />
            <div className={styles.documentGenerationPage}>
                <div
                    className={`${styles.pageContainer} 
                    ${isMobile ? styles.mobilePageContainer : ""}`}
                >
                    <h1 className={styles.pageHeader}>{DocumentGenerationPageConstants.header} - {recordId}</h1>

                    <div className={styles.wizardContainer} >
                        <div className={styles.containerStyle}>
                            <div className={styles.circleStyle} style={{ backgroundColor: getCircleColor([DocumentGenerationPageConstants.pageStepTemplateSelection]) }}>1</div>
                            <div className={styles.lineStyle}></div>
                            <div className={styles.circleStyle} style={{ backgroundColor: getCircleColor([DocumentGenerationPageConstants.pageStepQueryEntry, DocumentGenerationPageConstants.pageStepDocumentSelection]) }}>2</div>
                            <div className={styles.lineStyle}></div>
                            <div className={styles.circleStyle} style={{ backgroundColor: getCircleColor([DocumentGenerationPageConstants.pageStepDocumentGeneration]) }}>3</div>
                        </div>

                        {isLoadingDoc && (
                            <Spinner
                                className={styles.spinner}
                                size={SpinnerSize.large}
                                label="Loading..."
                            />
                        )}

                        {currentPage == DocumentGenerationPageConstants.pageStepTemplateSelection && 
                    <>
                        <div className={styles.wizardStepContainer}>
                           

                            {selectedDocType == "wordfile" && (
                                <>

                                    <Select
                                        name={t("fieldTemplateSubHeader")}
                                        options={availableTemplates}
                                        required={true}
                                        onChange={option => {
                                            if (option) {
                                                setSelectedTemplateUrl(option.key);
                                                setDocumentTemplateFileName(option.key.split("/").pop() ?? "");
                                            }
                                        }}
                                        className={styles.selectContainer}
                                    />
                                </>
                            )}
                        </div>
                    </>
                        }

                        {currentPage == DocumentGenerationPageConstants.pageStepQueryEntry && 
                    <>
                        <div className={styles.wizardStepContainer}>
                           

                            <TextField
                                label="Describe the content you want"
                                required={true}
                                value={documentSearchQuery}
                                multiline
                                onChange={(el, value) => setDocumentSearchQuery(value ?? "")}
                            ></TextField>
                            <br />
                            {/* <PrimaryButton name="Search" onClick={() => searchDocuments()}>
                        Search for relevant documents manually
                            </PrimaryButton>
                            <span>&nbsp;or&nbsp;</span>
                            <PrimaryButton name="Search" onClick={() => generateDocumentWithAssistant()}>
                        Automatically find relevant documents
                            </PrimaryButton> */}
                        </div>
                    </>
                        }

                        {currentPage == DocumentGenerationPageConstants.pageStepDocumentSelection && 
                    <>
                        <div className={styles.wizardStepContainer}>
                            

                            {!isLoadingDoc && (
                                <>
                                    {documentSearchResults.length > 0 && (
                                        <Label required={false}>Select relevant documents and then click Next.</Label>
                                    )}
                                    {/* {documentSearchResults.length > 0 && (
                                        <PrimaryButton
                                            disabled={selectedDocuments.length == 0}
                                            onClick={() => generateDocument()}
                                        >
                                    Generate document
                                        </PrimaryButton>
                                    )} */}
                                    {documentSearchResults.map((doc, index) => (
                                        <div key={doc.fileName + index + ""}>
                                           
                                            <Checkbox
                                                name=""
                                                required={false}
                                                className={styles.inlineCheckbox}
                                                checked={
                                                    selectedDocuments.findIndex(
                                                        value => value[1] == doc.fileSharepointWebUri
                                                    ) > -1
                                                }
                                                onChange={() =>
                                                    updateSelectedDocuments(
                                                        [doc.fileName, doc.fileSharepointWebUri],
                                                        selectedDocuments.findIndex(
                                                            value => value[1] == doc.fileSharepointWebUri
                                                        ) < 0
                                                    )
                                                }
                                            ></Checkbox>
                                            <a target="_blank" href={doc.fileSharepointWebUri}>
                                                <h3>{doc.fileName}</h3>
                                            </a>
                                            {/* highlights will include html tags to render */}
                                            {/* {doc.highlights.map((highlight, index) => (
                                                <p
                                                    key={index}
                                                    dangerouslySetInnerHTML={{ __html: highlight }}
                                                ></p>
                                            ))} */}
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </>
                        }

                        {currentPage == DocumentGenerationPageConstants.pageStepDocumentGeneration && 
                    <>
                        <div className={styles.wizardStepContainer}>
                           

                        
                            {!isLoadingDoc && generatedMarkdownDoc && (
                                <>
                                    <div className={styles.generatedDocContainer} data-color-mode="light">
                                        <MDEditor.Markdown source={generatedMarkdownDoc} />
                                    </div>
                                </>
                            )}
                            {!isLoadingDoc && generatedSharepointDocumentLink && (
                                <>
                                    <h3>Your generated document is ready:</h3>
                                    <p><a href={generatedSharepointDocumentLink} target="_blank">{generatedSharepointDocumentLink}</a></p>
                                    <p>Review and edit the document in your Personal Storage, then click the button below to attach it to the record.</p>
                                    <PrimaryButton onClick={() => attachDocumentToRecord()}>{"Attach to record " + recordId}</PrimaryButton>
                                </>
                            )}
                            {!isLoadingDoc && referencedFiles && referencedFiles.length > 0 && (
                                <>
                                    {" "}
                                    <h3>Files referenced</h3>
                                    {referencedFiles.map(file => (
                                        <a target="_blank" href={file.fileSharepointWebUri}>
                                            <p>{file.fileName}</p>
                                        </a>
                                    ))}
                                </>
                            )}
                        </div>
                    </>
                        }

                    </div>
                    <div className={styles.wizardButtons}>
                        {pageSteps.indexOf(currentPage) != (0) && 
                        <DefaultButton name="Back" onClick={() => pageBack()}>
                        Back
                        </DefaultButton>
                        }
                        <span>&nbsp;</span>
                        {pageSteps.indexOf(currentPage) != (pageSteps.length - 1) && 
                        <PrimaryButton name="Next" className={styles.nextButton} onClick={() => pageNext()}>
                        Next
                        </PrimaryButton>
                        }
                    </div>

                    {/* <Select
                        name="Document type"
                        required={true}
                        options={availableDocTypes.map((doc: [string, string]) => {
                            return {
                                text: doc[0],
                                key: doc[1]
                            };
                        })}
                        selectedKey={selectedDocType}
                        onChange={(opt: ISelectOptionProps | undefined) => {
                            setSelectedDocType(opt?.key || "");
                        }}
                    /> */}

                    
                </div>
            </div>
        </>
    );
}
