// 1. Imports
import React from "react";
import {  DefaultButton, Spinner, TextField } from "@fluentui/react";
import styles from "./RecordSchemaExportPage.module.scss";

import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { selectCurrentUser, setAppTitle } from "../../app/globalSlices/contextSlice";
import { IUser } from "../../services/Api/executor/IApiServiceExecutor";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";



// 2. DEFINE PROPS
export interface IRecordSchemaExportPageProps {
    children?: JSX.Element | JSX.Element[];
}

export function RecordSchemaExportPage(): JSX.Element {

    // GET DISPATCH
    // Disabling eslint for one line to make use of any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //const dispatch: Dispatch<any> = useDispatch();

    // DEFINE COMPONENT STATE
    // 2. SELECT CURRENT GLOBAL STATE
    const {t} = useTranslation();
    
    // 3. DEFINE COMPONENT STATE
    const [recordTypesSchema, setRecordTypesSchema] = React.useState<string>("");
    const [recordTypeFieldsSchema, setRecordTypeFieldsSchema] = React.useState<string>("");
    const [schemaLoading, setSchemaLoading] = React.useState<boolean>(false);


    const onLoadSchemaClick: () => Promise<void> = React.useCallback(async () => {
        setRecordTypesSchema("");
        setSchemaLoading(true);
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const ctSchema: string  = await apiService.GetContentTypesSchema();
        const fieldsSchema: string  = await apiService.GetContentTypesFieldsSchema();
        setRecordTypesSchema(ctSchema);
        setRecordTypeFieldsSchema(fieldsSchema);
        setSchemaLoading(false);
    }, []);

    setAppTitle(t("settingsPageTitle"));

    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const isAdmin: boolean = currentUser?.iIsAnyOrgAdmin || false;

    return isAdmin ? (
        <>
            <Breadcumbs pageName={"Record schema export"} />
            <div className={styles.exportSchemaPage}>
                <div className={styles.pageContainer}>
                    <h1 className={styles.pageHeader}>{"Export record schema"}</h1>
                    <div className={styles.buttonContainer}>
                        <DefaultButton
                            className={styles.button}
                            iconProps={{ iconName: "AdminSLogoInverse32", className: styles.iconBlue }}
                            ariaLabel={"Load schema button"}
                            disabled={schemaLoading}
                            onClick={onLoadSchemaClick}
                        >
                            {"Load schema"}
                        </DefaultButton>
                    </div>
                    {schemaLoading && <Spinner/>}
                    <div className={styles.bodyContainer}>
                        <h3 className={styles.subHeading}>{"Record types schema"}</h3>
                        <TextField label="" disabled={schemaLoading} multiline rows={40} readOnly={true} value={recordTypesSchema} />
                    </div>
                    <div className={styles.bodyContainer}>
                        <h3 className={styles.subHeading}>{"Record fields schema"}</h3>
                        <TextField label="" disabled={schemaLoading} multiline rows={40} readOnly={true} value={recordTypeFieldsSchema} />
                    </div>
                </div>
            </div>
        </>)
        : (<>
            <div>
                Access denied. The page is available only for site collection administrators.
            </div>
        </>);
}
