import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { IWorkflowConfig } from "../../services/Api/executor/IApiServiceExecutor";

// FEATURE STATE
export interface IWorkflowAdminState {
    isLoading: boolean;
    json: string;
    isUpdating: boolean;
    error: string;
}

export const initialState: IWorkflowAdminState = {
    json: "",
    isLoading: false,
    isUpdating: false,
    error: ""
};

// 3. FEATURE SLICE

// eslint-disable-next-line @typescript-eslint/typedef
const WorkflowAdminPageSlice = createSlice({
    name: "WorkflowAdminPage",
    initialState,
    reducers: {
        setJson: (
            state: IWorkflowAdminState,
            action: PayloadAction<string>
        ) => {
            state.json = action.payload;
        },
        setIsLoading: (
            state: IWorkflowAdminState,
            action: PayloadAction<boolean>
        ) => {
            state.isLoading = action.payload;
        },
        setIsUpdating: (
            state: IWorkflowAdminState,
            action: PayloadAction<boolean>
        ) => {
            state.isUpdating = action.payload;
        },
        setError: (
            state: IWorkflowAdminState,
            action: PayloadAction<string>
        ) => {
            state.error = action.payload;
        }
    }
});

export const { setJson, setIsLoading, setError, setIsUpdating } = WorkflowAdminPageSlice.actions;

export const selectJson = (state: RootState): string => state.wfConfig.json;

export const selectIsLoading = (state: RootState): boolean => state.wfConfig.isLoading;

export const selectIsUpdating = (state: RootState): boolean => state.wfConfig.isUpdating;

export const selectError = (state: RootState): string => state.wfConfig.error;

export const loadWorkflowConfigAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setIsLoading(true));

    const retVal: string | null = await apiService.GetWorkflowConfig();
    
    dispatch(setIsLoading(false));
    if(retVal){
        dispatch(setJson(retVal));
    }
};

export const downloadServerConfig = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    dispatch(setIsLoading(true));
    const retVal: string | null = await apiService.GetWorkflowConfig();
    dispatch(setIsLoading(false));
    if(retVal){
        const element: HTMLAnchorElement = document.createElement("a");
        const file: Blob = new Blob([retVal], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "workflow-config.json";
        document.body.appendChild(element);
        element.click();
    }
};

export const setWorkflowConfig = (config: IWorkflowConfig): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    dispatch(setIsUpdating(true));
    const retVal: boolean = await apiService.SetWorkflowConfig(config);
    dispatch(setIsUpdating(false));
    if(!retVal){
        dispatch(setError("Error updating workflow config"));
    }
};

export default WorkflowAdminPageSlice.reducer;

