// 1. Imports
import React from "react";
import { PrimaryButton,  DefaultButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from "@fluentui/react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import styles from "./BulkUpdatePage.module.scss";
import {
    stateFilesLoading,
    deleteFileAsync,
    uploadAndValidateFilesAsync,
    loadBulkUploadStateAsync,
    startBulkUploadStateAsync,
    stateSelectedFile,
    stateFilesErrorMessage,
    stateBulkUpdateState,
    stateBulkUpdateStateLoading,
    stateSelectedHistoryLink
} from "./BulkUpdatePageSlice";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import {
    FormMode,
    IBulkUpdateStateResponse,
    IFile
} from "../../services/Api/executor/IApiServiceExecutor";

import FileUpload from "../../components/FormControls/FileUpload/FileUpload";
import { breadcrumbBulkUpdate } from "../../providers/Constants/RecordWizardConstants";
import { useTranslation } from "react-i18next";
import { setAppTitle } from "../../app/globalSlices/contextSlice";

// 2. DEFINE PROPS
export interface IBulkUpdatePageProps {
    children?: JSX.Element | JSX.Element[];
}

export function BulkUpdatePage(): JSX.Element {

    // GET DISPATCH
    // Disabling eslint for one line to make use of any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // DEFINE COMPONENT STATE
    // 2. SELECT CURRENT GLOBAL STATE
    const isFileLoading: boolean = useSelector(stateFilesLoading);
    const historyLink: string = useSelector(stateSelectedHistoryLink);
    const isUpdateStateLoading: boolean = useSelector(stateBulkUpdateStateLoading);
    const selectedFiles: IFile[] = useSelector(stateSelectedFile);
    const bulkUpdateState: IBulkUpdateStateResponse | null = useSelector(stateBulkUpdateState);
    const errorMessage: string | undefined = useSelector(stateFilesErrorMessage);
    const { t } = useTranslation();
    
    // 3. DEFINE COMPONENT STATE
    const [fileLoadingText, setFileLoadingText] = React.useState<string>("");
    const [fileUploaded, setFileUploaded] = React.useState<boolean>(false);
    const [refreshTimer, setRefreshTimer] = React.useState<number>();

    React.useEffect(()=>{
        dispatch(loadBulkUploadStateAsync());
        dispatch(setAppTitle(t("bulkUpdatePageTitle")));
    },[dispatch, t]);

    React.useEffect(()=>{
        setFileUploaded(!isFileLoading && selectedFiles.length===1);
    },[selectedFiles, isFileLoading]);

    React.useEffect(()=>{
        //set refresh progress timer if there is update in progress and timer is not defined
        if(bulkUpdateState?.totalRecords && bulkUpdateState?.totalRecords > 0 && !refreshTimer){
            const timer: number = window.setTimeout(()=>{
                dispatch(loadBulkUploadStateAsync());
                setRefreshTimer(undefined);
            }, 30*1000);
            setRefreshTimer(timer);
        }
        //reset refresh progress timer if update is not in progress
        if(!bulkUpdateState?.totalRecords && !!refreshTimer){
            window.clearTimeout(refreshTimer);
            setRefreshTimer(undefined);
        }
    },[refreshTimer, bulkUpdateState?.totalRecords, dispatch]);

    function uploadFile(files: IFile[]): void {
        setFileLoadingText("Uploading and validating file");
        dispatch(uploadAndValidateFilesAsync(files));
    }

    function deleteFile(): void {
        setFileLoadingText("Deleting file");
        dispatch(deleteFileAsync());
    }

    function startUpdate(): void {
        dispatch(
            startBulkUploadStateAsync()
        );
    }

    return (
        <>
            <Breadcumbs pageName={breadcrumbBulkUpdate} />
            <div className={styles.bulkUpdatePage}>
                <div className={styles.pageContainer}>
                    <h1 className={styles.pageHeader}>{breadcrumbBulkUpdate}</h1>
                    <div className={styles.bodyContainer}>
                        <h3 className={styles.subHeading}>{"Updates in progress"}</h3>
                        {isUpdateStateLoading ? <Spinner size={SpinnerSize.medium}/>: <>
                            {bulkUpdateState?.totalRecords === 0 && 
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        No active updates in progress.
                                    </MessageBar>
                            }
                            {bulkUpdateState && bulkUpdateState.totalRecords > 0 && 
                                <div>
                                    <div><strong>Id:</strong> {bulkUpdateState.batchName}</div>
                                    <div><strong>Progress:</strong> {bulkUpdateState.processedRecords}/{bulkUpdateState.totalRecords}</div>
                                    <div><strong>Owner:</strong> {bulkUpdateState.author}</div>
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        The progress will be updated every 30 seconds automatically, or you can refresh your browser page.
                                    </MessageBar>
                                </div>
                            }
                        </>}

                    </div>
                    <div className={styles.bodyContainer}>
                        <h3 className={styles.subHeading}>{"New bulk records update"}</h3>
                        {isUpdateStateLoading 
                            ? <Spinner size={SpinnerSize.medium}/> 
                            : <>
                                {bulkUpdateState?.totalRecords === 0 && 
                                <>
                                    <div className={styles.bodySmallSection}>
                                        <FileUpload
                                            selectedFilesList={selectedFiles}
                                            onFileSelected={uploadFile}
                                            onFileRemove={() => {return;}}
                                            onFileEdit={() => {return;}}
                                            onFileMoved={() => {return;}}
                                            onLoading={isFileLoading}
                                            onLoadingText={fileLoadingText}
                                            selectFileButtonText={"Select CSV file"}
                                            componentMode={FormMode.New}
                                            errorMessages={errorMessage && [errorMessage] || undefined}
                                            canEdit={!fileUploaded}
                                            canAdd={!fileUploaded}
                                            canUploadSigned={false}
                                            canRemove={true}
                                            disableSelectFromSP
                                        ></FileUpload>
                                    </div>
                                    {fileUploaded && <div className={styles.bodySmallSection}>
                                        <PrimaryButton
                                            className={styles.button}
                                            ariaLabel={"Start bulk update button"}
                                            onClick={startUpdate}
                                            disabled={(bulkUpdateState?.errors?.length || 0) > 0}
                                        >
                                            {"Start bulk update"}
                                        </PrimaryButton>
                                        <DefaultButton
                                            className={styles.button}
                                            ariaLabel={"Cancel button"}
                                            onClick={deleteFile}
                                        >
                                            {"Cancel"}
                                        </DefaultButton>
                                    </div>}
                                </>
                                }
                                {bulkUpdateState && bulkUpdateState.totalRecords > 0 && 
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        You can`t start new updating process while there are active update in progress.
                                    </MessageBar>
                                }
                                {!!bulkUpdateState?.warnings?.length && 
                                    <div className={styles.warnings}>
                                        <MessageBar messageBarType={MessageBarType.warning}>
                                            There are some warnings in the source file:
                                        </MessageBar>
                                        {bulkUpdateState.warnings.map(x=>(<div key={x}>{x}</div>))}
                                    </div>
                                }
                                {!!bulkUpdateState?.errors?.length && 
                                    <>
                                        <MessageBar messageBarType={MessageBarType.error}>
                                            There are some errors in the source file:
                                        </MessageBar>
                                        {bulkUpdateState.errors.map(x=>(<div key={x}>{x}</div>))}
                                    </>
                                }
                            </>
                        }
                        
                    </div>
                    <div className={styles.bodyContainer}>
                        <h3 className={styles.subHeading}>{"Open updates history reports"}</h3>
                        <a href={historyLink}>
                            <DefaultButton
                                className={styles.button}
                                iconProps={{ iconName: "AdminSLogoInverse32", className: styles.iconBlue }}
                                ariaLabel={"Open in SharePoint library button"}
                                disabled={!historyLink}
                            >
                                {"Open in SharePoint library"}
                            </DefaultButton>
                        </a>
                    </div>
                    <div className={styles.buttonContainer}>
                    </div>
                    <div>{errorMessage}</div>
                </div>
            </div>
        </>
    );
}
