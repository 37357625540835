// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { IAppSettings } from "../../services/Api/executor/IApiServiceExecutor";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// 3. FEATURE STATE
export interface IAppSettingsPageState {
    initialSettings: IAppSettings | undefined;
    enteredBoardConnectSiteURL?: string;
    selectedFavicon?: string;
    selectedPrimaryIcon?: string;
    selectedNavbarIcon?: string;
    checkedWatermarkPDFPacks?: boolean;
}

export const initialState: IAppSettingsPageState = {
    initialSettings: undefined,
    enteredBoardConnectSiteURL: undefined,
    selectedFavicon: undefined,
    selectedPrimaryIcon: undefined,
    selectedNavbarIcon: undefined,
    checkedWatermarkPDFPacks: undefined
};

// 4. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const appSettingsPageSlice = createSlice({
    name: "appSettingsPage",
    initialState,
    reducers: {
        setBoardConnectSiteURL: (
            state: IAppSettingsPageState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.enteredBoardConnectSiteURL = undefined;
            } else {
                state.enteredBoardConnectSiteURL = action.payload;
            }
        },
        setSelectedFavicon: (
            state: IAppSettingsPageState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedFavicon = action.payload;
        },
        setSelectedPrimaryIcon: (
            state: IAppSettingsPageState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedPrimaryIcon = action.payload;
        },
        setSelectedNavbarIcon: (
            state: IAppSettingsPageState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedNavbarIcon = action.payload;
        },
        setCheckedWatermarkPDFPacks: (
            state: IAppSettingsPageState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.checkedWatermarkPDFPacks = action.payload;
        },
        setAppSettings: (
            state: IAppSettingsPageState,
            action: PayloadAction<IAppSettings | null>
        ) => {
            if(!action.payload){
                // for (const key in initialState) {
                //     const keyAs: keyof INotificationSettingsState = key as keyof INotificationSettingsState;
                //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
                //     (state as any)[keyAs] = initialState[keyAs] as any;
                // }
            } else {
                state.initialSettings = action.payload;
                state.selectedFavicon = action.payload.favicon;
                state.selectedPrimaryIcon = action.payload.primaryIcon;
                state.selectedNavbarIcon = action.payload.navbarIcon;
            }
        }
    }
});

// 4. SYNCRONOUS ACTIONS

export const {
    setBoardConnectSiteURL,
    setSelectedFavicon,
    setSelectedPrimaryIcon,
    setSelectedNavbarIcon,
    setCheckedWatermarkPDFPacks,
    setAppSettings
} = appSettingsPageSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const getAppSettings = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const settingsResponse: IAppSettings | null = await apiService.GetAppSettings();
    dispatch(setAppSettings(settingsResponse));
};

export const updateAppSettingsAsync = (
    payload: IAppSettings,
    callback?: { onSuccess: () => void; onError: (errorMsg: string) => void }
): AppThunk => async ()  => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        // Update the user settings in table storage
        const result: boolean | string = await apiService.UpdateAppSettings(payload);   
        
        result == true ? callback && callback.onSuccess && callback.onSuccess() : callback && callback.onError && callback.onError(result || "Something went wrong");
    } catch (e) {
        e && callback && callback.onError && callback.onError("Something went wrong");
    }
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectBoardConnectSiteURL = (state: RootState): string | undefined =>
    state.appSettingsPage.enteredBoardConnectSiteURL;
export const selectFavicon = (state: RootState): string | undefined =>
    state.appSettingsPage.selectedFavicon || state.context.favicon;
export const selectPrimaryIcon = (state: RootState): string | undefined =>
    state.appSettingsPage.selectedPrimaryIcon || state.context.primaryIcon;
export const selectNavbarIcon = (state: RootState): string | undefined =>
    state.appSettingsPage.selectedNavbarIcon || state.context.navbarIcon;
export const selectWatermarkPDFPacks = (state: RootState): boolean | undefined =>
    state.appSettingsPage.checkedWatermarkPDFPacks;

export const appInitialSettings = (state: RootState): IAppSettings | undefined =>
    state.appSettingsPage.initialSettings;


// 7. EXPORT REDUCER
export default appSettingsPageSlice.reducer;