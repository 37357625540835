import React from "react";
import { DefaultButton, Separator } from "@fluentui/react";
import { useSelector } from "react-redux";
import { selectCurrentRecord } from "../manageRecordWrapperSlice";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import { IRecord } from "../../../services/Api/executor/IApiServiceExecutor";
import styles from "./ManageEventPanel.module.scss";

export const ManageEventPanel = (): JSX.Element => {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const readOnly: boolean = React.useMemo(
        () =>
            !currentRecord?.canCurrentUserEditRecord ||
            !!currentRecord?.canCurrentUserEditRecordLimitedAccess ||
            !!currentRecord?.noFurtherAction ||
            !!currentRecord?.withdrawn,
        [
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.canCurrentUserEditRecordLimitedAccess,
            currentRecord?.noFurtherAction,
            currentRecord?.withdrawn
        ]
    );

    return (
        <div className={styles.eventManagePanelContainer}>
            <div className={styles.eventManagePanelDataContainer}>
                {!readOnly && (
                    <DefaultButton
                        className={styles.submitButton}
                        iconProps={{ iconName: "Edit" }}
                        href={UrlHelper.castUrlWithQueryStringParam(
                            "EditRecordDetails",
                            URL_QUERY_KEYS.TAB
                        )}
                    >
                    </DefaultButton>
                )}
                <div className={styles.subjectContainer}>
                    <div
                        className={[
                            styles.eventInfoContainer,
                            styles.eventDateAndLocationInfoContainer
                        ].join(" ")}
                    >
                    </div>
                    <div
                        className={[styles.eventInfoContainer, styles.eventItemsInfoContainer].join(
                            " "
                        )}
                    >
                        <Separator />
                    </div>
                </div>
            </div>
        </div>
    );
};
