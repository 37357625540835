import * as microsoftTeams from "@microsoft/teams-js";
let ctx: microsoftTeams.Context | undefined;
let initialized: boolean = false;

microsoftTeams.app
    .initialize()
    .then(() => {
        initialized = true;
    })
    .catch(() => {
        initialized = false;
    });

// No normal way to identify that app is not in the teams. https://stackoverflow.com/questions/60765958/is-there-a-way-to-tell-if-your-app-is-running-in-microsoft-teams
// Wait 800msec to init. Usually  it takes ~300 msec on my dev to init.
const maxAttempts: number = 8;

async function initCtx(): Promise<void> {
    return new Promise(resolve => {
        microsoftTeams.getContext((context: microsoftTeams.Context) => {
            ctx = context;
            resolve();
        });
    });
}

async function delay(msec: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, msec);
    });
}

export class TeamsContextProvider {
    public static async Init(): Promise<void> {
        let attempt: number = 0;
        while (!initialized && attempt < maxAttempts) {
            attempt++;
            await delay(100);
        }
        if (initialized) {
            await initCtx();
        }
    }

    public static get IsInTeams(): boolean {
        return !!ctx;
    }

    public static get Context(): microsoftTeams.Context | undefined {
        return ctx;
    }
}
