// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IBaseFile,
    IFile,
    ICorrespondenceFile,
    Move,
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { FileHelper } from "../../helpers/FileHelper";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

export declare type ImsgParsedFile = ICorrespondenceFile & IFile & {uploadedName: string};

// FEATURE STATE
export interface IWizardCorrespondenceFilesState {
    selectedFiles: ImsgParsedFile[];
    filesLoading: boolean;
    errorMessages: string[] | undefined;
    uploadSuccess: boolean;
}

export const initialState: IWizardCorrespondenceFilesState = {
    selectedFiles: [],
    filesLoading: false,
    errorMessages: undefined,
    uploadSuccess: false
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const WizardCorrespondenceFilesSlice = createSlice({
    name: "WizardCorrespondenceFiles",
    initialState,
    reducers: {
        resetWizardCorrespondenceFiles: (state: IWizardCorrespondenceFilesState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardCorrespondenceFilesState = key as keyof IWizardCorrespondenceFilesState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state[keyAs] = initialState[keyAs] as any;
            }
        },
        
        addSelectedFile: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<ImsgParsedFile>
        ) => {
            state.selectedFiles.push(action.payload);
        },
        
        removeSelectedFile: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<IBaseFile>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.selectedFiles.splice(fileIndex, 1);
            }
        },
        editSelectedFile: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<ImsgParsedFile>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                const updatedFile: ImsgParsedFile = action.payload;
                state.selectedFiles.splice(fileIndex, 1, updatedFile);
            }
        },
        renameSelectedFile: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: ImsgParsedFile = {
                    ...state.selectedFiles[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedFiles.splice(fileIndex, 1, updatedFile);
            }
        },
        moveSelectedFile: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: ImsgParsedFile = {
                    ...state.selectedFiles[fileIndex],
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: ImsgParsedFile = state.selectedFiles[fileIndex - 1];
                    state.selectedFiles.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: ImsgParsedFile = state.selectedFiles[fileIndex + 1];
                    state.selectedFiles.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }
        },
        setFilesLoading: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<boolean>
        ) => {
            state.filesLoading = action.payload;
        },
        setErrorMessages: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<string[] | undefined>
        ) => {
            state.errorMessages = action.payload;
        },
        setUploadSuccess: (
            state: IWizardCorrespondenceFilesState,
            action: PayloadAction<boolean>
        ) => {
            state.uploadSuccess = action.payload;
        },
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    resetWizardCorrespondenceFiles,
    addSelectedFile,
    removeSelectedFile,
    editSelectedFile,
    renameSelectedFile,
    moveSelectedFile,
    setFilesLoading,
    setErrorMessages,
    setUploadSuccess
} = WizardCorrespondenceFilesSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const deleteFileAsync = (
    item: ImsgParsedFile
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setFilesLoading(true));
    // Delete the record document from SharePoint
    const retVal: boolean = await apiService.DeleteFileFromPersonalStorage({
        name: item.uploadedName,
        path: item.path
    });
    // Remove file from the state
    if (retVal) {
        dispatch(removeSelectedFile(item));
    }
    dispatch(setFilesLoading(false));
    return retVal;
};


export const uploadFilesAsync = (
    files: IFile[]
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setFilesLoading(true));
    dispatch(setErrorMessages(undefined));

    const promises: Promise<void>[] = [];
    let errorMessages: string[] | undefined = undefined;

    for(const file of files){
        if (file.contentURL) {
            const binary: string  = await FileHelper.fileContentAsBase64(file.contentURL);
            file.contentURL = binary;
        }
    
        // Rename the record document from SharePoint
        const p: Promise<void> = apiService.UploadAndParseCorrespondenceFile({
            ...file
        }).then((retVal: ICorrespondenceFile | null) => {
            // Update the state
            if (retVal) {
                dispatch(
                    addSelectedFile({
                        ...retVal,
                        uploadedName: retVal.name,
                        name: file.name,
                        contentURL: retVal.path
                    })
                );
            } else {
                errorMessages = errorMessages || [];
                errorMessages.push(`Failed to upload file: ${file.name}`);
            }
        });
        promises.push(p);
    }

    await Promise.all(promises);
    
    dispatch(setFilesLoading(false));
    dispatch(setErrorMessages(errorMessages));
    if(!errorMessages){
        dispatch(setUploadSuccess(true));
        setTimeout(() => {
            dispatch(setUploadSuccess(false));
        }, 3000);
    }
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const stateSelectedFiles = (state: RootState): ImsgParsedFile[] =>
    state.wizardCorrespondenceFiles.selectedFiles;

export const stateFilesLoading = (state: RootState): boolean =>
    state.wizardCorrespondenceFiles.filesLoading;

export const stateErrorMessages = (state: RootState): string[] | undefined =>
    state.wizardCorrespondenceFiles.errorMessages;

export const uploadSuccess = (state: RootState): boolean =>
    state.wizardCorrespondenceFiles.uploadSuccess;

// 6. EXPORT REDUCER
export default WizardCorrespondenceFilesSlice.reducer;
