import IDependencyResolver from "./IDependencyResolver";
import ILogProvider from "../Log/ILogProvider";
/* eslint-disable @typescript-eslint/no-explicit-any */
import ConsoleLogProvider from "../Log/ConsoleLogProvider";
import StorageCacheProvider from "../Cache/StorageCacheProvider";
import ICacheProvider from "../Cache/ICacheProvider";
import IAuthService from "../../services/Auth/IAuthService";
import AuthServiceExecutor from "../../services/Auth/executor/AuthServiceExecutor";
import AuthService from "../../services/Auth/AuthService";
import IApiService from "../../services/Api/IApiService";
import ApiServiceExecutor from "../../services/Api/executor/ApiServiceExecutor";
import ApiService from "../../services/Api/ApiService";

export default class DependencyResolver implements IDependencyResolver {
    public ResolveICacheProvider(): ICacheProvider {
        if (!(window as any).dependencyResolverCacheProvider) {
            (window as any).dependencyResolverCacheProvider = new StorageCacheProvider(
                window.localStorage
            );
        }
        return (window as any).dependencyResolverCacheProvider;
    }

    public ResolveILogProvider(): ILogProvider {
        if (!(window as any).dependencyResolverLogProvider) {
            (window as any).dependencyResolverLogProvider = new ConsoleLogProvider();
        }
        return (window as any).dependencyResolverLogProvider;
    }

    public ResolveIApiService(): IApiService {
        if (!(window as any).dependencyResolverApiService) {
            (window as any).dependencyResolverApiService = new ApiService(
                new ApiServiceExecutor(this.ResolveILogProvider(), this.ResolveIAuthService()),
                this.ResolveICacheProvider(),
                this.ResolveIAuthService()
            );
        }
        return (window as any).dependencyResolverApiService;
    }

    public ResolveIAuthService(): IAuthService {
        if (!(window as any).dependencyResolverAuthService) {
            (window as any).dependencyResolverAuthService = new AuthService(
                new AuthServiceExecutor(this.ResolveILogProvider()),
                this.ResolveILogProvider(),
                this.ResolveICacheProvider()
            );
        }
        return (window as any).dependencyResolverAuthService;
    }
}
