import React from "react";
import { ChoiceGroup, DefaultButton, IChoiceGroupOption, MessageBar, MessageBarType, PrimaryButton, Separator, TextField, Checkbox } from "@fluentui/react";
import ModalContainer from "../../../components/FormControls/ModalContainer/ModalContainer";
import { ContactSource, IContact } from "../../../services/Api/executor/IApiServiceExecutor";
import {
    correspondentAddressFieldLabel,
    correspondentEmailFieldLabel,
    correspondentJobTitleFieldLabel,
    correspondentPhoneNumberFieldLabel,
    saveEditContactButtonLabel,
    editCorrespondentFormHeaderLabel,
    newContactFormHeaderLabel,
    correspondentFullNameFieldLabel,
    editOrgFormHeaderLabel,
    searchCorrespondentOrgFieldLabel,
    organisationFullNameFieldLabel,
    organisationAddressFieldLabel,
    organisationEmailFieldLabel,
    organisationPhoneNumberFieldLabel,
    addNewOrgButtonLabel,
    correspondentOrganisationFieldLabel,
    saveNewCorrespondentButtonLabel,
    saveNewOrgButtonLabel,
    cancelButtonLabel,
    saveFormContactButtonLabel
} from "../ContactFormConstants";
import ContactPicker from "../../../components/FormControls/ContactPicker/ContactPicker";
import styles from "../WizardCorrespondenceDetails.module.scss";
import { ContactItem } from "../WizardCorrespondenceDetails";
import IApiService from "../../../services/Api/IApiService";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";

interface IContactFormProps {
    contact?: IContact;
    isEditMode: boolean;
    onSubmit: (updatedContact: IContact) => Promise<void>;
    onCancel: () => void;
}

const supportedTypes: ContactSource[] = [ContactSource.Organization, ContactSource.Contact, ContactSource.New];

const initialContact: IContact = {
    email:"",
    emailIsNotProvided: false,
    fullName: "",
    id:"",
    sourceType: ContactSource.Contact
};

const contactTypeOptions: IChoiceGroupOption[] = [
    { key: String(ContactSource.Contact), text: "Add new person" },
    { key: String(ContactSource.Organization), text: "Add new organisation" }
];

export default function ContactForm(props: IContactFormProps): JSX.Element {
    const addressErrorMessage: string = "The address cannot begin with a comma character.";

    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [contactType, setContactType] = React.useState<ContactSource>(ContactSource.Contact);
    const [showOrgSubForm, setShowOrgSubForm] = React.useState<boolean>(false);
    const [formDataContact, setFormDataContact] = React.useState<IContact | undefined>();
    const [formDataContactOrganization, setFormDataContactOrganization] = React.useState<IContact | undefined>();
    const [addressFieldErrorMessage, setAddressFieldErrorMessage] = React.useState<string>("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if (props.isEditMode && props.contact) {
            if(supportedTypes.indexOf(props.contact.sourceType)=== -1){
                setError("Unsupported contact type");
            } else{
                let contactTypeFromProps:ContactSource = props.contact.sourceType;
                if(contactTypeFromProps === ContactSource.New){
                    contactTypeFromProps = ContactSource.Contact;
                }
                setContactType(contactTypeFromProps);
            }
        }
    }, [props.contact, props.isEditMode]);

    React.useEffect(() => {
        if (props.contact && !formDataContact) {
            setFormDataContact(props.contact);
        }
    }, [formDataContact, props.contact]);

    React.useEffect(() => {
        if (props.contact?.organization) {
            setFormDataContactOrganization(props.contact?.organization);
        }
    }, [props.contact?.organization]);

    const addressIsValid: boolean = React.useMemo(() => {
        if (formDataContact?.address) {
            return !formDataContact.address.startsWith(",");
        } else {
            return false;
        }
    }, [formDataContact?.address]);

    const formIsValid: boolean = React.useMemo(() => {
        if (formDataContact) {
            if (contactType === ContactSource.Organization) {
                return (
                    !!formDataContact.fullName &&
                    (!!formDataContact.email || formDataContact.emailIsNotProvided) &&
                    addressIsValid
                );
            } else if (contactType === ContactSource.Contact) {
                let isValid: boolean = !!formDataContact.fullName &&
                    (!!formDataContact.email || formDataContact.emailIsNotProvided) &&
                    !showOrgSubForm;
                if (formDataContact.address) {
                    isValid = isValid && addressIsValid;
                }
                return isValid;
            }
        }
        return false;
    }, [formDataContact, contactType, addressIsValid, showOrgSubForm]);

    const onConfirm: () => Promise<void> = React.useCallback(async () => {
        try {
            setError("");
            if(!formIsValid || !formDataContact){
                setError("Invalid form data");
                return;
            }
            setInProgress(true);
            const resolver: DependencyResolver = new DependencyResolver();
            const apiService: IApiService = resolver.ResolveIApiService();
            const dataToSend: IContact = {...formDataContact};
            //sanitize object
            if(dataToSend.organization){
                dataToSend.address = "";
            }
            const updatedContactData: IContact | null = await apiService.UpdateCreateContact(dataToSend);
            if(updatedContactData === null){
                setError("Error during contact creating/updating.");
            } else{
                await props.onSubmit(updatedContactData);
                props.onCancel();}
            
        } finally {
            setInProgress(false);
        }
    }, [formDataContact, props, formIsValid]);

    

    const setOrgFormData: (organizationFieldsToSet:Partial<IContact>|undefined) => void = React.useCallback((organizationFieldsToSet:Partial<IContact>|undefined)=>{
        // Contact form, so Organization is a subform
        if(contactType === ContactSource.Contact){
            if(organizationFieldsToSet===undefined){
                setFormDataContactOrganization(organizationFieldsToSet);
            } else {
                setFormDataContactOrganization({
                    ...formDataContactOrganization || initialContact,
                    sourceType: ContactSource.Organization,
                    ...organizationFieldsToSet
                });
            }
        // Organization form, so Organization is main form
        } else if(contactType === ContactSource.Organization){
            setFormDataContact({
                ...formDataContact || initialContact,
                sourceType: ContactSource.Organization,
                ...organizationFieldsToSet
            });
        }
        if (organizationFieldsToSet) {
            if (organizationFieldsToSet.address?.startsWith(",")) {
                setAddressFieldErrorMessage(addressErrorMessage);
            } else {
                setAddressFieldErrorMessage("");
            }   
        }
    },[formDataContactOrganization, contactType, formDataContact]);

    const setFormDataFields: (fildsData:Partial<IContact>) => void = React.useCallback((fildsData:Partial<IContact>)=>{
        setFormDataContact({
            ...formDataContact || initialContact,
            sourceType: contactType,
            ...fildsData
        });
        if (fildsData?.address?.startsWith(",")) {
            setAddressFieldErrorMessage(addressErrorMessage);
        } else {
            setAddressFieldErrorMessage("");
        }
    },[contactType, formDataContact]);

    const orgData: IContact | undefined = React.useMemo(()=>{
        if(contactType === ContactSource.Contact){
            return formDataContactOrganization;
        }else if(contactType === ContactSource.Organization){
            return formDataContact;
        }
    },[formDataContactOrganization, contactType, formDataContact]);

    const orgForm: JSX.Element = <>
        <TextField
            required={true}
            disabled={inProgress}
            label={organisationFullNameFieldLabel}
            value={orgData?.fullName}
            onChange={(event, newValue?: string) => setOrgFormData({ fullName: newValue || ""})}
        />
        <TextField
            required={true}
            disabled={inProgress}
            label={organisationAddressFieldLabel}
            value={orgData?.address}
            multiline
            autoAdjustHeight
            onChange={(event, newValue?: string) => setOrgFormData({ address: newValue || ""})}
            errorMessage={addressFieldErrorMessage}
        />
        <TextField
            required={!orgData?.emailIsNotProvided}
            disabled={inProgress || orgData?.emailIsNotProvided || (props.isEditMode && (orgData?.id === formDataContact?.organization?.id && !!orgData?.id))}
            label={organisationEmailFieldLabel}
            value={orgData?.email}
            onChange={(event, newValue?: string) => setOrgFormData({ email: newValue || ""})}
        />
        <Checkbox
            className={styles.checkboxTopGap}
            label="No email address provided"
            checked={orgData?.emailIsNotProvided}
            onChange={(event, newValue?: boolean) => setOrgFormData({ emailIsNotProvided: newValue, email: "" })}
        />
        <TextField
            required={false}
            disabled={inProgress}
            label={organisationPhoneNumberFieldLabel}
            value={orgData?.workPhone}
            onChange={(event, newValue?: string) => setOrgFormData({ workPhone: newValue || ""})}
        />
    </>;

    let formTitle: string = "";
    let submitLabel: string = "";

    if(props.isEditMode){
        submitLabel = saveEditContactButtonLabel;
        if(contactType === ContactSource.Organization){
            formTitle = editOrgFormHeaderLabel;
        }
        else{
            formTitle = editCorrespondentFormHeaderLabel;
        }
    } else {
        formTitle = newContactFormHeaderLabel;
        if(contactType === ContactSource.Organization){
            submitLabel = saveNewOrgButtonLabel;
        }
        else{
            submitLabel = saveNewCorrespondentButtonLabel;
        }
    }


    return (
        <ModalContainer
            title={formTitle}
            submitLabel={submitLabel}
            isOpen={true}
            inProgress={inProgress}
            submitDisabled={!formIsValid || inProgress}
            onSubmit={onConfirm}
            onCancel={props.onCancel}
        >
            <>
                {!props.isEditMode && (
                    <ChoiceGroup
                        className={styles.contactTypeChoiceGroupContainer}
                        defaultSelectedKey={contactTypeOptions[0].key}
                        options={contactTypeOptions}
                        disabled={inProgress}
                        onChange={(ev, option?: IChoiceGroupOption | undefined) => setContactType(Number(option?.key || ContactSource.Contact))}
                    />                
                )}
                {contactType === ContactSource.Organization && orgForm}
                {contactType === ContactSource.Contact && 
                <>
                    <TextField
                        required={true}
                        disabled={inProgress}
                        label={correspondentFullNameFieldLabel}
                        value={formDataContact?.fullName}
                        onChange={(event, newValue?: string) => setFormDataFields({ fullName: newValue || "" })}
                    />
                    <TextField
                        required={false}
                        disabled={inProgress}
                        label={correspondentJobTitleFieldLabel}
                        value={formDataContact?.jobTitle}
                        onChange={(event, newValue?: string) => setFormDataFields({ jobTitle: newValue || "" })}
                    />
                    <TextField
                        required={!formDataContact?.emailIsNotProvided}
                        disabled={inProgress || props.isEditMode || formDataContact?.emailIsNotProvided}
                        label={correspondentEmailFieldLabel}
                        value={formDataContact?.email}
                        onChange={(event, newValue?: string) => setFormDataFields({ email: newValue || "" })}
                    />
                    <Checkbox
                        className={styles.checkboxTopGap}
                        label="No email address provided"
                        checked={formDataContact?.emailIsNotProvided}
                        onChange={(event, newValue?: boolean) => setFormDataFields({ emailIsNotProvided: newValue, email: "" })}
                    />

                    <Separator />
                    {showOrgSubForm && (
                        <div className={styles.organisationSubFormContainer}>
                            {orgForm}
                            <div className={styles.orgSubFormButtonContainer}>
                                <PrimaryButton
                                    className={styles.submitButton}
                                    onClick={() => {
                                        setShowOrgSubForm(false);
                                        setFormDataFields({organization: formDataContactOrganization});
                                    }}
                                    disabled={
                                        inProgress ||
                                        !formDataContactOrganization ||
                                        !formDataContactOrganization?.fullName ||
                                        !formDataContactOrganization?.address ||
                                        (!formDataContactOrganization.emailIsNotProvided && !formDataContactOrganization?.email)
                                    }
                                    text={saveFormContactButtonLabel}
                                />
                                <DefaultButton
                                    className={styles.orgSubFormCancelButton}
                                    onClick={() => {
                                        if (formDataContactOrganization) {
                                            setFormDataContactOrganization(formDataContact?.organization);
                                        }
                                        setShowOrgSubForm(false);
                                    }}
                                    disabled={inProgress}
                                    text={cancelButtonLabel}
                                />
                            </div>
                        </div>
                    )}
                    {!showOrgSubForm && (
                        <>
                            {formDataContact?.organization == undefined ? (
                                <div
                                    className={[
                                        styles.fieldsCorrespondentContainer,
                                        styles.fieldOrgContainer
                                    ].join(" ")}
                                >
                                    <ContactPicker
                                        label={searchCorrespondentOrgFieldLabel}
                                        isRequired={false}
                                        disabled={inProgress}
                                        limitToSources={[ContactSource.Organization]}
                                        selectedUsers={
                                            formDataContactOrganization ? [formDataContactOrganization] : undefined
                                        }
                                        onChange={(contact?: IContact[]) => 
                                        {
                                            setOrgFormData((contact || [])[0]);
                                            setFormDataFields({organization: (contact || [])[0]});
                                        }
                                        }
                                    />
                                    <div className={styles.addNewCorrespondentContainer}>
                                        <div className={styles.orText}>OR</div>
                                        <DefaultButton
                                            allowDisabledFocus
                                            text={ addNewOrgButtonLabel }
                                            disabled={ inProgress }
                                            onClick={() => {
                                                setOrgFormData(undefined);
                                                setShowOrgSubForm(!showOrgSubForm);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.orgContactItemContainer}>
                                    <div className={styles.orgContactLabelField}>{correspondentOrganisationFieldLabel}</div>
                                    <ContactItem
                                        contact={formDataContact?.organization}
                                        showActionButtons
                                        onDelete={() => {
                                            setFormDataFields({organization: undefined});
                                            setOrgFormData(undefined);
                                        }}
                                        onEdit={() => setShowOrgSubForm(true)}
                                        disabled={inProgress}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {!showOrgSubForm && <>
                        {!formDataContact?.organization && 
                        <TextField
                            required={false}
                            disabled={inProgress}
                            label={correspondentAddressFieldLabel}
                            value={formDataContact?.address}
                            multiline 
                            autoAdjustHeight
                            onChange={(event, newValue?: string) => setFormDataFields({ address: newValue || "" })}
                            errorMessage={addressFieldErrorMessage}
                        />
                        }
                        <TextField
                            required={false}
                            disabled={inProgress}
                            label={correspondentPhoneNumberFieldLabel}
                            value={formDataContact?.workPhone}
                            onChange={(event, newValue?: string) => setFormDataFields({ workPhone: newValue || "" })}
                        />
                    </>}                
                </>}
                {!!error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            </>
        </ModalContainer>
    );
}
