// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IDeleteRecordDocumentRequest,
    IFile,
    IUploadRecordDocumentRequest,
    Move,
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { FileHelper } from "../../helpers/FileHelper";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export interface IWizardCorrespondenceDetailsState {
    selectedFiles: IFile[];
    filesLoading: boolean;
    errorMessages?: string[];
}

export const initialState: IWizardCorrespondenceDetailsState = {
    selectedFiles: [],
    filesLoading: false,
    errorMessages: undefined
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const WizardCorrespondenceDetailsSlice = createSlice({
    name: "WizardCorrespondenceDetails",
    initialState,
    reducers: {
        resetWizardCorrespondenceDetails: (state: IWizardCorrespondenceDetailsState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardCorrespondenceDetailsState = key as keyof IWizardCorrespondenceDetailsState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state[keyAs] = initialState[keyAs] as any;
            }
        },
        
        addSelectedFile: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<IFile>
        ) => {
            state.selectedFiles.push(action.payload);
        },
        setSelectedFiles: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<IFile[]>
        ) => {
            state.selectedFiles = action.payload;
        },
        setErrorMessages: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<string[] | undefined>
        ) => {
            state.errorMessages = action.payload;
        },
        removeSelectedFile: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<IFile>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload.name
            );
            if (fileIndex > -1) {
                state.selectedFiles.splice(fileIndex, 1);
            }
        },
        editSelectedFile: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<string[]>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: IFile = {
                    ...state.selectedFiles[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedFiles.splice(fileIndex, 1, updatedFile);
            }
        },
        moveSelectedFile: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedFiles.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: IFile = {
                    ...state.selectedFiles[fileIndex],
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: IFile = state.selectedFiles[fileIndex - 1];
                    state.selectedFiles.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: IFile = state.selectedFiles[fileIndex + 1];
                    state.selectedFiles.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }
        },
        setFilesLoading: (
            state: IWizardCorrespondenceDetailsState,
            action: PayloadAction<boolean>
        ) => {
            state.filesLoading = action.payload;
        },
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    resetWizardCorrespondenceDetails,
    addSelectedFile,
    removeSelectedFile,
    editSelectedFile,
    moveSelectedFile,
    setFilesLoading,
    setErrorMessages
} = WizardCorrespondenceDetailsSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const deleteDocumentAsync = (
    recordDocument: IDeleteRecordDocumentRequest
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setFilesLoading(true));
    // Delete the record document from SharePoint
    const retVal: boolean = await apiService.DeleteRecordDocument({
        ...recordDocument
    });

    // Remove file from the state
    if (retVal) {
        const file: IFile = { name: recordDocument.name, contentURL: "", path: "" };
        dispatch(removeSelectedFile(file));
        dispatch(setFilesLoading(false));
    }
    return retVal;
};


export const uploadDocumentAsync = (
    recordDocuments: IUploadRecordDocumentRequest[]
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setFilesLoading(false));
    dispatch(setErrorMessages(undefined));

    const promises: Promise<void>[] = [];
    let errorMessages: string[] | undefined = undefined;
    for(const doc of recordDocuments){
        if (doc.content) {
            const binary: string  = await FileHelper.fileContentAsBase64(doc.content);
            doc.content = binary;
        }
    
        // Rename the record document from SharePoint
        const p: Promise<void> = apiService.UploadRecordDocument({
            ...doc
        }).then((retVal) => {
            if (retVal && retVal != "") {
                dispatch(
                    addSelectedFile({
                        name: doc.name,
                        contentURL: retVal,
                        path: ""
                    })
                );
            } else {
                errorMessages = errorMessages || [];
                errorMessages.push(`Failed to upload file: ${doc.name}`);
            }
        });
        promises.push(p);
    }
    
    await Promise.all(promises);

    dispatch(setFilesLoading(false));
    dispatch(setErrorMessages(errorMessages));
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const stateSelectedFiles = (state: RootState): IFile[] =>
    state.wizardCorrespondenceDetails.selectedFiles;

export const stateFilesLoading = (state: RootState): boolean =>
    state.wizardCorrespondenceDetails.filesLoading;

export const stateErrorMessages = (state: RootState): string[] | undefined =>
    state.wizardCorrespondenceDetails.errorMessages;

// 6. EXPORT REDUCER
export default WizardCorrespondenceDetailsSlice.reducer;
