import {
    useDispatch,
    useSelector
} from "react-redux";
//1. IMPORTS
import React from "react";
import { PrimaryButton, Text, Spinner, SpinnerSize } from "@fluentui/react";
import styles from "./ManageRecordNotifications.module.scss";
import { TxtBtnSave
} from "../../providers/Constants/SettingsConstants";

import { 
    userPreferredChannel, 
    userPreferredFrequency,
    excludedNotifications,
    isGlobalSettingsOverriden,
    getUserSettings,
    usersForDecision,
    usersForRecommendation,
    userInitialSettings,
    returnInitialSettings,
    setUserSettings,
    proxyPreferredChannel,
    proxyPreferredFrequency
} from "../NotificationSettings/NotificationSettingsSlice";
import {
    NotificationSettings,
    NotificationSettingsScope
} from "../NotificationSettings/NotificationSettings";
import {
    IUserSettings,
    IRecord,
    ISimpleUser,
    NotificationsChannel,
    NotificationsFrequencyType,
    NotificationsOption
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    selectCurrentRecord,
    updateUserSettingsAsync
} from "../../features/manageRecordWrapper/manageRecordWrapperSlice";
import { Dispatch } from "@reduxjs/toolkit";
import ArrayHelper from "../../helpers/ArrayHelper";
import { DefaultButton, MessageBar, MessageBarType } from "@fluentui/react";
// import {
//     documentLoading,
// } from "../../features/manageRecordWrapper/manageRecordWrapperSlice";

// Manage record document component
export function ManageRecordNotifications(): JSX.Element {
    // Fetching the current record from redux
    const initialSettings: IUserSettings | undefined = useSelector(userInitialSettings);
    const userSelectedPreferredChannel: NotificationsChannel = useSelector(userPreferredChannel);
    const userSelectedPreferredFrequency: NotificationsFrequencyType = useSelector(userPreferredFrequency);
    const userSelectedExcludedNotifications: NotificationsOption[] = useSelector(excludedNotifications);
    const userSelectedIsGlobalSettingsOverriden: boolean = useSelector(isGlobalSettingsOverriden);
    const record: IRecord | null = useSelector(selectCurrentRecord);
    const selectedUsersForRecommendation : ISimpleUser[] = useSelector(usersForRecommendation);
    const selectedUsersForDecision : ISimpleUser[] = useSelector(usersForDecision);
    
    //proxy
    const proxySelectedPreferredChannel: NotificationsChannel = useSelector(proxyPreferredChannel);
    const proxySelectedPreferredFrequency: NotificationsFrequencyType =
        useSelector(proxyPreferredFrequency);

    // Disabling eslint for one line to make use of any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const [errorMessage, setErrorMessage] = React.useState("");
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);

    React.useEffect(() => {
        //dispatch(setDocumentFetching(true));
        if (record?.recordId) {
            dispatch(getUserSettings(record.recordId));
        }
    }, [dispatch, record?.recordId]);

    const readOnly: boolean = React.useMemo(
        () => !record?.canCurrentUserEditRecord || !!record?.canCurrentUsedEditRecordAdminLimitedAccess,
        [record?.canCurrentUsedEditRecordAdminLimitedAccess, record?.canCurrentUserEditRecord]
    );

    const userNotificationSettings: IUserSettings = React.useMemo(() => {
        return {
            settingsSubScopeId: record?.recordId,
            notificationsChannel: userSelectedPreferredChannel,
            notificationsFrequencyType: userSelectedPreferredFrequency,
            excludedNotifications: userSelectedExcludedNotifications,
            isOverridenOnSubScope: userSelectedIsGlobalSettingsOverriden,
            notifyUsersOfRecommendation: selectedUsersForRecommendation,
            notifyUsersOfDecision: selectedUsersForDecision,
            proxyNotificationsChannel: proxySelectedPreferredChannel,
            proxyNotificationsFrequencyType: proxySelectedPreferredFrequency,
            hasAccessToAIFeatures: false,
        };
    },[
        record, 
        userSelectedPreferredChannel, 
        userSelectedPreferredFrequency,
        userSelectedExcludedNotifications,
        userSelectedIsGlobalSettingsOverriden,
        selectedUsersForRecommendation,
        selectedUsersForDecision,
        proxySelectedPreferredChannel,
        proxySelectedPreferredFrequency
    ]);

    const anyChangesApplied: boolean = React.useMemo(() => { 
        return !!record && !!initialSettings
        && (userSelectedIsGlobalSettingsOverriden
            ? (initialSettings.isOverridenOnSubScope != userSelectedIsGlobalSettingsOverriden
                || initialSettings.notificationsFrequencyType !== userSelectedPreferredFrequency
                || initialSettings.notificationsChannel !== userSelectedPreferredChannel
                || !ArrayHelper.isEqualAsJSON(initialSettings.excludedNotifications, userSelectedExcludedNotifications))
            : initialSettings.isOverridenOnSubScope != userSelectedIsGlobalSettingsOverriden)

        || (!ArrayHelper.isEqualAsJSON(initialSettings?.notifyUsersOfRecommendation, selectedUsersForRecommendation))
        || (!ArrayHelper.isEqualAsJSON(initialSettings?.notifyUsersOfDecision, selectedUsersForDecision));
    },[
        record,
        initialSettings,
        selectedUsersForDecision,
        selectedUsersForRecommendation,
        userSelectedExcludedNotifications,
        userSelectedIsGlobalSettingsOverriden,
        userSelectedPreferredChannel,
        userSelectedPreferredFrequency
    ]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return readOnly || updatingInProgress || !initialSettings;
    },[readOnly, updatingInProgress, initialSettings]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    const cancelBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    const onUpdateClick: () => void = React.useCallback(() => {
        setUpdatingInProgress(true);
        setErrorMessage("");
        // const resolver: DependencyResolver = new DependencyResolver();
        // const apiService: IApiService = resolver.ResolveIApiService();
        dispatch(updateUserSettingsAsync(userNotificationSettings,
            {                                
                onSuccess: () => {
                    setUpdatingInProgress(false);
                    dispatch(setUserSettings(userNotificationSettings));
                },
                onError: () => {                                        
                    setUpdatingInProgress(false);
                }
            }));                                          
        // // Update the user settings in table storage
        // await apiService.UpdateUserSettings(userNotificationSettings);   
                 
    },[dispatch, userNotificationSettings]);

    const onCancelClick: () => void = React.useCallback(() => {
        dispatch(returnInitialSettings());
    },[
        dispatch
    ]);
    
    return (
        <div className={styles.manageRecordNotificationsContainer}>
            <div className={styles.myNotificationsContainer}>
                <NotificationSettings scope={NotificationSettingsScope.record} disabled={formIsBlocked}/>
            </div>
            {!!errorMessage && (
                <div>
                    <MessageBar
                        isMultiline={true}
                        messageBarType={MessageBarType.error}
                    >
                        {errorMessage}
                    </MessageBar>
                </div>
            )}
            <div className={styles.buttonContainer}>
                <PrimaryButton                   
                    onClick={onUpdateClick}
                    allowDisabledFocus
                    disabled={!updateBtnIsActive}
                    className={styles.primaryButtonStyling}
                    ariaLabel={`${TxtBtnSave} button`}
                >
                    <Text className={styles.text}>{TxtBtnSave}</Text>
                    {updatingInProgress && (
                        <Spinner className={styles.buttonSpinner} size={SpinnerSize.small} />
                    )}
                </PrimaryButton>
                <DefaultButton
                    disabled={!cancelBtnIsActive}
                    onClick={onCancelClick}
                >
                    Cancel
                </DefaultButton>
            </div>
            
        </div>
    );
}
