import {
    useDispatch,
    useSelector
} from "react-redux";
//1. IMPORTS
import React from "react";
import {
    DefaultButton,
    PrimaryButton,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import { Icon } from "@fluentui/react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import styles from "./ManageRecordDocuments.module.scss";
import {
    FormMode,
    IRecord,
    IRecordDocuments,
    ITerm,
    ITermSet,
    IUser,
    IUserSettings
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    getRecordDocumentsAsync,
    getSecurityClassification,
    recordDocuments,
    securityClassification,
    setSelectedAttachments,
    setSelectedPrimaryFiles,
    setSelectedSupportingFiles
} from "../manageRecordEditDocuments/manageRecordEditDocumentsSlice";
import {
    documentLoading,
    selectCurrentRecord,
    setDocumentFetching,
    setDocumentTabMode
} from "../../features/manageRecordWrapper/manageRecordWrapperSlice";
import PdfPackButton from "../../components/FormControls/PdfPackButton/PdfPackButton";
import DocumentLink from "../../components/FormControls/DocumentLink/DocumentLink";
import { selectCurrentUser, selectIsMobile } from "../../app/globalSlices/contextSlice";
import { isManageSignaturesStage } from "../../components/FormControls/RecordTimeline/StagesContentUtils";
import { resetWizardResponseInformation } from "../wizardResponseInformation/wizardResponseInformationSlice";
import SnapshotList from "../../components/FormControls/SnapshotList/SnapshotList";
import SnapshotCards from "../../components/FormControls/SnapshotCard/SnapshotCards";
import { MessageBar, MessageBarType, TooltipHost } from "@fluentui/react";
import { editDocumentsHeader } from "../../providers/Constants/RecordWizardConstants";
import { DocumentsHelper } from "../../helpers/DocumentsHelper";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../app/Constants";
import { Link } from "react-router-dom";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { SignedDocSignatureInstructionsPanel } from "./signedDocSignatureInstructions/SignedDocSignatureInstructionsPanel";

// Manage record document component
export function ManageRecordDocuments(): JSX.Element {
    const { t } = useTranslation();
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    // Fetching the current record from redux
    const record: IRecord | null = useSelector(selectCurrentRecord);
    const docCollection: IRecordDocuments = useSelector(recordDocuments);
    const isDocLoading: boolean = useSelector(documentLoading);
    const isMobile: boolean = useSelector(selectIsMobile);
    const securityClassifications: ITermSet | null = useSelector(securityClassification);

    // readonly if the user doesn't have permission to edit record documents or they have a limited view of the record
    // however, we show the button if the user can upload signed documents regardless
    const readOnly: boolean = (!record?.canCurrentUserEditRecordDocuments || !!record?.canCurrentUserEditRecordLimitedAccess || !!record?.canCurrentUsedEditRecordAdminLimitedAccess)
        && (!record?.canCurrentUserUploadSignedDocuments) && (!record?.canCurrentUserUploadSupportingDocuments);

    const filterByUserUPN: string | undefined = record?.canCurrentUserViewDocumentsFromOtherUsers ? undefined : (currentUser?.userPrincipalName || undefined);
    const filterByUserEmail: string | undefined = record?.canCurrentUserViewDocumentsFromOtherUsers ? undefined : (currentUser?.mail || undefined);

    // Disabling eslint for one line to make use of any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [highestDocLabel, setHighestDocLabel] = React.useState<string | undefined>(undefined);
    
    const [showAIDocumentGenerationOption, setShowAIDocumentGenerationOption] = React.useState<boolean>(false); 
    React.useEffect(() => {      
        async function loadUserSettings() {
            const resolver: DependencyResolver = new DependencyResolver();
            const apiService: IApiService = resolver.ResolveIApiService();
            const optionsResponse: IUserSettings | null = await apiService.GetUserSettings();
            setShowAIDocumentGenerationOption(optionsResponse?.hasAccessToAIFeatures ?? false);
        }

        loadUserSettings();
    }, []);

    React.useEffect(() => {
        dispatch(setDocumentFetching(true));
        if (record?.recordId && record?.name) {
            dispatch(getRecordDocumentsAsync(record.recordId, record.name));
            dispatch(getSecurityClassification());

            // const resolver: DependencyResolver = new DependencyResolver();
            // const apiService: IApiService = resolver.ResolveIApiService();
            // setPdfFile(`${apiService.GetApiBaseUrl()}/api/GeneratePdfPack?rId=${record.recordId}&rName=${record.name}`);
        }
    }, [dispatch, record?.name, record?.recordId]);


    React.useEffect(() => {
        return () => {
            dispatch(resetWizardResponseInformation());
        };
    }, [dispatch]);

    React.useEffect(() => {
        const securityClassificationName: string | undefined | null = record?.securityClassification?.length ? record?.securityClassification[0].name : undefined;
        const securityClassificationTerms: ITerm[] = securityClassifications?.terms ? securityClassifications?.terms : [];
        const securityClassificationLabels: string[] =  securityClassificationTerms.map(sc => sc.localCustomProperties["SecurityClassificationLabel"]);
        const recordSecurityClassificationIndex: number = securityClassificationTerms  || securityClassificationName 
            ? securityClassificationTerms.findIndex(t => t.name === securityClassificationName)
            : 0;
        
        let highestIndex: number = 0;

        Object.values(docCollection).forEach(item => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.forEach((doc: any) => {
                const docLabelIndex: number = securityClassificationLabels.findIndex(t => t === doc.sensitivityLabel);
                if(docLabelIndex > recordSecurityClassificationIndex && highestIndex < docLabelIndex){
                    setHighestDocLabel(doc.sensitivityLabel);
                    highestIndex = docLabelIndex;
                }
            });
        });
    }, [docCollection, record?.securityClassification, securityClassifications]);

    const addRemoveDocuments: () => void = React.useCallback(() => {
        // Setting the manage record into edit mode.
        dispatch(setDocumentTabMode(FormMode.Edit));
        // Setting the templates, attachments and supporting documents from the data returned from API
        // const templates: ITemplate[] = (docCollection.deptWorkProducts || []).map(f => {
        //     return {
        //         ...f,
        //         url: f.contentURL || ""
        //     };
        // });
        // dispatch(
        //     setSelectedTemplates(templates)
        // );
        dispatch(setSelectedAttachments(docCollection.attachments || []));
        dispatch(
            setSelectedSupportingFiles(docCollection.supportingDocuments || [])
        );
        dispatch(setSelectedPrimaryFiles(docCollection.deptWorkProducts || []));
    },[dispatch, docCollection]);

    return (
        <div>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>{editDocumentsHeader}</h2>
            </div>
            <div>{t("docTabDescription")}</div>
            {isDocLoading && (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.medium}
                        label={t("docLoadingMessage")}
                        ariaLabel={"Loading spinner"}
                    />
                </div>
            )}
            {!isDocLoading && (
                <>
                    <div className={styles.manageRecordDocumentContainer}>
                        <div className={styles.buttonContainer}>
                            {!readOnly && (
                                <>
                                    <PrimaryButton
                                        text={isMobile ? t("addRemoveDocumentsMobileButtonText") : t("addRemoveDocumentsButtonText")}
                                        onClick={addRemoveDocuments}
                                        allowDisabledFocus
                                        disabled={false}
                                        className={styles.primaryButtonStyling}
                                        ariaLabel={`${t("addRemoveDocumentsButtonText")} button`} />
                                    {showAIDocumentGenerationOption && 
                                    <Link to={AppRoutes.buildUrl(AppRoutes.documentGeneration, {
                                        recordId: record?.recordId || ""
                                    })}>
                                       
                                        <DefaultButton
                                            text={"✨ " + t("generateDocumentWithAI")}
                                            className={styles.generateDocumentWithAIButtonStyling}
                                            ariaLabel={`${t("generateDocumentWithAI")} button`}
                                            disabled={false}
                                        />
                                    </Link>
                                    }
                                </>
                            )}
                            {record && (
                                <PdfPackButton
                                    text={t("downloadPDFPackText")}
                                    loadingText={t("generatingPDFPackText")}
                                    buttonClass={styles.buttonStyling}
                                    recordId={record.recordId}
                                    title={record.name}
                                    openText={t("openPDFPackText")}
                                    onError={(msg: string | undefined) => { setErrorMessage(msg); }}
                                />
                            )}
                            <div className={styles.errorContainer}>
                                {errorMessage 
                                    && <MessageBar messageBarType={MessageBarType.blocked} isMultiline={true} >
                                        {errorMessage}
                                    </MessageBar>
                                }
                                {highestDocLabel && 
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        {t("securityClassificationDocWarning")}
                                    </MessageBar>
                                }
                            </div>
                        </div>
                        <div className={styles.dwpNAttachContainer}>
                            <div className={styles.dwpSection}>
                                <h2 className={styles.sectionHeader}>
                                    {t("docTabDwpWorkProductHeader")}                            
                                    <TooltipHost content={t("wpHelpText")}>
                                        <Icon iconName="Info" className={styles.iconStyling} ariaLabel={"Information Icon"} />
                                    </TooltipHost>
                                </h2>
                                <div className={styles.sectionContent}>
                                    <DocumentLink
                                        files={docCollection?.deptWorkProducts || null}
                                        fileNotAvailableMessage={t("deptNotAvailableMessage")}
                                    ></DocumentLink>
                                </div>
                            </div>
                            <div className={styles.attachmentSection}>
                                <h2 className={styles.sectionHeader}>
                                    {t("docTabAttachmentsHeader")}
                                    <TooltipHost content={t("attachHelpText")}>
                                        <Icon iconName="Info" className={styles.iconStyling} ariaLabel={"Information Icon"} />
                                    </TooltipHost>
                                </h2>
                                <div className={styles.sectionContent}>
                                    <DocumentLink
                                        files={docCollection?.attachments || null}
                                        fileNotAvailableMessage={t("attachNotAvailableMessage")}
                                    ></DocumentLink>
                                </div>
                            </div>
                        </div>
                        <div className={styles.supportingDocContainer}>
                            <h2 className={styles.sectionHeader}>
                                {t("docTabSupportingDocumentHeader")}
                                <TooltipHost content={t("suppDocHelpText")}>
                                    <Icon iconName="Info" className={styles.iconStyling} ariaLabel={"Information Icon"} />
                                </TooltipHost>
                            </h2>
                            <div className={styles.sectionContent}>
                                <DocumentLink
                                    files={(record?.canCurrentUserViewDocumentsFromOtherUsers ? docCollection?.supportingDocuments : DocumentsHelper.filterDocumentsByAuthor(docCollection?.supportingDocuments, filterByUserUPN, filterByUserEmail) ) || null}
                                    fileNotAvailableMessage={t("suppDocNotAvailableMessage")}
                                ></DocumentLink>
                            </div>
                        </div>
                        {(isManageSignaturesStage(record)) && (
                            <div className={styles.signatureDocContainer}>
                                <SignedDocSignatureInstructionsPanel readOnly={readOnly} />
                            </div>
                        )}
                        <div className={styles.snapShotsContainer}>
                            <h2 className={styles.sectionHeader}>{t("docTabSnapshotsHeader")}</h2>
                            <div className={styles.sectionDescription}>{t("docTabSnapshotDescription")}</div>
                            <div className={styles.sectionContent}>
                                {!!docCollection?.snapshots && docCollection.snapshots.length > 0 &&
                                    <div className={styles.secondaryContainer}>
                                        {!isMobile 
                                            ? <SnapshotList snapshots={docCollection.snapshots} />
                                            : <SnapshotCards items={docCollection.snapshots} />
                                        }
                                    </div>
                                }
                                {!docCollection?.snapshots || docCollection.snapshots.length < 1 &&
                                    <DocumentLink
                                        files={null}
                                        fileNotAvailableMessage={t("snapshotDocNotAvailableMessage")}
                                    ></DocumentLink>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
