// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IBaseFile,
    IBaseRecordDocument,
    IFile,
    IFileOrder,
    ITemplate,
    Move,
    RecordDocumentType,
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { FileHelper } from "../../helpers/FileHelper";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export interface IWizardResponseTemplateStepState {
    selectedTemplates: ITemplate[];
    availableTemplates: ITemplate[];
    selectedSupportingDocuments: IFile[];
    selectedPrimaryDocuments: IFile[];
    selectedAttachments: IFile[];
    uploadedDocumets: IBaseRecordDocument[];
    uploadedFileOrder: IFileOrder;
    supportingDocLoading: boolean;
    primaryDocLoading: boolean;
    attachmentsLoading: boolean;
    templateLoading: boolean;
    attachmentUploadSuccess: boolean;
    supportingDocUplaodSuccess: boolean;
    primaryDocUploadSuccess: boolean;
    errorMessages?: string[];
}

export const initialState: IWizardResponseTemplateStepState = {
    selectedTemplates: [],
    availableTemplates: [],
    selectedSupportingDocuments: [],
    selectedPrimaryDocuments: [],
    uploadedDocumets:[],
    selectedAttachments:[],
    uploadedFileOrder: {
        deptWorkProductsOrder: [],
        attachmentsOrder: [],
        supportingDocumentsOrder: []
    },
    supportingDocLoading: false,
    primaryDocLoading: false,
    attachmentsLoading: false,
    templateLoading: false,
    errorMessages: undefined,
    attachmentUploadSuccess: false,
    supportingDocUplaodSuccess: false,
    primaryDocUploadSuccess: false
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const wizardResponseTemplateSlice = createSlice({
    name: "wizardResponseTemplate",
    initialState,
    reducers: {
        setAvailableTemplates: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<ITemplate[] | null>
        ) => {
            state.availableTemplates = action.payload || [];
        },
        addSelectedTemplates: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<ITemplate>
        ) => {
            state.selectedTemplates.push(action.payload);
        },
        setSelectedTemplates: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<ITemplate[]>
        ) => {
            state.selectedTemplates = action.payload;
        },
        setErrorMessages: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<string[] | undefined>
        ) => {
            state.errorMessages = action.payload;
        },
        removeTemplate: (state: IWizardResponseTemplateStepState, action: PayloadAction<ITemplate>) => {
            const templateIndex: number = state.selectedTemplates.findIndex(
                t => t.name == action.payload.name && t.url == action.payload.url
            );
            if (templateIndex > -1) {
                state.selectedTemplates.splice(templateIndex, 1);
            }
        },
        resetWizardResponseTemplateStep: (state: IWizardResponseTemplateStepState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardResponseTemplateStepState = key as keyof IWizardResponseTemplateStepState;

                if (
                    keyAs == "supportingDocLoading" ||
                    keyAs == "templateLoading" || 
                    keyAs == "primaryDocLoading"
                ){
                    state[keyAs] = false;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    state[keyAs] = initialState[keyAs] as any;
                }
            }
        },

        addUploadedDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<IBaseRecordDocument>) => {
            state.uploadedDocumets.push(action.payload);
        },
        removeUploadedDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<[string, RecordDocumentType]>) => {
            const fileIndex: number = state.uploadedDocumets.findIndex(
                t => t.name == action.payload[0] && t.type == action.payload[1]
            );
            if (fileIndex > -1) {
                state.uploadedDocumets.splice(fileIndex, 1);
            }
        },
        
        editUploadedDoc: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[string, RecordDocumentType]>
        ) => {
            const fileIndex: number = state.uploadedDocumets.findIndex(
                t => t.name == action.payload[0] && t.type == action.payload[1]
            );
            if (fileIndex > -1) {
                const updatedFile: IBaseRecordDocument = {
                    ...state.uploadedDocumets[fileIndex],
                    name: action.payload[0],
                };
                state.uploadedDocumets.splice(fileIndex, 1, updatedFile);
            }

        },
        
        
        addSelectedAttachments: (state: IWizardResponseTemplateStepState, action: PayloadAction<IFile>) => {
            state.selectedAttachments.push(action.payload);
            state.uploadedFileOrder.attachmentsOrder.push(action.payload.name);
        },
        removeSelectedAttachments: (state: IWizardResponseTemplateStepState, action: PayloadAction<string>) => {
            const fileIndex: number = state.selectedAttachments.findIndex(
                t => t.name == action.payload
            );
            if (fileIndex > -1) {
                state.selectedAttachments.splice(fileIndex, 1);
            }

            //Updating the order
            state.uploadedFileOrder.attachmentsOrder = [];
            state.selectedAttachments.map(e => {
                state.uploadedFileOrder.attachmentsOrder.push(e.name);
            });
        },
        
        editSelectedAttachments: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<string[]>
        ) => {
            let fileIndex: number = state.selectedAttachments.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: IFile = {
                    ...state.selectedAttachments[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedAttachments.splice(fileIndex, 1, updatedFile);
            }

            //Updating the order
            state.uploadedFileOrder.attachmentsOrder = [];
            state.selectedAttachments.map(e => {
                state.uploadedFileOrder.attachmentsOrder.push(e.name);
            });

            //Updating upload documents
            fileIndex = state.uploadedDocumets.findIndex(t => t.name === action.payload[0]);
            if(fileIndex > -1){
                state.uploadedDocumets[fileIndex].name = action.payload[1] != undefined ? action.payload[1] : "";
            }
        },
        addSelectedSupportingDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<IFile>) => {
            state.selectedSupportingDocuments.push(action.payload);
            state.uploadedFileOrder.supportingDocumentsOrder.push(action.payload.name);
        },
        removeSelectedSupportingDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<string>) => {
            const fileIndex: number = state.selectedSupportingDocuments.findIndex(
                t => t.name == action.payload
            );
            if (fileIndex > -1) {
                state.selectedSupportingDocuments.splice(fileIndex, 1);
            }

            //Updating the order
            state.uploadedFileOrder.supportingDocumentsOrder = [];
            state.selectedSupportingDocuments.map(e => {
                state.uploadedFileOrder.supportingDocumentsOrder.push(e.name);
            });
        },
        
        editSelectedSupportingDoc: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<string[]>
        ) => {
            let fileIndex: number = state.selectedSupportingDocuments.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: IFile = {
                    ...state.selectedSupportingDocuments[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedSupportingDocuments.splice(fileIndex, 1, updatedFile);
            }

            //Updating the order
            state.uploadedFileOrder.supportingDocumentsOrder = [];
            state.selectedSupportingDocuments.map(e => {
                state.uploadedFileOrder.supportingDocumentsOrder.push(e.name);
            });

            //Updating upload documents
            fileIndex = state.uploadedDocumets.findIndex(t => t.name === action.payload[0]);
            if(fileIndex > -1){
                state.uploadedDocumets[fileIndex].name = action.payload[1] != undefined ? action.payload[1] : "";
            }
        },
        addSelectedPrimaryDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<IFile>) => {
            state.selectedPrimaryDocuments.push(action.payload);
            state.uploadedFileOrder.deptWorkProductsOrder.push(action.payload.name);
        },
        removeSelectedPrimaryDoc: (state: IWizardResponseTemplateStepState, action: PayloadAction<string>) => {
            const fileIndex: number = state.selectedPrimaryDocuments.findIndex(
                t => t.name == action.payload
            );
            if (fileIndex > -1) {
                state.selectedPrimaryDocuments.splice(fileIndex, 1);
            }

            //Updating the order
            state.uploadedFileOrder.deptWorkProductsOrder = [];
            state.selectedPrimaryDocuments.map(e => {
                state.uploadedFileOrder.deptWorkProductsOrder.push(e.name);
            });
        },
        editSelectedPrimaryDoc: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<string[]>
        ) => {
            let fileIndex: number = state.selectedPrimaryDocuments.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: IFile = {
                    ...state.selectedPrimaryDocuments[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedPrimaryDocuments.splice(fileIndex, 1, updatedFile);
            }

            //Updating the order
            state.uploadedFileOrder.deptWorkProductsOrder = [];
            state.selectedPrimaryDocuments.map(e => {
                state.uploadedFileOrder.deptWorkProductsOrder.push(e.name);
            });

            //Updating upload documents
            fileIndex = state.uploadedDocumets.findIndex(t => t.name === action.payload[0]);
            if(fileIndex > -1){
                state.uploadedDocumets[fileIndex].name = action.payload[1] != undefined ? action.payload[1] : "";
            }
        },
        editSelectedTemplate: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<string[]>
        ) => {
            let fileIndex: number = state.selectedTemplates.findIndex(
                t => t.name == action.payload[0]
            );
            if (fileIndex > -1) {
                const updatedFile: ITemplate = {
                    ...state.selectedTemplates[fileIndex],
                    name: action.payload[1] != undefined ? action.payload[1] : "",
                };
                state.selectedTemplates.splice(fileIndex, 1, updatedFile);
            }

            //Updating the order
            state.uploadedFileOrder.deptWorkProductsOrder = [];
            state.selectedSupportingDocuments.map(e => {
                state.uploadedFileOrder.deptWorkProductsOrder.push(e.name);
            });

            //Updating upload documents
            fileIndex = state.uploadedDocumets.findIndex(t => t.name === action.payload[0]);
            if(fileIndex > -1){
                state.uploadedDocumets[fileIndex].name = action.payload[1] != undefined ? action.payload[1] : "";
            }
        },
        moveSelectedSupportingDoc: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedSupportingDocuments.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: IFile = {
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                    contentURL: state.selectedSupportingDocuments[fileIndex].contentURL,
                    path: state.selectedSupportingDocuments[fileIndex].path
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: IFile = state.selectedSupportingDocuments[fileIndex - 1];
                    state.selectedSupportingDocuments.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: IFile = state.selectedSupportingDocuments[fileIndex + 1];
                    state.selectedSupportingDocuments.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }

            //Updating the order
            state.uploadedFileOrder.supportingDocumentsOrder = [];
            state.selectedSupportingDocuments.map(e => {
                state.uploadedFileOrder.supportingDocumentsOrder.push(e.name);
            });
        },
        moveSelectedPrimaryDoc: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedPrimaryDocuments.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: IFile = {
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                    contentURL: state.selectedPrimaryDocuments[fileIndex].contentURL,
                    path: state.selectedPrimaryDocuments[fileIndex].path
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: IFile = state.selectedPrimaryDocuments[fileIndex - 1];
                    state.selectedPrimaryDocuments.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: IFile = state.selectedPrimaryDocuments[fileIndex + 1];
                    state.selectedPrimaryDocuments.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }

            //Updating the order
            state.uploadedFileOrder.deptWorkProductsOrder = [];
            state.selectedPrimaryDocuments.map(e => {
                state.uploadedFileOrder.deptWorkProductsOrder.push(e.name);
            });
        },
        moveSelectedAttachments: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedAttachments.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: IFile = {
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                    contentURL: state.selectedAttachments[fileIndex].contentURL,
                    path: state.selectedAttachments[fileIndex].path
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: IFile = state.selectedAttachments[fileIndex - 1];
                    state.selectedAttachments.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: IFile = state.selectedAttachments[fileIndex + 1];
                    state.selectedAttachments.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }

            //Updating the order
            state.uploadedFileOrder.attachmentsOrder = [];
            state.selectedAttachments.map(e => {
                state.uploadedFileOrder.attachmentsOrder.push(e.name);
            });
        },
        moveTemplate: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[IFile, Move]>
        ) => {
            const fileIndex: number = state.selectedTemplates.findIndex(
                t => t.name == action.payload[0].name
            );
            if (fileIndex > -1) {
                const movedFile: ITemplate = {
                    name: action.payload[0] != undefined ? action.payload[0].name : "",
                    url: state.selectedTemplates[fileIndex].url,
                    fullUrl: state.selectedTemplates[fileIndex].fullUrl
                };
                if (action.payload[1] == Move.Up) {
                    const moveDownFile: ITemplate = state.selectedTemplates[fileIndex - 1];
                    state.selectedTemplates.splice(fileIndex - 1, 2, movedFile, moveDownFile);
                } else {
                    const moveUpFile: ITemplate = state.selectedTemplates[fileIndex + 1];
                    state.selectedTemplates.splice(fileIndex, 2, moveUpFile, movedFile);
                }
            }

            //Updating the order
            state.uploadedFileOrder.deptWorkProductsOrder = [];
            state.selectedTemplates.map(e => {
                state.uploadedFileOrder.deptWorkProductsOrder.push(e.name);
            });
        },
        setComponentLoading: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[RecordDocumentType, boolean]>
        ) => {
            switch (action.payload[0]) {
                case RecordDocumentType.supportingDocument:
                    state.supportingDocLoading = action.payload[1];
                    break;
                case RecordDocumentType.attachment:
                    state.attachmentsLoading = action.payload[1];
                    break;
                case RecordDocumentType.departmentWorkProduct:
                    state.primaryDocLoading = action.payload[1];
                    break;
            }
        },
        setIsUplaodSuccess:(
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<[RecordDocumentType, boolean]>
        ) => {
            switch (action.payload[0]) {
                case RecordDocumentType.attachment:
                    state.attachmentUploadSuccess = action.payload[1];
                    break;
                case RecordDocumentType.supportingDocument:
                    state.supportingDocUplaodSuccess = action.payload[1];
                    break;
                case RecordDocumentType.departmentWorkProduct:
                    state.primaryDocUploadSuccess = action.payload[1];
                    break;
            }
        },
        setTemplatesLoading: (
            state: IWizardResponseTemplateStepState,
            action: PayloadAction<boolean>
        ) => {
            state.templateLoading = action.payload;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    addSelectedTemplates,
    removeTemplate,
    resetWizardResponseTemplateStep,
    editSelectedPrimaryDoc,
    editSelectedSupportingDoc,
    editSelectedAttachments,
    editSelectedTemplate,
    moveSelectedPrimaryDoc,
    moveSelectedSupportingDoc,
    moveSelectedAttachments,
    setSelectedTemplates,
    moveTemplate,
} = wizardResponseTemplateSlice.actions;

const {
    removeUploadedDoc,
    removeSelectedPrimaryDoc,
    removeSelectedSupportingDoc,
    addSelectedPrimaryDoc,
    addSelectedSupportingDoc,
    removeSelectedAttachments,
    addSelectedAttachments,
    addUploadedDoc,
    setTemplatesLoading,
    setComponentLoading,
    setIsUplaodSuccess,
    setAvailableTemplates,
    setErrorMessages
} = wizardResponseTemplateSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched


export const getWizardResponseTemplateStepDataAsync = (
    selectedRecordSubTypes: string[]
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    dispatch(setTemplatesLoading(true));
    let recordTypeTemplates: ITemplate[] = [];
    const requestsArray: Promise<ITemplate[] | null>[] = selectedRecordSubTypes
        .map(recortType => apiService.GetAvailableTemplates(recortType)
            .then(templates => recordTypeTemplates = [...recordTypeTemplates, ...templates || []]));
    await Promise.all(requestsArray);
    recordTypeTemplates = recordTypeTemplates
        .filter((value, index, selfArray) => selfArray.findIndex(t => t.name === value.name) === index);
    dispatch(setTemplatesLoading(false));
    dispatch(setAvailableTemplates(recordTypeTemplates));
};


export const deleteFileAsync = (
    item: IFile,
    type: RecordDocumentType
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([type, true]));
    // Delete the record document from SharePoint
    const retVal: boolean = await apiService.DeleteFileFromPersonalStorage({
        name: item.name,
        path: item.path
    });

    // Remove file from the state
    if (retVal) {
        dispatch(removeUploadedDoc([item.name, type]));
        dispatch(removeSelectedSupportingDoc(item.name));
        dispatch(removeSelectedPrimaryDoc(item.name));
        dispatch(removeSelectedAttachments(item.name));
    }
    dispatch(setComponentLoading([type, false]));
    return retVal;
};

export const uploadFilesAsync = (
    files: IFile[],
    type: RecordDocumentType
): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setComponentLoading([type, true]));
    dispatch(setErrorMessages(undefined));

    const promises: Promise<void>[] = [];
    let errorMessages: string[] | undefined = undefined;

    for(const file of files) {
        let binary: string = "";
        if (file.contentURL) {
            binary = await FileHelper.fileContentAsBase64(file.contentURL);

        }

        const p: Promise<void> = apiService.UploadFileToPersonalStorage({
            ...file,
            contentURL: file.contentURL ? binary : undefined
        }).then((retVal: IBaseFile | null) => {
            // Update the state
            if (retVal) {
                dispatch(addUploadedDoc({...retVal, name: file.name, type: type}));
                switch (type) {
                    case RecordDocumentType.supportingDocument:
                        file.path = retVal.path;
                        dispatch(addSelectedSupportingDoc(file));
                        break;
                    case RecordDocumentType.departmentWorkProduct:
                        file.path = retVal.path;
                        dispatch(addSelectedPrimaryDoc(file));
                        break;
                    case RecordDocumentType.attachment:
                        file.path = retVal.path;
                        dispatch(addSelectedAttachments(file));
                        break;
                }
            } else {
                errorMessages = errorMessages || [];
                errorMessages.push(`Failed to upload file: ${file.name}`);
            }
        });
        promises.push(p);
    }
    
    await Promise.all(promises);
    
    dispatch(setComponentLoading([type, false]));
    dispatch(setErrorMessages(errorMessages));
    if(!errorMessages) {
        dispatch(setIsUplaodSuccess([type, true]));
        setTimeout(() => {
            dispatch(setIsUplaodSuccess([type, false]));
        }, 3000);
    }
};

// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const userSelectedTemplates = (state: RootState): ITemplate[] =>
    state.wizardResponseTemplateStep.selectedTemplates;

export const selectAvailableTemplates = (state: RootState): ITemplate[] =>
    state.wizardResponseTemplateStep.availableTemplates;

export const uploadedDocs = (state: RootState): IBaseRecordDocument[] =>
    state.wizardResponseTemplateStep.uploadedDocumets;

export const selectedSupportingDocuments = (state: RootState): IFile[] =>
    state.wizardResponseTemplateStep.selectedSupportingDocuments;

export const selectedPrimaryDocuments = (state: RootState): IFile[] =>
    state.wizardResponseTemplateStep.selectedPrimaryDocuments;

export const userSelectedAttachments = (state: RootState): IFile[] =>
    state.wizardResponseTemplateStep.selectedAttachments;

export const uploadedFileOrder = (state: RootState): IFileOrder =>
    state.wizardResponseTemplateStep.uploadedFileOrder;

export const supportingDocLoading = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.supportingDocLoading;

export const primaryDocLoading = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.primaryDocLoading;

export const attachmentsLoading = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.attachmentsLoading;

export const supportingDocUplaodSuccess = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.supportingDocUplaodSuccess;

export const primaryDocUplaodSuccess = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.primaryDocUploadSuccess;

export const attachmentsUplaodSuccess = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.attachmentUploadSuccess;

export const templateLoading = (state: RootState): boolean =>
    state.wizardResponseTemplateStep.templateLoading;

export const selectErrorMessages = (state: RootState): string[] | undefined =>
    state.wizardResponseTemplateStep.errorMessages;

// 6. EXPORT REDUCER
export default wizardResponseTemplateSlice.reducer;
