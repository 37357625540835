// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import { IContentType } from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import ILogProvider from "../../providers/Log/ILogProvider";
import { setErrorText } from "../../app/globalSlices/contextSlice";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// 3. FEATURE STATE
export interface IWizardCreateRecordState {
    recordTypes: IContentType[] | null;
    selectedRecordType: IContentType | null;
    selectedRecordCategory: string | null;
}

// 4. FEATURE INITIAL STATE
export const initialState: IWizardCreateRecordState = {
    recordTypes: null,
    selectedRecordType: null,
    selectedRecordCategory: null
};

// 4. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const wizardCreateRecordSlice = createSlice({
    name: "wizardCreateRecord",
    initialState,
    reducers: {
        resetWizardCreateRecord: (state: IWizardCreateRecordState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardCreateRecordState = key as keyof IWizardCreateRecordState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state[keyAs] = initialState[keyAs] as any;
            }
        },
        setRecordTypeTermSet: (
            state: IWizardCreateRecordState,
            action: PayloadAction<IContentType[] | null>
        ) => {
            state.recordTypes = action.payload;
        },
        setSelectedRecordCategory: (
            state: IWizardCreateRecordState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedRecordCategory = null;
            if (!action || !action.payload) {
                state.selectedRecordCategory = null;
            } else {
                state.selectedRecordCategory = action.payload;
            }
        },
        setSelectedRecordType: (
            state: IWizardCreateRecordState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.selectedRecordType = null;
            } else {
                const recordTypes: IContentType[] = state.recordTypes || [];
                for (let i: number = 0; i < recordTypes.length; i++) {
                    const term: IContentType = recordTypes[i];
                    if (term.rowKey === action.payload) {
                        state.selectedRecordType = term;
                        return;
                    }
                }
            }
        }
    }
});

// 4. SYNCRONOUS ACTIONS
const { setRecordTypeTermSet } = wizardCreateRecordSlice.actions;
export const {
    resetWizardCreateRecord,
    setSelectedRecordCategory,
    setSelectedRecordType,
} = wizardCreateRecordSlice.actions;

// 5. ASYNCRONOUS ACTIONS
export const getWizardCreateRecordDataAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const customRecordTypes: IContentType[] | null = await apiService.GetContentTypes();

    dispatch(setRecordTypeTermSet(customRecordTypes));
};

export const  getRecordTypeWorkflowDiagramAsync = (recordType: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const logger: ILogProvider = resolver.ResolveILogProvider();
    try{
        const optionsResponse: string | null = await apiService.GetWorkflowDiagram(recordType); 
        logger.Info("getRecordTypeWorkflowDiagramAsync",optionsResponse || "");
    }  catch (e) {
        if (typeof e === "string") {
            dispatch(setErrorText(e));
        } else if (e instanceof Error) {
            dispatch(setErrorText(e.message));
        }
    }
};

// 6. SELECTORS
export const selectSelectedRecordCategory = (state: RootState): string | null =>
    state.wizardCreateRecord.selectedRecordCategory;
export const selectSelectedRecordType = (state: RootState): IContentType | null =>
    state.wizardCreateRecord.selectedRecordType;
export const selectRecordTypesByCategory = (state: RootState): IContentType[] => {
    const selectedRecordCategory: string | null = selectSelectedRecordCategory(state);
    if (selectedRecordCategory) {
        return state.wizardCreateRecord.recordTypes?.filter(v => v.category === selectedRecordCategory) || [];
    }
    return [];
};
export const selectAllRecordTypes = (state: RootState): IContentType[] =>
    state.wizardCreateRecord.recordTypes || [];

// 6. EXPORT REDUCER
export default wizardCreateRecordSlice.reducer;
