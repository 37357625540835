import ILogProvider from "./ILogProvider";
import IConsole from "./IConsole";

export default class ConsoleLogProvider implements ILogProvider {
    private consolelog: IConsole;
    constructor(consolelog: IConsole = window.console) {
        this.consolelog = consolelog;
    }

    public async Debug(
        className: string,
        message: string,
        json: unknown = undefined
    ): Promise<void> {
        const jsonString: string = this.getJsonString(json);
        this.internalLog(this.consolelog.debug, className, message, jsonString);
    }
    public async Info(
        className: string,
        message: string,
        json: unknown = undefined
    ): Promise<void> {
        const jsonString: string = this.getJsonString(json);
        this.internalLog(this.consolelog.info, className, message, jsonString);
    }
    public async Warning(
        className: string,
        message: string,
        json: unknown = undefined
    ): Promise<void> {
        const jsonString: string = this.getJsonString(json);
        this.internalLog(this.consolelog.warn, className, message, jsonString);
    }
    public async Error(
        className: string,
        message: string,
        json: unknown = undefined
    ): Promise<void> {
        const jsonString: string = this.getJsonString(json);
        this.internalLog(this.consolelog.error, className, message, jsonString);
    }

    private getJsonString(json: unknown): string {
        let jsonString: string = `${json}`;
        if (json instanceof Error) {
            jsonString = `message: ${json.message}, stack: ${json.stack}`;
        } else if (typeof json === "object") {
            jsonString = json ? JSON.stringify(json) : "";
        } else if (json === undefined || json === null) {
            jsonString = "";
        }
        return jsonString;
    }

    private internalLog(
        log: (message: string) => void,
        className: string,
        message: string,
        jsonString: string
    ): void {
        const logMessage: string = `${className}, ${message}, ${jsonString}`;
        log(logMessage);
    }
}
