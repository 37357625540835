import { Checkbox, Icon, TextField } from "@fluentui/react";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import ModalContainer from "../../../components/FormControls/ModalContainer/ModalContainer";
import IconHelper from "../../../helpers/IconHelper";
import {
    signatureInstructFormCheckboxLabel,
    signatureInstructFormDescription,
    signatureInstructFormDocumentsHeader,
    signatureInstructFormHeader,
    signatureInstructFormSubmitBtnLabel,
    signatureInstructFormTextAreaLabel
} from "../../../providers/Constants/ManageRecordConstants";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import { IFile, IRecord } from "../../../services/Api/executor/IApiServiceExecutor";
import IApiService from "../../../services/Api/IApiService";
import { selectCurrentRecord, getIWantToOptionsAsync } from "../manageRecordWrapperSlice";
import { updateRecordDocuments } from "../../manageRecordEditDocuments/manageRecordEditDocumentsSlice";
import styles from "./SignatureInstructionsForm.module.scss";

interface ISignatureInstructionsFormProps {
    documents?: IFile[];
    onCancel: () => void;
}

export function SignatureInstructionsForm({
    documents,
    onCancel
}: ISignatureInstructionsFormProps): JSX.Element {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [docs, setDocs] = React.useState<IFile[] | undefined>(documents);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const onConfirm: () => Promise<void> = React.useCallback(async () => {
        try {
            setInProgress(true);
            if (docs && currentRecord?.recordId) {
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                await apiService.UpdateDocumentSignatures({
                    recordId: currentRecord.recordId,
                    signatures: docs
                });
                dispatch(updateRecordDocuments(docs));

                // force i want to options to be refetched as they may have changed due to upate
                dispatch(getIWantToOptionsAsync(currentRecord?.recordId));
            }
            onCancel();
        } finally {
            setInProgress(false);
        }
    }, [currentRecord?.recordId, dispatch, onCancel, docs]);

    const setSignatureRequired: (documentId: string, value: boolean) => void = React.useCallback(
        (documentId: string, value: boolean): void => {
            const documentsData: IFile[] = [...(docs || [])];
            const idx: number = documentsData.findIndex(docItem => docItem.id === documentId);
            const editableDocument: IFile | undefined = documentsData.find(
                docItem => docItem.id === documentId
            );
            if (idx > -1 && editableDocument) {
                documentsData.splice(idx, 1, {
                    ...documentsData[idx],
                    signatureRequired: value,
                    signatureInstructions:
                        !value && editableDocument.signatureInstructions
                            ? undefined
                            : editableDocument.signatureInstructions
                });
            }
            return setDocs(documentsData);
        },
        [docs]
    );

    const setDocumentInstructions: (documentId: string, value?: string) => void = React.useCallback(
        (documentId: string, value?: string): void => {
            const documentsData: IFile[] = [...(docs || [])];
            const idx: number = documentsData.findIndex(docItem => docItem.id === documentId);
            if (idx > -1) {
                documentsData.splice(idx, 1, {
                    ...documentsData[idx],
                    signatureInstructions: value
                });
            }
            return setDocs(documentsData);
        },
        [docs]
    );

    return (
        <ModalContainer
            className={styles.formContainer}
            title={signatureInstructFormHeader}
            submitLabel={signatureInstructFormSubmitBtnLabel}
            isOpen={true}
            inProgress={inProgress}
            submitDisabled={inProgress}
            onSubmit={onConfirm}
            onCancel={onCancel}
        >
            <>
                <div className={styles.descriptionForm}>{signatureInstructFormDescription}</div>
                {docs && (
                    <div className={styles.documentsSection}>
                        <div className={styles.documentsSectionHeader}>
                            {signatureInstructFormDocumentsHeader}
                        </div>
                        <div className={styles.documentsContainer}>
                            {docs.map((doc, index) => {
                                return (
                                    <div
                                        className={styles.documentItem}
                                        key={doc.name + index || doc.id}
                                    >
                                        <div className={styles.documentItemCheckboxContainer}>
                                            <div className={styles.iconColumnContainer}>
                                                <Icon
                                                    className={styles.iconColumnIcon}
                                                    iconName={IconHelper.GetIconOfficeUIFabricName(
                                                        doc.name
                                                    )}
                                                />
                                                <div className={styles.iconColumnText}>
                                                    {doc.name}
                                                </div>
                                            </div>
                                            <div className={styles.checkboxContainer}>
                                                <Checkbox
                                                    label={signatureInstructFormCheckboxLabel}
                                                    disabled={inProgress}
                                                    defaultChecked={doc.signatureRequired}
                                                    onChange={(
                                                        ev?:
                                                            | React.FormEvent<
                                                                  HTMLElement | HTMLInputElement
                                                              >
                                                            | undefined,
                                                        checked?: boolean | undefined
                                                    ) => {
                                                        if (
                                                            typeof checked !== "undefined" &&
                                                            doc.id
                                                        ) {
                                                            setSignatureRequired(
                                                                doc.id,
                                                                checked
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {doc.signatureRequired && (
                                            <TextField
                                                className={styles.textFieldInstructions}
                                                disabled={inProgress}
                                                label={signatureInstructFormTextAreaLabel}
                                                multiline
                                                rows={5}
                                                defaultValue={doc.signatureInstructions}
                                                onChange={(
                                                    event: React.FormEvent<
                                                        HTMLInputElement | HTMLTextAreaElement
                                                    >,
                                                    newValue?: string | undefined
                                                ) => {
                                                    if (doc.id) {
                                                        setDocumentInstructions(
                                                            doc.id,
                                                            newValue
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </>
        </ModalContainer>
    );
}
