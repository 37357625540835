import React from "react";
import {
    DefaultButton,
    Icon,
    IIconProps,
    Link,
    Spinner,
    SpinnerSize,
    TextField,
    Panel, 
    PanelType
} from "@fluentui/react";
import styles from "./FileList.module.scss";
import ModalContainer from "../ModalContainer/ModalContainer";
import {
    FormMode,
    IFile,
    Move
} from "../../../services/Api/executor/IApiServiceExecutor";
import {
    FileDeleteModalConfirmationMessage,
    FileDeleteModalConfirmButtonText,
    FileDeleteModalTitle,
    TemplateDeleteModalConfirmationMessage,
    TemplateDeleteModalTitle,
    TemplateDeleteModalConfirmButtonText,
    FileEditModalConfirmButtonText,
    FileEditModalTitle,
    FileRenameValidationMessage,
    UpdateSignedDocButtonText,
    ButtonAriaLabel,
    IconAriaLabel,
    SpinnerAriaLabel,
    TextFieldAriaLabel
} from "../../../providers/Constants/FormControlConstants";
import { reservedCharsForFileName } from "../../../helpers/StringHelper";
import { DocumentsHelper } from "../../../helpers/DocumentsHelper";

// Component properties
interface IFileListProps {
    selectedFiles: IFile[];
    onFileRemoveClick: (delFile: IFile) => void;
    onFileEditClick: (editFile: string[]) => void;
    onFileUpdateClick: (editFile: IFile) => void;
    onFileReorder: (movedFile: IFile, movement: number) => void;
    onLoading: boolean;
    onLoadingText: string;
    componentMode: FormMode;
    templateMode?: boolean;
    canEdit: boolean;
    canUploadSigned: boolean;
    canRemove: boolean;
    filterByUserUPN?: string;
    filterByUserEmail?: string;
}

// File list component. Displays a list of files.
export default function FileList(props: IFileListProps): JSX.Element {
    const txtFileRequired: boolean = true;
    const [errorMessage, setErrorMessage] = React.useState("");
    const deleteIconProps: IIconProps = { iconName: "Delete", className: styles.iconBlue };
    const editIconProps: IIconProps = { iconName: "Edit", className: styles.iconBlue };
    const updateIconProps: IIconProps = { iconName: "PageEdit", className: styles.iconBlue};
    const menuIcon: string = "MoreVertical";
    const moveUpIcon: string = "ChevronUp";
    const moveDownIcon: string = "ChevronDown";
    const signedDocIcon: string = "PageEdit";

    const [modalOpen, setModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalSubmitLabel, setModalSubmitLabel] = React.useState("");
    const [modalContent, setModalContent] = React.useState("");
    const [currentFile, setCurrentFile] = React.useState<IFile>({ name: "", contentURL: "", path: "" });
    const [filename, setFilename] = React.useState("");
    const [editDisabled, setEditDisabled] = React.useState(false);

    // Mobile State
    const [showMobielPanel, setShowMobielPanel] = React.useState<boolean>(false);

    // Closes the modal window.
    const dismissModal = () => {
        setErrorMessage("");
        setEditDisabled(false);
        setModalOpen(false);
        setDeleteModalOpen(false);
    };

    // Opens the delete file modal window
    const deleteFile = () => {
        props.onFileRemoveClick(currentFile);
        setDeleteModalOpen(false);
    };

    // Opens the edit file modal window
    const editFile = () => {
        const fileNames: string[] = [currentFile.name, filename];
        props.onFileEditClick(fileNames);
        setModalOpen(false);
    };

    // Validates the filename in edit file modal window.
    const validateFilename = (value: string): boolean => {
        let validFileName: boolean = true;
        reservedCharsForFileName.map(e => {
            validFileName = value.trim().indexOf(e) > -1 ? false : validFileName;
        });
        if (value.trim() == "" || !validFileName) {
            setErrorMessage(FileRenameValidationMessage);
            setEditDisabled(true);
        } else {
            setErrorMessage("");
            setEditDisabled(false);
        }
        return editDisabled;
    };

    const deleteLabel: string = "Delete";
    const removelabel: string = "Remove signed doc";

    return (
        <div className={styles.fileListContainer}>
            {props.onLoading && (
                <Spinner
                    className={styles.spinner}
                    size={SpinnerSize.small}
                    label={props.onLoadingText}
                    ariaLabel={SpinnerAriaLabel}
                />
            )}
            {!props.onLoading &&
                props.selectedFiles &&
                props.selectedFiles.length > 0 &&
                (props.filterByUserUPN 
                    ? DocumentsHelper.filterDocumentsByAuthor(props.selectedFiles.filter(f => !!f), props.filterByUserUPN, props.filterByUserEmail) || []
                    : props.selectedFiles.filter(f => !!f))
                    .map(file => (
                        <div key={file.name} className={styles.selectedFile}>
                            {!props.templateMode && !!props.canEdit &&
                                <div className={styles.reorderContainer}>
                                    <a
                                        href=""
                                        className={styles.reorder}
                                        title="Move up"
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            props.onFileReorder(
                                                { name: file.name, contentURL: file.contentURL, path: file.path },
                                                Move.Up
                                            );
                                        }}
                                    >
                                        <Icon iconName={moveUpIcon} className={styles.chevron} ariaLabel={`${"Move up"} ${IconAriaLabel}`}></Icon>
                                    </a>
                                    <a
                                        href=""
                                        className={styles.reorder}
                                        title="Move down"
                                        onClick={e => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            props.onFileReorder(
                                                { name: file.name, contentURL: file.contentURL, path: file.path },
                                                Move.Down
                                            );
                                        }}
                                    >
                                        <Icon iconName={moveDownIcon} className={styles.chevron} ariaLabel={`${"Move down"} ${IconAriaLabel}`}></Icon>
                                    </a>
                                </div>  
                            }
                            <div className={styles.selectedFilesContainer}>
                                {file.documentReference && 
                                    <div className={styles.fileSignedIcon}>
                                        <Icon iconName={signedDocIcon} className={styles.editIcon} ariaLabel={`${"Document signed"} ${IconAriaLabel}`}></Icon>
                                    </div>
                                }
                                <Link
                                    href={props.componentMode == FormMode.New ? "#" : file.contentURL}
                                    className={styles.fileNameStyle}
                                    rel="noreferrer"
                                    target="_blank"
                                    disabled={props.componentMode == FormMode.New ? true : false}
                                >
                                    {file.name}
                                </Link>
                            </div>
                            <div className={styles.buttonContainer}>
                                {file.signatureRequired && !!props.canUploadSigned &&
                                    <DefaultButton
                                        text={UpdateSignedDocButtonText}
                                        onClick={() => {
                                            props.onFileUpdateClick(file);
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        iconProps={updateIconProps}
                                        className={styles.buttonStyling}
                                        ariaLabel={`${ButtonAriaLabel}: ${UpdateSignedDocButtonText}`}
                                    />
                                }
                                {!props.templateMode && (!!props.canEdit || !!props.canUploadSigned) && (
                                    <DefaultButton
                                        text="Edit name"
                                        onClick={() => {
                                            setModalTitle(FileEditModalTitle);
                                            setModalSubmitLabel(FileEditModalConfirmButtonText);
                                            setCurrentFile({...file});
                                            setModalOpen(true);
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        iconProps={editIconProps}
                                        className={styles.buttonStyling}
                                        ariaLabel={`${ButtonAriaLabel}: ${"Edit name"}`}
                                    />
                                )}
                                {(!!props.canEdit || (!!props.canUploadSigned && file.documentReference)) && !!props.canRemove &&
                                    <DefaultButton
                                        text={file.documentReference ? removelabel : deleteLabel}
                                        onClick={() => {
                                            setModalTitle(!props.templateMode ? FileDeleteModalTitle : TemplateDeleteModalTitle);
                                            setModalSubmitLabel(!props.templateMode ? FileDeleteModalConfirmButtonText : TemplateDeleteModalConfirmButtonText);
                                            setModalContent(!props.templateMode ? FileDeleteModalConfirmationMessage : TemplateDeleteModalConfirmationMessage);
                                            setCurrentFile({...file});
                                            setDeleteModalOpen(true);
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        iconProps={deleteIconProps}
                                        className={styles.buttonStyling}
                                        ariaLabel={`${ButtonAriaLabel}: ${file.documentReference ? removelabel : deleteLabel}`}
                                    />
                                }
                            </div>
                            <div className={styles.buttonContainerMobile}>
                                <div 
                                    className={styles.menuButton}
                                    onClick={() => setShowMobielPanel(true)}
                                >
                                    <Icon iconName={menuIcon} className={styles.moreIcon} ariaLabel={`${"More options"} ${IconAriaLabel}`}/>
                                </div>
                                <Panel 
                                    isOpen={showMobielPanel}
                                    onDismiss={() => setShowMobielPanel(false)}
                                    type={PanelType.smallFixedNear}
                                    closeButtonAriaLabel={"Close Record Document Menu"}
                                >
                                    {file.signatureRequired && !!props.canUploadSigned &&
                                        <div
                                            onClick={() => {
                                                props.onFileUpdateClick(file);
                                                setShowMobielPanel(false);
                                            }}
                                            className={styles.panelBlade}
                                        >
                                            <div className={styles.iconContainer}><Icon iconName={updateIconProps.iconName} className={styles.iconBlue} ariaLabel={`${"Upload Signed Document"} ${IconAriaLabel}`}/></div>
                                            <div className={styles.textContainer}>{UpdateSignedDocButtonText}</div>
                                        </div>
                                    }
                                    {!props.templateMode && !!props.canEdit && (
                                        <div
                                            onClick={() => {
                                                setModalTitle(FileEditModalTitle);
                                                setModalSubmitLabel(FileEditModalConfirmButtonText);
                                                setCurrentFile({...file});
                                                setModalOpen(true);
                                                setShowMobielPanel(false);
                                            }}
                                            className={styles.panelBlade}
                                        >
                                            <div className={styles.iconContainer}><Icon iconName={editIconProps.iconName} className={styles.iconBlue} ariaLabel={`${"Edit Document"} ${IconAriaLabel}`}/></div>
                                            <div className={styles.textContainer}>{"Edit Name"}</div>
                                        </div>
                                    )}
                                    <div
                                        onClick={() => {
                                            setModalTitle(!props.templateMode ? FileDeleteModalTitle : TemplateDeleteModalTitle);
                                            setModalSubmitLabel(!props.templateMode ? FileDeleteModalConfirmButtonText : TemplateDeleteModalConfirmButtonText);
                                            setModalContent(!props.templateMode ? FileDeleteModalConfirmationMessage : TemplateDeleteModalConfirmationMessage);
                                            setCurrentFile({...file});
                                            setDeleteModalOpen(true);
                                            setShowMobielPanel(false);
                                        }}
                                        className={styles.panelBlade}
                                    >
                                        <div className={styles.iconContainer}><Icon iconName={deleteIconProps.iconName} className={styles.iconBlue} ariaLabel={`${file.documentReference ? removelabel : deleteLabel} ${IconAriaLabel}`}/></div>
                                        <div className={styles.textContainer}>{file.documentReference ? removelabel : deleteLabel}</div>
                                    </div>
                                </Panel>
                            </div>
                        </div>
                    ))}
            <ModalContainer
                isOpen={modalOpen}
                title={modalTitle}
                submitLabel={modalSubmitLabel}
                onCancel={dismissModal}
                onSubmit={editFile}
                submitDisabled={editDisabled}
            >
                <div>
                    <TextField
                        required={txtFileRequired}
                        defaultValue={currentFile.name.substring(
                            0,
                            currentFile.name.lastIndexOf(".")
                        )}
                        onChange={(event, newValue) => {
                            const extn: string | undefined = currentFile.name.split(".").pop();
                            newValue = newValue == undefined ? "" : newValue.trim();
                            if (!validateFilename(newValue)) {
                                if (extn != undefined) {
                                    setFilename(newValue + "." + extn);
                                }
                            }
                        }}
                        ariaLabel={`${"Rename document"} ${TextFieldAriaLabel},`}
                    ></TextField>
                    <span className={styles.errorMessage}>{errorMessage}</span>
                </div>
            </ModalContainer>
            <ModalContainer
                isOpen={deleteModalOpen}
                title={modalTitle}
                submitLabel={modalSubmitLabel}
                onCancel={dismissModal}
                onSubmit={deleteFile}
            >
                <div>{modalContent}</div>
            </ModalContainer>
        </div>
    );
}
