import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ActionButton, DefaultButton, Icon, Separator, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import RecommendForm, { IRecommendFormProps, RecommendFormType } from "./RecommendForm/RecommendForm";
import { hasUserPermission } from "./overviewRecommendUtils";
import { getFieldAsync, getRecordAsync, mergeRecord, selectCurrentRecord, selectInactiveFields, setForceReloadData } from "../manageRecordWrapper/manageRecordWrapperSlice";
import IApiService from "../../services/Api/IApiService";
import {
    IContentTypeField,
    IRecommendation,
    IRecord,
    IUser,
    Move
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { activityTabKey, breadcrumbManageRecord } from "../../providers/Constants/RecordWizardConstants";
import {
    addRecommendations,
    deleteRecommendationItem,
    descriptionAnalysis,
    descriptionTopic,
    editRecommendationItem,
    errorHeaderPermission,
    errorInfoPermission,
    exitButton,
    fieldLabelAnalysis,
    fieldLabelTopic,
    overviewRecommendationsFormHeaderStrings,
    placeholderNoHasRecommendations,
    recommendationsFormHeaderStrings,
    setOverviewRecommendations
} from "../../providers/Constants/ManageRecordConstants";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { AppRoutes } from "../../app/Constants";
import { selectCurrentUser, setAppTitle } from "../../app/globalSlices/contextSlice";
import styles from "./OverviewRecommendWizardWrapper.module.scss";
import { getRecommendationTypesAsync, selectOverviewLoading, setOverviewLoading } from "../manageRecordWrapper/manageRecordDetails/manageRecordDetailsSlice";
import { isFieldInActive } from "../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";
import UrlHelper, { URL_QUERY_KEYS } from "../../helpers/UrlHelper";

interface IOverviewRecommendationsFormData {
    topic: string;
    analysis: string;
    recommendations: Array<IRecommendation>;
}

interface IRecommendationItemProps {
    recommendation: IRecommendation;
    inProgress: boolean;
    isMobileView: boolean;
    triggerFormFunc: (value: React.SetStateAction<IRecommendFormProps | undefined>) => void;
    updatedRecommendFunc: (updatedRecommend: IRecommendation) => void;
    deleteRecommendFunc: (updatedRecommend: IRecommendation) => void;
    setRecommendState: (state: string, recommendationId: string, value: boolean) => void;
    reOrderRecommend: (recommendationId: string, movement: Move) => void;
    onCancelFunc: () => void;
}

function RecommendationItem({
    recommendation,
    inProgress,
    isMobileView,
    triggerFormFunc,
    updatedRecommendFunc,
    deleteRecommendFunc,
    setRecommendState,
    reOrderRecommend,
    onCancelFunc
}: IRecommendationItemProps): JSX.Element {
    const { t } = useTranslation();
    const recommendationStatesNames: string[] = [
        recommendation.type,
        "Not " + (recommendation.type as string || "").toLowerCase(),
        t("pleaseDiscussLabel")
    ];
    const checkStateLock: (stateName: string) => boolean = React.useCallback(
        (stateName: string): boolean => {
            return recommendation.state
                ? recommendation.state === stateName
                    ? false
                    : true
                : false;
        },
        [recommendation.state]
    );
    return (
        <div
            className={[
                styles.recommendationItem,
                isMobileView ? styles.mobileRecommendationItem : ""
            ].join(" ")}
        >
            <div 
                className={[
                    styles.recommendationLeftColumn,
                    isMobileView ? styles.mobileRecommendationLeftColumn : ""
                ].join(" ")}
            >
                <div className={styles.recommendationLeftColumnContainer}>
                    <div
                        className={[
                            styles.reorderContainer,
                            isMobileView ? styles.mobileReorderContainer : ""
                        ].join(" ")}
                    >
                        <a
                            href=""
                            className={styles.reorder}
                            title="Move up"
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                reOrderRecommend(recommendation.id, Move.Up);
                            }}
                        >
                            <Icon iconName={"ChevronUp"} className={styles.chevron} ariaLabel={"Move up button"}></Icon>
                        </a>
                        <a
                            href=""
                            className={styles.reorder}
                            title="Move down"
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                reOrderRecommend(recommendation.id, Move.Down);
                            }}
                        >
                            <Icon iconName={"ChevronDown"} className={styles.chevron} ariaLabel={"Move down button"}></Icon>
                        </a>
                    </div>
                    <div
                        className={[
                            styles.recommendItemTextInfo,
                            isMobileView ? styles.mobileRecommendItemTextInfo : ""
                        ].join(" ")}
                    >
                        <div
                            className={[
                                styles.recommendType,
                                isMobileView ? styles.mobileRecommendType : ""
                            ].join(" ")}
                        >
                            {recommendation.type}
                        </div>
                        <div
                            className={[
                                styles.recommendDescription,
                                isMobileView ? styles.mobileRecommendDescription : ""
                            ].join(" ")}
                        >
                            {recommendation.description}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={[
                    styles.recommendationRightColumn,
                    isMobileView ? styles.mobileRecommendationRightColumn : ""
                ].join(" ")}
            >
                <div
                    className={[
                        styles.checkboxRecommendContainer,
                        isMobileView ? styles.mobileCheckboxRecommendContainer : ""
                    ].join(" ")}
                >
                    <Checkbox
                        name={recommendationStatesNames[0]}
                        defaultChecked={recommendation.state === recommendationStatesNames[0]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[0],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={inProgress || checkStateLock(recommendationStatesNames[0])}
                    />
                    { (recommendationStatesNames[1].toLowerCase() != "not noted") &&
                    <Checkbox
                        name={recommendationStatesNames[1]}
                        defaultChecked={recommendation.state === recommendationStatesNames[1]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[1],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={inProgress || checkStateLock(recommendationStatesNames[1])}
                    />}
                    <Checkbox
                        name={recommendationStatesNames[2]}
                        defaultChecked={recommendation.state === recommendationStatesNames[2]}
                        required={false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                            checked?: boolean | undefined
                        ) => {
                            if (typeof checked === "boolean") {
                                setRecommendState(
                                    recommendationStatesNames[2],
                                    recommendation.id,
                                    checked
                                );
                            }
                        }}
                        disabled={inProgress || checkStateLock(recommendationStatesNames[2])}
                    />
                </div>
                <div>
                    <ActionButton
                        className={[
                            styles.editRecommendButton,
                            isMobileView ? styles.mobileEditRecommendButton : ""
                        ].join(" ")}
                        iconProps={{ iconName: "Edit" }}
                        allowDisabledFocus
                        disabled={inProgress}
                        onClick={() =>
                            triggerFormFunc({
                                type: RecommendFormType.Edit,
                                recommendation,
                                onSubmit: async updatedRecommend =>
                                    await updatedRecommendFunc(updatedRecommend),
                                onCancel: onCancelFunc
                            })
                        }
                        ariaLabel={`${editRecommendationItem} button`}
                    >
                        {editRecommendationItem}
                    </ActionButton>
                    <ActionButton
                        className={[
                            styles.editRecommendButton,
                            isMobileView ? styles.mobileEditRecommendButton : ""
                        ].join(" ")}
                        iconProps={{ iconName: "Delete" }}
                        allowDisabledFocus
                        disabled={inProgress}
                        onClick={() =>
                            triggerFormFunc({
                                type: RecommendFormType.Delete,
                                recommendation,
                                onSubmit: async deleteRecommend =>
                                    await deleteRecommendFunc(deleteRecommend),
                                onCancel: onCancelFunc
                            })
                        }
                        ariaLabel={`${deleteRecommendationItem} button`}
                    >
                        {deleteRecommendationItem}
                    </ActionButton>
                </div>
            </div>
        </div>
    );
}

export function OverviewRecommendWizardWrapper(): JSX.Element {
    const { t } = useTranslation();
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const { recordId } = useParams<{ recordId: string }>();

    const isMobileInnerWidth = (): boolean => window.innerWidth <= 640;
    const [isMobileView, setIsMobileView] = React.useState<boolean>(isMobileInnerWidth());
    const isOverviewLoading: boolean = useSelector(selectOverviewLoading);

    React.useEffect(() => {
        const detectMobileVersion = (): void => {
            if (isMobileView !== isMobileInnerWidth()) {
                setIsMobileView(isMobileInnerWidth());
            }
        };
        window.addEventListener("resize", detectMobileVersion);
        return () => window.removeEventListener("resize", detectMobileVersion);
    }, [isMobileView]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const [inProgress, setInProgress] = React.useState<boolean>(false);

    React.useEffect(() => {
        dispatch(setForceReloadData(true));
    }, [dispatch]);

    React.useEffect(() => {
        if (currentRecord && currentRecord.recordType) {    
            dispatch(getFieldAsync(true, currentRecord.recordType));          
            dispatch(setAppTitle(t("recommendationsPageTitlePrefix") + recordId + t("recommendationsPageTitlePostfix")));     
        }
    }, [currentRecord, dispatch, recordId, t]);

    React.useEffect(() => {
        dispatch(getRecommendationTypesAsync());
    }, [dispatch]);

    React.useEffect(() => {
        (async () => {
            dispatch(setOverviewLoading(true));
            if (!currentRecord) {
                try {
                    setInProgress(true);
                    const resolver: DependencyResolver = new DependencyResolver();
                    const apiService: IApiService = resolver.ResolveIApiService();
                    const currentRecord: IRecord | null = await apiService.GetRecord(recordId, false);
                    if (currentRecord) {
                        //TODO: remove
                        //workaround for ID field
                        const recommendations: IRecommendation[] | undefined =
                            currentRecord.recommendations?.recommendations;
                        if (recommendations) {
                            recommendations.forEach((r, i) => {
                                if (!r.id) {
                                    r.id = `${i}`;
                                }
                            });
                        }
                        //workaround for ID field

                        setFormData({
                            topic: currentRecord.topicSummary,
                            analysis: currentRecord.analysis,
                            recommendations: recommendations
                        });
                    }
                    dispatch(mergeRecord(currentRecord));
                    dispatch(setOverviewLoading(false));
                } finally {
                    setInProgress(false);
                }
            }
            else{
                dispatch(setOverviewLoading(false));
            }
        })();
    }, [currentRecord, dispatch, recordId]);

    const [formData, setFormData] = React.useState<
        Partial<IOverviewRecommendationsFormData> | undefined
    >({
        topic: currentRecord?.topicSummary,
        analysis: currentRecord?.analysis,
        recommendations: currentRecord?.recommendations?.recommendations
    });
    const [formRecommentProps, setFormRecommentProps] = React.useState<
        IRecommendFormProps | undefined
    >(undefined);

    const onConfirm: () => Promise<void> = React.useCallback(async () => {
        try {
            setInProgress(true);
            if (formData && currentRecord?.recordId) {
                //Some API method for set Overview&Recommendations data
                // const updatedRecord: IRecord | null = await onSubmit(formData as IOverviewRecommendationsFormData);
                // if (updatedRecord) {
                //     dispatch(mergeRecord(updatedRecord));
                // }
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const updatedRecord: IRecord | null = await apiService.UpdateRecordRecommendations({
                    recordId: currentRecord.recordId,
                    title: currentRecord.title,
                    topicSummary: formData.topic,
                    analysis: formData.analysis,
                    recommendations: { recommendations: formData.recommendations }
                });
                dispatch(mergeRecord(updatedRecord));
            }
            history.push(AppRoutes.buildUrl(AppRoutes.manageRecord, { recordId: recordId }));
        } finally {
            setInProgress(false);
        }
    }, [dispatch, formData, history, recordId, currentRecord]);

    React.useEffect(() => {
        if (formData?.recommendations && formData?.recommendations.length) {
            if (formData.recommendations.every(recommendItem => !recommendItem.id)) {
                const recommendations: IRecommendation[] = formData.recommendations.map(
                    (recommendItem, index) => {
                        if (!recommendItem.id) {
                            return { ...recommendItem, id: `${index}` };
                        } else {
                            return recommendItem;
                        }
                    }
                );
                setFormData({ ...formData, recommendations });
            }
        }
    }, [formData]);

    const testCreateRecommendationFunc: (newRecommend: IRecommendation) => void = React.useCallback(
        (newRecommend: IRecommendation): void => {
            let newRecommendId: number = 0;
            (formData?.recommendations || []).forEach(r => {
                if (Number(r.id) >= newRecommendId) {
                    newRecommendId = Number(r.id) + 1;
                }
            });
            newRecommend.id = String(newRecommendId);
            return setFormData({
                ...formData,
                recommendations: [...(formData?.recommendations || []), newRecommend]
            });
        },
        [formData]
    );

    const testUpdateRecommendationFunc: (
        updatedRecommend: IRecommendation
    ) => void = React.useCallback(
        (updatedRecommend: IRecommendation): void => {
            const recommendations: IRecommendation[] = [...(formData?.recommendations || [])];
            const idx: number = recommendations.findIndex(r => r.id === updatedRecommend.id);
            if (idx > -1) {
                recommendations.splice(idx, 1, { ...recommendations[idx], ...updatedRecommend });
            }
            return setFormData({
                ...formData,
                recommendations: recommendations
            });
        },
        [formData]
    );

    const deleteRecommendationFunc: (
        deleteRecommend: IRecommendation
    ) => void = React.useCallback((
        deleteRecommend: IRecommendation
    ) => {
        const recommendations: IRecommendation[] = [...(formData?.recommendations || [])];
        const idx: number = recommendations.findIndex(r => r.id === deleteRecommend.id);
        if (idx > -1) {
            if (idx > -1) {
                recommendations.splice(idx, 1);
            }
        }
        return setFormData({
            ...formData,
            recommendations: recommendations
        });
    },[formData]);

    const setRecommendationState: (
        state: string,
        recommendationId: string,
        value: boolean
    ) => void = React.useCallback(
        (state: string, recommendationId: string, value: boolean): void => {
            const recommendations: IRecommendation[] = [...(formData?.recommendations || [])];
            const idx: number = recommendations.findIndex(
                recommendItem => recommendItem.id === recommendationId
            );
            if (idx > -1) {
                recommendations.splice(idx, 1, {
                    ...recommendations[idx],
                    state: value ? state : undefined
                });
            }
            return setFormData({
                ...formData,
                recommendations: recommendations
            });
        },
        [formData]
    );

    const reOrderRecommendation: (recommendId: string, movement: Move) => void = React.useCallback(
        (recommendId: string, movement: Move): void => {
            if (formData?.recommendations) {
                const movedRecommendIndx: number = (formData.recommendations || []).findIndex(
                    recommendItem => recommendItem.id === recommendId
                );
                if (
                    (movedRecommendIndx <= 0 && movement === Move.Up) ||
                    (movedRecommendIndx === (formData.recommendations || []).length - 1 &&
                        movement === Move.Down)
                ) {
                    return;
                }
                const recommendations: IRecommendation[] = [...(formData.recommendations || [])];

                const movedRecommendItem: IRecommendation[] = recommendations.splice(
                    movedRecommendIndx,
                    1
                );
                const newIndex: number =
                    movement === Move.Down ? movedRecommendIndx + 1 : movedRecommendIndx - 1;
                recommendations.splice(newIndex, 0, ...movedRecommendItem);
                setFormData({ ...formData, recommendations });
            }
        },
        [formData]
    );

    return (
        <>
            <Breadcumbs pageName={breadcrumbManageRecord} />
            {isOverviewLoading && (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.medium}
                        label={t("docOverviewRecommendMessage")}
                        ariaLabel={"Loading spinner"}
                    />
                </div>
            )}
            {!isOverviewLoading && (<div className={styles.overviewRecommendContainer}>
                {hasUserPermission(currentRecord, currentUser || null) ? (
                    <>
                        <div className={styles.formContainer}>
                            <FormHeader strings={overviewRecommendationsFormHeaderStrings} />
                            <div className={styles.fieldsContainer}>
                                {!isFieldInActive("ABCTopicSummary", inactiveFields) && (
                                    <FieldWrapper description={descriptionTopic}>
                                        <TextField
                                            className={styles.textField}
                                            label={fieldLabelTopic}
                                            multiline
                                            resizable={false}
                                            required={true}
                                            defaultValue={formData?.topic}
                                            onChange={(ev, newValue?: string) =>
                                                setFormData({
                                                    ...formData,
                                                    topic: newValue
                                                })
                                            }
                                            disabled={inProgress || currentRecord?.noFurtherAction || currentRecord?.withdrawn}
                                            ariaLabel={`${fieldLabelTopic} text field`}
                                        />
                                    </FieldWrapper>
                                )} 
                                {!isFieldInActive("ABCAnalysis", inactiveFields) && (
                                    <>
                                        <FieldWrapper description={descriptionAnalysis}>
                                            <TextField
                                                className={styles.textField}
                                                label={fieldLabelAnalysis}
                                                multiline
                                                resizable={false}
                                                required={true}
                                                defaultValue={formData?.analysis}
                                                onChange={(ev, newValue?: string) =>
                                                    setFormData({
                                                        ...formData,
                                                        analysis: newValue
                                                    })
                                                }
                                                disabled={inProgress || currentRecord?.noFurtherAction || currentRecord?.withdrawn}
                                                ariaLabel={`${fieldLabelAnalysis} text field`}
                                            />
                                        </FieldWrapper>
                                        <br />
                                        <Separator aria-label={"Separator"}/>
                                        <br />                                       
                                    </>                                 
                                )}

                                <h2 className={styles.recommendHeader}>
                                    {recommendationsFormHeaderStrings.Header}
                                </h2>
                                <h4 className={styles.recommendSubtitle}>
                                    {recommendationsFormHeaderStrings.SubTitle}
                                </h4>
                                <div
                                    className={[
                                        styles.recommendationsContainer,
                                        isMobileView ? styles.mobileRecommendationsContainer : ""
                                    ].join(" ")}
                                >
                                    {formData?.recommendations?.length ? (
                                        <div
                                            className={[
                                                styles.recommendationsItems,
                                                isMobileView
                                                    ? styles.mobileRecommendationsItems
                                                    : ""
                                            ].join(" ")}
                                        >
                                            {formData.recommendations.map(recommendItem => {
                                                return (
                                                    <RecommendationItem
                                                        key={recommendItem.id}
                                                        recommendation={recommendItem}
                                                        inProgress={inProgress}
                                                        isMobileView={isMobileView}
                                                        updatedRecommendFunc={testUpdateRecommendationFunc}
                                                        deleteRecommendFunc={deleteRecommendationFunc}
                                                        triggerFormFunc={setFormRecommentProps}
                                                        setRecommendState={setRecommendationState}
                                                        reOrderRecommend={reOrderRecommendation}
                                                        onCancelFunc={() => setFormRecommentProps(undefined)}
                                                    />
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <p className={styles.noHasRecommendInfo}>
                                            <Icon iconName="Warning" ariaLabel={"Warning icon"}/>
                                            {placeholderNoHasRecommendations}
                                        </p>
                                    )}
                                </div>
                                <DefaultButton
                                    className={styles.addRecommendButton}
                                    onClick={() =>
                                        setFormRecommentProps({
                                            type: RecommendFormType.New,
                                            onSubmit: async newRecommend =>
                                                await testCreateRecommendationFunc(newRecommend),
                                            onCancel: () => setFormRecommentProps(undefined)
                                        })
                                    }
                                    iconProps={{ iconName: "Add" }}
                                    disabled={inProgress || currentRecord?.noFurtherAction || currentRecord?.withdrawn}
                                    ariaLabel={`${addRecommendations} button`}
                                >
                                    {addRecommendations}
                                </DefaultButton>
                                <br />
                                <Separator aria-label={"Separator"}/>
                                <br />
                                <div className={styles.submitButtonsContainer}>
                                    <DefaultButton
                                        className={styles.submitButton}
                                        primary
                                        onClick={onConfirm}
                                        styles={{ root: { marginRight: "15px" } }}
                                        disabled={inProgress || currentRecord?.noFurtherAction || currentRecord?.withdrawn}
                                        ariaLabel={`${setOverviewRecommendations} button`}
                                    >
                                        {setOverviewRecommendations}
                                    </DefaultButton>
                                    <DefaultButton
                                        className={styles.submitButton}
                                        disabled={inProgress}
                                        onClick={() =>
                                        {
                                            history.push(
                                                AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                                    recordId: recordId
                                                })
                                            );
                                            const currentTabKey: string | undefined = UrlHelper.getParameterByName(URL_QUERY_KEYS.TAB);
                                            if (currentTabKey == undefined || currentTabKey == activityTabKey) {
                                                dispatch(getRecordAsync(recordId, true, true));
                                            }
                                        }                                            
                                        }
                                        ariaLabel={`${exitButton} button`}
                                    >
                                        {exitButton}
                                    </DefaultButton>
                                </div>
                            </div>
                        </div>
                        {formRecommentProps && (
                            <RecommendForm
                                {...formRecommentProps}
                            />
                        )}
                    </>
                ) : (
                    <div className={styles.errorInfoContainer}>
                        <h1>{errorHeaderPermission}</h1>
                        <p>{errorInfoPermission}</p>
                    </div>
                )}
            </div>)}
        </>
    );
}
