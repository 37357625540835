import React from "react";
import {
    Checkbox,
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Separator
} from "@fluentui/react";
import { IRecordResultColumn } from "../../services/Api/executor/IApiServiceExecutor";
import styles from "./RecordsTableColumnsPanel.module.scss";

interface IRecordsTableColumnsPanelProps {
    title: string;
    descr: string;
    submitBtnTitle: string;
    cancelBtnTitle: string;
    columns?: IRecordResultColumn[];
    onSubmit: (managedProperties: string[]) => Promise<void>
    onCancel: () => void;
    replaseSubmitBtn?: JSX.Element;
    cancelAfterSubmit?: boolean;
    errorMessage?: string;
}

export default function RecordsTableColumnsPanel({
    title,
    descr,
    submitBtnTitle,
    cancelBtnTitle,
    columns,
    onSubmit,
    onCancel,
    replaseSubmitBtn,
    cancelAfterSubmit,
    errorMessage
}: IRecordsTableColumnsPanelProps): JSX.Element {
    const panelTitle: string = title;
    const panelDescription: string = descr;
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = React.useState<string[] | undefined>(undefined);
    
    React.useEffect(() => {
        if (columns && !selectedColumns) {
            const filteredColumns: string[] = columns
                .filter(columnItem => !!columnItem.isSelected)
                .map(columnItem => columnItem.managedPropertyName || "");
            setSelectedColumns(filteredColumns);
        }
    }, [selectedColumns, columns]);

    const duplciates: IRecordResultColumn[] | undefined = React.useMemo(() => 
        columns?.filter((item, index) => columns?.some((elem, idx) => elem.displayName === item.displayName && idx !== index))
    , [columns]);

    const columnsCheckboxsElements: JSX.Element = React.useMemo((): JSX.Element => {
        return columns && columns?.length ? (
            <div className={styles.checkboxsContainer}>
                {columns.map(columnItem => {
                    const label: string = `${columnItem.displayName} ${columnItem.key === "recordId" ? "(always displayed)" : ""} ${duplciates?.some(item => item.displayName === columnItem.displayName) ? `(${columnItem.key})`: ""}`;
                    return (
                        <Checkbox
                            key={columnItem.key}
                            className={[
                                styles.checkBoxItem,
                                inProgress || columnItem.key === "recordId"
                                    ? styles.disabledCheckBoxItem
                                    : ""
                            ].join(" ")}
                            name={columnItem.displayName}
                            label={label}
                            defaultChecked={columnItem.isSelected}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                                checked?: boolean | undefined
                            ) => {
                                if (checked) {
                                    setSelectedColumns([
                                        ...(selectedColumns as string[]),
                                        columnItem.managedPropertyName || ""
                                    ]);
                                } else if (typeof checked === "boolean") {
                                    const removedItemId:
                                        | number
                                        | undefined = selectedColumns?.findIndex(selectedColumnItem =>
                                            selectedColumnItem === columnItem.managedPropertyName);
                                    if (typeof removedItemId === "number" && removedItemId > -1) {
                                        const updatedDataSelectedColumns:
                                            | string[]
                                            | undefined = selectedColumns;
                                        (updatedDataSelectedColumns || []).splice(removedItemId, 1);
                                        setSelectedColumns(updatedDataSelectedColumns);
                                    }
                                }
                            }}
                            disabled={inProgress || columnItem.key === "recordId"}
                            ariaLabel={`${columnItem.displayName} checkbox`}
                        />
                    );
                })}
            </div>
        ) : (
            <></>
        );
    }, [columns, duplciates, inProgress, selectedColumns]);

    const onConfirm: () => Promise<void> = React.useCallback(async (): Promise<void> => {
        try {
            setInProgress(true);
            if (selectedColumns) {
                await onSubmit(selectedColumns);
            }
            if(cancelAfterSubmit){
                onCancel();
            }
        } finally {
            setInProgress(false);
        }
    }, [onSubmit, onCancel, selectedColumns, cancelAfterSubmit]);

    const onRenderFooterContent: () => JSX.Element = React.useCallback(
        (): JSX.Element => (
            <div  className={styles.footer}>
                {errorMessage &&
                    <MessageBar
                        className={styles.errorMessage}
                        isMultiline={false}
                        messageBarType={MessageBarType.error}
                    >{errorMessage}</MessageBar>
                }
                <div className={styles.actionsButtonsContainer}>
                    {replaseSubmitBtn ? replaseSubmitBtn
                        : <PrimaryButton 
                            onClick={onConfirm} 
                            disabled={inProgress || !selectedColumns} 
                            ariaLabel={submitBtnTitle} 
                            text={submitBtnTitle}                        
                        />
                    }
                    <DefaultButton 
                        onClick={onCancel} 
                        disabled={inProgress} 
                        ariaLabel={cancelBtnTitle} 
                        text={cancelBtnTitle}
                    />
                </div>
            </div>
        ),
        [inProgress, onCancel, onConfirm, selectedColumns, cancelBtnTitle, replaseSubmitBtn, submitBtnTitle, errorMessage]
    );

    return (
        <Panel
            isOpen={true}
            onDismiss={onCancel}
            type={PanelType.smallFixedFar}
            closeButtonAriaLabel="Close"
            headerText={panelTitle}
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
            aria-label={`${panelTitle} panel`}
        >
            <div className={styles.recordsColumnsPanel}>
                <div className={styles.descriptionTextPanel}>{panelDescription}</div>
                {columnsCheckboxsElements}
                <Separator className={styles.separator} />
            </div>
        </Panel>
    );
}
