import * as React from "react";
import {
    IBasePickerSuggestionsProps,
    IPersona,
    IPersonaProps,
    Label,
    NormalPeoplePicker
} from "@fluentui/react";
import styles from "./PeoplePicker.module.scss";
import IApiService from "../../../services/Api/IApiService";
import {
    ISimpleUser,
    ISimpleUsers
} from "../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import {
    DialogLoading,
    DialogNoResults,
    DialogSuggested,
    PeoplePickerDefaultAriaLabel
} from "../../../providers/Constants/FormControlConstants";
import {
    DialogHelper
} from "../../../helpers/DialogHelper";

// import {
//     getFilteredUsers,
//     selectSelectedUser
// } from "./peoplePickerSlice";

interface IPeoplePickerProps {
    selectedUsers?: ISimpleUser[];
    usersToExclude?: ISimpleUser[];
    onChange?: (user: ISimpleUser[] | undefined) => void;
    multi?: boolean;
    // className?: string;
    label: string;
    disabled?: boolean;
    isRequired?: boolean;
}

interface ICustomPersonaProps extends IPersonaProps {
    data: ISimpleUser;
}

export default function PeoplePicker(props: IPeoplePickerProps): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    // const selectedUser: number = useSelector(selectSelectedUser);

    // 3. DEFINE COMPONENT STATE
    // const [userList, setUserList] = useState<ISimpleUser[]>([]);
    // const [personaList, setPersonaList] = useState<IPersonaProps[]>([]);
    // const [ignorePreselection, setIgnorePreselection] = useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = useState("");

    // 4. GET DISPATCH
    // const dispatch: Dispatch<any> = useDispatch();

    // 5. Use Effect

    // 6. Other Functions
    const onChange = (people?: IPersonaProps[]): void => {
        if (props.onChange) {
            let result: ISimpleUser[] | undefined = undefined;
            if (people && people.length) {
                result = people.map(p => (p as ICustomPersonaProps).data);
            }
            props.onChange(result);
        }
    };

    const castSimpleUserToPersona = (users: ISimpleUser[] | undefined): IPersonaProps[] => {
        const result: IPersonaProps[] = (users || []).map(user => {
            return {
                text: user.displayName || user.mail || "",
                secondaryText: user.jobTitle || user.mail || "",
                tertiaryText: user.userPrincipalName || user.mail || "",
                imageInitials: DialogHelper.getInitials(user.displayName),
                optionalText: user.siteUserId || "",
                data: user
            } as ICustomPersonaProps;
        });
        return result;
    };

    const onResolveSuggestions = async (
        filterText: string,
        currentPersonas?: IPersonaProps[]
    ): Promise<IPersonaProps[]> => {
        const users: ISimpleUser[] | undefined = await getFilteredUsers(filterText);
        const allSuggestions: IPersonaProps[] = castSimpleUserToPersona(users);
        const currPersonas: IPersonaProps[] = currentPersonas
            ? currentPersonas
            : castSimpleUserToPersona(props.selectedUsers);
        const usersToExclude: IPersonaProps[] = [
            ...(currPersonas || []),
            ...castSimpleUserToPersona(props.usersToExclude)
        ];

        // optionalText/userId can be number (come from record) or GUID(come from search API) - that is why filtering is not working by user ID
        const filteredSuggestions: IPersonaProps[] = allSuggestions.filter(
            p => !usersToExclude.some(currPer => currPer.tertiaryText === p.tertiaryText)
        );
        const uniqueSuggestions: IPersonaProps[] = [];
        filteredSuggestions.forEach(p => {
            if (!uniqueSuggestions.some(uP => uP.tertiaryText === p.tertiaryText)) {
                uniqueSuggestions.push(p);
            }
        });

        return uniqueSuggestions;
    };

    const getFilteredUsers = async (filterText: string) => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const users: ISimpleUsers | null = await apiService.GetUsers(filterText);
        return users ? users.users : [];
    };

    const selectedUser = (): IPersona[] => {
        const result: IPersona[] = castSimpleUserToPersona(props.selectedUsers);
        return result;
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: DialogSuggested,
        noResultsFoundText: DialogNoResults,
        loadingText: DialogLoading
    };

    return (
        <div className={styles.peoplePickerContainer}>
            <Label className={styles.peoplePickerLabel} required={props.isRequired}>
                {props.label}
            </Label>
            <Label className={styles.peoplePickerFakeLabelForAccessibilityInsights}>            
                <NormalPeoplePicker
                    aria-label={props.label || PeoplePickerDefaultAriaLabel}
                    onResolveSuggestions={onResolveSuggestions}
                    pickerSuggestionsProps={suggestionProps}
                    itemLimit={props.multi ? 12 : 1}
                    // onEmptyInputFocus={returnNoFilterUser}
                    getTextFromItem={(persona: IPersonaProps) => persona.text || ""}
                    onChange={onChange}
                    selectedItems={selectedUser()}
                    className={styles.peoplePicker}
                    disabled={props.disabled}
                    resolveDelay={400}
                />
            </Label>
        </div>
    );
}
