import React from "react";
import { IRefiner, IRefinerEntry } from "../../../model/Refiner";
import styles from "./BooleanRefiner.module.scss";
import { Checkbox } from "@fluentui/react";

interface IBooleanRefinerProps {
    refiner: IRefiner;
    value?: IRefiner;
    onChange: (value: IRefiner) => void;
}

export default function BooleanRefiner({ refiner, value, onChange }: IBooleanRefinerProps): JSX.Element {
    const [checkedOpts, setCheckedOpts] = React.useState<IRefinerEntry[]>(value?.entries || []);

    function onChangeCheckbox(val: IRefinerEntry, checked?: boolean | undefined) {
        const idx: number = checkedOpts.findIndex(o => o.value === val.value);
        const newCheckedOpts: IRefinerEntry[] = [...checkedOpts];
        if (checked) {
            if (idx === -1) {
                newCheckedOpts.push(val);
            }
        } else {
            if (idx > -1) {
                newCheckedOpts.splice(idx, 1);
            }
        }
        setCheckedOpts(newCheckedOpts);

        const newValue: IRefiner = { ...refiner };
        newValue.entries = newCheckedOpts;
        onChange(newValue);
    }

    function isChecked(e: IRefinerEntry): boolean {
        const result: boolean = checkedOpts.some(o => o.value === e.value);
        return result;
    }

    React.useEffect(() => {
        if (!value?.entries) {
            setCheckedOpts([]);
        }
    }, [value]);

    return (
        <div className={styles.booleanRefiner}>
            <div className={styles.checkboxList}>
                {(refiner.entries || []).map((o, i) =>
                    <div className={styles.listItem} key={i}>
                        <Checkbox
                            className={styles.checkbox}
                            label={o.name}
                            onChange={(ev, checked) => { onChangeCheckbox(o, checked); }}
                            checked={isChecked(o)}
                        />
                    </div>)
                }
            </div>
        </div>
    );
}