import * as React from "react";
import {
    Label
} from "@fluentui/react";
import {
    DatePicker as ODatePicker,
    DayOfWeek
} from "@fluentui/react";
import styles from "./DatePicker.module.scss";
import Select, { ISelectOptionProps } from "../Select/Select";
import {
    ControlContainer
} from "../ControlContainer/ControlContainer";
import SPHelper from "../../../helpers/SharePointHelper";
import { IconButton } from "@fluentui/react";

export interface IDatePickerProps {
    dateLabel: string;
    timeLabel?: string;
    required: boolean;
    includeTime: boolean;
    hideDate?: boolean;
    minDate?: Date;
    maxDate?: Date;
    defaultValue?: Readonly<Date>;
    value?: Date;
    description?: string;
    onChange?: (date: Date | null) => void;
    onChangeErrorStyling?: (error: boolean) => void;
    className?: string;
    disabled?: boolean;
    ariaLabel?: string;
    hoursMinMax?: { min?: number; max?: number; minMinutes?: number; }
    invalidRangeMessage?: string;
    valueOutOfRangeMessage?: string;
    minutesStep?: number;
    placeholder?:string;
}

export interface IDatePickerState {
    selectedDate: Date | undefined;
}

export default class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
    constructor(props: IDatePickerProps) {
        super(props);
        const dateToSet: Readonly<Date> | undefined = this.props.value || this.props.defaultValue;
        this.state = {
            selectedDate: !dateToSet
                ? undefined
                : new Date(dateToSet.getTime())
        };
        this.onSelectDate = this.onSelectDate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onHoursChange = this.onHoursChange.bind(this);
        this.getHourOptions = this.getHourOptions.bind(this);
    }

    componentDidUpdate(prevProps: IDatePickerProps): void {
        if (prevProps.value !== this.props.value) {
            this.setState({
                selectedDate: this.props.value
            });
        }
    }

    public render(): React.ReactElement<IDatePickerProps> {
        const key: string = `${SPHelper.GetSafeFilename(this.props.dateLabel)}DatePickerControl`;
        const dateValue: Date | undefined = this.props.value || this.state.selectedDate;
        const hoursValue: string | undefined = this.getHoursBasedOnOptions(dateValue, this.props.hideDate && this.props.includeTime);
        const defaultHoursValue: string = this.props.value ? this.getHoursBasedOnOptions(dateValue) || "17.00" : "17.00";
        const invalidRange: boolean = !!((this.props.minDate && this.props.maxDate) && this.props.minDate > this.props.maxDate);
        const valueOutOfRange: boolean = !!((this.props.value && this.props.minDate) && this.props.value < this.props.minDate);
        const hasError: boolean = invalidRange || valueOutOfRange;
        if (this.props.onChangeErrorStyling) {
            this.props.onChangeErrorStyling(hasError);
        }
        return (
            <ControlContainer className={`${styles.datePickerControl} ${this.props.className || ""}`}>
                {!this.props.hideDate && 
                    <div>
                        <div>
                            <Label required={this.props.required}>{`${this.props.dateLabel}:`}</Label>
                        </div>
                        <div className={styles.fullContainer}>
                            <ODatePicker
                                key={key}
                                className={[styles.datePicker, this.props.disabled ? styles.disabledDatePickerField : "" ].join(" ")}
                                calloutProps={{ className: styles.calloutContainer }}
                                firstDayOfWeek={DayOfWeek.Monday}
                                // componentRef={r => (this.datePicker = r)}
                                placeholder={(this.props.placeholder)?this.props.placeholder:`Select ${this.props.dateLabel.toLowerCase()}`}
                                minDate={this.props.minDate ? this.props.minDate : undefined}
                                maxDate={this.props.maxDate ? this.props.maxDate : undefined}
                                allowTextInput={false}
                                value={dateValue}
                                onSelectDate={this.onChange}
                                disabled={this.props.disabled || invalidRange}
                                ariaLabel={this.props.ariaLabel}
                            />
                            {!this.props.required && dateValue 
                                    &&  <IconButton 
                                        iconProps={{iconName: "Clear"}}
                                        onClick={() => this.onChange(undefined)}
                                        disabled={this.props.disabled}
                                    />
                            }
                        </div>
                    </div>
                }
                {this.props.includeTime  &&
                    <div className={!this.props.timeLabel ? styles.timeFieldGap : ""}>
                        {this.props.timeLabel &&
                            <div>
                                <Label required={this.props.required} >{`${this.props.timeLabel}:`}</Label>
                            </div>
                        }
                        <div className={styles.fullContainer}>
                            <Select
                                key={`${key}Hour`}
                                name={"Hour"}
                                suppressNameLabel={true}
                                required={false}
                                defaultSelectedKey={this.props.hideDate ? undefined : defaultHoursValue}
                                selectedKey={hoursValue}
                                options={this.getHourOptions()}
                                onChange={(opt: ISelectOptionProps | undefined) => {
                                    this.onHoursChange(opt?.key || "");
                                }}
                                disabled={this.props.disabled || invalidRange}
                            />
                        </div>
                    </div>
                }
                {hasError &&
                    <div className={styles.invalidRangeMessage}>
                        {invalidRange && <span> {(this.props.invalidRangeMessage || "Wrong date range. MIN value more than MAX value.")}</span>}
                        {valueOutOfRange && <span> {(this.props.valueOutOfRangeMessage || "Selected value out of range.")}</span>}
                    </div>
                }
                <div className={styles.description}>{this.props.description}</div>
            </ControlContainer>
        );
    }

    // Only made public to support testing...
    public onSelectDate(date?: Date | null |     undefined): void {
        if (date !== null) {
            this.setState({
                selectedDate: date
            });
        }
    }

    public getHoursBasedOnOptions(date: Date | undefined, noDefault: boolean = false): string | undefined {
        const defaultMinValue: number = this.props?.hoursMinMax?.min !== undefined ? this.props.hoursMinMax.min : 12;
        let minutes: string = "00";
        let result: number = defaultMinValue;
        if(date){
            const dateHours: number = date.getHours();
            if((this.props?.hoursMinMax?.min !== undefined || this.props?.hoursMinMax?.max !== undefined)){
                const valueInRange: boolean = !!((this.props.hoursMinMax.min !== undefined ? this.props.hoursMinMax.min <= dateHours : true) &&
                (this.props.hoursMinMax.max !== undefined ? this.props.hoursMinMax.max >= dateHours : true));
                if(valueInRange){
                    result = dateHours;
                }
            } else {
                result = dateHours;
            }
            if(this.props.minutesStep){
                minutes= date.getMinutes() === 0 ? "00" : date.getMinutes().toString();
            }
        } else if(noDefault){
            return undefined;
        }
        return `${result.toString()}.${minutes}`;
    }

    public getHourOptions(): ISelectOptionProps[] {
        let firstOption: ISelectOptionProps | undefined = { key: "7.00", text: " 7:00 AM" };
        let lastOption: ISelectOptionProps | undefined = { key: "19.00", text: "7:00 PM" };
        let options: ISelectOptionProps[] = [
            firstOption,
            { key: "8.00", text: " 8:00 AM" },
            { key: "9.00", text: " 9:00 AM" },
            { key: "10.00", text: "10:00 AM" },
            { key: "11.00", text: "11:00 AM" },
            { key: "12.00", text: "12:00 PM" },
            { key: "13.00", text: "1:00 PM" },
            { key: "14.00", text: "2:00 PM" },
            { key: "15.00", text: "3:00 PM" },
            { key: "16.00", text: "4:00 PM" },
            { key: "17.00", text: "5:00 PM" },
            { key: "18.00", text: "6:00 PM" },
            lastOption
        ];
        if(this.props.minutesStep){
            const resultOptions: ISelectOptionProps[] = [];
            const lastHour: string = options[options.length - 1].key;
            options.forEach((o) => {
                resultOptions.push(o);
                if(o.key !== lastHour && this.props.minutesStep){
                    const stepsCount: number = 60 / this.props.minutesStep;
                    for (let index: number = 1; index < stepsCount; index++) {
                        const minutes: number = this.props.minutesStep * index;
                        const text: string = o.text.replace("00", minutes.toString());
                        const key: string = o.key.replace("00", minutes.toString());
                        resultOptions.push({key:key, text: text});
                    }
                }
            });

            options = resultOptions;
        }
        if (this.props.hoursMinMax) {
            const minHours: number = this.props.hoursMinMax.min || 0;
            const minMinutes: number = this.props.hoursMinMax.minMinutes || 0;
            const min: number = minHours + minMinutes / 100 ;
            const max: number = this.props.hoursMinMax.max || 23;
            options = options.filter(o => Number(o.key) >= min && Number(o.key) <= max);
            firstOption = options[0];
            lastOption = options.length > 0 ? options[options.length - 1] : undefined;
        }
        if (this.state.selectedDate) {
            const selDate: number = (new Date(this.state.selectedDate)).setHours(0, 0, 0, 0);
            if (this.props.minDate) {
                const minDate: number = (new Date(this.props.minDate)).setHours(0, 0, 0, 0);
                if (minDate === selDate) {
                    const minHour: number = (new Date(this.props.minDate)).getHours();
                    options = options.filter(o => Number(o.key) >= minHour);
                    //in case minHour > last available option, add last available time for not leaving empty dropdown.
                    if(!options.length && lastOption){
                        options.push(lastOption);
                    }
                }
            }
            if (this.props.maxDate) {
                const maxDate: number = (new Date(this.props.maxDate)).setHours(0, 0, 0, 0);
                if (maxDate === selDate) {
                    const maxHour: number = (new Date(this.props.maxDate)).getHours();
                    options = options.filter(o => Number(o.key) <= maxHour);
                    //in case maxHour < first available option, add first availabale time for not leaving empty dropdown.
                    if(!options.length && firstOption){
                        options.push(firstOption);
                    }
                }
            }
        }

        return options;
    }

    public onHoursChange(h: string): void {
        const date: Date = new Date(this.state.selectedDate || 0);
        const timeArray: string[] = h.split(".");
        date.setHours(Number(timeArray[0]));
        date.setMinutes(Number(timeArray[1]));
        this.onChange(date);
    }

    public onChange(date: Date | null | undefined): void {
        this.onSelectDate(date);
        this.props.onChange && this.props.onChange(date || null);
    }
}
