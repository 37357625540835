//1. IMPORTS
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    cicSelected,
    getWizardRecordInformationDataAsync,
    selectCabinetWorkingFileNumber,
    selectDecisionCategoryTermSet,
    selectOrgHierarchy,
    selectRestrictAccessTeams,
    selectSecurityClassificationTermSet,
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4,
    selectSelectedOverrideDefaultAccess,
    selectSelectedRestrictAccessTeams,
    selectSelectedSecurityClassificationsType,
    selectTeamRestrictTermSet,
    selectTermGroup,
    setCabinetWorkingFileNumber,
    setSelectedDecisionCategory,
    setSelectedOrgLevel1,
    setSelectedOrgLevel2,
    setSelectedOrgLevel3,
    setSelectedOrgLevel4,
} from "./../wizardRecordInformation/wizardRecordInformationSlice";
import {
    FieldTypes,
    IContentTypeField,
    IFieldValue,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    IOrgs,
    IRecord,
    ITerm,
    ITermSet,
    ITermGroup,
    IContact,
    IContentType
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    editRecordDetailsHeader,
    fieldDescriptionCabinetWorkingFileNumber,
    fieldLabelTitle,
    keyDetailsTitleDescription,
    loadingMessage,
    fieldDescriptionFlags,
    fieldLabelFlags,
} from "../../providers/Constants/RecordWizardConstants";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import { selectCurrentFieldValue, selectActiveFields, selectCurrentRecord, setFieldValues, updateRecordInformationAsync, selectInactiveFields, selectCurrentRecordType, selectAllFields } from "./manageRecordWrapperSlice";
import {
    resetWizardRecordInformation,
    selectDecisionCategories,
    selectOrgLevel1s,
    selectOrgLevel2s,
    selectOrgLevel3s,
    selectOrgLevel4s,
    setWizardRecordInformation
} from "../wizardRecordInformation/wizardRecordInformationSlice";
import {
    getChoiceOptionFromTerm,
    getISelectOptionPropsFromIOrgLevel,
    getISelectOptionPropsFromIterm
} from "../wizardRecordInformation/WizardRecordInformation";
import { useParams } from "react-router-dom";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import {
    getWizardResponseInformationDataAsync,
    resetWizardResponseInformation,
    selectEnteredTitle,
    setTitle,
    setWizardResponseInformation,
    setSelectedRecordFlags,
    selectSelectedRecordFlags,
    selectRecordFlags,
} from "../wizardResponseInformation/wizardResponseInformationSlice";
import styles from "./ManageRecordDetails.module.scss";
import {
    DefaultButton,
    IChoiceGroupOption,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator,
    Spinner,
    SpinnerSize,
    Text
} from "@fluentui/react";
import { teams } from "../../providers/Constants/TermConstants";
import StringHelper from "../../helpers/StringHelper";
import { getFieldDescription, getFieldDisplayName, isFieldInActive, updateFieldValues } from "../../helpers/ModelHelper";
import TermsHelper from "../../helpers/TermsHelper";
import Choice from "../../components/FormControls/Choice/Choice";
import ContactPicker from "../../components/FormControls/ContactPicker/ContactPicker";
import { getManageDetailsDataAsync, selectDateReceived, selectDateWritten, selectFromContact, selectToContact, setDateReceived, setDateWritten, setFromContact, setManageRecordDetails, setToContact } from "./manageRecordDetails/manageRecordDetailsSlice";
import { useTranslation } from "react-i18next";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import DateHelper from "../../helpers/DateHelper";

export function ManageRecordDetails(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const recordType: IContentType | null = useSelector(selectCurrentRecordType);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValue);
    const decisionCategoryTermSet: ITermSet | null = useSelector(selectDecisionCategoryTermSet);
    const securityClassificationTermSet: ITermSet | null = useSelector(
        selectSecurityClassificationTermSet
    );
    const teamRestrictTermSet: ITermSet | null = useSelector(selectTeamRestrictTermSet);
    const orgHierarchy: IOrgs | null = useSelector(selectOrgHierarchy);
    const recordFlagsTermSet: ITerm[] = useSelector(selectRecordFlags);

    const decisionCategories: ITerm[] = useSelector(selectDecisionCategories);
    const orgLevel1s: IOrgLevel1[] = useSelector(selectOrgLevel1s);
    const orgLevel2s: IOrgLevel2[] = useSelector(selectOrgLevel2s);
    const orgLevel3s: IOrgLevel3[] = useSelector(selectOrgLevel3s);
    const orgLevel4s: IOrgLevel4[] = useSelector(selectOrgLevel4s);
    const termGroup: ITermGroup | null = useSelector(selectTermGroup);

    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);

    const cabinetWorkingFileNumber: string | undefined = useSelector(
        selectCabinetWorkingFileNumber
    );

    const selectedDecisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const selectedOrgLevel1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const selectedOrgLevel2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const selectedOrgLevel3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const selectedOrgLevel4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);

    const selectedRecordFlags: ITerm[] | null = useSelector(selectSelectedRecordFlags);
    const selectedSecurityClassificationsType: ITerm | null = useSelector(
        selectSelectedSecurityClassificationsType
    );

    const selectedOverrideDefaultAccess: boolean = useSelector(selectSelectedOverrideDefaultAccess);
    const selectedRestrictAccessTeams: ITerm[] | null = useSelector(
        selectSelectedRestrictAccessTeams
    );
    const restrictAccessTeams: ITerm[] = useSelector(selectRestrictAccessTeams);
    const isCICSelected: boolean = useSelector(cicSelected);
    const selectedDateWritten: Date | undefined = useSelector(selectDateWritten);
    const selectedDateReceived: Date | undefined = useSelector(selectDateReceived);
    const selectedFromContact: IContact | undefined = useSelector(selectFromContact);
    const selectedToContact: IContact[] | undefined = useSelector(selectToContact);

    const { t } = useTranslation();
    // 3. DEFINE COMPONENT STATE
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [initialized, setInitialized] = React.useState<boolean>(false);
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // 5. DEFINE COMPONENT HOOKS
    const { recordId } = useParams<{ recordId: string }>();


    const readOnly: boolean = React.useMemo(
        () =>
            !currentRecord?.canCurrentUserEditRecord ||
            !!currentRecord?.canCurrentUserEditRecordLimitedAccess,
        [
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.canCurrentUserEditRecordLimitedAccess
        ]
    );

    React.useEffect(() => {
        //INIT WIZARD WITH CURRENT RECORD
        dispatch(getWizardRecordInformationDataAsync(currentRecord?.recordType || "(empty)"));
        dispatch(getWizardResponseInformationDataAsync(currentRecord?.recordType || ""));
        //INI MANAGE DETAILS
        dispatch(getManageDetailsDataAsync());
        return () => {
            //FLUSH WIZARD
            dispatch(resetWizardRecordInformation());
            dispatch(resetWizardResponseInformation());
        };
    }, [dispatch, currentRecord?.recordType]);

    React.useEffect(() => {
        if (
            currentRecord &&
            decisionCategoryTermSet &&
            securityClassificationTermSet &&
            teamRestrictTermSet &&
            orgHierarchy
        ) {
            dispatch(setWizardRecordInformation(currentRecord));
            dispatch(setWizardResponseInformation(currentRecord));
            dispatch(setManageRecordDetails(currentRecord));
        }
    }, [
        dispatch,
        currentRecord,
        decisionCategoryTermSet,
        securityClassificationTermSet,
        teamRestrictTermSet,
        orgHierarchy
    ]);

    const anyChangesApplied: boolean = React.useMemo(() => {
        let result: boolean = !!currentRecord && (
            (!StringHelper.isEqual(currentRecord.title, enteredTitle)
                || !StringHelper.isEqual((currentRecord.decisionCategory || [])[0]?.id, selectedDecisionCategory?.id)
                || !StringHelper.isEqual(currentRecord.organisationLv1, selectedOrgLevel1?.name)
                || !StringHelper.isEqual(currentRecord.organisationLv2, selectedOrgLevel2?.name)
                || !StringHelper.isEqual(currentRecord.organisationLv3, selectedOrgLevel3?.name)
                || !StringHelper.isEqual(currentRecord.organisationLv4, selectedOrgLevel4?.name)
                || !StringHelper.isEqual(currentRecord.cabinetWorkingFolderNumber, cabinetWorkingFileNumber)
                || !StringHelper.isEqual((currentRecord.securityClassification || [])[0]?.name, selectedSecurityClassificationsType?.name))
            || !(currentRecord.recordFlags?.length === selectedRecordFlags?.length && currentRecord.recordFlags?.every(x => selectedRecordFlags?.findIndex(s => s.id === x.id) !== -1))
        );
        if (currentRecord?.fieldValues && currentFieldValues) {
            for (const fv of currentFieldValues) {
                if (currentRecord.fieldValues.some(rv => rv.fieldInternalName === fv.fieldInternalName && rv.valueInJson !== fv.valueInJson)) {
                    result = true;
                    break;
                }
            }
        }
        if(!result && currentRecord && recordType?.isCorrespondenceType){
            //check correspondence fields
            if(!StringHelper.isEqual(currentRecord.from?.id, selectedFromContact?.id)){
                result = true;
            }
            if(!StringHelper.isEqual(currentRecord.to?.map(x=>x.id).join(), selectedToContact?.map(x=>x.id).join())){
                result = true;
            }
            if(selectedDateReceived && !StringHelper.isEqual(currentRecord.dateReceived, DateHelper.GetDateISOWithoutMilliseconds(selectedDateReceived))){
                result = true;
            }
            if(selectedDateWritten && !StringHelper.isEqual(currentRecord.dateWritten, DateHelper.GetDateISOWithoutMilliseconds(selectedDateWritten))){
                result = true;
            }
        }

        return result;
    }, [currentRecord, 
        enteredTitle, 
        selectedDecisionCategory?.id, 
        selectedOrgLevel1?.name, 
        selectedOrgLevel2?.name, 
        selectedOrgLevel3?.name, 
        selectedOrgLevel4?.name, 
        cabinetWorkingFileNumber, 
        selectedSecurityClassificationsType?.name, 
        selectedRecordFlags, 
        currentFieldValues, 
        recordType?.isCorrespondenceType, 
        selectedFromContact?.id, 
        selectedToContact, 
        selectedDateReceived, 
        selectedDateWritten]);

    const requiredFieldsIsNotEmpty: boolean = React.useMemo(() => {
        let result: boolean = !!enteredTitle && !!selectedOrgLevel1 && !!selectedOrgLevel2;

        if (result) {
            const requiredFields: IContentTypeField[] = currentFields?.filter(v => 
                v.isRequired 
                && v.fieldType !== FieldTypes.Role 
                && v.fieldType !== FieldTypes.RoleMultiSelect) || [];
            const emptyFieldValues: IFieldValue[] = currentFieldValues?.filter(v => StringHelper.isJsonEmptyObject(v.valueInJson)) || [];
            const someRequiredValuesAreEmpty: boolean = emptyFieldValues.some(v => requiredFields.some(r => r.internalName === v.fieldInternalName));
            const someRequiredFieldNotExist: boolean = !requiredFields.every(r => currentFieldValues?.some(v => v.fieldInternalName === r.internalName));
            result = !someRequiredValuesAreEmpty && !someRequiredFieldNotExist;
        }

        return result;
    }, [
        enteredTitle,
        selectedOrgLevel1,
        selectedOrgLevel2,
        currentFields,
        currentFieldValues
    ]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return readOnly || updatingInProgress;
    }, [readOnly, updatingInProgress]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked && requiredFieldsIsNotEmpty;
    }, [anyChangesApplied, formIsBlocked, requiredFieldsIsNotEmpty]);

    const cancelBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    }, [anyChangesApplied, formIsBlocked]);

    React.useEffect(() => {
        if (!initialized && currentRecord && enteredTitle) {
            setInitialized(true);
        }
    }, [initialized, currentRecord, enteredTitle]);

    const validateUpdate: () => void = React.useCallback((): void => {
        //validation...
        if (recordId && updateBtnIsActive) {
            setUpdatingInProgress(true);
            setError(undefined);
            const restrictCabinetTeam: ITerm | undefined = restrictAccessTeams.find(r => r.name === teams.cabinet);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: recordId,
                        title: enteredTitle,
                        decisionCategory: selectedDecisionCategory
                            ? [selectedDecisionCategory]
                            : undefined,
                        organisationLv1: selectedOrgLevel1?.name || "",
                        organisationLv2: selectedOrgLevel2?.name || "",
                        organisationLv3: selectedOrgLevel3?.name || "",
                        organisationLv4: selectedOrgLevel4?.name || "",
                        securityClassification: selectedSecurityClassificationsType
                            ? [selectedSecurityClassificationsType]
                            : undefined,
                        overrideDefaultAccess: isCICSelected ? true : selectedOverrideDefaultAccess,
                        restrictAccessTeams: isCICSelected && (!selectedRestrictAccessTeams || selectedRestrictAccessTeams.findIndex(r => r.name === teams.cabinet) === -1)
                            ? [...(selectedRestrictAccessTeams || []), (restrictCabinetTeam || {})]
                            : selectedRestrictAccessTeams || undefined,
                        cabinetWorkingFolderNumber: cabinetWorkingFileNumber,
                        recordFlags: selectedRecordFlags ? selectedRecordFlags : undefined,
                        fieldValues: currentFieldValues || undefined,
                        topicSummary: currentRecord?.topicSummary,
                        from: selectedFromContact,
                        to: selectedToContact,
                        dateReceived: selectedDateReceived?.toISOString() || "",
                        dateWritten: selectedDateWritten?.toISOString() || "",
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                        },
                        onError: e => {
                            setError(e);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [recordId, updateBtnIsActive, restrictAccessTeams, dispatch, enteredTitle, selectedDecisionCategory, selectedOrgLevel1?.name, selectedOrgLevel2?.name, selectedOrgLevel3?.name, selectedOrgLevel4?.name, selectedSecurityClassificationsType, isCICSelected, selectedOverrideDefaultAccess, selectedRestrictAccessTeams, cabinetWorkingFileNumber, selectedRecordFlags, currentFieldValues, currentRecord?.topicSummary, selectedFromContact, selectedToContact, selectedDateReceived, selectedDateWritten]);

    const onCancelClick: () => void = React.useCallback(() => {
        if (
            currentRecord &&
            decisionCategoryTermSet &&
            securityClassificationTermSet &&
            teamRestrictTermSet &&
            orgHierarchy
        ) {
            dispatch(setWizardRecordInformation(currentRecord));
            dispatch(setWizardResponseInformation(currentRecord));
        }
    }, [
        dispatch,
        currentRecord,
        decisionCategoryTermSet,
        securityClassificationTermSet,
        teamRestrictTermSet,
        orgHierarchy
    ]);

    // 6. RETURN RENDER TEMPLATE
    const decisionCategoriesOptions: ISelectOptionProps[] =
        decisionCategories?.map(getISelectOptionPropsFromIterm) || [];
    
    //add fake value if org level 1 renamed in AAD. levels 2-3 handled in slice
    const orgLevel1Options: ISelectOptionProps[] = useMemo(()=>{
        const options:ISelectOptionProps[] = orgLevel1s?.map(getISelectOptionPropsFromIOrgLevel) || [];
        if(orgLevel1s && orgLevel1s.length && selectedOrgLevel1?.name && orgLevel1s.every(x=>x.name !== selectedOrgLevel1?.name)){
            options.push(getISelectOptionPropsFromIOrgLevel(selectedOrgLevel1));
        }
        return options;
    }, [orgLevel1s, selectedOrgLevel1]);

    const orgLevel2Options: ISelectOptionProps[] =
        orgLevel2s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel3Options: ISelectOptionProps[] =
        orgLevel3s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel4Options: ISelectOptionProps[] =
        orgLevel4s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const recordFlagsOptions: ISelectOptionProps[] =
        recordFlagsTermSet
            .map(r => ({ key: r.id || "", text: r.name || "" })) || [];

    const titleDisabled: boolean = formIsBlocked;
    const decisionCategoryDisabled: boolean = formIsBlocked || !decisionCategoriesOptions.length;
    const orgLevel1Disabled: boolean = formIsBlocked || !decisionCategoriesOptions.length;
    const orgLevel2Disabled: boolean = formIsBlocked || !orgLevel2Options.length;
    const orgLevel3Disabled: boolean = formIsBlocked || !orgLevel3Options.length;
    const orgLevel4Disabled: boolean = formIsBlocked || !orgLevel4Options.length;

    const cabinetWorkingFileNumberDisabled: boolean = formIsBlocked;
    const recordFlagDisabled: boolean = formIsBlocked || recordFlagsOptions.length < 1;
    const dateWrittenDisabled: boolean = formIsBlocked;
    const dateReceivedDisabled: boolean = formIsBlocked;
    const fromContactDisabled: boolean = formIsBlocked;
    const toContactDisabled: boolean = formIsBlocked;

    return (
        <div>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>{editRecordDetailsHeader}</h2>
            </div>
            {!initialized ? (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.small}
                        label={loadingMessage}
                    />
                </div>
            ) : (
                <div className={styles.manageRecordDetailsContainer}>
                    <div className={styles.formContainer}>
                        <FieldWrapper description={keyDetailsTitleDescription}>
                            <TextBox
                                name={fieldLabelTitle}
                                required={true}
                                rows={5}
                                defaultValue={enteredTitle}
                                value={enteredTitle}
                                onChange={(ev, newValue) => {
                                    dispatch(setTitle(newValue));
                                }}
                                disabled={titleDisabled}
                            />
                        </FieldWrapper>
                        {decisionCategoriesOptions.length > 0 && !isFieldInActive("ABCDecisionCategory", inactiveFields) && (
                            <FieldWrapper description={getFieldDescription("ABCDecisionCategory", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCDecisionCategory", allFields)}
                                    options={decisionCategoriesOptions}
                                    required={decisionCategoriesOptions.length > 0}
                                    selectedKey={selectedDecisionCategory?.id || ""}
                                    disabled={decisionCategoryDisabled}
                                    onChange={option => {
                                        dispatch(setSelectedDecisionCategory(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel1", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel1", allFields)}
                                options={orgLevel1Options}
                                required={true}
                                disabled={orgLevel1Disabled}
                                selectedKey={selectedOrgLevel1?.name || ""}
                                showAsLoading={orgLevel1Options.length < 1}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel1(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel2", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel2", allFields)}
                                options={orgLevel2Options}
                                required={true}
                                disabled={orgLevel2Disabled}
                                selectedKey={selectedOrgLevel2?.name || undefined}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel2(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        {!isFieldInActive("ABCOrgLevel3", inactiveFields) &&       
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel3", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel3", allFields)}
                                    options={orgLevel3Options}
                                    required={false}
                                    disabled={orgLevel3Disabled}
                                    selectedKey={selectedOrgLevel3?.name || ""}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel3(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }
                        {!isFieldInActive("ABCOrgLevel4", inactiveFields) &&       
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel4", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel4", allFields)}
                                    options={orgLevel4Options}
                                    required={false}
                                    disabled={orgLevel4Disabled}
                                    selectedKey={selectedOrgLevel4?.name || ""}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel4(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }
                        {isCICSelected && !isFieldInActive("ABCCabinetWorkingFolderNumber", inactiveFields) && (
                            <FieldWrapper description={fieldDescriptionCabinetWorkingFileNumber}>
                                <TextBox
                                    name={getFieldDisplayName("ABCCabinetWorkingFolderNumber", allFields)}
                                    required={isCICSelected}
                                    disabled={cabinetWorkingFileNumberDisabled}
                                    defaultValue={cabinetWorkingFileNumber}
                                    onChange={(ev, newValue) => {
                                        dispatch(setCabinetWorkingFileNumber(newValue));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                        {inactiveFields?.every(v => v.internalName !== "ABCRecordFlags") && (
                            <FieldWrapper description={fieldDescriptionFlags}>
                                <Select
                                    name={fieldLabelFlags}
                                    options={recordFlagsOptions}
                                    required={false}
                                    defaultSelectedKey={selectedRecordFlags?.map(x => x?.id || "") || undefined}
                                    disabled={recordFlagDisabled}
                                    onChange={option => {
                                        dispatch(setSelectedRecordFlags(option?.key));
                                    }}
                                    isMultiSelect
                                />
                            </FieldWrapper>)}
                        {recordType?.isCorrespondenceType && (
                            <>
                                <FieldWrapper>
                                    <DatePicker
                                        dateLabel={t("dateWrittenFieldLabel")}
                                        placeholder={t("datePickerPlaceholder")}
                                        required={true}
                                        includeTime={false}
                                        ariaLabel={t("dateWrittenPlaceholderText")}
                                        value={selectedDateWritten}
                                        onChange={date => dispatch(setDateWritten(date))}
                                        disabled={dateWrittenDisabled}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <DatePicker
                                        dateLabel={t("dateReceivedLabel")}
                                        required={true}
                                        includeTime={false}
                                        ariaLabel={t("dateReceivedPlaceholderText")}
                                        placeholder={t("datePickerPlaceholder")}
                                        value={selectedDateReceived}
                                        onChange={date => dispatch(setDateReceived(date))}
                                        disabled={dateReceivedDisabled}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <ContactPicker
                                        label={t("fromContactFieldLabel")}
                                        isRequired={true}
                                        selectedUsers={
                                            selectedFromContact ? [selectedFromContact] : undefined
                                        }
                                        onChange={(user: IContact[] | undefined) =>
                                            dispatch(setFromContact(user ? user[0] : null))
                                        }
                                        disabled={fromContactDisabled}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <ContactPicker
                                        label={t("toContactFieldLabel")}
                                        isRequired={true}
                                        selectedUsers={selectedToContact}
                                        multi
                                        onChange={
                                            (user: IContact[] | undefined) =>
                                                dispatch(setToContact(user || null))
                                        }
                                        disabled={toContactDisabled}
                                    />
                                </FieldWrapper>
                            </>
                        )}
                        {currentFields?.filter(v => v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && !v.notEditable).map((field, index) => {
                            switch (field.fieldType) {
                                case FieldTypes.Text:
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <TextBox
                                                name={field.displayName}
                                                required={field.isRequired}
                                                value={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                                disabled={formIsBlocked}
                                                onChange={(ev, newValue) => {
                                                    dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                                }}
                                            />
                                        </FieldWrapper>);
                                case FieldTypes.MultilineText:
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <TextBox
                                                name={field.displayName}
                                                required={field.isRequired}
                                                rows={5}
                                                value={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                                disabled={formIsBlocked}
                                                onChange={(ev, newValue) => {
                                                    dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                                }}
                                            />
                                        </FieldWrapper>);
                                case FieldTypes.RadioManagedMetadata: {
                                    const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                                    const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                                    //get first selected term
                                    const currentValue: string | undefined = selectedTerms?.length ? selectedTerms.map(v => v?.Id)[0] : undefined;
                                    const options: IChoiceGroupOption[] = termSet?.terms?.map(getChoiceOptionFromTerm) || [];
                                    selectedTerms.forEach(v => {
                                        if (!options.some(o => o.key === v.Id)) {
                                            options.push({ key: v.Id, text: v.Name });
                                        }
                                    });
                                    
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <Choice
                                                name={field.displayName}
                                                required={field.isRequired}
                                                options={options}
                                                defaultSelectedKey={currentValue}
                                                onChange={option => {
                                                    selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                                    dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                                }}
                                            />
                                        </FieldWrapper>);
                                }

                                case FieldTypes.SelectManagedMetadata:
                                case FieldTypes.MultiSelectManagedMetadata: {
                                    const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                                    const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                                    const options: ISelectOptionProps[] = termSet?.terms?.map(getISelectOptionPropsFromIterm) || [];
                                    selectedTerms.forEach(v => {
                                        if (!options.some(o => o.key === v.Id)) {
                                            options.push({ key: v.Id, text: v.Name, disabled: true });
                                        }
                                    });
                                    const currentValues: string[] = selectedTerms.map(v => v?.Id);
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <Select
                                                name={field.displayName}
                                                options={options}
                                                required={field.isRequired}
                                                defaultSelectedKey={currentValues}
                                                disabled={formIsBlocked}
                                                hideDisabledOptions={true}
                                                onChange={option => {
                                                    if (selectedTerms.some(v => v.Id === option?.key)) {
                                                        selectedTerms.splice(selectedTerms.findIndex(v => v.Id === option?.key), 1);
                                                    } else {
                                                        selectedTerms.push({ Id: option?.key || "", Name: option?.text || "" });
                                                    }
                                                    if (field.fieldType === FieldTypes.SelectManagedMetadata) {
                                                        selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                                    }
                                                    dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                                }}
                                                isMultiSelect={field.fieldType === FieldTypes.MultiSelectManagedMetadata}
                                            />
                                        </FieldWrapper>);
                                }
                                default:
                                    return (<></>);
                            }
                        })}
                    </div>

                    {!readOnly && (
                        <div className={styles.footer}>
                            <Separator />
                            {!!error && (
                                <div>
                                    <MessageBar
                                        isMultiline={true}
                                        messageBarType={MessageBarType.error}
                                    >
                                        {error.message}
                                    </MessageBar>
                                </div>
                            )}
                            <div className={styles.btnsContainer}>
                                <PrimaryButton
                                    onClick={validateUpdate}
                                    disabled={!updateBtnIsActive}
                                    className={styles.updateBtn}
                                >
                                    <Text className={styles.text}>Save</Text>
                                    {updatingInProgress && (
                                        <Spinner className={styles.spinner} size={SpinnerSize.small} />
                                    )}
                                </PrimaryButton>
                                <DefaultButton
                                    disabled={!cancelBtnIsActive}
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </DefaultButton>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
