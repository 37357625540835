import React from "react";
import {
    DefaultButton,
    PrimaryButton
} from "@fluentui/react";
import styles from "./RefinerPanel.module.scss";
import {
    FilterButtonIcon,
    FilterButtonText
} from "../../../providers/Constants/FormControlConstants";
import { MessageBar, MessageBarType, Panel, PanelType, Separator } from "@fluentui/react";
import CollapsibleContainer from "../CollapsibleContainer/CollapsibleContainer";
import TextRefiner from "./TextRefiner";
import { IRefiner } from "../../../model/Refiner";
import UserRefiner from "./UserRefiner";
import DateRefiner, { TimeOnlyRefiner } from "./DateRefiner";
import { selectIsMobile } from "../../../app/globalSlices/contextSlice";
import { useSelector } from "react-redux";
import { ControlShimmer } from "../ControlShimmer/ControlShimmer";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";
import BooleanRefiner from "./BooleanRefiner";

interface IRefinerProps {
    refiners?: IRefiner[];
    selectedRefiners?: IRefiner[];
    inProgress: boolean;
    errorMessage?: string;
    onOpen: () => void;
    onClose: () => void;
    onChange(newRefiners: IRefiner[] | undefined): void;
}
/* TODO:
- this component should get "initial state" from parent component through props
- all selected refiners should come from redux store and should be used as a "defaults"
- all actions on child components should be tracked here in state and will change "defaults" state
- "Apply and save" should get current state and send it through callback function which will update redux store
- "Clear filters" should reset all selected refiners in state of this component
- current layout markup should be moved into RecordsTable.tsx. 
    Refiner component should include only button and panel.
*/
export default function RefinerPanel(props: IRefinerProps): JSX.Element {
    const isMobile: boolean = useSelector(selectIsMobile);
    const [showPanel, setShowPanel] = React.useState<boolean>(false);
    const [expandedRefiner, setExpandedRefiner] = React.useState<IRefiner | undefined>();
    const [selectedRefiners, setSelectedRefiners] = React.useState<IRefiner[] | undefined>(props.selectedRefiners);

    React.useEffect(() => {
        setSelectedRefiners(props.selectedRefiners);
    }, [props.selectedRefiners, setSelectedRefiners]);

    function onShowPanel() {
        setShowPanel(true);
        props.onOpen();
    }
    function onDismissPanel() {
        setShowPanel(false);
    }

    function onApplyAndSave() {
        props.onChange(selectedRefiners);
        setShowPanel(false);
    }

    function onClearFilters() {
        props.onChange(undefined);
    }

    const onSelectedRefinerEntriesChange: (r: IRefiner) => void = React.useCallback((refiner: IRefiner): void => {
        const newSelectedRefiners: IRefiner[] = [...selectedRefiners || []];
        const idx: number = newSelectedRefiners.findIndex(r => r.name === refiner.name);
        switch (refiner.type) {
            case (ManagedPropertyViewType.Number): 
            case (ManagedPropertyViewType.Text): 
            case (ManagedPropertyViewType.User):
            case (ManagedPropertyViewType.Boolean): {
                const hasEntries: boolean = !!(refiner.entries && refiner.entries.length);
                if (idx > -1) {
                    if (hasEntries) {
                        newSelectedRefiners.splice(idx, 1, refiner);
                    } else {
                        newSelectedRefiners.splice(idx, 1);
                    }
                } else {
                    if (hasEntries) {
                        newSelectedRefiners.push(refiner);
                    }
                }
                break;
            }
            case (ManagedPropertyViewType.Date): {
                const hasTimeTerm: boolean = !!(refiner.to && refiner.from);
                if (idx > -1) {
                    if (hasTimeTerm) {
                        newSelectedRefiners.splice(idx, 1, refiner);
                    } else {
                        newSelectedRefiners.splice(idx, 1);
                    }
                } else {
                    if (hasTimeTerm) {
                        newSelectedRefiners.push(refiner);
                    }
                }
                break;
            }
            case (ManagedPropertyViewType.NumberAsTime): {
                const hasTimeTerm: boolean = !!(refiner.to && refiner.from);
                if (idx > -1) {
                    if (hasTimeTerm) {
                        newSelectedRefiners.splice(idx, 1, refiner);
                    } else {
                        newSelectedRefiners.splice(idx, 1);
                    }
                } else {
                    if (hasTimeTerm) {
                        newSelectedRefiners.push(refiner);
                    }
                }
                break;
            }
        }
        setSelectedRefiners(newSelectedRefiners);
    }, [selectedRefiners]);

    function RenderRefiner(refiner: IRefiner): JSX.Element | undefined {
        let result: JSX.Element | undefined;
        const expanded: boolean = refiner === expandedRefiner;
        const value: IRefiner | undefined = (selectedRefiners || []).find(r => r.name === refiner.name);
        switch (refiner.type) {
            case (ManagedPropertyViewType.Text): {
                result = (
                    <TextRefiner
                        refiner={refiner}
                        expanded={expanded}
                        value={value}
                        onChange={onSelectedRefinerEntriesChange}
                        onExpand={() => { setExpandedRefiner(expanded ? undefined : refiner); }}
                    />
                );
                break;
            }
            case (ManagedPropertyViewType.User): {
                result = (
                    <UserRefiner
                        refiner={refiner}
                        expanded={expanded}
                        value={value}
                        onChange={onSelectedRefinerEntriesChange}
                        onExpand={() => { setExpandedRefiner(expanded ? undefined : refiner); }}
                    />
                );
                break;
            }
            case (ManagedPropertyViewType.Date): {
                result = (
                    <DateRefiner
                        refiner={refiner}
                        value={value}
                        onChange={onSelectedRefinerEntriesChange}
                    />
                );
                break;
            }            
            case (ManagedPropertyViewType.NumberAsTime): {
                result = (
                    <TimeOnlyRefiner
                        refiner={refiner}
                        value={value}
                        onChange={onSelectedRefinerEntriesChange}
                    />
                );
                break;
            }
            case (ManagedPropertyViewType.Boolean): {
                result = (
                    <BooleanRefiner
                        refiner={refiner}
                        value={value}
                        onChange={onSelectedRefinerEntriesChange}
                    />
                );
                break;
            }
        }
        return result;
    }

    return (
        <div className={styles.refinerPanelContainer}>
            <DefaultButton
                text={FilterButtonText}
                className={styles.btn}
                iconProps={{ iconName: FilterButtonIcon }}
                onClick={onShowPanel}
            />
            <Panel
                headerText="Filters"
                type={PanelType.customNear}
                onDismiss={onDismissPanel}
                isOpen={showPanel}
                customWidth={isMobile ? "100%" : "400px"}
                // isBlocking={false}
                isLightDismiss
                className={styles.refinerPanel}
            >
                <div className={styles.refinerContainer}>
                    <div className={styles.header}>
                        {props.errorMessage && 
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                            >{props.errorMessage}</MessageBar>
                        }
                    </div>
                    <Separator className={styles.separator} />
                    {!expandedRefiner && !props.inProgress &&
                        <>
                            <div className={styles.bodyContainer}>
                                {(props.refiners || []).map((r, i) =>
                                    <CollapsibleContainer
                                        key={i}
                                        title={r.displayName || r.name}
                                        className={styles.refinerAccordion}
                                        highlightContentBg={true}
                                        highlightHeadingBg={true}
                                        expandedDefault={false}
                                    >
                                        <div className={styles.refiner}>
                                            {RenderRefiner(r)}
                                        </div>
                                    </CollapsibleContainer>)
                                }
                            </div>
                            <div className={styles.additionalBox}>
                            </div>
                            <div className={styles.footerContainer}>
                                <Separator className={styles.separator} />
                                <div className={styles.footer}>
                                    <PrimaryButton text="Apply and save" onClick={onApplyAndSave} />
                                    <DefaultButton text="Clear filters" onClick={onClearFilters} />
                                </div>
                            </div>
                        </>
                    }
                    {!!expandedRefiner && !props.inProgress && RenderRefiner(expandedRefiner)}
                    {props.inProgress && <ControlShimmer rows={8} rowHeight={45} className={styles.shimmer} />}
                </div>
            </Panel>
        </div>
    );
}
