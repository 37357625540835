import React from "react";
import styles from "./WizardKeyRoles.module.scss";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { useTranslation } from "react-i18next";

export interface IWizardKeyRolesContainerProps {
    form: JSX.Element;
}

export function WizardKeyRolesContainer(props: IWizardKeyRolesContainerProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <div className={styles.wizardKeyRolesContainer}>
            <div className={styles.headerContainer}>
                <FormHeader strings={
                    {
                        Header:  t("keyRolesHeader"),
                        DotPoints:[ t("keyRolesDotPoint1"), t("keyRolesDotPoint2"), t("keyRolesDotPoint3"), t("keyRolesDotPoint4") ]                   
                    }} />
                {props.form}
            </div>
        </div>
    );
}
