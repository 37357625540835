import { Icon } from "@fluentui/react";
import React from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../app/Constants";
import DateHelper from "../../../helpers/DateHelper";
import { StageTypes } from "../../../providers/Constants/TermConstants";
import { IRecordResult } from "../../../services/Api/executor/IApiServiceExecutor";
import { getRecordColor } from "../../recordsTable/RecordsTableCommon";
import styles from "./RecordItem.module.scss";
import { useTranslation } from "react-i18next";

interface IRecordItem{
    record: IRecordResult,
    isMobile: boolean
}

export function RecordItem({record, isMobile}: IRecordItem): JSX.Element {
    const recordColor: React.CSSProperties = getRecordColor(record.recordType?.value?.prefix || "", record.recordType?.value?.color);
    const recordStatus: string = getRecordStatus(record);
    const link: string = AppRoutes.buildUrl(AppRoutes.manageRecord, {
        recordId: record.recordId?.value || ""
    });
    const {t} = useTranslation();

    function getRecordStatus (record: IRecordResult): string{
        if (!record.onhold 
            && !record.noFurtherAction?.value
            && !record.withdrawn?.value
            && !record.cancelled?.value
            && !record.superseded?.value
            && record.stage?.value === StageTypes.Closed
        ){
            return t("searchResultClosed");
        }else{  
            return t("searchResultOpen");
        }
    }

    function getStatusColor(status: string): React.CSSProperties{
        if(status === t("searchResultOpen")){
            return {color: "green"};
        }

        return {color: "red"};
    }

    return(
        <div className={`${styles.recordItem} 
            ${isMobile ? styles.mobileRecordItem : ""}
        `}>
            <Link
                className={styles.recordLink}
                to={link}
                target={"_self"}
            >
                <div style={recordColor} className={styles.recordDoc}>
                    <Icon className={styles.iconColumnIcon} iconName={"TextDocument"} ariaLabel={"Document icon"}/>
                    {record.recordId?.value}
                </div>
            </Link>
            <div className={styles.recordInformation}>
                <div className={styles.recordTitle}>
                    {record.title?.value}
                </div>
                <div className={styles.stageSection}>
                    <div>
                        <span className={styles.header}>{t("recordStatus")}</span>
                :
                        <span style={getStatusColor(recordStatus)} className={styles.value}>{recordStatus}</span>
                    </div>
                    <div>
                        <span className={styles.header}>{t("recordStage")}</span>
                :
                        <span className={styles.value}>{record.stage?.value}</span>
                    </div>
                </div>
                <div className={styles.modified}>
                    {`${t("lastModified")}: ${record.modified?.value ? DateHelper.ToDateTimeString(new Date(record.modified.value)) : ""}`}
                </div>
            </div>  
        </div>
    );
}
