import React from "react";
import {
    DefaultButton,
    Icon,
    Panel,
    Text,
    PanelType,
    Separator,
    Spinner,
    SpinnerSize,
    TextField,
    MessageBar,
    MessageBarType
} from "@fluentui/react";
import styles from "../ViewRecordActivity.module.scss";
import IApiService from "../../../../services/Api/IApiService";
import {
    IBaseFile,
    IRecord,
    IRecordActivities,
    IRecordActivity,
    IUser
} from "../../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../../providers/DependencyResolver/DependencyResolver";
import DateHelper, { regExDateISOFormat } from "../../../../helpers/DateHelper";
import PersonHoverCard from "../../PersonHoverCard/PersonHoverCard";
import { useSelector } from "react-redux";
import { selectCurrentRecord } from "../../../../features/manageRecordWrapper/manageRecordWrapperSlice";
import { fieldWhoComments, fieldWhoCommentsDescription } from "../../../../providers/Constants/FormControlConstants";

interface IRecordActivityPanelProps {
    recordId?: string;
    onCancel: () => void;
    panelTitle: string;
}

export default function RecordActivityPanel({
    recordId,
    onCancel,
    panelTitle
}: IRecordActivityPanelProps): JSX.Element {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);

    const [inGettingDataProgress, setInGettingDataProgress] = React.useState<boolean>(false);
    const [inProgress, setInProgress] = React.useState<boolean>(false);

    const [recordActivity, setRecordActivity] = React.useState<IRecordActivity[] | undefined>(
        undefined
    );
    const [activityComment, setActivityComment] = React.useState<string>("");
    const [activityCsv, setActivityCsv] = React.useState<IBaseFile | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

    const readOnly: boolean = React.useMemo(
        () => !currentRecord?.canCurrentUserEditRecord || !!currentRecord?.canCurrentUserEditRecordLimitedAccess,
        [currentRecord?.canCurrentUserEditRecordLimitedAccess, currentRecord?.canCurrentUserEditRecord]
    );

    const getRecordActivities = async (recordId: string) => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const recordActivities: IRecordActivities | null = await apiService.GetRecordActivityLog(
            recordId
        );
        return recordActivities;
    };

    React.useEffect(() => {
        (async () => {
            try {
                setInGettingDataProgress(true);
                if (recordId) {
                    const recordActivities: IRecordActivities | null = await getRecordActivities(recordId);
                    setRecordActivity(recordActivities?.activities);
                }
            } finally {
                setInGettingDataProgress(false);
            }
        })();
    }, [recordId]);

    const getCastedStringValueByDate: (initialStringValue: string) => string = React.useCallback(
        (initialStringValue: string): string => {
            let castedStringValue: string = initialStringValue;
            if (regExDateISOFormat.test(initialStringValue) || regExDateISOFormat.test(initialStringValue)) {
                const regEx: RegExp = new RegExp(regExDateISOFormat);
                const findedISODates: RegExpMatchArray[] = [...initialStringValue.matchAll(regEx)];
                for (const isoDateItem of findedISODates) {
                    castedStringValue = castedStringValue.replace(
                        isoDateItem[0],
                        DateHelper.ToDDMMYYYYTimeString(new Date(isoDateItem[0]))
                    );
                }
                return castedStringValue;
            } else {
                return castedStringValue;
            }
        },
        []
    );

    const getCastedStatementElement: (
        statement: string,
        users: IUser[]
    ) => JSX.Element[] | JSX.Element | undefined = React.useCallback(
        (statement: string, users: IUser[]): JSX.Element[] | JSX.Element | undefined => {
            let castedStatementElement: JSX.Element[] | JSX.Element | undefined = undefined;
            const updatedStatementByDate: string = getCastedStringValueByDate(statement);
            castedStatementElement = <div>{updatedStatementByDate}</div>;
            if (users.length) {
                const marker: string = "$#";
                let updatedStatement: string = updatedStatementByDate;
                const initialUsersDisplayNames: string = users
                    .map(userItem => userItem.displayName)
                    .join("|");
                const usersDisplayNamesRegEx: RegExp = new RegExp(initialUsersDisplayNames, "g");
                if (usersDisplayNamesRegEx.test(updatedStatement)) {
                    const regEx: RegExp = new RegExp(initialUsersDisplayNames, "g");
                    const findedUsers: RegExpMatchArray[] = [...updatedStatement.matchAll(regEx)];
                    for (let index: number = 0; index < findedUsers.length; index++) {
                        const findedUser: RegExpMatchArray = findedUsers[index];
                        updatedStatement =
                            updatedStatement.slice(0, (findedUser.index || 0) + index * 4) +
                            marker +
                            updatedStatement.slice(
                                (findedUser.index || 0) + index * 4,
                                (findedUser.index || 0) + findedUser[0].length + index * 4
                            ) +
                            marker +
                            updatedStatement.slice(
                                (findedUser.index || 0) + findedUser[0].length + index * 4
                            );
                    }
                    const statementElements: JSX.Element[] = updatedStatement
                        .split(marker)
                        .map(statementItem => {
                            if (statementItem) {
                                const findedUser: IUser | undefined = users.find(
                                    userItem =>
                                        userItem.displayName?.toLowerCase() ===
                                        statementItem.toLowerCase()
                                );
                                if (findedUser) {
                                    return (
                                        <PersonHoverCard
                                            className={styles.people}
                                            person={findedUser}
                                        />
                                    );
                                } else {
                                    return <>{statementItem}</>;
                                }
                            } else {
                                return <></>;
                            }
                        });
                    castedStatementElement = statementElements;
                }
            }
            return castedStatementElement;
        },
        [getCastedStringValueByDate]
    );

    const recordActivityElements: JSX.Element = React.useMemo((): JSX.Element => {
        return recordActivity ? (
            <div className={styles.recordActivityContainer}>
                {recordActivity.map((activityItem, index) => {
                    return (
                        <div key={index} className={styles.activityItem}>
                            <div className={styles.statementContainer}>
                                <Icon iconName={activityItem.icon} />
                                <div className={styles.statementContant}>
                                    {getCastedStatementElement(activityItem.statement, activityItem.users)}
                                </div>
                            </div>

                            <div className={styles.activityInfoContainer}>
                                {activityItem.listItems &&
                                    activityItem.listItems.map(li => {
                                        return (
                                            <div
                                                key={`${index}_${li.label}`}
                                                className={styles.activityLabel}
                                            >
                                                <span>{li.label}:</span>
                                                {getCastedStringValueByDate(li.text)}
                                            </div>
                                        );
                                    })}
                                <div className={styles.activityDate}>
                                    {DateHelper.ToDateTimeString(
                                        new Date(activityItem.date),
                                        undefined,
                                        true
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        ) : (
            <></>
        );
    }, [getCastedStatementElement, getCastedStringValueByDate, recordActivity]);

    const onConfirm: () => Promise<void> = React.useCallback(async (): Promise<void> => {
        try {
            setInProgress(true);
            if (recordId) {
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                await apiService.UpdateActivityLog({
                    recordId: recordId,
                    comment: activityComment
                });

                const recordActivities: IRecordActivities | null = await getRecordActivities(recordId);
                setRecordActivity(recordActivities?.activities);
            }
            return;
        } finally {
            setInProgress(false);
            setActivityComment("");
        }
    }, [recordId, activityComment]);

    const onExportAsCsv: () =>  Promise<void> = React.useCallback(async (): Promise<void> => {
        try {
            setInProgress(true);
            setErrorMessage(undefined);
            if (recordId) {
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const csvFile: IBaseFile | null = await apiService.GetRecordActivityCsv(recordId);
                if(csvFile){
                    setActivityCsv(csvFile);
                } else {
                    setErrorMessage("Unable to create CSV report ...");
                }
            }
        } finally {
            setInProgress(false);
        }
    },[recordId]);

    return (
        <Panel
            isOpen={true}
            onDismiss={onCancel}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={panelTitle}
        >
            {inGettingDataProgress ? (
                <Spinner label={"Loading record activity"} size={SpinnerSize.large} />
            ) : (
                <>
                    <div className={styles.panelContainer}>
                        <div className={styles.exportCsvContainer}>
                            {!activityCsv ? 
                                <DefaultButton
                                    disabled={inProgress || !recordId}
                                    onClick={async () => await onExportAsCsv()}
                                >
                                    <>
                                        <Text className={styles.text}>Export to Excel</Text>
                                        {inProgress && (<Spinner className={styles.spinner} size={SpinnerSize.small} />)}
                                    </>
                                </DefaultButton>
                                : <DefaultButton 
                                    text={"Open report"}
                                    primary
                                    disabled={inProgress || !recordId}
                                    href={`${activityCsv.path}?web=1`}
                                    target={"_blank"}
                                    rel="noreferrer"
                                />
                            }
                        </div>
                        <div>
                            {!!errorMessage && 
                                <MessageBar
                                    messageBarType={MessageBarType.blocked}
                                    isMultiline={true}
                                >{errorMessage}</MessageBar>
                            }
                        </div>
                        <Separator />
                        {!readOnly && 
                            <>
                                <div className={styles.fieldsContainer}>
                                    <TextField
                                        placeholder={"Type your comment here..."}
                                        multiline
                                        rows={3}
                                        value={activityComment}
                                        disabled={inProgress}
                                        onChange={(
                                            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                            newValue?: string | undefined
                                        ) => setActivityComment(newValue || "")}
                                    />
                                    <div className={styles.whoComments} title={fieldWhoCommentsDescription}>{fieldWhoComments}</div>
                                    <DefaultButton
                                        className={styles.addCommentButton}
                                        text={"Add comment"}
                                        primary
                                        disabled={inProgress || !recordId || !activityComment}
                                        onClick={async () => await onConfirm()}
                                    />
                                </div>
                                <Separator />
                            </>
                        }
                        {recordActivityElements}
                    </div>
                </>
            )}
        </Panel>
    );
}
