import React, {
    useState
} from "react";
import {
    DefaultButton,
    IIconProps,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import styles from "./PdfPackButton.module.scss";
import IApiService from "../../../services/Api/IApiService";
import {
    IGeneratePdfPackRequest
} from "../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import { PrimaryButton } from "@fluentui/react";

interface IPdfPackButtonProps {
    text: string;
    loadingText: string;
    openText: string;
    buttonClass?: string;
    spinnerClass?: string;
    recordId: string | undefined;
    title: string | undefined;
    onError: (erroMessage: string | undefined) => void;
}

export default function PdfPackButton(props: IPdfPackButtonProps): JSX.Element {
    const iconPdfPack: IIconProps = { iconName: "pdf" };

    const [pdfPackFile, setPdfFile] = useState<{ fileName: string; filePath: string } | null>(null);
    const [pdfPackLoading, setPdfPackLoading] = useState<boolean>(false);

    const generatePdfPack = async () => {
        setPdfPackLoading(true);
        setPdfFile(null);
        props.onError(undefined);
        if (props.recordId) {
            const resolver: DependencyResolver = new DependencyResolver();
            const apiService: IApiService = resolver.ResolveIApiService();

            const pdfPackRequest: IGeneratePdfPackRequest = {
                recordId: props.recordId,
                title: props.title
            };

            apiService.GeneratePdfPack(pdfPackRequest).then(res => {
                if (res) {
                    setPdfFile(res);
                    setPdfPackLoading(false);
                } else {
                    props.onError("Unable to create pdf pack ...");
                    setPdfPackLoading(false);
                }
            });
        }
    };

    const onRenderButtonIcon = (): JSX.Element=>{
        return <Spinner
            size={SpinnerSize.medium}
            className={props.spinnerClass || styles.spinnerStyling}
            ariaLabel={"Loading spinner"}
        />;
    };

    return (
        <>
            {!pdfPackFile 
                ? <DefaultButton
                    text={pdfPackLoading 
                        ? props.loadingText
                        :  props.text
                    }
                    onClick={() => generatePdfPack()}
                    allowDisabledFocus
                    disabled={pdfPackLoading}
                    iconProps={iconPdfPack}
                    className={props.buttonClass}
                    onRenderIcon={pdfPackLoading ? onRenderButtonIcon : undefined}
                    ariaLabel={`${!pdfPackLoading ? props.text : props.loadingText} button`}
                />
                : <PrimaryButton 
                    text={props.openText}
                    href={pdfPackFile.filePath}
                    iconProps={iconPdfPack}
                    className={props.buttonClass}
                    target="_blank"
                    rel="noreferrer"
                />
            }
        </>
    );
}
