import React from "react";
import { DefaultButton } from "@fluentui/react";
import RecordActivityPanel from "./RecordActivityPanel/RecordActivityPanel";

import styles from "./ViewRecordActivity.module.scss";
import { useHistory } from "react-router-dom";
import UrlHelper from "../../../helpers/UrlHelper";

interface IViewRecordActivityProps {
    recordId?: string;
}
const recordActivityTabKey: string = "showRecordActivity";

export default function ViewRecordActivity({ recordId }: IViewRecordActivityProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const isPanelActive: boolean = new URLSearchParams(history.location.search).get(recordActivityTabKey) === "1";

    const [showActivityPanel, setShowActivityPanel] = React.useState<boolean>(isPanelActive);
    

    const onViewRecordActivityClick: () => void = () => {
        history.push({ search: UrlHelper.castUrlWithQueryStringParam("1", recordActivityTabKey, history.location.search) });
        setShowActivityPanel(true);
    };

    const onCancelViewRecordActivityClick: () => void = () => {
        history.push({ search: UrlHelper.castUrlWithQueryStringParam( undefined, recordActivityTabKey, history.location.search) });
        setShowActivityPanel(false);
    };

    return (
        <>
            <DefaultButton
                className={styles.viewButton}
                text={"View record activity"}
                iconProps={{ iconName: "OfficeChat" }}
                onClick={onViewRecordActivityClick}
                ariaLabel={"View record activity button"}
            />
            {showActivityPanel && (
                <RecordActivityPanel
                    recordId={recordId}
                    panelTitle={"Activity"}
                    onCancel={onCancelViewRecordActivityClick}
                />
            )}
        </>
    );
}
