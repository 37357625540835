export const WordDocument: string = "WordDocument";
export const PowerPointDocument: string = "PowerPointDocument";
export const ExcelDocument: string = "ExcelDocument";
export const TextDocument: string = "TextDocument";
export const PDFDocument: string = "PDF";
export const Email: string = "Mail";
export const Meeting: string = "Event";
export const OtherDocument: string = "TextDocument";
export const Image: string = "PictureCenter";
export const Video: string = "MSNVideos";

export const Record: string = "Product";
