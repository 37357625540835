import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    getWizardResponseInformationDataAsync,
    selectDateRequested,
    selectEnteredSummary,
    selectEnteredTitle,
    selectRequestFromTypes,
    selectSelectedRequestFrom,
    selectSelectedTimeframe,
    selectSignatureRequired,
    selectTimeframeTypes,
    setSelectedRequestFrom,
    setSelectedSignatureRequired,
    setSelectedTimeframe,
    setSummary,
    setTitle,
    signatureTypes,
    selectSelectedRecordFlags,
    selectRecordFlags,
    setSelectedRecordFlags,
    selectInactiveFields,
    selectActiveFields,
    selectTermGroup,
    setDateRequested,
    selectAllFields,
} from "./wizardResponseInformationSlice";
import styles from "./WizardResponseInformation.module.scss";
import { selectWizardType, setPreventNext, WizardType } from "../recordWizardWrapper/recordWizardWrapperSlice";
import { FieldTypes, IContentType, IContentTypeField, IFieldValue, ITerm, ITermGroup, ITermSet } from "../../services/Api/executor/IApiServiceExecutor";
import {
    fieldLabelRequestFrom,
    replyInformationHeaderStrings,
    responseInformationHeaderStrings,
    eventInformationHeaderStrings,
    fieldLabelSignatureRequired,
    fieldLabelFlags,
    fieldDescriptionFlags,
    fieldDescriptionSignatureRequired,
} from "../../providers/Constants/RecordWizardConstants";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import Choice, { IChoiceOptionProps } from "../../components/FormControls/Choice/Choice";
import DateHelper from "../../helpers/DateHelper";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import { IChoiceGroupOption, Spinner } from "@fluentui/react";
import { selectSelectedRecordType } from "../wizardCreateRecord/wizardCreateRecordSlice";
import { getFieldDescription, getFieldDisplayName, isFieldInActive, updateFieldValues } from "../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";
import TermsHelper from "../../helpers/TermsHelper";
import { getChoiceOptionFromTerm, getISelectOptionPropsFromIterm } from "../wizardRecordInformation/WizardRecordInformation";
import { selectCurrentFieldValues, setFieldValues } from "../wizardRecordInformation/wizardRecordInformationSlice";
import StringHelper from "../../helpers/StringHelper";

export const getIChoiceOptionPropsFromIterm = (term: ITerm): IChoiceOptionProps => {
    return {
        key: term.id || "",
        text: term.name || ""
    };
};

export function WizardResponseInformation(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const { t } = useTranslation();
    const requestFromTerms: ITerm[] = useSelector(selectRequestFromTypes);
    const timeframeTerms: ITerm[] = useSelector(selectTimeframeTypes);
    const recordFlagsTerms: ITerm[] = useSelector(selectRecordFlags);

    const selectedRequestFrom: ITerm | null = useSelector(selectSelectedRequestFrom);
    const enteredSummary: string | undefined = useSelector(selectEnteredSummary);
    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);
    const wizardType: WizardType = useSelector(selectWizardType);
    const selectedDateRequested: Date | undefined = useSelector(selectDateRequested);
    const selectedTimeframe: ITerm | null = useSelector(selectSelectedTimeframe);
    const selectedSignatureRequired: boolean | undefined = useSelector(selectSignatureRequired);
    const selectedRecordFlags: ITerm[] | null = useSelector(selectSelectedRecordFlags);
    const termGroup: ITermGroup | null = useSelector(selectTermGroup);
    
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValues);

    // Parliamentary type of record states items
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    // NOTE: No component hooks
    React.useEffect(() => {
        const requiredFields: IContentTypeField[] = currentFields?.filter(v => v.wizardPosition == 1 && v.isRequired && v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && !v.notEditable) || [];
        const emptyFieldValues: IFieldValue[] = currentFieldValues?.filter(v => StringHelper.isJsonEmptyObject(v.valueInJson)) || [];
        const someRequiredValuesAreEmpty: boolean = emptyFieldValues.some(v => requiredFields.some(r => r.internalName === v.fieldInternalName));
        const someRequiredFieldNotExist: boolean = !requiredFields.every(r => currentFieldValues?.some(v => v.fieldInternalName === r.internalName));
        const isTitleImportant: boolean = wizardType !== WizardType.Correspondence && !enteredTitle;
        const modelIsValid: boolean = !someRequiredValuesAreEmpty && !someRequiredFieldNotExist && !isTitleImportant && !!allFields?.length;
        dispatch(setPreventNext(!modelIsValid));
    }, [inactiveFields, dispatch, enteredTitle, selectedRequestFrom, wizardType, currentFields, currentFieldValues, allFields]);

    React.useEffect(() => {
        dispatch(getWizardResponseInformationDataAsync(selectedRecordType?.rowKey || ""));
    }, [dispatch, selectedRecordType]);

    React.useEffect(() => {
        if (!selectedDateRequested) {
            const date: Date = new Date();
            date.setHours(17);
            dispatch(setDateRequested(date));
        }
    }, [dispatch, selectedDateRequested]);

    // 6. RETURN RENDER TEMPLATE
    const requestFromOptions: IChoiceOptionProps[] = requestFromTerms?.map(
        getIChoiceOptionPropsFromIterm
    );

    const timeFrameOptions: IChoiceOptionProps[] = timeframeTerms?.map(
        getIChoiceOptionPropsFromIterm
    );
    const recordFlagsOptions: ISelectOptionProps[] =
        recordFlagsTerms
            .map(r => ({ key: r.id || "", text: r.name || "" })) || [];

    const currDate: Date = React.useMemo(() => new Date(), []);

    const fieldsElementsForOtherWizardType: JSX.Element = React.useMemo(() => {
        return (<>
            {allFields?.length ? 
                <FieldWrapper description={getFieldDescription("Title", allFields)} >
                    <TextBox
                        name={getFieldDisplayName("Title", allFields)}
                        required={true}
                        rows={5}
                        defaultValue={enteredTitle}
                        onChange={(ev, newValue) => {
                            dispatch(setTitle(newValue));
                        }}
                    />
                </FieldWrapper> : <></>}
            {!isFieldInActive("ABCTopicSummary", inactiveFields) && (
                <FieldWrapper description={getFieldDescription("ABCTopicSummary", allFields)} >
                    <TextBox
                        name={getFieldDisplayName("ABCTopicSummary", allFields)}
                        required={false}
                        rows={5}
                        defaultValue={enteredSummary}
                        onChange={(ev, newValue) => {
                            dispatch(setSummary(newValue));
                        }}
                    />
                </FieldWrapper>               
            )}
            {!isFieldInActive("ABCRequestFrom", inactiveFields) && (
                <FieldWrapper description={getFieldDescription("ABCRequestFrom", allFields)} >
                    <Choice
                        name={getFieldDisplayName("ABCRequestFrom", allFields)}
                        required={true}
                        options={requestFromOptions}
                        defaultSelectedKey={selectedRequestFrom?.id || undefined}
                        onChange={option => {
                            dispatch(setSelectedRequestFrom(option?.key));
                        }}
                    />
                </FieldWrapper>                
            )}
            {isFieldInActive("ABCDateRequested", inactiveFields) ? (<></>) : (
                <FieldWrapper description={getFieldDescription("ABCDateRequested", allFields)} >
                    <DatePicker
                        dateLabel={getFieldDisplayName("ABCDateRequested", allFields)}
                        required={false}
                        includeTime={false}
                        defaultValue={selectedDateRequested || currDate}
                        onChange={date => dispatch(setDateRequested(date))}
                    />
                </FieldWrapper>               
            )}
        </>);
    }, [currDate, dispatch, enteredSummary, enteredTitle, requestFromOptions, selectedDateRequested, selectedRequestFrom?.id, inactiveFields, allFields]);

    return (
        <>
            <div className={styles.headerContainer}>
                {wizardType === WizardType.Other && <FormHeader strings={responseInformationHeaderStrings} />}
                {wizardType === WizardType.Correspondence && <FormHeader strings={replyInformationHeaderStrings} />}
                {wizardType === WizardType.Event && <FormHeader strings={eventInformationHeaderStrings} />}
            </div>
            <div className={styles.formContainer}>
                {wizardType === WizardType.Other && fieldsElementsForOtherWizardType}
                {wizardType === WizardType.Event && <>
                    <FieldWrapper description={getFieldDescription("Title", allFields)} >
                        <TextBox
                            name={getFieldDisplayName("Title", allFields)}
                            required={true}
                            rows={5}
                            defaultValue={enteredTitle}
                            onChange={(ev, newValue) => {
                                dispatch(setTitle(newValue));
                            }}
                        />
                    </FieldWrapper>
                    {isFieldInActive("ABCRequestFrom", inactiveFields) ? (<></>) : (
                        <FieldWrapper>
                            <Choice
                                name={fieldLabelRequestFrom}
                                required={true}
                                options={requestFromOptions}
                                defaultSelectedKey={selectedRequestFrom?.id || undefined}
                                onChange={option => {
                                    dispatch(setSelectedRequestFrom(option?.key));
                                }}
                            />
                        </FieldWrapper>                        
                    )}
                    {isFieldInActive("ABCDateRequested", inactiveFields) ? (<></>) : (
                        <FieldWrapper description={getFieldDescription("ABCDateRequested", allFields)}>
                            <DatePicker
                                dateLabel={getFieldDisplayName("ABCDateRequested", allFields)}
                                required={false}
                                includeTime={false}
                                defaultValue={selectedDateRequested || currDate}
                                onChange={date => dispatch(setDateRequested(date))}
                            />
                        </FieldWrapper>                        
                    )}
                </>}
                {!isFieldInActive("ABCTimeframe", inactiveFields) && (
                    <FieldWrapper description={getFieldDescription("ABCTimeframe", allFields)}>
                        <Choice
                            name={getFieldDisplayName("ABCTimeframe", allFields)}
                            required={false}
                            options={timeFrameOptions}
                            defaultSelectedKey={selectedTimeframe?.id || undefined}
                            onChange={option => {
                                dispatch(setSelectedTimeframe(option?.key));
                            }}
                        />
                    </FieldWrapper>
                )}
                {inactiveFields?.every(v => v.internalName !== "ABCRecordFlags") && (
                    <FieldWrapper description={fieldDescriptionFlags}>
                        <Select
                            name={fieldLabelFlags}
                            options={recordFlagsOptions}
                            required={false}
                            defaultSelectedKey={selectedRecordFlags?.map(x => x?.id || "") || undefined}
                            disabled={recordFlagsOptions.length < 1}
                            onChange={option => {
                                dispatch(setSelectedRecordFlags(option?.key));
                            }}
                            isMultiSelect
                        />
                    </FieldWrapper>)}
                {currentFields?.filter(v => v.wizardPosition == 1 && v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && !v.notEditable).map((field, index) => {
                    switch (field.fieldType) {
                        case FieldTypes.Text:
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <TextBox
                                        name={field.displayName}
                                        required={field.isRequired}
                                        defaultValue={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                        onChange={(ev, newValue) => {
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                        }}
                                    />
                                </FieldWrapper>);
                        case FieldTypes.MultilineText:
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <TextBox
                                        name={field.displayName}
                                        required={field.isRequired}
                                        rows={5}
                                        defaultValue={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                        onChange={(ev, newValue) => {
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                        }}
                                    />
                                </FieldWrapper>);
                        case FieldTypes.RadioManagedMetadata: {
                            const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                            const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                            //get first selected term
                            const currentValue: string | undefined = selectedTerms?.length ? selectedTerms.map(v => v?.Id)[0] : undefined;
                            const options: IChoiceGroupOption[] = termSet?.terms?.map(getChoiceOptionFromTerm) || [];

                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <Choice
                                        name={field.displayName}
                                        required={field.isRequired}
                                        options={options}
                                        defaultSelectedKey={currentValue}
                                        onChange={option => {
                                            selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.SelectManagedMetadata:
                        case FieldTypes.MultiSelectManagedMetadata: {
                            const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                            const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                            const currentValues: string[] = selectedTerms.map(v => v?.Id);
                            const options: ISelectOptionProps[] = termSet?.terms?.map(getISelectOptionPropsFromIterm) || [];
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <Select
                                        name={field.displayName}
                                        options={options}
                                        required={field.isRequired}
                                        defaultSelectedKey={currentValues}
                                        disabled={!options || options.length < 1}
                                        onChange={option => {
                                            if (selectedTerms.some(v => v.Id === option?.key)) {
                                                selectedTerms.splice(selectedTerms.findIndex(v => v.Id === option?.key), 1);
                                            } else {
                                                selectedTerms.push({ Id: option?.key || "", Name: option?.text || "" });
                                            }
                                            if (field.fieldType === FieldTypes.SelectManagedMetadata) {
                                                selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                            }
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                        }}
                                        isMultiSelect={field.fieldType === FieldTypes.MultiSelectManagedMetadata}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.DateOnly:
                        case FieldTypes.DateTime: {
                            const dateStringValue: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                            const dateValue: Date | undefined = dateStringValue ? new Date(dateStringValue) : undefined;
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <DatePicker
                                        dateLabel={field.displayName}
                                        timeLabel={""}
                                        placeholder={t("datePickerPlaceholder")}
                                        required={field.isRequired}
                                        includeTime={field.fieldType !== FieldTypes.DateOnly}
                                        hideDate={false}
                                        ariaLabel={field.description}
                                        value={dateValue}
                                        onChange={date => {
                                            const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === field.internalName)
                                                ? currentFieldValues?.map(v =>
                                                    v.fieldInternalName === field.internalName
                                                        ? ({ fieldInternalName: v.fieldInternalName, valueInJson: date?.toISOString() } as IFieldValue)
                                                        : v) || null
                                                : (currentFieldValues || []).concat([{ fieldInternalName: field.internalName, valueInJson: date?.toISOString() } as IFieldValue]);
                                            dispatch(setFieldValues(newValues));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.TimeOnly: {
                            const timeAsNumber: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                            const dateValue: Date | undefined = timeAsNumber ? DateHelper.ToTimeOnlyFromNumberAsDate(Number(timeAsNumber)) : undefined;
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <DatePicker
                                        dateLabel={field.displayName}
                                        timeLabel={field.displayName}
                                        placeholder={t("datePickerPlaceholder")}
                                        required={field.isRequired}
                                        includeTime={true}
                                        hideDate={field.fieldType === FieldTypes.TimeOnly}
                                        ariaLabel={field.description}
                                        value={dateValue}
                                        onChange={date => {
                                            const dateInUtcIsoString: string | undefined  = date ? DateHelper.ToTimeOnlyFromNumberFromDate(date) : undefined;
                                            const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === field.internalName)
                                                ? currentFieldValues?.map(v =>
                                                    v.fieldInternalName === field.internalName
                                                        ? ({ fieldInternalName: v.fieldInternalName, valueInJson: dateInUtcIsoString } as IFieldValue)
                                                        : v) || null
                                                : (currentFieldValues || []).concat([{ fieldInternalName: field.internalName, valueInJson: dateInUtcIsoString } as IFieldValue]);
                                            dispatch(setFieldValues(newValues));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        default:
                            return (<></>);
                    }
                })
                }
                {inactiveFields === null && <>
                    <Spinner label={t("fields_loading")} />
                </>}
            </div>
            {wizardType !== WizardType.Event && !isFieldInActive("ABCSignatureRequired", inactiveFields) && <div className={styles.signatureContainer}>
                <FieldWrapper description={fieldDescriptionSignatureRequired}>
                    <Choice
                        name={fieldLabelSignatureRequired}
                        required={true}
                        options={signatureTypes}
                        defaultSelectedKey={String(selectedSignatureRequired) || undefined}
                        allowLargeWidth={true}
                        onChange={option => {
                            if (option) {
                                dispatch(setSelectedSignatureRequired(JSON.parse(option.key)));
                            }
                        }}
                        supressLabelColon={true}
                    />
                </FieldWrapper>
            </div>}
        </>
    );
}