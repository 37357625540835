import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    DefaultButton
} from "@fluentui/react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    attachmentLoading,
    attachmentUploadSuccess,
    deleteDocumentAsync,
    editDocumentAsync,
    getWizardResponseTemplateDataAsync,
    primaryDocLoading,
    primaryDocUplaodSuccess,
    reorderDocumentAsync,
    selectAttachmentDocErrorMessages,
    selectAvailableTemplates,
    selectPrimaryDocErrorMessages,
    selectSupportingDocErrorMessages,
    selectTemplateDocErrorMessages,
    supportingDocLoading,
    supportingDocUplaodSuccess as supportingDocUploadSuccess,
    templateLoading,
    uploadDocumentsAsync,
    uploadSignedDocumentAsync,
    uploadTemplateAsync,
    userSelectedAttachments,
    userSelectedSupportingFiles,
    userSelectedPrimaryFiles,
    userSelectedTemplates,
} from "./manageRecordEditDocumentsSlice";
import styles from "./ManageRecordEditDocuments.module.scss";
import {
    selectCurrentRecord,
    setDocumentTabMode
} from "../manageRecordWrapper/manageRecordWrapperSlice";
import {
    FormMode,
    IFile,
    IFileOrder,
    IRecord,
    ITemplate,
    IUploadRecordDocumentRequest,
    IUser,
    Move,
    RecordDocumentType
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    AttachmentDeletingText,
    AttachmentRenamingText,
    AttachmentReorderingText,
    AttachmentUploadingText,
    PrimaryDocumentDeletingText,
    PrimaryDocumentRenamingText,
    PrimaryDocumentUploadingText,
    SupportingDocumentDeletingText,
    SupportingDocumentRenamingText,
    SupportingDocumentReorderingText,
    SupportingDocumentUploadingText,
    TemplateAddingText,
    TemplatesRenamingText,
    TemplatesReorderingText
} from "../../providers/Constants/FormControlConstants";
import Select, {
    ISelectOptionProps
} from "../../components/FormControls/Select/Select";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import FileUpload from "../../components/FormControls/FileUpload/FileUpload";
import { selectCurrentUser } from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";

export function ManageRecordEditDocuments(): JSX.Element {
    const { t } = useTranslation();
    // 2. SELECT CURRENT GLOBAL STATE
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const selectedTemplates: ITemplate[] = useSelector(userSelectedTemplates);
    const templateTypes: ITemplate[] = useSelector(selectAvailableTemplates);
    const isTemplateLoading: boolean = useSelector(templateLoading);
    const isAttachmentLoading: boolean = useSelector(attachmentLoading);
    const isSupportingDocLoading: boolean = useSelector(supportingDocLoading);
    const isPrimaryDocLoading: boolean = useSelector(primaryDocLoading);
    const selectedSupportingFiles: IFile[] = useSelector(userSelectedSupportingFiles);
    const selectedPrimaryFiles: IFile[] = useSelector(userSelectedPrimaryFiles);
    const selectedAttachments: IFile[] = useSelector(userSelectedAttachments);
    const primaryDocErrorMessages: string[] | undefined = useSelector(selectPrimaryDocErrorMessages);
    const supportingDocErrorMessages: string[] | undefined = useSelector(selectSupportingDocErrorMessages);
    const attachmentDocErrorMessages: string[] | undefined = useSelector(selectAttachmentDocErrorMessages);
    const templateDocErrorMessages: string[] | undefined = useSelector(selectTemplateDocErrorMessages);
    const isAttachmentUploadSuccess: boolean = useSelector(attachmentUploadSuccess);
    const isSupportingDocUploadSuccess: boolean = useSelector(supportingDocUploadSuccess);
    const isPrimaryDocUplaodSuccess: boolean = useSelector(primaryDocUplaodSuccess);

    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const isCorro: boolean = (!!currentRecord && !!currentRecord.recordType && currentRecord?.recordType?.toLowerCase().indexOf("correspondence") > -1)?true:false;

    // 3. DEFINE COMPONENT STATE
    const [templateLoadingText, setTemplateLoadingText] = React.useState<string>("");
    const [attachmentLoadingText, setAttachmentLoadingText] = React.useState<string>("");
    const [primaryDocLoadingText, setPrimaryDocLoadingText] = React.useState<string>("");  
    const [supportingDocLoadingText, setSupportingDocLoadingText] = React.useState<string>("");    

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    // NOTE: No component hooks

    React.useEffect(() => {
        dispatch(
            getWizardResponseTemplateDataAsync(
                currentRecord?.recordType != undefined 
                    ? ([currentRecord?.recordType]) 
                    : [""]
            )
        );
        
    }, [dispatch, currentRecord?.recordType]);

    // 6. RETURN RENDER TEMPLATE
    const availableTemplates: ISelectOptionProps[] = templateTypes.map(tmpl => ({
        key: String(tmpl.url),
        text: tmpl.name,
        disabled: selectedTemplates.find(e => e.name == tmpl.name) != undefined ? true : false
    }));

    // Function to delete file
    function deleteFile(name: string, documentType: RecordDocumentType): void {
        if (currentRecord) {
            dispatch(
                deleteDocumentAsync({
                    recordId: currentRecord.recordId != undefined ? currentRecord.recordId : "",
                    recordName: currentRecord.name != undefined ? currentRecord.name : "",
                    name: name,
                    type: documentType
                })
            );
        }
    }

    // Function to rename/edit file
    function renameFile(oldName: string, newName: string, documentType: RecordDocumentType): void {
        dispatch(
            editDocumentAsync({
                recordId: currentRecord?.recordId != undefined ? currentRecord.recordId : "",
                recordName: currentRecord?.name != undefined ? currentRecord?.name : "",
                name: oldName,
                updatedName: newName,
                type: documentType
            })
        );
    }

    // Function to upload file
    function uploadFiles(files: IFile[], documentType: RecordDocumentType): void {
        const docRequests: IUploadRecordDocumentRequest[] = files.map(f => {
            return {
                recordId: currentRecord?.recordId != undefined ? currentRecord.recordId : "",
                recordName: currentRecord?.name != undefined ? currentRecord?.name : "",
                name: f.name,
                content: f.contentURL,
                driveId: f.driveId,
                itemId: f.itemId,
                type: documentType
            };
        });
        dispatch(uploadDocumentsAsync(docRequests));
    }

    // Function to upload file signed file
    function uploadSignedFile(name: string, content: string | undefined, documentType: RecordDocumentType, fileToReplace: IFile, existingDocumentType: RecordDocumentType): void {
        dispatch(
            uploadSignedDocumentAsync({
                recordId: currentRecord?.recordId != undefined ? currentRecord.recordId : "",
                recordName: currentRecord?.name != undefined ? currentRecord?.name : "",
                name: name,
                content: content,
                type: documentType,
                CopyFromSharepointPersonalFileId: fileToReplace.documentReference || fileToReplace.id || "",
                documentToReplaceId: fileToReplace.id || ""
            }, existingDocumentType)
        );  
    }

    // Function to upload template
    function uploadTemplate(name: string, templateUrl: string): void {
        dispatch(
            uploadTemplateAsync({
                recordId: currentRecord?.recordId != undefined ? currentRecord.recordId : "",
                recordName: currentRecord?.name != undefined ? currentRecord?.name : "",
                name: name,
                url: templateUrl
            })
        );
    }

    // Function to reorder file
    function reorderFile(file: IFile, movement: Move, documentType: RecordDocumentType): void {
        const fileOrder: IFileOrder = {
            deptWorkProductsOrder: [],
            attachmentsOrder: [],
            supportingDocumentsOrder: []
        };

        let fileIndex: number = -1;
        let selectedFileCollection: IFile[] = [];
        switch (documentType) {
            case RecordDocumentType.attachment:
                selectedFileCollection = Object.assign([], selectedAttachments);
                fileIndex = selectedAttachments.findIndex(t => t.name == file.name);
                break;
            case RecordDocumentType.supportingDocument:
                selectedFileCollection = Object.assign([], selectedSupportingFiles);
                fileIndex = selectedSupportingFiles.findIndex(t => t.name == file.name);
                break;
            case RecordDocumentType.departmentWorkProduct: 
                selectedFileCollection = Object.assign([], selectedPrimaryFiles);
                fileIndex = selectedPrimaryFiles.findIndex(t => t.name == file.name);
                break;
        }

        if (fileIndex > -1 && (selectedSupportingFiles != null || selectedPrimaryFiles != null)) {
            const movedFile: IFile = {
                name: file.name,
                contentURL: file.contentURL,
                path: file.path
            };
            if (movement == Move.Up) {
                const moveDownFile: IFile = selectedFileCollection[fileIndex - 1];
                selectedFileCollection.splice(fileIndex - 1, 2, movedFile, moveDownFile);
            } else {
                const moveUpFile: IFile = selectedFileCollection[fileIndex + 1];
                selectedFileCollection.splice(fileIndex, 2, moveUpFile, movedFile);
            }
        }

        switch (documentType) {
            case RecordDocumentType.attachment:
                selectedFileCollection.map(e => {
                    fileOrder.attachmentsOrder.push(e.name);
                });
                selectedSupportingFiles.map(e => {
                    fileOrder.supportingDocumentsOrder.push(e.name);
                });
                selectedPrimaryFiles.map(e => {
                    fileOrder.deptWorkProductsOrder.push(e.name);
                });
                break;
            case RecordDocumentType.supportingDocument:
                selectedFileCollection.map(e => {
                    fileOrder.supportingDocumentsOrder.push(e.name);
                });
                selectedAttachments.map(e => {
                    fileOrder.attachmentsOrder.push(e.name);
                });
                selectedPrimaryFiles.map(e => {
                    fileOrder.deptWorkProductsOrder.push(e.name);
                });
                break;
            case RecordDocumentType.departmentWorkProduct:
                selectedFileCollection.map(e => {
                    fileOrder.deptWorkProductsOrder.push(e.name);
                });
                selectedAttachments.map(e => {
                    fileOrder.attachmentsOrder.push(e.name);
                });
                selectedSupportingFiles.map(e => {
                    fileOrder.supportingDocumentsOrder.push(e.name);
                });
                break;
        }

        dispatch(
            reorderDocumentAsync(
                {
                    recordId: currentRecord?.recordId != undefined ? currentRecord.recordId : "",
                    recordName: currentRecord?.name != undefined ? currentRecord?.name : "",
                    fileOrder: fileOrder
                },
                documentType
            )
        );
    }

    return (
        <div className={styles.wizardResponseTemplateContainer}>
            <div className={styles.headerContainer}>
                <FormHeader strings={{Header: t("editResponseTemplateHeaderStrings")}} />
            </div>
            <div className={styles.sections}>
                <h3 className={styles.header}>{t("editFieldTemplateHeader")}</h3>

                <div className={styles.descriptions}>{t("editFieldTemplateDescription1")}</div>
                <div className={styles.descriptions}>{t("editFieldTemplateDescription2")}</div>
                <div className={currentRecord?.canCurrentUserEditRecordDocuments ? styles.templateContainer : undefined}>
                    { !!currentRecord?.canCurrentUserEditRecordDocuments &&
                        <Select
                            name={t("fieldTemplateSubHeader")}
                            options={availableTemplates}
                            required={false}
                            onChange={option => {
                                if (option) {
                                    if (!selectedTemplates.some(t => t.name === option.text && t.url === option.key)) {
                                        uploadTemplate(option.text, option.key);
                                    }
                                }
                            }}
                            disabled={isTemplateLoading}
                            className={styles.selectContainer}
                        />
                    }
                    <FileUpload
                        selectedFilesList={selectedTemplates as IFile[]}
                        onFileSelected={(selectedFiles: IFile[], fileToUpdate?: IFile) => {
                            setTemplateLoadingText(TemplateAddingText);
                            if (fileToUpdate) {
                                uploadSignedFile(
                                    selectedFiles[0].name,
                                    selectedFiles[0].contentURL,
                                    RecordDocumentType.signedAndApprovedDocument,
                                    fileToUpdate,
                                    RecordDocumentType.departmentWorkProduct
                                );
                            } else {
                                // Unreachable
                                uploadFiles(selectedFiles, RecordDocumentType.signedAndApprovedDocument);
                            }
                        }}
                        onFileRemove={(selectedFile: IFile) => {

                            deleteFile(selectedFile.name, selectedFile.documentReference ? RecordDocumentType.signedAndApprovedDocument : RecordDocumentType.departmentWorkProduct);
                        }}
                        onFileEdit={(filenames: string[]) => {
                            setTemplateLoadingText(TemplatesRenamingText);
                            renameFile(filenames[0], filenames[1], RecordDocumentType.departmentWorkProduct);
                        }}
                        onFileMoved={(file: IFile, movement: number) => {
                            setTemplateLoadingText(TemplatesReorderingText);
                            reorderFile(file, movement, RecordDocumentType.departmentWorkProduct);
                        }}
                        onLoading={isTemplateLoading}
                        onLoadingText={templateLoadingText}
                        componentMode={FormMode.Edit}
                        templateMode={true}
                        errorMessages={templateDocErrorMessages}
                        canEdit={!!currentRecord?.canCurrentUserEditRecordDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                        canAdd={!!currentRecord?.canCurrentUserAddDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                        canUploadSigned={!!currentRecord?.canCurrentUserUploadSignedDocuments}
                        canRemove={!!currentRecord?.canCurrentUserRemoveDocuments}
                    ></FileUpload>
                </div>
            </div>

            <div className={styles.attachmentsSection}>
                <h3 className={styles.header}>{t("editFieldAttachmentsHeader")}</h3>
                <div className={styles.descriptions}>{t("editFieldAttachmentsDescription1")}</div>
                <div className={styles.descriptions}>{t("editFieldAttachmentsDescription2")}</div>
                <FileUpload
                    selectedFilesList={selectedAttachments}
                    onFileSelected={(selectedFiles: IFile[], fileToUpdate?: IFile) => {
                        setAttachmentLoadingText(AttachmentUploadingText);
                        if (fileToUpdate) {
                            uploadSignedFile(
                                selectedFiles[0].name,
                                selectedFiles[0].contentURL,
                                fileToUpdate ? RecordDocumentType.signedAndApprovedDocument : RecordDocumentType.attachment,
                                fileToUpdate,
                                RecordDocumentType.attachment
                            );
                        } else {
                            uploadFiles(selectedFiles, RecordDocumentType.attachment);
                        }
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        setAttachmentLoadingText(AttachmentDeletingText);
                        deleteFile(selectedFile.name, selectedFile.documentReference ? RecordDocumentType.signedAndApprovedDocument : RecordDocumentType.attachment);
                    }}
                    onFileEdit={(filenames: string[]) => {
                        setAttachmentLoadingText(AttachmentRenamingText);
                        renameFile(filenames[0], filenames[1], RecordDocumentType.attachment);
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        setAttachmentLoadingText(AttachmentReorderingText);
                        reorderFile(file, movement, RecordDocumentType.attachment);
                    }}
                    onLoading={isAttachmentLoading}
                    onLoadingText={attachmentLoadingText}
                    componentMode={FormMode.Edit}
                    errorMessages={attachmentDocErrorMessages}
                    canEdit={!!currentRecord?.canCurrentUserEditRecordDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                    canAdd={!!currentRecord?.canCurrentUserAddDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                    canUploadSigned={!!currentRecord?.canCurrentUserUploadSignedDocuments}
                    isSuccess={isAttachmentUploadSuccess}
                    canRemove={!!currentRecord?.canCurrentUserRemoveDocuments}
                ></FileUpload>
            </div>

            <div className={styles.supportingDocSection}>
                <h3 className={styles.header}>{t("fieldPrimaryDocumentHeader")}</h3>
                {!isCorro && <div className={styles.descriptions}>
                    {t("editFieldPrimaryDocumentDescription1")}
                </div>
                }
                {isCorro && <div className={styles.descriptions}>
                    {t("fieldPrimaryDocumentDescriptionCorro")}
                </div>}
                <div className={styles.descriptions}>
                    {t("editFieldPrimaryDocumentDescription2")}
                </div>
                <FileUpload
                    selectedFilesList={selectedPrimaryFiles}
                    onFileSelected={(selectedFiles: IFile[]) => {
                        setPrimaryDocLoadingText(PrimaryDocumentUploadingText);
                        uploadFiles(selectedFiles, RecordDocumentType.departmentWorkProduct);
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        setPrimaryDocLoadingText(PrimaryDocumentDeletingText);
                        deleteFile(selectedFile.name, RecordDocumentType.departmentWorkProduct);
                    }}
                    onFileEdit={(filenames: string[]) => {
                        setPrimaryDocLoadingText(PrimaryDocumentRenamingText);
                        renameFile(
                            filenames[0],
                            filenames[1],
                            RecordDocumentType.departmentWorkProduct
                        );
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        setPrimaryDocLoadingText(PrimaryDocumentRenamingText);
                        reorderFile(file, movement, RecordDocumentType.departmentWorkProduct);
                    }}
                    onLoading={isPrimaryDocLoading}
                    onLoadingText={primaryDocLoadingText}
                    componentMode={FormMode.Edit}
                    errorMessages={primaryDocErrorMessages}
                    isSuccess={isPrimaryDocUplaodSuccess}
                    canEdit={!!currentRecord?.canCurrentUserEditRecordDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                    canAdd={!!currentRecord?.canCurrentUserAddDocuments && !currentRecord?.canCurrentUserUploadOnlySupportingDocuments}
                    canUploadSigned={!!currentRecord?.canCurrentUserUploadSignedDocuments}
                    canRemove={!!currentRecord?.canCurrentUserRemoveDocuments}
                ></FileUpload>
            </div>

            <div className={styles.supportingDocSection}>
                <h3 className={styles.header}>{t("fieldSupportingDocumentHeader")}</h3>
                {!isCorro && <div className={styles.descriptions}>
                    {t("editFieldSupportingDocumentDescription1")}
                </div>
                }
                {isCorro && <div className={styles.descriptions}>
                    {t("fieldSupportingDocumentDescriptionCorro")}
                </div>}
                <div className={styles.descriptions}>
                    {t("editFieldSupportingDocumentDescription2")}
                </div>
                <FileUpload
                    selectedFilesList={selectedSupportingFiles}
                    onFileSelected={(selectedFiles: IFile[]) => {
                        setSupportingDocLoadingText(SupportingDocumentUploadingText);
                        uploadFiles(selectedFiles, RecordDocumentType.supportingDocument);
                    }}
                    onFileRemove={(selectedFile: IFile) => {
                        setSupportingDocLoadingText(SupportingDocumentDeletingText);
                        deleteFile(selectedFile.name, RecordDocumentType.supportingDocument);
                    }}
                    onFileEdit={(filenames: string[]) => {
                        setSupportingDocLoadingText(SupportingDocumentRenamingText);
                        renameFile(
                            filenames[0],
                            filenames[1],
                            RecordDocumentType.supportingDocument
                        );
                    }}
                    onFileMoved={(file: IFile, movement: number) => {
                        setSupportingDocLoadingText(SupportingDocumentReorderingText);
                        reorderFile(file, movement, RecordDocumentType.supportingDocument);
                    }}
                    onLoading={isSupportingDocLoading}
                    onLoadingText={supportingDocLoadingText}
                    componentMode={FormMode.Edit}
                    errorMessages={supportingDocErrorMessages}
                    isSuccess={isSupportingDocUploadSuccess}
                    canEdit={(!!currentRecord?.canCurrentUserEditRecordDocuments || !!currentRecord?.canCurrentUserUploadSupportingDocuments)}
                    canAdd={(!!currentRecord?.canCurrentUserAddDocuments || !!currentRecord?.canCurrentUserUploadSupportingDocuments)}
                    canRemove={!!currentRecord?.canCurrentUserRemoveDocuments}
                    canUploadSigned={!!currentRecord?.canCurrentUserUploadSignedDocuments}
                    filterByUserUPN={currentRecord?.canCurrentUserViewDocumentsFromOtherUsers ? undefined : (currentUser?.userPrincipalName || undefined)}
                    filterByUserEmail={currentRecord?.canCurrentUserViewDocumentsFromOtherUsers ? undefined : (currentUser?.mail || undefined)}
                ></FileUpload>
            </div>

            <div className={styles.exitButtonContainer}>
                <DefaultButton
                    text="Done"
                    onClick={() => {
                        dispatch(setDocumentTabMode(FormMode.View));
                    }}
                    allowDisabledFocus
                    disabled={false}
                    className={styles.buttonStyling}
                />
            </div>
        </div>
    );
}
