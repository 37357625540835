export const dueDateRecommender: string = "Date due to Recommender";
export const dueDateDecisionMaker: string = "Date due to Decision Maker";
export const dueDateDecision: string = "Date due for Decision";

export const initiatorNotAllocated: string = "Initiator not yet assigned";
export const requestApproverNotAllocated: string = "Request approver not yet assigned";
export const leadAuthorNotAllocated: string = "Lead Author not yet assigned";
export const AEONotAllocated: string = "Accountable Executive Officer not yet assigned";
export const recommenderNotAllocated: string = "Recommender not yet assigned";
export const departmentApprovalNotAllocated: string = "Department approval not yet assigned";
export const completionNotAllocated: string = "Completer not yet assigned";
export const leadAdvisorNotAllocated: string = "Lead Advisor not yet assigned";
export const reviewerNotAllocated: string = "Reviewer not yet assigned";
export const allocateRolesNotAllocated: string = "DLO for Decision Maker not yet assigned";
export const decisionMakerNotAllocated: string = "Decision Maker not yet assigned";

export const versionTask: string = "Currently with";
export const currentTask: string = "Current task";

export const briefingPackButtonText: string = "View PDF Pack";
export const openBriefingPackButtonText: string = "Open PDF Pack";
export const briefingPackButtonLoadingText: string = "Generating pack...";

export const emptyActivitySteps: string =
    "This record does not contain the required Activity steps";
