import React from "react";
import { ISnapshot } from "../../../services/Api/executor/IApiServiceExecutor";
import styles from "./SnapshotList.module.scss";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode
} from "@fluentui/react";
import {
    VersionColumnFieldName,
    VersionColumnKey,
    VersionColumnName,
    SentByColumnKey,
    SentByColumnName,
    SentByColumnFieldName,
    SentToColumnKey,
    SentToColumnName,
    SentToColumnFieldName,
    CommentsColumnKey,
    CommentsColumnName,
    CommentsColumnFieldName,
    BriefingDocumentsColumnKey,
    BriefingDocumentsColumnName,
    BriefingDocumentsFieldName,
    SnapshotsListAriaLabel
} from "../../../providers/Constants/FormControlConstants";
import DateHelper from "../../../helpers/DateHelper";
// import { SelectionMode } from "@fluentui/react";

interface ISnapshotsProps {
    snapshots: ISnapshot[];
    // readOnly?: boolean;
}

export default function SnapshotList({ snapshots }: ISnapshotsProps): JSX.Element {

    const columns: IColumn[] = [
        {
            key: VersionColumnKey,
            name: VersionColumnName,
            fieldName: VersionColumnFieldName,
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            onRender: (item: ISnapshot) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.primaryText}>{item.version}</div>
                        <div className={styles.secondaryText}>{DateHelper.ToDateTimeString(new Date(item.createdDate || ""))}</div>
                    </div>
                );
            }
        },
        {
            key: SentByColumnKey,
            name: SentByColumnName,
            fieldName: SentByColumnFieldName,
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            onRender: (item: ISnapshot) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.primaryText}>{item.sentBy}</div>
                    </div>
                );
            }
        },
        {
            key: SentToColumnKey,
            name: SentToColumnName,
            fieldName: SentToColumnFieldName,
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            onRender: (item: ISnapshot) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.primaryText}>{item.sentTo}</div>
                    </div>
                );
            }
        },
        {
            key: CommentsColumnKey,
            name: CommentsColumnName,
            fieldName: CommentsColumnFieldName,
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            onRender: (item: ISnapshot) => {
                return (
                    <div className={styles.bodyColumn}>
                        <div className={styles.tertiaryText}>{item.comments}</div>
                    </div>
                );
            }
        },
        {
            key: BriefingDocumentsColumnKey,
            name: BriefingDocumentsColumnName,
            fieldName: BriefingDocumentsFieldName,
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            onRender: (item: ISnapshot) => {
                return (
                    <div className={styles.bodyColumn}>
                        <a
                            className={styles.linkText}
                            href={item.contentURL}
                        >
                            {`Snapshot ${item.version}`}
                        </a>
                    </div>
                );
            }
        }
    ];

    return <div className={styles.snapshotTableContainer}>
        <DetailsList
            items={snapshots.map(ss => {
                return {
                    createdDate: DateHelper.ToDateTimeString(new Date(ss.createdDate || "")),
                    sentBy: ss.sentBy?.displayName,
                    sentTo: ss.sentTo?.displayName,
                    version: ss.version,
                    comments: ss.comments,
                    contentURL: ss.contentURL
                };
            })}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            ariaLabel={SnapshotsListAriaLabel}
        />
    </div>;
}