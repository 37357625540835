import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import {
    manageSignatureTableHeaderDocumentName,
    manageSignatureTableHeaderSignatureInstructions,
    placeholderNoHasSignatureDocuments
} from "../../../providers/Constants/ManageRecordConstants";
import {
    selectSignatureRequired,
    setSelectedSignatureRequired,
    signatureTypes
} from "../../wizardResponseInformation/wizardResponseInformationSlice";
import Choice from "../../../components/FormControls/Choice/Choice";
import { editFieldLabelSignatureRequired } from "../../../providers/Constants/RecordWizardConstants";
import { IFile, IRecord, IRecordDocuments } from "../../../services/Api/executor/IApiServiceExecutor";
import { Icon, PrimaryButton } from "@fluentui/react";
import { SignatureInstructionsForm } from "./SignatureInstructionsForm";
import styles from "../ManageRecordDocuments.module.scss";
import { recordDocuments } from "../../manageRecordEditDocuments/manageRecordEditDocumentsSlice";
import { selectCurrentRecord, updateRecordInformationAsync } from "../manageRecordWrapperSlice";
import IconHelper from "../../../helpers/IconHelper";
import { useTranslation } from "react-i18next";

interface ISignedDocSignatureInstructionsPanelProps {
    readOnly: boolean;
}

export function SignedDocSignatureInstructionsPanel({
    readOnly
}: ISignedDocSignatureInstructionsPanelProps): JSX.Element {
    const { t } = useTranslation();
    const record: IRecord | null = useSelector(selectCurrentRecord);
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [openMenu, setOpenMenu] = React.useState<boolean>(false);

    const recordDocs: IRecordDocuments = useSelector(recordDocuments);
    const selectedSignatureRequired: boolean | undefined = useSelector(selectSignatureRequired);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    React.useEffect(() => {
        if (record?.signatureRequired !== undefined && selectedSignatureRequired === undefined) {
            dispatch(setSelectedSignatureRequired(record.signatureRequired));
        }
    }, [record?.signatureRequired, dispatch, selectedSignatureRequired]);

    const allDocs: IFile[] = recordDocs ? [...recordDocs.attachments || [],...recordDocs.deptWorkProducts || []] : [];
    const signatureRequiredDocs: IFile[] = allDocs.filter(d => d.signatureRequired);

    const setSignatureRequired: (signatureRequiredValue: boolean) => void = React.useCallback((signatureRequiredValue: boolean): void => {
        if (record?.recordId) {
            setUpdatingInProgress(true);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: record.recordId,
                        signatureRequired: signatureRequiredValue
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                        },
                        onError: e => {
                            setError(e);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [dispatch, record?.recordId]);

    return (
        <>
            <div className={styles.sectionHeader}>{t("docTabSignatureDocumentHeader")}</div>
            <div className={styles.sectionContent}>
                <Choice
                    className={styles.signedDocumentCheckbox}
                    name={editFieldLabelSignatureRequired}
                    required={false}
                    options={signatureTypes}
                    defaultSelectedKey={String(record?.signatureRequired) || undefined}
                    allowLargeWidth={true}
                    onChange={option => {
                        if (option) {
                            const signatureRequiredValue: boolean = JSON.parse(option.key);
                            setSignatureRequired(signatureRequiredValue);
                            dispatch(setSelectedSignatureRequired(signatureRequiredValue));
                        }
                    }}
                    supressLabelColon={true}
                    disabled={updatingInProgress}
                />
                {(selectedSignatureRequired || record?.signatureRequired)  && <>
                    {!readOnly && (
                        <div><div className={styles.signedInstructionDesc}>{t("docTabSignatureInstructionsDesc")}</div>
                            <PrimaryButton
                                text={t("manageSignatureInstructionsButtonText")}
                                onClick={() => setOpenMenu(true)}
                                allowDisabledFocus
                                disabled={!allDocs.length || updatingInProgress}
                                className={styles.manageSignatureInstructBtn}
                            />
                        </div>
                    )}
                    <div className={styles.documentsSignatureInstructionsContainer}>
                        {signatureRequiredDocs && signatureRequiredDocs.length ? (
                            <div className={styles.documentsSignatureInstructionsItems}>
                                <div className={styles.documSignatureItem}>
                                    <div className={styles.tabHeader}>
                                        {manageSignatureTableHeaderDocumentName}
                                    </div>
                                    <div className={styles.tabHeader}>
                                        {manageSignatureTableHeaderSignatureInstructions}
                                    </div>
                                </div>
                                {signatureRequiredDocs.map((docItem, index) => {
                                    return (
                                        <div key={index} className={styles.documSignatureItem}>
                                            <div className={styles.documentNameTabContainer}>
                                                <Icon
                                                    className={styles.iconColumnIcon}
                                                    iconName={IconHelper.GetIconOfficeUIFabricName(
                                                        docItem.name
                                                    )}
                                                />
                                                <div className={styles.tabValue}>{docItem.name}</div>
                                            </div>
                                            <div className={[styles.tabValue, styles.tabInstuctionsValue].join(" ")}>
                                                {docItem.signatureInstructions}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p className={styles.noHasDocuments}>
                                <Icon iconName="Warning" />
                                {placeholderNoHasSignatureDocuments}
                            </p>
                        )}
                    </div>
                    {openMenu && (
                        <SignatureInstructionsForm
                            documents={allDocs}
                            onCancel={() => setOpenMenu(false)}
                        />
                    )}
                    {!!error && <div>{error.message}</div>}
                </>}
            </div>
        </>
    );
}
