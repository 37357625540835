import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { selectIsConfirmed, setIsConfirmed } from "./wizardSummarySlice";
import styles from "./WizardSummary.module.scss";
import {
    selectedPrimaryDocuments,
    selectedSupportingDocuments,
    userSelectedAttachments,
    userSelectedTemplates
} from "../wizardResponseTemplate/wizardResponseTemplateStepSlice";
import {
    selectDateRequested,
    selectEnteredSummary,
    selectEnteredTitle,
    selectRelatedRecords,
    selectSelectedRequestFrom,
    selectSelectedTimeframe,
    selectSignatureRequired,
    selectSelectedRecordFlags,
} from "../wizardResponseInformation/wizardResponseInformationSlice";
import {
    selectCabinetWorkingFileNumber,
    selectCurrentFieldValues,
    selectActiveFields,
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4,
    selectInactiveFields,
    selectAllFields,
} from "../wizardRecordInformation/wizardRecordInformationSlice";
import {
    selectSelectedRecordCategory,
    selectSelectedRecordType
} from "../wizardCreateRecord/wizardCreateRecordSlice";
import {
    navigateToPage,
    selectWizardType,
    WizardStates,
    WizardType
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import {
    FieldTypes,
    IContentType,
    IContentTypeField,
    IFieldValue,
    IFile,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    ITemplate,
    ITerm
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    fieldLabelDateRequested,
    fieldLabelRecordType,
    fieldLabelRelatedRecord,
    fieldLabelRequestFrom,
    fieldLabelSubRecordType,
    fieldLabelSummary,
    fieldLabelTimeframe,
    fieldLabelTitle,
    fieldLabelSignatureRequired,
    fieldLabelFlags
} from "../../providers/Constants/RecordWizardConstants";
import DateHelper from "../../helpers/DateHelper";
import SummaryField from "../../components/FormControls/SummaryField/SummaryField";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import CollapsibleContainer from "../../components/FormControls/CollapsibleContainer/CollapsibleContainer";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import { getFieldDisplayName, isFieldInActive } from "../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";

export function WizardSummary(): JSX.Element {
    const { t } = useTranslation();
    // 2. SELECT CURRENT GLOBAL STATE
    const isConfirmed: boolean = useSelector(selectIsConfirmed);

    const recordCategory: string | null = useSelector(selectSelectedRecordCategory);
    const recordType: IContentType | null = useSelector(selectSelectedRecordType);
    const decisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const orgLevel1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const orgLevel2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const orgLevel3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const orgLevel4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);

    const wizardType: WizardType = useSelector(selectWizardType);

    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);
    const enteredSummary: string | undefined = useSelector(selectEnteredSummary);
    const selectedRequestFrom: ITerm | null = useSelector(selectSelectedRequestFrom);
    const selectedDateRequested: Date | undefined = useSelector(selectDateRequested);
    const selectedTimeframe: ITerm | null = useSelector(selectSelectedTimeframe);
    const enteredRelatedRecords: string | undefined = useSelector(selectRelatedRecords);
    const selectedSignatureRequired: boolean | undefined = useSelector(selectSignatureRequired);
    const selectedRecordFlags: ITerm[] | null = useSelector(selectSelectedRecordFlags);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValues);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);

    const templateCollection: ITemplate[] | null = useSelector(userSelectedTemplates);
    const supportingDocCollection: IFile[] | null = useSelector(selectedSupportingDocuments);
    const primaryDocCollection: IFile[] | null = useSelector(selectedPrimaryDocuments);
    const attachmentsDocCollection: IFile[] | null = useSelector(userSelectedAttachments);

    const cabinetWorkingFileNumber: string | undefined = useSelector(
        selectCabinetWorkingFileNumber
    );

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    function getTemplateCard(templateCollection: ITemplate[] | null): JSX.Element {
        if (templateCollection && templateCollection.length > 0) {
            return (
                <div>
                    {templateCollection.map(template => (
                        <div key={template.name} className={styles.templateName}>
                            {template.name}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div className={styles.noselection}>{t("noSelectionMessage")}</div>;
        }
    }

    function getDocumentsCard(documentCollection: IFile[] | null): JSX.Element {
        if (documentCollection && documentCollection.length > 0) {
            return (
                <div>
                    {documentCollection.map(doc => (
                        <div key={doc.name} className={styles.documentName}>
                            {doc.name}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div className={styles.noselection}>{t("noSelectionMessage")}</div>;
        }
    }

    const showCabinetWorkingFileNumber: boolean | undefined = React.useMemo(() => {
        return cabinetWorkingFileNumber !== undefined && cabinetWorkingFileNumber !== "" && !isFieldInActive("ABCCabinetWorkingFolderNumber", inactiveFields);
    }, [cabinetWorkingFileNumber, inactiveFields]);

    const summaryFieldsElementsForOtherWizardType: JSX.Element = React.useMemo(() => {
        return (
            <>
                <SummaryField
                    fieldName={fieldLabelTitle}
                    fieldValue={enteredTitle}
                    // isMandatory={true}
                    missingValueText={t("noSelectionMessage")}
                />
                {!isFieldInActive("ABCTopicSummary", inactiveFields) ? (
                    <SummaryField
                        fieldName={fieldLabelSummary}
                        fieldValue={enteredSummary}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                ) : (<></>)}   
                {isFieldInActive("ABCRequestFrom", inactiveFields) ? (<></>) : (
                    <SummaryField
                        fieldName={fieldLabelRequestFrom}
                        fieldValue={selectedRequestFrom?.name}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />                    
                )}
                {isFieldInActive("ABCDateRequested", inactiveFields) ? (<></>) : (
                    <SummaryField
                        fieldName={fieldLabelDateRequested}
                        fieldValue={
                            selectedDateRequested &&
                            DateHelper.ToLongDateTimeString(selectedDateRequested)
                        }
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />                    
                )}
            </>
        );
    }, [enteredSummary, enteredTitle, selectedDateRequested, selectedRequestFrom?.name, inactiveFields, t]);

    const fieldValues: JSX.Element = React.useMemo(() => {
        return (
            <>
                {currentFields?.filter(v => v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && v.internalName !== "ABCTopicSummary").map((field, index) => {
                    const rawValue: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                    let valueToShow: string | undefined = rawValue;
                    switch (field.fieldType) {
                        case FieldTypes.DateTime:
                            valueToShow = rawValue ? DateHelper.ToLongDateWithTimeString(new Date(rawValue)) : rawValue;
                            break;
                        case FieldTypes.DateOnly:
                            valueToShow = rawValue ? DateHelper.ToLongDateTimeString(new Date(rawValue)) : rawValue;
                            break;
                        case FieldTypes.TimeOnly:
                            valueToShow = rawValue ? DateHelper.ToTimeOnlyFromNumber(Number(rawValue)) : rawValue;
                            break;
                        case FieldTypes.RadioManagedMetadata:
                        case FieldTypes.SelectManagedMetadata:
                        case FieldTypes.MultiSelectManagedMetadata:
                            valueToShow = rawValue ? (JSON.parse(rawValue) as { Id: string, Name: string }[]).map(v => v.Name).join("; ") : rawValue;
                            break;
                        default:
                            valueToShow = rawValue;
                            break;
                    }
                    return (
                        <SummaryField
                            key={index}
                            fieldName={field.displayName}
                            fieldValue={valueToShow}
                            isMandatory={field.isRequired}
                            missingValueText={t("noSelectionMessage")}
                        />
                    );
                })}
            </>
        );
    }, [currentFields, currentFieldValues, t]);

    // 6. RETURN RENDER TEMPLATE
    return (
        <div className={styles.wizardSummaryContainer}>
            <div className={styles.headerContainer}>
                <FormHeader strings={{
                    Header: t("summaryHeaderStrings_Header"),
                    DotPoints: [ t("summaryHeaderStrings_DotPoints_1") ]
                }} />
            </div>
            <div className={styles.bodyContainer}>
                <CollapsibleContainer
                    title={"Record Information"}
                    onEditClick={() => dispatch(navigateToPage(WizardStates.CreateRecord))}
                    highlightContentBg={true}
                    smallHeading={true}
                >
                    <SummaryField
                        fieldName={fieldLabelRecordType}
                        fieldValue={recordCategory}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    <SummaryField
                        fieldName={fieldLabelSubRecordType}
                        fieldValue={recordType?.rowKey}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    {isFieldInActive("ABCDecisionCategory", inactiveFields) ? (<></>) : (
                        <SummaryField
                            fieldName={getFieldDisplayName("ABCDecisionCategory", allFields)}
                            fieldValue={decisionCategory?.name}
                            isMandatory={true}
                            missingValueText={t("noSelectionMessage")}
                        />                        
                    )}
                    <SummaryField
                        fieldName={getFieldDisplayName("ABCOrgLevel1", allFields)}
                        fieldValue={orgLevel1?.name}
                        isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    <SummaryField
                        fieldName={getFieldDisplayName("ABCOrgLevel2", allFields)}
                        fieldValue={orgLevel2?.name}
                        isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    {isFieldInActive("ABCOrgLevel3", inactiveFields) ? (<></>) : (       
                        <SummaryField
                            fieldName={getFieldDisplayName("ABCOrgLevel3", allFields)}
                            fieldValue={orgLevel3?.name}
                            isMandatory={true}
                            missingValueText={t("noSelectionMessage")}
                        />
                    )}
                    {isFieldInActive("ABCOrgLevel4", inactiveFields) ? (<></>) : (     
                        <SummaryField
                            fieldName={getFieldDisplayName("ABCOrgLevel4", allFields)}
                            fieldValue={orgLevel4?.name}
                            isMandatory={true}
                            missingValueText={t("noSelectionMessage")}
                        />
                    )}
                    {showCabinetWorkingFileNumber ? (
                        <SummaryField
                            fieldName={getFieldDisplayName("ABCCabinetWorkingFolderNumber", allFields)}
                            fieldValue={cabinetWorkingFileNumber}
                            isMandatory={false}
                            missingValueText={t("noSelectionMessage")}
                        />
                    ) : (
                        <></>
                    )}
                    {fieldValues}
                </CollapsibleContainer>
                <CollapsibleContainer
                    title={"Response Information"}
                    onEditClick={() => dispatch(navigateToPage(WizardStates.ResponseInformation))}
                    highlightContentBg={true}
                    smallHeading={true}
                >
                    {wizardType === WizardType.Other ? (
                        summaryFieldsElementsForOtherWizardType
                    ) : (
                        <></>
                    )}
                    {wizardType === WizardType.Event ? (
                        <>                            
                          
                            {isFieldInActive("ABCRequestFrom", inactiveFields) ? (<></>) : (
                                <SummaryField
                                    fieldName={getFieldDisplayName("ABCRequestFrom", allFields)}
                                    fieldValue={selectedRequestFrom?.name}
                                    // isMandatory={true}
                                    missingValueText={t("noSelectionMessage")}
                                />                                
                            )}
                            {isFieldInActive("ABCDateRequested", inactiveFields) ? (<></>) : (
                                <SummaryField
                                    fieldName={fieldLabelDateRequested}
                                    fieldValue={
                                        selectedDateRequested &&
                                        DateHelper.ToLongDateTimeString(selectedDateRequested)
                                    }
                                    missingValueText={t("noSelectionMessage")}
                                />                                
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    <SummaryField
                        fieldName={fieldLabelTimeframe}
                        fieldValue={selectedTimeframe?.name}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    <SummaryField
                        fieldName={fieldLabelRelatedRecord}
                        fieldValue={enteredRelatedRecords}
                        // isMandatory={true}
                        missingValueText={t("noSelectionMessage")}
                    />
                    {wizardType !== WizardType.Event && !isFieldInActive("ABCSignatureRequired", inactiveFields) ? (
                        <div className={styles.signatureContainer}>
                            <SummaryField
                                fieldName={fieldLabelSignatureRequired}
                                fieldValue={selectedSignatureRequired ? "Yes" : "No"}
                                // isMandatory={true}
                                missingValueText={t("noSelectionMessage")}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {inactiveFields?.every(v => v.internalName !== "ABCRecordFlags") ? (
                        <SummaryField
                            fieldName={fieldLabelFlags}
                            fieldValue={selectedRecordFlags?.map(x => x?.name).join(", ")}
                            missingValueText={t("noSelectionMessage")}
                        />
                    ): (<></>)}
                </CollapsibleContainer>
                <CollapsibleContainer
                    title={"Response Template(s)"}
                    onEditClick={() => dispatch(navigateToPage(WizardStates.RecordTemplate))}
                    highlightContentBg={true}
                    smallHeading={true}
                >
                    <div className={styles.sectionHeading}>
                        <h2>{t("fieldTemplateHeader")}</h2>
                    </div>
                    <div>{getTemplateCard(templateCollection)}</div>
                    <div className={styles.sectionHeading}>
                        <h2>{t("fieldPrimaryDocumentHeader")}</h2>
                    </div>
                    {getDocumentsCard(primaryDocCollection)}
                    <div className={styles.sectionHeading}>
                        <h2>{t("fieldSupportingDocumentHeader")}</h2>
                    </div>
                    {getDocumentsCard(supportingDocCollection)}
                    <div className={styles.sectionHeading}>
                        <h2>{t("fieldAttachmentsHeader")}</h2>
                    </div>
                    {getDocumentsCard(attachmentsDocCollection)}
                </CollapsibleContainer>
            </div>
            <div className={styles.footerContainer}>
                <div className={styles.primaryContainer}>
                    <div className={styles.featureText}>{t("confirmationFeatureHeading")}</div>
                    <div className={styles.checkboxContainer}>
                        <Checkbox
                            name={t("confirmationCheckboxLabel")}
                            defaultChecked={isConfirmed}
                            required={false}
                            onChange={(ev, checked) => dispatch(setIsConfirmed(checked))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
