import StringHelper from "./StringHelper";

// eslint-disable-next-line @typescript-eslint/typedef
export const URL_QUERY_KEYS = {
    TAB: "tabKey",
    SEARCH_TERM: "searchTerm"
};
export default class UrlHelper {

    public static isValidUrl(url: string): boolean {
        return /^(https?|ftp):\/\/(-\.)?([^\s/?.#-]+\.?)+([^\s/?.#-]+(?<=\.[a-z]{2,3}))?(\/[^\s]*)?$/.test(url);
    }

    public static getParameterByName(queryStrParamKey: string, url: string = window.location.href): string | undefined {
        // eslint-disable-next-line no-useless-escape
        queryStrParamKey = queryStrParamKey.replace(/[\[\]]/g, "\\$&");
        const regex: RegExp = new RegExp("[?&]" + queryStrParamKey + "(=([^&#]*)|&|#|$)"),
            // eslint-disable-next-line @typescript-eslint/typedef
            results = regex.exec(url);
        if (!results) {
            return undefined;
        }
        if (!results[2]) {
            return "";
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    public static getFilenameFromUrl(url: string): string {
        const urlParts: string[] = url.split("/");
        return urlParts[urlParts.length - 1];
    }

    public static castUrlWithQueryStringParam(newQueryParamValue: string | undefined, queryParamKey: string, url: string = window.location.href): string {
        let result: string = url;

        const oldQueryStrParamValue: string | undefined = UrlHelper.getParameterByName(queryParamKey);        
        if (oldQueryStrParamValue) {            
            if(newQueryParamValue){
                //replace
                result = url.slice(0, url.lastIndexOf(oldQueryStrParamValue))
                    + newQueryParamValue 
                    + url.slice(url.lastIndexOf(oldQueryStrParamValue) 
                    + oldQueryStrParamValue.length
                    );
            } else {
                //remove
                const toRemove: string = `${queryParamKey}=${oldQueryStrParamValue}`;
                const startIndex: number = url.lastIndexOf(toRemove);
                               
                if(url[startIndex - 1] === "?"){
                    // ?key1=value&key2=value&key3=value
                    // ?  "\key1=value&\"  key2=value&key3=value
                    result = url.slice(0, startIndex)
                    + url.slice(startIndex + toRemove.length + 1, url.length);
                } else {
                    // ?key1=value&key2=value&key3=value
                    // ?key1=value&key2=value "\&key3=value\"
                    result = url.slice(0, startIndex - 1)
                    + url.slice(startIndex + toRemove.length, url.length);
                }
            }
        } else {
            if(newQueryParamValue){
                const hasUrlAdditionalQueryStrParams: boolean = StringHelper.hasReservedChars(url, ["?", "&"]);

                // url might be empty string when just the search component of the url is being analysed
                const delimChar: string = !url ? "" : (hasUrlAdditionalQueryStrParams ? "&" : "/?");
                result = url + `${delimChar}${queryParamKey}=${newQueryParamValue}`;
            }
        }

        return result;
    }
}
