// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountInfo } from "@azure/msal-browser";
import IAuthService from "../../services/Auth/IAuthService";
import IApiService from "../../services/Api/IApiService";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { getCurrentContextAsync } from "../../app/globalSlices/contextSlice";
import { IUserValidation } from "../../services/Api/executor/IApiServiceExecutor";

// 2. TYPES AND INTERFACES
export enum LogInState {
    NotLoggedIn,
    ValidatingLogin,
    ValidLogIn,
    InvalidLogIn
}

// 3. FEATURE STATE
export interface SignInState {
    logInState: LogInState;
    account: AccountInfo | null;
    errorMessage: string | null | undefined;
}

// 4. FEATURE INITIAL STATE
export const initialState: SignInState = {
    logInState: LogInState.NotLoggedIn,
    account: null,
    errorMessage: null
};

// 4. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const signInSlice = createSlice({
    name: "signIn",
    initialState,
    reducers: {
        signIn: (state: SignInState, action: PayloadAction<AccountInfo | null>) => {
            state.logInState = action?.payload
                ? LogInState.ValidatingLogin
                : LogInState.InvalidLogIn;
            state.account = action.payload;
        },
        signOut: (state: SignInState) => {
            state.logInState = LogInState.NotLoggedIn;
            state.account = null;

            // TODO: sign out of MSAL
        },
        validate: (state: SignInState, action: PayloadAction<LogInState | null>) => {
            state.logInState = action.payload === null ? LogInState.InvalidLogIn : action.payload;
        },
        setErrorMessage: (state: SignInState, action: PayloadAction<string | null | undefined>) => {
            state.errorMessage = action.payload;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
// export const { signIn, signOut } = signInSlice.actions;

// 5. ASYNCRONOUS ACTIONS
export const signInAsync = (): AppThunk | { type: string } => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();

    // sign in
    const authService: IAuthService = resolver.ResolveIAuthService();
    const account: AccountInfo | null = await authService.SignIn();
    if (account?.username) {
        dispatch(signInSlice.actions.signIn(account));

        // validate authentication against api (e.g. might be from unsupported tenant)
        const apiService: IApiService = resolver.ResolveIApiService();
        const userValidation: IUserValidation | null = await apiService.ValidateUserAccess();
        const logInState: LogInState = !userValidation || !userValidation.ok ? LogInState.InvalidLogIn : LogInState.ValidLogIn;
        dispatch(signInSlice.actions.validate(logInState));
        if (logInState === LogInState.ValidLogIn) {
            dispatch(getCurrentContextAsync());
        }
        else {
            dispatch(signInSlice.actions.setErrorMessage(userValidation?.message));
        }
    } else {
        dispatch(signInSlice.actions.validate(LogInState.InvalidLogIn));
    }
};

// export const signOutAsync = (): AppThunk => async dispatch => {
//     const logoutRequest: EndSessionRequest = {
//         account: account
//     };
//     await app.logout(logoutRequest);
// };

// 6. SELECTORS
export const selectLogInState = (state: RootState): LogInState => state.signIn.logInState;
export const selectAccount = (state: RootState): AccountInfo | null => state.signIn.account;
export const selectErrorMessage = (state: RootState): string | null | undefined => state.signIn.errorMessage;

// 6. EXPORT REDUCER
export default signInSlice.reducer;
