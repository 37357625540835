import "./App.scss";
import { Route, Switch, useHistory,  } from "react-router-dom";
import React from "react";
import { SignIn } from "../features/signIn/SignIn";
import { SettingsPage } from "../features/settingsPage/SettingsPage";
import { AppSettingsPage } from "../features/appSettingsPage/AppSettingsPage";
import { BulkUpdatePage } from "../features/bulkUpdatePage/BulkUpdatePage";
import { RecordSchemaExportPage } from "../features/recordSchema/RecordSchemaExportPage";
import { RecordWizardWrapper } from "../features/recordWizardWrapper/RecordWizardWrapper";
import { ManageRecordWrapper } from "../features/manageRecordWrapper/ManageRecordWrapper";
import { Header } from "../features/header/Header";
import { Footer } from "../features/footer/Footer";
// import { useSelector } from "react-redux";
// import { selectAccount } from "../features/signIn/signInSlice";
// To Do: Bad Practice to import a difference Slice
import { Dashboard } from "../features/dashboard/Dashboard";
import { OverviewRecommendWizardWrapper } from "../features/overviewRecommend/OverviewRecommendWizardWrapper";
import { AppRoutes } from "./Constants";
import { isMobileInnerWidth, selectAppName, selectAppTitle, selectFavicon, selectIsMobile, setIsMobile } from "./globalSlices/contextSlice";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { loadTheme } from "@fluentui/react";
import { customTheme } from "../styles/customTheme";
import { ManageRelatedRecords } from "../features/manageRecordWrapper/manageRelatedRecords/ManageRelatedRecords";
import { TeamsContextProvider } from "../providers/TeamsContextProvider";
import { SearchPage } from "../features/searchPage/SearchPage";
import { TeamsAuth } from "../features/teamsAuth/TeamsAuth";
import { WatermarkPdf } from "../features/watermarkPdf/WatermarkPdf";
import { useTranslation } from "react-i18next";
import { DocumentGenerationPage } from "../features/documentGenerationPage/DocumentGenerationPage";
const WorkflowAdminPage: React.LazyExoticComponent<() => JSX.Element> = React.lazy(() => import("../features/configurationWorkflowPage/WorkflowAdminPage"));

function App(): JSX.Element {
    // SELECT GLOBAL STATE
    const {t} = useTranslation();
    const isMobile: boolean = useSelector(selectIsMobile);
    const favicon: string | undefined = useSelector(selectFavicon);
    const pageTitle: string | undefined = useSelector(selectAppTitle);
    const appName: string | undefined = useSelector(selectAppName);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();

    // execute teams deep link
    React.useEffect(()=>{
        if(TeamsContextProvider.IsInTeams && TeamsContextProvider.Context?.subEntityId){
            const route:string = decodeURIComponent(TeamsContextProvider.Context?.subEntityId);
            if(route){
                history.push(route);
            }
        }
    },[history]);

    React.useEffect(() => {
        const detectMobileVersion = (): void => {
            if (isMobile !== isMobileInnerWidth()) {
                dispatch(setIsMobile(isMobileInnerWidth()));
            }
        };
        window.addEventListener("resize", detectMobileVersion);
        return () => window.removeEventListener("resize", detectMobileVersion);
    }, [dispatch, isMobile]);

    React.useEffect(() => {
        if (favicon) {
            let faviconLinkElement: HTMLLinkElement | null = document.getElementById("favicon") as HTMLLinkElement;
            if (!faviconLinkElement) {
                faviconLinkElement = document.createElement("link");
                faviconLinkElement.id = "favicon";
                faviconLinkElement.rel = "icon";
            }
            faviconLinkElement.href = favicon;

            let faviconAppleTouchLinkElement: HTMLLinkElement | null = document.getElementById("faviconAppleTouch") as HTMLLinkElement;
            if (!faviconAppleTouchLinkElement) {
                faviconAppleTouchLinkElement = document.createElement("link");
                faviconAppleTouchLinkElement.id = "faviconAppleTouch";
                faviconAppleTouchLinkElement.rel = "apple-touch-icon";
            }
            faviconAppleTouchLinkElement.href = favicon;

            document.head.append(faviconLinkElement, faviconAppleTouchLinkElement);

            
        }
        if(pageTitle){
            document.title = (appName || t("defaultAppName")) + " - " +  pageTitle;
        }
        else{
            document.title = (appName || t("defaultAppName"));
        }
    }, [favicon, t, pageTitle, appName]);
    
    loadTheme(customTheme);
    // RENDER SUB COMPONENTS
    return (
        <div className="App">
            <Switch>
                <Route path={AppRoutes.teamsAuth}>
                    <TeamsAuth />
                </Route>
                <Route>
                    <SignIn>
                        <Header />
                        <Switch>
                            <Route path={AppRoutes.watermarkPdf}>
                                <WatermarkPdf />
                            </Route>
                            <Route path={AppRoutes.createRecord}>
                                <RecordWizardWrapper />
                            </Route>
                            <Route exact path={AppRoutes.manageRecord}>
                                <ManageRecordWrapper />
                            </Route>
                            <Route path={AppRoutes.manageRelatedRecords}>
                                <ManageRelatedRecords />
                            </Route>
                            <Route path={AppRoutes.recordRecommendations}>
                                <OverviewRecommendWizardWrapper />
                            </Route>
                            <Route path={AppRoutes.settings}>
                                <SettingsPage />
                            </Route>
                            <Route path={AppRoutes.appSettings}>
                                <AppSettingsPage />
                            </Route>
                            <Route path={AppRoutes.bulkUpdate}>
                                <BulkUpdatePage />
                            </Route>
                            <Route path={AppRoutes.configWF}>
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <WorkflowAdminPage />
                                </React.Suspense>                                
                            </Route>
                            <Route path={AppRoutes.schemaExport}>
                                <RecordSchemaExportPage />
                            </Route>
                            <Route path={AppRoutes.search}>
                                <SearchPage />
                            </Route>
                            <Route exact path={AppRoutes.documentGeneration}>
                                <DocumentGenerationPage />
                            </Route>
                            <Route path={AppRoutes.home}>
                                <Dashboard />
                            </Route>
                        </Switch>
                        <Footer />
                    </SignIn>
                </Route>
            </Switch>
        </div>
    );
}

export default App;
