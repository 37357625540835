import React from "react";
import { useDispatch, useSelector } from "react-redux";
//1. IMPORTS
import styles from "./ManageRecordRelatedRecords.module.scss";
import {
    IGetRecordsRequest,
    IRecord,
    IRecordColumn,
    IRecordResult,
    IRecordResultColumn,
    IRecordResults,
    RecordViewType
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    selectCurrentRecord,
    selectIsLoading,
    updateRecordInformationAsync
} from "../../features/manageRecordWrapper/manageRecordWrapperSlice";
import { RecordRelatedRecordsHeader } from "../../providers/Constants/SettingsConstants";
import {
    ActionButton,
    Text,
    MessageBar,
    MessageBarType,
    Selection,
    SelectionMode,
    Spinner,
    SpinnerSize
} from "@fluentui/react";
import { selectIsMobile, selectProxy } from "../../app/globalSlices/contextSlice";
import { IRefiner } from "../../model/Refiner";
import { ManagedPropertyViewType } from "../../model/ManagedPropertyViewType";
import { Dispatch } from "@reduxjs/toolkit";
import { IOrderBy } from "../recordsTable/recordsTableSlice";
import { AppRoutes } from "../../app/Constants";
import { Link } from "react-router-dom";
import {
    mp_RecordId,
    mp_Title,
    mp_Stage,
    mp_Modified,
    mp_RecordType
} from "../../providers/Constants/SearchConstants";
import Table from "../../components/FormControls/Table/Table";
import Cards from "../../components/FormControls/Cards/Cards";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import {
    buildRecordTableColumn,
    buildRefinableFilterString
} from "../recordsTable/RecordsTableCommon";
import { ITableColumn } from "../../components/FormControls/Table/ITableColumn";
import { editRelatedRecordsHeader } from "../../providers/Constants/RecordWizardConstants";

//eslint-disable-next-line @typescript-eslint/typedef
export const ManageRecordRelatedRecordsFilters = {
    managedProperties: [mp_RecordType, mp_RecordId, mp_Title, mp_Stage, mp_Modified],
    getFilters(relatedRecords: string): IRefiner[] {
        if (!relatedRecords) {
            return [];
        }

        const result: IRefiner = {
            displayName: "",
            name: mp_RecordId,
            entries: [],
            type: ManagedPropertyViewType.Text
        };

        relatedRecords.split(";").forEach((recordId: string) => {
            result.entries.push({
                name: recordId,
                token: "",
                value: recordId
            });
        });
        return [result];
    }
};

export function ManageRecordRelatedRecords(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // eslint-disable-next-line @typescript-eslint/typedef
    const isMobile: boolean = useSelector(selectIsMobile);
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const isLoading: boolean = useSelector(selectIsLoading);
    const proxy: string | undefined = useSelector(selectProxy);

    //REL
    const [relRecords, setRelRecords] = React.useState<IRecordResult[] | undefined>();
    const [relatedRecordIds, setRelatedRecordIds] = React.useState<string[] | undefined>();
    const [relColumns, setRelColumns] = React.useState<IRecordResultColumn[] | undefined>();
    const [relItemIndexesSelected, setRelItemIndexesSelected] = React.useState<number[]>([]);
    const [refiners, setRefiners] = React.useState<IRefiner[] | undefined>();
    const [relRecordsInProgress, setRelRecordsInProgress] = React.useState<boolean>(false);
    const [orderBy, setOrderBy] = React.useState<IOrderBy | undefined>();

    //COMMON
    const [updateInProgress, setUpdateInProgress] = React.useState<boolean | undefined>(false);
    const [error, setError] = React.useState<Error | undefined>();

    //VARS AND FUNCS
    const relatedRecordsIsLoading: boolean = isLoading || relRecordsInProgress;
    const deleteBtnIsActive: boolean = !!(
        relItemIndexesSelected.length > 0 &&
        currentRecord?.recordId &&
        !relatedRecordsIsLoading &&
        !updateInProgress
    );

    const onSaveClick: () => void = React.useCallback(() => {
        if (deleteBtnIsActive && currentRecord?.recordId) {
            const newRelRecords: IRecordResult[] = (relRecords || []).filter(
                (r, i) => relItemIndexesSelected.filter(idx => idx === i).length == 0
            );
            setUpdateInProgress(true);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: currentRecord.recordId,
                        relatedRecords: newRelRecords.map(r => r.recordId?.value).join(";")
                    },
                    {
                        onSuccess: () => {
                            setUpdateInProgress(false);
                        },
                        onError: e => {
                            setError(e);
                            setUpdateInProgress(false);
                        }
                    }
                )
            );
        }
    }, [dispatch, currentRecord?.recordId, deleteBtnIsActive, relItemIndexesSelected, relRecords]);

    function onOrderChange(fieldName: string | undefined, isDesc: boolean | undefined): void {
        setOrderBy(fieldName ? { fieldName: fieldName, isDesc: !!isDesc } : undefined);
    }

    const getTableData: (
        searchTerm?: string,
        searfilters?: string[]
    ) => Promise<IRecordResults | null> = React.useCallback(
        async (searchTerm?: string, filters?: string[]): Promise<IRecordResults | null> => {
            const resolver: DependencyResolver = new DependencyResolver();
            const apiService: IApiService = resolver.ResolveIApiService();
            const requestBody: IGetRecordsRequest = {
                managedProperties: ManageRecordRelatedRecordsFilters.managedProperties,
                getRefiners: false,
                getResults: true,
                recordViewType: RecordViewType.All,
                proxy: proxy,
                orderByDescending: orderBy?.isDesc,
                orderByManagedProperty: orderBy?.fieldName,
                searchTerm: searchTerm,
                saveConfiguration: true,
                filters: filters
            };

            const result: IRecordResults | null = await apiService.GetRecords(requestBody);
            return result;
        },
        [orderBy, proxy]
    );

    const onSelectedRelRecordsCountChange: (s: Selection) => void = React.useCallback(
        (selection: Selection) => {
            setRelItemIndexesSelected(selection.getSelectedIndices());
        },
        [setRelItemIndexesSelected]
    );

    React.useEffect(() => {
        // init related records ids
        if (currentRecord && currentRecord.relatedRecords) {
            setRelatedRecordIds(currentRecord.relatedRecords.split(";"));
        }
    }, [currentRecord]);

    React.useEffect(() => {
        // cast filters based on related records ids on chage/init
        if (relatedRecordIds) {
            const refiners: IRefiner[] = ManageRecordRelatedRecordsFilters.getFilters(
                relatedRecordIds.join(";")
            );
            setRefiners(refiners);
        }
    }, [relatedRecordIds]);

    React.useEffect(() => {
        // launch query on refiners/filters changes for "related records"
        setRelRecordsInProgress(true);
        (async () => {
            if (refiners && refiners.length) {
                const filters: string[] | undefined = buildRefinableFilterString(refiners);
                const recordsResponse: IRecordResults | null = await getTableData(
                    undefined,
                    filters
                );
                setRelColumns(recordsResponse?.columns);
                setRelRecords(recordsResponse?.records);
                setRelRecordsInProgress(false);
            } else {
                setRelRecords([]);
                setRelRecordsInProgress(false);
            }
        })();
    }, [getTableData, refiners]);

    const addActionsColumn = (
        result: ITableColumn[],
        onSaveClick: () => void,
        relItemIndexesSelected: number[],
        updateInProgress: boolean | undefined
    ): void => {
        result.push({
            onRender: (item, index) =>
                relItemIndexesSelected.indexOf(index as number) >= 0 ? (
                    <ActionButton
                        className={styles.deleteBtn}
                        onClick={onSaveClick}
                        disabled={updateInProgress}
                        iconProps={{ iconName: "Delete" }}
                    >
                        <Text className={styles.text}>Delete</Text>
                        {updateInProgress && (
                            <Spinner className={styles.spinner} size={SpinnerSize.small} />
                        )}
                    </ActionButton>
                ) : (
                    <></>
                ),
            isSortable: false,
            key: "deleteColumn",
            minWidth: 100,
            name: "",
            className: styles.actionsCell
        });
    };

    const relTableColumns: ITableColumn[] = React.useMemo(() => {
        const cols: IRecordColumn[] = (relColumns || [])
            .filter(c => !!c.isSelected)
            .map(c => {
                return {
                    sortColumnName: c.managedPropertyName || "",
                    isSortable: c.isSortable || false,
                    key: c.key || "",
                    name: c.displayName || "",
                    type: c.type
                };
            });
        const result: ITableColumn[] = cols.map(c => buildRecordTableColumn(c, true));
        addActionsColumn(result, onSaveClick, relItemIndexesSelected, updateInProgress);
        return result;
    }, [relColumns, onSaveClick, relItemIndexesSelected, updateInProgress]);

    const canEditRelatedRecords: boolean =
        !!currentRecord?.canCurrentUserEditRecord &&
        !currentRecord?.canCurrentUsedEditRecordAdminLimitedAccess;

    return (
        <div className={styles.manageRecordRelatedRecordsContainer}>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>{editRelatedRecordsHeader}</h2>
            </div>
            <div className={styles.sectionHeader}>
                <h2>{RecordRelatedRecordsHeader}</h2>
                {canEditRelatedRecords && (
                    <div className={styles.btnsContainer}>
                        <Link
                            to={AppRoutes.buildUrl(AppRoutes.manageRelatedRecords, {
                                recordId: currentRecord?.recordId || ""
                            })}
                        >
                            <ActionButton iconProps={{ iconName: "Add" }} text={"Add"} />
                        </Link>
                        {isMobile ? (
                            <ActionButton
                                className={styles.deleteBtn}
                                onClick={onSaveClick}
                                disabled={!deleteBtnIsActive}
                                iconProps={{ iconName: "Delete" }}
                            >
                                <Text className={styles.text}>Delete</Text>
                                {updateInProgress && (
                                    <Spinner className={styles.spinner} size={SpinnerSize.small} />
                                )}
                            </ActionButton>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </div>
            <div>
                {!!error && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        {error.message}
                    </MessageBar>
                )}
            </div>
            <div className={styles.table}>
                <div>
                    {!isMobile ? (
                        <Table
                            key={"relatedRecords"}
                            items={relRecords}
                            columns={relTableColumns}
                            isLoading={relatedRecordsIsLoading}
                            selectionMode={SelectionMode.single}
                            onSelectionChange={onSelectedRelRecordsCountChange}
                            onOrderBy={onOrderChange}
                        />
                    ) : (
                        <Cards
                            items={relRecords}
                            showSelection={true}
                            onSelectionChange={onSelectedRelRecordsCountChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
