import * as React from "react";
import {
    DefaultButton,
    Dropdown,
    Icon,
    IDropdownOption,
    Separator,
    Text,
    TextField,
    TooltipHost
} from "@fluentui/react";
import styles from "./Role.module.scss";
import PeoplePicker from "../PeoplePicker/PeoplePicker";
import { IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import { ChangeReasonLabel, UnassignRoleDescriptionPart1, UnassignRoleDescriptionPart2, WhoViewCommentsDescription, WhoViewCommentsLabel } from "../../../providers/Constants/FormControlConstants";
import ModalContainer from "../ModalContainer/ModalContainer";

interface IRoleFormProps {
    onCancel: () => void;
    onSubmit: () => Promise<void>;
    dialogTitle: string;
    selectedUsers?: IUser[] | null;
    setSelectedUsers: (value: React.SetStateAction<IUser[] | null>) => void;
    changeReason?: string | null;
    setChangeReason: (value: React.SetStateAction<string | null>) => void;
    isCollaborator?: boolean;
    usersToExclude?: IUser[] | null;
    submitDisabled?: boolean;
    submitBtnLabel?: string;
    multi?: boolean;
    showUnAssignBtn?: boolean;
    changeReasonIsActive?: boolean;
    taskOptions: IDropdownOption[];
}

export default function RoleForm(props: IRoleFormProps): JSX.Element {
    const [inProgress, setInProgress] = React.useState<boolean>(false);

    const onSubmit: () => Promise<void> = React.useCallback(async (): Promise<void> => {
        try {
            setInProgress(true);
            await props.onSubmit();
        } finally {
            setInProgress(false);
        }
    }, [props]);

    return (
        <ModalContainer
            isOpen={true}
            title={props.dialogTitle}
            submitLabel={props.submitBtnLabel}
            onCancel={props.onCancel}
            onSubmit={onSubmit}
            submitDisabled={props.submitDisabled}
        >
            <div className={styles.roleForm}>
                {props.showUnAssignBtn && (
                    <>
                        <div>{UnassignRoleDescriptionPart1}</div>
                        <div>{UnassignRoleDescriptionPart2}</div>
                        <div className={styles.unAssignSection}>
                            <div>
                                <Text className={styles.subTitle}>Unassign this role</Text>
                            </div>
                            <div>
                                <Text>This will remove the current person from this role</Text>
                            </div>
                            <div>
                                <DefaultButton
                                    toggle
                                    text={"Unassign role"}
                                    iconProps={{ iconName: "UserRemove" }}
                                    onClick={() => {
                                        props.setSelectedUsers(null);
                                    }}
                                    disabled={inProgress}
                                />
                            </div>
                        </div>
                        <div>
                            <Separator />
                        </div>
                        <div>
                            <Text className={styles.subTitle}>Reassign role to:</Text>
                        </div>                        
                    </>
                )}
                <div className={styles.changePersonSection}>
                    <div className={styles.searchPeople}>
                        <PeoplePicker
                            label={"Search people"}
                            onChange={(users: IUser[] | undefined) => {
                                props.setSelectedUsers(users || null);
                            }}
                            selectedUsers={props.selectedUsers || undefined}
                            usersToExclude={props.usersToExclude || undefined}
                            multi={props.multi}
                            disabled={inProgress}
                            isRequired={!props.showUnAssignBtn}
                        />
                    </div>
                    <div>
                        {props.isCollaborator && (
                            <Dropdown
                                className={styles.taskDropdown}
                                options={props.taskOptions}
                                label="Assign a task"
                                disabled={inProgress}
                                required={true}
                            />
                        )}
                    </div>
                    <div className={styles.reasonChange}>
                        {props.changeReasonIsActive && (
                            <>
                                <TextField
                                    className={styles.reasonTextField}
                                    multiline
                                    autoAdjustHeight
                                    label={ChangeReasonLabel}
                                    defaultValue={props.changeReason || undefined}
                                    onChange={(e, newValue) => props.setChangeReason(newValue || null)}
                                    disabled={inProgress}
                                    required={true}
                                />
                                <div className={styles.whoComments}>{WhoViewCommentsLabel}
                                    <TooltipHost content={WhoViewCommentsDescription} className={styles.toolTip}>
                                        <Icon iconName="Info" className={styles.infoIcon} />
                                    </TooltipHost>
                                </div> 
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}
