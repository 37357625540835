export class FileHelper {
    public static imageMimeTypes: string[] = [
        "image/bmp",
        "image/jpeg",
        "image/png",
        "image/x-icon",
        "image/svg+xml",
    ];

    public static async fileContentAsBase64(contentUrl: string): Promise<string> {
        const blob: Blob = await FileHelper.getFileBlob(contentUrl);
        return await blobToBase64(blob);
    }

    public static async getFileBlob(contentUrl: string): Promise<Blob> {
        const fileResponse: Response = await fetch(contentUrl);
        const blob: Blob = await fileResponse.blob();
        return blob;
    }

    public static async blobAsBase64Image(blob: Blob): Promise<string> {
        const base64Content: string = await blobToBase64(blob);
        return `data:${blob.type};base64,${base64Content}`;
    }

    public static isImageBlob(blob: Blob): boolean {
        return FileHelper.imageMimeTypes.includes(blob.type);
    }

    public static downloadBlob(blob: Blob, fileName: string): void {
        const a: HTMLAnchorElement = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName || "file.pdf";
        a.click();
    }
}

async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise(resolve => {
        const reader: FileReader = new FileReader();
        reader.onloadend = () => {
            let base64String: string = `${reader.result}`;
            const metaIndex: number = base64String.indexOf(",");
            if (metaIndex > 0) {
                base64String = base64String.substr(metaIndex + 1);
            }
            resolve(base64String);
        };
        reader.readAsDataURL(blob);
    });
}