import {
    Link
} from "react-router-dom";
import * as React from "react";
import {
    Icon
} from "@fluentui/react";
import styles from "./Breadcrumbs.module.scss";
import {
    IRecord
} from "../../../services/Api/executor/IApiServiceExecutor";
import {
    breadcrumbDashboard,
    breadcrumbManageRecord,
    breadcrumbPages,
    breadcrumbIconAriaLabel
} from "../../../providers/Constants/RecordWizardConstants";
import {
    AppRoutes
} from "../../../app/Constants";
import { useSelector } from "react-redux";
import { selectIsMobile } from "../../../app/globalSlices/contextSlice";

export interface IBreadcrumbProps {
    pageName: string | null;
    record?: IRecord;
    isThirdStep?: boolean;
}

export default function Breadcumbs(props: IBreadcrumbProps): JSX.Element {
    const isMobile: boolean = useSelector(selectIsMobile);

    const isDashboardlink: boolean = !!props.pageName;
    const breadcrumbPageConfig: string | undefined = React.useMemo(() => {
        return breadcrumbPages.find(pageItem => pageItem === props.pageName);
    }, [props.pageName]);

    return (
        <div className={`${styles.breadcumbs} ${
            isMobile ? styles.breadcumbsMobile : ""
        }` }>
            <div className={styles.items}>
                <Link to={AppRoutes.home}>
                    <div
                        className={`${styles.dashboardText} ${
                            isDashboardlink ? styles.linkText : ""
                        }`}
                    >
                        {isMobile && isDashboardlink 
                            ? (props.isThirdStep ? "" : "...") 
                            : breadcrumbDashboard
                        }
                    </div>
                </Link>
                {props.isThirdStep && props.record && (
                    <>
                        {!isMobile && <Icon iconName={"ChevronRight"} ariaLabel={breadcrumbIconAriaLabel}/>}
                        <Link to={AppRoutes.buildUrl(AppRoutes.manageRecord, {
                            recordId: props.record.recordId || ""
                        })}>
                            <div
                                className={`${styles.dashboardText} ${
                                    isDashboardlink ? styles.linkText : ""
                                }`}
                            >
                                {isMobile ? "..." :breadcrumbManageRecord}
                            </div>
                        </Link>
                    </>
                )}
                {breadcrumbPageConfig && (
                    <>
                        {!isMobile && <Icon iconName={"ChevronRight"} />}
                        <div className={styles.currentPageText}>{breadcrumbPageConfig}</div>
                    </>
                )}
            </div>
        </div>
    );
}
