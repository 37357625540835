// 1. IMPORTS
import React, { Dispatch } from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "@fluentui/react";
import styles from "./Dashboard.module.scss";
import {
    activeTabKey,
    closedTabKey,
    myTabKey,
    openTabKey
} from "../../providers/Constants/DashboardConstants";
import { TableType } from "../../components/FormControls/Table/TableType";
import TabsController, { ITab } from "../../components/FormControls/TabsController/TabsController";
// import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { AppRoutes } from "../../app/Constants";
import { RecordsStatistics } from "../recordsStatistics/RecordsStatistics";
import { RecordsTable } from "../recordsTable/RecordsTable";
import { DashboardInfoBar } from "../dashboardInfoBar/DashboardInfoBar";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMobile, setAppTitle } from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";

export interface IDashboardProps {
    //for testing only
    supressAutoLoad?: boolean;
}
// 2. DEFINE PROPS

export function Dashboard({ supressAutoLoad }: IDashboardProps): JSX.Element {
    // 3. DEFINE COMPONENT STATE
    const isMobile: boolean = useSelector(selectIsMobile);
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    
    // 4. GET DISPATCH
    // 5. DEFINE COMPONENT HOOKS
    // 7. RETURN RENDER TEMPLATE

    React.useEffect(() => {
        dispatch(setAppTitle(t("dashboardPageTitle")));
    }
    , [dispatch, t]);


    const dashboardTabs: ITab[] = [
        {
            name: t("activeTabName"),
            key: activeTabKey,            
            content: <RecordsTable
                key={TableType.ActiveTasksTable}
                displayMobile={isMobile}
                tableType={TableType.ActiveTasksTable}
                hideEditCols={false}
                hideExportCsv={false}
                hideExportPdf={false}
                hideHeaderControls={false}
                hideRefiner={false}
                hideSearchBox={false}
                hideSelection={false}
            />
        },
        {
            name: t("myTabName"),
            key: myTabKey,
            content: <RecordsTable
                key={TableType.MyRecordsTable}
                displayMobile={isMobile}
                tableType={TableType.MyRecordsTable}
                hideEditCols={false}
                hideExportCsv={false}
                hideExportPdf={false}
                hideHeaderControls={false}
                hideRefiner={false}
                hideSearchBox={false}
                hideSelection={false}
            />
        },
        {
            name: t("openTabName"),
            key: openTabKey,
            content: <RecordsTable
                key={TableType.OpenRecordsTable}
                displayMobile={isMobile}
                tableType={TableType.OpenRecordsTable}
                hideEditCols={false}
                hideExportCsv={false}
                hideExportPdf={false}
                hideHeaderControls={false}
                hideRefiner={false}
                hideSearchBox={false}
                hideSelection={false}
            />
        },
        {
            name: t("closedTabName"),
            key: closedTabKey,
            content: <RecordsTable
                key={TableType.ClosedRecordsTable}
                displayMobile={isMobile}
                tableType={TableType.ClosedRecordsTable}
                hideEditCols={false}
                hideExportCsv={false}
                hideExportPdf={false}
                hideHeaderControls={false}
                hideRefiner={false}
                hideSearchBox={false}
                hideSelection={false}
            />
        }
    ];

    return (
        <>
            <DashboardInfoBar />
            {!supressAutoLoad && <RecordsStatistics mobileView={isMobile} />}
            <div className={`${styles.dashboardSection} ${styles.bgWhite}`}>
                { !isMobile && 
                <div className={styles.dashboardActionButtons}>
                    <div className={styles.buttonContainer}>
                        <Link to={AppRoutes.createRecord}>
                            <PrimaryButton
                                text={t("newRecordButton")}
                                iconProps={{ iconName: "Add" }}
                                allowDisabledFocus
                                ariaLabel={`${t("newRecordButton")} button`}
                            />
                        </Link>
                    </div>
                </div>
                }
                <TabsController
                    tabs={dashboardTabs}
                    mobileView={isMobile}
                    defaultTabKey={myTabKey}
                    removeTabLine={true}                
                />
            </div>
        </>
    );
}
