export const BreadcrumbAppSettings: string = "Application Settings";

export const SectionHeaderIcons: string = "Icons";
export const LabelFavicon: string = "Favicon";
export const LabelPrimaryIcon: string = "Primary Icon";
export const LabelSecondaryIcon: string = "Navbar Icon";
export const ErrorMessageFileTypes: string = "Wrong file type. Supported file types:";

export const IsLoadingText: string = "Is loading";
export const TxtBtnSave: string = "Save";
export const TxtBtnCancel: string = "Cancel";