import {
    IRecord,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";

export function hasUserPermission(record: IRecord | null, currentUser: IUser | null): boolean {
    let isUserPermission: boolean = false;
    if (record && currentUser && currentUser.graphUserId) {
        // ensure that we allow proxies to act on behalf of users as well
        const meAndProxies: string[] = (currentUser.proxyFor
            ?.map(p => p?.graphUserId?.toLocaleLowerCase())
            .filter(u => !!u) || []) as string[];
        meAndProxies.push(currentUser.graphUserId.toLocaleLowerCase());

        // check dept user access
        const isUserInitiator: boolean =
            record.initiator?.some(initiatorItem => meAndProxies.some(u => initiatorItem.graphUserId?.toLocaleLowerCase() === u)) ||
            false;
        const isAllowedDeptUser: boolean = !!isUserInitiator 
        || (record.taskMappings?.some(task => meAndProxies.some(u => task.assignedTo?.graphUserId?.toLocaleLowerCase() === u)) || false);
        if (isAllowedDeptUser) {
            // department users only allowed to edit these prior to decision stage
            const isPreDecisionStage: boolean = record?.decisionMadeBy === null || record?.decisionMadeBy === undefined;
            isUserPermission = isPreDecisionStage && isAllowedDeptUser;
        }
    }

    return isUserPermission;
}
