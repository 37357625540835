// import {
//     ModuleKind
// } from "typescript";
// import {
//     act
// } from "react-dom/test-utils";
// 1. IMPORTS
import {
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    FormMode,
    IContentType,
    IContentTypeField,
    IFieldValue,
    IGeneratePdfPackRequest,
    IIWantToOptions,
    IPdfPackResult,
    IRecord,
    IUpdateRecordInformationRequest,
    IUpdateRecordKeyDatesRequest,
    IUpdateRecordKeyRolesRequest,
    IUserSettings
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    AppThunk,
    RootState
} from "../../app/store";
import { setErrorText } from "../../app/globalSlices/contextSlice";
import ILogProvider from "../../providers/Log/ILogProvider";

// 2. TYPES AND INTERFACES

export enum ManageFormType {
    Correspondence = 1,
    Event = 2,
    Parliamentary = 3,
    Other = 4
}

export interface ITimeLineOptions{
    verticalLineStyleHeight: string;
}

// 3. FEATURE STATE
export interface IManageRecordWrapperState {
    currentRecord: IRecord | null;
    currentRecordType: IContentType | null;
    allFields: IContentTypeField[] | null;
    activeFields: IContentTypeField[] | null;
    inActiveFields: IContentTypeField[] | null;
    currentFieldValues: IFieldValue[] | null;
    errorMessage?: string;
    formType: ManageFormType;
    pdfPackLink: string | null;
    documentTabMode: FormMode;
    isLoading: boolean;
    documentLoading: boolean;
    iWantToOptions: IIWantToOptions | null;  
    forceReloadData: boolean; 
    timeLineOptions: ITimeLineOptions | null;
}

// 4. FEATURE INITIAL STATE
export const initialState: IManageRecordWrapperState = {
    currentRecord: null,
    currentRecordType: null,
    activeFields: null,
    inActiveFields: null,
    allFields: null,
    currentFieldValues: null,
    errorMessage: undefined,
    formType: ManageFormType.Other,
    pdfPackLink: null,
    documentTabMode: FormMode.View,
    isLoading: true,
    documentLoading: false,
    iWantToOptions: null,
    forceReloadData: false,
    timeLineOptions: null
};

function getFormType(contentType: IContentType | null):ManageFormType{
    if(contentType?.isCorrespondenceType === true){
        return ManageFormType.Correspondence;
    } 
    return ManageFormType.Other;
}

// 5. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const manageRecordWrapperSlice = createSlice({
    name: "manageRecordWrapper",
    initialState,
    reducers: {
        flushManageRecordState: (state: IManageRecordWrapperState) => {
            for (const key in initialState) {
                const keyAs: keyof IManageRecordWrapperState = key as keyof IManageRecordWrapperState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (state[keyAs] as any) = initialState[keyAs] as any;
            }
        },
        setRecord: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IRecord | null>
        ) => {
            state.currentRecord = action.payload;
            state.formType = getFormType(state.currentRecordType);
            state.currentFieldValues = action.payload?.fieldValues || null;
            state.isLoading = false;
        },
        setAllFields: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.allFields = action.payload;
        },
        setActiveFields: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.activeFields = action.payload;
        },
        setInactiveFields: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.inActiveFields = action.payload;
        },
        setRecordType: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IContentType | null>
        ) => {
            state.currentRecordType = action.payload;
            state.formType = getFormType(state.currentRecordType);
        },
        setFieldValues: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IFieldValue[] | null>
        ) => {
            state.currentFieldValues = action.payload;
        },
        setErrorMessage: (
            state: IManageRecordWrapperState,
            action: PayloadAction<string | undefined>
        ) => {
            state.errorMessage = action.payload;
        },
        setIsLoading: (state: IManageRecordWrapperState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setForceReloadData: (state: IManageRecordWrapperState, action: PayloadAction<boolean>) => {
            state.forceReloadData = action.payload;
        },
        mergeRecord: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IRecord | null>
        ) => {
            state.currentRecord = {
                ...state.currentRecord,
                ...action.payload
            };
            state.formType = getFormType(state.currentRecordType);
        },          
        setPdfPackLink: (state: IManageRecordWrapperState, action: PayloadAction<string | null>) => {
            state.pdfPackLink = action.payload;
        },
        setDocumentTabMode: (state: IManageRecordWrapperState, action: PayloadAction<FormMode>) => {
            state.documentTabMode = action.payload;
        },
        setDocumentFetching: (state: IManageRecordWrapperState, action: PayloadAction<boolean>) => {
            state.documentLoading = action.payload;
        },
        setIWantToOptions: (
            state: IManageRecordWrapperState,
            action: PayloadAction<IIWantToOptions | null>
        ) => {
            state.iWantToOptions = action.payload;
        },
        setTimeLineOptions: (state: IManageRecordWrapperState, action: PayloadAction<ITimeLineOptions | null>) => {
            state.timeLineOptions = action.payload;
        }
    }
});

// 6. SYNCRONOUS ACTIONS
export const {
    flushManageRecordState,
    setRecord,
    setRecordType,
    setErrorMessage,
    setActiveFields,
    setInactiveFields,
    setAllFields,
    setFieldValues,
    setIsLoading,
    setForceReloadData,
    setDocumentFetching,
    mergeRecord,
    setPdfPackLink,
    setDocumentTabMode,
    setIWantToOptions,
    setTimeLineOptions
} = manageRecordWrapperSlice.actions;

// 7. ASYNCRONOUS ACTIONS
export const getRecordAsync = (id: string, isSilent: boolean, includeTimelineAndMenu: boolean = false): AppThunk => async dispatch => {
    if (!isSilent) {
        dispatch(setIsLoading(true));
    }

    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const customRecordTypes: IContentType[] | null = await apiService.GetContentTypes();
       

    const record: IRecord | null = await apiService.GetRecord(id || "", includeTimelineAndMenu);
    if(record){
        const recordType: IContentType | undefined = customRecordTypes?.find(v=>v.rowKey === record.recordType);
        dispatch(setRecordType(recordType || null));
        dispatch(setRecord(record));
        if (record.iWantToOptionCollection){
            dispatch(setIWantToOptions(record.iWantToOptionCollection));
        }
    } else {
        if (!isSilent) {
            dispatch(setErrorMessage(`Unable to load record ${id}. You may not have access to view it or it may have been deleted.`));
        }
    }
};

export const  getRecordTypeWorkflowDiagramAsync = (recordType: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const logger: ILogProvider = resolver.ResolveILogProvider();
    try{
        const optionsResponse: string | null = await apiService.GetWorkflowDiagram(recordType); 
        logger.Info("getRecordTypeWorkflowDiagramAsync",optionsResponse || "");
    }  catch (e) {
        if (typeof e === "string") {
            dispatch(setErrorText(e));
        } else if (e instanceof Error) {
            dispatch(setErrorText(e.message));
        }
    }
};

export const getFieldAsync = (isSilent: boolean, contentTypeName: string): AppThunk => async dispatch => {
    if (!isSilent) {
        dispatch(setIsLoading(true));
    }

    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    const fields: IContentTypeField[] | null = await apiService.GetContentTypeFields(contentTypeName);
    if(fields){
        dispatch(setActiveFields(fields.filter(v=>v.isActive)));
        dispatch(setInactiveFields(fields.filter(v=>!v.isActive)));
        dispatch(setAllFields(fields));
    } else {
        if (!isSilent) {
            dispatch(setErrorMessage("Unable to load fields."));
        }
    }
};

export const getIWantToOptionsAsync = (recordId: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const optionsResponse: IIWantToOptions | null = await apiService.GetIWantToOptions(
        recordId
    );  
    dispatch(setIWantToOptions(optionsResponse));
};

// export const getRecordDepartmentActivity = (id?: string): AppThunk => async dispatch => {
//     // dispatch(setRecordDepartmentActivity(null));

//     const resolver: DependencyResolver = new DependencyResolver();
//     const apiService: IApiService = resolver.ResolveIApiService();

//     const departmentActivity: IRecordDepartmentActivities | null = await apiService.GetRecordDepartmentActivity(
//         id || ""
//     );
//     dispatch(setRecordDepartmentActivity(departmentActivity?.activities));
// };

export const updateRecordKeyRolesAsync = (
    payload: IUpdateRecordKeyRolesRequest,
    callback?: { onSuccess: () => void; onError: (err: Error) => void }
): AppThunk => async dispatch => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const record: IRecord | null = await apiService.UpdateRecordKeyRoles(payload);
        dispatch(mergeRecord(record));
        callback && callback.onSuccess && callback.onSuccess();
    } catch (e) {
        callback && callback.onError && callback.onError(e as Error);
    }
};

export const updateUserSettingsAsync = (
    payload: IUserSettings,
    callback?: { onSuccess: () => void; onError: () => void }
): AppThunk => async ()  => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        //const record: IRecord | null = await apiService.UpdateRecordKeyRoles(payload);
        // Update the user settings in table storage
        await apiService.UpdateUserSettings(payload);   
        //dispatch(mergeRecord(record));
        callback && callback.onSuccess && callback.onSuccess();
    } catch (e) {
        e && callback && callback.onError && callback.onError();
    }
};

export const updateRecordInformationAsync = (
    payload: IUpdateRecordInformationRequest,
    callback?: { onSuccess: () => void; onError: (err: Error) => void }
): AppThunk => async dispatch => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const record: IRecord | null = await apiService.UpdateRecordInformation(payload);
        if(record){
            dispatch(mergeRecord(record));
            // force i want to options to be refetched as they may have changed due to update
            dispatch(getIWantToOptionsAsync(record?.recordId || ""));
            callback && callback.onSuccess && callback.onSuccess();
        } else {
            callback && callback.onError && callback.onError(new Error("Unable to update record details ..."));    
        }
    } catch (e) {
        callback && callback.onError && callback.onError(e as Error);
    }
};

export const updateRecordKeyDatesAsync = (
    payload: IUpdateRecordKeyDatesRequest,
    callback?: { onSuccess: () => void; onError: (err: Error) => void }
): AppThunk => async dispatch => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const record: IRecord | null = await apiService.UpdateRecordKeyDates(payload);
        if(record){
            dispatch(mergeRecord(record));
            callback && callback.onSuccess && callback.onSuccess();
        } else {
            callback && callback.onError && callback.onError(new Error("Unable to update key dates ..."));    
        }
    } catch (e) {
        callback && callback.onError && callback.onError(e as Error);
    }
};

export const generatePdfPack = (
    payload: IGeneratePdfPackRequest,
    callback?: { onSuccess: (response: string | undefined) => void; onError: (err: Error) => void }
): AppThunk => async dispatch => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        const response: IPdfPackResult | null = await apiService.GeneratePdfPack(payload);
        dispatch(setPdfPackLink(response?.filePath || null));
        // await apiService.GeneratePdfPack(payload);

        callback && callback.onSuccess && callback.onSuccess(response?.filePath);
    } catch (e) {
        const error: Error = e as Error;
        callback && callback.onError && callback.onError(error);
    }
};

// 8. Selectors
export const selectCurrentRecord = (state: RootState): IRecord | null =>
    state.manageRecordWrapper.currentRecord;

export const selectCurrentRecordType = (state: RootState): IContentType | null =>
    state.manageRecordWrapper.currentRecordType;

export const selectAllFields = (state: RootState): IContentTypeField[] | null =>
    state.manageRecordWrapper.allFields;

export const selectActiveFields = (state: RootState): IContentTypeField[] | null =>
    state.manageRecordWrapper.activeFields;

export const selectInactiveFields = (state: RootState): IContentTypeField[] | null =>
    state.manageRecordWrapper.inActiveFields;

export const selectCurrentFieldValue = (state: RootState): IFieldValue[] | null =>
    state.manageRecordWrapper.currentFieldValues;

export const selectErrorMessage = (state: RootState): string | undefined =>
    state.manageRecordWrapper.errorMessage;
export const selectIsLoading = (state: RootState): boolean => state.manageRecordWrapper.isLoading;
export const selectForceReloadData = (state: RootState): boolean => state.manageRecordWrapper.forceReloadData;

export const documentTabMode = (state: RootState): FormMode =>
    state.manageRecordWrapper.documentTabMode;

export const documentLoading = (state: RootState): boolean =>
    state.manageRecordWrapper.documentLoading;

export const selectIWantToOptions = (state: RootState): IIWantToOptions | null =>
    state.manageRecordWrapper.iWantToOptions;

export const selectFormType = (state: RootState): ManageFormType =>
    state.manageRecordWrapper.formType;

export const selectTimelineOptions = (state: RootState): ITimeLineOptions | null => 
    state.manageRecordWrapper.timeLineOptions;

// 9. EXPORT REDUCER
export default manageRecordWrapperSlice.reducer;
