import React from "react";
import styles from "./SnapshotCards.module.scss";
import SummaryField from "../SummaryField/SummaryField";
import {ISnapshot } from "../../../services/Api/executor/IApiServiceExecutor";
import DateHelper from "../../../helpers/DateHelper";

interface ICardsProps {
    items: ISnapshot[] | undefined;
}

export default function SnapshotCards({ items }: ICardsProps): JSX.Element {
    const useLessPadding: boolean = true;
    const missingValueText: string = " - ";

    return (
        <div className={styles.cardsContainer}>
            {(items || []).map((snapshot: ISnapshot, idx: number) => (
                <div className={styles.card} key={idx}>
                    <SummaryField
                        fieldName={"Version"}
                        fieldValue={<>
                            <div>{snapshot.version}</div>   
                            <div>{DateHelper.ToDateTimeString(new Date(snapshot.createdDate || ""))}</div>
                        </>}
                        useLessPadding={useLessPadding}
                        missingValueText={missingValueText}
                    />
                    <SummaryField
                        fieldName={"Sent by"}
                        fieldValue={snapshot.sentBy?.displayName}
                        useLessPadding={useLessPadding}
                        missingValueText={missingValueText}
                    />
                    <SummaryField
                        fieldName={"Sent to"}
                        fieldValue={snapshot.sentTo?.displayName}
                        useLessPadding={useLessPadding}
                        missingValueText={missingValueText}
                    />
                    <SummaryField
                        fieldName={"Comments"}
                        fieldValue={snapshot.comments}
                        useLessPadding={useLessPadding}
                        missingValueText={missingValueText}
                    />
                    <SummaryField
                        fieldName={"Briefing documents"}
                        fieldValue={`Snapshot ${snapshot.version}`}
                        useLessPadding={useLessPadding}
                        missingValueText={missingValueText}
                        fieldHref={snapshot.contentURL}
                    />
                </div>
            ))}
        </div>
    );
}
