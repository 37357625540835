/* eslint-disable no-console */
import * as React from "react";
import {
    Icon,
    IDropdownOption,
    Persona,
    PersonaSize,
    Spinner,
    SpinnerSize,
    Text,
    TooltipHost
} from "@fluentui/react";
import styles from "./Role.module.scss";
import { IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import {
    AddLabel,
    AssignLabel,
    EditLabel,
    MandatoryString,
    NotAllocatedString,
    OptionalString,
    SaveLabel,
    PersonaComponentAltText
} from "../../../providers/Constants/FormControlConstants";
import RoleForm from "./RoleForm";

interface IRoleProps {
    roleName: string;
    selectedUsers: IUser[] | null;
    usersToExclude?: IUser[] | null;
    hideSelections?: boolean;
    changeReason?: string | null;
    isCollaborator?: boolean;
    removable?: boolean;
    tooltip?: string;
    isMandatory?: boolean;
    background?: "dark" | "light";
    hideUsersInCard?: boolean;
    onChange?(user: IUser[] | null, reason: string | null): void;
    readOnly?: boolean;
    isEditMode?: boolean;
    multi?: boolean;
    inProgress?: boolean;
    roleDescription?: string;
}

const taskOptions: IDropdownOption[] = [
    { key: 3, text: "Review and input advice and/or feedback" },
    { key: 4, text: "Endorse the recommendations" }
];

export default function Role(props: IRoleProps): JSX.Element {
    const [wasSubmitted, setWasSubmitted] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedUsers, setSelectedUsers] = React.useState<IUser[] | null>(props.selectedUsers);
    const [changeReason, setChangeReason] = React.useState<string | null>(props.changeReason || null);
    const isEditMode: boolean = !!props.isEditMode;
    const submitBtnLabel: string = SaveLabel;
    const showUnAssignBtn: boolean = isEditMode && !props.isMandatory && !!props.selectedUsers && !!props.selectedUsers.length && !props.hideSelections;
    const dialogTitle: string = `${props.selectedUsers && props.selectedUsers?.length ? EditLabel : AssignLabel} ${props.roleName}`;
    const background: string = props.background ? props.background : props.isMandatory ? "light" : "dark";
    // const [person, setPerson] = React.useState<IUser | null>(null);

    const isEditBtn: boolean = !!selectedUsers?.length;

    React.useEffect(() => {
        if (!modalOpen && props.selectedUsers != selectedUsers) {
            setSelectedUsers(props.selectedUsers);
        }
    }, [props.selectedUsers, modalOpen, selectedUsers]);
    React.useEffect(() => {
        setChangeReason(props.changeReason || null);
    }, [props.changeReason]);

    React.useEffect(() => {
        if (props.inProgress === false && wasSubmitted) {
            setWasSubmitted(false);
        }
    }, [props.inProgress, wasSubmitted]);

    const openModal = () => setModalOpen(true);

    const dismissModal = () => {
        setSelectedUsers(props.selectedUsers);
        setModalOpen(false);
    };

    const submit = async () => {
        if (props.onChange) {
            //don't remove await
            await props.onChange(selectedUsers, changeReason);
        }
        setModalOpen(false);
        setWasSubmitted(true);
    };
    const changeReasonIsActive: boolean = !!(isEditMode
        && (props.selectedUsers && props.selectedUsers.length));
    const submitDisabled: boolean = !!(
        isEditMode && (props.selectedUsers === selectedUsers)
        || changeReasonIsActive && !changeReason
        || props.isMandatory && (!selectedUsers || !selectedUsers.length)
        || props.inProgress
    );

    const userInfoElements: JSX.Element | JSX.Element[] = React.useMemo(():
        | JSX.Element
        | JSX.Element[] => {
        return selectedUsers && selectedUsers.length && !props.hideUsersInCard ? (
            selectedUsers.map(selectedUser => {
                return (
                    <Persona
                        key={selectedUser.siteUserId}
                        size={PersonaSize.size40}
                        className={styles.persona}
                        text={selectedUser.displayName || undefined}
                        secondaryText={selectedUser.jobTitle || undefined}
                        data-alt={`${PersonaComponentAltText} ${selectedUser.displayName || ""}`}
                    />
                );
            })
        ) : (
            <div className={`${styles.notAllocatedText} ${props.isMandatory ? styles.required : ""}`}>
                <Text>{NotAllocatedString}</Text>
                <Text className={styles.fieldTypeText}>
                    {props.isMandatory ? ` ${MandatoryString}` : ` ${OptionalString}`}
                </Text>
            </div>
        );
    }, [props.hideUsersInCard, props.isMandatory, selectedUsers]);

    return (
        <>
            {modalOpen && (
                <RoleForm
                    onCancel={dismissModal}
                    onSubmit={submit}
                    dialogTitle={dialogTitle}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    changeReason={changeReason}
                    setChangeReason={setChangeReason}
                    isCollaborator={props.isCollaborator}
                    usersToExclude={props.usersToExclude}
                    submitDisabled={submitDisabled}
                    submitBtnLabel={submitBtnLabel}
                    multi={props.multi}
                    showUnAssignBtn={showUnAssignBtn}
                    taskOptions={taskOptions}
                    changeReasonIsActive={changeReasonIsActive}
                />
            )}
            <div className={styles.roleContainer}>
                <div
                    className={
                        `${styles.roleContent} ${background === "light" ||
                            (selectedUsers && selectedUsers.length && !props.hideUsersInCard)
                            ? styles.roleMandatoryBackground
                            : styles.roleOptionalBackground
                        }`
                    }
                >
                    <div className={styles.roleHeader}>
                        <div className={styles.roleNameText}>
                            {props.roleName}
                            <TooltipHost content={(props.roleDescription)?props.roleDescription:props.roleName} className={styles.toolTip}>
                                <Icon iconName="Info" className={styles.infoIcon} />
                            </TooltipHost>
                        </div>

                        {!props.readOnly && (props.inProgress && wasSubmitted
                            ? <div className={styles.addIconContainer}>
                                <Spinner size={SpinnerSize.medium} />
                            </div>
                            : <div className={styles.addIconContainer} onClick={openModal}>
                                <Icon className={styles.addIcon} iconName={isEditBtn ? "Edit" : "Add"} />
                                <div className={styles.addIconText}>
                                    {isEditBtn ? EditLabel : AddLabel}
                                </div>
                            </div>
                        )}

                    </div>
                    <div>
                        {userInfoElements}
                    </div>
                </div>
            </div>
        </>
    );
}
