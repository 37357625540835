import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { correspondenceDetailsHeaderStrings } from "../../providers/Constants/RecordWizardConstants";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { Dispatch } from "@reduxjs/toolkit";
import {
    ActionButton,
    DefaultButton,
    MessageBar,
    // MessageBarButton,
    MessageBarType,
    PrimaryButton,
    Separator,
    TextField,
    TooltipHost
} from "@fluentui/react";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import {
    addNewContactButtonLabel,
    backButtonLabel,
    batchRecordHeaderLabel,
    canNotModifiedButtonLabel,
    deleteButtonLabel,
    editButtonLabel,
    fromCorrespondentsLabel,
    hideFullDetailsButtonLabel,
    searchSenderFieldLabel,
    searchReceiverFieldLabel,
    showFullDetailsButtonLabel,
    subjectFieldLabel,
    successMessageBarLabel,
    toCorrespondentsLabel,
    viewMessageButtonLabel,
    viewPreviousRecordsButtonLabel,
    warningMessageBarLabel,
    saveSenderContactButtonLabel,
    saveRecipientButtonLabel,
    warningMessageBarNoUserLabel
} from "./ContactFormConstants";
import { useSelector } from "react-redux";
import {
    editSelectedFile,
    ImsgParsedFile,
    stateSelectedFiles
} from "../wizardCorrespondenceFiles/wizardCorrespondenceFilesSlice";
import DateHelper from "../../helpers/DateHelper";
import { IContact, ContactSource, IContentType } from "../../services/Api/executor/IApiServiceExecutor";
import ContactPicker from "../../components/FormControls/ContactPicker/ContactPicker";
import ContactForm from "./ContactForm/ContactForm";
import styles from "./WizardCorrespondenceDetails.module.scss";
import {
    navigateToPage,
    setPreventNext,
    WizardStates
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import { selectIsMobile } from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";
import { selectSelectedRecordType } from "../wizardCreateRecord/wizardCreateRecordSlice";

interface IWizardBatchRecordItemProps {
    index: number;
    file: ImsgParsedFile;
    setUpdatedFile: (updatedFile: ImsgParsedFile) => void;
    readOnly?: boolean;
    required: boolean;
    isMobileView?: boolean;
}

interface IInfoMessageBarProps {
    text: string;
    type: MessageBarType;
    actionsButtonLabel?: string;
}

interface IFormProps {
    contact?: IContact;
    isEditMode: boolean;
}

interface ICorrespondentsDetailsProps {
    label: string;
    onSaveCorrespondent: (contact: IContact | undefined, removedContact?: IContact) => void;
    contacts?: IContact[];
    readOnly?: boolean;
    required: boolean;
    searchLabel:string;
    confirmButtonLabel?:string;
}

type SimpleFuncType = () => void;

interface IContactItemProps {
    contact: IContact;
    showActionButtons: boolean;
    onDelete: (contact: IContact | undefined, removedContact?: IContact) => void | SimpleFuncType;
    onEdit: (value: React.SetStateAction<IFormProps | undefined>) => void | SimpleFuncType;
    readOnly?: boolean;
    isLastItem?: boolean;
    className?: string;
    disabled?: boolean;
}

export const ContactItem = (props: IContactItemProps): JSX.Element => {

    const castAddressMessage: (address?: string) => string = React.useCallback(
        (address?: string): string => {
            return address ? address.replace(/,/g, ",\n") : "";
        },
        []
    );

    const getUserInformationElements: (user: IContact) => JSX.Element[] = React.useCallback(
        (user: IContact): JSX.Element[] => {            
            const validFieldsOrder: string[] = [
                "fullName",
                "jobTitle",
                "email",
                "workPhone",
                "organization",
                "address"
            ];
            const userFields: string[] = Object.keys(user).filter(
                e => validFieldsOrder.indexOf(e) == -1
            );
            const orderedFields: string[] = validFieldsOrder.concat(userFields);
            const elements: JSX.Element[] = Array.from(orderedFields).map((keyItem: string) => {
                let userElement: JSX.Element | undefined = undefined;
                switch (keyItem) {
                    case "fullName":
                        userElement = (
                            <div className={styles.userDisplayName}>
                                {user.fullName}
                            </div>
                        );
                        break;
                    case "email":
                        userElement = user.email ? (
                            <div className={styles.userContact}>
                                <div className={styles.emailContainer}>
                                    <TooltipHost content={user.email}>
                                        <div>Email: </div>
                                    </TooltipHost>
                                    <span className={styles.email}>{user.email}</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        );
                        break;
                    case "workPhone":
                        userElement = user.workPhone ? (
                            <div className={styles.userContact}>
                                <div>Phone: {user.workPhone}</div>
                            </div>
                        ) : (
                            <></>
                        );
                        break;
                    case "address":
                        userElement = user.address ? (
                            <div className={styles.userContact}>
                                <div className={styles.multiline}>{castAddressMessage(user.address)}</div>
                            </div>
                        ) : (
                            <></>
                        );
                        break;
                    case "organization":
                        userElement = user.organization ? (
                            <div className={styles.organizationContainer}>
                                <div className={styles.userContact}>
                                    <div>{user.organization.fullName}</div>
                                    <div>Phone: {user.organization.workPhone}</div>
                                </div>
                                <div>
                                    <div className={styles.multiline}>{castAddressMessage(user.organization.address)}</div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        );
                        break;
                    default:
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        userElement = (user as any)[keyItem] && keyItem !== "id" && keyItem !== "sourceType" ? (
                            <div className={styles.userAdditionalInfo}>
                                {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    (user as any)[keyItem]
                                }
                            </div>
                        ) : (
                            <></>
                        );
                }
                return userElement;
            });
            return elements;
        },
        [castAddressMessage]
    );

    return (
        <div className={[styles.viewContactInfoContainer, props.className ? props.className : ""].join(" ")}>
            <div className={styles.contactInfoWithActionsButtonsContainer}>
                {props.showActionButtons && (
                    <div className={styles.actionButtonsContainer}>
                        {props.contact?.sourceType === ContactSource.AadUser ? (
                            <TooltipHost
                                content={canNotModifiedButtonLabel}
                            >
                                <ActionButton
                                    text={editButtonLabel}
                                    allowDisabledFocus
                                    iconProps={{
                                        iconName: "Edit"
                                    }}
                                    disabled={true}
                                />
                            </TooltipHost>
                        ) : (
                            <ActionButton
                                text={editButtonLabel}
                                allowDisabledFocus
                                iconProps={{
                                    iconName: "Edit"
                                }}
                                disabled={props.disabled}
                                onClick={() =>
                                    props.onEdit({
                                        contact: props.contact,
                                        isEditMode:
                                            !props.contact || props.contact?.sourceType === ContactSource.New
                                                ? false
                                                : true
                                    })
                                }
                            />
                        )}
                        <ActionButton
                            text={deleteButtonLabel}
                            disabled={props.disabled}
                            allowDisabledFocus
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => props.onDelete(undefined, props.contact)}
                        />
                    </div>
                )}
                <div className={styles.usetInformationContainer}>{getUserInformationElements(props.contact)}</div>
            </div>
            {!props.isLastItem && <Separator />}
        </div>
    );
};

const CorrespondentsDetails = ({
    label,
    onSaveCorrespondent,
    contacts,
    readOnly, searchLabel, confirmButtonLabel, required
}: ICorrespondentsDetailsProps): JSX.Element => {
    const [formProps, setFormProps] = React.useState<IFormProps | undefined>(undefined);
    const [newCorrespondent, setNewCorrespondent] = React.useState<IContact | undefined>(undefined);

    const setUpdatedContact: (contact: IContact) => void = React.useCallback((contact: IContact): void => {
        return onSaveCorrespondent(contact);
    }, [onSaveCorrespondent]);

    const onConfirmUpdateCreateContact: (contact: IContact) => Promise<void> = React.useCallback(
        async (updatedContact: IContact): Promise<void> => {
            if (updatedContact) {
                setUpdatedContact(updatedContact);

            }
        },
        [setUpdatedContact]
    );

    return (
        <div className={styles.columnFields}>
            <div className={styles.correspondentItemHeaderContainer}>
                <div className={styles.fromText}>{label}: </div>
                {!readOnly && contacts && contacts.length === 1 && (
                    <div className={styles.actionButtonsContainer}>
                        {contacts[0]?.sourceType === ContactSource.AadUser ? (
                            <TooltipHost
                                content={canNotModifiedButtonLabel}
                            >
                                <ActionButton
                                    text={editButtonLabel}
                                    allowDisabledFocus
                                    iconProps={{ iconName: "Edit" }}
                                    disabled={true}
                                />
                            </TooltipHost>
                        ) : (
                            <ActionButton
                                text={editButtonLabel}
                                allowDisabledFocus
                                iconProps={{ iconName: "Edit" }}
                                onClick={() => {
                                    setFormProps({
                                        contact: contacts[0],
                                        isEditMode:
                                            !contacts[0] ||
                                            contacts[0]?.sourceType === ContactSource.New
                                                ? false
                                                : true
                                    });
                                }}
                            />
                        )}
                        <ActionButton
                            text={deleteButtonLabel}
                            allowDisabledFocus
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => {
                                setNewCorrespondent(undefined);
                                onSaveCorrespondent(undefined, contacts[0]);
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.correspondentInfoContainer}>
                {contacts && contacts.length ? (
                    contacts.map((contactItem, index) => {
                        return (
                            <ContactItem
                                key={index}
                                contact={contactItem}
                                showActionButtons={!readOnly && contacts.length > 1}
                                onDelete={onSaveCorrespondent}
                                onEdit={setFormProps}
                                isLastItem={contacts && contacts.length - 1 === index}
                            />
                        );
                    })
                ) : (
                    <div className={styles.fieldsCorrespondentContainer}>
                        <ContactPicker
                            label={searchLabel}
                            isRequired={required}
                            selectedUsers={newCorrespondent ? [newCorrespondent] : undefined}
                            onChange={(user: IContact[] | undefined) =>
                                setNewCorrespondent(user ? user[0] : undefined)
                            }
                        />
                        <div className={styles.addNewCorrespondentContainer}>
                            <div className={styles.orText}>OR</div>
                            <PrimaryButton
                                className={styles.addNewCorrespondentButton}
                                allowDisabledFocus
                                text={addNewContactButtonLabel}
                                disabled={!!newCorrespondent}
                                onClick={() =>
                                    setFormProps({
                                        contact: undefined,
                                        isEditMode: false
                                    })
                                }
                            />
                        </div>
                        {newCorrespondent && (
                            <PrimaryButton
                                className={styles.saveNewCorrespondentButton}
                                text={confirmButtonLabel}
                                allowDisabledFocus
                                onClick={() => setUpdatedContact(newCorrespondent)}
                            />
                        )}
                    </div>
                )}
                {formProps && (
                    <ContactForm
                        onCancel={() => setFormProps(undefined)}
                        onSubmit={onConfirmUpdateCreateContact}
                        contact={formProps.contact}
                        isEditMode={formProps.isEditMode}
                    />
                )}
            </div>
        </div>
    );
};

const WizardBatchRecordItem = ({
    index,
    file,
    setUpdatedFile,
    readOnly,
    required,
}: IWizardBatchRecordItemProps): JSX.Element => {
    const [showFullDetails, setShowFullDetails] = React.useState<boolean>(true);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const [infoMessageBar, setInfoMessageBar] = useState<IInfoMessageBarProps | undefined>(
        undefined
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    React.useEffect(() => {
        const allFromUsersInDatabase: boolean | undefined = file?.from?.sourceType !== ContactSource.New;
        const allToUsersInDatabase: boolean | undefined =
            file?.to?.every(toContactItem => toContactItem.sourceType !== ContactSource.New) && !!file?.to?.length;
        if (allFromUsersInDatabase && allToUsersInDatabase) {
            setInfoMessageBar({
                text: successMessageBarLabel,
                type: MessageBarType.success,
                actionsButtonLabel: viewPreviousRecordsButtonLabel
            });
        } else {
            setInfoMessageBar({
                text: (file?.from == undefined) ? warningMessageBarNoUserLabel:warningMessageBarLabel,
                type: MessageBarType.warning
            });
        }
    }, [file?.from, file?.from?.sourceType, file?.to]);

    React.useEffect(() => {
        if (!file.sentOn || !file.receivedOn || !file.subject) {
            setIsEditMode(true);
        }
    }, [file.receivedOn, file.sentOn, file.subject]);

    const updateFromContact: (contact: IContact | undefined) => void = React.useCallback(
        (contact: IContact | undefined): void => {
            setUpdatedFile({ ...(file as ImsgParsedFile), from: contact });
        },
        [file, setUpdatedFile]
    );

    const updateToContact: (
        contact: IContact | undefined,
        removedItem?: IContact
    ) => void = React.useCallback(
        (contact: IContact | undefined, removedItem?: IContact): void => {
            const updatedToContacts: IContact[] = file.to ? [...file.to] : [];
            if (contact) {
                const fileIndex: number | undefined = file.to?.findIndex(
                    t => t.email === contact?.email || t.fullName === contact?.fullName
                );
                if (fileIndex !== undefined && fileIndex > -1) {
                    (updatedToContacts || []).splice(fileIndex, 1, contact);
                } else {
                    (updatedToContacts || []).push(contact);
                }
            } else if (removedItem) {
                const removedFileIndex: number | undefined = file.to?.findIndex(
                    t => t.email === removedItem?.email || t.fullName === removedItem?.fullName
                );
                if (removedFileIndex !== undefined && removedFileIndex > -1) {
                    (updatedToContacts || []).splice(removedFileIndex, 1);
                }
            }
            setUpdatedFile({ ...(file as ImsgParsedFile), to: updatedToContacts });
        },
        [file, setUpdatedFile]
    );

    return (
        <div className={styles.batchRecordContainer}>
            <div className={styles.batchRecordHeaderContainer}>
                <div className={styles.headerInfoContainer}>
                    <div>
                        {batchRecordHeaderLabel} #{index}:
                    </div>
                    <div>{file?.name}</div>
                </div>
                {file?.path &&
                    <div className={styles.actionButtonsContainer}>
                        <DefaultButton
                            allowDisabledFocus
                            text={viewMessageButtonLabel}
                            href={file?.path + "?web=1"}
                            target={"_blank"} // must open in new tab else breaks the create wizard flow as 'back' resets the wizard
                            rel="noreferrer"
                            className={styles.viewMessageButton}
                        />
                        <ActionButton
                            text={!isEditMode ? editButtonLabel : backButtonLabel}
                            allowDisabledFocus
                            iconProps={{ iconName: !isEditMode ? "Edit" : "Back" }}
                            onClick={() => {
                                if (!readOnly) {
                                    setIsEditMode(!isEditMode);
                                } else {
                                    dispatch(navigateToPage(WizardStates.CorrespondenceDetails));
                                }
                            }}
                        />
                        <ActionButton
                            text={deleteButtonLabel}
                            allowDisabledFocus
                            iconProps={{ iconName: "Delete" }}
                            onClick={() => dispatch(navigateToPage(WizardStates.CorrespondenceItems))}
                        />
                    </div>
                }
            </div>
            <ActionButton
                className={styles.hideOrShowButton}
                iconProps={{
                    iconName: showFullDetails ? "BoxSubtractSolid" : "BoxAdditionSolid"
                }}
                text={showFullDetails ? hideFullDetailsButtonLabel : showFullDetailsButtonLabel}
                onClick={() => setShowFullDetails(!showFullDetails)}
            />
            {showFullDetails && (
                <>
                    <Separator />
                    {!isEditMode ? (
                        <div className={styles.batchRecordInformationContainer}>
                            <div className={styles.columnsTypeContainer}>
                                <div className={styles.columnType}>{subjectFieldLabel}: </div>
                                <div className={styles.columnType}>{t("dateWrittenFieldLabel")}: </div>
                                <div className={styles.columnType}>
                                    {t("dateReceivedLabel")}:
                                </div>
                            </div>
                            <div className={styles.columnsValuesContainer}>
                                <div className={styles.columnValue}>{file?.subject}</div>
                                <div className={styles.columnValue}>
                                    {DateHelper.ToDateOnlyString(
                                        new Date(file?.sentOn || ""),
                                        true
                                    )}
                                </div>
                                <div className={styles.columnValue}>
                                    {DateHelper.ToDateOnlyString(
                                        new Date(file?.receivedOn || ""),
                                        true
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.batchRecordFieldsContainer}>
                            <TextField
                                multiline
                                rows={5}
                                label={subjectFieldLabel}
                                required={required}
                                defaultValue={file?.subject}
                                onChange={(
                                    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                    newValue?: string | undefined
                                ) =>
                                    setUpdatedFile({
                                        ...(file as ImsgParsedFile),
                                        subject: newValue
                                    })
                                }
                            />
                            <DatePicker
                                required={required}
                                dateLabel={t("dateWrittenFieldLabel")}
                                placeholder={t("datePickerPlaceholder")}
                                ariaLabel={t("dateWrittenPlaceholderText")}
                                includeTime={false}
                                onChange={(date: Date | null | undefined) =>
                                    setUpdatedFile({
                                        ...(file as ImsgParsedFile),
                                        sentOn: date ? new Date(date).toISOString() : undefined
                                    })
                                }
                                value={file?.sentOn ? new Date(file?.sentOn) : undefined}
                                defaultValue={file?.sentOn ? new Date(file?.sentOn) : undefined}
                            />
                            <DatePicker
                                required={required}
                                dateLabel={t("dateReceivedLabel")}
                                ariaLabel={t("dateReceivedPlaceholderText")}
                                placeholder={t("datePickerPlaceholder")}
                                includeTime={false}
                                onChange={(date: Date | null | undefined) =>
                                    setUpdatedFile({
                                        ...(file as ImsgParsedFile),
                                        receivedOn: date ? new Date(date).toISOString() : undefined
                                    })
                                }
                                value={file?.receivedOn ? new Date(file?.receivedOn) : undefined}
                                defaultValue={
                                    file?.receivedOn ? new Date(file?.receivedOn) : undefined
                                }
                            />
                        </div>
                    )}
                    <Separator />
                    {infoMessageBar && !readOnly && (
                        <MessageBar
                            // actions={
                            //     infoMessageBar.actionsButtonLabel ? (
                            //         <MessageBarButton>
                            //             {infoMessageBar.actionsButtonLabel}
                            //         </MessageBarButton>
                            //     ) : undefined
                            // }
                            messageBarType={infoMessageBar.type}
                            onDismiss={() => setInfoMessageBar(undefined)}
                            isMultiline={true}
                            dismissButtonAriaLabel="Close"
                        >
                            {infoMessageBar.text}
                        </MessageBar>
                    )}
                    <div className={styles.correspondentsContainer}>
                        <CorrespondentsDetails
                            label={fromCorrespondentsLabel}
                            contacts={file?.from ? [file?.from] : undefined}
                            readOnly={readOnly}
                            required={required}
                            onSaveCorrespondent={updateFromContact}
                            searchLabel={searchSenderFieldLabel}
                            confirmButtonLabel={saveSenderContactButtonLabel}
                        />
                        <CorrespondentsDetails
                            label={toCorrespondentsLabel}
                            contacts={file?.to}
                            readOnly={readOnly}
                            required={required}
                            onSaveCorrespondent={updateToContact}
                            searchLabel={searchReceiverFieldLabel}
                            confirmButtonLabel={saveRecipientButtonLabel}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

interface IBatchCorrespondenceRecordsProps {
    readOnly?: boolean;
}

export const BatchCorrespondenceRecords = ({
    readOnly
}: IBatchCorrespondenceRecordsProps): JSX.Element => {
    const isMobile: boolean = useSelector(selectIsMobile);
    const selectedFiles: ImsgParsedFile[] = useSelector(stateSelectedFiles);
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);
    const [updatedFiles, setUpdatedFiles] = React.useState<ImsgParsedFile[] | undefined>(undefined);
    const { t } = useTranslation();

    const isFilesRequired: boolean = selectedRecordType?.mandatoryCorrespondenceUpload !== false;
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    
    React.useEffect(() => {
        if (selectedFiles && !updatedFiles) {
            setUpdatedFiles(selectedFiles);
        } 
    }, [selectedFiles, updatedFiles]);

    React.useEffect(() => {
        if (!readOnly) {
            const isFilesNotEmpty: boolean = !!updatedFiles?.length;
            const isAnyRealFile: boolean = updatedFiles?.some(fileItem => !!fileItem.path) || false;
            const isValidUploadedFiles: boolean | undefined =
                updatedFiles &&
                !!updatedFiles.length &&
                updatedFiles.every(
                    fileItem =>
                        fileItem.subject &&
                        fileItem.sentOn &&
                        fileItem.receivedOn &&
                        fileItem.from?.sourceType !== ContactSource.New &&
                        fileItem.to?.every(toContactItem => toContactItem.sourceType !== ContactSource.New) &&
                        !!fileItem.to?.length
                );
            const isValidForNextStep: boolean = isFilesNotEmpty && isAnyRealFile ? !!isValidUploadedFiles : !isFilesRequired;
            if (!isValidForNextStep) {
                dispatch(setPreventNext(true));
            } else {
                dispatch(setPreventNext(false));
            }
        }
    }, [dispatch, readOnly, updatedFiles, isFilesRequired]);

    const updateFile: (updatedFile: ImsgParsedFile) => void = React.useCallback(
        (updatedFile: ImsgParsedFile): void => {
            const updatedSelectedFiles: ImsgParsedFile[] | undefined = updatedFiles
                ? [...updatedFiles]
                : undefined;
            const fileIndex: number | undefined = updatedFiles?.findIndex(
                fileItem => fileItem.name === updatedFile.name || fileItem.path === updatedFile.path
            );
            if (fileIndex !== undefined && fileIndex > -1) {
                (updatedSelectedFiles || []).splice(fileIndex, 1, updatedFile);
                setUpdatedFiles(updatedSelectedFiles);
                dispatch(editSelectedFile(updatedFile));
            }
        },
        [dispatch, updatedFiles]
    );

    return (
        <div className={styles.batchRecordsSection}>
            <div className={styles.batchRecordsContainer}>
                {selectedFiles?.length ? selectedFiles.map((fileItem, index) => {
                    return (
                        <WizardBatchRecordItem
                            key={fileItem.path + index}
                            index={index + 1}
                            file={fileItem}
                            readOnly={readOnly}
                            required={isFilesRequired || fileItem.path?.length > 0}
                            setUpdatedFile={updateFile}
                            isMobileView={isMobile}
                        />
                    );
                }) : (
                    <div className={styles.noCorrespondenceContainer}>
                        <div>{t("noCorrespondenceRecordsFound")}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export function WizardCorrespondenceDetails(): JSX.Element {
    return (
        <div className={styles.wizardCorrespondenceDetailsContainer}>
            <div className={styles.headerContainer}>
                <FormHeader strings={correspondenceDetailsHeaderStrings} />
            </div>
            <BatchCorrespondenceRecords />
        </div>
    );
}
