import { ITermGroup, ITermSet } from "../services/Api/executor/IApiServiceExecutor";

export default class TermsHelper {
    public static GetTermSetById(termGroup: ITermGroup | null, termSetId: string): ITermSet | null {
        const termSets: ITermSet[] = termGroup?.termSets || [];
        for (let i: number = 0; i < termSets.length; i++) {
            const termSet: ITermSet = termSets[i];
            if (termSet.id?.toLocaleLowerCase() === termSetId.toLocaleLowerCase()) {
                return termSet;
            }
        }
        return null;
    }
}