export enum CacheTimeout {
    oneSecond,
    veryShort,
    short,
    default,
    long,
    forever
}

export default interface ICacheProvider {
    Get<T>(key: string): Promise<T>;
    Set(key: string, valueObj: unknown, cacheTimeout: CacheTimeout): Promise<boolean>;
    Clear(key: string): Promise<void>;
}
