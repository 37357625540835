// eslint-disable-next-line quotes
export const reservedCharsForFileName: string[] = ['"', "*", ":", "<", ">", "?", "/", "\\", "|", ";"];

export default class StringHelper {

    public static hasReservedChars = (value: string, reservedChars: string[]): boolean => {
        return value.split("").some((c) => reservedChars.indexOf(c) !== -1);
    };

    public static isEqual = (str1: string | undefined | null, str2: string | undefined | null, caseInsenstive: boolean = false): boolean => {
        const firstString: string = str1 || "";
        const secondString: string = str2 || "";
        return caseInsenstive
            ? firstString.toLowerCase() === secondString.toLowerCase()
            : firstString === secondString;
    };

    public static isValidJson = (str: string): boolean => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    public static isJsonEmptyObject = (str: string): boolean =>
    {
        return str === "" 
        || str === undefined 
        || str === null 
        || (StringHelper.isValidJson(str) && JSON.parse(str) === null)
        || (StringHelper.isValidJson(str) && JSON.parse(str).length === 0);
    };
}
