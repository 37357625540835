import React from "react";
import { Icon, Link } from "@fluentui/react";
import styles from "./RecordTimeline.module.scss";
import { ITimeLineRecordElement } from "./RecordTimeline";
import PersonHoverCard from "../PersonHoverCard/PersonHoverCard";
import PeopleWrapper from "../PeopleWrapper/PeopleWrapper";
import {
    IContentTypeField,
    IRecord,
    IRecordDepartmentActivityItem,
    ISimpleUser,
    IUser
} from "../../../services/Api/executor/IApiServiceExecutor";
import DateHelper from "../../../helpers/DateHelper";
import { userRoles } from "../../../features/welcomeUser/WelcomeUser";
import { AppRoutes } from "../../../app/Constants";
import { StageTypes } from "../../../providers/Constants/TermConstants";
import { ActiveStageButtons } from "./ActiveStageButtons";

export const isMakeDecisionStage: (recordStage?: string | undefined) => boolean = (
    recordStage?: string
): boolean => {
    return recordStage?.startsWith(StageTypes.MakeDecisionGeneral) || false;
};

export const isClosed: (record: IRecord) => boolean = (record: IRecord): boolean => {
    return record?.stage === StageTypes.Closed;
};

export const isBlockedState: (record: IRecord) => boolean = (record: IRecord): boolean => {
    return !!record.onHold || !!record.noFurtherAction || !!record.withdrawn || !!record.cancelled || !!record.superseded || !!record.actionedByMcu || !!isClosed(record);
};

export const isManageSignaturesStage: (record: IRecord | null) => boolean = (record: IRecord | null): boolean => {
    return record?.canCurrentUserUploadSignedDocuments || false;
};

export const onHoldStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.OnHold as StageTypes, styles.onHoldStep, styles.onHoldIcon, "Blocked2", isMobileView);
};

export const noFurtherActionStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.NoFurtherAction as StageTypes, styles.noFurtherActionStep, styles.noFurtherActionIcon, "Completed", isMobileView);
};

export const withdrawnStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.Withdrawn as StageTypes, styles.withdrawnStep, styles.withdrawnIcon, "Completed", isMobileView);
};

export const cancelledStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.Cancelled as StageTypes, styles.cancelledStep, styles.cancelledIcon, "Completed", isMobileView);
};

export const supersededStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.Superseded as StageTypes, styles.supersededStep, styles.supersededIcon, "Completed", isMobileView);
};

export const actionedByMcuStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.ActionedByMcu as StageTypes, styles.actionedByMcuStep, styles.actionedByMcuIcon, "Completed", isMobileView);
};

export const closedStepElement = (record: IRecord, fields: IContentTypeField[], isMobileView?: boolean): ITimeLineRecordElement => {
    return blockingStepElement(record, fields, StageTypes.Closed as StageTypes, styles.closedStep, styles.closedIcon, "Completed", isMobileView);
};

const blockingStepElement = (record: IRecord, fields: IContentTypeField[], stageType: StageTypes, stepClassName: string, iconClassName: string, iconName: string, isMobileView?: boolean): ITimeLineRecordElement => {
    return {
        key: stageType,
        content: (
            <div
                className={[
                    styles.stage,
                    styles.predecessorActiveStep,
                    styles.additionalStep,
                    stepClassName
                ].join(" ")}
            >
                <div className={styles.leftSide}>
                    <div className={styles.headerIcon}>
                        <Icon className={iconClassName} iconName={iconName} ariaLabel={`${iconName} icon`}/>
                        <h3 className={styles.label}>{stageType}</h3>
                    </div>
                </div>
                <div className={styles.midArea}>
                </div>
                <div className={styles.rightSide}>
                    {isMobileView && (
                        <h3 className={[styles.label, styles.mobileBlockedSectionLabel].join(" ")}>{stageType}</h3>
                    )}
                    <ActiveStageButtons record={record} fields={fields}/>
                </div>
            </div>
        )
    };
};

export const renderPersonHoverCard: (user: IUser | ISimpleUser) => JSX.Element = (
    user: IUser | ISimpleUser
): JSX.Element => {
    return <PersonHoverCard key={user.siteUserId} className={styles.people} person={user} />;
};

export const renderPersonRole: (user: IUser | ISimpleUser) => JSX.Element = (
    user: IUser | ISimpleUser
): JSX.Element => {
    return (
        <span key={user.siteUserId} className={styles.people}>
            {userRoles(user)}
        </span>
    );
};

export const renderDueDate: (dateString: string | undefined | null) => JSX.Element = (
    dateString: string | undefined | null
): JSX.Element => {
    return !dateString ? <></> : <> on {getDueDateString(new Date(dateString), true)}</>;
};

export function GetRecordLink(recordId: string | null | undefined, label: string) : JSX.Element {
    return (
        !recordId ? <></> :
            <div className={styles.content}>
                <h4 style={{ marginRight: "5px" }}>{label} </h4>{" "}
                <Link href={"/#" + AppRoutes.buildUrl(AppRoutes.manageRecord, {
                    recordId: recordId
                })}>
                    <div className={styles.recordID}>
                        <Icon iconName="TextDocument" className={styles.icon} ariaLabel={"Record Icon"}/>
                        {recordId}
                    </div>
                </Link>
            </div>
    );
}

export function GetCustomActivitiesForStage(record: IRecord, stage: string): JSX.Element | null {
    const customActivities: IRecordDepartmentActivityItem[] | undefined =
        record.customActivities?.filter(v => v.stage === stage);
    return customActivities?.length ? (
        <>
            <div>
                {customActivities?.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.users?.length && (
                                <>
                                    <div className={styles.content}>
                                        <div className={styles.textInfoContainer}>{v.label}</div>
                                        <div className={styles.peopleContainer}>
                                            <PeopleWrapper
                                                users={v.users}
                                                onBehalfOf={v.onBehalfOf}
                                            />
                                        </div>
                                        {renderDueDate(v.date)}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    ) : null;
}

export const getDueDateString: (date: Date | string, extendedView?: boolean) => string = (
    date: Date | string,
    extendedView?: boolean
) => {
    if (extendedView) {
        const extendedDate: string = DateHelper.ToDateTimeString(
            new Date(date) as Date,
            false,
            true
        );
        return extendedDate;
    } else {
        const initialDate: Date = new Date(date);
        const month: string = initialDate.toLocaleDateString("en-AU", { month: "long" });
        const numberDate: string = initialDate.toLocaleDateString("en-AU", { day: "numeric" });
        return `${month} ${numberDate}`;
    }
};
