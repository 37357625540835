import React from "react";
import { IRefiner } from "../../../model/Refiner";
import DatePicker from "../DatePicker/DatePicker";
import styles from "./DateRefiner.module.scss";
import DateHelper from "../../../helpers/DateHelper";

export interface IDateRefinerResult {
    from?: Date;
    to?: Date;
}
interface IDateRefinerProps {
    refiner: IRefiner;
    value?: IRefiner;
    onChange: (value: IRefiner) => void;
}

export default function DateRefiner({ refiner, value, onChange }: IDateRefinerProps): JSX.Element {
    let from: string | undefined = value?.from;
    let to: string | undefined = value?.to;
    const fromDate: Date | undefined = castStringToDate(from);
    const toDate: Date | undefined = castStringToDate(to);

    function onChangeNotify() {
        const newValue: IRefiner = { ...refiner };
        if (from || to) {
            newValue.from = from || "min";
            newValue.to = to || "max";
        } else {
            newValue.from = undefined;
            newValue.to = undefined;
        }
        if (newValue.from !== value?.from || newValue.to !== value?.to) {
            onChange(newValue);
        }
    }

    function onFromChange(date: Date | null) {
        from = date ? date.toISOString() : undefined;
        onChangeNotify();
    }

    function onToChange(date: Date | null) {
        to = date ? date.toISOString() : undefined;
        onChangeNotify();
    }

    return <div className={styles.dateRefiner}>
        <div><DatePicker includeTime={false} required={false} dateLabel={"From"} onChange={onFromChange} value={fromDate} ariaLabel={"From Date Refiner"}/></div>
        <div><DatePicker includeTime={false} required={false} dateLabel={"To"} onChange={onToChange} value={toDate} ariaLabel={"To Date Refiner"}/></div>
    </div>;
}

export function TimeOnlyRefiner({ refiner, value, onChange }: IDateRefinerProps): JSX.Element {
    let from: string | undefined = value?.from;
    let to: string | undefined = value?.to;
    const fromNumber: Date | undefined = from ? DateHelper.ToTimeOnlyFromNumberAsDate(Number(from)) : undefined;
    const toDate: Date | undefined = to ? DateHelper.ToTimeOnlyFromNumberAsDate(Number(to)) : undefined;

    function onChangeNotify() {
        const newValue: IRefiner = { ...refiner };
        if (from || to) {
            newValue.from = from || "min";
            newValue.to = to || "max";
        } else {
            newValue.from = undefined;
            newValue.to = undefined;
        }
        if (newValue.from !== value?.from || newValue.to !== value?.to) {
            onChange(newValue);
        }
    }

    function onFromChange(date: Date | null) {
        from = date ? DateHelper.ToTimeOnlyFromNumberFromDate(date) : undefined;
        onChangeNotify();
    }

    function onToChange(date: Date | null) {
        to = date ? DateHelper.ToTimeOnlyFromNumberFromDate(date) : undefined;
        onChangeNotify();
    }

    return <div className={styles.dateRefiner}>
        <div>
            <DatePicker includeTime={true} hideDate={true} required={false} dateLabel={"From"} timeLabel={"From"} onChange={onFromChange} value={fromNumber} ariaLabel={"From Time Refiner"}/>
        </div>
        <div>
            <DatePicker includeTime={true} hideDate={true} required={false} dateLabel={"To"} timeLabel={"To"}  onChange={onToChange} value={toDate} ariaLabel={"To Time Refiner"}/>
        </div>
    </div>;
}

export function castStringToDate(dateSting: string | undefined): Date | undefined {
    let result: Date | undefined = undefined;
    if (dateSting) {
        const date: Date = new Date(dateSting);
        const dateIsValid: boolean = date instanceof Date && !isNaN(date.getDate());
        result = dateIsValid ? date : result;
    }
    return result;
}