import { HeaderStrings } from "./RecordWizardConstants";

export const notSpecifiedMessage: string = "Not specified";

// Event manage record panel
export const eventDateFieldLabel: string = "Event date:";
export const eventLocationFieldLabel: string = "Event location:";
export const eventTimeFieldLabel: string = "Event start time:";
export const eventEndTimeFieldLabel: string = "Event end time:";
export const neededForEventFieldLabel: string = "Needed for this event:";
export const eventSpeechLength: string = "Speech length:";

// Parliamentary manage record panel
export const parliamentaryQuestionsRaisedHeaderLabel: string = "Questions Raised";

export const parliamentaryQuestionAskedByFieldLabel: string = "Question asked by:";
export const parliamentaryQuestionDirectedToFieldLabel: string = "Question directed to:";
export const parliamentaryAskedAtFieldLabel: string = "Asked at:";
export const parliamentaryOnFieldLabel: string = "On:";
export const parliamentaryCoveringBriefFieldLabel: string = "Covering Brief:";
// Documents tab
export const manageSignatureTableHeaderDocumentName: string = "Document name";
export const manageSignatureTableHeaderSignatureInstructions: string = "Signature instructions";

// //Signature Instructions Form
export const signatureInstructFormHeader: string = "Signature instructions";
export const signatureInstructFormDescription: string = "";
export const signatureInstructFormDocumentsHeader: string = "Select the documents that require a signature";
export const signatureInstructFormSubmitBtnLabel: string = "Save";
export const signatureInstructFormCheckboxLabel: string = "Signature required";
export const signatureInstructFormTextAreaLabel: string = "Enter the signature type required and where to sign the document (e.g. wet ink signature - Page 7 - signature box at bottom of page)";

// 1st Screen: Manage Overview & Recommendations
// // Header
export const overviewRecommendationsFormHeaderStrings: HeaderStrings = {
    Header: "Manage overview and recommendations",
    SubTitle: "",
    DotPoints: []
};

export const recommendationsFormHeaderStrings: HeaderStrings = {
    Header: "Recommendations",
    SubTitle:
        "Your recommendations should be self-contained enough to make sense when standing alone.",
    DotPoints: []
};
// // Field Labels
export const fieldLabelTopic: string = "Purpose statement";
export const fieldLabelAnalysis: string = "Analysis";
export const recommendFieldLabelType: string = "Type";
export const recommendFieldLabelRecommend: string = "Recommendation";

// // Description for field
export const descriptionTopic: string =
    "The purpose statement should capture the issue and context in a concise sentence.";
export const descriptionAnalysis: string =
    "Summarise your conclusions in a short sentence, capturing the key reasons that support your recommendations.";

// // Placeholder
export const placeholderNoHasRecommendations: string = "There are currently no recommendations";
export const placeholderNoHasSignatureDocuments: string = "There are currently no signed documents and signature intructions";
export const placeholderRecommendFieldType: string = "Select type of recommendation";

// // Buttons
export const setOverviewRecommendations: string = "Save";
export const addRecommendations: string = "Add recommendations";
export const editRecommendationItem: string = "Edit";
export const deleteRecommendationItem: string = "Delete";
export const recommendationDescription:string = "Recommendations should be self-contained and make sense when standing alone.";

// Copy record page

// // Initial copy page
// // // Descriptions
export const copyRecordActionsDescription: string = "Select the details you want to copy to the new record";
export const copyRecordGeneralDescription: string = "";

export const supersedeRecordActionsDescription: string = "This will recall the record from the Decision Maker and initiate a new record using the details you select below. " 
+"The new record will be sent to the Lead Author and restart the process from the ‘Prepare and review’ stage. "
+"All Roles will be notified that the record has been superseded";
export const supersedeRecordGeneralDescription: string = "Select the details you want to copy into the new record:";

// // // Fields
export const duplicateRecordWithTemplatesFieldHeader: string = "Record details";
export const duplicateRecordWithTemplatesFieldDescription: string = "Copy all record details (without documents or attachments)";

export const duplicateRecordIncludingDocFieldHeader: string = "Record details and documents";
export const duplicateRecordIncludingDocFieldDescription: string = "Copy all record details, documents and attachments";

// // // Buttons
export const duplicateRecordButtonLabel: string = "Copy record";
export const supersedeRecordButtonLabel: string = "Copy record";

// // Copied page
export const recordCopyingHeaderStrings: HeaderStrings = {
    Header: "Your record is being copied..."
};
export const recordCopyingDescription: string =
    "A link will appear when the new record is ready, it may take up to 20 seconds. " +
    "Click the link to go to the Manage record page to continue working on the record. Alternatively, you can access the record from your Dashboard after a few minutes. ";

export const recordCopiedHeaderStrings: HeaderStrings = {
    Header: "Your record has been copied"
};
export const recordCopiedDescription: string =
    "Click the link to go to the Manage record page to continue working on the record or send it to the next person.";


export const recordSupersedingHeaderStrings: HeaderStrings = {
    Header: "Your record is being superseded..."
};
export const recordSupersedingDescription: string =
        "A link to the new record will appear when it is ready, it may take up to 20 seconds. Click the link to go to the Manage record page to continue working on the record. Alternatively, you can access the record from your Dashboard after a few minutes.";
    
export const recordSupersededHeaderStrings: HeaderStrings = {
    Header: "Your record has been superseded"
};

export const recordSupersededDescription: string =
    "Your record has been superseded with the new record above. Click the link to go to the Manage record page to continue working on the record or send it to the next person.";

// Misc
export const exitButton: string = "Cancel";

//Error of users permission
export const errorHeaderPermission: string = "Error in user access permissions";
export const errorInfoPermission: string =
    "You do not have access to change the overview and recommendations for the current page";


// Record types
export const recordTypeMinisterialEvent: string = "Ministerial Event (EMIN)";
export const recordTypeSecretarialEvent: string = "Secretarial Event (ESEC)";
export const recordTypeOrganisationalEvent: string = "Organisational Event (EORG)";

export const recordTypeAdjornmentParliamentary: string = "Adjornment Debate (AD)";
export const recordTypeConsistuencyParliamentary: string = "Consistuency Question (CQ)";
export const recordTypeWithoutNoticeParliamentary: string = "Question without Notice (QWN)";
export const recordTypeOnNoticeParliamentary: string = "Question on Notice (QN)";

export const recortTypeMinisterialBriefing: string = "Ministerial Briefing (BMIN)";

// Cards in Mobile View
export const downloadPDFPackMobile: string = "Download Pack";