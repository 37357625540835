// 1. IMPORTS
import { Link, useHistory } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { IconButton, Image, SearchBox, TooltipHost } from "@fluentui/react";
import styles from "./Header.module.scss";
import { AppRoutes } from "../../app/Constants";
import { LogoAltText } from "../../providers/Constants/HeaderConstants";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentUser,
    selectIsMobile,
    getCurrentContextAsync,
    selectHeaderBannerText,
    selectErrorText,
    selectShowValidationErrors
} from "../../app/globalSlices/contextSlice";
import {
    HeaderLinkType,
    IHeaderLink,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import UrlHelper from "../../helpers/UrlHelper";
import { resetAllRecordWizardState } from "../recordWizardWrapper/RecordWizardWrapper";
import { Dispatch } from "@reduxjs/toolkit";
import { setUserSettings } from "../NotificationSettings/NotificationSettingsSlice";
import { selectNavbarIcon } from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";

// 2. DEFINE PROPS
export function Header(): JSX.Element {
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const headerBannerText: string | undefined = useSelector(selectHeaderBannerText);
    const navbarIcon: string | undefined = useSelector(selectNavbarIcon);
    const errorText: string | undefined = useSelector(selectErrorText);
    const showValidationErrors: boolean | undefined = useSelector(selectShowValidationErrors);
    const [isErrorMessageCollapsed, setIsErrorMessageCollapsed] = useState<boolean>(false);
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    const isMobile: boolean = useSelector(selectIsMobile);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const dispatch: Dispatch<any> = useDispatch();
    const [links, setLinks] = React.useState<IHeaderLink[] | null>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!currentUser) {
            setIsLoading(true);
            dispatch(getCurrentContextAsync);
        } else {
            setIsLoading(false);
        }
    }, [currentUser, dispatch]);

    React.useEffect(() => {
        if (!links) {
            (async () => {
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const links: IHeaderLink[] | null = await apiService.GetHeaderLinks();
                setLinks(links || []);
            })();
        }
    }, [links, setLinks]);

    function handleSearch(newValue: string): void {
        history.push({
            pathname: AppRoutes.search,
            search: UrlHelper.castUrlWithQueryStringParam(
                newValue,
                t("searchTerm"),
                decodeURI(history.location.search)
            )
        });
    }

    // eslint-disable-next-line @typescript-eslint/typedef
    const renderHeaderLink = useCallback(
        (headerLink: IHeaderLink): JSX.Element => {
            let result: JSX.Element = <></>;

            let onClick: undefined | (() => void) = undefined;
            if (headerLink.href === "/create") {
                onClick = () => {
                    resetAllRecordWizardState(dispatch);
                };
            }
            if (headerLink.href === "/settings") {
                onClick = () => {
                    dispatch(setUserSettings(null));
                };
            }

            if(!isMobile && headerLink.isMobile){
                // skip link creation
            } else {
                switch (headerLink.type) {
                    case HeaderLinkType.Internal:
                        result = (
                            <TooltipHost
                                className={styles.toolTip}
                                content={headerLink.tooltip}
                                key={headerLink.tooltip}
                            >
                                <Link to={headerLink.href} onClick={onClick}>
                                    <IconButton
                                        iconProps={{ iconName: headerLink.iconName }}
                                        className={styles.btn}
                                        ariaLabel={`${headerLink.tooltip} button`}
                                    />
                                </Link>
                            </TooltipHost>
                        );
                        break;
                    case HeaderLinkType.External:
                        result = (
                            <TooltipHost
                                className={styles.toolTip}
                                content={headerLink.tooltip}
                                key={headerLink.tooltip}
                            >
                                <a href={headerLink.href} target="_blank" rel="noreferrer">
                                    <IconButton
                                        iconProps={{ iconName: headerLink.iconName }}
                                        className={styles.btn}
                                        ariaLabel={`${headerLink.tooltip} button`}
                                    />
                                </a>
                            </TooltipHost>
                        );
                        break;
                }
            }

            return result;
        },
        [dispatch, isMobile]);

    return (
        <div>
            {!!headerBannerText && <div className={styles.preHeaderBanner}>{headerBannerText}</div>}
            <div className={styles.header}>
                <div className={styles.logoContainer}>
                    {!isLoading && <Link to={AppRoutes.home}>
                        {navbarIcon ? (
                            <Image
                                height={30}
                                role="banner"
                                data-alt={LogoAltText}
                                aria-label={LogoAltText}
                                src={navbarIcon}
                            ></Image>
                        ) : (
                            <div
                                className={styles.logo}
                                role="banner"
                                data-alt={LogoAltText}
                                aria-label={LogoAltText}
                            ></div>
                        )}
                    </Link>}
                </div>
                <div className={styles.searchContainer}>
                    <SearchBox
                        placeholder="Search records"
                        className={styles.search}
                        onSearch={handleSearch}
                    />
                </div>
                <div className={styles.commandBarContainer}>
                    {(links || []).map((l, idx) => (
                        <div key={idx}>{renderHeaderLink(l)}</div>
                    ))}
                </div>
            </div>
            {showValidationErrors && errorText && <div className={styles.errorDialog + (isErrorMessageCollapsed ? " " + styles.collapsed : "")}>
                <h3>An error occurred!</h3>
                <p>Please contact the administrator.</p>
                <p>Details:</p>
                <pre>{errorText}</pre>
                {!isErrorMessageCollapsed && <button onClick={() => setIsErrorMessageCollapsed(true)}>Dismiss</button>}
            </div>}
        </div>
    );
}
