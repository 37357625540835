import React from "react";
import { DefaultButton } from "@fluentui/react";
import { useSelector } from "react-redux";
import { selectCurrentRecord } from "../manageRecordWrapperSlice";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import { IRecord } from "../../../services/Api/executor/IApiServiceExecutor";
import {
    notSpecifiedMessage,
    parliamentaryAskedAtFieldLabel,
    parliamentaryOnFieldLabel,
    parliamentaryQuestionAskedByFieldLabel,
    parliamentaryQuestionDirectedToFieldLabel,
    parliamentaryQuestionsRaisedHeaderLabel
} from "../../../providers/Constants/ManageRecordConstants";
import styles from "./ManageParliamPanel.module.scss";

export const ManageParliamPanel = (): JSX.Element => {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const readOnly: boolean = React.useMemo(
        () =>
            !currentRecord?.canCurrentUserEditRecord ||
            !!currentRecord?.canCurrentUserEditRecordLimitedAccess ||
            !!currentRecord?.noFurtherAction ||
            !!currentRecord?.withdrawn,
        [
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.canCurrentUserEditRecordLimitedAccess,
            currentRecord?.noFurtherAction,
            currentRecord?.withdrawn
        ]
    );

    const notSpecifiedElement: JSX.Element = React.useMemo((): JSX.Element => {
        return (
            <div className={[styles.fieldColumnValue, styles.notSpecifiedMessage].join(" ")}>
                {notSpecifiedMessage}
            </div>
        );
    }, []);

    return (
        <div className={styles.parliamManagePanelContainer}>
            <div className={styles.parliamManagePanelDataContainer}>
                {!readOnly && (
                    <DefaultButton
                        className={styles.submitButton}
                        iconProps={{ iconName: "Edit" }}
                        href={UrlHelper.castUrlWithQueryStringParam(
                            "EditRecordDetails",
                            URL_QUERY_KEYS.TAB
                        )}
                    >
                    </DefaultButton>
                )}
                <div className={styles.subjectContainer}>
                    <div className={styles.parliamInfoContainer}>
                        <div className={styles.fieldsParliamContainer}>
                            <div className={styles.fieldParliamContainer}>
                                <div className={styles.fieldColumnName}>
                                    {parliamentaryQuestionAskedByFieldLabel}
                                </div>
                            </div>
                            <div className={styles.fieldParliamContainer}>
                                <div className={styles.fieldColumnName}>
                                    {parliamentaryQuestionDirectedToFieldLabel}
                                </div>
                                {currentRecord?.decisionCategory &&
                                currentRecord?.decisionCategory?.length ? (
                                        <div className={styles.fieldColumnValue}>
                                            {currentRecord.decisionCategory[0].name}
                                        </div>
                                    ) : (
                                        notSpecifiedElement
                                    )}
                            </div>
                            <div className={styles.fieldParliamContainer}>
                                <div className={styles.fieldColumnName}>
                                    {parliamentaryAskedAtFieldLabel}
                                </div>
                            </div>
                            <div className={styles.fieldParliamContainer}>
                                <div className={styles.fieldColumnName}>
                                    {parliamentaryOnFieldLabel}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={[
                            styles.parliamInfoContainer,
                            styles.parliamQuestionRaisedContainer
                        ].join(" ")}
                    >
                        <div className={styles.headerQuestionRaised}>{parliamentaryQuestionsRaisedHeaderLabel}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
