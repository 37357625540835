import * as React from "react";
import {
    DefaultButton,
    PrimaryButton,
    Dialog,
    DialogFooter,
    DialogType,
    DialogContent,
    Stack,
    Image,
    Text,
    MessageBar,
    MessageBarType,
    Breadcrumb,
    IBreadcrumbItem,
    Shimmer,
    TextField,
    TooltipHost,
    Icon
} from "@fluentui/react";
import { FileList, Get } from "@microsoft/mgt-react";
import { getFileTypeIconUriByExtension } from "@microsoft/mgt-components/dist/es6/styles/fluent-icons";
import styles from "./SelectFileFromSPDialogButton.module.scss";
import { IDrive, IFile, ISite } from "./SelectFileFromSPInterfaces";


export interface IFileTemplateProps {
    template?: string;
    dataContext?: {
        files: IFile[];
    };
    files?: IFile[];
    selectedFolders: IFile[];
    selectedFiles: IFile[];
    setSelectedFolders: React.Dispatch<React.SetStateAction<IFile[]>>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
}



const FileTemplate = (props: IFileTemplateProps) => {
    //CacheService.config.fileLists.invalidationPeriod = 60000;

    return (
        <div>
            {(props.dataContext?.files || []).sort((a: IFile, b: IFile) => a.name.localeCompare(b.name)).map((file: IFile) => {
                const iconName: string = file.folder ? "folder" : file.name.slice(file.name.lastIndexOf(".") + 1) || "genericfile";
                const isSelectedFile: boolean = props.selectedFiles.some(f => f.id === file.id);
                return (
                    <Stack
                        className={[styles.siteItemContainer, isSelectedFile ? styles.selectedFile : ""].join(" ")}
                        onClick={() => {
                            if (file.folder) {
                                const newFolders: IFile[] = [...props.selectedFolders];
                                newFolders?.push(file);
                                props.setSelectedFolders(newFolders);
                            } else if (file.file) {
                                const idx: number = props.selectedFiles.findIndex(
                                    f => f.id === file.id
                                );
                                const newFiles: IFile[] = [...props.selectedFiles];
                                if (idx === -1) {
                                    newFiles?.push(file);
                                } else {
                                    newFiles.splice(idx, 1);
                                }
                                props.setSelectedFiles(newFiles);
                            }
                        }}
                        key={`${file.id}_siteItem`}
                        tokens={{ childrenGap: "m", padding: "s1" }}
                        horizontal
                    >
                        <Image src={getFileTypeIconUriByExtension(iconName, 48, "svg")} width={28} height={28} />
                        <Stack>
                            <Text className={styles.displayName}>{file.name}</Text>
                            {file.lastModifiedDateTime && (
                                <Text className={styles.modifiedText}>
                                    {`Modified ${new Date(file.lastModifiedDateTime).toLocaleDateString()}`}
                                </Text>
                            )}
                        </Stack>
                    </Stack>
                );
            })}
        </div>
    );
};

export interface ISiteListProps {
    setSelectedSite: React.Dispatch<React.SetStateAction<ISite | undefined>>;
    searchSitesString?: string;
    dataContext?: {
        value: ISite[];
    };
}

const SiteList = (props: ISiteListProps) => {
    if (!(props.dataContext?.value || []).length) {
        return (
            <MessageBar messageBarType={MessageBarType.info} isMultiline={false} {...{ template: "no-data" }}>
                No sites found
            </MessageBar>
        );
    }

    return (
        <div>
            {(props.dataContext?.value || []).filter(item => props.searchSitesString ? item.displayName?.toLocaleLowerCase()?.includes(props.searchSitesString?.toLocaleLowerCase()) : true).sort((a: ISite, b: ISite) => a.displayName.localeCompare(b.displayName)).map((site: ISite) => {
                return (
                    <Stack
                        className={styles.siteItemContainer}
                        onClick={() => props.setSelectedSite(site)}
                        key={`${site.id}_siteItem`}
                        tokens={{ childrenGap: "m", padding: "s1" }}
                        horizontal
                    >
                        <Image src={getFileTypeIconUriByExtension("spo", 48, "svg")} width={28} height={28} />
                        <Stack>
                            <Text className={styles.displayName}>{site.displayName}</Text>
                            {site.lastModifiedDateTime && (
                                <Text className={styles.modifiedText}>
                                    {`Modified ${new Date(site.lastModifiedDateTime).toLocaleDateString()}`}
                                </Text>
                            )}
                        </Stack>
                    </Stack>
                );
            })}
        </div>
    );
};

export interface IDocumentLibraryListProps {
    setSelectedDocumentLibrary: React.Dispatch<React.SetStateAction<IDrive | undefined>>;
    dataContext?: {
        value: IDrive[];
    };
}

const DocumentLibraryList = (props: IDocumentLibraryListProps) => {
    if (!(props.dataContext?.value || []).length) {
        return (
            <MessageBar messageBarType={MessageBarType.info} isMultiline={false} {...{ template: "no-data" }}>
                No document libraries found
            </MessageBar>
        );
    }
    
    return (
        <div>
            {(props.dataContext?.value || []).sort((a: IDrive, b: IDrive) => a.name.localeCompare(b.name)).map((documentLibrary: IDrive) => {
                return (
                    <Stack
                        className={styles.siteItemContainer}
                        onClick={() => props.setSelectedDocumentLibrary(documentLibrary)}
                        key={`${documentLibrary.id}_siteItem`}
                        tokens={{ childrenGap: "m", padding: "s1" }}
                        horizontal
                    >
                        <Image src={getFileTypeIconUriByExtension("archive", 48, "svg")} width={28} height={28} />
                        <Stack>
                            <Text className={styles.displayName}>{documentLibrary.name}</Text>
                            {documentLibrary.lastModifiedDateTime && (
                                <Text className={styles.modifiedText}>
                                    {`Modified ${new Date(documentLibrary.lastModifiedDateTime).toLocaleDateString()}`}
                                </Text>
                            )}
                        </Stack>
                    </Stack>
                );
            })}
        </div>
    );
};

export interface ISelectFileFromSPDialog {
    onSelectFiles?: (files: IFile[]) => void;
    onDismiss: () => void;
}

const selectChildrenPropList: string[] = ["id", "name", "file", "folder", "webUrl", "parentReference", "createdDateTime", "lastModifiedBy", "lastModifiedDateTime", "webDavUrl"];

export const SelectFilesFromSPDialog = (props: ISelectFileFromSPDialog): JSX.Element => {
    const [selectedSite, setSelectedSite] = React.useState<ISite | undefined>();
    const [selectedDocumentLibrary, setSelectedDocumentLibrary] = React.useState<IDrive | undefined>();
    const [selectedFolders, setSelectedFolders] = React.useState<IFile[]>([]);
    const [selectedFiles, setSelectedFiles] = React.useState<IFile[]>([]);
    const [searchSitesString, setSearchSitesString] = React.useState<string>();

    const breadcrumbItems: IBreadcrumbItem[] = React.useMemo<IBreadcrumbItem[]>(() => {
        const items: IBreadcrumbItem[] = [
            {
                key: "allSites",
                text: "All sites",
                onClick: () => {
                    setSelectedSite(undefined);
                    setSelectedDocumentLibrary(undefined);
                    setSelectedFolders([]);
                }
            }
        ];
        if (selectedSite) {
            items.push({
                key: `${selectedSite.id}_site`,
                text: selectedSite.displayName,
                onClick: () => {
                    setSelectedDocumentLibrary(undefined);
                    setSelectedFolders([]);
                }
            });
        }

        if (selectedDocumentLibrary) {
            items.push({
                key: `${selectedDocumentLibrary.id}_documentLibrary`,
                text: selectedDocumentLibrary.name,
                onClick: () => {
                    setSelectedFolders([]);
                }
            });
        }

        if (selectedFolders.length) {
            selectedFolders.forEach(folder =>
                items.push({
                    key: `${folder.id}_folder`,
                    text: folder.name,
                    onClick: () => {
                        const newSelectedFolders: IFile[] = selectedFolders.slice(0, selectedFolders.findIndex(f => f.id === folder.id) + 1);
                        setSelectedFolders(newSelectedFolders);
                    }
                })
            );
        }

        return items;
    }, [selectedSite, selectedDocumentLibrary, selectedFolders]);

    const siteListComponent: JSX.Element = React.useMemo<JSX.Element>(() => {
        return (
            <Stack tokens={{ childrenGap: "m" }}>
                <TextField
                    placeholder="Search for sites"
                    onChange={(_, newValue?: string) => setSearchSitesString(newValue)}
                    autoFocus={false}
                />
                <Get resource={"/sites?search=*"} templateContext={{searchSitesString}}>
                    <SiteList setSelectedSite={setSelectedSite} searchSitesString={searchSitesString} />
                    <Stack tokens={{ childrenGap: "m" }} {...{ template: "loading" }}>
                        <Shimmer />
                        <Shimmer />
                        <Shimmer />
                        <Shimmer />
                        <Shimmer />
                    </Stack>
                </Get>
            </Stack>
        );
    }, [searchSitesString]);

    const closeDialog: () => void = React.useCallback(() => {
        props.onDismiss();
        setSelectedSite(undefined);
        setSelectedDocumentLibrary(undefined);
        setSelectedFolders([]);
        setSearchSitesString("");
    }, [props]);
    
    return (
        <>
            <Dialog
                hidden={false}
                onDismiss={() => props.onDismiss()}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Select files from Sharepoint",
                    className: styles.dialogContainer
                }}
                maxWidth="100%"
                minWidth="500px"
            >
                <DialogContent
                    className={styles.modalTitle}
                    title={breadcrumbItems.length === 1 ? (
                        <Stack horizontal className={styles.breadcrumbContainer}>
                            <Text className={styles.allSitesText}>All sites</Text>
                            <TooltipHost content={"Tip: If you can't find your site, rather than searching for a partial part of the Site Name, try searching using for the full name of the Site. For example, to find the site \"Project A123, rather than searching \"A123\", search for \"Project A123\""}>
                                <Icon iconName="Info" className={styles.infoIcon} />
                            </TooltipHost>
                        </Stack>
                    ) : (
                        <Breadcrumb items={breadcrumbItems} />
                    )}
                >
                    <>
                        {!selectedSite && siteListComponent}
                        {selectedSite && !selectedDocumentLibrary && (
                            <Get resource={`/sites/${selectedSite.id}/drives`}>
                                <DocumentLibraryList setSelectedDocumentLibrary={setSelectedDocumentLibrary} />
                                <Stack tokens={{ childrenGap: "m" }} {...{ template: "loading" }}>
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                </Stack>
                            </Get>
                        )}
                        {selectedDocumentLibrary && (
                            <FileList
                                fileListQuery={!selectedFolders.length 
                                    ? `drives/${selectedDocumentLibrary.id}/root/children?$select=${selectChildrenPropList.join(",")}`
                                    : `drives/${selectedDocumentLibrary.id}/items/${selectedFolders[selectedFolders.length - 1].id}/children?$select=${selectChildrenPropList.join(",")}`}
                                pageSize={5000}
                            >
                                <FileTemplate
                                    template={"default" }
                                    selectedFolders={selectedFolders}
                                    selectedFiles={selectedFiles}
                                    setSelectedFolders={setSelectedFolders}
                                    setSelectedFiles={setSelectedFiles}
                                />
                                <Stack tokens={{ childrenGap: "m" }} {...{ template: "loading" }}>
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                    <Shimmer />
                                </Stack>
                                <MessageBar messageBarType={MessageBarType.info} isMultiline={false} {...{ template: "no-data" }}>
                                    No files found
                                </MessageBar>
                            </FileList>
                        )}
                    </>
                </DialogContent>
                <DialogFooter>
                    <PrimaryButton
                        disabled={!selectedFiles.length}
                        onClick={() => {
                            props.onDismiss();
                            if (props.onSelectFiles) {
                                props.onSelectFiles(selectedFiles);
                            }
                        }}
                        text="Select"
                    />
                    <DefaultButton onClick={closeDialog} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
