import { ITerm } from "../../services/Api/executor/IApiServiceExecutor";

// eslint-disable-next-line @typescript-eslint/typedef
export const actions = {
    recordDecision: "Record a decision",
    sendAllocation: "Send on for allocation",
    approveRequest: "Approve request"
};

export enum StageTypes {
    Initiate = "Initiate",
    PrepareAndReview = "Prepare and review",
    Recommend = "Recommend",

    MakeDecisionGeneral = "Make decision",

    ImplementAndClose = "Implement and close",
    Closed = "Closed",

    NoFurtherAction = "No further action",
    OnHold = "On hold",
    Withdrawn = "Withdrawn",
    Cancelled = "Cancelled",
    Superseded = "Superseded",
    ActionedByMcu = "Actioned by MCU"
}

export const emptyTerm: ITerm = {
    id: "00000000-0000-0000-0000-000000000000",
    name: null,
    terms: null
};

// eslint-disable-next-line @typescript-eslint/typedef
export const teams = {
    cabinet: "Cabinet"
};

export const CICSecurityClassificationNames: string[] = ["Cabinet in confidence","Cabinet-in-confidence"];