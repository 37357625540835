import * as React from "react";
import { IContact } from "../../../services/Api/executor/IApiServiceExecutor";
import styles from "./ContactContainer.module.scss";

interface IContactContainerProps {
    contact: IContact;
}


export function ContactContainer(props: IContactContainerProps): JSX.Element {
    if(!props.contact) return <></>;
    const { contact } = props;
    return (
        <div className={styles.contactContainer}>
            <div className={styles.normalText}>
                {contact.fullName}
            </div>
            <div className={styles.smallText}>
                {contact.jobTitle}
            </div>
            <div className={[styles.normalText, styles.row, styles.gap].join(" ")}>
                {contact.email ? (
                    <>
                        <div className={styles.title}>Email:</div>
                        <a className={styles.value} href={`mailto:${contact.email}`}>{contact.email}</a>
                    </>
                ): (
                    <span className={styles.value}>No email address provided</span>
                )}
            </div>
            <div className={[styles.normalText, styles.row].join(" ")}>
                <div className={styles.title}>Phone:</div>
                <div>{contact.workPhone}</div>
            </div>
            <div className={[styles.normalText, styles.gap].join(" ")}>
                {contact.address}
            </div>
            {contact.organization && (
                <div className={[styles.gap, styles.orgViewContainer].join(" ")}>
                    <div className={styles.headerTitle}>Organisation: </div>
                    <div>
                        <div className={styles.normalText}>
                            {contact.organization.fullName}
                        </div>
                        <div className={[styles.normalText, styles.row].join(" ")}>
                            {contact.organization.email ? (
                                <>
                                    <div className={styles.title}>Email:</div>
                                    <div>{contact.organization.email}</div>
                                </>
                            ): (
                                <span className={styles.value}>No email address provided</span>
                            )}
                        </div>
                        <div className={[styles.normalText, styles.row].join(" ")}>
                            <div className={styles.title}>Phone:</div>
                            <div>{contact.organization.workPhone}</div>
                        </div>
                        <div className={styles.normalText}>
                            {contact.organization.address}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
