import {
    AccountInfo
} from "@azure/msal-browser";
import IApiService from "./IApiService";
import IApiServiceExecutor, {
    ICreateRecordRequest,
    IDefaultKeyRoles,
    IDefaultKeyRolesRequest,
    IDeleteRecordDocumentRequest,
    IEditRecordDocumentRequest,
    IGeneratePdfPackRequest,
    IGetRecordsRequest,
    IIWantToOptions,
    IOrgs,
    IPdfPackResult,
    IRecord,
    IRecordActivities,
    IRecordDepartmentActivities,
    IRecordDocuments,
    IRecordResults,
    IReorderRecordDocumentRequest,
    ITemplate,
    ITermGroup,
    ITermSet,
    IUpdateActivityLogRequest,
    IUpdateDocumentSignaturesRequest,
    IUpdateRecordInformationRequest,
    IUpdateRecordKeyDatesRequest,
    IUpdateRecordKeyRolesRequest,
    IUpdateRecordRecommendations,
    IUpdateTaskRequest,
    IUploadRecordDocumentRequest,
    IUploadRecordSignedDocumentRequest,
    IUploadRecordTemplateRequest,
    IUsers,
    IGenerateExportPdfPackRequest,
    ICorrespondenceFile,
    IBaseFile,
    ICopyRecordRequest,
    IContact,
    IUserSettings,
    IProxyEmailTemplates,
    ISupersedeRecordRequest,
    IHeaderLink,
    IGenerateExportCsvPackRequest,
    IUserValidation,
    ContactSource,
    IBulkUpdateStateResponse,
    IStartBulkUpdateRequest,
    ICurrentClientContext,
    IUser,
    IFile,
    IGenerateOneNoteHtmlRequst,
    ITerm,
    IAppSettings,
    IContentTypeField,
    IContentType,
    ITimelineEntry,
    IDocumentSearchResponse,
    IOpenAIDocumentGenerationRequest,
    IOpenAIDocumentGenerationResponse,
    IWorkflowConfig
} from "./executor/IApiServiceExecutor";
import IAuthService from "../Auth/IAuthService";
import {
    termSet_AutoResponseTemplates,
    termSet_DecisionCategories,
    termSet_MediaCoverage,
    termSet_itemsForEvent,
    termSet_ReplyFormat,
    termSet_ReplyType,
    termSet_RequestFrom,
    termSet_SecurityRestricted,
    termSet_TeamRestrict,
    termSet_Timeframe,
    termSet_ParliamentarySource,
    termSet_RecommendationTypes,
    termSet_ParliamentMembers
} from "../../providers/Constants/AppConstants";
import ICacheProvider, {
    CacheTimeout
} from "../../providers/Cache/ICacheProvider";

export default class ApiService implements IApiService {
    private executor: IApiServiceExecutor;
    private cacheProvider: ICacheProvider;
    private authService: IAuthService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private pendingRequests: Map<string, Promise<any>> = new Map<string, Promise<any>>();

    constructor(
        executor: IApiServiceExecutor,
        cacheProvider: ICacheProvider,
        authService: IAuthService
    ) {
        this.executor = executor;
        this.cacheProvider = cacheProvider;
        this.authService = authService;
    }
    async SearchDocuments(search: string): Promise<IDocumentSearchResponse[] | null> {
        const result: IDocumentSearchResponse[] | null = await this.executor.SearchDocuments(
            search
        );
        return result;
    }
    async GenerateDocumentWithAI(
        request: IOpenAIDocumentGenerationRequest
    ): Promise<IOpenAIDocumentGenerationResponse | null> {
        const result: IOpenAIDocumentGenerationResponse | null =
            await this.executor.GenerateDocumentWithAI(request);
        return result;
    }

    public async GetWorkflowDiagram(recordType: string): Promise<string | null> {
        const response: string | null = await this.GetWithCaching(
            `ApiService.GetWorkflowDiagram.${recordType}`,
            () => this.executor.GetWorkflowDiagram(recordType),
            CacheTimeout.default
        );
        return response;
    }

    public async ClearCache(): Promise<boolean> {
        const result: boolean = await this.executor.ClearCache();
        return result;
    }

    public async GetWorkflowConfig(): Promise<string | null> {
        const config: string | null = await this.executor.GetWorkflowConfig();
        return config;
    }

    public async SetWorkflowConfig(workflowConfig: IWorkflowConfig): Promise<boolean> {
        const result: boolean | null = await this.executor.SetWorkflowConfig(workflowConfig);
        return result;
    }

    public async GetContentTypeFields(contentTypeName: string): Promise<IContentTypeField[] | null> {
        const response: IContentTypeField[] | null = await this.GetWithCaching(
            `ApiService.GetContentTypeFields.${contentTypeName}`,
            () => this.executor.GetContentTypeFields(contentTypeName),
            CacheTimeout.long
        );
        return response;
    }

    public async GetContentTypesFieldsSchema(): Promise<string> {
        const schema: string = await this.executor.GetContentTypesFieldsSchema();
        return schema;
    }

    public async GetContentTypes(): Promise<IContentType[] | null> {
        const response: IContentType[] | null = await this.GetWithCaching(
            "ApiService.GetContentTypes",
            () => this.executor.GetContentTypes(),
            CacheTimeout.long
        );
        return response;
    }

    public async GetContentTypesSchema(): Promise<string> {
        const data: string = await this.executor.GetContentTypesSchema();
        return data;
    }

    GetCurrentUser(): Promise<IUser | null> {
        throw new Error("Method not implemented.");
    }

    // UpdateSettings(settings: IUserSettings) {
    //     throw new Error("Method not implemented.");
    // }

    public async CreateRecord(createRecordRequest: ICreateRecordRequest): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.CreateRecord(createRecordRequest);
        return record;
    }

    public async CopyRecord(copyRecordRequest: ICopyRecordRequest): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.CopyRecord(copyRecordRequest);
        return record;
    }

    public async SupersedeRecord(supersedeRecordRequest: ISupersedeRecordRequest): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.SupersedeRecord(supersedeRecordRequest);
        return record;
    }

    public async UploadRecordDocument(
        uploadRecordDocumentRequest: IUploadRecordDocumentRequest
    ): Promise<string | null> {
        const response: string | null = await this.executor.UploadRecordDocument(
            uploadRecordDocumentRequest
        );
        return response;
    }

    public async UploadRecordSignedDocument(
        uploadRecordSignedDocumentRequest: IUploadRecordSignedDocumentRequest
    ): Promise<IRecordDocuments | null> {
        const response: IRecordDocuments | null = await this.executor.UploadRecordSignedDocument(
            uploadRecordSignedDocumentRequest
        );
        return response;
    }

    public async UpdateCreateContact(contact: IContact): Promise<IContact | null> {
        const response: IContact | null = await this.executor.UpdateCreateContact(
            contact
        );
        return response;
    }

    public async UploadRecordTemplate(
        uploadRecordTemplateRequest: IUploadRecordTemplateRequest
    ): Promise<boolean> {
        const response: boolean = await this.executor.UploadRecordTemplate(
            uploadRecordTemplateRequest
        );
        return response;
    }

    public async UploadFileToPersonalStorage(
        request: IFile
    ): Promise<IBaseFile|null>{
        const response: IBaseFile | null = await this.executor.UploadFileToPersonalStorage(
            request
        );
        return response;
    }
    
    public async UploadAndParseCorrespondenceFile(
        request: IFile
    ):Promise<ICorrespondenceFile | null>{
        const response: ICorrespondenceFile | null = await this.executor.UploadAndParseCorrespondenceFile(
            request
        );
        return response;
    }

    public async DeleteRecordDocument(
        deleteRecordDocumentRequest: IDeleteRecordDocumentRequest
    ): Promise<boolean> {
        const response: boolean = await this.executor.DeleteRecordDocument(
            deleteRecordDocumentRequest
        );
        return response;
    }

    public async DeleteFileFromPersonalStorage(
        deleteRequest: IBaseFile
    ): Promise<boolean>{
        const response: boolean = await this.executor.DeleteFileFromPersonalStorage(
            deleteRequest
        );
        return response;
    }

    public async EditRecordDocument(
        editRecordDocumentRequest: IEditRecordDocumentRequest
    ): Promise<boolean> {
        const response: boolean = await this.executor.EditRecordDocument(editRecordDocumentRequest);
        return response;
    }

    public async ReorderRecordDocument(
        reorderRecordDocumentRequest: IReorderRecordDocumentRequest
    ): Promise<boolean> {
        const response: boolean = await this.executor.ReorderRecordDocument(
            reorderRecordDocumentRequest
        );
        return response;
    }

    public async UpdateDocumentSignatures(
        updateDocumentSignatures: IUpdateDocumentSignaturesRequest
    ): Promise<boolean> {
        const result: boolean = await this.executor.UpdateDocumentSignatures(
            updateDocumentSignatures
        );
        return result;
    }

    public async UpdateRecordKeyRoles(
        updateRecordKeyRolesRequest: IUpdateRecordKeyRolesRequest
    ): Promise<IRecord | null> {
        updateRecordKeyRolesRequest.rolesOnlyUpdate = true;
        const record: IRecord | null = await this.executor.UpdateRecord(
            updateRecordKeyRolesRequest
        );
        return record;
    }

    public async UpdateRecordKeyDates(
        updateRecordKeyDatesRequest: IUpdateRecordKeyDatesRequest
    ): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.UpdateRecord(
            updateRecordKeyDatesRequest
        );
        return record;
    }

    public async UpdateRecordIWantToAction(
        updateRecordIWantToActionRequest: IUpdateTaskRequest
    ): Promise<IRecord | null> {
        let updatedRecordByRecommends: IRecord | null = null;
        if (updateRecordIWantToActionRequest.recommendations) {
            updatedRecordByRecommends = await this.executor.UpdateRecord({
                recordId: updateRecordIWantToActionRequest.recordId,
                recommendations: updateRecordIWantToActionRequest.recommendations
            } as IUpdateRecordRecommendations);
        }
        const record: IRecord | null = await this.executor.UpdateTask(
            updateRecordIWantToActionRequest
        );
        return record ? {...record, recommendations: updatedRecordByRecommends ? updatedRecordByRecommends.recommendations : record?.recommendations} : null;
    }

    public async UpdateActivityLog(
        updateActivityLogRequest: IUpdateActivityLogRequest
    ): Promise<boolean | null> {
        const response: boolean | null = await this.executor.UpdateActivityLog(
            updateActivityLogRequest
        );
        return response;
    }

    public async UpdateRecordInformation(
        updateRecordInformationRequest: IUpdateRecordInformationRequest
    ): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.UpdateRecord(
            updateRecordInformationRequest
        );
        return record;
    }

    public async UpdateRecordRecommendations(
        updateRecordRecommendationsRequest: IUpdateRecordRecommendations
    ): Promise<IRecord | null> {
        const record: IRecord | null = await this.executor.UpdateRecord(
            updateRecordRecommendationsRequest
        );
        return record;
    }

    public async ValidateUserAccess(): Promise<IUserValidation | null> {
        const response: IUserValidation | null = await this.GetWithCaching(
            "ApiService.ValidateUserAccess",
            () => this.executor.ValidateUserAccess(),
            CacheTimeout.short
        );
        return response;
    }

    public async GetCurrentContext(): Promise<ICurrentClientContext | null> {
        const response: ICurrentClientContext | null = await this.GetWithCaching(
            "ApiService.GetCurrentContext",
            () => this.executor.GetCurrentContext(),
            CacheTimeout.default
        );
        return response;
    }

    public async GetHeaderLinks(): Promise<IHeaderLink[] | null> {
        const response: IHeaderLink[] | null = await this.GetWithCaching(
            "ApiService.GetHeaderLinks",
            () => this.executor.GetHeaderLinks(),
            CacheTimeout.default
        );
        return response;
    }

    public async GetUsers(searchTerm: string): Promise<IUsers | null> {
        const response: IUsers | null = await this.GetWithCaching(
            `ApiService.GetUsers.${searchTerm}`,
            () => this.executor.GetUsers(searchTerm),
            CacheTimeout.short
        );
        return response;
    }

    public async GetTimelineEntries(recordId?: string): Promise<ITimelineEntry[] | null> {
        if (!recordId) {
            return null;
        } else {
            const result: ITimelineEntry[] | null = await this.executor.GetTimelineEntries(recordId);
            return result;
        }
    }

    public async GetRecord(recordId?: string, includeTimelineAndMenu?: boolean): Promise<IRecord | null> {
        if (!recordId) {
            return null;
        } else {
            const record: IRecord | null = await this.executor.GetRecord(recordId, includeTimelineAndMenu || false);
            return record;
        }
    }

    public async GetOrgHierarchy(): Promise<IOrgs | null> {
        const response: IOrgs | null = await this.GetWithCaching(
            "ApiService.GetOrgHierarchy",
            () => this.executor.GetOrgHierarchy(),
            CacheTimeout.default
        );
        return response;
    }

    public async GetRecords(request: IGetRecordsRequest): Promise<IRecordResults | null> {
        // no value in caching beyond preventing flooding of the api which it can handle anyway
        const response: IRecordResults | null = await this.executor.GetRecords(request);
        return response;
    }

    public async GetDefaultKeyRoles(
        defaultKeyRolesRequest: IDefaultKeyRolesRequest
    ): Promise<IDefaultKeyRoles | null> {
        const availableTemplates: IDefaultKeyRoles | null = await this.executor.GetDefaultKeyRoles(
            defaultKeyRolesRequest
        );
        return availableTemplates;
    }

    public async GetAvailableTemplates(recordSubType: string): Promise<ITemplate[] | null> {
        const response: ITemplate[] | null = await this.GetWithCaching(
            `ApiService.GetAvailableTemplates.${recordSubType}`,
            () => this.executor.GetAvailableTemplates(recordSubType),
            CacheTimeout.default
        );
        return response;
    }

    public async GetAvailableRecordFlags(recordSubType: string): Promise<ITerm[] | null> {
        const response: ITerm[] | null = await this.GetWithCaching(
            `ApiService.GetAvailableRecordFlags.${recordSubType}`,
            () => this.executor.GetAvailableRecordFlags(recordSubType),
            CacheTimeout.default
        );
        return response;
    }

    public async GetRecordDocuments(
        recordId: string,
        recordName: string
    ): Promise<IRecordDocuments | null> {
        if (!recordId) {
            return null;
        } else {
            const response: IRecordDocuments | null = await this.executor.GetRecordDocuments(
                recordId,
                recordName
            );
            return response;
        }
    }

    public async GetContacts(query: string, types?: ContactSource[], top?: number): Promise<IContact[]>{
        const response: IContact[] = await this.executor.GetContacts(query, types, top || 10);
        return response;
    }

    public async GeneratePdfPack(
        pdfPackRequest: IGeneratePdfPackRequest
    ): Promise<IPdfPackResult | null> {
        if (!pdfPackRequest.recordId) {
            return null;
        } else {
            const response: IPdfPackResult | null = await this.executor.GeneratePdfPack(
                pdfPackRequest
            );
            return response;
        }
    }

    public async GetWatermarkPdf(fileRef: string): Promise<string | null> {
        if (!fileRef) {
            return null;
        } else {
            const response: string | null = await this.executor.GenerateWatermarkedPDF(fileRef);
            return response;
        }
    }

    public async GenerateExportPdfPack(pdfPackRequest: IGenerateExportPdfPackRequest): Promise<string | null> {
        if (!pdfPackRequest) {
            return null;
        } else {
            const response: string | null = await this.executor.GenerateExportPdfPack(
                pdfPackRequest
            );
            return response;
        }
    }

    public async GenerateOneNoteHtml(generateOneNoteHtmlRequst: IGenerateOneNoteHtmlRequst): Promise<string | null> {
        if (!generateOneNoteHtmlRequst.recordId) {
            return null;
        } else {
            const response: string | null = await this.executor.GenerateOneNoteHtml(
                generateOneNoteHtmlRequst
            );
            return response;
        }
    }

    public async GenerateExportCsvPack(csvPackRequest: IGenerateExportCsvPackRequest): Promise<IBaseFile | null> {
        const response: IBaseFile | null = await this.executor.GenerateExportCsvPack(
            csvPackRequest
        );
        return response;
    }

    public async GetRecordDepartmentActivity(
        recordId: string
    ): Promise<IRecordDepartmentActivities | null> {
        if (!recordId) {
            return null;
        } else {
            const response: IRecordDepartmentActivities | null = await this.executor.GetRecordDepartmentActivity(
                recordId
            );
            return response;
        }
    }

    public async GetIWantToOptions(recordId?: string): Promise<IIWantToOptions | null> {
        if (!recordId) {
            return null;
        } else {
            const response: IIWantToOptions | null = await this.executor.GetIWantToOptions(
                recordId
            );
            return response;
        }
    }

    public async GetRecordActivityLog(recordId: string): Promise<IRecordActivities | null> {
        if (!recordId) {
            return null;
        } else {
            const response: IRecordActivities | null = await this.executor.GetRecordActivityLog(
                recordId
            );
            return response;
        }
    }

    public async GetRecordActivityCsv(
        recordId: string
    ): Promise<IBaseFile | null> {
        const response: IBaseFile | null = await this.executor.GetRecordActivityCsv(
            recordId
        );
        return response;
    }

    public async GetReplyTypesTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_ReplyType);
        }
        return termSet;
    }

    public async GetReplyFormatsTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_ReplyFormat);
        }
        return termSet;
    }

    public async GetAutoResponseTemplatesTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_AutoResponseTemplates);
        }
        return termSet;
    }

    public async GetDecisionCategoryTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_DecisionCategories);
        }
        return termSet;
    }

    public async GetTeamRestrictTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_TeamRestrict);
        }
        return termSet;
    }

    public async GetSecurityClassificationTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_SecurityRestricted);
        }
        return termSet;
    }

    public async GetRequestFromTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_RequestFrom);
        }
        return termSet;
    }

    public async GetTimeframeTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_Timeframe);
        }
        return termSet;
    }

    public async GetItemsForEventTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_itemsForEvent);
        }
        return termSet;
    }

    public async GetMediaCoverageTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_MediaCoverage);
        }
        return termSet;
    }

    public async GetParliamentarySourceTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_ParliamentarySource);
        }
        return termSet;
    }

    public async GetRecommendationTypesTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_RecommendationTypes);
        }
        return termSet;
    }
    
    public async GetParliamentMembersTermSet(): Promise<ITermSet | null> {
        let termSet: ITermSet | null = null;
        const termGroup: ITermGroup | null = await this.GetAllTermSets();
        if (termGroup) {
            termSet = this.GetTermSet(termGroup, termSet_ParliamentMembers);
        }
        return termSet;
    }

    public async GetApiBaseUrl(): Promise<string> {
        return await this.executor.GetApiBaseUrl();
    }

    public async GetAllTermSets(): Promise<ITermGroup | null> {
        const response: ITermGroup | null = await this.GetWithCaching(
            "ApiService.GetAllTermSets",
            () => this.executor.GetAllTermSets(),
            CacheTimeout.default
        );
        return response;
    }

    private GetTermSet(termGroup: ITermGroup, termSetName: string): ITermSet | null {
        const termSets: ITermSet[] = termGroup?.termSets || [];
        for (let i: number = 0; i < termSets.length; i++) {
            const termSet: ITermSet = termSets[i];
            if (termSet.name === termSetName) {
                return termSet;
            }
        }
        return null;
    }

    private async GetWithCaching<T>(
        cacheKeyPrefix: string,
        getter: () => Promise<T>,
        timeout: CacheTimeout
    ): Promise<T | null> {
        let result: T | null = null;
        const account: AccountInfo | null = await this.authService.SignIn();
        const cacheKey: string | null = this.GetCacheKey(cacheKeyPrefix, account);
    
        if (!cacheKey) {
            // Return early if no cache key is available (e.g., user isn't logged in)
            return await getter();
        }
    
        // Check if there's already a pending request for this cache key
        if (this.pendingRequests.has(cacheKey)) {
            // Wait for the existing promise to resolve
            return await this.pendingRequests.get(cacheKey)!;
        }
    
        // Start a new request and store it in the pending requests map
        const requestPromise: Promise<T> = (async () => {
            try {
                result = await this.cacheProvider.Get<T>(cacheKey);
                if (this.ValueIsEmpty(result)) {
                    result = await getter();
                    if (!this.ValueIsEmpty(result)) {
                        await this.cacheProvider.Set(cacheKey, result, timeout);
                    }
                }
                return result;
            } finally {
                // Remove the promise from the map once it resolves or rejects
                this.pendingRequests.delete(cacheKey);
            }
        })();
    
        // Store the promise in the pending requests map and await its result
        this.pendingRequests.set(cacheKey, requestPromise);
        return await requestPromise;
    }

    private ValueIsEmpty<T>(value: T): boolean {
        const result: boolean = value === null 
        || value === undefined 
        || (Array.isArray(value) && !value.length);
        return result;
    }

    private GetCacheKey(prefix: string, account: AccountInfo | null): string | null {
        let cacheKey: string | null = null;

        // return a null cache key if uset isn't logged in
        if (account) {
            cacheKey = `${prefix}.${account.username}`;
        }
        return cacheKey;
    }

    public async UpdateUserSettings(
        settings: IUserSettings
    ): Promise<boolean> {
        let retVal: boolean = false;
        retVal = await this.executor.UpdateUserSettings(
            settings
        );
        return retVal;
    }

    public async GetUserSettings(
        key: string
    ): Promise<IUserSettings|null> {        
        let retVal: IUserSettings|null = null;
        retVal = await this.executor.GetUserSettings(
            key
        );
        return retVal;
    }

    public async GetProxyEmailTemplates(): Promise<IProxyEmailTemplates|null> {
        let retVal: IProxyEmailTemplates|null = null;
        retVal = await this.executor.GetProxyEmailTemplates();
        return retVal;
    }
    
    public async GetUserPhoto(userPrincipal: string): Promise<string | null> {
        const response: string | null = await this.GetWithCaching(
            `ApiService.GetUserPhoto.${userPrincipal}`,
            () => this.executor.GetUserPhoto(userPrincipal),
            CacheTimeout.long
        );
        return response;
    }

    public async GetPublicHolidays(): Promise<Date[] | null> {
        const response: Date[] | null = await this.GetWithCaching(
            "ApiService.GetPublicHolidays",
            () => this.executor.GetPublicHolidays(),
            CacheTimeout.default
        );
        
        return response?.map(r => new Date(r)) || null;
    }

    public async GetBulkUpdateState(): Promise<IBulkUpdateStateResponse | null> {
        const response: IBulkUpdateStateResponse | null = await this.executor.GetBulkUpdateState();
        return response;
    }

    public async StartBulkUpdate(model: IStartBulkUpdateRequest): Promise<IBulkUpdateStateResponse | null>{
        const response: IBulkUpdateStateResponse | null = await this.executor.StartBulkUpdate(model);
        return response;
    }

    public async GetAppSettings(): Promise<IAppSettings|null> {        
        let retVal: IAppSettings|null = null;
        retVal = await this.executor.GetAppSettings();
        return retVal;
    }

    public async UpdateAppSettings(
        appSettings: IAppSettings
    ): Promise<boolean | string> {
        let retVal: boolean | string = false;
        if (appSettings.favicon || appSettings.primaryIcon || appSettings.navbarIcon) {
            const account: AccountInfo | null = await this.authService.SignIn();
            const key: string | null = this.GetCacheKey("ApiService.GetCurrentContext", account);
            if (key) {
                this.cacheProvider.Clear(key);
            }
        }
        retVal = await this.executor.UpdateAppSettings(
            appSettings
        );
        return retVal;
    }
}
