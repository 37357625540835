import * as React from "react";
import {
    DefaultButton,
    IconButton,
    Modal,
    PrimaryButton,
    Separator
} from "@fluentui/react";
import styles from "./ModalContainer.module.scss";

export interface IModalContainerProps {
    isOpen: boolean;
    title: string;
    description?:string;
    inProgress?: boolean;
    submitLabel?: string;
    cancelLabel?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (data?: any) => Promise<void | any> | void;
    onCancel: () => void;
    children?: JSX.Element;
    submitDisabled?: boolean;
    className?: string;
    hideFooter?:boolean;
}

export default function ModalContainer(props: IModalContainerProps): JSX.Element {
    return (
        <Modal
            isOpen={props.isOpen}
            containerClassName={[styles.container, props.className ? props.className : ""].join(" ")}
            allowTouchBodyScroll
            styles={{
                scrollableContent: {
                    maxHeight: "2000px"
                }
            }}
        >
            <div className={`${styles.header} ${styles.sectionPadding}`}>
                <h3 className={styles.title}>{props.title}</h3>                
                <IconButton
                    className={styles.closeBtn}
                    iconProps={{ iconName: "Cancel" }}
                    onClick={props.onCancel}
                    ariaLabel={"Cancel button"}
                />
            </div>
            {props.description && <div className={styles.sectionPadding}>{props.description}</div>}
            <div className={`${styles.body} ${styles.sectionPadding}`}>{props.children}</div>
            {!props.hideFooter && 
                <>
                    <Separator />
                    <div className={`${styles.footer} ${styles.sectionPadding}`}>
                        <PrimaryButton
                            className={styles.submitButton}
                            onClick={props.onSubmit}
                            disabled={props.submitDisabled}
                            ariaLabel={`${props.submitLabel || "Submit"} button`}
                        >
                            {props.submitLabel || "Submit"}
                        </PrimaryButton>
                        <DefaultButton onClick={props.onCancel} disabled={props.inProgress} ariaLabel={`${props.cancelLabel || "Cancel"} button`}>
                            {props.cancelLabel || "Cancel"}
                        </DefaultButton>
                    </div>
                </>
            }
        </Modal>
    );
}
