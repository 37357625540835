// // Header
export const batchRecordHeaderLabel: string = "Original correspondence";

// // Labels
export const fromCorrespondentsLabel: string = "Sender";
export const toCorrespondentsLabel: string = "Recipient";
export const successMessageBarLabel: string = "Sender or Recipient found in database";
export const warningMessageBarLabel: string = "Sender or Recipient’s profile is missing mandatory information, click ‘Edit’ to update.";
export const warningMessageBarNoUserLabel: string = "Add Sender and Recipient by searching for the person/organisation and selecting them. If not found, click ‘Add new’.";

// // Buttons
export const viewMessageButtonLabel: string = "View correspondence";
export const editButtonLabel: string = "Edit";
export const deleteButtonLabel: string = "Delete";
export const saveButtonLabel: string = "Save";
export const backButtonLabel: string = "Back";

export const hideFullDetailsButtonLabel: string = "Hide full details";
export const showFullDetailsButtonLabel: string = "Show full details";

export const addNewContactButtonLabel: string = "Add new";
export const addNewOrgButtonLabel: string = "Add new organisation";

export const editCorrespondentButtonLabel: string = "Edit correspondent";
export const editOrgButtonLabel: string = "Edit organisation";

export const saveSenderContactButtonLabel: string = "Confirm Sender";
export const saveRecipientButtonLabel: string = "Confirm Recipient";
export const saveNewCorrespondentButtonLabel: string = "Save";
export const saveNewOrgButtonLabel: string = "Save";

export const saveEditContactButtonLabel: string = "Save";
export const saveFormContactButtonLabel: string = "Save";

export const cancelButtonLabel: string = "Cancel";

export const viewPreviousRecordsButtonLabel: string = "View previous records";

export const canNotModifiedButtonLabel: string = "Internal contact can't be modified";

// // Fields 
export const subjectFieldLabel: string = "Subject of correspondence";
export const dateWrittenFieldLabel: string = "Date of correspondence";

// export const searchContactFieldLabel: string = "Search Correspondent or Organisation";
export const searchSenderFieldLabel: string = "Search person or organisation";
export const searchReceiverFieldLabel: string = "Search person or organisation";
export const searchCorrespondentOrgFieldLabel: string = "Organisation";

// // Contact Form
export const newContactFormHeaderLabel: string = "Add new person or organisation";
export const editCorrespondentFormHeaderLabel: string = "Edit correspondent";
export const editOrgFormHeaderLabel: string = "Edit organisation";

// // Correspondent Form
export const correspondentFullNameFieldLabel: string = "Full name";
export const correspondentJobTitleFieldLabel: string = "Job title";
export const correspondentOrganisationFieldLabel: string = "Organisation";

export const correspondentEmailFieldLabel: string = "Email";
export const correspondentPhoneNumberFieldLabel: string = "Phone number";

export const correspondentAddressFieldLabel: string = "Address";

// // Organisation Form
export const organisationFullNameFieldLabel: string = "Organisation name";

export const organisationEmailFieldLabel: string = "Organisation email";
export const organisationPhoneNumberFieldLabel: string = "Organisation phone number";

export const organisationAddressFieldLabel: string = "Organisation address";