import * as React from "react";
import {
    HoverCard,
    HoverCardType,
    Icon,
    IPlainCardProps,
    KeyCodes,
    Persona,
    PersonaSize
} from "@fluentui/react";
import { ISimpleUser, IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import { ImageUrlText, PersonaComponentAltText } from "../../../providers/Constants/FormControlConstants";

import styles from "./PersonHoverCard.module.scss";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../../services/Api/IApiService";

export interface IPersonHoverCardProps {
    person: IUser | ISimpleUser;
    className?: string;
}

export default function PersonHoverCard(props: IPersonHoverCardProps): JSX.Element {
    const [userPhoto, setUserPhoto] = React.useState<string>("");

    const getUserPhoto: () => Promise<string | null> = React.useCallback(async (): Promise<string | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        
        const result: string | null = await apiService.GetUserPhoto(props.person.userPrincipalName ? props.person.userPrincipalName : "");
        return result;
    }, [props.person.userPrincipalName]);
    
    let isYetToFetchPhoto: boolean = true;

    const onRenderPlainCard = (): JSX.Element => {
        // only fetch photo on load (check bool to prevent many requests on rerender of component)
        if (!userPhoto && isYetToFetchPhoto) {
            isYetToFetchPhoto = false;
            getUserPhoto().then((photo: string | null) => {
                setUserPhoto(photo ? photo : "");
            });
        }

        return (
            <div className={styles.personHoverCardItem}>
                <div className={styles.personInfoContainer}>
                    <Persona
                        imageUrl={`${ImageUrlText}, ${userPhoto}`}
                        size={PersonaSize.size40}
                        text={props.person.displayName || undefined}
                        secondaryText={props.person.jobTitle || undefined}
                        data-alt={`${PersonaComponentAltText} ${props.person.displayName || ""}`}
                    />
                </div>
                <div className={styles.divider}></div>
                <div className={styles.personCommunicationInfoContainer}>
                    {props.person.mail && (
                        <div className={styles.personCommunication}>
                            <Icon iconName={"Mail"} ariaLabel={"Mail icon"}/>
                            <a href="">{props.person.mail}</a>
                        </div>
                    )}
                    {/* there is no phone property for the user */}
                    {/* {props.person.phone && (
                        <div className={styles.personCommunication}>
                            <Icon iconName={"Phone"} />
                            <a href="">{props.person.phone}</a>
                        </div>
                    )} */}
                </div>
            </div>
        );
    };
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderPlainCard
    };
    return (
        <HoverCard
            type={HoverCardType.plain}
            openHotKey={KeyCodes.enter}
            plainCardProps={plainCardProps}
            className={styles.hoverPersonCardContainer}
        >
            <span className={props.className ? props.className : ""}>
                {props.person.displayName}
            </span>
        </HoverCard>
    );
}
