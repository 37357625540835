// 1. IMPORTS
import IApiService from "../../services/Api/IApiService";
import {
    IUserSettings
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    AppThunk,
} from "../../app/store";

// 7. ASYNCRONOUS ACTIONS
export const updateUserSettingsAsync = (
    payload: IUserSettings,
    callback?: { onSuccess: () => void; onError: () => void }
): AppThunk => async ()  => {
    try {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        // Update the user settings in table storage
        await apiService.UpdateUserSettings(payload);   
        callback && callback.onSuccess && callback.onSuccess();
    } catch (e) {
        e && callback && callback.onError && callback.onError();
    }
};