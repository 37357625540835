export class DialogHelper {
    public static getInitials(title?: string | null): string {
        if (title) {
            const temp: string[] = title.split(/\s+/);
            let initials: string = "";
            temp.forEach(element => {
                initials += element.substr(0, 1);
            });
            return initials;
        } else {
            return "";
        }
    }

    public static getRandomInitialColour(): string {
        const colours: string[] = ["#0B6A0B", "#4F6BED", "#038387"];
        return colours[Math.floor(Math.random() * colours.length)];
    }

    public static checkIfExternalEmail(email: string): boolean {
        const regexp: RegExp = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return regexp.test(email);
    }
}
