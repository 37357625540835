/* eslint-disable @typescript-eslint/no-unused-vars */
// Importing required components
import React, {
    useRef,
    useState
} from "react";
import { DefaultButton, Icon, IContextualMenuItem, Stack, Text } from "@fluentui/react";
import styles from "./FileUpload.module.scss";
import FileList from "../FileList/FileList";
import {
    FormMode,
    IFile
} from "../../../services/Api/executor/IApiServiceExecutor";
import StringHelper, { reservedCharsForFileName } from "../../../helpers/StringHelper";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { SelectFilesFromSPDialog } from "./SelectFilesFromSPDialog";
import { IFile as IGraphFile } from "./SelectFileFromSPInterfaces";

// File upload component properties
interface IFileUploadProps {
    selectedFilesList: IFile[];
    onFileSelected: (addFile: IFile[], updateFile?: IFile) => void;
    onFileRemove: (delFile: IFile) => void;
    onFileEdit: (editFile: string[]) => void;
    onFileMoved: (moveFile: IFile, movement: number) => void;
    onLoading: boolean;
    onLoadingText: string;
    componentMode: FormMode;
    templateMode?: boolean;
    errorMessages?: string[];
    isSuccess?: boolean;
    selectFileButtonText?: string;
    canAdd: boolean;
    canEdit: boolean;
    canRemove: boolean;
    canUploadSigned: boolean;
    filterByUserUPN?: string;
    filterByUserEmail?: string;
    disableSelectFromSP?: boolean;
}

// File upload component
// Has a button to upload file
// Has a hidden input type="file" control for the actual file upload
// Has the custom file list component which displays the selected files.
export default function FileUpload(props: IFileUploadProps): JSX.Element {
    const [validationErrorMessages, setValidationErrorMessages] = React.useState<string[] | undefined>(undefined);
    const inputFileUpload: React.LegacyRef<HTMLInputElement> = useRef(null);
    const [updateFile, setUpdateFile] = useState<IFile | undefined>(undefined);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [isSelectFilesFromSPDialogOpen, setIsSelectFilesFromSPDialogOpen] = React.useState<boolean>(false);

    const isMulti: boolean = !updateFile;
    const menuItems: IContextualMenuItem[] = React.useMemo(() => {
        const items: IContextualMenuItem[] = [
            {
                key: "uploadFile",
                text: "Upload file",
                iconProps: { iconName: "BulkUpload" },
                onClick: () => {
                    setUpdateFile(undefined);
                    inputFileUpload.current?.click();
                }
            },
        ];
        if (!props.disableSelectFromSP) {
            items.push({
                key: "fileFromSharepoint",
                text: "Select files from Sharepoint",
                iconProps: { iconName: "SharepointLogo" },
                onClick: () => setIsSelectFilesFromSPDialogOpen(true)
            });
        }
        return items;
    }, [props]);
    return (
        <div className={styles.fileUploadContainer}>
            {validationErrorMessages && validationErrorMessages.map( (msg, idx) => 
                <MessageBar
                    key={idx}
                    className={styles.messageBar}
                    messageBarType={MessageBarType.blocked}
                    isMultiline={false}
                    onDismiss={() => setValidationErrorMessages(validationErrorMessages.splice(idx, 1))}
                    dismissButtonAriaLabel="Close"
                    overflowButtonAriaLabel="See more"
                >{msg}</MessageBar>
            )}
            {props.isSuccess && !!successMessage && !props.templateMode &&
                <MessageBar
                    className={styles.messageBar}
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => setSuccessMessage("")}
                    dismissButtonAriaLabel="Close"
                    overflowButtonAriaLabel="See more"
                >{successMessage}</MessageBar>
            }
            {!props.templateMode && !!props.canAdd &&(
                <>
                    <DefaultButton
                        text={props.selectFileButtonText || "Select file"}
                        onClick={() => {
                            setUpdateFile(undefined);
                            inputFileUpload.current?.click();
                        }}
                        allowDisabledFocus
                        disabled={false}
                        className={styles.buttonStyling}
                        menuProps={{items: menuItems}}
                    
                    />
                    {isSelectFilesFromSPDialogOpen && <SelectFilesFromSPDialog
                        onDismiss={() => setIsSelectFilesFromSPDialogOpen(false)}
                        onSelectFiles={(files: IGraphFile[]) => {
                            if (files.length) {
                                const errors: string[] = [];
                                const filesToUpload: IFile[] = [];
                                for(const file of files){
                                    const uploadedFilename: string = file.name;
                                    if (!StringHelper.hasReservedChars(uploadedFilename, reservedCharsForFileName)) {
                                        // If we are uploading a signed document, we can upload a file with the same name. 
                                        // If we are uploading a non signed document, it must have a different name to 
                                        // those already uploaded.
                                        if(updateFile){
                                            filesToUpload.push({
                                                name: uploadedFilename,
                                                driveId: file.parentReference.driveId,
                                                itemId: file.id,
                                                path: file.webDavUrl
                                            });
                                        } else {
                                            if(!props.selectedFilesList?.some(t => t.name === uploadedFilename)) {
                                                filesToUpload.push({
                                                    name: uploadedFilename,
                                                    driveId: file.parentReference.driveId,
                                                    itemId: file.id,
                                                    path: file.webDavUrl
                                                });
                                            } else {
                                                errors.push(`File with the name "${uploadedFilename}" already exists.`);
                                            }
                                        }
                                    } else {
                                        errors.push(`This file ${uploadedFilename} has incorrect characters in its name (Invalid characters - ${reservedCharsForFileName.join(" ")}). Change the file name and try again.`);
                                    }
                                }
                                if(errors.length > 0){
                                    setValidationErrorMessages(errors);
                                } else {
                                    setSuccessMessage("Upload from SharePoint successful");
                                    props.onFileSelected(filesToUpload, updateFile);
                                }
                            }
                        }}
                    />}
                </>
            )}
            <input
                type="file"
                multiple={isMulti}
                className={styles.filebrowser}
                ref={inputFileUpload}
                onChange={event => {
                    if (event.target.files && event.target.files.length > 0) {
                        const errors: string[] = [];
                        const filesToUpload: IFile[] = [];
                        for(const file of event.target.files){
                            const uploadedFilename: string = file.name;
                            if (!StringHelper.hasReservedChars(uploadedFilename, reservedCharsForFileName)) {
                                // If we are uploading a signed document, we can upload a file with the same name. 
                                // If we are uploading a non signed document, it must have a different name to 
                                // those already uploaded.
                                if(updateFile){
                                    filesToUpload.push({
                                        name: uploadedFilename,
                                        contentURL: URL.createObjectURL(file),
                                        path: URL.createObjectURL(file)
                                    });
                                } else {
                                    if(!props.selectedFilesList?.some(t => t.name === uploadedFilename)) {
                                        filesToUpload.push({
                                            name: uploadedFilename,
                                            contentURL: URL.createObjectURL(file),
                                            path: URL.createObjectURL(file)
                                        });
                                    } else {
                                        errors.push(`File with the name "${uploadedFilename}" already exists.`);
                                    }
                                }
                            } else {
                                errors.push(`This file ${uploadedFilename} has incorrect characters in its name (Invalid characters - ${reservedCharsForFileName.join(" ")}). Change the file name and try again.`);
                            }
                        }
                        if(errors.length > 0){
                            setValidationErrorMessages(errors);
                        } else {
                            setSuccessMessage("");
                            props.onFileSelected(filesToUpload, updateFile);
                        }
                        event.target.value = "";
                    }
                }}
            ></input>
            <div className={styles.uploadedFilesContainer}>
                {props.selectedFilesList && (props.selectedFilesList.length > 0 || props.onLoading) && (
                    <FileList
                        selectedFiles={props.selectedFilesList}
                        onFileRemoveClick={delFile => {
                            props.onFileRemove(delFile);
                        }}
                        onFileEditClick={editFile => {
                            props.onFileEdit(editFile);
                        }}
                        onFileReorder={(moveFile, movement) => {
                            props.onFileMoved(moveFile, movement);
                        }}
                        onFileUpdateClick={editFile => {
                            setUpdateFile(editFile);
                            inputFileUpload.current?.click();
                        }}
                        onLoading={props.onLoading}
                        onLoadingText={props.onLoadingText}
                        componentMode={props.componentMode}
                        templateMode={props.templateMode}
                        canEdit={props.canEdit}
                        canUploadSigned={props.canUploadSigned}
                        canRemove={props.canRemove}
                        filterByUserUPN={props.filterByUserUPN}
                        filterByUserEmail={props.filterByUserEmail}
                    ></FileList>
                )}
            </div>
            <div>
                {props.errorMessages && 
                    <div>
                        {props.errorMessages && props.errorMessages.map( (msg, idx) => 
                            <MessageBar
                                key={idx}
                                messageBarType={MessageBarType.blocked}
                                isMultiline={true}
                            >{msg}</MessageBar>
                        )}
                    </div>
                }
            </div>
        </div>
    );
}
