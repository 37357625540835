import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import contextReducer from "./globalSlices/contextSlice";
import wizardSummaryReducer from "../features/wizardSummary/wizardSummarySlice";
import manageRecordEditDocumentsReducer from "../features/manageRecordEditDocuments/manageRecordEditDocumentsSlice";
import wizardResponseTemplateStepReducer from "../features/wizardResponseTemplate/wizardResponseTemplateStepSlice";
import wizardResponseInformationReducer from "../features/wizardResponseInformation/wizardResponseInformationSlice";
import wizardRecordInformationReducer from "../features/wizardRecordInformation/wizardRecordInformationSlice";
import wizardKeyRolesReducer from "../features/wizardKeyRoles/wizardKeyRolesSlice";
import wizardCreateRecordReducer from "../features/wizardCreateRecord/wizardCreateRecordSlice";
import signInReducer from "../features/signIn/signInSlice";
import recordWizardWrapperReducer from "../features/recordWizardWrapper/recordWizardWrapperSlice";
import wizardCorrespondenceFilesReducer from "../features/wizardCorrespondenceFiles/wizardCorrespondenceFilesSlice";
import bulkUpdateReducer from "../features/bulkUpdatePage/BulkUpdatePageSlice";
import wfAdminReducer from "../features/configurationWorkflowPage/WorkflowAdminPageSlice";
import peoplePickerReducer from "../components/FormControls/PeoplePicker/peoplePickerSlice";
import wizardCorrespondenceDetailsReducer from "../features/wizardCorrespondenceDetails/wizardCorrespondenceDetailsSlice";
import recordsTableReducer from "../features/recordsTable/recordsTableSlice";
import manageRecordWrapperReducer from "../features/manageRecordWrapper/manageRecordWrapperSlice";
import notificationSettingsReducer from "../features/NotificationSettings/NotificationSettingsSlice";
import manageRecordDetailsReducer from "../features/manageRecordWrapper/manageRecordDetails/manageRecordDetailsSlice";
import appSettingsPageReducer from "../features/appSettingsPage/AppSettingsPageSlice";

// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
export const store = configureStore({
    reducer: {
        signIn: signInReducer,
        context: contextReducer,
        recordWizardWrapper: recordWizardWrapperReducer,
        wizardCreateRecord: wizardCreateRecordReducer,
        wizardCorrespondenceFiles: wizardCorrespondenceFilesReducer,
        bulkUpdate: bulkUpdateReducer,
        wfConfig: wfAdminReducer,
        wizardCorrespondenceDetails: wizardCorrespondenceDetailsReducer,
        wizardRecordInformation: wizardRecordInformationReducer,
        wizardResponseInformation: wizardResponseInformationReducer,
        wizardSummary: wizardSummaryReducer,
        wizardKeyRoles: wizardKeyRolesReducer,
        manageRecordEditDocuments: manageRecordEditDocumentsReducer,
        wizardResponseTemplateStep: wizardResponseTemplateStepReducer,
        manageRecordWrapper: manageRecordWrapperReducer,
        recordsTable: recordsTableReducer,
        peoplePicker: peoplePickerReducer,
        notificationSettings: notificationSettingsReducer,
        manageRecordDetails: manageRecordDetailsReducer,
        appSettingsPage: appSettingsPageReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
