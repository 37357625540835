import React from "react";
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    Icon,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/Constants";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import {
    copyRecordActionsDescription,
    supersedeRecordActionsDescription,
    duplicateRecordButtonLabel,
    supersedeRecordButtonLabel,
    duplicateRecordIncludingDocFieldDescription,
    duplicateRecordIncludingDocFieldHeader,
    duplicateRecordWithTemplatesFieldDescription,
    duplicateRecordWithTemplatesFieldHeader,
    exitButton,
    recordCopiedDescription,
    recordCopiedHeaderStrings,
    recordCopyingDescription,
    recordCopyingHeaderStrings,
    recordSupersedingHeaderStrings,
    recordSupersedingDescription,
    recordSupersededHeaderStrings,
    recordSupersededDescription
} from "../../providers/Constants/ManageRecordConstants";
import {
    dashboardButton,
    wrapperOpenButton
} from "../../providers/Constants/RecordWizardConstants";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { IContentType, ICopyRecordRequest, IRecord, ISupersedeRecordRequest } from "../../services/Api/executor/IApiServiceExecutor";
import IApiService from "../../services/Api/IApiService";
import { selectCurrentRecord, selectCurrentRecordType } from "../manageRecordWrapper/manageRecordWrapperSlice";
import { getRecordColor } from "../recordsTable/RecordsTableCommon";
import styles from "./WizardCopyRecord.module.scss";

export interface IWizardCopyRecord {
    isSupersede: boolean;
}

export function WizardCopyRecord(props: IWizardCopyRecord): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const [inCopyProgress, setInCopyProgress] = React.useState<boolean>(false);
    const [choiceOptionKey, setChoiceOptionKey] = React.useState<string | undefined>(undefined);
    const [copiedRecord, setCopiedRecord] = React.useState<IRecord | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const currentRecordType: IContentType | null = useSelector(selectCurrentRecordType);
    const trueKey: string = "true";
    const falseKey: string = "false";

    const recordColor: React.CSSProperties = React.useMemo(
        (): React.CSSProperties =>  getRecordColor(currentRecordType?.prefix || "", currentRecordType?.color),
        [currentRecordType]
    );

    const choiceOptions: IChoiceGroupOption[] = React.useMemo((): IChoiceGroupOption[] => {
        return [
            {
                key: falseKey,
                text: duplicateRecordWithTemplatesFieldHeader,
                // eslint-disable-next-line react/display-name
                onRenderField: (props, render) => {
                    return (
                        <>
                            {render?.(props)}
                            <div className={styles.subChoiceLabel}>
                                {duplicateRecordWithTemplatesFieldDescription}
                            </div>
                        </>
                    );
                },
                value: "duplicateRecordWithTemplates",
                disabled: inCopyProgress
            },
            {
                key: trueKey,
                text: duplicateRecordIncludingDocFieldHeader,
                // eslint-disable-next-line react/display-name
                onRenderField: (props, render) => {
                    return (
                        <>
                            {render?.(props)}
                            <div className={styles.subChoiceLabel}>
                                {duplicateRecordIncludingDocFieldDescription}
                            </div>
                        </>
                    );
                },
                value: "duplicateRecordIncludingDoc",
                disabled: inCopyProgress
            }
        ];
    }, [inCopyProgress]);

    const onConfirm: () => Promise<void> = React.useCallback(async () => {
        try {
            if (choiceOptionKey && currentRecord) {
                setInCopyProgress(true);
                setErrorMessage(undefined);

                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                
                let result: IRecord | null = null;
                const payload: ISupersedeRecordRequest | ICopyRecordRequest = {
                    recordId: currentRecord.recordId,
                    copyDocuments: choiceOptionKey === trueKey
                };

                if (props.isSupersede == true) {
                    result = await apiService.SupersedeRecord(payload);
                }
                else {
                    result = await apiService.CopyRecord(payload);
                }

                if(!result){
                    setErrorMessage("Unable to create record copy ...");
                }

                setCopiedRecord(result);
            }
        } finally {
            setInCopyProgress(false);
        }
    }, [choiceOptionKey, currentRecord, props.isSupersede]);

    return (
        <>
            <div className={styles.copyingRecordContainer}>
                {copiedRecord || inCopyProgress ? (
                    <FormHeader
                        strings={
                            inCopyProgress 
                                ? (props.isSupersede ? recordSupersedingHeaderStrings : recordCopyingHeaderStrings)
                                : (props.isSupersede ? recordSupersededHeaderStrings : recordCopiedHeaderStrings)
                        }
                    />
                ) : (
                    <div className={styles.currentRecordInfoContainer}>
                        <div style={recordColor} className={styles.iconColumnContainer}>
                            <Icon className={styles.iconColumnIcon} iconName={"TextDocument"} ariaLabel={"Document icon"}/>
                            <div className={styles.iconColumnText}>{currentRecord?.recordId}</div>
                        </div>
                        <div className={styles.recordTitle}>{currentRecord?.title}</div>
                    </div>
                )}
                {copiedRecord && !inCopyProgress && (
                    <Link
                        onClick={() => history.push(AppRoutes.buildUrl(AppRoutes.manageRecord, {
                            recordId: copiedRecord.recordId || ""
                        }))}
                    >
                        <div className={styles.recordID}>
                            <Icon iconName="TextDocument" className={styles.icon} ariaLabel={"Document icon"}/>
                            {copiedRecord.recordId}
                        </div>
                    </Link>
                )}
                {!copiedRecord && inCopyProgress && (
                    <div className={styles.description}>{props.isSupersede ? recordSupersedingDescription : recordCopyingDescription}</div>
                )}
                {copiedRecord && !inCopyProgress && (
                    <div className={styles.description}>{props.isSupersede ? recordSupersededDescription : recordCopiedDescription}</div>
                )}
                {!copiedRecord && !inCopyProgress && (
                    <>
                        <div className={styles.beforeActionDescrContainer}>
                            <div className={styles.actionHeaderDescr}>{props.isSupersede ? supersedeRecordActionsDescription : copyRecordActionsDescription}</div>
                            
                        </div>
                        <div className={styles.choiceGroupContainer}>
                            <ChoiceGroup
                                disabled={inCopyProgress || !currentRecord}
                                className={styles.choiceRoleContainer}
                                options={choiceOptions}
                                selectedKey={choiceOptionKey}
                                onChange={(
                                    ev?:
                                        | React.FormEvent<HTMLElement | HTMLInputElement>
                                        | undefined,
                                    option?: IChoiceGroupOption | undefined
                                ) => {
                                    if (option) {
                                        return setChoiceOptionKey(option.key);
                                    }
                                }}
                            />
                        </div>
                    </>
                )}
                <Separator />
                <div>
                    {!!errorMessage &&
                        <MessageBar isMultiline={false} messageBarType={MessageBarType.error}>
                            {errorMessage}
                        </MessageBar>
                    }
                </div>
                <div className={styles.actionButtonsContainer}>
                    {!copiedRecord && !inCopyProgress ? (
                        <PrimaryButton
                            className={styles.actionButton}
                            text={props.isSupersede ? supersedeRecordButtonLabel : duplicateRecordButtonLabel}
                            onClick={onConfirm}
                            disabled={inCopyProgress || !choiceOptionKey || !currentRecord}
                            allowDisabledFocus
                            ariaLabel={`${props.isSupersede ? supersedeRecordButtonLabel : duplicateRecordButtonLabel} button`}
                        />
                    ) : (
                        <PrimaryButton
                            className={styles.actionButton}
                            text={wrapperOpenButton}
                            disabled={inCopyProgress}
                            allowDisabledFocus
                            onClick={() => history.push(AppRoutes.buildUrl(AppRoutes.manageRecord, { recordId: copiedRecord?.recordId || "" }))}
                            ariaLabel={`${wrapperOpenButton} button`}
                        />
                    )}
                    <DefaultButton
                        text={!copiedRecord ? exitButton : dashboardButton}
                        allowDisabledFocus
                        disabled={inCopyProgress}
                        onClick={() => history.goBack()}
                        ariaLabel={`${!copiedRecord ? exitButton : dashboardButton} button`}
                    />
                </div>
            </div>
        </>
    );
}
