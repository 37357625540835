//1. IMPORTS
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import { PrimaryButton, Separator, Spinner, SpinnerSize, Text } from "@fluentui/react";
import {
    fieldDescriptionCabinetWorkingFileNumber,
    fieldLabelEventTitle,
    loadingMessage
} from "../../../providers/Constants/RecordWizardConstants";
import { selectAllFields, selectCurrentRecord, updateRecordInformationAsync } from "../manageRecordWrapperSlice";
import { IContentTypeField, IRecord, ITerm } from "../../../services/Api/executor/IApiServiceExecutor";
import { FieldWrapper } from "../../../components/FormControls/FieldWrapper/FieldWrapper";
import TextBox from "../../../components/FormControls/TextBox/TextBox";
import styles from "../ManageRecordDetails.module.scss";
import {
    cicSelected,
    getManageDetailsDataAsync,
    resetManageRecordDetails,
    selectCabinetWorkingFileNumber,
    selectEnteredTitle,
    selectSelectedSecurityClassificationsType,
    setCabinetWorkingFileNumber,
    setManageRecordDetails,
    setTitle
} from "../manageRecordDetails/manageRecordDetailsSlice";
import StringHelper from "../../../helpers/StringHelper";
import { getWizardRecordInformationDataAsync, resetWizardRecordInformation, selectRestrictAccessTeams, selectSelectedOverrideDefaultAccess, selectSelectedRestrictAccessTeams } from "../../wizardRecordInformation/wizardRecordInformationSlice";
import { teams } from "../../../providers/Constants/TermConstants";
import { getFieldDisplayName } from "../../../helpers/ModelHelper";

export function ManageEventRecordDetails(): JSX.Element {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);

    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);
    const isCICSelected: boolean = useSelector(cicSelected);
    const cabinetWorkingFileNumber: string | undefined = useSelector(selectCabinetWorkingFileNumber);
    const selectedSecurityClassificationsType: ITerm | null = useSelector(
        selectSelectedSecurityClassificationsType
    );
    const selectedOverrideDefaultAccess: boolean = useSelector(selectSelectedOverrideDefaultAccess);
    const selectedRestrictAccessTeams: ITerm[] | null = useSelector(
        selectSelectedRestrictAccessTeams
    );
    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);
    const restrictAccessTeams: ITerm[] = useSelector(selectRestrictAccessTeams);
    
    // 3. DEFINE COMPONENT STATE
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [initilized, setInitilized] = React.useState<boolean>(false);
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
   
    // 5. DEFINE COMPONENT HOOKS
    const { recordId } = useParams<{ recordId: string }>();

    const readOnly: boolean = React.useMemo(
        () =>
            !currentRecord?.isCurrentUserOrgAdmin && (
                !currentRecord?.canCurrentUserEditRecord ||
                !!currentRecord?.canCurrentUserEditRecordLimitedAccess ||
                !!currentRecord?.noFurtherAction ||
                !!currentRecord?.withdrawn
            ),
        [
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.canCurrentUserEditRecordLimitedAccess,
            currentRecord?.noFurtherAction,
            currentRecord?.withdrawn,
            currentRecord?.isCurrentUserOrgAdmin
        ]
    );

    React.useEffect(() => {
        dispatch(getWizardRecordInformationDataAsync(currentRecord?.recordType || "(empty)"));
        dispatch(getManageDetailsDataAsync());
        return () => {
            dispatch(resetWizardRecordInformation());
            dispatch(resetManageRecordDetails());
        };
    }, [dispatch, currentRecord?.recordType]);

    React.useEffect(() => {
        if (currentRecord) {
            dispatch(setManageRecordDetails(currentRecord));
        }
    }, [dispatch, currentRecord]);

    const modelIsValid: boolean = React.useMemo(() => {
        const isValid: boolean = !!enteredTitle;
        return isValid;
    }, [enteredTitle]);

    React.useEffect(() => {
        if (!initilized && currentRecord && enteredTitle) {
            setInitilized(true);
        }
    }, [initilized, currentRecord, enteredTitle]);
    
    const validateUpdate: () => void = React.useCallback((): void => {
        if (recordId && modelIsValid) {
            setUpdatingInProgress(true);
            const restrictCabinetTeam: ITerm | undefined = restrictAccessTeams.find(r => r.name === teams.cabinet);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: recordId,
                        title: enteredTitle,
                        cabinetWorkingFolderNumber: cabinetWorkingFileNumber,
                        overrideDefaultAccess: isCICSelected ? true : selectedOverrideDefaultAccess,
                        securityClassification: selectedSecurityClassificationsType
                            ? [selectedSecurityClassificationsType]
                            : undefined,
                        restrictAccessTeams: isCICSelected  && (!selectedRestrictAccessTeams || selectedRestrictAccessTeams.findIndex(r => r.name === teams.cabinet) === -1)
                            ? [...(selectedRestrictAccessTeams || []), (restrictCabinetTeam || {})]
                            : selectedRestrictAccessTeams || undefined,
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                        },
                        onError: e => {
                            setError(e);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [
        recordId,
        modelIsValid,
        dispatch,
        enteredTitle,
        cabinetWorkingFileNumber,
        selectedSecurityClassificationsType,
        selectedOverrideDefaultAccess,
        isCICSelected,
        selectedRestrictAccessTeams,
        restrictAccessTeams
    ]);

    const updateBtnIsActive: boolean =
        modelIsValid &&
        !updatingInProgress &&
        !!currentRecord &&
        (currentRecord.title !== enteredTitle ||
            !StringHelper.isEqual(currentRecord.cabinetWorkingFolderNumber, cabinetWorkingFileNumber) ||
            !StringHelper.isEqual((currentRecord.securityClassification || [])[0]?.name, selectedSecurityClassificationsType?.name));

    return (
        <div>
            {!initilized ? (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.small}
                        label={loadingMessage}
                    />
                </div>
            ) : (
                <div className={styles.manageRecordDetailsContainer}>
                    <div className={styles.formContainer}>
                        <FieldWrapper>
                            <TextBox
                                name={fieldLabelEventTitle}
                                required={true}
                                rows={5}
                                defaultValue={enteredTitle}
                                value={enteredTitle}
                                onChange={(ev, newValue) => {
                                    dispatch(setTitle(newValue));
                                }}
                                disabled={!initilized || readOnly || updatingInProgress}
                            />
                        </FieldWrapper>
                        {isCICSelected && (
                            <FieldWrapper description={fieldDescriptionCabinetWorkingFileNumber}>
                                <TextBox
                                    name={getFieldDisplayName("ABCCabinetWorkingFolderNumber", allFields)}
                                    required={isCICSelected}
                                    disabled={readOnly || updatingInProgress}
                                    defaultValue={cabinetWorkingFileNumber}
                                    onChange={(ev, newValue) => {
                                        dispatch(setCabinetWorkingFileNumber(newValue));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                    </div>
                    {!readOnly && (
                        <div className={styles.footer}>
                            <Separator />
                            <PrimaryButton
                                onClick={validateUpdate}
                                disabled={readOnly || !updateBtnIsActive || updatingInProgress}
                                className={styles.updateBtn}
                            >
                                <Text className={styles.text}>Save</Text>
                                {updatingInProgress && (
                                    <Spinner className={styles.spinner} size={SpinnerSize.small} />
                                )}
                            </PrimaryButton>
                            {!!error && <div>{error.message}</div>}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
