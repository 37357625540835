// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IContentTypeField,
    IFieldValue,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    IOrgs,
    IRecord,
    ITerm,
    ITermGroup,
    ITermSet,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { AppThunk, RootState } from "../../app/store";
import { isCicSelected } from "../../helpers/ModelHelper";

// 2. TYPES AND INTERFACES
// none

// 3. FEATURE STATE
export interface IWizardRecordInformationState {
    decisionCategories: ITermSet | null;
    selectedDecisionCategory: ITerm | null;
    orgHierarchy: IOrgs | null;
    selectedOrgLevel1: IOrgLevel1 | null;
    selectedOrgLevel2: IOrgLevel2 | null;
    selectedOrgLevel3: IOrgLevel3 | null;
    selectedOrgLevel4: IOrgLevel4 | null;
    activeFields: IContentTypeField[] | null;
    allFields: IContentTypeField[] | null;
    inActiveFields: IContentTypeField[] | null;
    termGroup: ITermGroup | null;
    fieldValues?: IFieldValue[] | null;
    overrideDefaultAccess: boolean;
    accessRestrictedUsers: IUser[];
    restrictAccessTeams: ITermSet | null;
    securityClassifications: ITermSet | null;
    parliamentMembers: ITermSet | null;
    selectedRestrictAccessTeams: ITerm[] | null;
    selectedSecurityClassification: ITerm | null;
    cicSelected: boolean;
    selectedCabinetWorkingFileNumber?: string;
    selectedRecordType: string | null;
}

// 4. FEATURE INITIAL STATE
export const initialState: IWizardRecordInformationState = {
    decisionCategories: null,
    selectedDecisionCategory: null,
    orgHierarchy: null,
    termGroup: null,
    selectedOrgLevel1: null,
    selectedOrgLevel2: null,
    selectedOrgLevel3: null,
    selectedOrgLevel4: null,
    overrideDefaultAccess: false,
    activeFields: null,
    allFields: null,
    inActiveFields: null,
    fieldValues: null,
    accessRestrictedUsers: [],
    restrictAccessTeams: null,
    securityClassifications: null,
    parliamentMembers: null,
    selectedRestrictAccessTeams: null,
    selectedSecurityClassification: null,
    cicSelected:false,
    selectedRecordType: null,
    selectedCabinetWorkingFileNumber: undefined
};

// 4. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const wizardRecordInformationSlice = createSlice({
    name: "recordWizardWrapper",
    initialState,
    reducers: {
        resetWizardRecordInformation: (state: IWizardRecordInformationState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardRecordInformationState =
                    key as keyof IWizardRecordInformationState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (state[keyAs] as any) = initialState[keyAs] as any;
            }
        },
        setWizardRecordInformation: (
            state: IWizardRecordInformationState,
            action: PayloadAction<Partial<IRecord>>
        ) => {
            const record: Partial<IRecord> = action.payload;
            state.selectedDecisionCategory = (record.decisionCategory || [])[0];
            state.selectedOrgLevel1 = castSelectedOrgLevel1(state, record.organisationLv1);
            state.selectedOrgLevel2 = castSelectedOrgLevel2(state, record.organisationLv2);
            state.selectedOrgLevel3 = castSelectedOrgLevel3(state, record.organisationLv3);
            state.selectedOrgLevel4 = castSelectedOrgLevel4(state, record.organisationLv4);

            //fill it with fake values in case Org Level was removed from AAD to show it on edit form
            addFakeOrgStructureForAADUpdates(state, record);

            state.selectedRestrictAccessTeams = record.restrictAccessTeams || null;
            state.overrideDefaultAccess = record.overrideDefaultAccess || false;
            state.selectedCabinetWorkingFileNumber = record.cabinetWorkingFolderNumber;
            state.fieldValues = record.fieldValues;
            state.accessRestrictedUsers = record.viewAccessUsers || [];
            state.selectedSecurityClassification = record.securityClassification
                ? record.securityClassification[0]
                : null;
            state.cicSelected = isCicSelected(state.selectedSecurityClassification);
            state.selectedRecordType = record.recordType ? record.recordType : null;
        },
        setAllFields: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.allFields = action.payload;
        },
        setActiveFields: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.activeFields = action.payload;
        },
        setInactiveFields: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IContentTypeField[] | null>
        ) => {
            state.inActiveFields = action.payload;
        },
        setTermGroup: (
            state: IWizardRecordInformationState,
            action: PayloadAction<ITermGroup | null>
        ) => {
            state.termGroup = action.payload;
        },
        setFieldValues: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IFieldValue[] | null>
        ) => {
            state.fieldValues = action.payload;
        },
        setDecisionCategoriesTermSet: (
            state: IWizardRecordInformationState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.decisionCategories = action.payload;
        },
        setRestictedAccessTeamsTermSet: (
            state: IWizardRecordInformationState,
            action: PayloadAction<ITermSet>
        ) => {
            state.restrictAccessTeams = action.payload;
        },
        setOrgHierarchy: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IOrgs | null>
        ) => {
            state.orgHierarchy = action.payload;
        },
        setSelectedDecisionCategory: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedDecisionCategory = castSelectedDecisionCategory(state, action.payload);
        },
        setSelectedOrgLevel1: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel2 = null;
            state.selectedOrgLevel3 = null;
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel1 = castSelectedOrgLevel1(state, action.payload);
        },
        setSelectedOrgLevel2: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel3 = null;
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel2 = castSelectedOrgLevel2(state, action.payload);
        },
        setSelectedOrgLevel3: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel4 = null;
            state.selectedOrgLevel3 = castSelectedOrgLevel3(state, action.payload);
        },
        setSelectedOrgLevel4: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedOrgLevel4 = castSelectedOrgLevel4(state, action.payload);
        },
        setCabinetWorkingFileNumber: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            state.selectedCabinetWorkingFileNumber = action.payload;
        },
        setOverrideDefaultAccess: (
            state: IWizardRecordInformationState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.overrideDefaultAccess = action?.payload || false;
        },
        setRestrictTeamsTermSet: (
            state: IWizardRecordInformationState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.restrictAccessTeams = action.payload;
        },
        setSecurityClassificationTermSet: (
            state: IWizardRecordInformationState,
            action: PayloadAction<ITermSet | null>
        ) => {
            state.securityClassifications = action.payload;
        },
        addAccessRestrictedUsers: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IUser>
        ) => {
            state.accessRestrictedUsers.push(action.payload);
        },
        removeAccessRestrictedUsers: (
            state: IWizardRecordInformationState,
            action: PayloadAction<IUser>
        ) => {
            const users: IUser[] = [];
            state.accessRestrictedUsers.forEach(u => {
                if (
                    u.graphUserId !== action.payload.graphUserId ||
                    u.siteUserId !== action.payload.siteUserId
                ) {
                    users.push(u);
                }
            });
            state.accessRestrictedUsers = users;
        },
        setSelectedSecurityClassification: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string | undefined>
        ) => {
            if (!action || !action.payload) {
                state.selectedSecurityClassification = null;
            } else {
                const terms: ITerm[] = state.securityClassifications?.terms || [];
                for (let i: number = 0; i < terms.length; i++) {
                    const term: ITerm = terms[i];
                    if (term.id === action.payload) {
                        state.selectedSecurityClassification = term;
                        break;
                    }
                }
            }

            state.cicSelected = isCicSelected(state.selectedSecurityClassification);
            if (!state.cicSelected) {
                state.selectedCabinetWorkingFileNumber = undefined;
            }
        },
        setSelectedRestrictTeams: (
            state: IWizardRecordInformationState,
            action: PayloadAction<string[] | undefined>
        ) => {
            if (!action || !action.payload) {
                state.selectedRestrictAccessTeams = null;
            } else {
                const terms: ITerm[] = state.restrictAccessTeams?.terms || [];
                const selectedTerms: ITerm[] = [];
                for (let i: number = 0; i < terms.length; i++) {
                    const term: ITerm = terms[i];
                    const pos: number = action.payload.indexOf(term.name || "");
                    if (pos >= 0) {
                        selectedTerms.push(term);
                    }
                }
                state.selectedRestrictAccessTeams = selectedTerms;
            }
        }
    }
});

// 4. SYNCRONOUS ACTIONS
const { setDecisionCategoriesTermSet, setOrgHierarchy, setRestrictTeamsTermSet } =
    wizardRecordInformationSlice.actions;
export const {
    resetWizardRecordInformation,
    setWizardRecordInformation,
    setSelectedDecisionCategory,
    setSelectedOrgLevel1,
    setSelectedOrgLevel2,
    setSelectedOrgLevel3,
    setSelectedOrgLevel4,
    setOverrideDefaultAccess,
    setRestictedAccessTeamsTermSet,
    setActiveFields,
    setInactiveFields,
    setAllFields,
    setTermGroup,
    setFieldValues,
    setSecurityClassificationTermSet,
    addAccessRestrictedUsers,
    removeAccessRestrictedUsers,
    setSelectedSecurityClassification,
    setCabinetWorkingFileNumber,
    setSelectedRestrictTeams
} = wizardRecordInformationSlice.actions;

// 5. ASYNCRONOUS ACTIONS
export const getWizardRecordInformationDataAsync = (recordType:string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    const promises: Promise<ITermSet | IOrgs | IContentTypeField[] | ITermGroup | null>[] = [
        apiService.GetDecisionCategoryTermSet(),
        apiService.GetSecurityClassificationTermSet(),
        apiService.GetTeamRestrictTermSet(),
        apiService.GetOrgHierarchy(),
        apiService.GetContentTypeFields(recordType),
        apiService.GetAllTermSets()
    ];
    const [decisionCategoryTermSet, securityClassificationTermSet, teamRestrictTermSet, orgs, contentTypeFields, termGroup] =
    await Promise.all(promises);
    dispatch(setDecisionCategoriesTermSet(decisionCategoryTermSet as ITermSet));
    dispatch(setSecurityClassificationTermSet(securityClassificationTermSet as ITermSet));
    dispatch(setRestrictTeamsTermSet(teamRestrictTermSet as ITermSet));
    dispatch(setOrgHierarchy(orgs as IOrgs));
    dispatch(setActiveFields((contentTypeFields as IContentTypeField[])?.filter(v=>v.isActive)));
    dispatch(setInactiveFields((contentTypeFields as IContentTypeField[])?.filter(v=>!v.isActive)));
    dispatch(setAllFields((contentTypeFields as IContentTypeField[])));
    dispatch(setTermGroup(termGroup as ITermGroup));
};

// 6. SELECTORS
const castSelectedDecisionCategory = (
    state: IWizardRecordInformationState,
    id: string | undefined
) => {
    let result: ITerm | null = null;
    if (id) {
        const decisionCategoryTerms: ITerm[] = state.decisionCategories?.terms || [];
        for (let j: number = 0; j < decisionCategoryTerms.length; j++) {
            const o: ITerm = decisionCategoryTerms[j];
            if (o.id === id) {
                result = o;
            }
        }
    }
    return result;
};

const castSelectedOrgLevel1 = (
    state: IWizardRecordInformationState,
    orgName: string | undefined
): IOrgLevel1 | null => {
    let result: IOrgLevel1 | null = null;
    if (orgName && state?.orgHierarchy?.orgLevel1) {
        for (let i: number = 0; i < state.orgHierarchy.orgLevel1.length; i++) {
            const o: IOrgLevel1 = state.orgHierarchy.orgLevel1[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const castSelectedOrgLevel2 = (
    state: IWizardRecordInformationState,
    orgName: string | undefined
): IOrgLevel2 | null => {
    let result: IOrgLevel2 | null = null;
    if (orgName && state.selectedOrgLevel1?.orgLevel2) {
        for (let i: number = 0; i < state.selectedOrgLevel1.orgLevel2.length; i++) {
            const o: IOrgLevel2 = state.selectedOrgLevel1.orgLevel2[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const castSelectedOrgLevel3 = (
    state: IWizardRecordInformationState,
    orgName: string | undefined
): IOrgLevel3 | null => {
    let result: IOrgLevel3 | null = null;
    if (orgName && state.selectedOrgLevel2?.orgLevel3) {
        for (let i: number = 0; i < state.selectedOrgLevel2.orgLevel3.length; i++) {
            const o: IOrgLevel3 = state.selectedOrgLevel2.orgLevel3[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};
const castSelectedOrgLevel4 = (
    state: IWizardRecordInformationState,
    orgName: string | undefined
): IOrgLevel4 | null => {
    let result: IOrgLevel4 | null = null;
    if (orgName && state.selectedOrgLevel3?.orgLevel4) {
        for (let i: number = 0; i < state.selectedOrgLevel3.orgLevel4.length; i++) {
            const o: IOrgLevel3 = state.selectedOrgLevel3.orgLevel4[i];
            if (o.name === orgName) {
                result = o;
            }
        }
    }
    return result;
};

const addFakeOrgStructureForAADUpdates = (state: IWizardRecordInformationState, record: Partial<IRecord>): void => {
    //fill it with fake values in case Org Level was removed from AAD.
    // Just to make current record's value make available on edit form
    // -----------  LVL 4 --------------------
    if(!!record.organisationLv4 && record.organisationLv4.length > 0 && state.selectedOrgLevel4 === null){
        state.selectedOrgLevel4 = {
            name: record.organisationLv4
        };
    }
    
    // -----------  LVL 3 --------------------
    if(!!record.organisationLv3 && record.organisationLv3.length > 0 && state.selectedOrgLevel3 === null){
        state.selectedOrgLevel3 = {
            name: record.organisationLv3, 
            orgLevel4: []
        };
    }
    //add level4 to level3 options if required
    if(state.selectedOrgLevel3 && state.selectedOrgLevel4?.name){
        state.selectedOrgLevel3.orgLevel4 = state.selectedOrgLevel3.orgLevel4 || [];
        const lvl4name: string = state.selectedOrgLevel4.name;
        if(state.selectedOrgLevel3.orgLevel4.every(x=>x.name !== lvl4name)){
            state.selectedOrgLevel3.orgLevel4.push(state.selectedOrgLevel4);
        }
    }
    
    // -----------   LVL 2 --------------------
    if(!!record.organisationLv2 && record.organisationLv2.length > 0 && state.selectedOrgLevel2 === null){
        state.selectedOrgLevel2 = {
            name: record.organisationLv2, 
            orgLevel3: []
        };
    
    }
    //add level3 to level2 options if required
    if(state.selectedOrgLevel2 && state.selectedOrgLevel3?.name){
        state.selectedOrgLevel2.orgLevel3 = state.selectedOrgLevel2.orgLevel3 || [];
        const lvl3name: string = state.selectedOrgLevel3.name;
        if(state.selectedOrgLevel2.orgLevel3.every(x=>x.name !== lvl3name)){
            state.selectedOrgLevel2.orgLevel3.push(state.selectedOrgLevel3);
        }
    }
    
    // -----------   LVL 1 --------------------
    if(!!record.organisationLv1 && record.organisationLv1.length > 0 && state.selectedOrgLevel1 === null){
        state.selectedOrgLevel1 = {
            name: record.organisationLv1, 
            orgLevel2: []
        };
    
    }
    //add level2 to level1 options if required
    if(state.selectedOrgLevel1 && state.selectedOrgLevel2?.name){
        state.selectedOrgLevel1.orgLevel2 = state.selectedOrgLevel1.orgLevel2 || [];
        const lvl2name: string = state.selectedOrgLevel2.name;
        if(state.selectedOrgLevel1.orgLevel2.every(x=>x.name !== lvl2name)){
            state.selectedOrgLevel1.orgLevel2.push(state.selectedOrgLevel2);
        }
    }
};

export const selectDecisionCategories = (state: RootState): ITerm[] => {
    const decisionCategories: ITerm[] =
        state.wizardRecordInformation.decisionCategories?.terms || [];
    return decisionCategories;
};

export const selectOrgLevel1s = (state: RootState): IOrgLevel1[] =>
    state.wizardRecordInformation.orgHierarchy?.orgLevel1 || [];
export const selectOrgLevel2s = (state: RootState): IOrgLevel2[] =>
    state.wizardRecordInformation.selectedOrgLevel1?.orgLevel2 || [];
export const selectOrgLevel3s = (state: RootState): IOrgLevel3[] =>
    state.wizardRecordInformation.selectedOrgLevel2?.orgLevel3 || [];
export const selectOrgLevel4s = (state: RootState): IOrgLevel4[] =>
    state.wizardRecordInformation.selectedOrgLevel3?.orgLevel4 || [];
export const selectAllFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardRecordInformation.allFields || [];
export const selectActiveFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardRecordInformation.activeFields || [];
export const selectInactiveFields = (state: RootState): IContentTypeField[] | null =>
    state.wizardRecordInformation.inActiveFields || [];
export const selectCurrentFieldValues = (state: RootState): IFieldValue[] | null =>
    state.wizardRecordInformation.fieldValues || [];
export const selectTermGroup = (state: RootState): ITermGroup | null =>
    state.wizardRecordInformation.termGroup || null;

export const selectSelectedDecisionCategory = (state: RootState): ITerm | null =>
    state.wizardRecordInformation.selectedDecisionCategory;
export const selectSelectedOrgLevel1 = (state: RootState): IOrgLevel1 | null =>
    state.wizardRecordInformation.selectedOrgLevel1;
export const selectSelectedOrgLevel2 = (state: RootState): IOrgLevel2 | null =>
    state.wizardRecordInformation.selectedOrgLevel2;
export const selectSelectedOrgLevel3 = (state: RootState): IOrgLevel3 | null =>
    state.wizardRecordInformation.selectedOrgLevel3;
export const selectSelectedOrgLevel4 = (state: RootState): IOrgLevel4 | null =>
    state.wizardRecordInformation.selectedOrgLevel4;
export const selectSelectedOverrideDefaultAccess = (state: RootState): boolean =>
    state.wizardRecordInformation.overrideDefaultAccess;
export const selectSelectedAccessRestrictedUsers = (state: RootState): IUser[] =>
    state.wizardRecordInformation.accessRestrictedUsers;

export const selectSecurityClassificationsTypes = (state: RootState): ITerm[] =>
    state.wizardRecordInformation.securityClassifications?.terms || [];
export const cicSelected = (state: RootState): boolean =>
    state.wizardRecordInformation.cicSelected;
export const selectParliamentMembers = (state: RootState): ITerm[] =>
    state.wizardRecordInformation.parliamentMembers?.terms || [];
export const selectSelectedSecurityClassificationsType = (state: RootState): ITerm | null =>
    state.wizardRecordInformation.selectedSecurityClassification;

export const selectCabinetWorkingFileNumber = (state: RootState): string | undefined =>
    state.wizardRecordInformation.selectedCabinetWorkingFileNumber;

export const selectRestrictAccessTeams = (state: RootState): ITerm[] =>
    state.wizardRecordInformation.restrictAccessTeams?.terms || [];
export const selectSelectedRestrictAccessTeams = (state: RootState): ITerm[] | null =>
    state.wizardRecordInformation.selectedRestrictAccessTeams;

export const selectDecisionCategoryTermSet = (state: RootState): ITermSet | null =>
    state.wizardRecordInformation.decisionCategories;
export const selectSecurityClassificationTermSet = (state: RootState): ITermSet | null =>
    state.wizardRecordInformation.securityClassifications;
export const selectTeamRestrictTermSet = (state: RootState): ITermSet | null =>
    state.wizardRecordInformation.restrictAccessTeams;
export const selectOrgHierarchy = (state: RootState): IOrgs | null =>
    state.wizardRecordInformation.orgHierarchy;
// 7. EXPORT REDUCER
export default wizardRecordInformationSlice.reducer;
