import { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import { NotificationsChannel } from "../../services/Api/executor/IApiServiceExecutor";

export const SettingsHeader: string = "Settings";
export const SettingsSubText: string = "Manage Notifications";
export const ProxyButtonIcon: string = "Add";
export const ProxyButtonText: string = "Open request template";
export const ProxyEmailUserToken: string = "{{proxy}}";
export const ProxyEmailExpiryToken: string = "{{proxyExpiry}}";
export const ProxyEmailCurrentTimeToken: string = "{{currentTime}}";
export const ProxyEmailCurrentUserToken: string = "{{currentUser}}";
export const ProxyEmailRemoveText: string = "Request to remove";
export const ProxyEmailEditExpiry: string = "Edit end date";
export const ProxyTableHeading: string = "Access Permissions";
export const ProxyAddHeading: string = "Request access for a new proxy";
export const BreadcrumbSettings: string = "Settings";
export const ProxyTableHeadingHeader: string = "Manage Proxy Access";

// Notifications
// // Header
export const RecordNotificationsHeader: string = "Manage record notifications";
export const GlobalNotificationsHeader: string = "Manage notifications";
export const OtherNotificationsHeader: string = "Notify others about this record";
export const RecordRelatedRecordsHeader: string = "Related records";
export const AddNewRelatedRecordsHeader: string = "Add new related records";
export const RecentlyAddedRelatedRecordsHeader: string = "Related records added";
export const TxtChannel: string = "Preferred channel";
export const AvailableChannels: ISelectOptionProps[] = [
    { key: String(NotificationsChannel.Email), text: "Email (default)" }, 
    { key: String(NotificationsChannel.Teams), text: "Teams" }
];
export const TxtFrequency: string = "Frequency";
export const TxtNotifyRecommendation: string = "Notify of recommendation";
export const TxtNotifyDecision: string = "Notify of decision";
export const TxtFullName: string = "Full Name";
export const TxtNotifyOthersDescription: string =
    "Notify people not assigned to an Role when the record is sent to the Decision Maker and/or when a decision is made.";
export const TxtNotifyRecommendationDescription: string =
    "An email notification will be sent when the record is sent to the Decision Maker.";
export const TxtNotifyDecisionDescription: string =
    "An email notification will be sent when a decision has been entered and the record is sent on by the Decision Maker. ";
export const TxtSearch:string="Search people";
export const TxtBtnSave: string = "Save";
export const TxtBtnCancel: string = "Cancel";
export const TxtBtnAdd:string = "Add";
export const TxtBtnRemove:string = "Remove";
export const TxtWarning:string = "Warning";
export const TxtUserEdit:string = "PageEdit";
export const TxtPeopleNotified:string = "People to be notified";
export const TxtNoPeopleMsg:string ="There are currently no people to be notified.";
export const TxtModalTitle:string ="Remove user";
export const TxtModalSubmitLabel:string ="Submit";
export const TxtModalContent:string ="Are you sure you want to remove the user?";
