import React from "react";
import styles from "./RecordTimeline.module.scss";
import { ActiveStageButtons } from "./ActiveStageButtons";
import { Icon } from "@fluentui/react";
import { IContentTypeField, IRecord } from "../../../services/Api/executor/IApiServiceExecutor";
import { StageTypes } from "../../../providers/Constants/TermConstants";

interface IBlockingTimelineEntryProps {
    record: IRecord;
    fields: IContentTypeField[];
    stageType: StageTypes;
    stepClassName: string;
    iconClassName: string;
    iconName: string;
    isMobileView?: boolean;
}

export default function BlockingTimelineEntry(props: IBlockingTimelineEntryProps): JSX.Element {
    return (<div
        className={[
            styles.stage,
            styles.predecessorActiveStep,
            styles.additionalStep,
            props.stepClassName
        ].join(" ")}
    >
        <div className={styles.leftSide}>
            <div className={styles.headerIcon}>
                <Icon className={props.iconClassName} iconName={props.iconName} ariaLabel={`${props.iconName} icon`}/>
                <h3 className={styles.label}>{props.stageType}</h3>
            </div>
        </div>
        <div className={styles.midArea}>
        </div>
        <div className={styles.rightSide}>
            {props.isMobileView && (
                <h3 className={[styles.label, styles.mobileBlockedSectionLabel].join(" ")}>{props.stageType}</h3>
            )}
            <ActiveStageButtons record={props.record} fields={props.fields}/>
        </div>
    </div>);
}