// 1. IMPORTS

import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    AccountInfo
} from "@azure/msal-browser";
import {
    LogInState,
    selectAccount,
    selectErrorMessage,
    selectLogInState,
    signInAsync
} from "./signInSlice";
import styles from "./SignIn.module.css";

// 2. DEFINE PROPS
export interface ISignInProps {
    supressAutoLoad?: boolean;
    children?: JSX.Element | JSX.Element[];
}

export function SignIn(props: ISignInProps): JSX.Element {
    // 3. SELECT CURRENT GLOBAL STATE
    const logInState: LogInState = useSelector(selectLogInState);
    const account: AccountInfo | null = useSelector(selectAccount);
    const errorMessage: string | null | undefined = useSelector(selectErrorMessage);

    // 4. DEFINE COMPONENT STATE
    // NOTE: No component state

    // 5. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 6. DEFINE COMPONENT HOOKS
    React.useEffect(() => {
        if (!props.supressAutoLoad && logInState === LogInState.NotLoggedIn) {
            dispatch(signInAsync());
        }
    }, [logInState, dispatch, props.supressAutoLoad]);

    // 7. RETURN RENDER TEMPLATE
    return (
        <>
            {logInState === LogInState.NotLoggedIn && (
                <div className={styles.signIn}>Signing you in...</div>
            )}
            {logInState === LogInState.ValidatingLogin && (
                <div className={styles.signIn}>Checking your access...</div>
            )}
            {logInState === LogInState.InvalidLogIn && (
                <div className={styles.signIn}>
                    {account?.username && (
                        <p>
                            You are signed in as {account.username}
                        </p>
                    )}
                    {!account?.username && <span>You have failed to sign in successfully.</span>}
                    <p>
                        {errorMessage ? errorMessage : "There has been an error validating your access."}
                    </p>
                </div>
            )}
            {logInState === LogInState.ValidLogIn && props.children}
        </>
    );
}
