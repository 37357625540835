import React from "react";
import {
    Icon
} from "@fluentui/react";
import styles from "./DocumentLink.module.scss";
import {
    IFile
} from "../../../services/Api/executor/IApiServiceExecutor";
import IconHelper from "../../../helpers/IconHelper";
import { TeamsContextProvider } from "../../../providers/TeamsContextProvider";

interface IDocumentLinkProps {
    files: IFile[] | null;
    fileNotAvailableMessage: string;
}

export default function DocumentLink(props: IDocumentLinkProps): JSX.Element {
    if (props.files && props.files.length > 0) {
        return (
            <div>
                {props.files.map(file => (
                    <div className={styles.linkContainer} key={file.name}>
                        <a
                            href={file.contentURL}
                            className={styles.docLink}
                            target={TeamsContextProvider.IsInTeams ? "_blank" : "_self"}
                            rel="noreferrer"
                        >
                            <div className={styles.docContainer}>
                                <div className={styles.docIcon}>
                                    <Icon
                                        iconName={IconHelper.GetIconOfficeUIFabricName(file.name)}
                                        className={styles.docIcon}
                                        ariaLabel={`${IconHelper.GetIconOfficeUIFabricName(file.name)} document icon`}
                                    />
                                </div>
                                <div className={styles.docName}>{file.name}</div>
                            </div>
                        </a>
                        {file.sensitivityLabel && `(${file.sensitivityLabel})`}
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.fileNotAvailableMessage}>{props.fileNotAvailableMessage}</div>
        );
    }
}
