import React, { CSSProperties } from "react";
import styles from "./RecordTimeline.module.scss";
import {
    IContentTypeField,
    IRecord,
    ITimelineEntry,
    IUser
} from "../../../services/Api/executor/IApiServiceExecutor";
import {
    isBlockedState,
    isClosed,
    isMakeDecisionStage,
} from "./StagesContentUtils";
import { StageTypes } from "../../../providers/Constants/TermConstants";
import { useSelector } from "react-redux";
import {
    ITimeLineOptions,
    selectTimelineOptions
} from "../../../features/manageRecordWrapper/manageRecordWrapperSlice";
import TimelineEntry from "./TimelineEntry";
import BlockingTimelineEntry from "./BlockingTimelineEntry";
import { useTranslation } from "react-i18next";
import { Spinner, SpinnerSize } from "@fluentui/react";

interface IRecordTimelineProps {
    record: IRecord;
    fields: IContentTypeField[];
    timelineItems?: ITimelineEntry[] | null;
    isMobileView?: boolean;
    user: IUser;
}

export interface ITimeLineRecordElement {
    key: StageTypes;
    content: JSX.Element;
}

export default function RecordTimeline({
    record,
    fields,
    isMobileView,
    timelineItems
}: IRecordTimelineProps): JSX.Element | null {
    const activeActivityIndex: number = timelineItems?.findIndex(da => da.isActive) || 0;
    const selectedVerticalLineOPtions: ITimeLineOptions | null = useSelector(selectTimelineOptions);
    const isDecisionStage: boolean = React.useMemo(
        () => isMakeDecisionStage(record.stage),
        [record.stage]
    );
    const isBlocked: boolean = isBlockedState(record);
    const { t } = useTranslation();

    let blockedIcon: string = "";
    let blockedStageType: StageTypes = StageTypes.Cancelled;
    let blockedStepClassName: string = styles.cancelledStep;
    let blockedClassName: string = styles.cancelledIcon;
        
    if (record.onHold == true) {
        blockedIcon = "Blocked2";
        blockedStageType = StageTypes.OnHold;
        blockedStepClassName = styles.onHoldStep;
        blockedClassName = styles.onHoldIcon;
    } else if (record.noFurtherAction == true) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.NoFurtherAction;
        blockedStepClassName = styles.noFurtherActionStep;
        blockedClassName = styles.noFurtherActionIcon;
    } else if (record.withdrawn == true) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.Withdrawn;
        blockedStepClassName = styles.withdrawnStep;
        blockedClassName = styles.withdrawnIcon;
    } else if (record.cancelled == true) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.Cancelled;
        blockedStepClassName = styles.cancelledStep;
        blockedClassName = styles.cancelledIcon;
    } else if (record.superseded == true) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.Superseded;
        blockedStepClassName = styles.supersededStep;
        blockedClassName = styles.supersededIcon;
    } else if (record.actionedByMcu == true) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.ActionedByMcu;
        blockedStepClassName = styles.actionedByMcuStep;
        blockedClassName = styles.actionedByMcuIcon;
    } else if (isClosed(record)) {
        blockedIcon = "Completed";
        blockedStageType = StageTypes.Closed;
        blockedStepClassName = styles.closedStep;
        blockedClassName = styles.closedIcon;
    }

    const lineHeightClassName: string = styles.singleTimeline;

    const lineStyle: CSSProperties = React.useMemo(() => {
        if (isBlocked && !isMobileView && selectedVerticalLineOPtions) {
            return { height: selectedVerticalLineOPtions.verticalLineStyleHeight };
        }
        return {};
    }, [selectedVerticalLineOPtions, isBlocked, isMobileView]);

    const isTimelineInconsistent: boolean = record.modified !== timelineItems?.[0]?.lastModifiedTime;

    return timelineItems?.length ? (
        <>
            <div
                className={`${styles.timeline} ${
                    isBlocked && !isMobileView ? styles.blockedTimeline : ""
                }`}
            >
                {isTimelineInconsistent && (
                    <div className={styles.overlay}>
                        <Spinner size={SpinnerSize.large} label={t("timelineLoading")} ariaLabel={"Loading spinner"} />
                    </div>
                )}
                <div>
                    <div>
                        <div className={styles.lineContainer}>
                            <div>
                                <div className={styles.leftSide}></div>
                                <div className={styles.midArea}>
                                    <div
                                        className={`${styles.line} ${lineHeightClassName} ${
                                            isBlocked
                                                ? isMobileView
                                                    ? styles.mobileShortenedLine
                                                    : styles.shortenedLine
                                                : ""
                                        } ${
                                            record.signatureRequired &&
                                            isDecisionStage &&
                                            !isBlocked
                                                ? styles.manageSignaturesLine
                                                : ""
                                        } ${isMobileView ? styles.mobileLine : ""}`}
                                        style={lineStyle}
                                    ></div>
                                </div>
                                <div className={styles.rightSide}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.recordStagesTimeline}>
                    <div>
                        <div className={styles.listOfStages}>
                            <ul>
                                {timelineItems?.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {(!item.isActive || item.subEntries?.length > 0) && (
                                                <TimelineEntry
                                                    drawTasks={false}
                                                    record={record}
                                                    fields={fields}
                                                    isMobileView={isMobileView}
                                                    activeActivityIndex={activeActivityIndex}
                                                    isBlocked={isBlocked}
                                                    item={item}
                                                    index={index}
                                                ></TimelineEntry>
                                            )}
                                            {item.isActive && !item.isBlocked && !isClosed(record) && (
                                                <TimelineEntry
                                                    drawTasks={true}
                                                    record={record}
                                                    fields={fields}
                                                    isMobileView={isMobileView}
                                                    activeActivityIndex={activeActivityIndex}
                                                    isBlocked={isBlocked}
                                                    item={item}
                                                    index={index}
                                                ></TimelineEntry>
                                            )}
                                            {item.isActive && (item.isBlocked || isClosed(record)) && (
                                                <BlockingTimelineEntry
                                                    fields={fields}
                                                    iconName={blockedIcon}
                                                    iconClassName={blockedClassName}
                                                    record={record}
                                                    stageType={blockedStageType}
                                                    stepClassName={blockedStepClassName}
                                                    isMobileView={isMobileView}
                                                ></BlockingTimelineEntry>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : null;
}
