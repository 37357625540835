import * as React from "react";
import styles from "./ControlContainer.module.scss";

export interface IControlContainerProps {
    className?: string;
    children?: JSX.Element | (JSX.Element | false)[] | false;
    allowLargeWidth?: boolean;
}

export function ControlContainer(props: IControlContainerProps): JSX.Element {
    let className: string = props.className
        ? `${styles.controlContainer} ${props.className}`
        : styles.controlContainer;
    if (props.allowLargeWidth) {
        className += ` ${styles.controlContainerWide}`;
    }
    return props.children ? <div className={className}>{props.children}</div> : <></>;
}
