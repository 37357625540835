import { Doughnut } from "react-chartjs-2";
import React from "react";
/* eslint-disable @typescript-eslint/typedef */
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import { recordsProgressHeader,
    recordsProgressDescr } from "./recordsProgressConstants";
import styles from "./RecordsProgress.module.scss";
import { IRecordResult } from "../../../services/Api/executor/IApiServiceExecutor";
import { StageTypes } from "../../../providers/Constants/TermConstants";

interface IRecordsProgressProps {
    records?: IRecordResult[];
    inProgress?: boolean;
    mobileView?: boolean;
}

export function RecordsProgress({
    records,
    inProgress,
    mobileView
}: IRecordsProgressProps): JSX.Element {
    const getCountRecordsByStageType: (stageType: StageTypes) => number = React.useCallback(
        (stageType: StageTypes): number => {
            if (records) {
                // Record in the make decision stage are grouped together
                if (stageType === StageTypes.MakeDecisionGeneral) {
                    const filteredRecords = records.filter(recordItem => {
                        const stageName: string | undefined = recordItem.stage?.value;
                        if (stageName) {
                            return stageName.substr(0, stageName.indexOf(":")) === stageType;
                        } else {
                            return false;
                        }
                    });
                    return filteredRecords.length;
                }
                else {
                    // All other records
                    const countRecords: number = records.reduce(
                        (sum, recordItem) => sum + (recordItem.stage?.value === stageType ? 1 : 0),
                        0
                    );
                    return countRecords;
                }
            } else {
                return 0;
            }
        },
        [records]
    );

    const countAllRecordsByStages: number = React.useMemo((): number => {
        return (
            getCountRecordsByStageType(StageTypes.Initiate) +
            getCountRecordsByStageType(StageTypes.PrepareAndReview) +
            getCountRecordsByStageType(StageTypes.Recommend) +
            getCountRecordsByStageType(StageTypes.MakeDecisionGeneral)
        );
    }, [getCountRecordsByStageType]);

    const getStageColor: (stageType: StageTypes) => string = React.useCallback(
        (stageType: StageTypes): string => {
            let stageColor: string = "";
            switch (stageType) {
                case StageTypes.Initiate:
                    stageColor = "#0052C2";
                    break;
                case StageTypes.PrepareAndReview:
                    stageColor = "#2B88D8";
                    break;
                case StageTypes.Recommend:
                    stageColor = "#106EBE";
                    break;
                case StageTypes.MakeDecisionGeneral:
                    stageColor = "#C7E0F4";
                    break;
                case StageTypes.Closed:
                    stageColor = "#004578";
                    break;
            }
            return stageColor;
        },
        []
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const generateLegends: (chart: any) => any = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (chart: any): any => {
            const { data } = chart;
            if (data.labels.length && data.datasets.length) {
                return data.labels.map((label: string, index: number) => {
                    const labelValue: number = data.datasets[0].data[index];
                    const labelColor: string = getStageColor(label as StageTypes);
                    return {
                        index: String(index) + label,
                        text: `${label} (${labelValue})`,
                        textAlign: "left",
                        fillStyle: labelColor,
                        hidden: !labelValue,
                        lineWidth: 0,
                        datasetIndex: 0
                    };
                });
            } else {
                return [];
            }
        },
        [getStageColor]
    );

    return (
        <div
            className={[
                styles.recordsCardInfo,
                mobileView ? styles.mobileRecordsCardInfo : ""
            ].join(" ")}
        >
            <div className={styles.recordsProgressContainer}>
                <div className={styles.cardTextInfo}>
                    <div className={styles.cardHeader}>
                        <Icon iconName={"Chart"} ariaLabel={"Chart icon"}/>
                        <h3>{recordsProgressHeader}</h3>
                    </div>
                    <p>{recordsProgressDescr}</p>
                </div>
                {!inProgress ? (
                    <div className={styles.recordsProgressInfoContainer}>
                        <div
                            className={[
                                styles.chartProgressContainer,
                                mobileView ? styles.mobileChartProgressContainer : ""
                            ].join(" ")}
                            id={"record-progress"}
                        >
                            <Doughnut
                                options={{ cutoutPercentage: 70, maintainAspectRatio: false }}
                                data={{
                                    labels: [
                                        StageTypes.Initiate,
                                        StageTypes.PrepareAndReview,
                                        StageTypes.Recommend,
                                        StageTypes.MakeDecisionGeneral,
                                        // StageTypes.Closed
                                    ],
                                    datasets: [
                                        {
                                            data: [
                                                getCountRecordsByStageType(StageTypes.Initiate),
                                                getCountRecordsByStageType(
                                                    StageTypes.PrepareAndReview
                                                ),
                                                getCountRecordsByStageType(StageTypes.Recommend),
                                                getCountRecordsByStageType(
                                                    StageTypes.MakeDecisionGeneral
                                                ),
                                                // getCountRecordsByStageType(StageTypes.Closed)
                                            ],
                                            backgroundColor: [
                                                getStageColor(StageTypes.Initiate),
                                                getStageColor(StageTypes.PrepareAndReview),
                                                getStageColor(StageTypes.Recommend),
                                                getStageColor(StageTypes.MakeDecisionGeneral),
                                                // getStageColor(StageTypes.Closed)
                                            ],
                                            borderWidth: 0,
                                            label: "Records progress"
                                        }
                                    ]
                                }}
                                legend={{
                                    position: !mobileView ? "right" : "bottom",
                                    align: mobileView ? "start" : "",
                                    labels: {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        generateLabels: (chart: any) => generateLegends(chart),
                                        boxWidth: 14,
                                        padding: 20,
                                        fontColor: "#0052C2"
                                    },
                                    onHover: () => {
                                        const element = document.getElementById("record-progress");
                                        if(element) {
                                            element.style.cursor = "pointer";
                                        }
                                    },
                                    onLeave: () => {
                                        const element = document.getElementById("record-progress");
                                        if(element){ 
                                            element.style.cursor = "default";
                                        }
                                    }
                                }}
                                width={mobileView ? 285 : 300}
                                height={mobileView ? 250 : 150}
                            />
                            {countAllRecordsByStages >= 0 && (
                                <div
                                    className={[
                                        styles.countAllRecordsInChart,
                                        mobileView ? styles.mobileCountAllRecordsInChart : "",
                                    ].join(" ")}
                                >
                                    {countAllRecordsByStages}
                                </div>
                            )}
                            <p>I have {countAllRecordsByStages} open records</p>
                        </div>
                    </div>
                ) : (
                    <Spinner size={SpinnerSize.large} ariaLabel={"Loading spinner"}/>
                )}
            </div>
        </div>
    );
}

