import React from "react";
import styles from "./FormHeader.module.scss";
import { HeaderStrings } from "../../../providers/Constants/RecordWizardConstants";
import {
    selectStepNumber,
    selectTotalSteps
} from "../../../features/recordWizardWrapper/recordWizardWrapperSlice";
import { useSelector } from "react-redux";
interface IFormHeaderProps {
    strings: HeaderStrings;
}

export default function FormHeader(props: IFormHeaderProps): JSX.Element {
    const selectedStepNumber: number = useSelector(selectStepNumber);
    const selectedTotalSteps: number = useSelector(selectTotalSteps);
    const header: string = (props.strings.Header || "")
        .replace("{stepNumber}", String(selectedStepNumber))
        .replace("{totalSteps}", String(selectedTotalSteps));
    return (
        <div className={styles.headerContainer}>
            {props.strings && (
                <>
                    <h1>{header}</h1>
                    {props.strings.SubTitle && <h3>{props.strings.SubTitle}</h3>}
                    {props.strings.DotPoints && (
                        <ul>
                            {props.strings.DotPoints.map(dp => (
                                <li key={dp}>{dp}</li>
                            ))}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
}
