
//1. IMPORTS
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    Dispatch
} from "@reduxjs/toolkit";
import React from "react";
import { PrimaryButton, DefaultButton, IIconProps, Icon } from "@fluentui/react";
import ModalContainer from "../../components/FormControls/ModalContainer/ModalContainer";
import {
    RecordNotificationsHeader,
    GlobalNotificationsHeader,
    TxtChannel,
    TxtFrequency,
    AvailableChannels,
    OtherNotificationsHeader,
    TxtNotifyDecision,
    TxtNotifyDecisionDescription,
    TxtNotifyOthersDescription,
    TxtNotifyRecommendation,
    TxtNotifyRecommendationDescription,
    TxtSearch,
    TxtBtnAdd,
    TxtPeopleNotified,
    TxtWarning, TxtNoPeopleMsg,
    TxtBtnRemove,
    TxtModalContent,
    TxtModalSubmitLabel,
    TxtModalTitle
} from "../../providers/Constants/SettingsConstants";
import Select from "../../components/FormControls/Select/Select";
import Choice, { IChoiceOptionProps } from "../../components/FormControls/Choice/Choice";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import styles from "./NotificationSettings.module.scss";
import { IUserSettings, NotificationsChannel, NotificationsFrequencyType, NotificationsOption } from "../../services/Api/executor/IApiServiceExecutor";
import { 
    setPreferredChannel,
    setPreferredFrequency,
    excludedNotifications,
    addExcludedNotificationOption,
    removeExcludedNotificationOption,
    userPreferredChannel,
    userPreferredFrequency,
    usersForRecommendation,
    addUserForRecommendations,
    addUserForDecision,
    removeUserForDecision,
    removeUserForRecommendations,
    isGlobalSettingsOverriden,
    setIsGlobalSettingsOverriden,
    usersForDecision,
    proxyPreferredChannel,
    proxyPreferredFrequency,
    excludedProxyNotifications,
    setProxyPreferredChannel,
    setProxyPreferredFrequency,
    addProxyExcludedNotificationOption,
    removeProxyExcludedNotificationOption,
} from "./NotificationSettingsSlice";
import PeoplePicker from "../../components/FormControls/PeoplePicker/PeoplePicker";
import { ISimpleUser } from "../../services/Api/executor/IApiServiceExecutor";
import { useTranslation } from "react-i18next"; 

export enum NotificationSettingsScope {
    global,
    record
}

// Notification component properties
interface INotificationSettingsProps {
    scope: NotificationSettingsScope,
    disabled: boolean;
    notificationSettings?: IUserSettings | null
}

// Notifications component
export function NotificationSettings(props:INotificationSettingsProps): JSX.Element {
    const { t } = useTranslation();
    const userSelectedChannel : NotificationsChannel = useSelector(userPreferredChannel);
    const userSelectedFrequency : NotificationsFrequencyType = useSelector(userPreferredFrequency);
    const userExcludedNotifications : NotificationsOption[] = useSelector(excludedNotifications);
    const selectedUsersForRecommendation : ISimpleUser[] = useSelector(usersForRecommendation);
    const selectedusersforDecision : ISimpleUser[] = useSelector(usersForDecision);
    const selectedIsGlobalSettingsOverriden :boolean = useSelector(isGlobalSettingsOverriden);

    const proxySelectedChannel : NotificationsChannel = useSelector(proxyPreferredChannel);
    const proxySelectedFrequency : NotificationsFrequencyType = useSelector(proxyPreferredFrequency);
    const proxyExcludedNotifications : NotificationsOption[]  = useSelector(excludedProxyNotifications);
    
    // Component state
    const [selectedRecNotifiers, setSelectedRecNotifiers] = React.useState<ISimpleUser | null>(null);
    const [selectedDecNotifiers, setSelectedDecNotifiers] = React.useState<ISimpleUser | null>(null);
    const [currentRecUser, setCurrentRecUser] = React.useState<ISimpleUser | null>(null);
    const [currentDecUser, setCurrentDecUser] = React.useState<ISimpleUser | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

    const ManualFrequencyOptions:{key: NotificationsOption, text: string}[] = 
    Object.keys(NotificationsOption).filter((v) => isNaN(Number(v))).map((enumKey) => {
        return { 
            key: NotificationsOption[enumKey as keyof typeof NotificationsOption],
            text: t("notificationsOption."+enumKey) };
    });

    const AvailableFrequencies: IChoiceOptionProps[] = [
        { key: String(NotificationsFrequencyType.All), text: t("notificationsFrequencyType.All") },
        { key: String(NotificationsFrequencyType.ImportantOnly), text: t("notificationsFrequencyType.ImportantOnly") },
        { key: String(NotificationsFrequencyType.Muted), text:  t("notificationsFrequencyType.Muted") },
        { key: String(NotificationsFrequencyType.AllExceptExcluded), text:  t("notificationsFrequencyType.AllExceptExcluded")  }
    ];

    const AvailableProxyFrequencies: IChoiceOptionProps[] = [
        { key: String(NotificationsFrequencyType.All), text: t("notificationsFrequencyTypeProxy.All") },
        { key: String(NotificationsFrequencyType.ImportantOnly), text: t("notificationsFrequencyTypeProxy.ImportantOnly") },
        { key: String(NotificationsFrequencyType.Muted), text:  t("notificationsFrequencyTypeProxy.Muted") },
        { key: String(NotificationsFrequencyType.AllExceptExcluded), text:  t("notificationsFrequencyTypeProxy.AllExceptExcluded")  }
    ];

    // Properties
    const addIconProps: IIconProps = { iconName: TxtBtnAdd };
    const removeIconProps: IIconProps = { iconName: TxtBtnRemove };
    
    //Disabling eslint for one line to make use of any.
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // Function to remove user
    function removeUser(): void {
        if (currentRecUser) {
            dispatch(removeUserForRecommendations(currentRecUser));
        }
        if(currentDecUser){
            dispatch(removeUserForDecision(currentDecUser));
        }
        dismissModal();
    }

    // Closes the modal window.
    const dismissModal = () => {
        setDeleteModalOpen(false);
        setCurrentDecUser(null);
        setCurrentRecUser(null);
    };

    const isManualOptionsDisabled: boolean = React.useMemo(()=>userSelectedFrequency !== NotificationsFrequencyType.AllExceptExcluded,[userSelectedFrequency]);
    const isProxyManualOptionsDisabled: boolean = React.useMemo(()=>proxySelectedFrequency !== NotificationsFrequencyType.AllExceptExcluded,[proxySelectedFrequency]);
    const isOverrideAvailable: boolean = React.useMemo(()=> props.scope === NotificationSettingsScope.record, [props.scope]);
    const isNotificationsSectionDisabled: boolean = React.useMemo(
        ()=> isOverrideAvailable && !selectedIsGlobalSettingsOverriden, 
        [isOverrideAvailable, selectedIsGlobalSettingsOverriden]
    );

    const isNotificationsProxySectionDisabled: boolean = React.useMemo(
        ()=> isOverrideAvailable && !selectedIsGlobalSettingsOverriden, 
        [isOverrideAvailable, selectedIsGlobalSettingsOverriden]
    );

    return (
        <div>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>
                    {
                        (props.scope === NotificationSettingsScope.global) 
                            ? GlobalNotificationsHeader
                            : RecordNotificationsHeader
                    }
                </h2>
                <>
                    {props.scope === NotificationSettingsScope.global ? t("globalNotificationsDescription") : t("recordNotificationsDescription")}
                </>
            </div>
            <div className={styles.notificationsContainer}>                
                <div className={styles.myNotificationsContainer}>                                                   
                    <div className={styles.sectionContent}>
                        {isOverrideAvailable &&
                            <Checkbox
                                name={"Change my notifications for this record"} 
                                required={false} 
                                className={styles.overrideCheckbox}
                                defaultChecked={selectedIsGlobalSettingsOverriden}
                                checked = {selectedIsGlobalSettingsOverriden}               
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                                    checked?: boolean | undefined) => {
                                    dispatch(setIsGlobalSettingsOverriden(checked || false));            
                                }}
                                disabled={props.disabled}                   
                            />
                        }
                        <Select
                            name={TxtChannel}
                            options={AvailableChannels}
                            required={false}
                            disabled={isNotificationsSectionDisabled || props.disabled}
                            defaultSelectedKey={String(userSelectedChannel)}
                            onChange={option => {
                                if(option!=undefined)
                                {
                                    dispatch(setPreferredChannel(Number(option.key)));
                                }
                            }}
                            selectedKey={String(userSelectedChannel)}
                        />                        
                        <Choice name={TxtFrequency} options={AvailableFrequencies} required={false} className={styles.availableFrequencies} 
                            defaultSelectedKey = {String(userSelectedFrequency)}
                            disabled={isNotificationsSectionDisabled || props.disabled}
                            onChange={(option) => {
                                if(option){
                                    dispatch(setPreferredFrequency(Number(option.key)));
                                }
                            }}                        
                        />
                        {ManualFrequencyOptions.map(opt=>{
                            return (
                                <Checkbox
                                    key={opt.key+opt.text}
                                    name={opt.text} 
                                    required={false}
                                    defaultChecked
                                    className={styles.manualFrequencyOptions} 
                                    disabled={isManualOptionsDisabled || isNotificationsSectionDisabled || props.disabled} 
                                    checked = {userExcludedNotifications.indexOf(opt.key) < 0}               
                                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                                        checked?: boolean | undefined) => {
                                        if(checked == true)
                                        {
                                            dispatch(removeExcludedNotificationOption(opt.key));
                                        }        
                                        else
                                        {
                                            dispatch(addExcludedNotificationOption(opt.key));
                                        }                        
                                    }}                            
                                />);
                        })}
                    </div>
                </div>

                { (props.scope === NotificationSettingsScope.global) && <>
                  
                    <div className={styles.expandedHeader}>
                        <h2 className={styles.expandedHeaderText}>
                            {t("proxyNotificationsHeader")}
                        </h2>
                        <p>
                            {t("proxyNotificationsDescription")}
                        </p>

                        <div>    
                            <Select
                                name={TxtChannel}
                                options={AvailableChannels}
                                required={false}
                                disabled={isNotificationsProxySectionDisabled || props.disabled}
                                defaultSelectedKey={String(proxySelectedChannel)}
                                onChange={option => {
                                    if(option!=undefined)
                                    {
                                        dispatch(setProxyPreferredChannel(Number(option.key)));
                                    }
                                }}
                                selectedKey={String(proxySelectedChannel)}
                            />                        
                            <Choice name={TxtFrequency} options={AvailableProxyFrequencies} required={false} className={styles.availableFrequencies} 
                                defaultSelectedKey = {String(proxySelectedFrequency)}
                                disabled={isNotificationsProxySectionDisabled || props.disabled}
                                onChange={(option) => {
                                    if(option){
                                        dispatch(setProxyPreferredFrequency(Number(option.key)));
                                    }
                                }}                        
                            />
                            {ManualFrequencyOptions.map(opt=>{
                                return (
                                    <Checkbox
                                        key={opt.key+opt.text}
                                        name={opt.text} 
                                        required={false}
                                        defaultChecked
                                        className={styles.manualFrequencyOptions} 
                                        disabled={isProxyManualOptionsDisabled || isNotificationsProxySectionDisabled || props.disabled} 
                                        checked = {proxyExcludedNotifications.indexOf(opt.key) < 0}               
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                                            checked?: boolean | undefined) => {
                                            if(checked == true)
                                            {
                                                dispatch(removeProxyExcludedNotificationOption(opt.key));
                                            }        
                                            else
                                            {
                                                dispatch(addProxyExcludedNotificationOption(opt.key));
                                            }                        
                                        }}                            
                                    />);
                            })}</div>
                    </div>
                </> }
           
                { (props.scope === NotificationSettingsScope.record) &&
            <div className={styles.otherNotificationsContainer}>
                <div className={styles.sectionHeader}>{OtherNotificationsHeader}</div>
                <div className={styles.notifyOthersDescription}>
                    {TxtNotifyOthersDescription}
                </div>
                <div className={styles.sectionContent}>
                    <div className={styles.subHeader}>{TxtNotifyRecommendation}</div>
                    <div className={styles.recommendationDescription}>{TxtNotifyRecommendationDescription}</div>
                    <div className={styles.peoplePickerContainer}>
                        <div className={styles.peoplePicker}>
                            <PeoplePicker
                                selectedUsers={selectedRecNotifiers ? [selectedRecNotifiers] : undefined}
                                onChange={(users: ISimpleUser[] | undefined) => {
                                    setSelectedRecNotifiers((users || [])[0]);                                    
                                }}
                                label={TxtSearch}
                                disabled={props.disabled}                               
                            />
                        </div>

                        <PrimaryButton
                            text={TxtBtnAdd}
                            onClick={() => {
                                if (selectedRecNotifiers) {
                                    if (selectedUsersForRecommendation.map(u => u.siteUserId).indexOf(selectedRecNotifiers.siteUserId) < 0) {                                        
                                        dispatch(addUserForRecommendations(selectedRecNotifiers));                                       
                                    }
                                }
                                setSelectedRecNotifiers(null);
                            }}
                            allowDisabledFocus
                            iconProps={addIconProps}
                            className={styles.addButton}
                            disabled={!selectedRecNotifiers || props.disabled}
                        />
                    </div>
                    <div className={styles.selectedRecommendationHeader}>{TxtPeopleNotified}</div>
                    <div className={styles.selectedRecommendations}>
                        {selectedUsersForRecommendation.length<=0 && <div className={styles.noPeopleMsg}><Icon className={styles.noPeopleIcon} iconName={TxtWarning} /><span>{TxtNoPeopleMsg}</span></div>}
                        {selectedUsersForRecommendation && selectedUsersForRecommendation.length>0 &&
                            <div className={styles.peopleListContainer}>
                                {selectedUsersForRecommendation.map(e => (
                                    <div className={styles.selectedPeople} key={e.siteUserId}>                               
                                        {/* <div><Icon iconName={TxtUserEdit} className={styles.editIcon}></Icon></div>                                     */}
                                        <div className={styles.personName}>{e.displayName}</div>                         
                                        <div >
                                            <DefaultButton
                                                text={TxtBtnRemove}
                                                onClick={() => {                                                   
                                                    setCurrentRecUser(e);
                                                    setDeleteModalOpen(true);
                                                }}
                                                allowDisabledFocus
                                                disabled={props.disabled}
                                                iconProps={removeIconProps}
                                                className={styles.removeButtonStyling}
                                            />
                                        </div>
                                    </div>                                                                    
                                ))
                                }                         
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.sectionContent2}>
                    <div className={styles.subHeader}>{TxtNotifyDecision}</div>
                    <div className={styles.decisionDescription}>
                        {TxtNotifyDecisionDescription}
                    </div>
                    <div className={styles.peoplePickerContainer}>
                        <div className={styles.peoplePicker}>
                            <PeoplePicker
                                selectedUsers={selectedDecNotifiers ? [selectedDecNotifiers] : undefined}
                                onChange={(users: ISimpleUser[] | undefined) => {
                                    setSelectedDecNotifiers((users || [])[0]);                                    
                                }}
                                label={TxtSearch}
                                disabled={props.disabled}
                            />
                        </div>

                        <PrimaryButton
                            text={TxtBtnAdd}
                            onClick={() => {
                                if (selectedDecNotifiers) {
                                    if (selectedusersforDecision.map(u => u.siteUserId).indexOf(selectedDecNotifiers.siteUserId) < 0) {
                                        dispatch(addUserForDecision(selectedDecNotifiers));
                                    }
                                }
                                setSelectedDecNotifiers(null);
                            }}
                            allowDisabledFocus
                            // disabled={preventNext}
                            iconProps={addIconProps}
                            className={styles.addButton}
                            disabled={!selectedDecNotifiers || props.disabled}
                        />
                    </div>
                    <div className={styles.selectedDecisionHeader}>{TxtPeopleNotified}</div>
                    <div className={styles.selectedDecisions}>
                        {selectedusersforDecision.length<=0 && <div className={styles.noPeopleMsg}><Icon className={styles.noPeopleIcon} iconName={TxtWarning} /><span>{TxtNoPeopleMsg}</span></div>}
                        {selectedusersforDecision && selectedusersforDecision.length>0 &&
                            <div className={styles.peopleListContainer}>
                                {selectedusersforDecision.map(e => (
                                    <div className={styles.selectedPeople} key={e.siteUserId}>                               
                                        {/* <div><Icon iconName={TxtUserEdit} className={styles.editIcon}></Icon></div>                                     */}
                                        <div className={styles.personName}>{e.displayName}</div>                         
                                        <div >
                                            <DefaultButton
                                                text={TxtBtnRemove}
                                                onClick={() => {                                                   
                                                    setCurrentDecUser(e);
                                                    setDeleteModalOpen(true);
                                                }}
                                                allowDisabledFocus
                                                disabled={props.disabled}
                                                iconProps={removeIconProps}
                                                className={styles.removeButtonStyling}
                                            />
                                        </div>
                                    </div>                                                                    
                                ))
                                }                         
                            </div>
                        }
                    </div>
                </div>
            </div>
                }
            </div>
            <ModalContainer
                isOpen={deleteModalOpen}
                title={TxtModalTitle}
                submitLabel={TxtModalSubmitLabel}
                onCancel={dismissModal}
                onSubmit={removeUser}
            >
                <div>{TxtModalContent}</div>
            </ModalContainer>
        </div>
    );

}