//1. IMPORTS
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    getWizardCreateRecordDataAsync,
    selectAllRecordTypes,
    selectSelectedRecordCategory,
    selectSelectedRecordType,
    selectRecordTypesByCategory,
    setSelectedRecordType as setSelectedRecordType,
    setSelectedRecordCategory,
    getRecordTypeWorkflowDiagramAsync
} from "./wizardCreateRecordSlice";
import styles from "./WizardCreateRecord.module.scss";
import { setPreventNext, setWizardType } from "../recordWizardWrapper/recordWizardWrapperSlice";
import { IContentType, IContentTypeField } from "../../services/Api/executor/IApiServiceExecutor";
import {
    createRecordHeaderStrings,
    fieldLabelRecordSubTypeField,
    fieldLabelRecordTypeField
} from "../../providers/Constants/RecordWizardConstants";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import { resetWizardResponseInformation } from "../wizardResponseInformation/wizardResponseInformationSlice";
import { resetWizardCorrespondenceFiles } from "../wizardCorrespondenceFiles/wizardCorrespondenceFilesSlice";
import { setRecord } from "../manageRecordWrapper/manageRecordWrapperSlice";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { setActiveFields, setAllFields, setInactiveFields } from "../wizardRecordInformation/wizardRecordInformationSlice";

const getISelectOptionPropsFromIterm = (term: IContentType): ISelectOptionProps => {
    return {
        key: term.rowKey || "",
        text: term.rowKey || ""
    };
};

export function WizardCreateRecord(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const recordTypeTerms: IContentType[] = useSelector(selectAllRecordTypes);
    const recordTypesByCategory: IContentType[] = useSelector(selectRecordTypesByCategory);
    const selectedRecordCategory: string | null = useSelector(selectSelectedRecordCategory);
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);

    // 3. DEFINE COMPONENT STATE
    // const [subRecordDisabled, setSubRecordDisabled] = useState(true);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const checkMandatoryFieldsComplete: (statement: boolean) => void = React.useCallback(
        (statement: boolean): void => {
            if (statement) {
                dispatch(setPreventNext(false));
            } else {
                dispatch(setPreventNext(true));
            }
        },
        [dispatch]
    );

    const prefetchFields: (recordType: string | undefined) => void = React.useCallback(
        async (recordType: string | undefined): Promise<void> => {
            if (recordType) {
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const fields: IContentTypeField[] | null = await apiService.GetContentTypeFields(recordType);
                dispatch(setActiveFields((fields as IContentTypeField[])?.filter(v=>v.isActive)));
                dispatch(setInactiveFields((fields as IContentTypeField[])?.filter(v=>!v.isActive)));
                dispatch(setAllFields((fields as IContentTypeField[])));
            }
        },
        [dispatch]
    );

    const resetRecordWizardState = ():void => {
        dispatch(resetWizardResponseInformation());
        dispatch(resetWizardCorrespondenceFiles());
        dispatch(setRecord(null));
        dispatch(setActiveFields(null));
        dispatch(setInactiveFields(null));
        dispatch(setAllFields(null));
    };

    // 5. DEFINE COMPONENT HOOKS
    React.useEffect(() => {
        dispatch(getWizardCreateRecordDataAsync());
        checkMandatoryFieldsComplete(!!selectedRecordCategory && !!selectedRecordType);
    }, [checkMandatoryFieldsComplete, dispatch, selectedRecordCategory, selectedRecordType]);
    
    React.useEffect(() => {
        if (selectedRecordType) {
            dispatch(getRecordTypeWorkflowDiagramAsync(selectedRecordType.rowKey));
        }
    }, [dispatch, selectedRecordType]);

    const recordOptions: ISelectOptionProps[] =
        recordTypeTerms
            ?.map(v => v.category)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map(v => {
                return { key: v || "", text: v || "" };
            }) || [];

    const subRecordOptions: ISelectOptionProps[] =
        recordTypesByCategory?.map(getISelectOptionPropsFromIterm) || [];

    // 6. RETURN RENDER TEMPLATE

    return (
        <>
            <FormHeader strings={createRecordHeaderStrings} />
            <div className={styles.fieldsContainer}>
                <FieldWrapper>
                    <Select
                        name={fieldLabelRecordTypeField}
                        options={recordOptions}
                        required={true}
                        disabled={!recordOptions || recordOptions.length < 1}
                        defaultSelectedKey={selectedRecordCategory || undefined}
                        showAsLoading={recordOptions.length < 1}
                        onChange={option => {
                            resetRecordWizardState();
                            dispatch(setSelectedRecordCategory(option?.key));
                        }}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Select
                        name={fieldLabelRecordSubTypeField}
                        options={subRecordOptions}
                        required={true}
                        disabled={
                            !selectedRecordCategory || !subRecordOptions || subRecordOptions.length < 1
                        }
                        defaultSelectedKey={selectedRecordType?.rowKey || undefined}
                        onChange={option => {
                            dispatch(setSelectedRecordType(option?.key));
                            const selectedContentType: IContentType | undefined = recordTypeTerms.find(v => v.rowKey === option?.key);
                            dispatch(setWizardType(selectedContentType));
                            checkMandatoryFieldsComplete(!!option);
                            prefetchFields(option?.key);
                        }}
                    />
                </FieldWrapper>
            </div>
        </>
    );
}
