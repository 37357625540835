// 1. Imports
import React from "react";
import {
    PrimaryButton, DefaultButton, Text, Spinner, SpinnerSize
} from "@fluentui/react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import styles from "./SettingsPage.module.scss";
import {
    Link
} from "react-router-dom";
import {
    AppRoutes
} from "../../app/Constants";
import {
    BreadcrumbSettings,
    ProxyButtonIcon,
    ProxyButtonText,  
    TxtBtnCancel,
    TxtBtnSave,
    ProxyTableHeading,
    ProxyTableHeadingHeader,
    ProxyAddHeading,
    SettingsHeader
} from "../../providers/Constants/SettingsConstants";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import ProxiesList from "../../components/FormControls/ProxiesList/ProxiesList";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import {
    IProxyEmailTemplates
} from "../../services/Api/executor/IApiServiceExecutor";
import { 
    userPreferredChannel, 
    userPreferredFrequency,
    getUserSettings,
    setUserSettings,
    isGlobalSettingsOverriden,
    excludedNotifications, 
    userInitialSettings,
    proxyPreferredChannel,
    proxyPreferredFrequency,
    excludedProxyNotifications} from "../NotificationSettings/NotificationSettingsSlice";
import { updateUserSettingsAsync } from "./SettingsPageSlice";
import { selectIsMobile, setAppTitle } from "../../app/globalSlices/contextSlice";
import {
    selectCurrentUser
} from "./../../app/globalSlices/contextSlice";
// import { NotificationSettings, NotificationSettingsScope } from "../NotificationSettings/NotificationSettings";
import { IUserSettings, IUser, NotificationsChannel, NotificationsFrequencyType, NotificationsOption } from "../../services/Api/executor/IApiServiceExecutor";
import { NotificationSettings, NotificationSettingsScope } from "../NotificationSettings/NotificationSettings";
import ArrayHelper from "../../helpers/ArrayHelper";
import { useTranslation } from "react-i18next";

// 2. DEFINE PROPS
export interface ISettingsPageProps {
    children?: JSX.Element | JSX.Element[];
}

export function SettingsPage(): JSX.Element {
    const selectedCurrentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const initialSettings: IUserSettings | undefined = useSelector(userInitialSettings);
    const userSelectedPreferredChannel: NotificationsChannel = useSelector(userPreferredChannel);
    const userSelectedPreferredFrequency: NotificationsFrequencyType = useSelector(userPreferredFrequency);
    const userSelectedExcludedNotifications: NotificationsOption[] = useSelector(excludedNotifications);
    //proxy
    const proxySelectedPreferredChannel: NotificationsChannel = useSelector(proxyPreferredChannel);
    const proxySelectedPreferredFrequency: NotificationsFrequencyType = useSelector(proxyPreferredFrequency);
    const proxySelectedExcludedNotifications: NotificationsOption[] = useSelector(excludedProxyNotifications);

    const userSelectedIsGlobalSettingsOverriden: boolean = useSelector(isGlobalSettingsOverriden);
    const isMobile: boolean = useSelector(selectIsMobile);
    const { t } = useTranslation();

    // GET DISPATCH
    // Disabling eslint for one line to make use of any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // DEFINE COMPONENT STATE
    const [errorMessage, setErrorMessage] = React.useState("");
    const [updating, setUpdating] = React.useState(false);
    const [proxyEmailTemplates, setProxyEmailTemapltes] = React.useState<IProxyEmailTemplates | null>();

    React.useEffect(() => {
        dispatch(getUserSettings());
        dispatch(setAppTitle(t("settingsPageTitle")));
    }, [dispatch, t]);

    React.useEffect(() => {
        if(!proxyEmailTemplates){
            (async () =>{
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const templates: IProxyEmailTemplates | null = await apiService.GetProxyEmailTemplates();
                setProxyEmailTemapltes(templates);
            })();
        }
    },[proxyEmailTemplates, setProxyEmailTemapltes]);

    const userNotificationSettings: IUserSettings = React.useMemo(() => {
        return {
            notificationsChannel: userSelectedPreferredChannel,
            notificationsFrequencyType: userSelectedPreferredFrequency,
            excludedNotifications: userSelectedExcludedNotifications,
            isOverridenOnSubScope: userSelectedIsGlobalSettingsOverriden,
            proxyNotificationsChannel: proxySelectedPreferredChannel,
            proxyNotificationsFrequencyType: proxySelectedPreferredFrequency,
            proxyExcludedNotifications: proxySelectedExcludedNotifications,
            hasAccessToAIFeatures: false,
        };
    },[
        userSelectedPreferredChannel, 
        userSelectedPreferredFrequency,
        userSelectedExcludedNotifications,
        userSelectedIsGlobalSettingsOverriden,
        proxySelectedPreferredChannel,
        proxySelectedPreferredFrequency,
        proxySelectedExcludedNotifications
    ]);

    const anyChangesApplied: boolean = React.useMemo(() => { 
        return !!initialSettings 
        && ((initialSettings.isOverridenOnSubScope != userSelectedIsGlobalSettingsOverriden)
        || (initialSettings.notificationsFrequencyType !== userSelectedPreferredFrequency
            || initialSettings.notificationsChannel !== userSelectedPreferredChannel
            || !ArrayHelper.isEqualAsJSON(initialSettings.excludedNotifications, userSelectedExcludedNotifications))
        || initialSettings.proxyNotificationsFrequencyType !== proxySelectedPreferredFrequency
        || initialSettings.proxyNotificationsChannel !== proxySelectedPreferredChannel
        || !ArrayHelper.isEqualAsJSON(initialSettings.proxyExcludedNotifications, proxySelectedExcludedNotifications));
    },[
        initialSettings, 
        proxySelectedExcludedNotifications, 
        proxySelectedPreferredChannel, 
        proxySelectedPreferredFrequency, 
        userSelectedExcludedNotifications, 
        userSelectedIsGlobalSettingsOverriden, 
        userSelectedPreferredChannel, 
        userSelectedPreferredFrequency]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return !initialSettings;
    },[initialSettings]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    const onUpdateClick: () => void = React.useCallback(() => {
        setUpdating(true);
        setErrorMessage("");
        dispatch(updateUserSettingsAsync(
            userNotificationSettings,
            {                                
                onSuccess: () => {
                    setUpdating(false);
                    dispatch(setUserSettings(userNotificationSettings));
                    // <Redirect to={AppRoutes.home} />;
                },
                onError: () => {                                        
                    setUpdating(false);
                }
            }));
                 
    },[
        dispatch,
        userNotificationSettings
    ]);

    return (
        <>
            <Breadcumbs pageName={BreadcrumbSettings} />
            <div className={styles.settingsPage}>
                <div className={styles.pageContainer}>
                    <h1 className={styles.pageHeader}>{SettingsHeader}</h1>
                    <div className={styles.notificationsHeader}>
                        <NotificationSettings scope={NotificationSettingsScope.global} disabled={formIsBlocked}></NotificationSettings>
                    </div>
                    <div className={styles.bodyContainer}>
                        <div>
                            <h2 className={styles.heading}>{ProxyTableHeadingHeader}</h2>
                            {
                                selectedCurrentUser && selectedCurrentUser.myProxies &&
                                <>
                                    <h3 className={styles.subHeading}>{ProxyTableHeading}</h3>
                                    <ProxiesList users={selectedCurrentUser.myProxies} emailTemplates={proxyEmailTemplates} isMobile={isMobile}/>
                                </>
                            }
                        </div>
                        <h3 className={styles.subHeading}>{ProxyAddHeading}</h3>
                        <a href={proxyEmailTemplates?.requestNewProxy || t("proxyButtonMailToLinkDefault")}>
                            <DefaultButton
                                className={styles.proxyButton}
                                iconProps={{ iconName: ProxyButtonIcon, className: styles.iconBlue }}
                                ariaLabel={`${ProxyButtonText} button`}
                            >
                                {ProxyButtonText}
                            </DefaultButton>
                        </a>
                    </div>
                    <div className={styles.buttonContainer}>
                        <PrimaryButton                            
                            onClick={onUpdateClick}
                            allowDisabledFocus
                            disabled={!updateBtnIsActive}
                            className={styles.primaryButtonStyling}
                            ariaLabel={`${TxtBtnSave} button`}
                        >
                            <Text className={styles.text}>{TxtBtnSave}</Text>
                            {updating && (
                                <Spinner className={styles.buttonSpinner} size={SpinnerSize.small} />
                            )}
                        </PrimaryButton>                        
                        <Link to={AppRoutes.home}>
                            <DefaultButton
                                text={TxtBtnCancel}                                
                                allowDisabledFocus
                                className={styles.buttonStyling}
                                ariaLabel={`${TxtBtnCancel} button`}
                            />
                        </Link>                        
                    </div>
                    <div>{errorMessage}</div>
                </div>
            </div>
        </>
    );
}
