// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IRecordResult,
    IRecordResultColumn
} from "../../services/Api/executor/IApiServiceExecutor";
import { IRefiner } from "../../model/Refiner";
import { RootState } from "../../app/store";

// 2. TYPES AND INTERFACES

// 3. FEATURE STATE
export interface IOrderBy {
    fieldName: string;
    isDesc: boolean;
}
export interface RecordsTableState {
    records: IRecordResult[] | undefined;
    refiners: IRefiner[] | undefined;
    searchText: string | undefined;
    orderBy: IOrderBy | undefined;
    columns: IRecordResultColumn[] | undefined;
}

// 4. FEATURE INITIAL STATE
export const initialState: RecordsTableState = {
    records: undefined,
    columns: undefined,
    refiners: undefined,
    searchText: undefined,
    orderBy: undefined
};

// 5. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const recordsTableSlice = createSlice({
    name: "recordsTable",
    initialState,
    reducers: {
        resetRecordsTable: (state: RecordsTableState) => {
            state.records = initialState.records;
            state.searchText = initialState.searchText;
            state.refiners = initialState.refiners;
            state.orderBy = initialState.orderBy;
            state.columns = initialState.columns;
        },
        setRecords: (
            state: RecordsTableState,
            action: PayloadAction<IRecordResult[] | undefined>
        ) => {
            state.records = action.payload;
        },
        setColumns: (
            state: RecordsTableState,
            action: PayloadAction<IRecordResultColumn[] | undefined>
        ) => {
            state.columns = action.payload;
        },
        setSearchText: (state: RecordsTableState, action: PayloadAction<string | undefined>) => {
            state.searchText = action.payload;
        },
        setRefiners: (state: RecordsTableState, action: PayloadAction<IRefiner[] | undefined>) => {
            state.refiners = action.payload;
        },
        setOrderBy: (state: RecordsTableState, action: PayloadAction<IOrderBy | undefined>) => {
            state.orderBy = action.payload;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    resetRecordsTable,
    setRecords,
    setColumns,
    setSearchText,
    setRefiners,
    setOrderBy
} = recordsTableSlice.actions;
// 5. ASYNCRONOUS ACTIONS
// 6. SELECTORS
export const selectRecords = (state: RootState): IRecordResult[] | undefined =>
    state.recordsTable.records;
export const selectColumns = (state: RootState): IRecordResultColumn[] | undefined =>
    state.recordsTable.columns;
export const selectSearchText = (state: RootState): string | undefined =>
    state.recordsTable.searchText;
export const selectRefiners = (state: RootState): IRefiner[] | undefined =>
    state.recordsTable.refiners;
export const selectOrderBy = (state: RootState): IOrderBy | undefined => state.recordsTable.orderBy;
// 9. EXPORT REDUCER
export default recordsTableSlice.reducer;
