import * as React from "react";
import { Checkbox as OCheckbox, ICheckbox } from "@fluentui/react";
import styles from "./Checkbox.module.scss";
import { ControlContainer } from "../ControlContainer/ControlContainer";
import SPHelper from "../../../helpers/SharePointHelper";

export interface ICheckboxProps {
    name: string;
    checked?: boolean;
    required: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
    disabled?: boolean;
    className?: string;
    defaultChecked?:boolean;
}

export default class Checkbox extends React.Component<ICheckboxProps> {
    private componentRef: ICheckbox | undefined;

    public getValue(): boolean {
        return this.componentRef ? this.componentRef.checked : false;
    }

    public render(): React.ReactElement<ICheckboxProps> {
        const key: string = `${SPHelper.GetSafeFilename(this.props.name)}CheckboxControl`;
        return (
            <ControlContainer>
                <OCheckbox
                    key={key}
                    name={this.props.name}
                    componentRef={r => (this.componentRef = r || undefined)}
                    label={this.props.name}
                    className={`${styles.checkbox} ${this.props.className || ""}`}
                    ariaLabel={`${this.props.name} checkbox control`}
                    onChange={this.props.onChange}
                    defaultChecked={this.props.defaultChecked}
                    checked={this.props.checked}
                    disabled={this.props.disabled}
                />
            </ControlContainer>
        );
    }
}
