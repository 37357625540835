import React from "react";
import { getFieldAsync, getRecordAsync, selectCurrentRecord, selectIsLoading, updateRecordInformationAsync } from "../manageRecordWrapperSlice";
import { useDispatch, useSelector } from "react-redux";
import Breadcumbs from "../../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { useHistory, useParams } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
import { DefaultButton, PrimaryButton, SearchBox, Selection, Separator, Spinner, SpinnerSize, Text } from "@fluentui/react";
import { selectIsMobile, selectProxy, setAppTitle } from "../../../app/globalSlices/contextSlice";
import styles from "./ManageRelatedRecords.module.scss";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../../services/Api/IApiService";
import { SearchFieldPlaceholder, SearchFieldNoSearchTermText } from "../../../providers/Constants/FormControlConstants";
import Table from "../../../components/FormControls/Table/Table";
import { ITableColumn } from "../../../components/FormControls/Table/ITableColumn";
import { buildRecordTableColumn, buildRefinableFilterString } from "../../recordsTable/RecordsTableCommon";
import { IOrderBy } from "../../recordsTable/recordsTableSlice";
import { 
    IGetRecordsRequest, 
    IRecord, 
    IRecordColumn, 
    IRecordResult, 
    IRecordResultColumn, 
    IRecordResults, 
    RecordViewType 
} from "../../../services/Api/executor/IApiServiceExecutor";
import { AddNewRelatedRecordsHeader, RecentlyAddedRelatedRecordsHeader } from "../../../providers/Constants/SettingsConstants";
import { IRefiner } from "../../../model/Refiner";
import { ManageRecordRelatedRecordsFilters } from "../ManageRecordRelatedRecords";
import { breadcrumbAddNewRelatedRecord } from "../../../providers/Constants/RecordWizardConstants";
import Cards from "../../../components/FormControls/Cards/Cards";
import { useTranslation } from "react-i18next";

export function ManageRelatedRecords(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const { t } = useTranslation();
    const { recordId } = useParams<{ recordId: string }>();
    const isMobile: boolean = useSelector(selectIsMobile);
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const isLoading: boolean = useSelector(selectIsLoading);
    const proxy: string | undefined = useSelector(selectProxy);

    //NEW
    const [records, setRecords] = React.useState<IRecordResult[] | undefined>([]);
    const [columns, setColumns] = React.useState<IRecordResultColumn[] | undefined>();
    const [orderBy, setOrderBy] = React.useState<IOrderBy | undefined>();
    const [newItemsSelectedCount, setNewItemsSelectedCount] = React.useState<number>(0);
    const [newItemIndexesSelected, setNewItemIndexesSelected] = React.useState<number[]>([]);
    const [recordsInProgress, setRecordsInProgress] = React.useState<boolean>(false);
    const [srchText, setSrchText] = React.useState<string | undefined>("");

    //RELATED
    const [relRecords, setRelRecords] = React.useState<IRecordResult[] | undefined>();
    const [relatedRecordIds, setRelatedRecordIds] = React.useState<string[] | undefined>();
    const [relColumns, setRelColumns] = React.useState<IRecordResultColumn[] | undefined>();
    const [relItemsSelectedCount, setRelItemsSelectedCount] = React.useState<number>(0);
    const [relItemIndexesSelected, setRelItemIndexesSelected] = React.useState<number[]>([]);
    const [refiners, setRefiners] = React.useState<IRefiner[] | undefined>();
    const [relRecordsInProgress, setRelRecordsInProgress] = React.useState<boolean>(false);    
  
    //COMMON
    const [updateInProgress, setUpdateInProgress] = React.useState<boolean | undefined>(false);
    const [error, setError] = React.useState<Error | undefined>();

    //VARS AND FUNCS
    const resultsRecordsIsLoading: boolean = (isLoading || recordsInProgress) && !!srchText;
    const relatedRecordsIsLoading: boolean = isLoading || relRecordsInProgress;
    const saveBtnDisabled: boolean = currentRecord?.relatedRecords === relatedRecordIds?.join(";") || !!updateInProgress;
    const cancelBtnDisabled: boolean = !!updateInProgress;
    const recordsToShow: IRecordResult[] | undefined = React.useMemo(() => {
        if(!records){ return undefined;}
        const result: IRecordResult[] = records.filter(r => !(relRecords || []).some(relR => relR.recordId?.value === r.recordId?.value) && r.recordId?.value !== recordId);
        return result.slice(0, 10);
    },[records, relRecords, recordId]);

    const searchResultsString: string = React.useMemo(() => {
        if (recordsInProgress) {
            return "Results: ...";
        } else if ((records && records.length > 10) && (recordsToShow && recordsToShow.length === 10)) {
            return "Only showing top 10 results";
        } else {
            return `Results: ${(recordsToShow || []).length}`;
        }
    },[records, recordsToShow, recordsInProgress]);

    function flushNewSelections(): void {
        setNewItemsSelectedCount(0);
        setNewItemIndexesSelected([]);
    }

    function flushRelSelections(): void {
        setRelItemsSelectedCount(0);
        setRelItemIndexesSelected([]);
    }

    function onSearchChange(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newSearchText: any
    ): void {
        setSrchText(newSearchText);
        if(!newSearchText){ flushNewSelections(); }
    }

    function onSearchClear(): void {
        setSrchText("");
        flushNewSelections();
    }

    function onOrderChange(fieldName: string | undefined, isDesc: boolean | undefined): void {
        setOrderBy(fieldName ? { fieldName: fieldName, isDesc: !!isDesc } : undefined);
    }

    //CALLBACKS
    const onAddSelectedRecordsClick: (idxs?: number[]) => void = React.useCallback((idxs?: number[]) => {
        const selectedIndexes: number[] = idxs || newItemIndexesSelected;
        if(selectedIndexes && recordsToShow && recordsToShow.length){
            const newRelatedRecordIds: string[] = [];
            for(const idx of selectedIndexes){
                const recordId: string | undefined = recordsToShow[idx] && recordsToShow[idx].recordId?.value;
                if(recordId){ newRelatedRecordIds.push(recordId); }
            }

            if(newRelatedRecordIds.length){
                const allRelatedRecordIds: string[] = [...(relatedRecordIds || []), ...newRelatedRecordIds];
                setRelatedRecordIds([... new Set(allRelatedRecordIds)]);
            }
        }
    },[newItemIndexesSelected, recordsToShow, relatedRecordIds]);

    const onRemoveSelectedRecordsClick: (idxs?: number[]) => void = React.useCallback((idxs?: number[]) => {
        const selectedIndexes: number[] = idxs || relItemIndexesSelected;
        if(selectedIndexes && relRecords){
            const newRelatedRecords: IRecordResult[] = [...(relRecords || [])];
            for(const idx of selectedIndexes){
                newRelatedRecords.splice(idx, 1);
            }
            const newRelatedRecordIds: string[] = newRelatedRecords.map(r => r.recordId?.value || "");
            setRelatedRecordIds([... new Set(newRelatedRecordIds)]);
            flushRelSelections();
        }
    },[relItemIndexesSelected, relRecords]);

    const onSaveClick: () => void = React.useCallback(() => {
        if(!saveBtnDisabled){
            setUpdateInProgress(true);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: recordId,
                        relatedRecords: (relatedRecordIds || []).join(";")
                    },
                    {
                        onSuccess: () => {
                            setUpdateInProgress(false);
                            history.goBack();
                        },
                        onError: e => {
                            setError(e);
                            setUpdateInProgress(false);
                        }
                    }
                )
            );
        }
    },[dispatch, recordId, relatedRecordIds, saveBtnDisabled, history]);

    const getTableData: (searchTerm?: string, searfilters?: string[]) => Promise<IRecordResults | null> = React.useCallback(async (
        searchTerm?: string, 
        filters?: string[]
    ): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const requestBody: IGetRecordsRequest = {
            managedProperties:ManageRecordRelatedRecordsFilters.managedProperties,
            getRefiners: false,
            getResults: true,
            recordViewType: RecordViewType.All,
            orderByDescending: orderBy?.isDesc,
            orderByManagedProperty: orderBy?.fieldName,
            proxy: proxy,
            searchTerm: searchTerm,
            saveConfiguration: true,
            filters: filters
        };

        const result: IRecordResults | null = await apiService.GetRecords(requestBody);
        return result;
    }, [orderBy, proxy]);

    const onSelectedNewRecordsCountChange: (s: Selection) => void = React.useCallback(
        (selection: Selection) => {
            setNewItemsSelectedCount(selection.getSelectedCount());
            setNewItemIndexesSelected(selection.getSelectedIndices());  
        },
        [setNewItemsSelectedCount, setNewItemIndexesSelected]
    );

    const onSelectedRelRecordsCountChange: (s: Selection) => void = React.useCallback(
        (selection: Selection) => {
            setRelItemsSelectedCount(selection.getSelectedCount());
            setRelItemIndexesSelected(selection.getSelectedIndices());
        },
        [setRelItemsSelectedCount, setRelItemIndexesSelected]
    );

    //EFFECTS
    React.useEffect(() => {
        // init current record
        if (recordId) {            
            dispatch(getRecordAsync(recordId, false));
            dispatch(setAppTitle(t("manageRelatedRecordPageTitlePrefix") + recordId + t("manageRelatedRecordPageTitlePostfix")));
        }
    }, [dispatch, recordId, t]);

    React.useEffect(() => {
        if (currentRecord && currentRecord.recordType) {           
            dispatch(getFieldAsync(true, currentRecord.recordType));               
        }
    }, [currentRecord, dispatch]);

    React.useEffect(() => {
        // init related records ids
        if(currentRecord && currentRecord.relatedRecords){
            setRelatedRecordIds(currentRecord.relatedRecords.split(";"));
        }
    },[currentRecord]);

    React.useEffect(()=>{
        // cast filters based on related records ids on chage/init
        if(relatedRecordIds){
            const refiners: IRefiner[] = ManageRecordRelatedRecordsFilters.getFilters(relatedRecordIds.join(";"));
            setRefiners(refiners);
        }
    },[relatedRecordIds]);

    React.useEffect(() => {
        // launch query on srch text changes for "all records"
        setRecordsInProgress(true);
        (async () => {
            if(srchText){
                const recordsResponse: IRecordResults | null = await getTableData(srchText);
                setColumns(recordsResponse?.columns);
                setRecords(recordsResponse?.records);
                setRecordsInProgress(false);
            } else {
                setRecords([]);
                setRecordsInProgress(false);
            }
        })();
    }, [getTableData, srchText]);

    React.useEffect(() => {
        // launch query on refiners/filters changes for "related records"
        setRelRecordsInProgress(true);
        (async () => {
            if(refiners && refiners.length){
                const filters: string[] | undefined = buildRefinableFilterString(refiners);
                const recordsResponse: IRecordResults | null = await getTableData(undefined, filters);
                setRelColumns(recordsResponse?.columns);
                setRelRecords(recordsResponse?.records);
                setRelRecordsInProgress(false);
            } else {
                setRelRecords([]);
                setRelRecordsInProgress(false);
            }
        })();
    },[getTableData, refiners]);

    

    //TABLE COLUMNS
    const tableColumns: ITableColumn[] = React.useMemo(() => {
        const cols: IRecordColumn[] = (columns || [])
            .filter(c => !!c.isSelected)
            .map(c => {
                return {
                    sortColumnName: c.managedPropertyName || "",
                    isSortable: c.isSortable || false,
                    key: c.key || "",
                    name: c.displayName || "",
                    type: c.type
                };
            });
        const result: ITableColumn[] = cols.map(c => buildRecordTableColumn(c, true));
        return result;
    }, [columns]);

    const relTableColumns: ITableColumn[] = React.useMemo(() => {
        const cols: IRecordColumn[] = (relColumns || [])
            .filter(c => !!c.isSelected)
            .map(c => {
                return {
                    sortColumnName: c.managedPropertyName || "",
                    isSortable: c.isSortable || false,
                    key: c.key || "",
                    name: c.displayName || "",
                    type: c.type
                };
            });
        const result: ITableColumn[] = cols.map(c => buildRecordTableColumn(c, true));
        return result;
    }, [relColumns]);

    const canEditRelatedRecords: boolean = (!!currentRecord?.canCurrentUserEditRecord && !currentRecord?.canCurrentUsedEditRecordAdminLimitedAccess);

    return <div className={styles.manageRelatedRecordsPage}>
        <div className={styles.top}>
            <Breadcumbs
                pageName={breadcrumbAddNewRelatedRecord}
                record={currentRecord ? currentRecord : undefined}
                isThirdStep={true}
            />
        </div>
        { !canEditRelatedRecords &&
            <div className={`${styles.mid} ${styles.layoutPaddings}`}>
                <p>You do not have permission to add or remove related records</p>
            </div>
        }
        { !!canEditRelatedRecords &&
            <div className={`${styles.mid} ${styles.layoutPaddings}`}>
                <div className={styles.allrecordsTableContainer}>
                    <div>
                        <h2>{AddNewRelatedRecordsHeader}</h2>
                    </div>
                    <div className={styles.searchBoxContainer}>
                        <SearchBox
                            className={styles.searchBox}
                            placeholder={SearchFieldPlaceholder}
                            onSearch={onSearchChange}
                            onClear={onSearchClear}
                        />
                        {srchText?.length ? <div className={styles.resultsCount}>
                            {searchResultsString}
                            {/* {` ${recordsInProgress ? "..." : (recordsToShow || []).length} `} */}
                        </div> : null}
                    </div>
                    <div>
                        {!isMobile 
                            ? <Table
                                items={recordsToShow}
                                columns={tableColumns}
                                isLoading={resultsRecordsIsLoading}
                                onOrderBy={onOrderChange}
                                onSelectionChange={onSelectedNewRecordsCountChange}
                                noResultsText={!srchText?.length ? SearchFieldNoSearchTermText : undefined}
                            />
                            : <Cards items={recordsToShow} showSelection={true} onSelectionChange={onSelectedNewRecordsCountChange}/>
                        }

                    </div>
                    <div className={styles.addSelectedRecordsBtnContainer}>
                        <PrimaryButton
                            text="Add selected records"
                            iconProps={{iconName:"Add"}}
                            disabled={!newItemsSelectedCount}
                            onClick={() => { onAddSelectedRecordsClick(); }}
                        />
                    </div>
                </div>
                <Separator className={styles.separator} />
                <div className={styles.relatedRecordsTable}>
                    <div>
                        <h2>{RecentlyAddedRelatedRecordsHeader}</h2>
                    </div>
                    <div>
                        {!isMobile 
                            ? <Table
                                key={"relatedRecords"}
                                items={relRecords}
                                columns={relTableColumns}
                                isLoading={relatedRecordsIsLoading}
                                onOrderBy={onOrderChange}
                                onSelectionChange={onSelectedRelRecordsCountChange}
                            />
                            : <Cards items={relRecords} showSelection={true} onSelectionChange={onSelectedRelRecordsCountChange}/>
                        }
                    </div>
                    <div className={styles.removeSelectedRecordsBtnContainer}>
                        <PrimaryButton
                            text="Remove selected records"
                            iconProps={{iconName:"Delete"}}
                            disabled={!relItemsSelectedCount}
                            onClick={() => { onRemoveSelectedRecordsClick(); }}
                        />
                    </div>
                </div>
            </div>
        }       
        <div className={`${styles.bot} ${styles.layoutPaddings}`}>
            {!!error && <div>{`ERROR: ${error.message}`}</div>}
            <div className={styles.buttonsArea}>
                { !!canEditRelatedRecords &&
                    <PrimaryButton
                        disabled={saveBtnDisabled}
                        onClick={onSaveClick}
                    >
                        <Text>{"Save and return"}</Text>
                        {updateInProgress && <Spinner className={styles.spinner} size={SpinnerSize.small} />}
                    </PrimaryButton>
                }
                <DefaultButton 
                    onClick={() => {history.goBack();}} 
                    disabled={cancelBtnDisabled}
                    text={"Cancel"}
                />
            </div>
        </div>        
    </div>;
}

