import { Label, SearchBox, Separator } from "@fluentui/react";
import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectIsMobile, selectProxy, setAppTitle } from "../../app/globalSlices/contextSlice";
import Breadcumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import NoResults from "../../components/FormControls/NoResults/NoResults";
import RefinerActiveFilters from "../../components/FormControls/Refiner/RefinerActiveFilters";
import RefinerPanel from "../../components/FormControls/Refiner/RefinerPanel";
import UrlHelper, { URL_QUERY_KEYS } from "../../helpers/UrlHelper";
import { IRefiner } from "../../model/Refiner";
import { breadcrumbSearch } from "../../providers/Constants/RecordWizardConstants";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import { IGetRecordsRequest, IRecordResult, IRecordResults, RecordViewType } from "../../services/Api/executor/IApiServiceExecutor";
import IApiService from "../../services/Api/IApiService";
import { buildRefinableFilterString } from "../recordsTable/RecordsTableCommon";
import { selectRecords, selectRefiners, setRecords, setRefiners } from "../recordsTable/recordsTableSlice";
import styles from "./SearchPage.module.scss";
import { SearchResult } from "./SearchResult";
import { useTranslation } from "react-i18next";

let historyListener: { remove: () => void} | undefined;
export function SearchPage(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const searchTermParam: string | null = new URLSearchParams(history.location.search).get(URL_QUERY_KEYS.SEARCH_TERM);
    const isMobile: boolean = useSelector(selectIsMobile);
    const { t } = useTranslation();

    const proxy: string | undefined = useSelector(selectProxy);
    const refiners: IRefiner[] | undefined = useSelector(selectRefiners);
    const records: IRecordResult[] | undefined = useSelector(selectRecords);

    const isEmptyResults: boolean = !records || (!!records && !records.length);

    const [panelIsOpen, setPanelIsOpen] = React.useState<boolean>(false);
    const [refinerInProgress, setRefinerInProgress] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>(searchTermParam ? searchTermParam : "");
    const [availbleRefiners, setAvailbleRefiners] = React.useState<IRefiner[] | undefined>();
    const [recordsInProgress, setRecordsInProgress] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    React.useEffect(() => {
        if(historyListener){ historyListener.remove(); }
        const remove: () => void = history.listen((location) => {
            const key: string | null = new URLSearchParams(location.search).get(t("searchTerm"));
            if (key && searchTerm != key) { setSearchTerm(key); }
        });
        historyListener = {remove};
    }, [history, searchTerm, t]);

    function onRefinersPanelOpen(): void {
        setPanelIsOpen(true);
    }

    function onRefinersPanelClose(): void {
        setPanelIsOpen(false);
    }

    function onRefinersChange(newRefiners: IRefiner[] | undefined): void {
        dispatch(setRefiners(newRefiners));
    }

    function handleSearchChange(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newValue?: any
    ): void {
        setSearchTerm(newValue);
        history.push({ search: UrlHelper.castUrlWithQueryStringParam(newValue, t("searchTerm"), decodeURI(history.location.search)) });
    }

    const getRequestBody: (
        getRecords: boolean,
        getRefiners: boolean,
        managedProperties?: string[]
    ) => IGetRecordsRequest = React.useCallback(
        (
            getRecords: boolean,
            getRefiners: boolean,
            managedProperties?: string[]
        ): IGetRecordsRequest => {
            const request: IGetRecordsRequest = {
                getRefiners: getRefiners,
                getResults: getRecords,
                recordViewType: RecordViewType.Search,
                proxy: proxy,
                searchTerm: searchTerm,
                filters: buildRefinableFilterString(refiners),
                saveConfiguration: true,
                managedProperties: managedProperties
            };
            
            return request;
        },
        [proxy, refiners, searchTerm]
    );

    const getRecords: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const requestBody: IGetRecordsRequest = getRequestBody(true, false);
        const result: IRecordResults | null = await apiService.GetRecords(requestBody);
        return result;
    }, [getRequestBody]);

    const getRefinerData: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const requestBody: IGetRecordsRequest = getRequestBody(false, true);
        const result: IRecordResults | null = await apiService.GetRecords(requestBody);
        return result;
    }, [getRequestBody]);

    React.useEffect(() => {
        dispatch(setAppTitle(t("searchPageTitle")));
    }
    , [dispatch, t]);

    React.useEffect(() => {
        if (panelIsOpen) {
            setRefinerInProgress(true);
            (async () => {
                const recordsResponse: IRecordResults | null = await getRefinerData();
                setAvailbleRefiners(recordsResponse?.refiners);
                setRefinerInProgress(false);
            })();
        }
    }, [getRefinerData, panelIsOpen]);

    React.useEffect(() => {
        (async () => {
            setRecordsInProgress(true);
            const recordsResponse: IRecordResults | null = await getRecords();
            dispatch(setRecords(recordsResponse?.records));
            setRecordsInProgress(false);
        })();
    }, [dispatch, getRecords]);

    return (
        <>
            <Breadcumbs pageName={breadcrumbSearch} />
            <div className={styles.searchPage}>
                <div className={`${styles.pageContainer} 
                    ${isMobile ? styles.mobilePageContainer : ""}`
                }>
                    <h1 className={styles.pageHeader}>{t("searchHeader")}</h1>
                    <div className={styles.actions}>
                        <div className={`${styles.searchBoxContainer}
                            ${isMobile ? styles.mobileSearchBoxContainer : ""}
                        `}>
                            <Label>{t("searchLabel")}</Label>
                            <SearchBox 
                                value={searchTerm}
                                onSearch={handleSearchChange}
                            />
                        </div>
                        {!isMobile 
                            && <div className={styles.filter}>
                                <RefinerPanel
                                    refiners={availbleRefiners}
                                    selectedRefiners={refiners}
                                    inProgress={refinerInProgress}
                                    onOpen={onRefinersPanelOpen}
                                    onClose={onRefinersPanelClose}
                                    onChange={onRefinersChange}
                                />  
                            </div>
                        }
                    </div>
                    {!isMobile 
                        && <div className={styles.refiner}>
                            <RefinerActiveFilters onChange={onRefinersChange} selectedRefiners={refiners} />
                        </div>
                    }
                    <div className={styles.searchResultContainer}>
                        <div  className={styles.searchResultText}>
                            {!isMobile && <Separator/>}
                            <div> 
                                {!isEmptyResults && !recordsInProgress
                                    ?  `${records?.length} ${t("result")}`
                                    : recordsInProgress 
                                        ? "..."
                                        : <NoResults />
                                } 
                            </div>
                        </div>
                        <div className={isMobile && !isEmptyResults && !recordsInProgress
                            ? styles.mobileSearchResult 
                            : ""
                        }>
                            {isMobile && !isEmptyResults && !recordsInProgress
                                && <div className={styles.mobileFilter}>
                                    <RefinerPanel
                                        refiners={availbleRefiners}
                                        selectedRefiners={refiners}
                                        inProgress={refinerInProgress}
                                        onOpen={onRefinersPanelOpen}
                                        onClose={onRefinersPanelClose}
                                        onChange={onRefinersChange}
                                    />  
                                </div>
                            }
                            {isMobile && refiners
                                && <div className={styles.refiner}>
                                    <RefinerActiveFilters onChange={onRefinersChange} selectedRefiners={refiners} />
                                </div>
                            }
                            <SearchResult
                                items={records}
                                isLoading={recordsInProgress}
                                isMobile={isMobile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}