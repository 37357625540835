// eslint-disable-next-line @typescript-eslint/typedef
export const SCROLL_KEYS = {
    TABS: "tabs"
};

export class ScrollHelper {
    public static scrollTo(elementId: string): void {
        const el: HTMLElement | null = document.getElementById(elementId);
        if(el){ 
            el.scrollIntoView({block: "start", behavior: "smooth"}); 
        }
    }
}