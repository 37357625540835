// Role.tsx
export const AssignLabel: string = "Assign";
export const EditLabel: string = "Edit";
export const SubmitLabel: string = "Submit";
export const SaveLabel: string = "Save";
export const PersonDropDownLabel: string = "Person";
export const ChangeReasonLabel: string = "Reason for role change";
export const NotAllocatedString: string = "Not yet assigned";
export const OptionalString: string = " - Optional";
export const MandatoryString: string = " - Required";
export const AddLabel: string = "Add";
export const WhoViewCommentsLabel:string = "Who can see my comments?";
export const WhoViewCommentsDescription:string = "Your comments will appear in the record activity and can be seen by people within your organisation who have access to the record. The Premier’s Private Office, Ministerial Offices and DPC cannot view each other’s comments (except for comments made when recording a decision or sending a record between the organisations).";
export const UnassignRoleDescriptionPart1:string = "To reassign a role, click Unassign role and enter the new person’s name in Search people. Enter the reason for the role change and click Submit. ";
export const UnassignRoleDescriptionPart2:string = "To remove an optional role from the record, click Unassign role, enter the reason and then click Submit. Mandatory roles cannot be removed. ";
// PeoplePicker.tsx
export const DialogSuggested: string = "Suggested People";
export const DialogNoResults: string = "No results found";
export const DialogLoading: string = "Loading";
export const PeoplePickerDefaultAriaLabel: string = "People Picker";

// ContactPicker.tsx
export const ContactsDialogSuggested: string = "Suggested Contacts";
export const ContactsDialogNoResults: string = DialogNoResults;
export const ContactsDialogLoading: string = DialogLoading;
export const ContactsPickerDefaultAriaLabel: string = "Contacts Picker";

// UserList.tsx
export const NameColumnKey: string = "nameColumn";
export const NameColumnName: string = "Name";
export const NameColumnFieldName: string = "displayName";
export const JobTitleColumnKey: string = "jobTitleColumn";
export const JobTitleColumnName: string = "Job Title";
export const JobTitleColumnFieldName: string = "jobTitle";
export const DateAccessColumnKey: string = "dateAccessColumn";
export const DateAccessColumnName: string = "Date given access";
export const DateAccessColumnFieldName: string = "dateAccess";
export const RemoveUserColumnKey: string = "removeUser";
export const RemoveuserColumnName: string = "";
export const OrganisationColumnName: string = "Organisation";

// NoResults.tsx
export const NoResultsText: string = "Sorry, there are no results";

// Table.tsx
export const FilterButtonText: string = "Filter";
export const FilterButtonIcon: string = "Filter";
export const SearchFieldPlaceholder: string = "Search by keyword";
export const SearchFieldNoSearchTermText: string = "Please enter a search term";
export const SearchFieldIcon: string = "Search";
export const ResultsText: string = "Results:";
export const PDFButtonTextAlt: string = "Export selected records as PDF";
export const PDFButtonText: string = "Export as PDF";
export const PDFButtonModalTitle: string = "Export as PDF";
export const PDFButtonModalBlurb1: string = "You have selected";
export const PDFButtonModalBlurb2: string = "records to download. All record content and attachments will be collated into a single PDF pack.";
export const PDFButtonModalBlurb3: string = "You have not selected any records. All content and attachments of the ";
export const PDFButtonModalBlurb4: string = "records in the table will be included.";
export const PDFButtonModalBlurb5: string = "Your PDF pack is processing and will be emailed to you within five minutes.";
export const PDFBatchLimit: number = 400;
export const PDFButtonModalBlurbExceeded: string = `Please select up to ${PDFBatchLimit} records.`;
export const CSVButtonTextAlt: string = "Export selected records to Excel";
export const CSVButtonText: string = "Export to Excel";
export const ExportButtonIcon: string = "Download";
export const ButtonIconColor: string = "#0052C2";
export const NoItemSelectedText: string = "No items selected";
export const EditColumnIcon: string = "Edit";
export const EditColumnText: string = "Edit columns";

//FileList.tsx
export const FileDeleteModalConfirmationMessage: string =
    "";
export const FileDeleteModalTitle: string = "Delete this document?";
export const FileDeleteModalConfirmButtonText: string = "Delete";
export const FileRenameValidationMessage: string =
    "The file name must be valid and cannot contain any of the following characters: \" * : < > ? \\ / |";
export const FileEditModalTitle: string = "Edit file name";
export const FileEditModalConfirmButtonText: string = "Save";

//TemplateList.tsx
export const TemplateDeleteModalConfirmationMessage: string =
    "";
export const TemplateDeleteModalTitle: string = "Delete this document?";
export const TemplateDeleteModalConfirmButtonText: string = "Delete";
export const TemplateAddingText: string = "Adding Template";
export const AttachmentUploadingText: string = "Uploading attachment";
export const CorrespondenceUploadingText: string = "Uploading correspondence item";
export const SupportingDocumentUploadingText: string = "Uploading supporting document";
export const PrimaryDocumentUploadingText: string = "Uploading primary document";
export const SignedAndApprovedDocumentUploadingText: string = "Uploading signed briefing document";
export const TemplateDeletingText: string = "Removing Template";
export const AttachmentDeletingText: string = "Deleting attachment";
export const CorrespondenceDeletingText: string = "Deleting correspondence item";
export const SupportingDocumentDeletingText: string = "Deleting supporting document";
export const PrimaryDocumentDeletingText: string = "Deleting primary document";
export const SignedAndApprovedDocumentDeletingText: string = "Deleting signed briefing document";
export const AttachmentReorderingText: string = "Reordering attachments";
export const SupportingDocumentReorderingText: string = "Reordering supporting document";
export const PrimaryDocumentRenamingText: string = "Reordering primary document";
export const SignedAndApprovedDocumentReorderingText: string = "Reordering signed briefing document";
export const AttachmentRenamingText: string = "Renaming attachment";
export const SupportingDocumentRenamingText: string = "Renaming supporting document";
export const SignedAndApprovedDocumentRenamingText: string = "Renaming signed briefing document";
export const TemplatesRenamingText: string = "Renaming template";
export const TemplatesReorderingText: string = "Reordering templates";

//Persona Component
export const PersonaComponentAltText: string = "Persona of";
export const ImageUrlText: string = "data:image/png;base64";

// SnapshotList.tsx
export const VersionColumnKey: string = "versionColumn";

export const VersionColumnName: string = "Version";
export const VersionColumnFieldName: string = "versionDate";
export const SentByColumnKey: string = "sentByColumn";
export const SentByColumnName: string = "Sent By";
export const SentByColumnFieldName: string = "sentBy";
export const SentToColumnKey: string = "sentToColumn";
export const SentToColumnName: string = "Sent To";
export const SentToColumnFieldName: string = "sentTo";
export const CommentsColumnKey: string = "commentsColumn";
export const CommentsColumnName: string = "Comments";
export const CommentsColumnFieldName: string = "comments";
export const BriefingDocumentsColumnKey: string = "BriefingDocumentsColumn";
export const BriefingDocumentsColumnName: string = "Briefing documents";
export const BriefingDocumentsFieldName: string = "version";

// TemplateList.tsx
export const UpdateSignedDocButtonText: string = "Update with signed doc";

// Aria Labels
export const ButtonAriaLabel: string = "button";
export const IconAriaLabel: string = "icon";
export const SpinnerAriaLabel: string = "loading icon";
export const TextFieldAriaLabel: string = "text field";
export const SnapshotsListAriaLabel: string = "Snapshots List";

// RecordActivityPanel.tsx
export const fieldWhoComments:string = "Who can see my comments?";
export const fieldWhoCommentsDescription:string = "Your comments will appear in the record activity and can be seen by people within your organisation who have access to the record. The Premier’s Private Office, Ministerial Offices and DPC cannot view each other’s comments (except for comments made when recording a decision or sending a record between the organisations).";