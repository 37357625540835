import * as React from "react";
import styles from "./NoResults.module.scss";
import {
    NoResultsText
} from "../../../providers/Constants/FormControlConstants";

export interface INoResultsProps {
    text?: string;
    className?: string;
    isError?: boolean;
}

export default class NoResults extends React.Component<INoResultsProps> {
    public render(): React.ReactElement<INoResultsProps> {
        const message: string = this.props.text ? this.props.text : NoResultsText;
        const classArray: string[] = [styles.noResults];
        if (this.props.isError === true) {
            classArray.push(styles.isError);
        }
        if (this.props.className) {
            classArray.push(this.props.className);
        }
        const classList: string = classArray.join(" ");
        return <p className={classList}>{message}</p>;
    }
}
