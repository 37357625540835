import {
    useDispatch,
    useSelector
} from "react-redux";
import React from "react";
import {
    Dispatch
} from "@reduxjs/toolkit";
import {
    fillDefaultKeyRoles,
    FormMode,
    selectChangeReason,
    selectFormMode,
    selectSelectedInitiator,
    setChangeReason,
    setInitiator,
    selectCustomUserValues,
    setFieldValues
} from "./wizardKeyRolesSlice";
import styles from "./WizardKeyRoles.module.scss";
import {
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4
} from "../wizardRecordInformation/wizardRecordInformationSlice";
import {
    setPreventNext
} from "../recordWizardWrapper/recordWizardWrapperSlice";
import {
    FieldTypes,
    IContentType,
    IContentTypeField,
    IFieldValue,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    IRecord,
    IStage,
    ITerm,
    IUpdateRecordKeyRolesRequest,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import Role from "../../components/FormControls/Role/Role";
import { selectCurrentRecord } from "../manageRecordWrapper/manageRecordWrapperSlice";
import { selectCurrentUser } from "../../app/globalSlices/contextSlice";
import { parseJsonArrayOfUsers } from "../../helpers/KeyRolesHelper";
import StringHelper from "../../helpers/StringHelper";
import { StageTypes } from "../../providers/Constants/TermConstants";
import { updateFieldValues } from "../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";

export interface IWizardKeyRolesForm {
    inProgress?: boolean;
    readOnly?: boolean;
    fields?: IContentTypeField[];
    recordType?: IContentType;
    onRoleChange?: (comments: string | null, user: Partial<IUpdateRecordKeyRolesRequest>) => void;
}

export function WizardKeyRolesForm({
    inProgress,
    readOnly,
    fields,
    recordType,
    onRoleChange
}: IWizardKeyRolesForm): JSX.Element {
    const { t } = useTranslation();
    // 2. SELECT CURRENT GLOBAL STATE
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCustomUserValues);

    const selectedInitiator: IUser | null = useSelector(selectSelectedInitiator);
    const changeReason: string | null = useSelector(selectChangeReason);
    const formMode: FormMode | null = useSelector(selectFormMode);

    const decisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const organisationLv1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const organisationLv2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const organisationLv3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const organisationLv4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);
    const isEditMode: boolean = formMode === FormMode.Edit;

    const customStages: IStage[] = recordType?.stagesJson ? JSON.parse(recordType?.stagesJson) : [];

    // 3. DEFINE COMPONENT STATE

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS

    React.useEffect(() => {
        if (formMode === FormMode.New) {
            dispatch(
                fillDefaultKeyRoles(currentUser)
            );
        }
    }, [decisionCategory, dispatch, formMode, organisationLv1?.name, organisationLv2?.name, organisationLv3?.name, organisationLv4?.name, recordType?.rowKey, currentUser]);

    const customRoleFields: IContentTypeField[] = React.useMemo((): IContentTypeField[] => {
        return fields?.filter(v => v.fieldType === FieldTypes.Role || v.fieldType === FieldTypes.RoleMultiSelect) || [];
    }, [fields]);

    React.useEffect(() => {
        let modelIsValid: boolean =  !!selectedInitiator;

        if (modelIsValid) {
            const requiredRoles: IContentTypeField[] = customRoleFields.filter(v => isEditMode ? v.isRequired : v.isNotRequiredOnCreation ? false : v.isRequired);
            const emptyFieldValues: IFieldValue[] = currentFieldValues?.filter(v => StringHelper.isJsonEmptyObject(v.valueInJson)) || [];
            const someRequiredValuesAreEmpty: boolean = emptyFieldValues.some(v => requiredRoles.some(r => r.internalName === v.fieldInternalName));
            const someRequiredFieldNotExist: boolean = !requiredRoles.every(r => currentFieldValues?.some(v => v.fieldInternalName === r.internalName));
            modelIsValid = !someRequiredValuesAreEmpty && !someRequiredFieldNotExist;
        }

        dispatch(setPreventNext(!modelIsValid));
    }, [dispatch, selectedInitiator, customRoleFields, currentFieldValues, isEditMode]);

    const getSelectedUsers = (field: IContentTypeField): IUser[] | null => {
        return (currentRecord?.fieldValues || currentFieldValues)?.some(v => v.fieldInternalName === field.internalName)
            ? parseJsonArrayOfUsers((currentRecord?.fieldValues || currentFieldValues || []).find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]")
            : null;
    };

    const getCustomRoles = (stage: string): (JSX.Element | null)[] => {
        const stageRoles: IContentTypeField[] = fields?.filter(v => (v.fieldType === FieldTypes.Role || v.fieldType === FieldTypes.RoleMultiSelect) && (v.stages?.toLocaleLowerCase()?.indexOf(stage?.toLocaleLowerCase()) >= 0)) || [];
        return stageRoles.map((field, index) => {
            const roleUsers: IUser[] | null = getSelectedUsers(field);
            return field.fieldType === FieldTypes.RoleMultiSelect
                ? (<>
                    <div>
                        <Role
                            isEditMode={isEditMode}
                            selectedUsers={[]}
                            usersToExclude={roleUsers}
                            changeReason={changeReason}
                            roleName={field.displayName}
                            roleDescription={field.description}
                            onChange={(users: IUser[] | null, reason: string | null) => {
                                if (users) {
                                    const allUsers: IUser[] = [
                                        ...users,
                                        ...(roleUsers || [])
                                    ];
                                    const newValues: IFieldValue[] | null = updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(allUsers));
                                    dispatch(setFieldValues(newValues));
                                    dispatch(setChangeReason(reason));
                                    if (onRoleChange) {
                                        onRoleChange(reason, { fieldValues: newValues || undefined });
                                    }
                                }
                            }}
                            multi={true}
                            background="dark"
                            hideUsersInCard={true}
                            inProgress={inProgress}
                            readOnly={readOnly || field.notEditable}
                        />
                        {(roleUsers || []).map(selUser => {
                            return (
                                <Role
                                    key={selUser.siteUserId}
                                    isEditMode={isEditMode}
                                    selectedUsers={[selUser]}
                                    changeReason={changeReason}
                                    roleName={field.displayName}
                                    roleDescription={field.description}
                                    onChange={(
                                        users: IUser[] | null,
                                        reason: string | null
                                    ) => {
                                        const newArr: IUser[] = [...(roleUsers || [])];
                                        const idx: number = newArr.findIndex((u: IUser) => {
                                            return u.siteUserId === selUser.siteUserId;
                                        });
                                        if (!users) {
                                            //REMOVE
                                            if (idx > -1) {
                                                newArr.splice(idx, 1);
                                            }
                                        } else {
                                            //REPLACE
                                            const user: IUser = users[0];
                                            newArr.splice(idx, 1, user);
                                        }

                                        const newValues: IFieldValue[] | null = updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(newArr));
                                        dispatch(setFieldValues(newValues));
                                        dispatch(setChangeReason(reason));
                                        if (onRoleChange) {
                                            onRoleChange(reason, { fieldValues: newValues || undefined });
                                        }
                                    }}
                                    background="light"
                                    inProgress={inProgress}
                                    readOnly={readOnly || field.notEditable}
                                />
                            );
                        })}
                    </div>
                </>)
                : (
                    <>
                        <Role
                            key={index + field.internalName}
                            isEditMode={isEditMode}
                            selectedUsers={getSelectedUsers(field)}
                            changeReason={changeReason}
                            roleName={field.displayName}
                            roleDescription={field.description}
                            onChange={(users: IUser[] | null, reason: string | null) => {
                                const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === field.internalName)
                                    ? currentFieldValues?.map(v =>
                                        v.fieldInternalName === field.internalName
                                            ? ({ fieldInternalName: v.fieldInternalName, valueInJson: users ? JSON.stringify(users) : JSON.stringify([]) } as IFieldValue)
                                            : v) || null
                                    : (currentFieldValues || []).concat([{ fieldInternalName: field.internalName, valueInJson: users ? JSON.stringify(users) : JSON.stringify([]) } as IFieldValue]);

                                dispatch(setFieldValues(newValues));
                                dispatch(setChangeReason(reason));
                                if (onRoleChange) {
                                    onRoleChange(reason, { fieldValues: newValues || undefined });
                                }
                            }}
                            inProgress={inProgress}
                            readOnly={readOnly || field.notEditable}
                            isMandatory={isEditMode ? field.isRequired : field.isNotRequiredOnCreation ? false : field.isRequired}
                        />
                    </>);
        });
    };

    const makeDecisionSection: JSX.Element = (
        <>
            <>
                <div className={styles.roleSection}>
                    <div className={styles.sectionHeading}>
                        <h2>{t("decisionHeader")}</h2>
                    </div>
                    <div>
                        <div className={styles.sectionColumn}>
                            {getCustomRoles(StageTypes.MakeDecisionGeneral)}
                        </div>
                        <div className={styles.sectionColumn}>

                        </div>
                    </div>
                </div>
            </>
        </>
    );

    const fullKeyRolesSections: JSX.Element = (
        <>
            {customStages.sort((a, b) => a.Order - b.Order).map((stage, index) => {
                switch (stage.Name) {
                    case StageTypes.Initiate: return (
                        <div className={styles.roleSection}>
                            <div className={styles.sectionHeading}>
                                <h2>{t("initiateHeader")}</h2>
                            </div>
                            <div className={styles.sectionColumn}>
                                <Role
                                    isEditMode={isEditMode}
                                    selectedUsers={selectedInitiator ? [selectedInitiator] : null}
                                    changeReason={changeReason}
                                    roleName={t("initiatorLabel")}
                                    roleDescription={t("initiatorDescription")}
                                    onChange={(user: IUser[] | null, reason: string | null) => {
                                        dispatch(setInitiator((user || [])[0]));
                                        dispatch(setChangeReason(reason));
                                        if (onRoleChange) {
                                            onRoleChange(reason, { initiator: user || [] });
                                        }
                                    }}
                                    isMandatory={true}
                                    inProgress={inProgress}
                                    readOnly={readOnly}
                                />
                            </div>
                            <div className={styles.sectionColumn}>
                                {getCustomRoles(StageTypes.Initiate)}
                            </div>
                        </div>
                    );
                    case StageTypes.PrepareAndReview: return (<>
                        {
                            <div className={styles.roleSection}>
                                <div className={styles.sectionHeading}>
                                    <h2>{t("prepareHeader")}</h2>
                                </div>
                                <div>
                                    <div className={styles.sectionColumn}>
                                        {getCustomRoles(StageTypes.PrepareAndReview)}
                                    </div>
                                </div>
                            </div>
                        }
                    </>);
                    case StageTypes.Recommend: return (<>
                        {
                            <div className={styles.roleSection}>
                                <div className={styles.sectionHeading}>
                                    <h2>{t("recommendHeaderDepartmentTypes")}</h2>
                                </div>
                                <div className={styles.sectionColumn}>
                                    {getCustomRoles(StageTypes.Recommend)}
                                </div>
                                <div className={styles.sectionColumn}>

                                </div>
                            </div>
                        }
                    </>);
                    case StageTypes.ImplementAndClose: return (<>
                        {
                            <div className={styles.roleSection}>
                                <div className={styles.sectionHeading}>
                                    <h2>{t("implementHeader")}</h2>
                                </div>
                                <div>
                                    <div className={styles.sectionColumn}>
                                        {getCustomRoles(StageTypes.ImplementAndClose)}
                                    </div>
                                </div>
                            </div>
                        }
                    </>);
                    default:
                        if (stage.Name.indexOf("Make decision") === 0) {
                            if (stage.Name.indexOf("Make decision: Make decision") === 0) {
                                return makeDecisionSection;
                            }
                        }
                        else {
                            const customRolesForStage: (JSX.Element | null)[] = getCustomRoles(stage.Name);
                            if (customRolesForStage && customRolesForStage.length) {
                                return (
                                    <div key={index} className={styles.roleSection}>
                                        <div className={styles.sectionHeading}>
                                            <h2>{stage.Name}</h2>
                                        </div>
                                        <div className={styles.sectionColumn}>
                                            {customRolesForStage}
                                        </div>
                                        <div className={styles.sectionColumn}> </div>
                                    </div>
                                );
                            }
                        }
                }
            })
            }
        </>
    );

    return (
        <div className={styles.formContainer}>
            <div className={styles.formContainerBackground}></div>
            {fullKeyRolesSections}
        </div>
    );
}
