import { IWizardKeyRolesModelState } from "../features/wizardKeyRoles/wizardKeyRolesSlice";
import { IRecord, IUser } from "../services/Api/executor/IApiServiceExecutor";
import i18next from "i18next";

interface IUserKeyRole {
    role: string;
    usersNames: string;
}

export function castRecordToKeyRoles(record: IRecord | null): Partial<IWizardKeyRolesModelState> | null {
    let result: Partial<IWizardKeyRolesModelState> | null = null;
    if (record) {
        const keyRoles: Partial<IWizardKeyRolesModelState> = {
            userInitiator: record.initiator?.length ? record.initiator[0] : null,
            currentFieldValues: record.fieldValues,
        };
        const objNotEmpty: boolean = Object.keys(keyRoles).some(
            key => !!keyRoles[key as keyof Partial<IWizardKeyRolesModelState>]
        );
        if (objNotEmpty) {
            result = keyRoles;
        }
    }
    return result;
}

export function castRecordToUserKeyRoles (record: IRecord | null) : IUserKeyRole[]{
    let result: IUserKeyRole[] = [];
    if(record){
        result = [{
            role: i18next.t("initiatorLabel"),
            usersNames: record.initiator?.length  ? (record.initiator[0].displayName || "") : ""
        }];
    }

    return result;
}

export const parseJsonArrayOfUsers = (json: string): IUser[] => {
    const result: IUser[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const objArray: any[] = JSON.parse(json);
    
    for (const obj of objArray) {
        const user: IUser = <IUser>{};
        for (const keyObj in obj) {         
            const lowerCaseVariant: string = keyObj.charAt(0).toLowerCase() + keyObj.slice(1);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (user as any)[lowerCaseVariant] = obj[keyObj];                
        }
        result.push(user);
    }
    
    return result;
};