import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import styles from "./RecordTimeline.module.scss";
import PdfPackButton from "../PdfPackButton/PdfPackButton";
import IWantButton from "../IWantButton/IWantButton";
import {
    IContentTypeField,
    IRecord} from "../../../services/Api/executor/IApiServiceExecutor";
import {
    briefingPackButtonLoadingText,
    briefingPackButtonText,
    openBriefingPackButtonText
} from "../../../providers/Constants/RecordTitleConstants";
import ViewRecordActivity from "../ViewRecordActivity/ViewRecordActivity";
import { useTranslation } from "react-i18next";

export interface IActiveStageButtonsProps {
    record: IRecord;
    fields: IContentTypeField[];
}

export function ActiveStageButtons({ record, fields }: IActiveStageButtonsProps): JSX.Element {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.activeButtonsContainer}>
                <PdfPackButton
                    text={briefingPackButtonText}
                    loadingText={briefingPackButtonLoadingText}
                    buttonClass={styles.viewButton}
                    spinnerClass={styles.spinnerStyling}
                    recordId={record.recordId}
                    title={record.name}
                    openText={openBriefingPackButtonText}
                    onError={(msg: string | undefined) => { setErrorMessage(msg); }}
                />
                <ViewRecordActivity recordId={record.recordId} />
            </div>
            {errorMessage 
                && <MessageBar messageBarType={MessageBarType.blocked} isMultiline={true} >
                    {errorMessage}
                </MessageBar>
            }
            <IWantButton
                className={styles.wantOptionsButton}
                title={t("titleOptionsButton")}
                record={record}
                fields={fields}
            />
        </>
    );
}