// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

// 2. TYPES AND INTERFACES
// Define any additional types or interfaces required here

// FEATURE STATE
export interface IWizardSummaryState {
    isConfirmed: boolean;
}

export const initialState: IWizardSummaryState = {
    isConfirmed: false
};

// 3. FEATURE SLICE
// real type is calculated automatically from the defined object
// eslint-disable-next-line @typescript-eslint/typedef
const wizardSummarySlice = createSlice({
    name: "wizardSummary",
    initialState,
    reducers: {
        resetWizardSummary: (state: IWizardSummaryState) => {
            for (const key in initialState) {
                const keyAs: keyof IWizardSummaryState = key as keyof IWizardSummaryState;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                state[keyAs] = initialState[keyAs] as any;
            }
        },
        setIsConfirmed: (
            state: IWizardSummaryState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.isConfirmed = action?.payload || false;
        }
    }
});

// 4. SYNCRONOUS ACTIONS
export const { resetWizardSummary, setIsConfirmed } = wizardSummarySlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// 6. SELECTORS
export const selectIsConfirmed = (state: RootState): boolean => state.wizardSummary.isConfirmed;

// 7. EXPORT REDUCER
export default wizardSummarySlice.reducer;
