// 1. IMPORTS
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IApiService from "../../services/Api/IApiService";
import {
    IBaseFile,
    IBulkUpdateStateResponse,
    IFile,
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    AppThunk, RootState,
} from "../../app/store";
import { FileHelper } from "../../helpers/FileHelper";

// FEATURE STATE
export interface IBulkUpdatesState {
    selectedFile: IFile[];
    bulkUpdateStateLoading: boolean;
    filesLoading: boolean;
    filesErrorMessage: string | undefined;
    bulkUpdateState: IBulkUpdateStateResponse | null,
    historyLink: string
}

export const initialState: IBulkUpdatesState = {
    selectedFile: [],
    filesLoading: false,
    bulkUpdateStateLoading: false,
    filesErrorMessage: undefined,
    bulkUpdateState: null,
    historyLink: ""
};

// 3. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const BulkUpdatesSlice = createSlice({
    name: "BulkUpdates",
    initialState,
    reducers: {
        setHistoryLink: (
            state: IBulkUpdatesState,
            action: PayloadAction<string>
        ) => {
            state.historyLink = action.payload;
        },
        addFile: (
            state: IBulkUpdatesState,
            action: PayloadAction<IFile>
        ) => {
            state.selectedFile = [action.payload];
        },
        
        removeFile: (state: IBulkUpdatesState) => {
            state.selectedFile = [];
        },
        setFilesLoading: (
            state: IBulkUpdatesState,
            action: PayloadAction<boolean>
        ) => {
            state.filesLoading = action.payload;
        },
        setBulkUpdateStateLoading: (
            state: IBulkUpdatesState,
            action: PayloadAction<boolean>
        ) => {
            state.bulkUpdateStateLoading = action.payload;
        },
        setBulkUpdateState: (
            state: IBulkUpdatesState,
            action: PayloadAction<IBulkUpdateStateResponse|null>
        ) => {
            state.bulkUpdateState = action.payload;
        },
        setFilesErrorMessage: (
            state: IBulkUpdatesState,
            action: PayloadAction<string | undefined>
        ) => {
            state.filesErrorMessage = action.payload;
        },
    }
});

// 4. SYNCRONOUS ACTIONS
export const {
    addFile,
    removeFile,
    setFilesLoading,
    setFilesErrorMessage,
    setBulkUpdateStateLoading,
    setBulkUpdateState,
    setHistoryLink
} = BulkUpdatesSlice.actions;

// 5. ASYNCRONOUS ACTIONS
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const deleteFileAsync = (): AppThunk => async (dispatch, state) => {
    const file: IFile = state().bulkUpdate.selectedFile[0];
    if(file){
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();

        dispatch(setFilesLoading(true));
        // Delete the record document from SharePoint
        const retVal: boolean = await apiService.DeleteFileFromPersonalStorage(file);
        // Remove file from the state
        dispatch(removeFile());
        dispatch(setFilesLoading(false));
        const bulkUpdateState :IBulkUpdateStateResponse|null  = state().bulkUpdate.bulkUpdateState;
        if(bulkUpdateState?.errors?.length || bulkUpdateState?.warnings?.length){
            dispatch(setBulkUpdateState({...bulkUpdateState, errors:[], warnings: []}));
        }
        return retVal;
    }
    return true;
};


export const uploadAndValidateFilesAsync = (
    files: IFile[]
): AppThunk => async dispatch => {
    if(!files || !files.length) return;
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setFilesLoading(true));
    dispatch(setFilesErrorMessage(undefined));

    let errorMessage: string | undefined = undefined;


    const file:IFile = files[0];
    if(file.name.toLowerCase().endsWith(".csv")){
        if (file.contentURL) {
            const binary: string  = await FileHelper.fileContentAsBase64(file.contentURL);
            file.contentURL = binary;
        }

        // Rename the record document from SharePoint
        const retVal: IBaseFile | null = await apiService.UploadFileToPersonalStorage(file);
        // Update the state
        if (retVal) {
            if(retVal.path){
                const bulkUpdateResponse: IBulkUpdateStateResponse | null = await apiService.StartBulkUpdate({
                    sourceCsvPath: retVal.path,
                    validateOnly: true
                });
                dispatch(setBulkUpdateState(bulkUpdateResponse));
            }
            dispatch(
                addFile({
                    ...retVal,
                    name: file.name,
                    contentURL: retVal.path
                })
            );
        } else {
            errorMessage = `Failed to upload file: ${file.name}`;
        }
    } else {
        errorMessage = "Invalid file format. Please, upload CSV file.";
    }
    dispatch(setFilesLoading(false));
    dispatch(setFilesErrorMessage(errorMessage));
};

export const loadBulkUploadStateAsync = (): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setBulkUpdateStateLoading(true));

    const retVal: IBulkUpdateStateResponse | null = await apiService.GetBulkUpdateState();
    
    dispatch(setBulkUpdateStateLoading(false));
    dispatch(setBulkUpdateState(retVal));
    if(retVal?.hitoryLibraryUrl){
        dispatch(setHistoryLink(retVal.hitoryLibraryUrl));
    }
};


export const startBulkUploadStateAsync = (): AppThunk => async (dispatch, state) => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();

    dispatch(setBulkUpdateStateLoading(true));
    const path: string | undefined = state().bulkUpdate.selectedFile[0]?.path;
    if(path){
        const retVal: IBulkUpdateStateResponse | null = await apiService.StartBulkUpdate({
            sourceCsvPath: path,
            validateOnly: false
        });
        dispatch(setBulkUpdateState(retVal));
        //if process started, remove uploaded file
        if(retVal?.totalRecords && retVal.totalRecords > 0){
            dispatch(removeFile());
        }

    }
    
    dispatch(setBulkUpdateStateLoading(false));
    
};




// 6. SELECTORS
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const stateSelectedFile = (state: RootState): IFile[] =>
    state.bulkUpdate.selectedFile;

export const stateSelectedHistoryLink = (state: RootState): string =>
    state.bulkUpdate.historyLink;

export const stateFilesLoading = (state: RootState): boolean =>
    state.bulkUpdate.filesLoading;

export const stateFilesErrorMessage = (state: RootState): string | undefined =>
    state.bulkUpdate.filesErrorMessage;
    
export const stateBulkUpdateStateLoading = (state: RootState): boolean =>
    state.bulkUpdate.bulkUpdateStateLoading;

export const stateBulkUpdateState = (state: RootState): IBulkUpdateStateResponse | null =>
    state.bulkUpdate.bulkUpdateState;


// 6. EXPORT REDUCER
export default BulkUpdatesSlice.reducer;