// Interfaces
export interface HeaderStrings {
    Header: string;
    SubTitle?: string;
    DotPoints?: string[];
}

export interface FieldValue {
    key: string;
    value: string;
    missingValueMessage: string;
}

// BreadCrumb
export const breadcrumbDashboard: string = "Dashboard";
export const breadcrumbCreateRecord: string = "Initiate record";
export const breadcrumbManageRecord: string = "Manage Record";
export const breadcrumbSearch: string = "Search";
export const breadcrumbBulkUpdate: string = "Bulk Update";
export const breadcrumbAddNewRelatedRecord: string = "Add new related record";
export const breadcrumbCopyRecord: string = "Make a copy";
export const breadcrumbSupersedeRecord: string = "Supersede";
export const breadcumbSettings: string = "Settings";
export const breadcumbAppSettings: string = "Application Settings";
export const breadcrumbIconAriaLabel: string = "Forward Arrow Icon";
export const breadcrumbDocumentGeneration: string = "Document Generation";
export const breadcrumbPages: Array<string> = [
    breadcrumbDashboard,
    breadcrumbCreateRecord,
    breadcrumbManageRecord,
    breadcumbSettings,
    breadcumbAppSettings,
    breadcrumbCopyRecord,
    breadcrumbAddNewRelatedRecord,
    breadcrumbSearch,
    breadcrumbBulkUpdate,
    breadcrumbDocumentGeneration
];

//OptionsButton
export const pagesIWantButtonAvailable: Array<string> = [breadcrumbManageRecord];

// WizardButtons
export const wrapperGetStartedButton: string = "Get started";
export const wrapperNextButton: string = "Next";
export const wrapperCreateRecordButton: string = "Initiate record";
export const wrapperBackButton: string = "Back";
export const wrapperExitButton: string = "Cancel";
export const wrapperOpenButton: string = "Open record";
export const dashboardButton: string = "Go to Dashboard";

// Record MessageBar
export const createRecordErrorMessage: string =
    "There are required fields missing. Please ensure that the title and record type have been provided.";
export const updateRecordErrorMessage: string = "Can't update record.";

// 1st Screen: Create Record
// // Header
export const createRecordHeaderStrings: HeaderStrings = {
    Header: "Initiate record",
    SubTitle: "Select the record type and subtype",
    DotPoints: [
        "Ensure your selections are correct before continuing as these fields cannot be edited later.",
        "Required fields are marked with an asterisk *"
    ]
};
// // Field Labels
export const fieldLabelRecordTypeField: string = "Record type";
export const fieldLabelRecordSubTypeField: string = "Record sub-type";
export const fieldLabelRecordType: string = "Record type";
export const fieldLabelSubRecordType: string = "Record sub-type";

// // Buttons
export const createRecordGetStarted: string = "Get started";

// Misc
export const exitButton: string = "Exit";

//Loading
export const loadingMessage: string = "Loading record details";
export const loadingKeyDatesMessage: string = "Loading record key dates";

// 2nd Screen: Record Information
// // Header
export const recordInformationHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Enter the record's information",
    DotPoints: ["These details can be edited any time on the Manage record page."]
};

// Field Desciptions
export const keyDetailsTitleDescription: string = "Capture the subject in a short, one-line title";
export const fieldDescriptionCabinetWorkingFileNumber: string =
    "Cabinet/Committee submissions must have a copy saved into a Cabinet working folder in Content Manager. Need help? Email CIC.Information.Management@dpc.vic.gov.au.";

// Access Strings
export const headerAccess: string = "Access";
export const headerDescription1: string = "Access is set to";
export const headerDescriptionBoldOpen: string = "'Open'";
export const headerDescriptionBoldRestricted: string = "'Restricted'";
export const headerDescription2: string = "by default.";
export const headerAccessShowButton: string = "Show details";
export const headerAccessCloseButton: string = "Close details";

export const accessDescipritonHeader: string = "Manage access & permissions";
export const accessDescriptionBlurb1: string =
    "By default, everyone within a work area has read only access to all unrestricted records initiated by their area.";
//export const accessDescriptionBlurb2: string =
//    "'Restricted' access means only nominated people can read and it's documents.";
export const accessDescriptionBlurbStrong: string =
    "Record access should only be restricted for secure record types.";

export const fieldLabelOverideAccess: string = "Override default access";
export const fieldDescrpitionOverideAccess: string = "Access is now set to 'Restricted'.";
export const fieldLabelRestrictAccessTeam: string = "Restrict access by secure record type";
export const fieldDescriptionRestrictAccessTeam: string =
    "Restricts access according to security permission rules.";
export const fieldLabelSecurityClassification: string =
    "Select the Information Security Classification";
export const fieldDescriptionSecurityClassification: string =
    "Selecting Cabinet in Confidence will automatically restrict the record’s access.";
export const fieldDescriptionViewAccessRestricted: string =
    "Restrict access to a work area or individual(s):";
export const fieldLabelViewAccessRestricted: string = "View access is restricted to:";
export const buttonLabelAdd: string = "Add";

export const commentsEditAccess: string = "Reason for access change";
export const descriptionCommentsEditAccess: string =
    "This will be displayed in the record activity to ensure that access changes are recorded for auditing.";

// Correspondence files screen

export const correspondenceFilesHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Upload original correspondence",
    SubTitle: "",
    DotPoints: [
        "Save files to your computer before uploading. Emails should be saved as .msg file type.",
        "Multiple files can be uploaded if they all require the same type of reply. A record will be initiated for each file using the details you select."
    ]
};

// Correspondence details screen

export const correspondenceDetailsHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Enter original correspondence details"
    // ,
    // DotPoints: [
    //     "Please confirm that the correspondent details scanned from the uploaded correspondence items are correct.",
    //     "If no details have been found, please enter them individually and choose if you want to add this correspondent to the central database."
    // ]
};

// 2nd Screen: Response Information
// // Header
export const responseInformationHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Enter the response information"
};
export const replyInformationHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Enter the reply's information"
};
export const eventInformationHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Enter event information"
};

export const editDocumentsHeader: string = "Manage documents";
export const editKeyDatesHeader: string = "Manage key dates";
export const editKeyRolesHeader: string = "Manage people and roles";
export const editRelatedRecordsHeader: string = "Manage related records";
export const editRecordDetailsHeader: string = "Manage record details";

// // Field Labels
export const fieldLabelTitle: string = "Title";
export const titleDescription: string =
    "Capture the subject in a short, one line title. This will appear at the top of the briefing pack.";

// // // Event
export const fieldLabelEventTitle: string = "Event title";

export const fieldDescriptionEventLocation: string = "Enter the address of the event location";
export const fieldLabelEventLocation: string = "Event location";

export const fieldDescriptionEventDueDate: string = "";
export const fieldLabelEventDate: string = "Event date";
export const fieldLabelEventTime: string = "Event start time";
export const fieldLabelEventEndTime: string = "Event end time";
export const fieldLabelEventItems: string = "Needed for this event:";
export const fieldLabelEventMediaCoverage: string = "Media coverage:";

export const fieldDescriptionEventSpeechLength: string =
    "Enter the duration, word count or number of pages required.";
export const fieldLabelEventSpeechLength: string = "Speech length";

// // // Parliamentary
export const fieldLabelParliamentaryTitle: string = "Response Title";
export const fieldDescrParliamentaryTitle: string =
    "Your entry here will appear on the response document and on the dashbaord. For Adjournment Debates, copy the text from Hansard. For all other PQs, provide a concise description of content in the ersponse document.";
export const fieldLabelParliamentarySource: string = "Parliamentary Source";

export const fieldLabelParliamentaryDateRaised: string = "Date raised";
export const fieldDescrParliamentaryDateRaised: string =
    "Date you want this record to be with the Recommender.";

export const fieldLabelParliamentaryQuestionNumber: string = "Question Number";
export const fieldDescrParliamentaryQuestionNumber: string =
    "Applicable only to Legislative Assembly and Legislative Council Questions on Notice.";

export const fieldLabelParliamentaryRaisedBy: string = "Raised by";
export const fieldLabelParliamentaryQuestionRaised: string = "Question raised";
export const fieldDescrParliamentaryQuestionRaised: string =
    "Copy the text from the transcript directly into this text box. Include the transcript page number at the beginning of each quote. Alternatively, write a summary of the question and attach the complete transcript extract as an attachment in the next step.";
export const fieldLabelSummary: string = "Purpose statement";
export const fieldLabelFlags: string = "Flag";
export const fieldDescriptionSummary: string =
    "Summarise the issue and context into a concise sentence. This will appear in the final briefing pack and the Dashboard record entry.";
export const fieldLabelRequestFrom: string = "Requested by";
export const fieldLabelReplyType: string = "Reply type";
export const fieldLabelReplyFormat: string = "Reply format and signature type";
export const fieldLabelAutoResponseTemplate: string = "Email auto confirmation";
export const fieldLabelAdditionalReplyInstructions: string = "Additional reply instructions";
export const fieldLabelDateRequested: string = "Date requested";
export const fieldLabelTimeframe: string = "Timeframe (when record is due to the Decision Maker)";
export const fieldLabelCoveringBrief: string = "Does the reply require a cover brief?";
export const fieldDescriptionTimeframe: string =
    "Due dates populate automatically based on the timeframe you select. They can be edited anytime.";
export const fieldLabelRecommenderDueDate: string = "Date due to Recommender";
export const fieldPlaceholderRecommenderDueDate: string = "Select date due to Recommender";
export const fieldLabelRecommenderDueTime: string = "Time due to Recommender";
export const fieldDescriptionRecommenderDueDate: string =
    "The Recommender is the relevant Deputy Secretary or line executive.";
export const fieldDescriptionReplyType: string = "Select the type of reply response required";
export const fieldDescriptionReplyFormat: string = "Select the format and signature type";
export const fieldDescriptionAutoResponseTemplate: string =
    "Select how many days until they can expect a reply, or select No auto confirmation. The email will be sent after the record is initiated.";
export const placeholderAutoResponseTemplate: string = "Select auto confirmation option";
export const fieldAutoResponseSupportText: string =
    "The email will confirm their correspondence was received and when they can expect a reply. Send an auto confirmation by selecting the number of days until the Sender will receive a reply, or select No auto confirmation.";
export const fieldLabelDecisionMakeDueDate: string = "Date due to Decision Maker";
export const fieldPlaceholderDecisionMakeDueDate: string = "Select date due to Decision Maker";
export const fieldLabelDecisionMakeDueTime: string = "Time due to Decision Maker";
export const fieldDescriptionDecisionMakeDueDate: string =
    "The Decision Maker is the final approver of the record.";
export const fieldLabelOnAddDecisionRequiredDate: string = "Act by date (optional)";
export const fieldLabelOnEditDecisionRequiredDate: string = "Act by date";
export const fieldDescriptionDecisionRequiredDate: string =
    "Use only when a decision is required by a date to meet legislation or government processes.";
export const fieldLabelRelatedRecord: string = "Related Record";
export const fieldDescriptionRelatedRecord: string =
    "Paste in the record number for related records, seperated by commas. They will be clickable links later, on the Manage record screen.";
export const editFieldLabelSignatureRequired: string =
    "Do any documents require the Decision Maker’s signature?";
export const fieldLabelSignatureRequired: string =
    "Will any documents require the Decision Maker’s signature?";
export const fieldDescriptionSignatureRequired: string =
    "Your selection can be changed any time on the Manage Record page. Documents for signature are added later.";

export const commentsEditKeyDates: string = "Reason for date change";
export const descriptionCommentsEditKeyDates: string =
    "This will be displayed in the record activity to ensure that dates are accurate for useful timeliness reporting";
export const datePickerArialabel: string = "Date Picker";
export const fieldDescriptionFlags: string =
    "Additional context for the record, outside of it's state within a workflow.";

export const tailoredResponseOptionNameReplyType: string = "Tailored response";
export const tailoredPremierResponseOptionNameReplyType: string =
    "Tailored Premier/Minister response";
export const tailoredDepartmentResponseOptionNameReplyType: string = "Tailored department response";

// 4th Screen: Response Templates
// // Header
export const responseTemplateHeaderStrings: HeaderStrings = {
    Header: "Step {stepNumber} of {totalSteps}: Add templates and documents",
    DotPoints: [
        "Templates are used to prepare the record’s content and can be added or removed at any time on the Manage Record page. The templates available below are relevant to the record type."
    ]
};

// Tabs
export const activityTabName: string = "Activity";
export const activityTabKey: string = "activity";
export const documentsTabName: string = "Documents";
export const documentsTabKey: string = "documents";
export const keyDatesTabName: string = "Key dates";
export const keyDatesTabKey: string = "keyDates";
export const peopleAndRolesTabName: string = "People and roles";
export const peopleAndRolesTabKey: string = "PeopleAndRoles";
export const relatedRecordsTabName: string = "Related records";
export const relatedRecordsTabKey: string = "relatedRecords";
export const accessTabName: string = "Access";
export const accessTabKey: string = "access";
export const notificationsTabName: string = "Notifications";
export const notificationsTabKey: string = "notifications";
export const detailsTabName: string = "Details";
export const detailsTabKey: string = "EditRecordDetails";
export const copyRecordTabKey: string = "CopyRecord";
export const supersedeRecordTabKey: string = "SupersedeRecord";

export const currentStepHashKey: string = "#step-";
