import { CICSecurityClassificationNames } from "../providers/Constants/TermConstants";
import { IContentTypeField, IFieldValue, ITerm } from "../services/Api/executor/IApiServiceExecutor";

export const isCicSelectedInArray = (selectedSecurityClassifications: ITerm[] | undefined): boolean => {
    let result: boolean = false;
    if (selectedSecurityClassifications) {
        for (const securityClassification of selectedSecurityClassifications) {
            if (isCicSelected(securityClassification)) {
                result = true;
                break;
            }
        }
    }
    return result;
};

export const isCicSelected = (selectedSecurityClassificationsType: ITerm | null): boolean => {
    const securityClassificationType: string = selectedSecurityClassificationsType?.name
        ? selectedSecurityClassificationsType?.name
        : "";
    const fouldItems: string | undefined = CICSecurityClassificationNames.find(value => {
        return securityClassificationType.toLowerCase().indexOf(value.toLowerCase()) >= 0;
    });
    return fouldItems !== undefined;
};

export const isFieldInActive = (fieldInternalName: string, inactiveFields: IContentTypeField[] | null | undefined): boolean => {
    if(inactiveFields?.length){
        return (inactiveFields || []).some(f => f.internalName === fieldInternalName);
    }
    return true;
};

export const getFieldDisplayName = (fieldInternalName: string, contentTypeFields: IContentTypeField[] | null | undefined): string => {
    const field: IContentTypeField | undefined = contentTypeFields?.find(f => f.internalName === fieldInternalName || f.displayName === fieldInternalName);
    return field?.displayName || "";
};

export const getFieldDescription = (fieldInternalName: string, contentTypeFields: IContentTypeField[] | null | undefined): string => {
    const field: IContentTypeField | undefined = contentTypeFields?.find(f => f.internalName === fieldInternalName || f.displayName === fieldInternalName);
    return field?.description || "";
};

export const updateFieldValues = (currentFieldValues: IFieldValue[] | null, fieldInternalName: string, fieldValueInJson: string): IFieldValue[] => {
    const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === fieldInternalName)
        ? currentFieldValues?.map(v =>
            v.fieldInternalName === fieldInternalName
                ? ({ fieldInternalName: v.fieldInternalName, valueInJson: fieldValueInJson } as IFieldValue)
                : v) || null
        : (currentFieldValues || []).concat([{ fieldInternalName: fieldInternalName, valueInJson: fieldValueInJson } as IFieldValue]);
    return newValues;
};