export enum DayIndex {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}

export enum MonthStep {
    Current = 1,
    Next = 2
}

export const regExDateISOFormat: RegExp = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/g;

export default class DateHelper {
    private static readonly locales: string[] = ["en-AU", "en-GB"];

    private static readonly australiaTimezone: string = "Australia/Sydney";

    public static ToLongDateTimeString(date: Readonly<Date>): string {
        const options: Intl.DateTimeFormatOptions = {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric"
            // hour: this.numeric,
            // minute: this.numeric
            // timeZoneName: this.short
        };
        const dateString: string = DateHelper.getDateString(date, options);
        return dateString;
    }

    public static ToLongDateWithTimeString(date: Readonly<Date>): string {
        const options: Intl.DateTimeFormatOptions = {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        };
        const dateString: string = DateHelper.getDateString(date, options);
        return dateString;
    }

    public static ToDateTimeString(
        date: Readonly<Date>,
        includeSeconds?: boolean,
        includeYear?: boolean
    ): string {
        const options: Intl.DateTimeFormatOptions = {
            month: "short", // long. 2-digit
            day: "2-digit",
            hour: "numeric",
            minute: "2-digit"
        };
        if (includeSeconds === true) {
            options.second = "2-digit";
        }
        if (includeYear === true) {
            options.year = "numeric";
        }
        const dateString: string = DateHelper.getDateString(date, options);
        return dateString;
    }

    public static ToDDMMYYYYTimeString(
        date: Readonly<Date>
    ): string {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        };

        const dateString: string = DateHelper.getDateString(date, options);
        return dateString;
    }

    public static ToDDMMYYYYString(
        date: Readonly<Date>
    ): string {        
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    public static ToDateOnlyString(
        date: Readonly<Date>,
        includeYear?: boolean,
        supressDate?: boolean,
        shortMode?: boolean
    ): string {
        const options: Intl.DateTimeFormatOptions = {
            month: shortMode === true ? "numeric" : "short"
            // day: this.twoDigit,
        };
        if (supressDate !== true) {
            options.day = shortMode === true ? "numeric" : "2-digit";
        }
        if (includeYear === true) {
            options.year = shortMode === true ? "2-digit" : "numeric"; // "4-digit",
        }
        const dateString: string = DateHelper.getDateString(date, options);
        return dateString;
    }

    public static ToTimeOnlyFromNumber(
        numberMinutes: number| string
    ): string | undefined{
        if (DateHelper.isNumeric(numberMinutes)) {
            const date: number = Number(numberMinutes);
            const hours: number = Math.floor(date / 60);
            const minutes: number = date % 60;
            const minutesString: string = minutes < 10 ? "0" + minutes : minutes.toString();
            const am_pm: string = hours >= 12 ? "PM" : "AM";
            const hoursString: string = (hours % 12 || 12).toString();

            return `${hoursString}:${minutesString} ${am_pm}`;
        }
        return undefined;
    }
    
    public static ToTimeOnlyFromNumberAsDate(
        numberMinutes: number | string
    ): Date | undefined {
        if (DateHelper.isNumeric(numberMinutes)) {
            const date: number = Number(numberMinutes);
            const hours: number = Math.floor(date / 60);
            const minutes: number = date % 60;

            const dateObj: Date = new Date(2024, 1, 1, hours, minutes, 0, 0);
            return dateObj;
        }
        return undefined;
    }

    public static ToTimeOnlyFromNumberFromDate(
        date: Date
    ): string {
        const hours: number = date.getHours();
        const minutes: number = date.getMinutes();

        return (hours * 60 + minutes).toString();
    }

    public static ToTimeOnlyString(
        date: Readonly<Date>
    ): string {
        const options: Intl.DateTimeFormatOptions = {
            hour:"2-digit",
            minute: "2-digit"
        };
        const dateString: string = DateHelper.getDateString(date, options);
        const timeString: string = dateString.slice(dateString.indexOf(" "));
        return timeString;
    }

    public static GetLocalDateFromUTCDate(date: Readonly<Date>): Date {
        const aDate: Date = new Date(date.getTime());
        aDate.setMinutes(date.getMinutes() - new Date().getTimezoneOffset());
        return aDate;
    }

    public static GetUTCDateFromLocalDate(date: Readonly<Date>): Date {
        const aDate: Date = new Date(date.getTime());
        aDate.setMinutes(date.getMinutes() + new Date().getTimezoneOffset());
        return aDate;
    }

    public static GetDateFromStringIgnoringTimezone(dateString: string): Date {
        if(dateString.endsWith("Z")){
            dateString = dateString.slice(0, -1);
        }
        const date: Date = new Date(dateString);
        return date;
    }

    public static GetDateISOWithoutConversion(date: Readonly<Date>): string {
        return date.getFullYear() + "-" + this.pad(date.getMonth() + 1) + "-" + this.pad(date.getDate()) + "T" + this.pad(date.getHours()) + ":" + this.pad(date.getMinutes()) + ":" + this.pad(date.getSeconds()) + "Z";
    }

    public static GetDateISOWithoutMilliseconds(date: Readonly<Date> | Date | null): string {
        if(!date){
            return "";
        }
        return date.toISOString().slice(0, -5) + "Z";
    }

    public static pad(arg0: number): string|number{
        return arg0 < 10 ? "0" + arg0 : arg0;
    }

    public static IsDateInRange(
        isThis: Readonly<Date>,
        afterThis?: Readonly<Date>,
        beforeThis?: Readonly<Date>
    ): boolean {
        let isDateInRange: boolean = false;
        const isThisTime: number = isThis.getTime();

        if (afterThis && beforeThis) {
            isDateInRange = isThisTime >= afterThis.getTime() && isThisTime <= beforeThis.getTime();
        } else if (afterThis) {
            isDateInRange = isThisTime >= afterThis.getTime();
        } else if (beforeThis) {
            isDateInRange = isThisTime <= beforeThis.getTime();
        } else {
            isDateInRange = true;
        }

        return isDateInRange;
    }

    private static isNumeric(str: number | string): boolean {     
        let result: boolean = !isNaN(+str) && !isNaN(parseFloat(str.toString()));
        if(result){
            result = str !== "" && str !== null && str !== undefined && !isNaN(Number(str));
        }
        return result;
    }

    private static getDateString(
        date: Readonly<Date>,
        options: Intl.DateTimeFormatOptions
    ): string {
        let dateString: string = date.toLocaleDateString(this.locales, options);
        // strip non-standard characters characters (IE11 prints 200E)
        // note: this regex matches any character that is NOT in the unicode range x20 to x7E or a new line.
        // this is the standard 'latin' part of unicode.
        dateString = dateString.replace(/[^\x20-\x7E\n]+/g, "");
        // IE11 prints the AM and PM in uppercase where all other browsers do it in lower. make consistent
        dateString = dateString.replace(" AM:", " am:").replace(" PM:", " pm:");
        return dateString;
    }

    public static isToday(initialDate: Date): boolean {
        const today: Date = new Date();
        return (
            initialDate.getDate() == today.getDate() &&
            initialDate.getMonth() == today.getMonth() &&
            initialDate.getFullYear() == today.getFullYear()
        );
    }

    public static getStartOfDay(date?: Date): Date {
        const result: Date = date ? new Date(date) : new Date();
        result.setHours(0, 0, 0, 0);
        return result;
    }

    public static getEndOfDay(date?: Date): Date {
        const result: Date = date ? new Date(date) : new Date();
        result.setHours(23, 59, 59, 999);
        return result;
    }

    public static getLastSunday(): Date {
        const initialDate: Date = new Date();
        initialDate.setDate(initialDate.getDate() - initialDate.getDay());
        initialDate.setHours(0, 0, 0, 0);
        return initialDate;
    }

    public static getNextDate(dayIndex: DayIndex, initialDate?: Date): Date {
        const date: Date = initialDate ? new Date(initialDate) : new Date();
        date.setDate(date.getDate() + ((dayIndex - 1 - date.getDay() + 7) % 7) + 1);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public static getLastDayInMonth(monthStep: MonthStep): Date {
        const initialDate: Date = new Date();
        const lastDayInMonth: Date = new Date(
            initialDate.getFullYear(),
            initialDate.getMonth() + monthStep,
            0
        );
        lastDayInMonth.setHours(0, 0, 0, 0);
        return lastDayInMonth;
    }

    public static addWorkdays(date: Date, workDays: number, holidays?: Date[] | null): Date {
        const result: Date = new Date(date);
        let counter: number = 0;
        function isWeekend(d: Date): boolean {
            // 0 - sunday; 6 - saturday;
            return d.getDay() === 0 || d.getDay() === 6;
        }
        while (counter < workDays || isWeekend(result)) {
            if (!isWeekend(result) && !this.isHoliday(result, holidays)) {
                counter++;
            }
            result.setDate(result.getDate() + 1);
        }
        return result;
    }

    public static isHoliday(d: Date, holidays: Date[] | null | undefined): boolean{
        let result: boolean = false;
        if(holidays && holidays.length > 0){
            const dateWithoutTime: Date = new Date(d);
            result = holidays.findIndex(h => h.setHours(0, 0, 0, 0) === dateWithoutTime.setHours(0, 0, 0, 0 )) > -1;
        }
        
        return result;
    }

    public static isEqual(d1: Date | string | undefined | null, d2: Date | string | undefined | null): boolean {
        let result: boolean = (!d1 && !d2) || (d1 === d2);
        if(!result && d1 && d2) {
            const now: number = Date.now();
            const firstDate: Date = new Date(d1 || now);
            const secondDate: Date = new Date(d2 || now);
            result = firstDate.getTime() === secondDate.getTime();
        }

        return result;
    }
}
