import * as React from "react";
import {
    IIconProps,
    ITextField,
    TextField
} from "@fluentui/react";
import styles from "./TextBox.module.scss";
import {
    ControlContainer
} from "../ControlContainer/ControlContainer";
import SPHelper from "../../../helpers/SharePointHelper";
import { ITextFieldStyles } from "@fluentui/react/lib/TextField";

export interface ITextBoxProps {
    name: string;
    required: boolean;
    defaultValue?: string;
    value?: string;
    description?: string;
    rows?: number;
    readonly?: boolean;
    maxLength?: number;
    disabled?: boolean;
    onChange?: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => void;
    iconProps?: IIconProps;
    valid?: boolean;
    preventBorderColour?: boolean;
}

export default class TextBox extends React.Component<ITextBoxProps> {
    public componentRef: ITextField | null = null;

    public getValue(): string {
        if (!this.componentRef) {
            throw new Error("Attempting to call getValue() before render()");
        }
        return this.componentRef.value || "";
    }

    // Function returns partial styles for textbox
    public getStyles(textValid:boolean, preventStyles:boolean): Partial<ITextFieldStyles> {
        return {
            fieldGroup: [
                (!preventStyles && !textValid) && {
                    borderColor: "red",
                },
            ]            
        };
    }
    

    public render(): React.ReactElement<ITextBoxProps> {
        const key: string = `${SPHelper.GetSafeFilename(this.props.name)}Control`;
        const isMultiLine: boolean = !!this.props.rows && this.props.rows > 1;
        let maxLength: number;
        if (!!this.props.maxLength && this.props.maxLength > 0) {
            maxLength = this.props.maxLength;
        } else if (isMultiLine) {
            maxLength = 4000;
        } else {
            maxLength = 250;
        }

        const textValid:boolean = (this.props.valid != undefined)?this.props.valid:true;
        return (            
            <ControlContainer>
                <TextField
                    id={key}
                    key={key}
                    className={styles.textField}           
                    styles={this.getStyles(textValid, !!this.props.preventBorderColour)}                               
                    componentRef={r => (this.componentRef = r)}
                    label={`${this.props.name}:`}
                    ariaLabel={`${this.props.name} text input control`}
                    required={this.props.required}
                    multiline={isMultiLine ? true : undefined}
                    rows={isMultiLine ? this.props.rows : undefined}
                    description={this.props.description}
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    readOnly={this.props.readonly}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    maxLength={maxLength}
                    iconProps={this.props.iconProps}
                />
            </ControlContainer>
        );
    }
}
