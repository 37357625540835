import {
    useSelector
} from "react-redux";
import React from "react";
import {
    Pivot,
    PivotItem,
    IPivotItemProps,
    Icon
} from "@fluentui/react";
import {
    RecordsTimeliness
} from "./recordsTimeliness/RecordsTimeliness";
import styles from "./RecordsStatistics.module.scss";
import {
    RecordsProgress
} from "./recordsProgress/RecordsProgress";
import {
    TasksPipeline
} from "./tasksPipeline/TasksPipeline";
import IApiService from "../../services/Api/IApiService";
import {
    IGetRecordsRequest,
    IRecordResult,
    IRecordResults,
    RecordViewType
} from "../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import {
    selectProxy
} from "../../app/globalSlices/contextSlice";
import { mp_Stage, mp_DueDateRecommender, mp_DueDateDecisionMaker, mp_RequestFrom, mp_DueDateCurrentTask } from "../../providers/Constants/SearchConstants";

interface IRecordsStatisticsProps {
    mobileView: boolean;
}

export function RecordsStatistics({ mobileView }: IRecordsStatisticsProps): JSX.Element {
    const proxy: string | undefined = useSelector(selectProxy);
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [myRecords, setMyRecords] = React.useState<IRecordResult[] | undefined>(undefined);
    const [activeRecords, setActiveRecords] = React.useState<IRecordResult[] | undefined>(undefined);
    const [proxyUsed, setProxyUsed] = React.useState<string | undefined>(proxy);

    const getMyRecords: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const request: IGetRecordsRequest = {
            getRefiners: false,
            getResults: true,
            recordViewType: RecordViewType.My,
            proxy: proxy,
            saveConfiguration: false,
            managedProperties: [
                mp_Stage,
                mp_DueDateRecommender,
                mp_DueDateDecisionMaker,
                mp_RequestFrom
            ]
        };
        const recordsResponse: IRecordResults | null = await apiService.GetRecords(request);
        return recordsResponse;
    }, [proxy]);

    const getActiveTaskRecords: () => Promise<IRecordResults | null> = React.useCallback(async (): Promise<IRecordResults | null> => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const request: IGetRecordsRequest = {
            getRefiners: false,
            getResults: true,
            recordViewType: RecordViewType.Active,
            proxy: proxy,
            saveConfiguration: false,
            managedProperties: [
                mp_DueDateCurrentTask
            ]
        };
        const recordsResponse: IRecordResults | null = await apiService.GetRecords(request);
        return recordsResponse;
    }, [proxy]);

    React.useEffect(() => {
        if (proxy !== proxyUsed || !myRecords) {
            (async () => {
                try {
                    setInProgress(true);
                    setProxyUsed(proxy);

                    // fetch in parallel
                    const myRecordsTask: Promise<IRecordResults | null> = getMyRecords();
                    const activeRecordsTask: Promise<IRecordResults | null> = getActiveTaskRecords();
                    const myRecords: IRecordResults | null = await myRecordsTask;
                    const activeRecords: IRecordResults | null = await activeRecordsTask;

                    setMyRecords(myRecords?.records);
                    setActiveRecords(activeRecords?.records);
                } finally {
                    setInProgress(false);
                }
            })();
        }
    }, [getMyRecords, myRecords, proxyUsed, proxy, getActiveTaskRecords]);

    function renderMobilePivot(
        link?: IPivotItemProps,
        defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
    ): JSX.Element | null {
        if (!link || !defaultRenderer) {
            return null;
        }
      
        return (
            <div className={styles.pivotItemContainer} > 
                <Icon iconName={link.itemIcon} className={styles.pivotIcon} />
                {defaultRenderer({ ...link, itemIcon: undefined })}
            </div>
        );
    }

    return (
        <div className={styles.recordsStatisticsContainer}>
            <div className={styles.recordsCardsInfoContainer}>
                {!mobileView ? (
                    <>
                        <TasksPipeline inProgress={inProgress} records={activeRecords} />
                        <RecordsTimeliness inProgress={inProgress} records={myRecords} />
                        <RecordsProgress inProgress={inProgress} records={myRecords} />
                    </>
                ) : (
                    <Pivot className={styles.pivotContainer}>
                        <PivotItem
                            className={styles.pivotItem}
                            headerText="Pipeline"
                            itemIcon="DocumentApproval"
                            ariaLabel={"Pipeline pivot"}
                            onRenderItemLink={renderMobilePivot}
                        >
                            <TasksPipeline
                                mobileView={mobileView}
                                inProgress={inProgress}
                                records={activeRecords}
                            />
                        </PivotItem>
                        <PivotItem
                            className={styles.pivotItem}
                            headerText="Timeliness"
                            itemIcon="CalendarMirrored"
                            ariaLabel={"Timeliness pivot"}
                            onRenderItemLink={renderMobilePivot}
                        >
                            <RecordsTimeliness
                                mobileView={mobileView}
                                inProgress={inProgress}
                                records={myRecords}
                            />
                        </PivotItem>
                        <PivotItem
                            className={styles.pivotItem}
                            headerText="Progress"
                            itemIcon="Chart"
                            ariaLabel={"Progress pivot"}
                            onRenderItemLink={renderMobilePivot}
                        >
                            <RecordsProgress
                                mobileView={mobileView}
                                inProgress={inProgress}
                                records={myRecords}
                            />
                        </PivotItem>
                    </Pivot>
                )}
            </div>
        </div>
    );
}
