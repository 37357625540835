import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    ActionButton,
    Checkbox,
    ChoiceGroup,
    DefaultButton,
    Dropdown,
    IChoiceGroupOption,
    IChoiceGroupProps,
    Icon,
    IDropdownOption,
    ISelectableOption,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField,
    TooltipHost
} from "@fluentui/react";
import {
    Initiator,
    InitiatorInternalName
} from "./IWantFormConstant";
import ModalContainer from "../ModalContainer/ModalContainer";
import DatePicker from "../DatePicker/DatePicker";
import {
    IContentTypeField,
    IFieldValue,
    IIWantToDialog,
    IRecommendation,
    IRecommendations,
    IRecord,
    ISimpleUser,
    ITaskMapping,
    IUpdateRecordKeyRolesRequest,
    IUpdateTaskRequest,
    IUser,
    IWorkflowRole,
    IWorkflowTask
} from "../../../services/Api/executor/IApiServiceExecutor";
import { peopleAndRolesTabKey } from "../../../providers/Constants/RecordWizardConstants";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import {
    mergeRecord,
    getIWantToOptionsAsync,
    updateRecordKeyRolesAsync,
    setIWantToOptions
} from "../../../features/manageRecordWrapper/manageRecordWrapperSlice";
import { selectIsMobile } from "./../../../app/globalSlices/contextSlice";
import PersonHoverCard from "../PersonHoverCard/PersonHoverCard";
import styles from "./IWantForm.module.scss";
import RecommendationsControl from "./RecommendationsControl/RecommendationsControl";
import { castRecordToUserKeyRoles, parseJsonArrayOfUsers } from "../../../helpers/KeyRolesHelper";
import PeoplePicker from "../PeoplePicker/PeoplePicker";
import { setFieldValues, setInitiator} from "../../../features/wizardKeyRoles/wizardKeyRolesSlice";
import { isCicSelectedInArray, updateFieldValues } from "../../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";

interface IChoiceGroupControlProps extends IChoiceGroupProps {
    roles: IWorkflowRole[];
}

interface IFormData {
    assignedUsers?: string[];
    task?: IWorkflowTask;
    dueDate?: Date;
    comments?: string;
    role?: IWorkflowRole;
    recommendations?: Array<IRecommendation>;
}

interface IIWantFormProps {
    record: IRecord;
    fields: IContentTypeField[];
    skipRecordUpdate: boolean;
    onSubmit: (updatedData: IFormOutputData) => Promise<IRecord | null>;
    onCancel: () => void;
    dialogInfo: IIWantToDialog;
    buttonOptionLabel?: string;
}

export interface IFormOutputData {
    comments?: string;
    recordId: string;
    dueDate?: string;
    customTextField?: string;
    action?: string;
    transitionId: string;
    newTasks?: ITaskMapping[];
    recommendations?: IRecommendations;
}

export default function IWantForm({
    record,
    fields,
    onSubmit,
    onCancel,
    dialogInfo
}: IIWantFormProps): JSX.Element {
    const { t } = useTranslation();
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<IFormData | undefined>(undefined);
    const [tasksOptions, setTasksOptions] = React.useState<Array<IDropdownOption>>([]);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const [roleUser, setRoleUser] = React.useState<ISimpleUser[] | undefined>(undefined);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const isMobile: boolean = useSelector(selectIsMobile);

    const assignedUsersRequired: boolean = !!dialogInfo.roles && !!dialogInfo.roles?.length;
    const roleRequired: boolean = false;
    const taskRequired: boolean = !!dialogInfo.tasks?.length;
    const dueDateRequired: boolean = !!dialogInfo.canSelectDueDate;
    const commentsRequired: boolean = dialogInfo.isCommentsRequired
        || (!dialogInfo.roles?.length && !dialogInfo.tasks?.length);

    const recommendationsRequired: boolean = !!dialogInfo.canRecordRecommendations;

    const hasAllRequiredFields: boolean =
        (assignedUsersRequired ? !!formData?.assignedUsers?.length : true) &&
        (roleRequired ? !!formData?.role?.internalName?.length : true) &&
        (taskRequired ? !!formData?.task : true) &&
        (dueDateRequired ? !!formData?.dueDate : true) &&
        (commentsRequired ? !!formData?.comments : true) &&
        (recommendationsRequired ? !!formData?.recommendations?.every(recommendItem => !!recommendItem.state) : true);

    const onConfirm: () => Promise<void> = React.useCallback(async () => {
        try {
            if (formData) {
                setInProgress(true);
                setErrorMessage(undefined);

                // get the user that will be assigned new tasks
                const assignedToData: ISimpleUser[] =
                    formData.assignedUsers?.map(userString => JSON.parse(userString)) || [];

                // get the due date for the new tasks. either from the form, or the static date
                // provided with the dialog info. otherwise send no date and the task will get the default due date.
                const date: Date | undefined =
                    dialogInfo.canSelectDueDate && formData.dueDate
                        ? new Date(formData.dueDate)
                        : dialogInfo.dueDateCurrentTask ? new Date(dialogInfo.dueDateCurrentTask) : undefined;

                // generate the task mappings to pass to the api
                const tasks: ITaskMapping[] = assignedToData.map(a => {
                    return {
                        assignedTo: a,
                        assignedToRole: formData.role,
                        dueDate: date,
                        createdDate: new Date(),
                        comments: formData.comments || "",
                        task: formData.task,
                    };
                });
                const data: IUpdateTaskRequest = {
                    recordId: record.recordId || "",
                    comments: formData.comments,
                    action: dialogInfo.action,
                    transitionId: dialogInfo.overrideTransitionId || formData.task?.transitionId || "",
                    newTasks: tasks,
                    recommendations:
                        formData.recommendations && dialogInfo.canRecordRecommendations
                            ? { recommendations: formData.recommendations }
                            : undefined
                };
                const updatedRecord: IRecord | null = await onSubmit(data);
                if (updatedRecord) {
                    dispatch(mergeRecord(updatedRecord));
                    if(updatedRecord.iWantToOptionCollection){
                        dispatch(setIWantToOptions(updatedRecord.iWantToOptionCollection));
                    }else{
                        dispatch(getIWantToOptionsAsync(updatedRecord.recordId || ""));
                    }
                    onCancel();
                } else {
                    setErrorMessage("Unable to update record stage ...");
                }
            }
        } finally {
            setInProgress(false);
        }
    }, [
        formData,
        dialogInfo.canSelectDueDate,
        dialogInfo.dueDateCurrentTask,
        dialogInfo.action,
        dialogInfo.overrideTransitionId,
        dialogInfo.canRecordRecommendations,
        record.recordId,
        onSubmit,
        onCancel,
        dispatch
    ]);

    const castPersonCard: (user: IUser | ISimpleUser) => JSX.Element = React.useCallback(
        (user: IUser | ISimpleUser): JSX.Element => {
            return <PersonHoverCard className={styles.people} person={user} />;
        },
        []
    );

    const getUsersByRole: (
        role: IWorkflowRole
    ) => Array<IUser | ISimpleUser> | undefined = React.useCallback(
        (role: IWorkflowRole): Array<IUser | ISimpleUser> | undefined => {
            let usersByRole: Array<IUser | ISimpleUser> | undefined = undefined;
            switch (role.internalName) {
                case InitiatorInternalName:
                case Initiator:
                    usersByRole =
                        record.initiator && record.initiator?.length ? record.initiator : undefined;
                    break;
                default: {
                    const userField: IContentTypeField | undefined = fields?.find(fieldItem => fieldItem.displayName === role.displayName || fieldItem.internalName === role.internalName);
                    const userFeildValue: IUser[] = parseJsonArrayOfUsers((record?.fieldValues || []).find(v => v.fieldInternalName === userField?.internalName)?.valueInJson || "[]");
                    usersByRole = userFeildValue && userFeildValue.length ? userFeildValue : undefined;
                    break;
                }
            }
            return usersByRole;
        },
        [fields, record?.fieldValues, record.initiator]
    );

    const getFullNameRoleOption: (optionName: IWorkflowRole, isEmptyRoleUser?: boolean) => string = React.useCallback(
        (optionName: IWorkflowRole): string => {
            let fullNameOption: string = optionName.displayName;
            switch (optionName.internalName) {    
                case InitiatorInternalName:           
                case Initiator:
                    fullNameOption = Initiator;
                    break;               
                default: {
                    const userField: IContentTypeField | undefined = fields?.find(fieldItem => fieldItem.displayName === optionName.displayName || fieldItem.internalName === optionName.internalName);
                    fullNameOption = userField?.displayName || optionName + " (invalid role field)";
                    break;
                }
            }
            return fullNameOption;
        },
        [fields]
    );

    const onRoleChange: (
        user: Partial<IUpdateRecordKeyRolesRequest>
    ) => void = React.useCallback(
        (user: Partial<IUpdateRecordKeyRolesRequest>) => {
            if (record.recordId && !inProgress) {
                setInProgress(true);
                dispatch(
                    updateRecordKeyRolesAsync(
                        {
                            ...user,
                            recordId: record.recordId,
                            comments: ""
                        },
                        {
                            onSuccess: () => {
                                setInProgress(false);
                            },
                            onError: err => {
                                setErrorMessage(err.message);
                                setInProgress(false);
                            }
                        }
                    )
                );
            }
        },
        [dispatch, inProgress, record.recordId]
    );

    const updateRoleUser: (user: ISimpleUser[], role: IWorkflowRole) => void = React.useCallback(
        (user: ISimpleUser[], role: IWorkflowRole): void => {
            let updateRecordKeyRolesObj: Partial<IUpdateRecordKeyRolesRequest> | undefined = undefined;
            switch (role.internalName) {
                case InitiatorInternalName:
                case Initiator:
                    dispatch(setInitiator((user || [])[0]));
                    updateRecordKeyRolesObj = { initiator: user || [] };
                    break;
                default: {
                    const userField: IContentTypeField | undefined = fields?.find(fieldItem => fieldItem.displayName === role.displayName || fieldItem.internalName === role.internalName);
                    const fieldValues: IFieldValue[] = updateFieldValues(record?.fieldValues || null, userField?.internalName || "", JSON.stringify(user || []));
                    dispatch(setFieldValues(fieldValues));
                    updateRecordKeyRolesObj = { fieldValues: fieldValues || [] };
                    break;
                }
            }
            if (updateRecordKeyRolesObj) {
                onRoleChange(updateRecordKeyRolesObj);
            }
            setFormData({
                ...formData,
                role: role,
                assignedUsers: [
                    ...((formData?.assignedUsers as string[]) || []),
                    JSON.stringify(user[0])
                ]
            });
        },
        [dispatch, formData, onRoleChange, record?.fieldValues, fields]
    );

    const redirectionButton: (
        role: IWorkflowRole,
        isStandartButton?: boolean
    ) => JSX.Element = React.useCallback(
        (role: IWorkflowRole, isStandartButton?: boolean): JSX.Element => {
            return !isStandartButton ? (
                <ActionButton
                    className={[
                        styles.buttonToEnterData,
                        inProgress ? styles.disabledPersonCard : ""
                    ].join(" ")}
                    primary
                    href={UrlHelper.castUrlWithQueryStringParam(
                        peopleAndRolesTabKey,
                        URL_QUERY_KEYS.TAB
                    )}
                    onClick={() => onCancel()}
                    disabled={inProgress}
                >
                    Add {getFullNameRoleOption(role, true)}
                </ActionButton>
            ) : (
                <DefaultButton
                    className={inProgress ? styles.disabledPersonCard : ""}
                    text={`Add ${getFullNameRoleOption(role, true)}`}
                    href={UrlHelper.castUrlWithQueryStringParam(
                        peopleAndRolesTabKey,
                        URL_QUERY_KEYS.TAB
                    )}
                    allowDisabledFocus
                    disabled={inProgress}
                />
            );
        },
        [getFullNameRoleOption, inProgress, onCancel]
    );

    const renderPeopleByRole: (role: IWorkflowRole) => JSX.Element[] | JSX.Element = React.useCallback(
        (role: IWorkflowRole): JSX.Element[] | JSX.Element => {
            const peopleData: (IUser | ISimpleUser)[] | undefined = getUsersByRole(role);
            let peopleElements: JSX.Element[] | JSX.Element = [];
            if (peopleData) {
                peopleElements = peopleData.map(personItem => castPersonCard(personItem));
            } else {
                peopleElements = redirectionButton(role);
            }
            return peopleElements;
        },
        [castPersonCard, getUsersByRole, redirectionButton]
    );

    const getChoiceOption: (
        role: IWorkflowRole,
        users?: (IUser | ISimpleUser)[]
    ) => IChoiceGroupOption = React.useCallback(
        (role: IWorkflowRole, users?: (IUser | ISimpleUser)[]): IChoiceGroupOption => {
            return {
                key: role.internalName,
                text: getFullNameRoleOption(role),
                // eslint-disable-next-line react/display-name
                onRenderField: (props, render) => {
                    return (
                        <div className={styles.choiceFieldContainer}>
                            <div className={styles.choiceFieldHeaderContainer}>
                                {render?.(props)}
                                <div className={styles.colon}>:</div>
                                <div className={styles.hoverPeopleCardContainer}>
                                    {renderPeopleByRole(role)}
                                </div>
                            </div>
                            {role?.internalName === formData?.role?.internalName && !formData.assignedUsers?.length && (
                                <div className={styles.choiceFieldPeoplePickerContainer}>
                                    <div className={styles.searchPeople}>
                                        <PeoplePicker
                                            label={""}
                                            onChange={(user: ISimpleUser[] | undefined) => {
                                                setRoleUser(user);
                                            }}
                                            selectedUsers={roleUser}
                                            disabled={inProgress}
                                        />
                                    </div>
                                    <PrimaryButton
                                        className={styles.addUserInChoiceControl}
                                        iconProps={{ iconName: "Add" }}
                                        allowDisabledFocus
                                        disabled={!roleUser || inProgress}
                                        onClick={() => updateRoleUser(roleUser as ISimpleUser[], role)}
                                    >
                                        Add
                                    </PrimaryButton>
                                </div>
                            )}
                        </div>
                    );
                },
                value: users ? users.map(userItem => JSON.stringify(userItem)) : undefined,
                disabled: inProgress
            };
        },
        [formData?.assignedUsers?.length, formData?.role, getFullNameRoleOption, inProgress, renderPeopleByRole, roleUser, updateRoleUser]
    );

    const getRoleOptions: (roles: IWorkflowRole[]) => Array<IChoiceGroupOption> = React.useCallback(
        (roles: IWorkflowRole[]): Array<IChoiceGroupOption> => {
            const options: Array<IChoiceGroupOption> = [];
            roles.map((roleItem: IWorkflowRole) => {
                const usersByRole: (IUser | ISimpleUser)[] | undefined = getUsersByRole(roleItem);
                options.push(getChoiceOption(roleItem, usersByRole));
            });
            return options;
        },
        [getChoiceOption, getUsersByRole]
    );

    const defaultTasksOptions: Array<IDropdownOption> = React.useMemo((): Array<IDropdownOption> => {
        const options: Array<IDropdownOption> = [];
        if (dialogInfo.tasks && dialogInfo.tasks.length) {
            dialogInfo.tasks
                .filter(t => t)
                .map((taskItem: IWorkflowTask) =>
                    options.push({
                        key: taskItem.id || "",
                        text: taskItem.name || ""
                    })
                );
        }
        return options;
    }, [dialogInfo.tasks]);

    React.useEffect(() => {
        if (dialogInfo.tasks && !tasksOptions.length) {
            setTasksOptions(defaultTasksOptions);
        }
    }, [defaultTasksOptions, dialogInfo.tasks, tasksOptions]);

    React.useEffect(() => {
        if (tasksOptions.length === 1 && !formData?.task) {
            setFormData({
                ...formData,
                task: {
                    id: tasksOptions[0].key as string,
                    name: tasksOptions[0].text,
                    transitionId: dialogInfo.tasks?.find(t => t.id === tasksOptions[0].key)?.transitionId || "",
                }
            });
        }
    }, [formData, tasksOptions, dialogInfo.tasks]);

    //This function is only for "Send on to branch to allocate lead author" on "Initiate" stage
    const filterTasksOptionsBySelectingRole: (
        roleInternalName: string
    ) => IDropdownOption[] = React.useCallback(
        (roleInternalName: string): IDropdownOption[] => {
            let filteredTasksOptions: IDropdownOption[] = [];

            // do not attempt to filter if only one task available
            if (defaultTasksOptions.length > 1) {
                for(const task of dialogInfo.tasks || []) {
                    if(task.roleAssignee && task.roleAssignee.toLowerCase() === roleInternalName.toLowerCase()) {
                        filteredTasksOptions.push({
                            key: task.id || "",
                            text: task.name || ""
                        });
                    }
                }

                // if the filter removes all options, then just return all default options as something has gone wrong.
                if (filteredTasksOptions.length < 1) {
                    filteredTasksOptions = defaultTasksOptions;
                }
            }
            return filteredTasksOptions;
        },
        [defaultTasksOptions, dialogInfo.tasks]
    );

    const roleControls: (
        role: IWorkflowRole,
        usersByRole: (IUser | ISimpleUser)[]
    ) => JSX.Element[] = React.useCallback(
        (role: IWorkflowRole, usersByRole: (IUser | ISimpleUser)[]): JSX.Element[] => {
            const controls: JSX.Element[] = [];
            usersByRole.map(userItem => {
                return controls.push(
                    <div className={styles.roleControls}>
                        <Checkbox
                            label={getFullNameRoleOption(role)}
                            disabled={inProgress}
                            onChange={(
                                ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                                checked?: boolean | undefined
                            ) => {
                                if (checked) {
                                    setFormData({
                                        ...formData,
                                        role: role,
                                        assignedUsers: [
                                            ...((formData?.assignedUsers as string[]) || []),
                                            JSON.stringify(userItem)
                                        ]
                                    });
                                } else if (checked !== undefined) {
                                    // eslint-disable-next-line @typescript-eslint/typedef
                                    const removedItemId = formData?.assignedUsers?.findIndex(
                                        assignedUserItem =>
                                            assignedUserItem === JSON.stringify(userItem)
                                    );
                                    if (typeof removedItemId === "number") {
                                        if ((removedItemId as number) > -1) {
                                            const updatedDataAssignUsers: string[] | undefined =
                                                formData?.assignedUsers;
                                            (updatedDataAssignUsers || []).splice(removedItemId, 1);
                                            setFormData({
                                                ...formData,
                                                assignedUsers: updatedDataAssignUsers
                                            });
                                        }
                                    }
                                }
                            }}
                        />
                        <div className={styles.hoverPeopleCardContainer}>
                            {castPersonCard(userItem)}
                        </div>
                    </div>
                );
            });
            return controls;
        },
        [castPersonCard, formData, getFullNameRoleOption, inProgress]
    );

    const setChoiceOption: (option: IChoiceGroupOption) => void = React.useCallback(
        (option: IChoiceGroupOption): void => {
            const filteredTasksOptions: IDropdownOption[] = filterTasksOptionsBySelectingRole(
                option.key
            );
            const role: IWorkflowRole = dialogInfo.roles?.find(r => r.internalName === option.key) || { internalName: "", displayName: "" };
            if (
                filteredTasksOptions && filteredTasksOptions.length && filteredTasksOptions.length >= 1
            ) {
                setTasksOptions(filteredTasksOptions);                
                return setFormData({
                    ...formData,
                    role: role,
                    assignedUsers: (option.value as string[]) || [],
                    task: {
                        id: filteredTasksOptions[0].key as string,
                        name: filteredTasksOptions[0].text,
                        transitionId: dialogInfo.tasks?.find(t => t.id === filteredTasksOptions[0].key)?.transitionId || "",
                    }
                });
            } else {
                return setFormData({
                    ...formData,
                    role: role,
                    assignedUsers: (option.value as string[]) || []
                });
            }
        },
        [filterTasksOptionsBySelectingRole, formData, dialogInfo.tasks, dialogInfo.roles]
    );

    React.useEffect(() => {
        if (dialogInfo.roles?.length === 1 && !formData?.role?.internalName) {
            setChoiceOption(getRoleOptions(dialogInfo.roles)[0]);
        }
    }, [dialogInfo.canSelectMultipleRoles, dialogInfo.roles, formData?.role, getRoleOptions, setChoiceOption]);

    const choiceGroupControl: (props: IChoiceGroupControlProps) => JSX.Element = React.useCallback(
        (props: IChoiceGroupControlProps): JSX.Element => {
            return (
                <ChoiceGroup
                    disabled={inProgress}
                    className={styles.choiceRoleContainer}
                    options={getRoleOptions(props.roles)}
                    selectedKey={formData?.role?.internalName}
                    onChange={(
                        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                        option?: IChoiceGroupOption | undefined
                    ) => {
                        if (option) {
                            return setChoiceOption(option);
                        }
                    }}
                />
            );
        },
        [formData?.role, getRoleOptions, inProgress, setChoiceOption]
    );

    const renderRolesControls: (
        roles: IWorkflowRole[]
    ) => Array<JSX.Element | JSX.Element[]> | JSX.Element = React.useCallback(
        (roles: IWorkflowRole[]): Array<JSX.Element | JSX.Element[]> | JSX.Element => {
            if (dialogInfo.canSelectMultipleRoles) {
                const multipleRolesControls: Array<JSX.Element | JSX.Element[]> = [];
                roles.map(roleItem => {
                    const usersByRole: (IUser | ISimpleUser)[] | undefined = getUsersByRole(
                        roleItem
                    );
                    multipleRolesControls.push(
                        usersByRole ? (
                            usersByRole.length > 1 ? (
                                roleControls(roleItem, usersByRole)
                            ) : (
                                choiceGroupControl({
                                    roles: [roleItem]
                                })
                            )
                        ) : (
                            // <p>{redirectionButton(roleItem, true)}</p>
                            choiceGroupControl({
                                roles
                            })
                        )
                    );
                });
                return multipleRolesControls;
            } else {
                return choiceGroupControl({
                    roles
                });
            }
        },
        [
            choiceGroupControl,
            dialogInfo.canSelectMultipleRoles,
            getUsersByRole,
            roleControls
        ]
    );

    React.useEffect(() => {
        if (
            dialogInfo.canRecordRecommendations &&
            !formData?.recommendations &&
            record?.recommendations &&
            record?.recommendations?.recommendations?.length
        ) {
            setFormData({ ...formData, recommendations: record?.recommendations?.recommendations });
        }
    }, [dialogInfo.canRecordRecommendations, formData, record?.recommendations]);

    React.useEffect(() => {
        if (formData?.recommendations && formData?.recommendations.length) {
            if (formData.recommendations.every(recommendItem => !recommendItem.id)) {
                const recommendations: IRecommendation[] = formData.recommendations.map(
                    (recommendItem, index) => {
                        if (!recommendItem.id) {
                            return { ...recommendItem, id: `${index}` };
                        } else {
                            return recommendItem;
                        }
                    }
                );
                setFormData({ ...formData, recommendations });
            }
        }
    }, [formData]);

    const setRecommendationState: (
        state: string,
        recommendationId: string,
        value: boolean
    ) => void = React.useCallback(
        (state: string, recommendationId: string, value: boolean): void => {
            const recommendations: IRecommendation[] = [...(formData?.recommendations || [])];
            const idx: number = recommendations.findIndex(
                recommendItem => recommendItem.id === recommendationId
            );
            if (idx > -1) {
                recommendations.splice(idx, 1, {
                    ...recommendations[idx],
                    state: value ? state : undefined
                });
            }
            return setFormData({
                ...formData,
                recommendations: recommendations
            });
        },
        [formData]
    );

    React.useEffect(() => {
        if (!formData?.dueDate && dialogInfo.dueDateCurrentTask) {
            setFormData({ ...formData, dueDate: new Date(dialogInfo.dueDateCurrentTask) });
        }
    }, [dialogInfo.dueDateCurrentTask, formData]);

    React.useEffect(() => {
        if (dialogInfo.hideTaskSelection && dialogInfo?.tasks?.length && !formData?.task) {
            const task: IWorkflowTask = dialogInfo.tasks[0];
            setFormData({
                ...formData,
                task: { id: task.id || "", name: task.name || "", transitionId: task.transitionId || "" }
            });
        }
    }, [dialogInfo.hideTaskSelection, dialogInfo.tasks, formData]);

    React.useEffect(() => {
        if (dialogInfo.hideRoleSelection && dialogInfo?.roles?.length && !formData?.role) {
            const opts: Array<IChoiceGroupOption> = getRoleOptions(dialogInfo.roles);
            setChoiceOption(opts[0]);
        }
    }, [dialogInfo.hideRoleSelection, dialogInfo.roles, formData, getRoleOptions, setChoiceOption]);

    const dialogDescription: string = React.useMemo(() => {
        const restrictAccessTeams: string = !record.overrideDefaultAccess ? "N/A" : !record.restrictAccessTeams || record.restrictAccessTeams.length < 1 ? "None" : record.restrictAccessTeams.map(t => t.name).join(", ");
        const keyRoles: string = castRecordToUserKeyRoles(record)
            .filter(u => u.usersNames)
            .map(u => `<p>${u.role}: ${u.usersNames}</p>`)
            .join("");
        const viewAccessUsersString: string =
            keyRoles +
            (record?.viewAccessUsers || [])
                .map(c => `<p>${c.jobTitle}: ${c.displayName}</p>`)
                .join("");
        const cabinetWorkingFileNumber: string = record.cabinetWorkingFolderNumber ? record.cabinetWorkingFolderNumber : "N/A";
        const isCICSelected: boolean = isCicSelectedInArray(record.securityClassification);
        const cabinetWorkingFileNumberSection: string =
            isCICSelected
                ? `<h3>Cabinet working folder number:</h3><div>${cabinetWorkingFileNumber}</div><mark>Cabinet in Confidence records must have a copy of their briefing pack and activity log saved into the Cabinet working folder in Content Manager.</mark>`
                : "";
        return !dialogInfo.description
            ? ""
            : dialogInfo.description
                .replaceAll("{recordId}", record.recordId || "")
                .replaceAll(
                    "{overrideDefaultAccess}",
                    record.overrideDefaultAccess ? "Restricted" : "Default"
                )
                .replaceAll("{restrictAccessTeams}", restrictAccessTeams)
                .replaceAll(
                    "{securityClassification}",
                    !record.securityClassification || record.securityClassification.length < 1
                        ? "Not classified"
                        : record.securityClassification.map(c => c.name).join(", ")
                )
                .replaceAll("{cabinetWorkingFileNumber}", cabinetWorkingFileNumberSection)
                .replaceAll(
                    "{viewAccessUsers}",
                    viewAccessUsersString ? viewAccessUsersString : "None"
                );
    }, [dialogInfo.description, record]);

    const onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <div>
                <div>{option.text}</div>
            </div>
        );
    };

    return (
        <ModalContainer
            title={dialogInfo.title || ""}
            submitLabel={dialogInfo.buttonLabel}
            isOpen={true}
            inProgress={inProgress}
            hideFooter={dialogInfo.hideButtons}
            submitDisabled={!hasAllRequiredFields || inProgress || dialogInfo.disableForm}
            onSubmit={onConfirm}
            onCancel={onCancel}
        >
            <>
                {dialogInfo.description && (
                    <div
                        className={styles.descriptionForm}
                        dangerouslySetInnerHTML={{
                            __html: dialogDescription
                        }}
                    />
                )}
                {dialogInfo.roles &&
                    ((!dialogInfo.hideRoleSelection && !!dialogInfo.roles.length)
                        || (dialogInfo.hideRoleSelection && !!dialogInfo.roles.length && !getUsersByRole(dialogInfo.roles[0])))
                    && (
                        <div className={styles.rolesContainer}>
                            {renderRolesControls(dialogInfo.roles)}
                        </div>
                    )}
                {dialogInfo.tasks && !!dialogInfo.tasks?.length && !dialogInfo.hideTaskSelection && (
                    <>
                        <Dropdown
                            required={taskRequired}
                            styles={{
                                dropdownItem: {
                                    height: "auto"
                                }
                            }}
                            disabled={inProgress || dialogInfo.disableForm || (!dialogInfo.hideRoleSelection && !formData?.role)}
                            className={styles.dropDownContainer}
                            placeholder={t("taskDropdownPlaceholder")}
                            label={t("taskDropdownLabel")}
                            options={tasksOptions}
                            selectedKey={formData?.task?.id}
                            ariaLabel={"Record Action"}
                            onChange={(
                                event: React.FormEvent<HTMLDivElement>,
                                option?: IDropdownOption | undefined
                            ) => {
                                if (option) {
                                    return setFormData({
                                        ...formData,
                                        task: { id: option.key as string, name: option.text, transitionId: dialogInfo.tasks?.find(t => t.id === option.key)?.transitionId || "" }
                                    });
                                }
                            }}
                            onRenderOption={isMobile ? (option: ISelectableOption | undefined) => onRenderOption(option as IDropdownOption) : undefined}
                        />
                        <br />
                    </>
                )}
                {dialogInfo.canSelectDueDate && (
                    <>
                        <DatePicker
                            className={styles.dueDateContainer}
                            required={dueDateRequired}
                            disabled={inProgress || dialogInfo.disableForm}
                            dateLabel={t("dueDateLabel")}
                            timeLabel={t("dueTimeLabel")}
                            ariaLabel={t("dueDatePlaceholder")}
                            value={formData?.dueDate}
                            includeTime={true}
                            minDate={dialogInfo.minDueDate ? new Date(dialogInfo.minDueDate) : new Date()}
                            maxDate={dialogInfo.maxDueDate ? new Date(dialogInfo.maxDueDate) : undefined}
                            hoursMinMax={{ min: 7, max: 19 }}
                            onChange={(date: Date | null | undefined) => {
                                if (date) {
                                    return setFormData({
                                        ...formData,
                                        dueDate: date
                                    });
                                }
                            }}
                            invalidRangeMessage={"This date must be prior to the next due date"}
                        />
                        <br />
                    </>
                )}
                {dialogInfo.canRecordRecommendations &&
                    <RecommendationsControl
                        recommendationsRequired={recommendationsRequired}
                        recommendations={formData?.recommendations}
                        inProgress={inProgress}
                        setRecommendationState={setRecommendationState}
                    />
                }
                {!dialogInfo.hideCommentsInput &&
                    <>
                        <TextField
                            required={commentsRequired}
                            disabled={inProgress || dialogInfo.disableForm}
                            label={dialogInfo.commentType ? dialogInfo.commentType : t("commentsTextFieldLabel")}
                            multiline
                            autoAdjustHeight
                            ariaLabel={`${dialogInfo.commentType ? dialogInfo.commentType : t("commentsTextFieldLabel")} text field`}
                            onChange={(
                                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                newValue?: string | undefined
                            ) => {
                                return setFormData({
                                    ...formData,
                                    comments: newValue?.trim() || ""
                                });
                            }}
                        />
                        <div className={styles.whoComments}>{t("whoCanViewLabel")}
                            <TooltipHost content={t("whoCanViewDescription")} className={styles.toolTip}>
                                <Icon iconName="Info" className={styles.infoIcon} />
                            </TooltipHost>
                        </div>
                    </>
                }
                {errorMessage &&
                    <MessageBar
                        className={styles.errorMessage}
                        isMultiline={false}
                        messageBarType={MessageBarType.error}
                    >
                        {errorMessage}
                    </MessageBar>
                }
            </>
        </ModalContainer>
    );
}
