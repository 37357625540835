import React from "react";
import {
    DefaultButton,
    IIconProps
} from "@fluentui/react";
import styles from "./TemplateList.module.scss";
import ModalContainer from "../ModalContainer/ModalContainer";
import {
    ITemplate
} from "../../../services/Api/executor/IApiServiceExecutor";
import {
    TemplateDeleteModalConfirmationMessage,
    TemplateDeleteModalConfirmButtonText,
    TemplateDeleteModalTitle,
    UpdateSignedDocButtonText
} from "../../../providers/Constants/FormControlConstants";

interface ITemplateListProps {
    availableTemplates: ITemplate[];
    onClick: (delTemplate: ITemplate) => void;
    disabled?: boolean;
}

const deleteIconProps: IIconProps = { iconName: "Delete", className: styles.iconBlue };
const editIconProps: IIconProps = { iconName: "PageEdit", className: styles.iconBlue};

export default function TemplateList(props: ITemplateListProps): JSX.Element {
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalSubmitLabel, setModalSubmitLabel] = React.useState("");
    const [modalContent, setModalContent] = React.useState("");
    const [currentTemplate, setCurrentTemplate] = React.useState<ITemplate>({ name: "", url: "" });

    // Dismisses the open modal
    const dismissModal = () => {
        setDeleteModalOpen(false);
    };

    // Deletes the template from the template list.
    const deleteFile = () => {
        props.onClick(currentTemplate);
        setDeleteModalOpen(false);
    };

    return (
        <div className={styles.templateListContainer}>
            {props.availableTemplates &&
                props.availableTemplates.length > 0 &&
                props.availableTemplates.map(tmpl => (
                    <div key={tmpl.name} className={styles.selectedTemplate}>
                        <div className={styles.selectedTemplateContainer}>
                            <div className={styles.templateNameStyle}>
                                {tmpl.name}
                            </div>
                        </div>
                        <div className={styles.removeButtonContainer}>
                            {tmpl.signatureRequired &&
                                <DefaultButton
                                    text={UpdateSignedDocButtonText}
                                    // onClick={() => {
                                    //     // Setting properties for the delete modal dialog and opening it.
                                    //     setModalTitle(TemplateDeleteModalTitle);
                                    //     setModalSubmitLabel(TemplateDeleteModalConfirmButtonText);
                                    //     setModalContent(TemplateDeleteModalConfirmationMessage);
                                    //     setCurrentTemplate({
                                    //         name: tmpl.name,
                                    //         url: tmpl.url
                                    //     });
                                    //     setDeleteModalOpen(true);
                                    // }}
                                    allowDisabledFocus
                                    disabled={props.disabled}
                                    iconProps={editIconProps}
                                    className={styles.buttonStyling}
                                    ariaLabel={`${UpdateSignedDocButtonText} button`}
                                />
                            }
                            <DefaultButton
                                text="Delete"
                                onClick={() => {
                                    // Setting properties for the delete modal dialog and opening it.
                                    setModalTitle(TemplateDeleteModalTitle);
                                    setModalSubmitLabel(TemplateDeleteModalConfirmButtonText);
                                    setModalContent(TemplateDeleteModalConfirmationMessage);
                                    setCurrentTemplate({
                                        name: tmpl.name,
                                        url: tmpl.url
                                    });
                                    setDeleteModalOpen(true);
                                }}
                                allowDisabledFocus
                                disabled={props.disabled}
                                iconProps={deleteIconProps}
                                className={styles.buttonStyling}
                                ariaLabel={"Delete button"}
                            />
                        </div>
                    </div>
                ))}
            <ModalContainer
                isOpen={deleteModalOpen}
                title={modalTitle}
                submitLabel={modalSubmitLabel}
                onCancel={dismissModal}
                onSubmit={deleteFile}
            >
                <div>{modalContent}</div>
            </ModalContainer>
        </div>
    );
}
