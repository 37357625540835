import React from "react";
import {
    HoverCard,
    HoverCardType,
    IPlainCardProps,
    KeyCodes,
    Persona,
    PersonaSize
} from "@fluentui/react";
import { ISimpleUser, IUser } from "../../../services/Api/executor/IApiServiceExecutor";
import PersonHoverCard from "../PersonHoverCard/PersonHoverCard";
import styles from "./PeopleWrapper.module.scss";
import { PersonaComponentAltText } from "../../../providers/Constants/FormControlConstants";

interface IPeopleWrapperProps {
    users: (IUser | ISimpleUser)[];
    onBehalfOf?: (IUser | ISimpleUser)[];
    showMax?: number;
}

export default function PeopleWrapper(props: IPeopleWrapperProps): JSX.Element {
    const showMax: number = props.showMax || 1;
    const usersToShow: (IUser | ISimpleUser)[] = (props.users || []).slice(0, showMax);
    const additionalUsers: (IUser | ISimpleUser)[] = (props.users || []).slice(showMax);

    const onBehalfOfToShow: (IUser | ISimpleUser)[] = (props.onBehalfOf || []).slice(0, showMax);
    const additionalOnBehalfOf: (IUser | ISimpleUser)[] = (props.onBehalfOf || []).slice(showMax);

    const onRenderPlainCard = (): JSX.Element => {
        return (
            <div className={styles.additionalUsersContainer}>
                {additionalUsers.map((userItem, index) => {
                    return (
                        <Persona
                            key={userItem.siteUserId || index}
                            className={styles.additionalUser}
                            size={PersonaSize.size24}
                            text={userItem.displayName || undefined}
                            secondaryText={userItem.jobTitle || undefined}
                            data-alt={`${PersonaComponentAltText} ${userItem.displayName || ""}`}
                        />
                    );
                })}
            </div>
        );
    };

    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderPlainCard
    };
    return (
        <div className={styles.peopleContainer}>
            {usersToShow.map((userItem, index) => {
                return <PersonHoverCard key={userItem.siteUserId || index} className={styles.hoverPersonCard} person={userItem} />;
            })}
            {additionalUsers.length > 0 && (
                <HoverCard
                    type={HoverCardType.plain}
                    openHotKey={KeyCodes.enter}
                    plainCardProps={plainCardProps}
                >
                    <span className={styles.additionalText}>and</span>
                    <span className={styles.additionalUsersText}>{`${additionalUsers.length} others`}</span>
                </HoverCard>
            )}
            {onBehalfOfToShow.length > 0 &&
                <>
                    <span className={styles.onBehalfOfText}>on behalf of</span>
                    {onBehalfOfToShow.map((userItem, index) => {
                        return <PersonHoverCard key={userItem.siteUserId || index} className={styles.hoverPersonCard} person={userItem} />;
                    })}
                    {additionalOnBehalfOf.length > 0 && (
                        <HoverCard
                            type={HoverCardType.plain}
                            openHotKey={KeyCodes.enter}
                            plainCardProps={plainCardProps}
                        >
                            <span className={styles.additionalText}>and</span>
                            <span className={styles.additionalUsersText}>{`${additionalOnBehalfOf.length} others`}</span>
                        </HoverCard>
                    )}
                </>
            }
        </div>
    );
}
