import React from "react";
import styles from "./RecordTimeline.module.scss";
import { getDueDateString, renderDueDate } from "./StagesContentUtils";
import { Icon } from "@fluentui/react";
import PeopleWrapper from "../PeopleWrapper/PeopleWrapper";
import { currentTask, versionTask } from "../../../providers/Constants/RecordTitleConstants";
import { IContentTypeField, IRecord, ISimpleUser, ITimelineEntry } from "../../../services/Api/executor/IApiServiceExecutor";
import { useTranslation } from "react-i18next";
import { ActiveStageButtons } from "./ActiveStageButtons";

interface ITimelineEntryProps {
    record: IRecord;
    fields: IContentTypeField[];
    isMobileView?: boolean;
    isBlocked?: boolean;
    drawTasks: boolean;
    activeActivityIndex: number;
    index: number;
    item: ITimelineEntry;
}

export default function TimelineEntry(props: ITimelineEntryProps): JSX.Element {
    const { t } = useTranslation();
   
    return (
        <div className={[styles.stage, props.drawTasks ? styles.active : (props.index === props.activeActivityIndex ? styles.predecessorActiveStep : "")].join(" ")}>            
            <div className={styles.leftSide}>
                <h3 className={styles.label}>{(!props.drawTasks && props.activeActivityIndex === props.index) ? "" : props.item.stageName}</h3>
                <div className={styles.leftSectionAdditionalInfo}>
                    {props.drawTasks ? props.item.currentTasks.map((task, i) => {
                        return <div key={i}>
                            <div className={styles.calendarDate}>
                                <Icon iconName="Calendar" ariaLabel={"Calendar icon"} />
                                <span>
                                    {getDueDateString(task?.dueDate || "")}
                                </span>
                            </div>
                            <div className={styles.dueDateContainer}>
                                <p>
                                    <Icon iconName="AlertSolid" ariaLabel={"Due date icon"} />
                                    {t("dateDueTo") + " " + task.assignedToRole}
                                </p>
                            </div>
                        </div>;
                    }) : <></>}
                </div>
            </div>
            <div className={styles.midArea}>
                <div className={`${styles.point} ${props.drawTasks ? styles.current : (props.index <= props.activeActivityIndex ? styles.closed : styles.opened)}`}></div>
            </div>
            <div className={styles.rightSide}>
                {props.isMobileView && <>
                    <h3 className={styles.label}>{props.item.stageName}</h3>
                    {props.drawTasks ? props.item.currentTasks.map((task, i) => {
                        return <div key={i}>
                            <div className={styles.calendarDate}>
                                <Icon iconName="Calendar" ariaLabel={"Calendar icon"} />
                                <span>
                                    {getDueDateString(task.dueDate || "")}
                                </span>
                            </div>
                            <div className={styles.dueDateContainer}>
                                <p>
                                    <Icon iconName="AlertSolid" ariaLabel={"Due date icon"} />
                                    {t("dateDueTo") + " " + task.assignedToRole}
                                </p>
                            </div>
                        </div>;
                    }) : <></>}
                </>}
                {props.item.currentTasks?.length && props.drawTasks ? <>
                    {!props.isBlocked && <>
                        {<div className={styles.content}>
                            <h4>{versionTask}: </h4>
                            <div className={styles.peopleContainer}>
                                <PeopleWrapper users={props.item.currentTasks.flatMap(v=>v.assignedTo).filter(v=>v!==undefined).map(v=>v as ISimpleUser)} />
                            </div>
                        </div>}
                        <div className={styles.contentWithoutFlex}>
                            <span className={styles.spanHeader} style={{
                                marginRight: "5px"
                            }}>{currentTask}: </span>{" "}
                            {props.item.currentTasks.map(t => {
                                return <span className={styles.taskItem} key={t.task?.name}>
                                    {`${t.task?.name}. Due ${getDueDateString(t.dueDate || "", true)}. `}
                                </span>;
                            })}
                        </div>
                        <ActiveStageButtons record={props.record} fields={props.fields}/>
                    </>}
                    {props.isBlocked && <>
                        {props.item.isBlocked && <>
                            <div className={styles.content}>
                                {props.item.currentTasks?.map(t => {
                                    return <> 
                                        <h4>{t.task?.name} </h4>
                                        <div className={styles.peopleContainer}>
                                            <PeopleWrapper users={t.assignedTo ? [t.assignedTo] : []} />
                                        </div>
                                        {getDueDateString(t.dueDate || "", true)}
                                    </>;
                                })}
                                <>test</>                                     
                            </div>
                            {!!props.item.comments && <div className={styles.content}>
                                <h4 style={{
                                    marginRight: "5px"
                                }}>Reason: </h4>{" "}
                                <div className={styles.taskItem}>
                                    {props.item.comments}
                                </div>
                            </div>}
                        </>}
                    </>}
                </> : props.index <= props.activeActivityIndex 
                    ?
                    <>
                        <div>
                            {props.item.subEntries?.length ? props.item.subEntries.map((subEntry, i) => {
                                return <div key={i} className={styles.content}>
                                    <div className={styles.textInfoContainer}>{subEntry.activityDescription || (subEntry.users?.length ? subEntry.role + " assigned to " : "")}</div>
                                    <div className={styles.peopleContainer}>
                                        <PeopleWrapper users={subEntry.users || []} onBehalfOf={subEntry.onBehalfOf} />
                                    </div>
                                    {renderDueDate(subEntry.completedDate)}
                                </div>;
                            }) : <></>}
                        </div>
                    </> 
                    : 
                    <>
                        {props.item.subEntries?.length ? props.item.subEntries.map((subEntry, i) => {
                            return <div key={i} className={styles.content}>
                                <div className={styles.textInfoContainer}>{subEntry.users?.length ? subEntry.role + " "+t("assignedTo")+" " : subEntry.role}</div>
                                <div className={styles.peopleContainer}>
                                    {subEntry.users?.length ? <PeopleWrapper users={subEntry.users || []} /> : t("notYetAssigned")}
                                </div>
                            </div>;
                        }) 
                            : 
                            <></>}
                    </>}
            </div>
        </div>);
}