import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import { MessageBar, MessageBarType, Spinner } from "@fluentui/react";
import styles from "./WatermarkPdf.module.scss";
import { useTranslation } from "react-i18next";
import { setAppTitle } from "../../app/globalSlices/contextSlice";

export function WatermarkPdf(): JSX.Element {
    const { fileRef } = useParams<{ fileRef: string }>();
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [base64, setBase64] = React.useState<string>("");
    const { t } = useTranslation();

    useEffect(() => {
        setAppTitle(t("watermarkPdfPageTitle"));
        setInProgress(true);
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        apiService
            .GetWatermarkPdf(fileRef)
            .then(response => {
                setBase64(response || "");
            })
            .catch(error => {
                setError(error.message);
            })
            .finally(() => {
                setInProgress(false);
            });
    }, [fileRef, t]);

    return (
        <div className={styles.container}>
            {inProgress ? (
                <MessageBar>Generating PDF, please wait...</MessageBar>
            ) : error ? (
                <MessageBar messageBarType={MessageBarType.error}>
                    <span>{error}</span>
                </MessageBar>
            ) : (
                <MessageBar messageBarType={MessageBarType.success}>
                    <span>PDF generated successfully.</span>
                </MessageBar>
            )}
            {inProgress && (
                <Spinner label="Generating..." ariaLive="assertive" labelPosition="top" />
            )}
            <object data={base64} type="application/pdf" height="100%" width="100%">
                <iframe src={base64} height="100%" width="100%">
                    <p>This browser does not support PDF!</p>
                </iframe>
            </object>
        </div>
    );
}
