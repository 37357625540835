// 1. IMPORTS
import {
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import IApiService from "../../../services/Api/IApiService";
import {
    IUser,
    IUsers
} from "../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import {
    AppThunk,
    RootState
} from "../../../app/store";

// import IUser from "../../../services/Api/IApiService";

// 3. FEATURE STATE
export interface IPeoplePickerState {
    selectedUser: IUser | null;
    filteredUsers: IUser[] | null;
}

export const initialState: IPeoplePickerState = {
    selectedUser: null,
    filteredUsers: null
};

// 4. FEATURE SLICE
// eslint-disable-next-line @typescript-eslint/typedef
const peoplePickerSlice = createSlice({
    name: "peoplePicker",
    initialState,
    reducers: {
        setSelectedUser: (state: IPeoplePickerState, action: PayloadAction<IUser | undefined>) => {
            if (!action || !action.payload) {
                state.selectedUser = null;
            } else {
                state.selectedUser = action.payload;
            }
        },
        setFilteredUsers: (state: IPeoplePickerState, action: PayloadAction<IUser[] | undefined>) => {
            if (!action || !action.payload) {
                state.filteredUsers = null;
            } else {
                state.filteredUsers = action.payload;
            }
        }
    }
});
// 5. SYNCHRONOUS ACTIONS
export const { setSelectedUser, setFilteredUsers } = peoplePickerSlice.actions;

// 6. ASYNCHRONOUS ACTIONS
export const getFilteredUsers = (filterText: string): AppThunk => async dispatch => {
    const resolver: DependencyResolver = new DependencyResolver();
    const apiService: IApiService = resolver.ResolveIApiService();
    const users: IUsers | null = await apiService.GetUsers(filterText);

    dispatch(setFilteredUsers(users?.users || []));
};

// 7. SELECTORS
export const selectSelectedUser = (state: RootState): IUser | null => state.peoplePicker.selectedUser;

export default peoplePickerSlice.reducer;
