import React from "react";
import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import ModalContainer from "../../../components/FormControls/ModalContainer/ModalContainer";
import {
    placeholderRecommendFieldType,
    recommendationDescription,
    recommendFieldLabelRecommend,
    recommendFieldLabelType
} from "../../../providers/Constants/ManageRecordConstants";
import {
    IRecommendation,
    ITerm
} from "../../../services/Api/executor/IApiServiceExecutor";
import { useSelector } from "react-redux";
import { selectRecommendationTypes } from "../../manageRecordWrapper/manageRecordDetails/manageRecordDetailsSlice";

export enum RecommendFormType {
    New,
    Edit,
    Delete
}
export interface IRecommendFormProps {
    type: RecommendFormType;
    recommendation?: IRecommendation;
    onSubmit: (updatedRecommendation: IRecommendation) => void;
    onCancel: () => void;
}

export default function RecommendForm({
    type,
    recommendation,
    onSubmit,
    onCancel
}: IRecommendFormProps): JSX.Element {
    const [inProgress, setInProgress] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<Partial<IRecommendation> | undefined>(recommendation);
    const recommendationTypesTerms: ITerm[] = useSelector(selectRecommendationTypes);

    const hasRequiredData: boolean = React.useMemo(() => {
        return !!formData?.type && !!formData?.description;
    },[formData?.description, formData?.type]);

    const onConfirm: () => void = React.useCallback((): void => {
        try {
            setInProgress(true);
            if (hasRequiredData) {
                onSubmit(formData as IRecommendation);
            }
            onCancel();
        } finally {
            setInProgress(false);
        }
    }, [formData, hasRequiredData, onCancel, onSubmit]);

    const recommendationTypesOptions: IDropdownOption[] = React.useMemo((): IDropdownOption[] => {
        return (recommendationTypesTerms || [])
            .map((recommendTypeItem) => ({key: recommendTypeItem.name || "", text: recommendTypeItem.name || ""}));
    }, [recommendationTypesTerms]);

    const title: string = React.useMemo(() => {
        let result: string = "Add";
        switch(type){
            case RecommendFormType.Edit:
                result = "Edit";
                break;
            case RecommendFormType.Delete:
                result = "Delete";
                break;
        }
        return result;
    }, [
        type
    ]);

    const submitTitle: string = React.useMemo(() => {
        let result: string = "Save";
        switch(type){
            case RecommendFormType.Edit:
                result = "Save";
                break;
            case RecommendFormType.Delete:
                result = "Delete";
                break;
        }
        return result;
    }, [
        type
    ]);

    const fromDisabled: boolean = type == RecommendFormType.Delete || inProgress;

    return (
        <ModalContainer
            title={`${title} recommendation`}
            description={recommendationDescription}
            submitLabel={submitTitle}
            isOpen={true}
            inProgress={inProgress}
            submitDisabled={!hasRequiredData}
            onSubmit={onConfirm}
            onCancel={onCancel}
        >
            <>
                <Dropdown
                    disabled={fromDisabled}
                    placeholder={placeholderRecommendFieldType}
                    required
                    label={recommendFieldLabelType}
                    defaultSelectedKey={formData?.type}
                    options={recommendationTypesOptions as IDropdownOption[]}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption | undefined
                    ) => {
                        if (option) {
                            return setFormData({
                                ...formData,
                                type: option.key as string
                            });
                        }
                    }}
                />
                <br />
                <TextField
                    disabled={fromDisabled}
                    label={recommendFieldLabelRecommend}
                    required
                    multiline
                    autoAdjustHeight
                    defaultValue={formData?.description || undefined}
                    onChange={(
                        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue?: string | undefined
                    ) => {
                        return setFormData({
                            ...formData,
                            description: newValue
                        });
                    }}
                />
            </>
        </ModalContainer>
    );
}
