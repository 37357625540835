import { Link } from "react-router-dom";
import React from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { hasUserPermission } from "./overviewRecommendUtils";
import styles from "./OverviewRecommend.module.scss";
import {
    IContentTypeField,
    IIWantToDialog,
    IIWantToOption,
    IRecord,
    IUpdateTaskRequest,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import { ManageFormType, selectActiveFields, selectCurrentRecord, selectFormType, selectIWantToOptions, selectInactiveFields } from "../manageRecordWrapper/manageRecordWrapperSlice";
import { AppRoutes } from "../../app/Constants";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import { Icon, Separator, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import DateHelper from "../../helpers/DateHelper";
import { ContactContainer } from "../../components/FormControls/ContactContainer/ContactContainer";
import { selectCurrentUser, selectIsMobile } from "../../app/globalSlices/contextSlice";
import { isMakeDecisionStage } from "../../components/FormControls/RecordTimeline/StagesContentUtils";
import IWantForm from "../../components/FormControls/IWantForm/IWantForm";
import { actions } from "../../providers/Constants/TermConstants";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";
import PeopleWrapper from "../../components/FormControls/PeopleWrapper/PeopleWrapper";
import { ManageEventPanel } from "../manageRecordWrapper/manageEventRecord/ManageEventPanel";
import { ManageParliamPanel } from "../manageRecordWrapper/manageParliamRecord/ManageParliamPanel";
import { isFieldInActive } from "../../helpers/ModelHelper";
import { useTranslation } from "react-i18next";

interface IFormProps {
    dialog: IIWantToDialog;
    onSubmit: (updatedData: IUpdateTaskRequest) => Promise<IRecord | null>;
    buttonOptionLabel?: string;
}

export function OverviewRecommendPanel(): JSX.Element {
    const { t } = useTranslation();
    const record: IRecord | null = useSelector(selectCurrentRecord);
    const currentUser: IUser | null | undefined = useSelector(selectCurrentUser);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);

    const recordDecisionOption: IIWantToOption | undefined = useSelector(selectIWantToOptions)?.options.find(option => option.label === actions.recordDecision);
    const [formProps, setFormProps] = React.useState<IFormProps | undefined>(undefined);
    const formType: ManageFormType = useSelector(selectFormType);
    const hasAnalysis: boolean = !!record?.analysis;
    const hasRecommendations: boolean = !!record?.recommendations?.recommendations?.length;
    const hasTopic: boolean = !!record?.topicSummary;
    const recommendationsSectionName: string = formType === ManageFormType.Correspondence ? t("correspondenceDecisionHeader") : t("recommendationsHeader");
    const isMobile: boolean = useSelector(selectIsMobile);
    const isMakeDecision: boolean = isMakeDecisionStage(record?.stage);

    const decisionComments: string = React.useMemo(() => {
        let result: string = t("decisionComments");
        if (record?.decisionCategory && record.decisionCategory.length === 1) {
            result = record.decisionCategory[0].name + " comments:";
        }
        return result;
    }, [record?.decisionCategory, t]);

    const showAddEditBtn: boolean | undefined = hasUserPermission(record, (currentUser || null))
        && !record?.noFurtherAction
        && !record?.withdrawn;
    const showEditBtn: boolean = hasAnalysis || hasRecommendations || hasTopic;

    return (
        <div className={styles.overviewRecommendContainer}>
            <div className={[
                styles.overviewRecommendDataContainer,
                isMobile ? styles.mobileOverviewRecommendDataContainer : ""
            ].join(" ")}>
                {showAddEditBtn && (
                    <>
                        {record && (
                            <Link
                                to={AppRoutes.buildUrl(AppRoutes.recordRecommendations, {
                                    recordId: record.recordId || ""
                                })}
                            > {showEditBtn ? (
                                    <DefaultButton
                                        className={styles.submitButton}
                                        iconProps={{ iconName: "Edit" }}
                                    >
                                        {t("editOverviewAndRecommend")}
                                    </DefaultButton>
                                ) : (
                                    <PrimaryButton className={styles.submitButton}>
                                        {t("addOverviewAndRecommend")}
                                    </PrimaryButton>
                                )}
                            </Link>
                        )}
                    </>
                )}
                <div className={styles.subjectContainer}>
                    <div
                        className={[
                            (!isFieldInActive("ABCTopicSummary", inactiveFields) || !isFieldInActive("ABCAnalysis", inactiveFields)) ? styles.topicAnalysisSection : "",
                            hasTopic || hasAnalysis ? "" : styles.blockedTopicAnalysisSection
                        ].join(" ")}
                    >
                        {!isFieldInActive("ABCTopicSummary", inactiveFields) && (
                            <div>
                                <h2 className={styles.sybjectHeader}>{t("topicHeader")}</h2>
                                {hasTopic ? (
                                    <p className={styles.subjectText}>{record?.topicSummary}</p>
                                ) : (
                                    <p className={styles.subjectText}>{t("topicNotExitMessage")}</p>
                                )}
                            </div>)}
                        {!isFieldInActive("ABCAnalysis", inactiveFields) && (
                            <>
                                <span className={styles.divider}></span>
                                <div>
                                    <h2 className={styles.sybjectHeader}>{t("analysisHeader")}</h2>
                                    {hasAnalysis ? (
                                        <p className={styles.subjectText}>{record?.analysis}</p>
                                    ) : (
                                        <p className={styles.subjectText}>{t("analysisNotExitMessage")}</p>
                                    )}
                                </div>
                            </>
                        )}

                    </div>

                    {formType === ManageFormType.Correspondence &&
                        <>
                            <div className={styles.responseDetailsContainer}>
                                <div className={styles.responseDetailsHeader}>
                                    <h2 className={styles.sybjectHeader}>{t("correspondenceResponseDetails")}</h2>
                                    {record?.originalCorrespondence?.name && 
                                        <DefaultButton
                                            allowDisabledFocus
                                            text={t("viewMessageButtonLabel")}
                                            href={record?.originalCorrespondence?.path + "?web=1"}
                                        />
                                    }
                                </div>
                                <div className={styles.responseDetailsContent}>
                                    <div className={styles.responseDetailsCRow}>
                                        <div className={styles.columnType}>{t("fieldLabelDateWritten")}: </div>
                                        <div className={styles.columnValue}>
                                            {DateHelper.ToDateOnlyString(
                                                new Date(record?.dateWritten || ""),
                                                true
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.responseDetailsCRow}>
                                        <div className={styles.columnType}>{t("fieldLabelDateReceived")}: </div>
                                        <div className={styles.columnValue}>
                                            {DateHelper.ToDateOnlyString(
                                                new Date(record?.dateReceived || ""),
                                                true
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Separator />
                                <div className={styles.contactsContainer}>
                                    <div className={styles.contactColumn}>
                                        <div className={styles.columnType}>{t("fieldLabelFrom")}: </div>
                                        {record?.from &&
                                            <div className={styles.contactsList}>
                                                <ContactContainer
                                                    contact={record.from}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.contactColumn}>
                                        <div className={styles.columnType}>{t("fieldLabelTo")}: </div>
                                        <div className={styles.contactsList}>
                                            {record?.to?.map(c =>
                                                <ContactContainer
                                                    key={c.email}
                                                    contact={c}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    <div
                        className={[
                            styles.recommendationsSection,
                            hasRecommendations ? "" : styles.blockedRecommendationsSection
                        ].join(" ")}
                    >
                        <h2 className={styles.sybjectHeader}>{recommendationsSectionName}</h2>
                        {hasRecommendations ? (
                            <div>
                                {(record?.recommendations?.recommendations || []).map((r, i) => (
                                    <div key={r.id} className={styles.recommendationPreviewItem}>
                                        <div className={`${styles.info} ${isMobile ? styles.mobileInfo : ""}`}>
                                            <div className={styles.number}>{i + 1}.</div>
                                            <span className={`${styles.descr} ${isMobile ? styles.mobileDescr : ""}`}>
                                                <span className={styles.type}>
                                                    {r.type == "Agreed" ? "AGREE" : r.type == "Noted" ? "NOTE" : r.type == "Approved" ? "APPROVE" : r.type == "Signed" ? "SIGN" : r.type}
                                                </span>
                                                <div className={styles.recommendationPreviewItemDescr}>{r.description}</div>
                                            </span>
                                        </div>
                                        <div className={[
                                            styles.checkboxes,
                                            isMobile ? styles.mobileCheckboxex : "",
                                            record?.decisionMadeBy || isMakeDecision ? styles.makeDesicionCheckboxes : ""
                                        ].join(" ")
                                        }>
                                            <div className={styles.OptionsContainer}>
                                                <Checkbox
                                                    name={r.type}
                                                    checked={r.state === r.type}
                                                    required={false}
                                                    onChange={undefined}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className={styles.OptionsContainer}>
                                                {(!(r.type == "Noted") &&
                                                    <Checkbox
                                                        name={"Not " + (r.type as string || "").toLowerCase()}
                                                        checked={r.state === "Not " + (r.type as string || "").toLowerCase()}
                                                        required={false}
                                                        onChange={undefined}
                                                        disabled={true}
                                                    />) || (r.type == "Noted" &&
                                                        <Checkbox
                                                            name={t("pleaseDiscussLabel")}
                                                            checked={r.state === t("pleaseDiscussLabel")}
                                                            required={false}
                                                            onChange={undefined}
                                                            disabled={true}
                                                        />)
                                                }
                                            </div>
                                            <div className={styles.OptionsContainer}>
                                                {r.type != "Noted" &&
                                                    <Checkbox
                                                        name={t("pleaseDiscussLabel")}
                                                        checked={r.state === t("pleaseDiscussLabel")}
                                                        required={false}
                                                        onChange={undefined}
                                                        disabled={true}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {isMakeDecision && recordDecisionOption &&
                                    <div className={styles.button} onClick={() => {
                                        setFormProps({
                                            dialog: recordDecisionOption?.dialog ? recordDecisionOption.dialog : {},
                                            onSubmit: async (updatedData: IUpdateTaskRequest) => {
                                                const resolver: DependencyResolver = new DependencyResolver();
                                                const apiService: IApiService = resolver.ResolveIApiService();
                                                return await apiService.UpdateRecordIWantToAction(updatedData);
                                            },
                                            buttonOptionLabel: recordDecisionOption?.label
                                        });
                                    }}>
                                        <PrimaryButton text={actions.recordDecision} />
                                    </div>
                                }
                                {formProps && (
                                    <IWantForm
                                        record={record ? record : {}}
                                        fields={currentFields || []}
                                        dialogInfo={formProps.dialog}
                                        skipRecordUpdate={false}
                                        onSubmit={formProps.onSubmit}
                                        onCancel={() => setFormProps(undefined)}
                                        buttonOptionLabel={formProps.buttonOptionLabel}
                                    />
                                )}
                                {!isMakeDecision && !record?.decisionMadeBy &&
                                    <div className={styles.footer}>
                                        <div className={styles.icon}>
                                            <TooltipHost>
                                                <Icon iconName="Info" className={styles.infoIcon} />
                                            </TooltipHost>
                                        </div>
                                        <div className={styles.descr}>
                                            These options will become active when the record is sent to the Decision Maker.
                                        </div>
                                    </div>
                                }
                                {record?.decisionMadeBy &&
                                    <div>
                                        <div className={styles.decisionMadeContainer}>
                                            <div className={styles.icon}>
                                                <TooltipHost>
                                                    <Icon iconName="CompletedSolid" className={styles.successIcon} />
                                                </TooltipHost>
                                            </div>
                                            <div className={styles.approveMessage}>
                                                {t("recommendationDecisionMessage")}
                                                <PeopleWrapper users={record.decisionMadeBy.users as IUser[]} />
                                                at {DateHelper.ToDateTimeString(new Date(record.decisionMadeBy?.date))}
                                            </div>
                                        </div>
                                        <div className={styles.replyContainer}>
                                            <div className={styles.replyCommentsLabel}>
                                                {decisionComments}
                                            </div>
                                            <div>
                                                {record.decisionMadeBy.comments
                                                    ? record.decisionMadeBy.comments
                                                    : t("noDecisionCommentsProvided")
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        ) : (
                            <p className={styles.subjectText}>{t("recommendationsNotExitMessage")}</p>
                        )}
                    </div>
                </div>
            </div>
            {formType === ManageFormType.Event &&
                <ManageEventPanel />
            }

            {formType === ManageFormType.Parliamentary &&
                <ManageParliamPanel />
            }
        </div>
    );
}
