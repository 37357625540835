//1. IMPORTS
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    getWizardRecordInformationDataAsync,
    selectCurrentFieldValues,
    selectActiveFields,
    selectDecisionCategories,
    selectOrgLevel1s,
    selectOrgLevel2s,
    selectOrgLevel3s,
    selectOrgLevel4s,
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4,
    selectTermGroup,
    setFieldValues,
    setSelectedDecisionCategory,
    setSelectedOrgLevel1,
    setSelectedOrgLevel2,
    setSelectedOrgLevel3,
    setSelectedOrgLevel4,
    selectInactiveFields,
    selectAllFields
} from "./wizardRecordInformationSlice";
import styles from "./WizardRecordInformation.module.scss";
import {
    selectSelectedRecordCategory,
    selectSelectedRecordType
} from "../wizardCreateRecord/wizardCreateRecordSlice";
import { setPreventNext } from "../recordWizardWrapper/recordWizardWrapperSlice";
import AccessPermissions from "../accessPermissions/AccessPermissions";
import {
    FieldTypes,
    IContentType,
    IContentTypeField,
    IFieldValue,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    ITerm,
    ITermGroup,
    ITermSet
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    fieldLabelRecordType,
    fieldLabelSubRecordType,
    recordInformationHeaderStrings
} from "../../providers/Constants/RecordWizardConstants";
import SummaryField from "../../components/FormControls/SummaryField/SummaryField";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import FormHeader from "../../components/FormControls/FormHeader/FormHeader";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import { selectIsMobile } from "../../app/globalSlices/contextSlice";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import StringHelper from "../../helpers/StringHelper";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import TermsHelper from "../../helpers/TermsHelper";
import { getFieldDescription, getFieldDisplayName, isFieldInActive, updateFieldValues } from "../../helpers/ModelHelper";
import { IChoiceGroupOption, Spinner, SpinnerSize } from "@fluentui/react";
import Choice from "../../components/FormControls/Choice/Choice";
import DateHelper from "../../helpers/DateHelper";
import { useTranslation } from "react-i18next";

export const getISelectOptionPropsFromIterm = (term: ITerm): ISelectOptionProps => {
    return {
        key: term.id || "",
        text: term.name || ""
    };
};

export const getChoiceOptionFromTerm = (term: ITerm): IChoiceGroupOption => {
    return {
        key: term.id || "",
        text: term.name || ""
    };
};

export const getISelectOptionPropsFromIOrgLevel = (
    ol: IOrgLevel1 | IOrgLevel2 | IOrgLevel3 | IOrgLevel4
): ISelectOptionProps => {
    return {
        key: ol.name || "",
        text: ol.name || ""
    };
};

export function WizardRecordInformation(): JSX.Element {
    // 2. SELECT CURRENT GLOBAL STATE
    const { t } = useTranslation();
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);
    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValues);
    const decisionCategories: ITerm[] = useSelector(selectDecisionCategories);
    const orgLevel1s: IOrgLevel1[] = useSelector(selectOrgLevel1s);
    const orgLevel2s: IOrgLevel2[] = useSelector(selectOrgLevel2s);
    const orgLevel3s: IOrgLevel3[] = useSelector(selectOrgLevel3s);
    const orgLevel4s: IOrgLevel4[] = useSelector(selectOrgLevel4s);
    
    const selectedDecisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const selectedOrgLevel1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const selectedOrgLevel2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const selectedOrgLevel3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const selectedOrgLevel4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);

    const selectedRecordCategory: string | null = useSelector(selectSelectedRecordCategory);
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);

    const termGroup: ITermGroup | null = useSelector(selectTermGroup);

    // 3. DEFINE COMPONENT STATE
    const isMobile: boolean = useSelector(selectIsMobile);
    const [isCustomFieldsValid, setIsCustomFieldsValid] = useState(true);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    React.useEffect(() => {
        dispatch(getWizardRecordInformationDataAsync(selectedRecordType?.rowKey || "(empty)"));
    }, [dispatch, selectedDecisionCategory, selectedOrgLevel1, selectedOrgLevel2, selectedRecordType?.rowKey]);

    // 6. RETURN RENDER TEMPLATE
    const decisionCategoriesOptions: ISelectOptionProps[] =
        decisionCategories?.map(getISelectOptionPropsFromIterm) || [];
   
    //add fake value if org level 1 renamed in AAD. levels 2-3 handled in slice
    const orgLevel1Options: ISelectOptionProps[] = useMemo(()=>{
        const options:ISelectOptionProps[] = orgLevel1s?.map(getISelectOptionPropsFromIOrgLevel) || [];
        if(orgLevel1s && orgLevel1s.length && selectedOrgLevel1?.name && orgLevel1s.every(x=>x.name !== selectedOrgLevel1?.name)){
            options.push(getISelectOptionPropsFromIOrgLevel(selectedOrgLevel1));
        }
        return options;
    }, [orgLevel1s, selectedOrgLevel1]);

    const orgLevel2Options: ISelectOptionProps[] =
        orgLevel2s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel3Options: ISelectOptionProps[] =
        orgLevel3s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel4Options: ISelectOptionProps[] =
        orgLevel4s?.map(getISelectOptionPropsFromIOrgLevel) || [];

    React.useEffect(() => {
        const requiredFields: IContentTypeField[] = currentFields?.filter(v => v.wizardPosition == 0 && v.isRequired && v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && !v.notEditable) || [];
        const emptyFieldValues: IFieldValue[] = currentFieldValues?.filter(v => StringHelper.isJsonEmptyObject(v.valueInJson)) || [];
        const someRequiredValuesAreEmpty: boolean = emptyFieldValues.some(v => requiredFields.some(r => r.internalName === v.fieldInternalName));
        const someRequiredFieldNotExist: boolean = !requiredFields.every(r => currentFieldValues?.some(v => v.fieldInternalName === r.internalName));
        const modelIsValid: boolean = !someRequiredValuesAreEmpty && !someRequiredFieldNotExist;
        setIsCustomFieldsValid(modelIsValid);
    }, [
        currentFields,
        currentFieldValues
    ]);

    React.useEffect(() => {
        const modelIsValid: boolean =
            (decisionCategoriesOptions.length > 0 && !isFieldInActive("ABCDecisionCategory", inactiveFields) ? !!selectedDecisionCategory : true) &&
            !!selectedOrgLevel1 &&
            !!selectedOrgLevel2;

        dispatch(setPreventNext(!modelIsValid || !isCustomFieldsValid));
    }, [
        decisionCategoriesOptions.length,
        dispatch,
        selectedDecisionCategory,
        selectedOrgLevel1,
        selectedOrgLevel2,
        isCustomFieldsValid,
        inactiveFields
    ]);

    return (
        <>
            <FormHeader strings={recordInformationHeaderStrings} />
            <div className={styles.fieldsContainer}>
                {selectedRecordCategory && (
                    <FieldWrapper>
                        <SummaryField
                            fieldName={fieldLabelRecordType}
                            fieldValue={selectedRecordCategory}
                        />
                    </FieldWrapper>
                )}
                {selectedRecordType?.rowKey && (
                    <FieldWrapper>
                        <SummaryField
                            fieldName={fieldLabelSubRecordType}
                            fieldValue={selectedRecordType.rowKey}
                        />
                    </FieldWrapper>
                )}
                {allFields?.length ? (
                    <>
                        {decisionCategoriesOptions.length > 0 && !isFieldInActive("ABCDecisionCategory", inactiveFields) && (
                            <FieldWrapper description={getFieldDescription("ABCDecisionCategory", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCDecisionCategory", allFields)}
                                    options={decisionCategoriesOptions}
                                    required={decisionCategoriesOptions.length > 0}
                                    defaultSelectedKey={selectedDecisionCategory?.id || undefined}
                                    disabled={decisionCategoriesOptions.length < 1}
                                    onChange={option => {
                                        dispatch(setSelectedDecisionCategory(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel1", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel1", allFields)}
                                options={orgLevel1Options}
                                required={true}
                                disabled={!orgLevel1Options || orgLevel1Options.length < 1}
                                defaultSelectedKey={selectedOrgLevel1?.name || undefined}
                                showAsLoading={orgLevel1Options.length < 1}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel1(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel2", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel2", allFields)}
                                options={orgLevel2Options}
                                required={true}
                                disabled={!orgLevel2Options || orgLevel2Options.length < 1}
                                defaultSelectedKey={selectedOrgLevel2?.name || undefined}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel2(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        {!isFieldInActive("ABCOrgLevel3", inactiveFields) && 
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel3", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel3", allFields)}
                                    options={orgLevel3Options}
                                    required={false}
                                    disabled={!orgLevel3Options || orgLevel3Options.length < 1}
                                    defaultSelectedKey={selectedOrgLevel3?.name || undefined}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel3(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }
                        {!isFieldInActive("ABCOrgLevel4", inactiveFields) &&                 
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel4", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel4", allFields)}
                                    options={orgLevel4Options}
                                    required={false}
                                    disabled={!orgLevel4Options || orgLevel4Options.length < 1}
                                    defaultSelectedKey={selectedOrgLevel4?.name || undefined}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel4(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }    
                    </>
                ) : (
                    <>
                        <Spinner labelPosition="right" className={styles.spinner} size={SpinnerSize.large} label={t("fields_loading")} />
                    </>
                )}                
                {currentFields?.filter(v => v.wizardPosition == 0 && v.fieldType !== FieldTypes.Role && v.fieldType !== FieldTypes.RoleMultiSelect && !v.notEditable).map((field, index) => {
                    switch (field.fieldType) {
                        case FieldTypes.Text:
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <TextBox
                                        name={field.displayName}
                                        required={field.isRequired}
                                        defaultValue={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                        onChange={(ev, newValue) => {
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                        }}
                                    />
                                </FieldWrapper>);
                        case FieldTypes.MultilineText:
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <TextBox
                                        name={field.displayName}
                                        required={field.isRequired}
                                        rows={5}
                                        defaultValue={currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson}
                                        onChange={(ev, newValue) => {
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, newValue || "")));
                                        }}
                                    />
                                </FieldWrapper>);
                        case FieldTypes.RadioManagedMetadata: {
                            const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                            const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                            //get first selected term
                            const currentValue: string | undefined = selectedTerms?.length ? selectedTerms.map(v => v?.Id)[0] : undefined;
                            const options: IChoiceGroupOption[] = termSet?.terms?.map(getChoiceOptionFromTerm) || [];

                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <Choice
                                        name={field.displayName}
                                        required={field.isRequired}
                                        options={options}
                                        defaultSelectedKey={currentValue}
                                        onChange={option => {
                                            selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.SelectManagedMetadata:
                        case FieldTypes.MultiSelectManagedMetadata: {
                            const termSet: ITermSet | null = TermsHelper.GetTermSetById(termGroup, field.dataSource);
                            const selectedTerms: { Id: string, Name: string }[] = JSON.parse(currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson || "[]") || [];
                            const currentValues: string[] = selectedTerms.map(v => v?.Id);
                            const options: ISelectOptionProps[] = termSet?.terms?.map(getISelectOptionPropsFromIterm) || [];
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <Select
                                        name={field.displayName}
                                        options={options}
                                        required={field.isRequired}
                                        defaultSelectedKey={currentValues}
                                        disabled={!options || options.length < 1}
                                        onChange={option => {
                                            if (selectedTerms.some(v => v.Id === option?.key)) {
                                                selectedTerms.splice(selectedTerms.findIndex(v => v.Id === option?.key), 1);
                                            } else {
                                                selectedTerms.push({ Id: option?.key || "", Name: option?.text || "" });
                                            }
                                            if (field.fieldType === FieldTypes.SelectManagedMetadata) {
                                                selectedTerms.splice(0, selectedTerms.length, { Id: option?.key || "", Name: option?.text || "" });
                                            }
                                            dispatch(setFieldValues(updateFieldValues(currentFieldValues, field.internalName, JSON.stringify(selectedTerms))));
                                        }}
                                        isMultiSelect={field.fieldType === FieldTypes.MultiSelectManagedMetadata}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.DateOnly:
                        case FieldTypes.DateTime: {
                            const dateStringValue: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                            const dateValue: Date | undefined = dateStringValue ? new Date(dateStringValue) : undefined;
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <DatePicker
                                        dateLabel={field.displayName}
                                        timeLabel={""}
                                        placeholder={t("datePickerPlaceholder")}
                                        required={field.isRequired}
                                        includeTime={field.fieldType !== FieldTypes.DateOnly}
                                        hideDate={false}
                                        ariaLabel={field.description}
                                        value={dateValue}
                                        onChange={date => {
                                            const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === field.internalName)
                                                ? currentFieldValues?.map(v =>
                                                    v.fieldInternalName === field.internalName
                                                        ? ({ fieldInternalName: v.fieldInternalName, valueInJson: date?.toISOString() } as IFieldValue)
                                                        : v) || null
                                                : (currentFieldValues || []).concat([{ fieldInternalName: field.internalName, valueInJson: date?.toISOString() } as IFieldValue]);
                                            dispatch(setFieldValues(newValues));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        case FieldTypes.TimeOnly: {
                            const timeAsNumber: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                            const dateValue: Date | undefined = timeAsNumber ? DateHelper.ToTimeOnlyFromNumberAsDate(Number(timeAsNumber)) : undefined;
                            return (
                                <FieldWrapper key={index} description={field.description}>
                                    <DatePicker
                                        dateLabel={field.displayName}
                                        timeLabel={field.displayName}
                                        placeholder={t("datePickerPlaceholder")}
                                        required={field.isRequired}
                                        includeTime={true}
                                        hideDate={field.fieldType === FieldTypes.TimeOnly}
                                        ariaLabel={field.description}
                                        value={dateValue}
                                        onChange={date => {
                                            const dateInUtcIsoString: string | undefined  = date ? DateHelper.ToTimeOnlyFromNumberFromDate(date) : undefined;
                                            const newValues: IFieldValue[] | null = currentFieldValues?.some(v => v.fieldInternalName === field.internalName)
                                                ? currentFieldValues?.map(v =>
                                                    v.fieldInternalName === field.internalName
                                                        ? ({ fieldInternalName: v.fieldInternalName, valueInJson: dateInUtcIsoString } as IFieldValue)
                                                        : v) || null
                                                : (currentFieldValues || []).concat([{ fieldInternalName: field.internalName, valueInJson: dateInUtcIsoString } as IFieldValue]);
                                            dispatch(setFieldValues(newValues));
                                        }}
                                    />
                                </FieldWrapper>);
                        }
                        default:
                            return (<></>);
                    }
                })
                }
            </div>
            <AccessPermissions isMobileView={isMobile} isCreateRecordWizard={true} />
        </>
    );
}
