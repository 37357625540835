import * as React from "react";
import { Icon } from "@fluentui/react";
import styles from "./FieldWrapper.module.scss";
import { RMark } from "../../../helpers/RMark";

export interface IFieldWrapperProps {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    description?: string;
    errorPadding?: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps): JSX.Element {
    const className: string = props.className
        ? `${styles.fieldWrapper} ${props.className}`
        : styles.fieldWrapper;
    const escapedHtml: string |  undefined = props.description?.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    const markdown: string = new RMark().render(escapedHtml || "");
    return (
        <div className={className}>
            <div className={styles.fieldContainer}>               
                {props.children}
            </div>
            {props.description && (
                <div className={`${styles.descriptionContainer} ${props.errorPadding && styles.errorPadding}`}>
                    <div className={styles.icon}>
                        <Icon iconName="Info" className={styles.iconStyling} ariaLabel={"Information Icon"}/>
                    </div>
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: markdown }}></div>
                </div>
            )}
        </div>
    );
}
