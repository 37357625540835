import * as React from "react";
import {
    IShimmerElement,
    Shimmer,
    ShimmerElementType
} from "@fluentui/react";
import styles from "./ControlShimmer.module.scss";

export interface IControlShimmerProps {
    rows?: number;
    cols?: number;
    rowHeight?: number;
    className?: string;
}

export function ControlShimmer(props: IControlShimmerProps): JSX.Element {
    let shimEls: IShimmerElement[];
    const cols: number =
        typeof props.cols === "number" && props.cols >= 0 && props.cols < 10 ? props.cols : 1;
    if (cols > 1) {
        shimEls = [];
        const gapWidth: number = 2;
        const lineWidth: number = (100 - gapWidth * (cols - 1)) / cols;
        for (let i: number = 0; i < cols; i++) {
            shimEls.push({ type: ShimmerElementType.line, width: `${lineWidth}%`, height: props.rowHeight });
            shimEls.push({ type: ShimmerElementType.gap, width: `${gapWidth}%` });
        }
    }
    const rows: number =
        typeof props.rows === "number" && props.rows >= 0 && props.rows < 50 ? props.rows : 1;
    const rowArray: IShimmerElement[] = [];
    for (let i: number = 0; i < rows; i++) {
        rowArray.push({ type: ShimmerElementType.line, height: props.rowHeight });
    }

    return (
        <div className={`${styles.controlShimmer} ${props.className || ""}`}>
            {rowArray.map((v, i) => {
                return <Shimmer key={i} className={styles.shimmer} marginHeight={10} shimmerElements={shimEls || [v]} ariaLabel={"Shimmer loading annimation"}/>;
            })}
        </div>
    );
}
