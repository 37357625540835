import * as React from "react";
import {
    IBasePickerSuggestionsProps,
    IPersona,
    IPersonaProps,
    Label,
    NormalPeoplePicker,
    Icon
} from "@fluentui/react";
import styles from "./ContactPicker.module.scss";
import IApiService from "../../../services/Api/IApiService";
import {
    ContactSource,
    IContact,
} from "../../../services/Api/executor/IApiServiceExecutor";
import DependencyResolver from "../../../providers/DependencyResolver/DependencyResolver";
import {
    ContactsDialogLoading,
    ContactsDialogNoResults,
    ContactsDialogSuggested,
    ContactsPickerDefaultAriaLabel
} from "../../../providers/Constants/FormControlConstants";
import {
    DialogHelper
} from "../../../helpers/DialogHelper";


interface IContactPickerProps {
    selectedUsers?: IContact[];
    usersToExclude?: IContact[];
    onChange?: (user: IContact[] | undefined) => void;
    limitToSources?: ContactSource[];
    multi?: boolean;
    label: string;
    disabled?: boolean;
    isRequired?: boolean;
}

interface ICustomPersonaProps extends IPersonaProps {
    data: IContact;
}

export default function ContactPicker(props: IContactPickerProps): JSX.Element {
    const onChange = (people?: IPersonaProps[]): void => {
        if (props.onChange) {
            let result: IContact[] | undefined = undefined;
            if (people && people.length) {
                result = people.map(p => (p as ICustomPersonaProps).data);
            }
            props.onChange(result);
        }
    };

    const castContactToPersona = (users: IContact[] | undefined): ICustomPersonaProps[] => {
        const result: ICustomPersonaProps[] = (users || []).map(user => {
            let className: string = styles.coinAad;
            let renderer: JSX.Element = <div title="Internal Contact">{DialogHelper.getInitials(user.fullName)}</div>;
            if(user.sourceType == ContactSource.Contact){
                renderer = <div title="External Contact"><Icon iconName={"Contact"}/></div>;
                className = styles.coinUser;
            } else if(user.sourceType == ContactSource.Organization){
                renderer = <div title="Organization"><Icon iconName={"Org"}/></div>;
                className = styles.coinOrg;
            }
            return {
                className: styles.personaWrapper,
                text: user.fullName || "",
                secondaryText: user.email || "",
                tertiaryText: user.jobTitle || "",
                imageInitials: DialogHelper.getInitials(user.fullName),
                onRenderInitials:() => renderer,
                coinProps:{className:className},
                optionalText: user.email || "",
                data: user
            } as ICustomPersonaProps;
        });
        return result;
    };

    const onResolveSuggestions = async (
        filterText: string,
        cp?: IPersonaProps[]
    ): Promise<ICustomPersonaProps[]> => {
        const currentPersonas: ICustomPersonaProps[] = cp as ICustomPersonaProps[] || [];
        const users: IContact[] | undefined = await getFilteredUsers(filterText);
        const allSuggestions: ICustomPersonaProps[] = castContactToPersona(users);
        const currPersonas: ICustomPersonaProps[] = currentPersonas
            ? currentPersonas
            : castContactToPersona(props.selectedUsers);
        const usersToExclude: ICustomPersonaProps[] = [
            ...(currPersonas || []),
            ...castContactToPersona(props.usersToExclude)
        ];

        const filteredSuggestions: ICustomPersonaProps[] = allSuggestions.filter(
            p => !usersToExclude.some(currPer => currPer.data.id === p.data.id && currPer.data.sourceType === p.data.sourceType)
        );
        const uniqueSuggestions: ICustomPersonaProps[] = [];
        filteredSuggestions.forEach(p => {
            if (!uniqueSuggestions.some(uP =>uP.data.id === p.data.id && uP.data.sourceType === p.data.sourceType)) {
                uniqueSuggestions.push(p);
            }
        });

        return uniqueSuggestions;
    };

    const getFilteredUsers = async (filterText: string) => {
        const resolver: DependencyResolver = new DependencyResolver();
        const apiService: IApiService = resolver.ResolveIApiService();
        const users: IContact[] = await apiService.GetContacts(filterText, props.limitToSources);
        return users;
    };

    const selectedUser = (): IPersona[] => {
        const result: IPersona[] = castContactToPersona(props.selectedUsers);
        return result;
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: ContactsDialogSuggested,
        noResultsFoundText: ContactsDialogNoResults,
        loadingText: ContactsDialogLoading,
        resultsMaximumNumber:99,
    };

    return (
        <div className={styles.contactPickerContainer}>
            <Label className={styles.contactPickerLabel} required={props.isRequired}>
                {props.label}
            </Label>
            <NormalPeoplePicker
                aria-label={props.label || ContactsPickerDefaultAriaLabel}
                onResolveSuggestions={onResolveSuggestions}
                pickerSuggestionsProps={suggestionProps}
                itemLimit={props.multi ? 12 : 1}
                getTextFromItem={(persona: IPersonaProps) => persona.text || ""}
                onChange={onChange}
                selectedItems={selectedUser()}
                className={styles.contactPicker}
                disabled={props.disabled}
                
                resolveDelay={500}
            />
        </div>
    );
}
