import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { FieldTypes, IContentTypeField, IFieldValue, IRecord } from "../../services/Api/executor/IApiServiceExecutor";
import {
    commentsEditKeyDates,
    editKeyDatesHeader,
    loadingKeyDatesMessage
} from "../../providers/Constants/RecordWizardConstants";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import { selectActiveFields, selectCurrentFieldValue, selectCurrentRecord, setFieldValues, updateRecordKeyDatesAsync } from "./manageRecordWrapperSlice";
import { useParams } from "react-router-dom";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import {
    getWizardResponseInformationDataAsync,
    resetWizardResponseInformation,
    setWizardResponseInformation
} from "../wizardResponseInformation/wizardResponseInformationSlice";
import { DefaultButton, Icon, MessageBar, MessageBarType, PrimaryButton, Separator, Spinner, SpinnerSize, Text, TooltipHost } from "@fluentui/react";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import styles from "./ManageRecordKeyDates.module.scss";
import { WhoViewCommentsDescription, WhoViewCommentsLabel } from "../../providers/Constants/FormControlConstants";
import { isMakeDecisionStage } from "./../../components/FormControls/RecordTimeline/StagesContentUtils";
import DateHelper from "../../helpers/DateHelper";
import StringHelper from "../../helpers/StringHelper";
import { useTranslation } from "react-i18next";

export function ManageRecordKeyDates(): JSX.Element {
    const { t } = useTranslation();
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValue);
    const [changeComments, setChangeComments] = React.useState<string | undefined>(undefined);

    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [initilized, setInitilized] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const { recordId } = useParams<{ recordId: string }>();

    const readOnly: boolean = React.useMemo(
        () => !currentRecord?.canCurrentUserEditRecord || !!currentRecord?.canCurrentUsedEditRecordAdminLimitedAccess,
        [currentRecord?.canCurrentUsedEditRecordAdminLimitedAccess, currentRecord?.canCurrentUserEditRecord]
    );

    const anyChangesApplied: boolean = React.useMemo(() => { 
        let result: boolean =  !!currentRecord;

        if (currentRecord?.fieldValues && currentFieldValues) {
            for (const fv of currentFieldValues) {
                if (currentRecord.fieldValues.some(rv => rv.fieldInternalName === fv.fieldInternalName && rv.valueInJson !== fv.valueInJson)) {
                    result = true;
                    break;
                }
            }
        }

        return result;
    },[currentFieldValues, currentRecord]);
    
    const requiredFieldsIsNotEmpty: boolean = React.useMemo(() => {
        let result: boolean = !!changeComments;

        if (result) {
            const requiredFields: IContentTypeField[] = currentFields?.filter(v => v.isRequired && (v.fieldType === FieldTypes.DateOnly || v.fieldType === FieldTypes.DateTime)) || [];
            const emptyFieldValues: IFieldValue[] = currentFieldValues?.filter(v => StringHelper.isJsonEmptyObject(v.valueInJson)) || [];
            const someRequiredValuesAreEmpty: boolean = emptyFieldValues.some(v => requiredFields.some(r => r.internalName === v.fieldInternalName));
            const someRequiredFieldNotExist: boolean = !requiredFields.every(r => currentFieldValues?.some(v => v.fieldInternalName === r.internalName));
            result = !someRequiredValuesAreEmpty && !someRequiredFieldNotExist;
        }

        return result;
    }, [changeComments, currentFields, currentFieldValues]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return readOnly || updatingInProgress;
    },[readOnly, updatingInProgress]);
    
    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked && requiredFieldsIsNotEmpty;
    },[anyChangesApplied, formIsBlocked, requiredFieldsIsNotEmpty]);

    const cancelBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    React.useEffect(() => {
        if (!initilized && currentRecord) {
            setInitilized(true);
        }
    }, [initilized, currentRecord]);

    const validateUpdate: () => void = React.useCallback((): void => {
        if (recordId && updateBtnIsActive) {
            setUpdatingInProgress(true);
            setError(undefined);
            dispatch(
                updateRecordKeyDatesAsync(
                    {
                        recordId: recordId,
                        comments: changeComments || "",                        
                        fieldValues: currentFieldValues || undefined
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                            setChangeComments("");
                        },
                        onError: e => {
                            setError(e);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [recordId, updateBtnIsActive, dispatch, changeComments, currentFieldValues]);

    React.useEffect(() => {
        dispatch(getWizardResponseInformationDataAsync(currentRecord?.recordType || ""));
        if (currentRecord) {
            dispatch(setWizardResponseInformation(currentRecord));
        }
        return () => {
            dispatch(resetWizardResponseInformation());
        };
    }, [currentRecord, dispatch]);
    
    const onCancelClick: () => void = React.useCallback(() => {
        if (currentRecord) {
            dispatch(setWizardResponseInformation(currentRecord));
        }
    },[
        dispatch,
        currentRecord
    ]);
    const isDecisionStage: boolean = React.useMemo(() => isMakeDecisionStage(currentRecord?.stage), [currentRecord?.stage]);
    const commentsDisabled: boolean = formIsBlocked || !anyChangesApplied;

    return (
        <div>
            {!initilized ? (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.small}
                        label={loadingKeyDatesMessage}
                    />
                </div>
            ) : (
                <div className={styles.manageRecordDetailsContainer}>
                    <div className={styles.expandedHeader}>
                        <h2 className={styles.expandedHeaderText}>{editKeyDatesHeader}</h2>
                    </div>
                    <div className={styles.formContainer}>
                        {currentFields?.filter(v => (v.fieldType === FieldTypes.DateOnly 
                        || v.fieldType === FieldTypes.DateTime
                        || v.fieldType === FieldTypes.TimeOnly) && !v.notEditable).map((field, index) => {
                            switch (field.fieldType) {
                                case FieldTypes.DateOnly:
                                case FieldTypes.DateTime: {
                                    const dateStringValue: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                                    const dateValue: Date | undefined = dateStringValue ? new Date(dateStringValue) : undefined;
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <DatePicker
                                                dateLabel={field.displayName}
                                                timeLabel={""}
                                                placeholder={t("datePickerPlaceholder")}
                                                required={field.isRequired}
                                                includeTime={field.fieldType !== FieldTypes.DateOnly}
                                                hideDate={false}
                                                ariaLabel={field.description}
                                                value={dateValue}
                                                onChange={date => {
                                                    const newValues: IFieldValue[] | null = currentFieldValues?.map(v =>
                                                        v.fieldInternalName === field.internalName
                                                            ? ({ fieldInternalName: v.fieldInternalName, valueInJson: date ? DateHelper.GetDateISOWithoutMilliseconds(date) : undefined } as IFieldValue)
                                                            : v) || null;
                                                    dispatch(setFieldValues(newValues));
                                                }}
                                                disabled={formIsBlocked}
                                            />
                                        </FieldWrapper>);
                                }
                                case FieldTypes.TimeOnly: {
                                    const timeAsNumber: string | undefined = currentFieldValues?.find(v => v.fieldInternalName === field.internalName)?.valueInJson;
                                    const dateValue: Date | undefined = timeAsNumber ? DateHelper.ToTimeOnlyFromNumberAsDate(Number(timeAsNumber)) : undefined;
                                    return (
                                        <FieldWrapper key={index} description={field.description}>
                                            <DatePicker
                                                dateLabel={field.displayName}
                                                timeLabel={field.displayName}
                                                placeholder={t("datePickerPlaceholder")}
                                                required={field.isRequired}
                                                includeTime={true}
                                                hideDate={true}
                                                ariaLabel={field.description}
                                                value={dateValue}
                                                onChange={date => {
                                                    const timeOnlyNumber: string | undefined  = date ? DateHelper.ToTimeOnlyFromNumberFromDate(date) : undefined;
                                                    const newValues: IFieldValue[] | null = currentFieldValues?.map(v =>
                                                        v.fieldInternalName === field.internalName
                                                            ? ({ fieldInternalName: v.fieldInternalName, valueInJson: timeOnlyNumber } as IFieldValue)
                                                            : v) || null;
                                                    dispatch(setFieldValues(newValues));
                                                }}
                                                disabled={formIsBlocked}
                                            />
                                        </FieldWrapper>);
                                }
                                default:
                                    return (<></>);
                            }
                        })}
                        <FieldWrapper>
                            <TextBox
                                name={commentsEditKeyDates}
                                required={true}
                                rows={5}                                
                                defaultValue={changeComments}
                                value={changeComments}
                                onChange={(ev, newValue) => {
                                    setChangeComments(newValue || "");
                                }}
                                disabled={commentsDisabled}
                                valid={!commentsDisabled ? !!changeComments : true}
                                preventBorderColour={isDecisionStage}
                            />
                        </FieldWrapper>
                        <div className={styles.whoComments}>{WhoViewCommentsLabel}
                            <TooltipHost content={WhoViewCommentsDescription}>
                                <Icon iconName="Info" className={styles.iconStyling} ariaLabel={"Information Icon"} />
                            </TooltipHost>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Separator />
                        {!!error && 
                                <div>
                                    <MessageBar
                                        isMultiline={true}
                                        messageBarType={MessageBarType.error}
                                    >
                                        {error.message}
                                    </MessageBar>
                                </div>
                        }
                        <div className={styles.btnsContainer}>
                            <PrimaryButton
                                onClick={validateUpdate}
                                disabled={!updateBtnIsActive}
                                className={styles.updateBtn}
                            >
                                <Text className={styles.text}>Save</Text>
                                {updatingInProgress && (
                                    <Spinner className={styles.spinner} size={SpinnerSize.small} />
                                )}
                            </PrimaryButton>
                            <DefaultButton
                                disabled={!cancelBtnIsActive}
                                onClick={onCancelClick}
                            >
                                Cancel
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
