import React from "react";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import {
    allTasksPipelineTitle,
    nextMonthPipelineTitle,
    nextWeekPipelineTitle,
    nowPipelineTitle,
    overduePipelineTitle,
    tasksPipelineDescr,
    tasksPipelineHeader,
    thisWeekPipelineTitle
} from "./tasksPipelineConstants";
import styles from "./TasksPipeline.module.scss";
import { IRecordResult } from "../../../services/Api/executor/IApiServiceExecutor";
import DateHelper, { DayIndex, MonthStep } from "../../../helpers/DateHelper";
import { IRefiner } from "../../../model/Refiner";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";
import { useHistory } from "react-router-dom";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { setRefiners } from "../../recordsTable/recordsTableSlice";
import { activeTabKey } from "../../../providers/Constants/DashboardConstants";
import UrlHelper, { URL_QUERY_KEYS } from "../../../helpers/UrlHelper";
import { mp_DueDateCurrentTask } from "../../../providers/Constants/SearchConstants";
import { ScrollHelper, SCROLL_KEYS } from "../../../helpers/ScrollHelper";

//eslint-disable-next-line @typescript-eslint/typedef
const RecordsPiplineFilters = {
    managedProperties: {
        dueDateCurrentTask: mp_DueDateCurrentTask
    },
    getDateString(record: IRecordResult): string | undefined {
        return record.dueDateCurrentTask?.value;
    },
    isOverdueRecord(record: IRecordResult): boolean {
        const dateString: string | undefined = RecordsPiplineFilters.getDateString(record);
        const result: boolean = !!(dateString && new Date() > new Date(dateString));
        return result;
    },
    overdueRecordFilters(): IRefiner[] {
        const result: IRefiner[] = [{
            displayName: overduePipelineTitle,
            entries: [],
            from: "min",
            name: RecordsPiplineFilters.managedProperties.dueDateCurrentTask,
            to: (new Date()).toISOString(),
            type: ManagedPropertyViewType.Date
        }];
        return result;
    },
    isNowRecord(record: IRecordResult): boolean {
        const dateString: string | undefined = RecordsPiplineFilters.getDateString(record);
        const result: boolean = !!(dateString && DateHelper.isToday(new Date(dateString)));
        return result;
    },
    nowRecordFilters(): IRefiner[] {
        const startDay: Date = DateHelper.getStartOfDay();
        const endDay: Date = DateHelper.getEndOfDay();
        const result: IRefiner[] = [{
            displayName: nowPipelineTitle,
            entries: [],
            from: startDay.toISOString(),
            name: RecordsPiplineFilters.managedProperties.dueDateCurrentTask,
            to: endDay.toISOString(),
            type: ManagedPropertyViewType.Date
        }];
        return result;
    },
    isThisWeekRecord(record: IRecordResult): boolean {
        const dateString: string | undefined = RecordsPiplineFilters.getDateString(record);
        let result: boolean = false;
        if (dateString) {
            const initiateDate: Date = new Date(dateString);
            const lastSunday: Date = DateHelper.getLastSunday();
            const nextSaturday: Date = DateHelper.getNextDate(DayIndex.Saturday);
            result = initiateDate > lastSunday && initiateDate < nextSaturday;
        }
        return result;
    },
    thisWeekRecordFilters(): IRefiner[] {
        const startDay: Date = DateHelper.getLastSunday();
        const endDay: Date = DateHelper.getNextDate(DayIndex.Saturday);
        const result: IRefiner[] = [{
            displayName: thisWeekPipelineTitle,
            entries: [],
            from: startDay.toISOString(),
            name: RecordsPiplineFilters.managedProperties.dueDateCurrentTask,
            to: endDay.toISOString(),
            type: ManagedPropertyViewType.Date
        }];
        return result;
    },
    isNextWeekRecord(record: IRecordResult): boolean {
        const dateString: string | undefined = RecordsPiplineFilters.getDateString(record);
        let result: boolean = false;
        if (dateString) {
            const nextSunday: Date = DateHelper.getNextDate(DayIndex.Sunday);
            const nextSaturdayByNextSunday: Date = DateHelper.getNextDate(DayIndex.Saturday, nextSunday);
            const initiateDate: Date = new Date(dateString);
            result = initiateDate > nextSunday && initiateDate < nextSaturdayByNextSunday;
        }
        return result;
    },
    nextWeekRecordFilters(): IRefiner[] {
        const startDay: Date = DateHelper.getNextDate(DayIndex.Sunday);
        const endDay: Date = DateHelper.getNextDate(DayIndex.Saturday, startDay);
        const result: IRefiner[] = [{
            displayName: nextWeekPipelineTitle,
            entries: [],
            from: startDay.toISOString(),
            name: RecordsPiplineFilters.managedProperties.dueDateCurrentTask,
            to: endDay.toISOString(),
            type: ManagedPropertyViewType.Date
        }];
        return result;
    },
    isNextMonthRecord(record: IRecordResult): boolean {
        const dateString: string | undefined = RecordsPiplineFilters.getDateString(record);
        let result: boolean = false;
        if (dateString) {
            const lastDayOfCurrentMonth: Date = DateHelper.getLastDayInMonth(MonthStep.Current);
            const lastDayOfNextMonth: Date = DateHelper.getLastDayInMonth(MonthStep.Next);
            const initiateDate: Date = new Date(dateString);
            result = initiateDate > lastDayOfCurrentMonth && initiateDate < lastDayOfNextMonth;
        }
        return result;
    },
    nextMonthRecordFilters(): IRefiner[] {
        const startDay: Date = DateHelper.getLastDayInMonth(MonthStep.Current);
        const endDay: Date = DateHelper.getLastDayInMonth(MonthStep.Next);
        const result: IRefiner[] = [{
            displayName: nextMonthPipelineTitle,
            entries: [],
            from: startDay.toISOString(),
            name: RecordsPiplineFilters.managedProperties.dueDateCurrentTask,
            to: endDay.toISOString(),
            type: ManagedPropertyViewType.Date
        }];
        return result;
    },
    isValidRecord(record: IRecordResult): boolean {
        return !!(record.dueDateDecisionMaker?.value);
    }
};

interface ITasksPipelineProps {
    records?: IRecordResult[];
    inProgress?: boolean;
    mobileView?: boolean;
}

interface ITypeTasksPipeline {
    title: string;
    className: string;
    recordsCount: number;
    onClick: () => void;
}

export function TasksPipeline({ records, inProgress, mobileView }: ITasksPipelineProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    const onItemClick: (k: string) => void = React.useCallback((key: string) => {
        let refiners: IRefiner[] = [];
        switch (key) {
            case overduePipelineTitle: {
                refiners = RecordsPiplineFilters.overdueRecordFilters();
                break;
            }
            case nowPipelineTitle: {
                refiners = RecordsPiplineFilters.nowRecordFilters();
                break;
            }
            case thisWeekPipelineTitle: {
                refiners = RecordsPiplineFilters.thisWeekRecordFilters();
                break;
            }
            case nextWeekPipelineTitle: {
                refiners = RecordsPiplineFilters.nextWeekRecordFilters();
                break;
            }
            case nextMonthPipelineTitle: {
                refiners = RecordsPiplineFilters.nextMonthRecordFilters();
                break;
            }
            case allTasksPipelineTitle: {
                break;
            }
        }
        history.push({ search: UrlHelper.castUrlWithQueryStringParam(activeTabKey, URL_QUERY_KEYS.TAB, history.location.search) });
        ScrollHelper.scrollTo(SCROLL_KEYS.TABS);
        setTimeout(function () { dispatch(setRefiners(refiners)); }, 200);
    }, [dispatch, history]);

    const overdueRecords: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        return (records || []).filter(RecordsPiplineFilters.isOverdueRecord);
    }, [records]);

    const nowRecords: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        return (records || []).filter(RecordsPiplineFilters.isNowRecord);
    }, [records]);

    const thisWeekRecords: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        return (records || []).filter(RecordsPiplineFilters.isThisWeekRecord);
    }, [records]);

    const nextWeekRecords: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        return (records || []).filter(RecordsPiplineFilters.isNextWeekRecord);
    }, [records]);

    const nextMonthRecords: IRecordResult[] = React.useMemo((): IRecordResult[] => {
        return (records || []).filter(RecordsPiplineFilters.isNextMonthRecord);
    }, [records]);

    const allRecords: IRecordResult[] = React.useMemo(() => {
        return records || [];
    }, [records]);

    const recordsPipeline: ITypeTasksPipeline[] = React.useMemo((): ITypeTasksPipeline[] => {
        return [
            {
                title: overduePipelineTitle,
                className: styles.overdueBgr,
                recordsCount: overdueRecords.length,
                onClick: () => onItemClick(overduePipelineTitle)
            },
            {
                title: nowPipelineTitle,
                className: styles.nowBgr,
                recordsCount: nowRecords.length,
                onClick: () => onItemClick(nowPipelineTitle)
            },
            {
                title: thisWeekPipelineTitle,
                className: styles.thisWeekBgr,
                recordsCount: thisWeekRecords.length,
                onClick: () => onItemClick(thisWeekPipelineTitle)
            },
            {
                title: nextWeekPipelineTitle,
                className: styles.nextWeekBgr,
                recordsCount: nextWeekRecords.length,
                onClick: () => onItemClick(nextWeekPipelineTitle)
            },
            {
                title: nextMonthPipelineTitle,
                className: styles.nextMonthBgr,
                recordsCount: nextMonthRecords.length,
                onClick: () => onItemClick(nextMonthPipelineTitle)
            },
            {
                title: allTasksPipelineTitle,
                className: styles.allRecordBgr,
                recordsCount: allRecords.length,
                onClick: () => onItemClick(allTasksPipelineTitle)
            }
        ];
    }, [
        allRecords.length,
        nextMonthRecords.length,
        nextWeekRecords.length,
        nowRecords.length,
        overdueRecords.length,
        thisWeekRecords.length,
        onItemClick
    ]);
    return (
        <div
            className={[
                styles.recordsCardInfo,
                mobileView ? styles.mobileRecordsCardInfo : ""
            ].join(" ")}
        >
            <div className={styles.recordsPipelineContainer}>
                <div className={styles.cardTextInfo}>
                    <div className={styles.cardHeader}>
                        <Icon iconName={"DocumentApproval"} />
                        <h3>{tasksPipelineHeader}</h3>
                    </div>
                    <p>{tasksPipelineDescr}</p>
                </div>
                {!inProgress ? (
                    <div className={styles.cardPipelineData}>
                        <div
                            className={[
                                styles.cardPipelineColumn,
                                mobileView ? styles.mobileCardPipelineColumn : ""
                            ].join(" ")}
                        >
                            {recordsPipeline.map((pipelineItem, index) => {
                                return (
                                    <div key={index} className={styles.cardItem} onClick={pipelineItem.onClick}>
                                        <div
                                            className={[
                                                styles.cardNumberBox,
                                                pipelineItem.className
                                            ].join(" ")}
                                        >
                                            {pipelineItem.recordsCount}
                                        </div>
                                        <div className={styles.cardItemText}>
                                            {pipelineItem.title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <Spinner size={SpinnerSize.large} />
                )}
            </div>
        </div>
    );
}
