import {
    Email,
    ExcelDocument,
    Image,
    Meeting,
    OtherDocument,
    PDFDocument,
    PowerPointDocument,
    Record,
    TextDocument,
    Video,
    WordDocument
} from "../providers/Constants/FabricIconConstants";

// import { RecordWorkProductType } from "../providers/Constants/AppConstants";

export default class IconHelper {
    public static ImageExtensions: string[] = ["jpg", "jpeg", "gif", "png", "bmp", "tif"];

    public static VideoExtensions: string[] = [
        "mp4",
        "avi",
        "wmv",
        "mov",
        "flv",
        "m4p",
        "m4v",
        "ogg",
        "mpg",
        "mpeg",
        "mpv"
    ];

    public static GetIconOfficeUIFabricName(filename: string): string {
        let fabricIconName: string = OtherDocument;
        if (!filename || filename.indexOf(".") < 0) {
            fabricIconName = Record;
        } else {
            const splitArray: string[] = filename.split(".");
            const ext: string = splitArray[splitArray.length - 1].toLowerCase();
            if (ext.indexOf("doc") === 0) {
                fabricIconName = WordDocument;
            } else if (ext.indexOf("ppt") === 0) {
                fabricIconName = PowerPointDocument;
            } else if (ext.indexOf("pdf") === 0) {
                fabricIconName = PDFDocument;
            } else if (ext.indexOf("xls") === 0) {
                fabricIconName = ExcelDocument;
            } else if (ext.indexOf("txt") === 0) {
                fabricIconName = TextDocument;
            } else if (ext.indexOf("msg") === 0) {
                fabricIconName = Email;
            } else if (ext.indexOf("ics") === 0) {
                fabricIconName = Meeting;
            } else if (IconHelper.ImageExtensions.some(e => ext.indexOf(e) === 0)) {
                fabricIconName = Image;
            } else if (IconHelper.VideoExtensions.some(e => ext.indexOf(e) === 0)) {
                fabricIconName = Video;
            } else {
                fabricIconName = OtherDocument;
            }
        }
        return fabricIconName;
    }

    // public static GetRecordIconOfficeUIFabricName(type: RecordWorkProductType | undefined): string {
    //   let fabricIconName: string;
    //   switch (type) {
    //     case RecordWorkProductType.DepartmentalBrief:
    //       fabricIconName = DepartmentBrief;
    //       break;
    //     case RecordWorkProductType.DepartmentalCorrespondence:
    //       fabricIconName = DepartmentalCorrespondence;
    //       break;
    //     case RecordWorkProductType.EventPack:
    //       fabricIconName = EventPack;
    //       break;
    //     case RecordWorkProductType.MinisterialBrief:
    //       fabricIconName = MinisterialBrief;
    //       break;
    //     case RecordWorkProductType.MinisterialCorrespondence:
    //       fabricIconName = MinisterialCorrespondence;
    //       break;
    //     case RecordWorkProductType.PAECBrief:
    //       fabricIconName = PAECBrief;
    //       break;
    //     case RecordWorkProductType.PAECTopicList:
    //       fabricIconName = PAECTopicList;
    //       break;
    //     case RecordWorkProductType.PPQ:
    //       fabricIconName = PPQ;
    //       break;
    //     case RecordWorkProductType.AdjournmentDebate:
    //       fabricIconName = AdjournmentDebate;
    //       break;
    //     case RecordWorkProductType.ConstituencyQuestion:
    //       fabricIconName = ConstituencyQuestion;
    //       break;
    //     case RecordWorkProductType.QuestionOnNotice:
    //       fabricIconName = QuestionOnNotice;
    //       break;
    //     case RecordWorkProductType.QuestionWithoutNotice:
    //       fabricIconName = QuestionWithoutNotice;
    //       break;
    //     case RecordWorkProductType.CoOrdinationComment:
    //       fabricIconName = CoOrdinationComment;
    //       break;
    //     default:
    //       fabricIconName = Record;
    //       break;
    //   }
    //   return fabricIconName;
    // }
}
