import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PrimaryButton } from "@fluentui/react";
import { selectIsMobile } from "../../app/globalSlices/contextSlice";

import styles from "./DashboardInfoBar.module.scss";
import { WelcomeUser } from "../welcomeUser/WelcomeUser";
import { ViewDashboardDropDown } from "../dashboard/viewDashboardDropDown/ViewDashboardDropDown";
import { AppRoutes } from "../../app/Constants";
import { useTranslation } from "react-i18next";

export function DashboardInfoBar(): JSX.Element {
    const isMobile: boolean = useSelector(selectIsMobile);
    const { t } = useTranslation();

    return (
        <div className={`${styles.pageInfoBarContainer} ${styles.bgWhite}`}>
            <WelcomeUser/>
            <ViewDashboardDropDown />
            {
                isMobile && (
                    <div className={styles.dashboardMobileButtons}>
                        <div className={styles.buttonContainer}>
                            <Link to={AppRoutes.createRecord}>
                                <PrimaryButton
                                    text={t("newRecordButton")}
                                    iconProps={{ iconName: "Add" }}
                                    allowDisabledFocus
                                    ariaLabel={`${t("newRecordButton")} button`}
                                />
                            </Link>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
