import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest
} from "@azure/msal-browser";
import IAuthServiceExecutor, { IAccessToken, IAuthApiConfig } from "./IAuthServiceExecutor";
import ILogProvider from "../../../providers/Log/ILogProvider";
import { TeamsContextProvider } from "../../../providers/TeamsContextProvider";
import * as microsoftTeams from "@microsoft/teams-js";
import { Msal2Provider, Msal2PublicClientApplicationConfig, ProviderState, Providers } from "@microsoft/mgt";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { TeamsUserCredentialAuthConfig, TeamsUserCredential } from "@microsoft/teamsfx";

const MGTScopes :string[] = ["Sites.Read.All"];

export default class AuthServiceExecutor implements IAuthServiceExecutor {
    private logProvider: ILogProvider;
    private publicClientApplication: PublicClientApplication | null = null;

    private readonly className: string = "AuthServiceExecutor";

    constructor(logProvider: ILogProvider) {
        this.logProvider = logProvider;
    }

    public async SignIn(): Promise<AccountInfo | null> {
        let account: AccountInfo | null = null;
        if(TeamsContextProvider.IsInTeams && TeamsContextProvider.Context?.userObjectId){
            account = {
                environment: "",
                homeAccountId: TeamsContextProvider.Context.userObjectId,
                localAccountId: TeamsContextProvider.Context.userObjectId,
                tenantId: TeamsContextProvider.Context.tid || "",
                username: TeamsContextProvider.Context.userPrincipalName || ""
            };
            await this.initializeTeamsMgtProvider();
        } else {
            try {
            // is there already a signed in account?  
                const app: PublicClientApplication = await this.GetPublicClientApplication();
                app.initialize();
                const accounts: AccountInfo[] = app.getAllAccounts();
                account = accounts && accounts.length > 0 ? accounts[0] : null;
                const scopes: string[] = await this.GetScopes();
                if (!account) {                  
                    const tokenResponse: AuthenticationResult | null = await app.handleRedirectPromise();
                    account = tokenResponse?.account || null;
                    if (!account) {
                        this.logProvider.Info(this.className, "No account. User must login.");
                        await app.loginRedirect({ scopes: scopes });
                    }
                }

                if (account) {
                    const authority: string | undefined = !account.tenantId
                        ? undefined
                        : `https://login.microsoftonline.com/${account.tenantId}`;
                    this.initializeMgtProvider(app, authority);
                }
            } catch (error) {
                this.logProvider.Error(this.className, "Failed to handleRedirectPromise()", error);
            }
        }

        return account;
    }

    public async GetAccessToken(account: AccountInfo | null): Promise<IAccessToken> {
        let accessToken: IAccessToken;
        if(TeamsContextProvider.IsInTeams){
            return await this.getTeamsToken();
        }
        if (!account) {
            throw new Error("Can't get token when not signed in!");

            // NOTE: we could call SignIn here but might end up in an infite loop. The application should not ever be
            // calling GetAccessToken prior to having called SignIn. As such, this is an error in the application that
            // should not be resolved here.

        } else {
            const scopes: string[] = await this.GetScopes();
            const authority: string | undefined = !account.tenantId
                ? undefined
                : `https://login.microsoftonline.com/${account.tenantId}`;

            const app: PublicClientApplication = await this.GetPublicClientApplication();
            try {
                const tokenRequest: SilentRequest = {
                    account: account,
                    authority: authority,
                    scopes: scopes
                };
                const authResult: AuthenticationResult = await app.acquireTokenSilent(tokenRequest);
                accessToken = {
                    accessToken: authResult.accessToken,
                    expiresOn: authResult.expiresOn
                };
            } catch (ex) {
                this.logProvider.Warning(this.className, "Silent token acquisition failed, fetching token via redirect flow.", ex);

                // if we get here it is because there is a cached account but it can no longer refetch access tokens silently
                // ie if it has been idle overnight. The following forces the redirect flow with the signed in account.
                const tokenRequest: RedirectRequest = {
                    account: account,
                    authority: authority,
                    scopes: scopes,
                    prompt: "login",
                    loginHint: account.username
                };
                await app.acquireTokenRedirect(tokenRequest);

                // should be impossible to get here, but just in case, this will force the redirect flow from the beginning
                // as if the user was never signed in.
                await app.loginRedirect({ scopes: scopes });

                // should be double impossible to get here...
                throw new Error("Failed to fetch token silently and failed to force redirect flow.");
            }
        }
        return accessToken;
    }

    public async GetAuthApiConfig(): Promise<IAuthApiConfig> {
        //config.json file updated by release pipelines for each environment
        const configToFetch: string = "config.json";
        const apiConfigResponse: Response = await fetch(configToFetch);
        const apiConfig: IAuthApiConfig = await apiConfigResponse.json();
        return apiConfig;
    }

    private async GetPublicClientApplication(): Promise<PublicClientApplication> {
        if (!this.publicClientApplication) {
            const msalConfig: Configuration = await this.GetMsalConfig();
            this.publicClientApplication = new PublicClientApplication(msalConfig);
        }
        return this.publicClientApplication;
    }

    private async getTeamsToken():Promise<IAccessToken>{
        return new Promise((resolve, reject)=>{
            const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
                successCallback: (token: string) => {
                    resolve({
                        accessToken: token,
                        expiresOn: tryParseExpDateJwt(token)
                    });
                },
                failureCallback: (error: string) => {
                    // When the getAuthToken function returns a "resourceRequiresConsent" error, 
                    // it means Azure AD needs the user's consent before issuing a token to the app. 
                    // The following code redirects the user to the "Sign in" page where the user can grant the consent. 
                    // Right now, the app redirects to the consent page for any error.
                    this.logProvider.Error(this.className, error);
                    reject(error);
                },
                resources: []
            };
            microsoftTeams.authentication.getAuthToken(authTokenRequest);
        });
    }

    private async GetMsalConfig(): Promise<Configuration> {
        // get auto/api config
        const apiConfig: IAuthApiConfig = await this.GetAuthApiConfig();

        // MSAL configration
        const origin: string = `${window.location.protocol}//${window.location.host}`;
        const tenant: string = apiConfig.tenantId; //"organizations";
        const msalConfig: Configuration = {
            auth: {
                clientId: apiConfig.clientId,
                authority: `https://login.microsoftonline.com/${tenant}`,
                redirectUri: origin
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
            }
        };
        return msalConfig;
    }

    private async GetScopes(): Promise<string[]> {
        // get auto/api config
        const apiConfig: IAuthApiConfig = await this.GetAuthApiConfig();

        // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
        const scopes: string[] = ["openid", "profile", "email", "offline_access", apiConfig.apiScope];
        return scopes;
    }

    private initializeMgtProvider = (app: PublicClientApplication, authority: string | undefined) => {
        const msal2Config: Msal2PublicClientApplicationConfig = {
            publicClientApplication: app,
            scopes: MGTScopes,
            authority: authority
        };
        Providers.globalProvider = new Msal2Provider(msal2Config);
    };

    private initializeTeamsMgtProvider = async () => {
        const apiConfig: IAuthApiConfig = await this.GetAuthApiConfig();     
        /*TeamsMsal2Provider.microsoftTeamsLib = microsoftTeams;
        const msal2Config: TeamsMsal2Config = {
            scopes: MGTScopes,
            clientId: apiConfig.clientId,
            authPopupUrl: "/#" + AppRoutes.teamsAuth,
            ssoUrl: apiConfig.baseUrl + "/api/GetMgtAccessToken",
            httpMethod: HttpMethod.POST
        };
        Providers.globalProvider = new TeamsMsal2Provider(msal2Config);*/
        //TeamsFxProvider version
        const authConfig: TeamsUserCredentialAuthConfig = {
            clientId: apiConfig.clientId,
            initiateLoginEndpoint: apiConfig.baseUrl + "/api/GetMgtAccessToken",
        };

        const scope: string[] = MGTScopes;
        const credential: TeamsUserCredential = new TeamsUserCredential(authConfig);
        const provider: TeamsFxProvider = new TeamsFxProvider(credential, scope);
        Providers.globalProvider = provider;

        await credential.login(scope);
        Providers.globalProvider.setState(ProviderState.SignedIn);
    };
}

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function tryParseExpDateJwt(token:string): Date|null {
    try{
        const base64Url:string = token.split(".")[1];
        const base64:string = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload: string = decodeURIComponent(atob(base64).split("").map(function(c:string) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(""));
        const exp: number = JSON.parse(jsonPayload)?.exp;
        if(exp > 0){
            // https://stackoverflow.com/questions/39926104/what-format-is-the-exp-expiration-time-claim-in-a-jwt
            return new Date(exp * 1000);
        }
    // eslint-disable-next-line no-empty
    } catch {}
    return null;
}
