export const termSet_RecordTypes: string = "Record Types";
export const termSet_SecurityRestricted: string = "Security Classification";
export const termSet_DecisionCategories: string = "Decision Category";
export const termSet_RequestFrom: string = "Request From";
export const termSet_Timeframe: string = "Timeframe";
export const termSet_TeamRestrict: string = "Teams";
export const termSet_ReplyType: string = "Reply type";
export const termSet_ReplyFormat: string = "Reply format";
export const termSet_AutoResponseTemplates: string = "Auto-response Templates";
export const termSet_itemsForEvent: string = "Event Needs";
export const termSet_MediaCoverage: string = "Media Coverage";
export const termSet_ParliamentarySource: string = "Parliamentary Source";
export const termSet_RecommendationTypes: string = "Recommendation Types";
export const termSet_ParliamentMembers: string = "Parliament Members";

// Searchable Select Form Control
export const defaultLoadingString: string = "Loading...";
export const defaultNoResultsText: string = "Sorry, there are no results";
